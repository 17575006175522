import { MRT_ColumnDef } from "material-react-table";
import { Post_getConsultasDinamicasConAuthCore } from "../../../../../_start/helpers/Axios/CoreService";
import { Post_GetConsultasDinamicasUserDWH } from "../../../../../_start/helpers/Axios/DWHService";
import { DeserializarJSon } from "../../../../../_start/helpers/Helper";

export const FiltroListaDistribucion = {
  // verifica que exista una lista de distribución con la misma categoría
  validarListaCategoria: (correos: any[], Categoria: string) => {
    return correos.filter(f => f.Categoria === Categoria).length > 0
  },
  ValidarCorreoPrincipal: (correos: any[]) => {
    return correos.filter(f => f.TipoEnvio ===  'TO').length > 0
  },
}

export function getClientes(clienteIdS: string | null) {
  var params: { [id: string]: string | null; } = {};
  params["clienteIdS"] = String(clienteIdS);
  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "PortalQueryHelper",
    NombreConsulta: "GetClientesAdministradores",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};
export function setDataParamSistema(clienteIdS: string, Propiedad: string, ValorPropiedad: string) {
  var params: { [id: string]: string | null; } = {};
  params["ClienteIds"] = String(clienteIdS);
  params["Propiedad"] = String(Propiedad);
  params["ValorPropiedad"] = String(ValorPropiedad);

  setDataParamSistemaDWH(clienteIdS, Propiedad, ValorPropiedad);
  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "PORTALQueryHelper",
    NombreConsulta: "ActualizarPropiedad_Objeto",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};
export function setDataParamSistemaDWH(clienteIdS: string, Propiedad: string, ValorPropiedad: string) {
  var params: { [id: string]: string | null; } = {};
  params["ClienteIds"] = String(clienteIdS);
  params["Propiedad"] = String(Propiedad);
  params["ValorPropiedad"] = String(ValorPropiedad);


  // hacemos la consulta 
  return Post_GetConsultasDinamicasUserDWH({
    Clase: "PORTALQueryHelper",
    NombreConsulta: "ActualizarPropiedad_Objeto",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function getUsuarioClientePreoperacional(ClienteId: string) {
  var params: { [id: string]: string | null; } = {};
  params["ClienteId"] = String(ClienteId);
  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "PortalQueryHelper",
    NombreConsulta: "GetUsuariosPreoperacionalClientes",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function GetClientesPorModulo() {
  var params: { [id: string]: string | null; } = {};
  params["modulo"] = 'preoperacional';
  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "PortalQueryHelper",
    NombreConsulta: "GetClientesPorModulo",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function setUsuarioClientePreoperacional(ClienteId: string, Clave: string, DetallesAdicionales: string, LockoutEnabled: string, Id: string) {
  var params: { [id: string]: string | null; } = {};
  params["ClienteId"] = String(ClienteId);
  params["Clave"] = String(Clave);
  params["DetallesAdicionales"] = String(DetallesAdicionales);
  params["LockoutEnabled"] = String(LockoutEnabled);
  params["Id"] = String(Id);
  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "PortalQueryHelper",
    NombreConsulta: "SetDatosPreoperacionalCargouser",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function getPlantillas(ClienteId: string | null) {
  var params: { [id: string]: string | null; } = {};
  params["ClienteId"] = ClienteId;
  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "PortalQueryHelper",
    NombreConsulta: "GetPlantillasMov",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};



export function getTipos() {
  var params: { [id: string]: string | null; } = {};
  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "MOVQueryHelper",
    NombreConsulta: "GetTiposPreguntas",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};


export function getPreguntasPlantillas(PlantillaId: any) {
  var params: { [id: string]: string | null; } = {};
  params["PlantillaId"] = PlantillaId;
  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "MOVQueryHelper",
    NombreConsulta: "ConsultaPreguntasByPlantillas",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function VincularUsuarios(usuarios: string, dataCliente: string, ClienteId: string) {
  var params: { [id: string]: string | null; } = {};
  params["usuarios"] = usuarios;
  params["dataCliente"] = dataCliente;
  params["ClienteId"] = ClienteId;
  // hacemos la consulta 
  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "PREOPQueryHelper",
    NombreConsulta: "VincularUsuarios",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function setPreguntasPlantillas(Pregunta: any) {
  var params: { [id: string]: string | null; } = {};
  params["PlantillaId"] = String(Pregunta.PlantillaId);
  params["TipoPreguntaId"] = String(Pregunta.TipoPreguntaId);
  params["Valores"] = String(Pregunta.Valores);
  params["Secuencia"] = String(Pregunta.Secuencia);
  params["Descripcion"] = String(Pregunta.Descripcion);
  params["UsuarioId"] = String(Pregunta.UsuarioId);
  params["IsRedLine"] = String(Pregunta.IsRedLine);
  params["CompareRed"] = String(Pregunta.CompareRed);
  params["EsActivo"] = String(Pregunta.EsActivo);
  params["Agrupador"] = String(Pregunta.Agrupador);
  params["EsObligatorio"] = String(Pregunta.EsObligatorio);
  params["Clave"] = String(Pregunta.Clave);
  params["TienesImagenes"] = String((Pregunta.TienesImagenes == "null" ? "false" : "true"));
  params["PreguntaId"] = String(Pregunta.PreguntaId);
  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "MOVQueryHelper",
    NombreConsulta: "SetPreguntasByPlantillas",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function setPlantillas(Plantilla: any) {
  var params: { [id: string]: string | null; } = {};
  params["Clave"] = String(Plantilla.Clave);
  params["PlantillaId"] = String(Plantilla.PlantillaId);
  params["ClienteId"] = String(Plantilla.ClienteId);
  params["Nombre"] = Plantilla.Nombre;
  params["Observaciones"] = Plantilla.Observaciones;
  params["EsActivo"] = String(Plantilla.EsActivo);
  params["TipoPlantilla"] = String(Plantilla.TipoPlantilla);
  params["UsuarioId"] = Plantilla.UsuarioId;

  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "MOVQueryHelper",
    NombreConsulta: "SetPlantillas",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export const Tooltips: any = {
  "Cerrar": { title: "Cerrar", content: "Cancela todas las acciones y cierra el modal." }
  , "Guardar": { title: "Guardar", content: "Graba todo el contenido en el servidor, para posteriormente consultarlo." }
  , "Nuevo": { title: "Nuevo", content: "Limpia los campos para agregar un nuevo registro." }
}

export const TiposPlantillas = (data: any[]) => {
  return data.map((val: any) => {
    return val.TipoPlantilla;
  }).filter((e) => e);
}


export const BuscarReodenarSecuencia = (arr: any[]) => {
  // Función para convertir un objeto a una cadena JSON para comparación
  const objToString = (obj: any) => JSON.stringify(obj);

  // Función para encontrar secuencias repetidas
  const findRepeatedSequence = (arr: any) => {
    const seen = new Set();
    for (let i = 0; i < arr.length; i++) {
      const seq = objToString(arr[i]);
      if (seen.has(seq)) {
        return i; // Devuelve el índice donde comienza la secuencia repetida
      }
      seen.add(seq);
    }
    return -1; // No se encontró secuencia repetida
  };
  // Encuentra el índice de la secuencia repetida
  const index = findRepeatedSequence(arr);

  // Si se encuentra una secuencia repetida, reordena el array
  if (index !== -1) {
    const newSequence = arr.slice(index);
    return newSequence.concat(arr.slice(0, index));
  }
  // Si no hay secuencia repetida, devuelve el array original
  return arr;

}

// detallado de los correos para la lista de distribución de los correos del preoperacional
export const preop_DetalladoDistribucion: MRT_ColumnDef<any>[] =
  [{
    accessorKey: 'TipoEnvio',
    header: 'Tipo Envio'
  }, {
    accessorKey: 'Nombres',
    header: 'Nombres'
  },
  {
    accessorKey: 'Correo',
    header: 'Correo'
  }
  ];

// columnas para la configuracion de las listas de distribución preoperacional
export const preop_ListaDistribucion: MRT_ColumnDef<any>[] =
  [{
    accessorKey: 'Categoria',
    header: 'Categoría',
    Cell({ row }) {
      return row.original.Categoria ?? 'Ninguno'
    },
  }, {
    accessorKey: 'Nombre',
    header: 'Nombre',
    Cell({ row }) {
      return row.original.Nombre ?? 'General'
    },
  },
  {
    accessorKey: 'Correos',
    header: 'Correos',
    Cell({ row }) {
      const correos: any[] = DeserializarJSon(row.original.Correos)
      return Array.isArray(correos) ? correos.map(m => m.Correo).join(',') : '';
    }
  },
  {
    accessorKey: 'EsActivo',
    header: 'Estado',
    Cell({ row }) {
      return <span className={(row.original.EsActivo == 1 ? 'badge bg-success' : 'badge bg-danger')}>{(row.original.EsActivo == 1 ? "Activo" : "Inactivo")}</span>
    }
  }
  ];

export function GetListasDistribucion(ClienteId: string) {
  var params: { [id: string]: string | null; } = { ClienteId };
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "PREOPQueryHelper",
    NombreConsulta: "GetListaDistribucionPorCliente",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function GestionListaDistribucion(Data: string) {
  var params: { [id: string]: string | null; } = { Data };
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "PREOPQueryHelper",
    NombreConsulta: "GestionListaDistribucion",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};


