
import { useDataUsuarios } from "../../../core/usuariosProvider";
import { Check, Clear } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap-v5";
import { ComponenteClientes, generarContrasena, PerfilesSelect, validarEmail } from "../../../data/usuariosdata";
import { Checkbox } from "rsuite";

export const ECO_Usuario_InformacionSeguridad: React.FC = () => {
    const { usuarioSel, setUsuarioSel, lstClientes, lstPerfiles } = useDataUsuarios();
    const [mailValid, setMailValid] = useState<boolean | null>(!usuarioSel.isNew);
    const [verPwd, setverPwd] = useState<boolean>(false);
    const [passwordValid, setpasswordValid] = useState<any>();
    useEffect(()=>{
        let u = { ...usuarioSel };       
        u.Password = generarContrasena(8);
        u.ConfirmaPassword =  u.Password;
        setUsuarioSel(u);

    }, []);
    const setClienteUsuario = (e: any) => {
        let u = { ...usuarioSel };
        u.ClienteId = e.currentTarget.value;
        u.ClienteNombre = lstClientes.filter(f => f.ClienteId == e.currentTarget.value)[0].clienteNombre;       
        setUsuarioSel(u);
    }

    return (<div className="border rounded row mb-2 bg-secondary text-capitalize">
        <span style={{ width: '100%', textAlign: 'center' }} className="fs-2 fw-bold text-syscaf-amarillo">Información Seguridad</span>
        <hr className="m-1" style={{ width: '95%', textAlign: 'center' }} />
        <Form className="m-2 " style={{ width: '100%' }} >
            <Row  >
                <Col sm="6">
                    <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                        <Form.Label column sm="4" >Correo:</Form.Label>
                        <Col sm="8">
                            <InputGroup>
                                <Form.Control placeholder="email@domain.com" autoComplete="off"
                                    type="email" value={usuarioSel?.Correo} onChange={(e) => {
                                        const isValid = validarEmail(e.currentTarget.value)
                                        setMailValid(isValid);
                                        let usr = { ...usuarioSel };
                                        usr.Correo = e.currentTarget.value;
                                        setUsuarioSel(usr);
                                    }} />
                                {!mailValid ? (<Clear className="mt-4 mx-2" color="error" />) : (<Check className="mt-4 mx-2" color="success" />)}
                            </InputGroup>


                        </Col>
                    </Form.Group>

                </Col>
                <Col sm="6" >
                    <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                        <Checkbox color="white" checked={usuarioSel.esDummy}
                            onChange={(e: any, checked: boolean) => {
                                let usr = { ...usuarioSel };
                                usr.esDummy = checked;
                                setUsuarioSel(usr);
                            }}> <span>es Generico?</span></Checkbox>
                    </Form.Group>

                </Col>
            </Row>
            <Row  >
                <Col sm="6">
                    <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                        <Form.Label column sm="4" >Perfil:</Form.Label>
                        <Col sm="8">
                            <PerfilesSelect Usuario={usuarioSel} Perfiles={lstPerfiles} setUsuario={setUsuarioSel} />
                        </Col>
                    </Form.Group>

                </Col>
                <Col sm="6" hidden={usuarioSel.PerfilId !== 119}>
                    <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                        <Form.Label column sm="4" >Cliente:</Form.Label>
                        <Col sm="8">
                            <ComponenteClientes ClienteId={usuarioSel.ClienteId} Clientes={lstClientes} callBack={setClienteUsuario} />
                        </Col>
                    </Form.Group>

                </Col>
            </Row>
            <Row  >
                <Col sm="6">
                    <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                        <Checkbox checked={usuarioSel[(usuarioSel.isNew) ? 'AsignarContrasenia' : 'UpdatePassword']}
                            onChange={(e: any, checked: boolean) => {
                                let usr = { ...usuarioSel };
                                usr[(usuarioSel.isNew) ? 'AsignarContrasenia' : 'UpdatePassword'] = checked;
                                setUsuarioSel(usr);
                            }}>
                            {usuarioSel.isNew ? 'Asignar' : 'Cambiar'} Contraseña
                        </Checkbox>
                    </Form.Group>

                </Col>
                <Col sm="6" >
                <Form.Group hidden={!(usuarioSel.AsignarContrasenia || usuarioSel.UpdatePassword)} as={Row} className="mb-3 " controlId="formBasicEmail">
                        <Checkbox checked={verPwd}
                            onChange={(e: any, checked: boolean) => {                              
                                setverPwd(checked);
                            }}>
                            Ver Contraseñas
                        </Checkbox>
                    </Form.Group>
                    <Form.Group hidden={(usuarioSel.AsignarContrasenia || usuarioSel.UpdatePassword)} as={Row} className="mb-3 " controlId="formBasicEmail">
                    <Form.Label column sm="4" >Generada Sistema:</Form.Label>
                    <Form.Label className="text-muted" column sm="8" >{usuarioSel.Password}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row hidden={!(usuarioSel.AsignarContrasenia || usuarioSel.UpdatePassword)} >
                <Col sm="6">
                    <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                        <Form.Label column sm="4" >Constraseña:</Form.Label>
                        <Col sm="8">
                            <InputGroup>
                                <Form.Control autoComplete="new-password" id="contrassenia"
                                    type={`${verPwd ? 'text' :'password' }`} value={usuarioSel?.Password} onChange={(e) => {
                                        const valor: string = e.currentTarget.value;
                                        let usr = { ...usuarioSel };
                                        usr.Password = valor;
                                        setUsuarioSel(usr);
                                        setpasswordValid({ ...passwordValid, pws: (valor.length >= 8 && valor.length <= 20) });
                                    }} />
                                {passwordValid !== undefined && (!passwordValid.pws ? (<Clear className="mt-4 mx-2" color="error" />) : (<Check className="mt-4 mx-2" color="success" />))}
                            </InputGroup>
                        </Col>
                    </Form.Group>

                </Col>
                <Col sm="6" >
                    <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                        <Form.Label column sm="4" >Confirmar Constraseña:</Form.Label>
                        <Col sm="8">
                            <InputGroup>
                                <Form.Control autoComplete="new-password" id="confirmar"
                                    type={`${verPwd ? 'text' :'password' }`} value={usuarioSel?.ConfirmaPassword} onChange={(e) => {
                                        const valor: string = e.currentTarget.value;
                                        let usr = { ...usuarioSel };
                                        usr.ConfirmaPassword = valor;
                                        setUsuarioSel(usr);


                                        setpasswordValid({ ...passwordValid, pws2: (usr.Password === valor) });
                                    }} />
                                {passwordValid !== undefined && (!passwordValid.pws2 ? (<Clear className="mt-4 mx-2" color="error" />) : (<Check className="mt-4 mx-2" color="success" />))}
                            </InputGroup>
                        </Col>
                    </Form.Group>

                </Col>
            </Row>
        </Form>


    </div>)

}