import { MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import { FormatoColombiaDDMMYYYHHmm, FormatoColombiaDDMMYYYHHmmss } from "../../../../../_start/helpers/Constants";
import { DeserializarJSon, GetFechaServidor, msToTimeSeconds } from "../../../../../_start/helpers/Helper";
import { TabProperty } from "../../../../../_start/helpers/Models/Tabs";
import { getCSSVariableValue } from "../../../../../_start/assets/ts/_utils";
import { ChartConfiguration } from "chart.js";
import { DonaUsers } from "../components/Historial/Indicadores/Dona";
import { Button } from "@mui/material";
import { Post_GetConsultasDinamicasUserCore } from "../../../../../_start/helpers/Axios/DWHService";
import { FiltroHistorial } from "./consultasGenerales";


export const ECO_Tooltips = {
    "ErrorConsultar": { title: "Consultar Datos", content: "Error al consultar datos favor vuelva a consultar." },
    "ClienteDetalle": {
        title: "Detalle Usuarios Cliente",
        content: ["Muestra el detalle de la siguiente manera: En rojo mostrará aquellos que sus ususarios inactivos superen el 66% ",
            "respecto al total de usuarios registrados, Amarillos entre el [66% - 33%] y verde inferiores a éste."].join()
    },
    "UsuariosRegistrados": {
        title: "Detalle Usuarios Registrados",
        content: ["Muestra el detalle de la siguiente manera: En rojo mostrará aquellos usuarios activos que superen más de 31 días de inactividad o no tengan actividad ",
            "Amarillo aquellos entre [10-31] días, Verde entre [0 - 10] días"].join()
    },
    "Historial": { title: "Consultar historial usuario", content: "Permite tener el listado de los ingresos al portal, ver indicadores que permitan tomar desiciones administrativas." }
}

const tab1: TabProperty = {
    icon: "/media/svg/logo/gray/kanba.svg", iconColored: "/media/svg/logo/colored/kanba.svg",
    titulo: "Detalle Ingresos", subtitulo: "Inicio Session"
}
const tab2: TabProperty = {
    icon: "/media/svg/logo/gray/tower.svg", iconColored: "/media/svg/logo/colored/tower.svg",
    titulo: "Detalle Usuarios", subtitulo: "Sistema"
}
export const listTabsECO: TabProperty[] = [tab1, tab2]


export const ECO_camposTablausuarios: MRT_ColumnDef<any>[] =

    [
        {
            accessorKey: 'Id',
            header: 'Id Usuario'
        },
        {
            accessorKey: 'Nombres',
            header: "Nombres"
        },
        {
            accessorKey: 'username',
            header: "Usuario"
        }

        , {
            accessorKey: 'ClienteNombre',
            header: "Cliente"
        }, {
            accessorKey: 'Perfil',
            header: "Perfil"
        }, {
            accessorKey: 'dias',
            header: "Días Último Ingreso",

            Cell({ cell, column, row, table, }) {
                const segundos = GetFechaServidor().diff(moment(row.original.UltimoIngreso), 'seconds');

                return row.original.dias != null ? row.original.dias <= 1 ? msToTimeSeconds(segundos)
                    : row.original.dias : <span key={`eco_usurio_na_${row.original.Id}`}> N/A</span>;
            }
        }, {
            accessorKey: 'UltimoIngreso',
            header: "Último Ingreso",
            Cell({ cell, column, row, table, }) {
                return row.original.UltimoIngreso ? moment(row.original.UltimoIngreso).format(FormatoColombiaDDMMYYYHHmm)
                    : <span key={`eco_usurio_siningreso_${row.original.Id}`}>  Sin Ingreso </span>;
            }
        }

    ];


export const ECO_camposusuarios: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'UserId',
            header: 'Id Usuario'
        },
        {
            accessorKey: 'Nombres',
            header: "Nombres"
        }
        , {
            accessorKey: 'StartDate',
            header: 'Inicio',

            Cell({ cell, column, row, table, }) {
                return moment(row.original.StartDate).format(FormatoColombiaDDMMYYYHHmmss);
            }
        }, {
            accessorKey: 'EndDate',
            header: 'Fin',

            Cell({ cell, column, row, table, }) {
                return moment(row.original.EndDate).format(FormatoColombiaDDMMYYYHHmmss);
            }
        }, {
            accessorKey: 'Duracion',
            header: 'Duracion',
            Cell({ cell, column, row, table, }) {
                return msToTimeSeconds(row.original.Duracion);
            }
        }, {
            accessorKey: 'NombreCliente',
            header: "Cliente"
        }, {
            accessorKey: 'Perfil',
            header: "Perfil"
        },
        {

            header: "Ultimo Ingreso",
            size: 300,
            Cell({ cell, column, row, table, }) {

                const m: any[] = DeserializarJSon(row.original.Ingresos);
                const agrupado = FiltroHistorial.AgruparUltimosAccesoPorModulo(m);
                return (agrupado.length > 0 ? <table key={`ultimoingresoshistorial${row.original.UserId}`}
                    className="table table-bordered table-striped">
                    <thead key={`encabezadohead${row.original.UserId}_0}`} >
                        <tr key={`encabezado${row.original.UserId}_0`}>
                            {
                                agrupado[0].map((mm: string) => {
                                    return (<th scope="col" className="fw-bold "  >{mm.toLocaleUpperCase()}</th>)
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            agrupado.map((m: string[], ind: number) => {
                                if (ind !== 0)
                                    return (<tr key={`detalle${row.original.UserId}${ind}`}>
                                        {
                                            m.map((mm) => {
                                                return (<td className="fs-8">{mm}</td>)
                                            })
                                        }
                                    </tr>)

                                    return ('')
                            })}
                    </tbody>
                </table> : '')
            }
        }

    ];
;



export function GetListadoUsuario() {
    var params: { [id: string]: string | null | undefined; } = {};
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetListadoUsuario", Clase: "PORTALQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};
export function GetClientesAdministradores() {
    var params: { [id: string]: string | null | undefined; } = {};
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetClientesAdministradores", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};



export function HistorialUsuarios(FechaInicial: string, FechaFinal: string
) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['FechaInicial'] = FechaInicial;
    params['FechaFinal'] = FechaFinal;
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetLogUsuarioByFechas", Clase: "PORTALQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};


export function getChartOptions(data: any[] = []) {
    const tooltipBgColor = getCSSVariableValue("--bs-gray-200");
    const tooltipColor = getCSSVariableValue("--bs-gray-800");

    const color1 = getCSSVariableValue("--bs-success");
    const color2 = getCSSVariableValue("--bs-danger");
 //   const color3 = getCSSVariableValue("--bs-primary");

    const options: ChartConfiguration = {
        type: "pie",
        data: {
            datasets: [
                {
                    data: data,
                    backgroundColor: [color2, color1],
                },
            ],
            labels: ["Inactivos", "Activos"],
        },
        options: {
            cutoutPercentage: 75,
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                position: "right",
            },
            title: {
                display: false,
                text: "Technology",
            },
            animation: {
                animateScale: true,
                animateRotate: true,
            },
            tooltips: {
                enabled: true,
                intersect: false,
                mode: "nearest",
                bodySpacing: 5,
                yPadding: 10,
                xPadding: 10,
                caretPadding: 0,
                displayColors: false,
                backgroundColor: tooltipBgColor,
                bodyFontColor: tooltipColor,
                cornerRadius: 4,
                footerSpacing: 0,
                titleSpacing: 0,
            },
        },
    };
    return options;
}




export const ECO_camposTablaResumidoUsuarios = (callbackLinks: any) => {

    const columnas: MRT_ColumnDef<any>[] =
        [
            {
                accessorKey: 'Cliente',
                header: "Cliente"
            },
            {
                accessorKey: 'Total',
                header: "Total",
                Cell({ cell, column, row, table, }) {
                    return (
                        <Button
                            onClick={() => { callbackLinks(row.original.Cliente, 1) }}>
                            {cell.getValue()}
                        </Button>
                    )
                }
            }

            , {
                accessorKey: 'Activos',
                header: "Activos",
                Cell({ cell, column, row, table, }) {
                    return (
                        <Button
                            onClick={() => { callbackLinks(row.original.Cliente, 2) }}>
                            {cell.getValue()}
                        </Button>
                    )
                }
            }, {
                accessorKey: 'Inactivos',
                header: "Inactivos",
                Cell({ cell, column, row, table, }) {
                    return (
                        <Button
                            onClick={() => { callbackLinks(row.original.Cliente, 3) }}>
                            {cell.getValue()}
                        </Button>
                    )
                }
            }
            , {

                header: "Distribucion",
                Cell({ cell, column, row, table, }) {
                    const m: any = row.original;
                    return (<DonaUsers config={{ width: 80, height: 80 }} series={[m.Inactivos, m.Activos]} labels={['Inactivos', 'Activos']}
                        nameChart={`eco-tabla-${m.Cliente}`} titulo='' />
                    )
                }
            },
            {
                accessorKey: 'Categoria',
                header: "Categoria",
                enableHiding: false
            }

        ];
    return columnas
}



export const ECO_camposDetalladoUsuario: MRT_ColumnDef<any>[] =

    [
        {
            accessorKey: 'Nombres',
            header: "Usuario"
        }, {
            accessorKey: 'username',
            header: "UserName"
        },
        {
            accessorKey: 'dias',
            header: "dias",
            Cell({ cell, column, row, table, }) {
                const segundos = GetFechaServidor().diff(moment(row.original.UltimoIngreso), 'seconds');
                return row.original.dias != null ? row.original.dias <= 1 ? msToTimeSeconds(segundos) : row.original.dias : <span> N/A</span>;
            }
        },
        {
            accessorKey: 'UltimoIngreso',
            header: "Ultimo Ingreso",
            Cell({ cell, column, row, table, }) {
                return row.original.UltimoIngreso === null ? 'Sin Actividad' : moment(row.original.UltimoIngreso).format(FormatoColombiaDDMMYYYHHmmss);
            }
        }
        // , {
        //     accessorKey: 'Ingresos',
        //     header: "Modulos",
        //     Cell({ cell, column, row, table, }) {
        //         const m: any = row.original;
        //         return (<DonaUsers config={{ width: 80, height: 80 }} series={[m.Inactivos, m.Activos]} labels={['Inactivos', 'Activos']}
        //             nameChart={`eco-tabla-${m.Cliente}`} titulo='' />
        //         )
        //     }
        // }

    ];


export const ECO_camposTablaResumidoModulos = (callbackLinks: any) => {

    const columnas: MRT_ColumnDef<any>[] =
        [
            {
                accessorKey: 'Modulo',
                header: "Modulo"
            },
            {
                accessorKey: 'TotalUsuarios',
                header: "Total Usuarios",
                Cell({ cell, column, row, table, }) {
                    return (
                        <Button
                            onClick={() => { callbackLinks(row.original.Detallado) }}>
                            {cell.getValue()}
                        </Button>
                    )
                }
            }
            , {
                accessorKey: 'TotalOpciones',
                header: "Total Opciones"
            }
            , {

                header: "Total Accesos",
                Cell({ cell, column, row, table, }) {
                    const m: any[] = row.original.LabelsOpciones;
                    return (<div key={`eco_consolidado_modulo_${row.original.Modulo}`} className="row col-12 overflow-auto h-80 w-80" >
                        {m.map(m => {
                            return (<span key={`eco_consolidado_modulo_${row.original.Modulo}${m[0]}`}>{`${m[0]}(${m[1]})`}</span>)
                        })}
                    </div>)
                }
            }

        ];
    return columnas
}


export const ECO_ModulosDetalladoUsuario: MRT_ColumnDef<any>[] =

    [
        {
            accessorKey: 'Nombres',
            header: "Nombres"
        },
        {
            accessorKey: 'Date',
            header: "Ultimo Ingreso",
            size: 300,
            Cell({ cell, column, row, table, }) {
                const m: any[] = row.original.UltimosIngresos;
                return (<div key={`ultimoingresousuariomodulos${row.original.Nombres}`} className="row col-12 overflow-auto h-80 w-200" > {m.map(m => {
                    return (<span key={`eco_ultimo_${m[0]}`}>{`${m[0]}(${moment(m[1]).format(FormatoColombiaDDMMYYYHHmmss)})`}</span>)
                })}
                </div>)
            }
        }
        , {

            header: "Total Accesos",
            Cell({ cell, column, row, table, }) {
                const m: any[] = row.original.LabelsOpciones;
                return (<div key={`detalladousuariomodulos${row.original.Nombres}`} className="row col-12 overflow-auto h-80 w-80" > {
                    m.map(m => {
                        return (<span key={`eco_totalacceso_${m[0]}`}>{`${m[0]}(${m[1]})`}</span>)
                    })}
                </div>)
            }
        }

    ];

