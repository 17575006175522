// APP NEPTUNO
import Neptuno from "../../modules/SYSCAF/Neptuno";
import NeptunoST from "../../modules/SYSCAF/Neptuno/indexTree";
import Logs from "../../modules/SYSCAF/Neptuno/log";

// APP SEÑALES
import Reporte from "../../modules/SYSCAF/Senial/components/Reporte";
import Condiciones from "../../modules/SYSCAF/Senial/components/Condiciones";

// TRANSMISION
import Dashboard from "../../modules/SYSCAF/Tx/Dashboard";
import Reportes from "../../modules/SYSCAF/Tx/Reportes";
import EstadosTx from "../../modules/SYSCAF/Tx/Estados";
import ConfiguracionCorreos from "../../modules/SYSCAF/Tx/configuracionCorreosTx";

// APP IMG 
import ErroresViajesyUso from "../../modules/MODULOS/Img/ErroresViajesyUso";

// APP CLIENTES
import Clientes from "../../modules/Seguridad/Administracion/components/Clientes";

//APP POSTVENTA
import HomePostVenta from "../../modules/SYSCAF/PostVenta/components/DashBoard/Dashboard";
import Parametrizacion from "../../modules/SYSCAF/PostVenta/components/Parametrizacion/Parametrizacion";
import Creacion from "../../modules/SYSCAF/PostVenta/components/Requerimientos/Creacion";
import Informe from "../../modules/SYSCAF/PostVenta/components/Requerimientos/Informe";
//Configuraciones
import GruposSeguridad from "../../modules/Seguridad/Administracion/components/GruposSeguridad";
//Insights
import Insights from "../../modules/SYSCAF/Insights/components/Exclusion";
//Declaraciones
export let ListComponents : any [] = [];

// APP NEPTUNO
ListComponents.push( { Component: Neptuno, url : "/neptuno/archivos" })
ListComponents.push( { Component: NeptunoST, url : "/neptuno/archivosServicioTecnico" })
ListComponents.push( { Component: Logs, url : "/neptuno/log" })
// APP SEÑALES
ListComponents.push( { Component: Reporte, url : "/soporte/senial/reporte" })
ListComponents.push( { Component: Condiciones, url : "/soporte/senial/condiciones" })
// TRANSMISION
ListComponents.push( { Component: Dashboard, url : "/soporte/tx/dashboard" })
ListComponents.push( { Component: Reportes, url : "/soporte/tx/reporte" })
ListComponents.push( { Component: EstadosTx, url : "/soporte/tx/Estados" })
ListComponents.push( { Component: ConfiguracionCorreos, url : "/soporte/tx/correos" })
// APP IMG 
ListComponents.push( { Component: ErroresViajesyUso, url : "/soporte/img/reporte" })
// APP CLIENTES
ListComponents.push( { Component: Clientes, url : "/soporte/admin/clientes" })
//APP POSTVENTA
ListComponents.push( { Component: HomePostVenta, url : "/gos/dashborad" })
ListComponents.push( { Component: Parametrizacion, url : "/gos/parametrizacion" })
ListComponents.push( { Component: Creacion, url : "/gos/requerimientos" })
ListComponents.push( { Component: Informe, url : "/gos/informe" })
//Configuraciones
ListComponents.push( { Component: GruposSeguridad, url : "/soporte/admin/GruposSeguridad" })
//Insights
ListComponents.push( { Component: Insights, url : "/Insights/Exclusion" })


