
import { PageTitle } from "../../../../_start/layout/core";
import { ConfiguracionProvider } from "./core/ConfiguracionCore";
import ConfiguracionPrincipal from "./components/Configuraciones/Configuracion";


export default function Configuracion() {
    return (
    <>
      <PageTitle>Configuración preoperacionales</PageTitle>
    <ConfiguracionProvider>
        <ConfiguracionPrincipal/>
    </ConfiguracionProvider>
    </>)
}