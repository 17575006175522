import { useEffect } from "react";
import { useDataConfiguracion } from "../../core/ConfiguracionCore";
import { AxiosError, AxiosResponse } from "axios";
import { getClientes, GetClientesPorModulo, getPlantillas } from "../../data/dataConfiguracion";
import { ModalEventoMTO } from "./Components/ModaEventoMTO";
import { ModalusuarioCargo } from "./Components/ModalusuarioCargo";
import { Preguntas } from "./Components/Preguntas";
import { Plantillas } from "./Components/Plantillas";
import { GetUser } from "../../../../auth/data/datPerfil";
import { useToaster } from "rsuite";
import { MensajeError } from "../../../../../../_start/helpers/components/Toaster";
import { consultarUsuario } from "./Components/ComponenteCliente";
import { PREOP_PanelGeneral } from "./Components/PanelGeneral";
import { PREOP_ConfigAssets } from "./Components/AsociacionAssets";
import { PREOP_ListaDistribucion } from "./Components/ListasDistribucion";

export default function ConfiguracionPrincipal() {
    const toaster = useToaster();
    //INICIO ESPACIO CONSTANTES
    const { setlstUsuarios, Clientes, showObject, setlstPlantillas, setUsuarios, setClientes, setdatosPreoperacional, clienteSeleccionado, setloader } = useDataConfiguracion();
    //Componentes del los clientes
    useEffect(() => {

        GetClientesPorModulo().then((response: AxiosResponse<any>) => {
            if (response.status === 200) {
                // asignamos los datos a el user state para que se visualicen los datos
                setdatosPreoperacional(response.data);
            }
            else
                toaster.push(MensajeError("Error Consultar Usuarios", 'Error al consultar las Usuarios'), {
                    placement: 'topCenter'
                });

        }).catch(() => {
            toaster.push(MensajeError("Error Consultar Usuarios", 'Error al consultar las Usuarios'), {
                placement: 'topCenter'
            });
        })

        GetUser(null, null).then((response: AxiosResponse<any>) => {
            if (response.status == 200) {
                // asignamos los datos a el user state para que se visualicen los datos
                setlstUsuarios(response.data);
            }
            else
                toaster.push(MensajeError("Error Consultar Usuarios", 'Error al consultar las Usuarios'), {
                    placement: 'topCenter'
                });

        }).catch(() => {
            toaster.push(MensajeError("Error Consultar Usuarios", 'Error al consultar las Usuarios'), {
                placement: 'topCenter'
            });
        }).finally(() => {

        })


    }, [])

    useEffect(() => {
        if (clienteSeleccionado !== undefined && clienteSeleccionado.ClienteIds !== "0") {
            consultarUsuario(clienteSeleccionado, setUsuarios);
            ConsultarClientesDatos();
        }

    }, [clienteSeleccionado])



    const ConsultarClientesDatos = () => {
        setloader(true)
        getClientes(String(clienteSeleccionado?.ClienteIds)).then((response: AxiosResponse<any>) => {
            setClientes(response.data);
        }).catch((error: AxiosError<any>) => {
            toaster.push(MensajeError("Error Consultar Plantillas", 'Error al consultar el dato de los clientes , ' + error.message), {
                placement: 'topCenter'
            });
        }).finally(()=>{setloader(false)});
    }

    useEffect(() => {
        if (clienteSeleccionado !== undefined && clienteSeleccionado.ClienteIds !== "0")
            ConsultarPlantillas();
    }, [clienteSeleccionado])
    const ConsultarPlantillas = () => {
        getPlantillas((clienteSeleccionado?.ClienteId == undefined ? null : clienteSeleccionado?.ClienteId)).then((response: AxiosResponse<any>) => {
            setlstPlantillas(response.data);
        }).catch((error: AxiosError<any>) => {
            toaster.push(MensajeError("Error Consultar Plantillas", 'Error al consultar las Plantillas, ' + error.message), {
                placement: 'topCenter'
            });
        });
    }

    return (
        <>
            {/* Panel de los botones     */}
            {(showObject.panelCentral) && (<PREOP_PanelGeneral />)}
            {(showObject.plantillas) && (<Plantillas />)}
            {(showObject.preguntas) && (<Preguntas />)}

            {(showObject.configuracion) && (Clientes) && (<ModalEventoMTO />)}
            {(showObject.usuarios) && (<ModalusuarioCargo />)}
            {(showObject.assets) && (<PREOP_ConfigAssets />)}
            {(showObject.listas) && (<PREOP_ListaDistribucion />)}


        </>
    )
}