
import { Post_GetConsultasDinamicasUserCore } from "../../../../../../_start/helpers/Axios/DWHService";

export function AddProgramacionTracking (Data: string){
   // llamamos la informacion de los perfiles
   var params: { [id: string]: string | null; } = {};
   params["Data"] = Data;
  return  Post_GetConsultasDinamicasUserCore({ Clase: "TrackQueryHelper", NombreConsulta: "AddProgramacionTracking", 
       Pagina: 1, RecordsPorPagina: 100 },
       params)
}
  
export function UpdateProgramacionTracking (Data: string){
    // llamamos la informacion de los perfiles
    var params: { [id: string]: string | null; } = {};
    params["Data"] = Data;
   return  Post_GetConsultasDinamicasUserCore({ Clase: "TrackQueryHelper", NombreConsulta: "UpdateProgramacionTracking", 
        Pagina: 1, RecordsPorPagina: 100 },
        params)
 }
     
 
