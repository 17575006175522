import axios from "axios";
import moment from "moment";

import { DWH_getconsultadinamicasprocedure, urlFatigueEnviarInforme, urlFatigueInforme } from "../../../../../apiurlstore";
import { ParamsEndPointDynamic } from "../../../../../_start/helpers/Models/paramsConsultasDinamicas";
import { Post_GetConsultasDinamicasUserDWH, Post_getDynamicValueProcedureDWHTabla } from "../../../../../_start/helpers/Axios/DWHService";
import { locateFormatNumberNDijitos, locateFormatPercentNDijitos } from "../../../../../_start/helpers/Helper";
import { MRT_ColumnDef } from "material-react-table";
import { NotificacionSyscaf } from "../../../../../_start/helpers/components/NotificacionSonora";
import Swal from "sweetalert2";
import { FormatoColombiaDDMMYYYHHmmss } from "../../../../../_start/helpers/Constants";


export const informedefault = {
  "Evento": "",
  "Hora": "",
  "Operacion": "",
  "Ruta": "ruta 1,ruta 2,ruta 3",
  "Placa": "",
  "Conductor": "",
  "Contacto": "",
  "Motivo": "Incidente",
  "Ubicacion": "",
  "Coordenadas": "",
  "Observacion": "",
  "Imagen": "",
  "Video": "",
}

export function getEventosActivosPorDia(props: ParamsEndPointDynamic, body: any) {
  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedure,
    data: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}

// obtine los vehiculos operando en el sistema
//MOVQueryHelper
//GetEventosSubtrips
export function getVehiculosOperando(ClienteIds: string, Fecha: Date) {

  var params: { [id: string]: string | null; } = {};
  params["ClienteIds"] = ClienteIds;
  params["Fecha"] = moment(Fecha).format("YYYYMMDD");

  
  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedure,
    data: JSON.stringify(params),
    headers: { 'Content-Type': 'application/json' },
    params: { Clase: "FATGQueryHelper", NombreConsulta: "GetVehiculosOperacion" }
  });
}

export function GetClientesFatiga() {
  var params: { [id: string]: string | null; } = {};
  return Post_GetConsultasDinamicasUserDWH({
    Clase: "FATGQueryHelper", NombreConsulta: "GetClientesFatiga",
    Pagina: null,
    RecordsPorPagina: null
  }, params);
}

//GetClientesFatiga


export function getClienteFatiguePorUsuario(body: any) {
  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedure,
    data: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    params: { Clase: "FATGQueryHelper", NombreConsulta: "GetClienteFatiguePorUsuario" }
  });
}

export function getAlertas(clienteIds: string) {
  var params: { [id: string]: string | null; } = {};
  params["clienteIds"] = clienteIds.toString();
  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedure,
    data: JSON.stringify(params),
    headers: { 'Content-Type': 'application/json' },
    params: { Clase: "FATGQueryHelper", NombreConsulta: "getAlertas" }
  });
}

export function setGestor(clienteIds: string, Observaciones: string, EstadoGestion: boolean, alertaId: number, gestor: string, tiempoCierreAuto: string) {
  var params: { [id: string]: string | null; } = {};
  params["clienteIds"] = clienteIds;
  params["Observaciones"] = Observaciones;
  params["EsGestionado"] = EstadoGestion.toString();
  params["AlertaId"] = alertaId.toString();
  params["gestor"] = gestor;
  params["tiempoCierreAuto"] = tiempoCierreAuto;

  // hacemos la consulta 
  return Post_GetConsultasDinamicasUserDWH({
    Clase: "FATGQueryHelper",
    NombreConsulta: "setGestor",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function setObservaciones(clienteIds: string, Observaciones: string, tiempoCierreAuto: string) {
  var params: { [id: string]: string | null; } = {};
  params["clienteIds"] = clienteIds;
  params["data"] = Observaciones;
  params["tiempoCierreAuto"] = tiempoCierreAuto;
  // hacemos la consulta 
  return Post_GetConsultasDinamicasUserDWH({
    Clase: "FATGQueryHelper",
    NombreConsulta: "setObervaciones",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function GetAlarmas(clientesIds: string, FechaInicio: Date, FechaFinal: Date) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['FechaInicial'] = moment(FechaInicio).format("YYYY-MM-DD HH:mm");
  params['FechaFinal'] = moment(FechaFinal).format("YYYY-MM-DD HH:mm");
  return Post_getDynamicValueProcedureDWHTabla({
    NombreConsulta: "GetAlertasTimeLine", Clase: "FATGQueryHelper",
    tabla: clientesIds
  }, params);
}

export function GetDetalladoEventos(clientesIds: string, FechaInicio: Date) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['Fecha'] = moment(FechaInicio).format("YYYYMMDD");
  return Post_getDynamicValueProcedureDWHTabla({
    NombreConsulta: "GetDetalladosAlertas", Clase: "FATGQueryHelper",
    tabla: clientesIds
  }, params);
}

export function getAlertasHistorical(fechaInicial: string, fechaFinal: string, clienteIdS: string) {
  var params: { [id: string]: string | null; } = {};
  params["FechaInicial"] = fechaInicial;
  params["FechaFinal"] = fechaFinal;
  params["clienteIdS"] = clienteIdS;
  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedure,
    data: JSON.stringify(params),
    headers: { 'Content-Type': 'application/json' },
    params: { Clase: "FATGQueryHelper", NombreConsulta: "getAlertasHistoricas" }
  });
}

export function getIndicadoresHistorical(fechaInicial: string, fechaFinal: string, clienteIdS: string) {
  var params: { [id: string]: string | null; } = {};
  params["FechaInicial"] = fechaInicial;
  params["FechaFinal"] = fechaFinal;
  params["ClienteIdS"] = clienteIdS;
  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedure,
    data: JSON.stringify(params),
    headers: { 'Content-Type': 'application/json' },
    params: { Clase: "FATGQueryHelper", NombreConsulta: "GetIndicadoresPorCliente" }
  });
}

export function setNotificaciones(clienteIds: string, Notificacion: string, AlertaId: string) {
  var params: { [id: string]: string | null; } = {};
  params["clienteIds"] = clienteIds;
  params["Notificacion"] = Notificacion;
  params["AlertaId"] = AlertaId;
  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedure,
    data: JSON.stringify(params),
    headers: { 'Content-Type': 'application/json' },
    params: { Clase: "FATGQueryHelper", NombreConsulta: "setNotificaciones" }
  });
}
export function GetAlertasPorUsuarios(clienteIds: string) {
  var params: { [id: string]: string | null; } = {};
  params["clienteIds"] = clienteIds.toString();
  return Post_GetConsultasDinamicasUserDWH({
    Clase: "FATGQueryHelper", NombreConsulta: "getAlertasProcedimiento",
    Pagina: null,
    RecordsPorPagina: null
  }, params);
}

// export function GetParametrosClientesCliente(ClienteIds:any) {
//   return GetParametros(null,ClienteIds).then((response:any) =>{
//     return response.data;
//   }).catch((error:any) =>{
//     return error.data;
//   });
// }

export const FiltroDataAlerta = {
  getAgrupadoPorAlerta: (alertas: any[], clienteIdS: string) => {
    // contabilizamos las nuevas alertas

    return alertas.reduce((prev, next) => {
      const criticidad = next.Criticidad;
      const AlertaId = next.AlertaId;
      const TipoAlerta = next.TipoAlerta;
      const vehiculo = next.vehiculo;
      const conductor = next.conductor;
      const Sonido = next.Sonido; // validamos que deba sonar al momento de hacer notificación
      // solo ingresa aquellos no gestionados
      if (next.EstadoGestion == null) {
        prev[criticidad] = prev[criticidad] ?? [];
        prev[criticidad].push({ clienteIdS, AlertaId, TipoAlerta, vehiculo, conductor, Sonido });
      }
      return prev;

    }, {});
  },
  getSinNotificar: (consolidadoAct: any, consolidadoAnt: any, clienteIdS: string) => {
    // si no tiene el anterior, quiere decir que apenas est'a iniciando el sistema
    // se le debe notificar todo lo que este llegando de manera consolidada
    if (Object.keys(consolidadoAnt).length == 0)
      return { tipo: "INFO", consolidado: consolidadoAct };

    // verifica si ha cambiado de cliente, si lo ha hecho  notifica como si ingresara la primera vez
    const clienteids = (((Object.entries(consolidadoAnt)[0])[1] as any)[0] as any).clienteIdS;
    if (clienteids != clienteIdS)
      return { tipo: "INFO", consolidado: consolidadoAct };

    // se compara la informacion de ambos consolidados para verificar las entradas
    let consolidadoNotificar: any = {}
    Object.entries(consolidadoAct).forEach((f) => {
      // primero verificamos que exista el dato en el consolidado anterior
      // variables  del array actual
      const key = (f[0] as any);
      const value = (f[1] as any[]);
      // variables dle array anterior
      let arrayNotificar: any[] = [];
      if (consolidadoAnt[key] != undefined) {
        const valueAnt = (consolidadoAnt[key] as any[]);
        // validamdos que tengan totales diferentes
        // si lo son, revisamos cuales no esten contenidos en el array nuevo
        if (value.length != valueAnt.length) {
          value.forEach((v) => {
            // si no esta contenido, lo agregamos
            if (valueAnt.filter(f => f.AlertaId == v.AlertaId).length == 0) {
              arrayNotificar.push(v);
            }

          });

          if (arrayNotificar.length > 0)
            consolidadoNotificar[key] = arrayNotificar;
        }
      }
      else
        consolidadoNotificar[key] = value;

    });

    return { tipo: "NUEVOS", consolidado: consolidadoNotificar };

  }
}
// crea las notificaciones para ser mostradas en el navegador
export function NotificarCambios(data: any, toaster: any) {
  if (data != undefined && data != null)
    Object.entries(data.consolidado).forEach((m, index) => {
      // validamos entre todo el contenido de la data
      // si al menos alguno tiene marcado como sonar .
      let tieneSonido: boolean = false;
      (m[1] as any[]).forEach(f => { if (f.Sonido) { tieneSonido = true; return; } })

      let titulo = TituloNotificacion(data.tipo, (m[0] as any));
      let body: React.ReactNode = "";
      switch (data.tipo) {
        case "INFO":
          body = `Hasta el momento tiene (${(m[1] as any[]).length}) sin gestionar.`;
          break;
        case "NUEVOS":
          body = `Tiene (${(m[1] as any[]).length}) nuevas alertas.`;
          break;
      }

      toaster.push(NotificacionSyscaf({
        includeSonido: tieneSonido, duration: 10000, closable: true,
        type: TiposRiesgoColor[m[0]], content: body, header: titulo
      }), {
        placement: 'topEnd'
      });

    })

}
// segun el tipo  se muestra un titulo diferente y un mensaje diferente
const TituloNotificacion = (tipo: string, mensaje: string) => {
  switch (tipo) {
    case "INFO": return `Notificación alertas de ${mensaje}`
    case "NUEVOS": return `Nuevas alertas de ${mensaje}`;
  }
  return "";
}





// categorizamos tipo de riesto
export enum TiposRiesgo {

  "Riesgo bajo" = 2,
  "Riesgo alto" = 0,
  "Riesgo moderado" = 1

}
// categorizamos tipo de riesgo y color a representar
export enum TiposRiesgoColor {

  "Riesgo bajo" = "success",
  "Riesgo alto" = "error",
  "Riesgo moderado" = "warning"

}
// categorizamos tipo de riesgo y color a representar



// WARNING --  HAY QUE TENER PRESENTE QUE LOS USESTATE
// DENTRO DE LOS EVENTOS DE LA GRAFICA NO USA EL ESTADO ACTUAL SINO EL ESTADO
// AL MOMENTO DE SER CREADO, SE DEBEN USAR LOS SETUSETATE Y LOS CLICK EVENTS 
// PARA QUE USE LOS USESTATE CON LA INFORMACIONF REAL

export const defaultopciones = {
  options: {

    chart: {
      fontFamily: 'Montserrat',
      animations: { enabled: true },
      zoom: {
        enabled: true,
        type: 'x',
        // autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1
          }
        }
      },
      type: 'area',
      //   stacked: true,
      id: 'fatg-graficadearea',
      events: {
        selection: function (chart: any, e: any) {
          console.log(new Date(e.xaxis.min))
        }
      }
    },
    stroke: {
      curve: 'smooth'
    }
    ,
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      onItemClick: {
        toggleDataSeries: false
      }
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: [{
      showAlways: true,
      tickAmount: 5,
      min: 0,
      labels: {
        formatter: function (val: number, index: any) {
          return val.toFixed(0);
        }
      },
      title: {
        text: "Alarmas"
      }
    },
    {
      show: false,
      min: 0
    }
    ],
    dataLabels: {
      enabled: true,
      // formatter: function (value: any, { seriesIndex, dataPointIndex, w }: any) {
      //     return seriesIndex == 0 ? value : (seriesIndex == 1 ? locateFormatPercentNDijitos(value / 100, 0) : locateFormatNumberNDijitos(value, 1))
      // },
    },
    tooltip: {
      y: {
        formatter: function (value: any, { seriesIndex, dataPointIndex, w }: any) {
          return seriesIndex == 0 ? value : (seriesIndex == 1 ? locateFormatPercentNDijitos(value / 100, 0) : locateFormatNumberNDijitos(value, 1))
        },
      }
    }
  },
  series: []
  // , colors: ['#008FFB', '#00E396', '#CED4DC'],

}


//listado campos tablas
export const columnsPrincipal: MRT_ColumnDef<any>[]
  = [
    {
      accessorKey: 'AlertaId',
      header: 'Id'
    },{
      accessorKey: 'Cliente',
      header: 'Cliente'
    }, 
    {
      accessorKey: 'Criticidad',
      header: 'Categoría'
    },
    {
      accessorKey: 'TipoAlerta',
      header: 'Alarma'
    },
    {
      accessorKey: 'vehiculo',
      header: 'Vehículo'
    },
    {
      accessorKey: 'conductor',
      header: 'Conductor'
    }, {
      accessorKey: 'EventDateTime',
      header: 'Fecha evento'
    }, {
      accessorKey: 'DetalladoEventos',
      header: 'N. Eventos'
    }, {
      accessorKey: 'EstadoGestion',
      header: 'Estado'
    },
    {
      accessorKey: 'Operacion',
      header: 'Operacion'
    },
    {
      accessorKey: 'Ruta',
      header: 'Ruta'
    },
    {
      accessorKey: 'Ciudad',
      header: 'Ciudad'
    },
    {
      accessorKey: 'Zona',
      header: 'Zona'
    },
    {
      accessorKey: 'ContactoHa',
      header: 'Contacto'
    },
    {
      accessorKey: 'Contacto',
      header: 'Correos'
    },
    {
      accessorKey: 'Motivo',
      header: 'Motivo'
    },
    {
      accessorKey: 'Ubicacion',
      header: 'Ubicacion'
    },
    {
      accessorKey: 'imagen',
      header: 'imagen'
    },
    {
      accessorKey: 'video',
      header: 'video'
    },
    {
      accessorKey: 'gestor',
      header: 'Analista'
    },
    {
      accessorKey: 'fechaapertura',
      header: 'Fecha Apertura'
    },
    {
      accessorKey: 'fechagestion',
      header: 'Fecha Gestión'
    },
    {
      accessorKey: 'value',
      header: 'Observación'
    }
  ]
//listado campos tablas
export const columnsDetalleEventos: MRT_ColumnDef<any>[]
  = [
    {
      accessorKey: 'AlertaId',
      header: 'Id'
    },
    // {
    //   accessorKey: 'TipoAlerta',
    //   header: 'Alarma'
    // },
    // {
    //   accessorKey: 'vehiculo',
    //   header: 'Vehículo'
    // },
    // {
    //   accessorKey: 'conductor',
    //   header: 'Conductor'
    // }, {
    //   accessorKey: 'EventDateTime',
    //   header: 'Fecha evento'
    // }, {
    //   accessorKey: 'DetalladoEventos',
    //   header: 'Cantidad eventos'
    // }, {
    //   accessorKey: 'EstadoGestion',
    //   header: 'Estado'
    // },
    // {
    //   accessorKey: 'gestor',
    //   header: 'Analista'
    // },
    // {
    //   accessorKey: 'EventId',
    //   header: 'EventId'
    // },
    {
      accessorKey: 'evento',
      header: 'Evento'
    },
    {
      accessorKey: 'EventDateTime',
      header: 'EventDateTime'
    },
    {
      accessorKey: 'Latitud',
      header: 'Latitud'
    },
    {
      accessorKey: 'Longitud',
      header: 'Longitud'
    },
    {
      accessorKey: 'valor',
      header: 'Valor'
    },
    {
      accessorKey: 'velocidad',
      header: 'Km/h'
    },
    {
      accessorKey: 'kilometros',
      header: 'Odometro'
    },
    {
      accessorKey: 'fecharecibido',
      header: 'Fecha Recibido'
    },
    {
      accessorKey: 'fechaactualizado',
      header: 'Fecha Actualizado'
    }

  ];

export const fncReporteAlarma = [
  {
    name: "EstadoGestion",
    getData: (data: any) => {
      return (data == null) ? 'No Gestionado'
        : (data == true) ? 'Gestionado'
          : (data == false) ? 'En Gestion'
            : { data }
    }
  },
  {
    name: "gestor",
    getData: (data: any) => {

      return (data == null) ? 'Sin Analista' : data
    }
  }, {
    name: 'DetalladoEventos',

    getData: (data: any) => {
      return JSON.parse(data).length
    }
  }
  // ,{
  //   name: 'EventId',

  //   getData: (data: string) => {
  //     return `'${data}`
  //   }
  // }
];

//Esta funccion tiene como finalidad emular un modal mas no solo notificar.
export function confirmarDialogTexto(
  onConfirm: any,
  titulo: string = "¿Desea agregar información a el registro?",
  Texto: string = "Información de actividad",
  textoBotonConfirmacion: string = "Guardar",
  textoBotonCancel: string = "Cancelar",
  Tiempo: any,
  TiempoValidar: any,
  Html: any
) {
  Swal.fire({
    title: titulo,
    confirmButtonText: textoBotonConfirmacion,
    showCancelButton: true,
    cancelButtonText: textoBotonCancel,
    input: "text",
    html: Html,
    inputPlaceholder: Texto,
    inputValidator: result => {
      return new Promise((resolve) => {
        let Fecha = moment(Tiempo).add(parseInt(result), "m");
        if (result == "" || result == undefined || result == "0")
          resolve("el tiempo no puede estar vacio o ser 0");
        else if (moment(Fecha) >= moment(TiempoValidar))
          resolve("Supera a la fecha de cierre");
        else
          resolve("");
      });
    },
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33'
  }).then(result => {
    if (result.isConfirmed) {
      onConfirm(result);
    }
    return result;
  })

  return false;
}

export function setFinalizarJornada(clienteIds: string, Observaciones: string) {
  var params: { [id: string]: string | null; } = {};
  params["clienteIds"] = clienteIds;
  params["data"] = Observaciones;
  // hacemos la consulta 
  return Post_GetConsultasDinamicasUserDWH({
    Clase: "FATGQueryHelper",
    NombreConsulta: "finalizarJornada",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};


export function Get_InformeTorreControl(Informe: FormData) {
  return axios.post(urlFatigueInforme, Informe, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

}


export function Get_EnviarInformeTorreControl(Informe: FormData) {
  return axios.post(urlFatigueEnviarInforme, Informe, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}


export function base64ToArrayBuffer(base64: string) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}
export const ObjetoParametros = {
  "notificacion": 1,
  "Trips": 1,
  "Metrics": 1,
  "Event": 1,
  "Position": 0,
  "ActiveEvent": 1,
  "ebus": 0,
  "fatiga": 1,
  "fatiga_conf": {
    "usuarios":
      [],
    "usuariosbase": [],
    "franjahoraria": [],
    "InformeAlerta": {
      "Evento": "",
      "Hora": "",
      "Operacion": "",
      "Ruta": "ruta 1,ruta 2,ruta 3",
      "Placa": "",
      "Conductor": "",
      "Contacto": "",
      "Motivo": "Incidente",
      "Ubicacion": "",
      "Coordenadas": "",
      "Observacion": "",
      "Imagen": ""
    }
  }
}

export const ImagenToByte = (file: any, cb: any) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result)
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

export const BiteToImagen = (data: any) => {

}

export const Operador = (Usuario: any, data: any) => {
  return data.filter((val: any) => {
    if (val.UsuarioId == Usuario.Id && val.FechaFinal == null)
      return val
  });
}


// para el componente principal


export const columnasContacto: MRT_ColumnDef<any>[]
  = [
    {
      accessorKey: 'tipo',
      header: 'Tipo'
    },
    {
      accessorKey: 'nombre',
      header: 'Nombre'
    },
    {
      accessorKey: 'numero',
      header: 'Número'
    },
    {
      accessorKey: 'correo',
      header: 'Email'
    }

  ];

export const listadoCampos: MRT_ColumnDef<any>[] =

  [
    {
      accessorKey: 'fechagestion',
      header: 'Fecha',
      size: 100,
      Cell({ cell, column, row, table, }) {
        return (moment(cell.getValue() as Date).format(FormatoColombiaDDMMYYYHHmmss))
      }
    },
    {
      accessorKey: 'value',
      header: 'Observaciones',
      size: 100
    },
    {
      accessorKey: 'recursos',
      header: 'Recursos',
      size: 100
    }

  ];

export const listadoEventos: MRT_ColumnDef<any>[] =

  [
    {
      accessorKey: 'evento',
      header: 'Evento',
      size: 100
    },
    {
      accessorKey: 'EventDateTime',
      header: 'Fecha',
      size: 100,
      Cell({ cell, column, row, table, }) {
        return (moment(cell.getValue() as Date).format(FormatoColombiaDDMMYYYHHmmss))
      }
    },
    {
      accessorKey: 'Latitud',
      header: 'Latitud',
      size: 100
    },
    {
      accessorKey: 'Longitud',
      header: 'Longitud',
      size: 100
    },
    {
      accessorKey: 'velocidad',
      header: 'Velocidad',
      size: 100
    },
    {
      accessorKey: 'FormattedAddress',
      header: 'Ubicación',
      size: 100
    }


  ];