import MaterialReactTable from "material-react-table";
import { ECO_camposusuarios } from "../../data/datausuarios";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, Button } from "@mui/material";
import { FileDownload, QueryStats } from "@mui/icons-material";
import { DescargarExcel } from "../../../../../../_start/helpers/components/DescargarExcel";

type Props = {
    data: any[];
};


export const TabHistorial: React.FC<Props> = ({ data }) => {

    return (<>

        <MaterialReactTable
            localization={MRT_Localization_ES}
            columns={ECO_camposusuarios}
            data={data}
            rowCount={data.length}
            enableColumnFilters={false}
            enableColumnOrdering
            enableColumnDragging={false}
            //enablePagination={false}
            enableStickyHeader
            enableDensityToggle={false}
            //enableRowVirtualization
            defaultColumn={{
                minSize: 40, //allow columns to get smaller than default
                maxSize: 200, //allow columns to get larger than default
                size: 150,
            }}
            muiTableContainerProps={{
                sx: { maxHeight: '500px' }, //give the table a max height
            }}
            initialState={{
                pagination: {
                    pageIndex: 0,
                    pageSize: 100, //customize the default page size
                },
            }}
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ justifyContent: 'flex-end', alignItems: 'center', flex: 1, display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        onClick={() => { DescargarExcel(data, ECO_camposusuarios, "Reporte Historial Usuarios") }}
                        startIcon={<FileDownload />}>
                        Descargar Datos
                    </Button>

                </Box>
            )}
        /></>)

}