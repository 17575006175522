import { Button, Card, Modal, Tab, Tabs } from "react-bootstrap-v5";
import { EncabezadoModales } from "./EncabezadoModales";
import { useState } from "react";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { columnasContacto, listadoCampos, listadoEventos, setObservaciones } from "../../data/dashBoardData";
import { DeserializarJSon, GetFechaServidor, formatableJson_HHMMss } from "../../../../../../_start/helpers/Helper";
import { ColumnFiltersState } from "@tanstack/react-table";
import { useToaster } from "rsuite";
import { useDataFatigue } from "../../core/provider";

type Props = {
  showGestion: boolean;
  setShowGestion: any;
  setObservacion: any;
  AlertaSeleccionada: any;
  GenerarInforme: any;
  Operador : any;
  clienteIds : any;
  message : any
};
export const ModalObservaciones: React.FC<Props> = ({ showGestion, setShowGestion,
   AlertaSeleccionada, setObservacion, GenerarInforme , Operador, clienteIds, message}) => {
  const toaster = useToaster();
  const DataDetalleEventos: any[] = DeserializarJSon(AlertaSeleccionada.DetalladoEventos) ?? [];
  const dataContacto: any[] = DeserializarJSon(AlertaSeleccionada.contactos) ?? [];

  const [obervacionGestion, setobervacionGestion] = useState("");

  const getobservacion = (e: any) => {
    setobervacionGestion(e.target.value)
  };
  const [observaciones, setobservaciones] = useState(DeserializarJSon(AlertaSeleccionada.Observaciones) ?? []);
  const [esgestionado, setesgestionado] = useState(AlertaSeleccionada.EstadoGestion);
  const [esgestionable, setesgestionable] = useState(AlertaSeleccionada.EsGestionable);
  const [EsCerradoAuto, setEsCerradoAuto] = useState(AlertaSeleccionada.EsCerradoAuto);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  //table state
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const {alertas, setalertas} = useDataFatigue()

  
  return (<Modal
    show={showGestion}
    onHide={setShowGestion}
    size="lg">
    <Modal.Header className=" fw-bolder  bg-secondary rounded m-4" style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center", fontSize: 20
    }} closeButton={false}>
      <Modal.Title className="text-uppercase" style={{ fontSize: 20 }}>
        Gestionar Alerta</Modal.Title>
    </Modal.Header>
    <EncabezadoModales row={AlertaSeleccionada}></EncabezadoModales>
    <Card className="border border-gray-300  shadow-sm mx-2">
      <Tabs
        defaultActiveKey="gestion"
        className="px-0 mx-2 mt-2"
      // justify
      // onClick={() => {
      //   console.log('hola', row);
      // }} 
      >
        <Tab eventKey="gestion" title={`Gestión`} >
          <div className="rounded shadow-sm m-2">
            <div className="row m-1 mt-3">
              <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12">
                <div className="">
                  <label className="control-label label-sm font-weight-bold" htmlFor="comentario"
                    style={{ fontWeight: 'bold' }}>Nuevo Comentario:</label>
                  <textarea className="form-control  input input-sm " id={'obervacionueva'}
                    onChange={getobservacion} rows={3} value={obervacionGestion}></textarea>
                </div>
              </div>
            </div>

            <div className="row m-4">

              {(esgestionado != 1 && esgestionado != 2) && (<div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
                <Button type="button" variant="primary" onClick={() => {
                  setObservacion(obervacionGestion, 'false', false);
                }}>
                  Guardar
                </Button></div>)}


              {(esgestionado == 0) && (<div className="col-sm-3 col-xl-3 col-md-3 col-lg-3"><Button type="button" variant="danger" onClick={() => {
                setObservacion('Cierre Gestión', 'true', true);
              }}>
                Cerrar Gestion
              </Button></div>)}


              {(esgestionado == 1 || esgestionado == 2) && (<div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
                <Button type="button" variant={esgestionado == 1 ? 'danger' : 'success'} onClick={() => {
                  setObservacion(esgestionado == 1 ? 'Se reabre Gestión' : 'Se reasigna gestión', 'false', true);
                }}>
                  {esgestionado == 1 ? 'Reabrir Gestión' : 'Reasignar Gestión'}
                </Button> </div>)}
              {/* Marcial */}
              {(clienteIds.Parametros.fatiga_conf.TieneReporte == 1 ) && (
              ( esgestionado == 1 && esgestionable && !EsCerradoAuto) && (
                <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
                  <Button type="button" variant={'success'} onClick={() => {

                    setShowGestion(false);
                    let idtimer = setTimeout(() => {
                      GenerarInforme(AlertaSeleccionada);
                      clearTimeout(idtimer);
                    }, 300);
                  }}>
                    <i className=""></i> {'Reporte'}
                  </Button> </div>
              ))}
            </div>
          </div>


          <div>
            <MaterialReactTable
              localization={MRT_Localization_ES}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 120,
                },
              }}
              columns={listadoCampos}
              data={observaciones}
              // editingMode="modal" //default         
              enableTopToolbar={false}
              enableColumnOrdering
              // enableEditing
              /* onEditingRowSave={handleSaveRowEdits}
                  onEditingRowCancel={handleCancelRowEdits}*/
              muiToolbarAlertBannerProps={
                isError
                  ? {
                    color: 'error',
                    children: 'Error al cargar información',
                  }
                  : undefined
              }
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              rowCount={observaciones?.length}
              initialState={{
                sorting: [
                  {
                    id: 'fechagestion', //sort by age by default on page load
                    desc: true
                  }]
              }}

              state={{
                columnFilters,
                globalFilter,
                isLoading,
                showAlertBanner: isError,
                showProgressBars: isRefetching
              }}
            />
          </div>

        </Tab>
        <Tab eventKey="evetos" title={`Eventos`}>
          {/* <Modal.Header>
              <Modal.Title>Detallado Eventos</Modal.Title>
            </Modal.Header> */}
          <div className="m-2">
            <MaterialReactTable
              localization={MRT_Localization_ES}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 120,
                },
              }}
              columns={listadoEventos}
              data={DataDetalleEventos}
              // editingMode="modal" //default         
              enableTopToolbar={false}
              enableColumnOrdering
              // enableEditing
              /* onEditingRowSave={handleSaveRowEdits}
                  onEditingRowCancel={handleCancelRowEdits}*/
              muiToolbarAlertBannerProps={
                isError
                  ? {
                    color: 'error',
                    children: 'Error al cargar información',
                  }
                  : undefined
              }
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              rowCount={DataDetalleEventos?.length}
              initialState={{ density: 'compact' }}
              state={{
                columnFilters,
                globalFilter,
                isLoading,
                showAlertBanner: isError,
                showProgressBars: isRefetching
              }} />
          </div>
        </Tab>

        {/* <Tab eventKey="Mapa" title={`Mapas`}>

              {show && (<MapTab2 />)}

            </Tab> */}
        <Tab eventKey="Contacto" title={`Contactos`}>

          <MaterialReactTable
            localization={MRT_Localization_ES}
            displayColumnDefOptions={{
              'mrt-row-actions': {
                muiTableHeadCellProps: {
                  align: 'center'
                }
              },
            }}
            columns={columnasContacto}
            data={dataContacto}
            enableTopToolbar
            enableColumnOrdering
            enableFilters
            enablePagination={false}
            enableColumnFilters={false}
            muiToolbarAlertBannerProps={
              isError
                ? {
                  color: 'error',
                  children: 'Error al cargar información',
                }
                : undefined
            }
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            rowCount={dataContacto?.length}
            initialState={{ density: 'compact' }}
            state={{
              columnFilters,
              globalFilter,
              isLoading,
              showAlertBanner: isError,
              showProgressBars: isRefetching
            }}
          />

        </Tab>
      </Tabs>
    </Card>

    <Modal.Footer>
      <Button type="button" variant="secondary" onClick={() => { setShowGestion(false) }}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>)
}