
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useToaster } from "rsuite";
import { MensajeError } from "../../../../../_start/helpers/components/Toaster";
import { GetUser } from "../../../auth/data/datPerfil";
import { AxiosResponse } from "axios";
import { getListadoClientes } from "../data/GruposSeguridad";
import { NuevoUsuario } from "../data/usuariosdata";
import { GetOrganizaciones, GetPerfilesUsuarios } from "../../Modulos/data/seguridaddata";
export interface UsuariosContextModel {
    loader: boolean;
    lstUsuarios: any[];
    lstClientes: any[];
    usuarioSel: any;
    lstPerfiles: any[];
    lstOrganizaciones: any[];
    setlstUsuarios: (listado: any[]) => void;
    setlstClientes: (listado: any[]) => void;
    setloader: (loader: boolean) => void;
    setUsuarioSel: (item: any) => void;
    setlstPerfiles: (listado: any[]) => void;
    setlstOrganizaciones: (listado: any[]) => void;
}

const ServcContext = createContext<UsuariosContextModel>({
    setlstUsuarios: (listado: any[]) => { },
    setlstClientes: (listado: any[]) => { },
    setloader: (lloader: boolean) => { },
    loader: true,
    lstUsuarios: [],
    lstClientes: [],
    usuarioSel: NuevoUsuario,
    setUsuarioSel: (item: any) => { },
    lstPerfiles: [],
    setlstPerfiles: (listado: any[]) => { },
    lstOrganizaciones: [],
    setlstOrganizaciones: (listado: any[]) => { }
});

export const UsuariosProvider: React.FC = ({ children }) => {
    const [lstUsuarios, setlstUsuarios] = useState<any[]>([]);
    const [lstClientes, setlstClientes] = useState<any[]>([]);
    const [loader, setloader] = useState<boolean>(true);
    const [usuarioSel, setUsuarioSel] = useState<any>(NuevoUsuario);
    const [lstPerfiles, setlstPerfiles] = useState<any[]>([]);
    const [lstOrganizaciones, setlstOrganizaciones] = useState<any[]>([]);
    const value: UsuariosContextModel = {
        lstClientes,
        lstUsuarios,
        loader,
        usuarioSel,
        lstPerfiles,
        lstOrganizaciones,
        setlstClientes,
        setlstUsuarios,
        setloader,
        setUsuarioSel,
        setlstPerfiles,
        setlstOrganizaciones
    };
    return (<ServcContext.Provider value={value}> {children} </ServcContext.Provider>);
};

//Hace toda la magia de ir al servidor, traerse los datos y setearlos
export const GetDataUsuarios: React.FC = ({ children }) => {
    const toaster = useToaster();
    const { setlstClientes, setlstUsuarios, setloader, setlstPerfiles, setlstOrganizaciones } = useDataUsuarios();

    const interval = useRef<any>();
    //CONSULTA VEHICULOS OPERANDO


    const ConsultarListadoUsuarios = () => {
        setloader(true);
        GetUser(null, null).then((response: AxiosResponse<any>) => {
            if (response.status == 200) {
                // asignamos los datos a el user state para que se visualicen los datos
                setlstUsuarios(response.data);
              
                // let c = Recargar;
                // setRecargar(c++)
            }
            else
                toaster.push(MensajeError("Error Consultar Usuarios", 'Error al consultar las Usuarios'), {
                    placement: 'topCenter'
                });

        }).catch((e) => {
            toaster.push(MensajeError("Error Consultar Usuarios", 'Error al consultar las Usuarios'), {
                placement: 'topCenter'
            });
        }).finally(() => {
            setloader(false);
        })
    }
    const ObtenerClientes = () => {
        getListadoClientes().then(
            (response: AxiosResponse<any>) => {
                if (response.status == 200) {
                    setlstClientes(response.data);
                }
                else
                    toaster.push(MensajeError("Error Consultar Clientes", 'Error al consultar las Clientes'), {
                        placement: 'topCenter'
                    });

            }).catch((e) => {
                toaster.push(MensajeError("Error Consultar Clientes", 'Error al consultar las Clientes'), {
                    placement: 'topCenter'
                });
            }).finally(() => {
                setloader(false);
            })
    }



    useEffect(() => {
        clearInterval(interval.current)

        ConsultarListadoUsuarios();
        ObtenerClientes();
        ObtenerOrganizaciones(toaster, setloader, setlstOrganizaciones);
        interval.current = setInterval(() => {
            ConsultarListadoUsuarios();
            ObtenerClientes();
            ObtenerOrganizaciones(toaster, setloader, setlstOrganizaciones);
        }, 1200000);

        GetPerfilesUsuarios().then((response: AxiosResponse) => {
            setlstPerfiles(response.data.map((m : any)=>{
                return  { PerfilId : m.DetalleListaId, Nombres : m.Nombre }
            }));
         }).catch((e) => {
            toaster.push(MensajeError("Error Consultar Perfiles", 'Error al consultar las Perfiles'), {
                placement: 'topCenter'
            });
        });
    
        return () => {
            clearInterval(interval.current);
            setlstClientes([]);
            setlstUsuarios([]);
            // limpiamos todas las variables a ser detruidas

        };
    }, [children]);
    return <></>;
};

/** */
export function useDataUsuarios() {
    return useContext(ServcContext);
}


export const ObtenerOrganizaciones = (toaster: any, setloader: any, setlstOrganizaciones: any) => {

    GetOrganizaciones().then(
        (response: AxiosResponse<any>) => {
            if (response.status == 200) {
                setlstOrganizaciones(response.data);
            }
            else
                toaster.push(MensajeError("Error Consultar Organizaciones", 'Error al consultar las Organizaciones'), {
                    placement: 'topCenter'
                });

        }).catch((e) => {
            toaster.push(MensajeError("Error Consultar Organizaciones", 'Error al consultar las Organizaciones'), {
                placement: 'topCenter'
            });
        }).finally(() => {
            setloader(false);
        })
}
