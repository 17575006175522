import { MRT_ColumnDef } from "material-react-table";
import moment from "moment";

//======================================================================================================================================
export const FiltroDataTX = {
    getAdmins: function(data: any[],admin: string, semana : string)  {
        let AdminsTransmision: { usuarioIds: string, nombre: string }[] = [];
        AdminsTransmision.push({ "usuarioIds": "0", "nombre": "Todos" });
        // agrupa la informacion por los admins
     const admins =  this.getFiltadoPorAdmins(data, admin, semana).reduce((actual: any[], next: any) => {          
            const Administrador = next.AdministradorId.split(" - ") ;
            // agrupamos en un array por semana y por administrador
            let registro = actual.filter(f => f.usuarioIds == Administrador[0].trim());
            // consolidamos el total por estado

            if (registro.length == 0)                
                actual.push({ usuarioIds : Administrador[0].trim(), nombre : Administrador[1]?.trim()})

            return actual;

        }, AdminsTransmision);
       return admins;

        
    },
    getFiltadoPorAdmins: (data: any[], admin: string, semana : string) => {
        return data.filter( f=> 
            ( semana == "-1" || moment(f.Fecha).format("DD/MM/YYYY") == moment(semana).format("DD/MM/YYYY"))
          && (admin == "0"  || f.AdministradorId.includes(admin))
        );
    },
    // onbtiene consolidado por semana
    getConsolidadoPorSemana: function (data: any[], admin: string, semana : string ) {
        return this.getFiltadoPorAdmins(data, admin, semana).reduce((actual: any[], next: any) => {
            const semana = next.Semana;
            const consolidado = JSON.parse(next.Consolidado);
            let registro = actual.filter(f => f.Semana == semana);            
            const TotalGeneral = consolidado.TotalCliente.reduce((p: number, c: any) => p + c.CantSintTx, 0);
            if (registro.length > 0) {
                registro[0].TotalGeneral += TotalGeneral;
            }
            else {

                actual.push({ semana, TotalGeneral })
            }
            return actual;

        }, []);
    }, 
    // INFORMACION AGRUPADA POR
    getConsolidadoPorSemanaAdmin: function (data: any[], admin: string, semana : string ) {
        return this.getFiltadoPorAdmins(data, admin, semana).reduce((actual: any[], next: any) => {
            const semana = next.Semana;
            const consolidado = JSON.parse(next.Consolidado);
            actual.push(...consolidado.Detallado.map(((m: any) => { return { semana, Administrador: m.Administrador.split(" - ")[1].trim() } }))
            );
            return actual;

        }, []).reduce((actual: any[], next: any) => {
            const semana = next.semana;
            const Administrador = next.Administrador;
            // agrupamos en un array por semana y por administrador
            let registro = actual.filter(f => f.semana == semana && f.Administrador == Administrador);
            // consolidamos el total por estado

            if (registro.length > 0)
                registro[0].TotalGeneral += 1;
            else
                actual.push({ semana, Administrador, TotalGeneral: 1 })

            return actual;

        }, []);
    },
    // ontiene el consolidado por cliente y  por estado
    getConsolidadoPorClienteEstado: function (data: any[], admin: string, semana : string) {
        return this.getFiltadoPorAdmins(data, admin, semana).map(
            (m : any) => {
                const consolidado = JSON.parse(m.Consolidado);
                 const totalEstado = consolidado.TotalEstado.reduce((actual: any[], next: any) => {
                    const estado = next.estado;                   
                    let registro = actual.filter(f => f.estado == estado);
                    // consolidamos el total por estado
                    const TotalGeneral = next.CantSintTx;
                    if (registro.length > 0) {
                        registro[0].TotalGeneral += TotalGeneral;
                    }
                    else {
        
                        actual.push({ estado, TotalGeneral })
                    }
                    return actual;
        
                }, []);

                return { clienteNombre : m.NombreCliente, estados : totalEstado }
            }
        );
       
    },
    // obtiene el consolidado por cliente
    getConsolidadoPorCliente: function (data: any[], admin: string, semana : string) {
        return this.getFiltadoPorAdmins(data, admin, semana).reduce((actual: any[], next: any) => {
            const nombreCliente = next.NombreCliente;
            const consolidado = JSON.parse(next.Consolidado);
            let registro = actual.filter(f => f.nombreCliente == nombreCliente);
            const TotalGeneral = consolidado.TotalCliente.reduce((p: number, c: any) => p + c.CantSintTx, 0);
            if (registro.length > 0) {
                registro[0].TotalGeneral += TotalGeneral;
            }
            else {

                actual.push({ nombreCliente, DiasSinTx :TotalGeneral })
            }
            return actual;

        }, []);
    },
    // obtiene el detallado general para el listado en la tabla
    getDetallado: function (data: any[], admin: string, semana : string) {
        return this.getFiltadoPorAdmins(data, admin, semana).reduce((actual: any[], next: any) => {
            const nombreCliente = next.NombreCliente;
            const consolidado = JSON.parse(next.Consolidado);
            actual.push(...consolidado.Detallado.map(((m : any ) => { return { nombreCliente, Sitio : m.Sitio, registrationNumber: m.registrationNumber, DiasSinTx: m.DiasSinTx  }} ))
             );
            return actual;

        }, []);
    },
    // obtiene el consoliddo de los estados de tx
    getConsolidadoEstados: function (data: any[]) {
        let detallado : any[] = [];
        data.forEach( m=> {detallado.push(...m.estados)})
       
        return detallado.reduce((actual: any[], next: any) => {
            const estado = next.estado;           
            let registro = actual.filter(f => f == estado);            
            if (registro.length == 0) {
                actual.push(estado)
            }
            return actual;

        }, []);
    }
};


export const listadoCamposTablaClientes: MRT_ColumnDef<any>[] =

    [
        {
            accessorKey: 'nombreCliente',
            header: 'Cliente'
        },
        {
            accessorKey: 'DiasSinTx',
            id: 'DiasSinTx', 
            header: 'Cant sin Tx'
        }
    ]
export const listadoCamposTabla2: MRT_ColumnDef<any>[] =

    [
        {
            accessorKey: 'nombreCliente',
            header: 'Cliente',
            maxSize: 10,
        }, {
            accessorKey: 'registrationNumber',
            header: 'Placa',
            maxSize: 10,
        },
        {
            accessorKey: 'DiasSinTx',
            header: 'Dias sin Tx',           
            id: 'DiasSinTx', 
            maxSize: 10,
        }
    ];

export const opcionScatter = {
    options: {
        chart: {
          id: 'totalPorSemana',
          fontFamily: 'Montserrat'
        },
        xaxis: {
          categories: []
        },
        yaxis: [{
          showAlways: true,
          tickAmount: 5,
          min: 0,
       
          title: {
            text: "Total Sin Tx"
          }
        }
        ],
        title: {
          text: 'Total Por Semana',
          align: 'center'
        },
        dataLabels: {
          enabled: true,


          enabledOnSeries: true,
         

        },
        plotOptions: {
          line: {
            dataLabels: {
              position: 'top'
            }
          }
        }
      },
      series: []
}

export const opcionesVertical = {
    options: {
        chart: {
            id: 'apexchart-verticalTX',
            stacked: true,
            toolbar: {
                show: true
            },
         

        },
        yaxis: [{
            showAlways: true,
            tickAmount: 5,
            min: 0,
         
            title: {
              text: "Total Sin Tx"
            }
          }],
        title: {
            text: 'Total Por Vertical',
            align: 'center'
          },
        dataLabels: {
            enabled: true,
            enabledOnSeries: true,
            style: {
                colors: ['#424249']
            }
        },
    },
    series: [],
    xaxis: {
        type: 'category'
    },
    fill: {
        opacity: 1
    },
    legend: {
        show: true,        
        position: 'bottom'
    },
    tooltip: {
        y: {
            formatter: function (value: any, serie: any, index: any) {
                return `${serie.w.config.labels[serie.dataPointIndex]} : ${value}`
            }
        }
    },
    plotOptions: {
        bar: {
            horizontal: false
        }
    }
}