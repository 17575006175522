import { MRT_ColumnDef } from "material-react-table";

export const ConfigAssets = {
    getSitesAnidadosTree: (Sites: any[], SitePadreId: string | null, lstVehiculos: any[], lstSeleccionados: string[]) => {
        // ahora iteramos para conseguir los hijos recursivamente
        const result = Sites.filter(f => f.SitePadreId === SitePadreId)
            .map((value: any) => {
                let hijos = ConfigAssets.getSitesAnidadosTree(Sites, value.siteid, lstVehiculos, lstSeleccionados);
                let vehiculos = lstVehiculos.filter(f => f.siteId === value.siteid).map(mp => {
                    return {
                        Node: 0,
                        SiteId: value.siteid,
                        Sitio: '', tipo: mp.tipo, description: mp.description, registrationNumber: mp.registrationNumber,
                        AssetId: mp.assetIdString,
                        seleccionado: lstSeleccionados.includes(mp.assetIdString)
                    }
                });
                const listMerge: any[] = vehiculos.concat(hijos);    
                return {
                    Node: 1,
                    SiteId: value.siteid,
                    Sitio: value.sitename,
                    tipo: '',
                    description: '',
                    registrationNumber: '',
                    AssetId: '',
                    subRows: listMerge,
                    seleccionado: false
                }
            });
        

            return result;
    },
    getAssetsAnidados: (lstVehiculoAnidado: any[]) => {
        // ahora iteramos para conseguir los hijos recursivamente
        let lstVehiculos: any[] = [];
        const getDataHijos = (subRows: any[]) => {
            if (subRows)
                subRows.forEach((value: any) => {
                    if (value.Node === 1) // si dentro de él contiene un subnodo  se extraen los hijos
                    {
                        getDataHijos(value.subRows)
                    }
                    else
                        lstVehiculos.push(value.AssetId) // si no se hace push al array
                });

        }
        getDataHijos(lstVehiculoAnidado);
        return lstVehiculos
    }

}

export const preop_columnasAssets: MRT_ColumnDef<any>[] =
    [{
        accessorKey: 'Sitio',
        header: 'Sitio'
    },
    {
        accessorKey: 'tipo',
        header: 'Tipo'
    }, {
        accessorKey: 'description',
        header: 'Descripción'
    },
    {
        accessorKey: 'registrationNumber',
        header: 'Placa'
    }
    ];
