

import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import { useDataIndicadoresHistorical } from "../../../core/providerIndicadores";
import { Radio, RadioGroup } from "rsuite";
import moment from "moment";
import { FormatoColombiaDDMMYYY } from "../../../../../../../_start/helpers/Constants";

export const AlertasHoras: React.FC = ({ }) => {
    const [Titulo, setTitulo] = useState<string>("")
    const [tipoGrafica, setTipoGrafica] = useState<any>("1")
    let options = {
        options: {
            chart: {
                id: 'totalDistancia',
                fontFamily: 'Montserrat',

            },
            xaxis: {
                categories: ['']
            },
            yaxis: [{
                showAlways: true,
                tickAmount: 5,
                min: 0,
                title: {
                    text: "Total Alertas "
                }
            }
            ],
            dataLabels: {
                enabled: true,
                enabledOnSeries: true,
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        position: 'top'
                    }
                }
            }
        },
        series: [{}]

    }

    const { dataIndicadores } = useDataIndicadoresHistorical();
    const [chartOptions, setChartOptions] = useState<any>();

    useEffect(() => {
        if (dataIndicadores.length > 0)
            PintarGrafica()


    }, [dataIndicadores, tipoGrafica]);


    // funcion que se encarga de pintar las graficas con los resultados listos
    const PintarGrafica = () => {
        /*** {
              name: "High - 2013",
              data: [28, 29, 33, 36, 32, 32, 33]
            },
            {
              name: "Low - 2013",
              data: [12, 11, 14, 18, 17, 13, 13]
            } */
        // informacion de los indicadores
        let objectdata = dataIndicadores.filter(
            (f: any) => f.Tipo == (( ["2", "3"].includes(tipoGrafica)) ? 12 : 11));

        // traemos las series primero
        // agrupamos la información por serie
        const seriesAgrupadas: string[] = objectdata.reduce((p: any, c: any) => {
            const isExist = p.find((f: any) => f == c.Serie);
            if (isExist === undefined)
                p.push(c.Serie)
            return p;
        }, [])

        // armamos las categoria que serian las horas del dia
        let categories: number[];

        if (["2", "3"].includes(tipoGrafica))
            categories = objectdata.reduce((p: any, c: any) => {
                const isExist = p.find((f: any) => f == c.Fecha);
                if (isExist === undefined)
                    p.push(c.Fecha)
                return p;
            }, []).sort(function (a: any, b: any) {
                // convertimos en fecha y extraemos el objeto date para poder organizar los datos en el orden ascendente
                return  moment(a).date() - moment(b).date() 
            });
        else
            categories = Array.from(
                { length: 24 },
                (value, index) => (index * 1)
            ); // cuando es por hora armamos las categorias necesarias en ese tiempo, construimos deliberadamente un array de 0 a 24 

        // si es por tiempo debemos sacar las categorias organizadas por fecha

        let ArraySeries: any[] = [];
        if (tipoGrafica == "1")
            seriesAgrupadas.forEach((e: any) => {
                let adata: number[] = [];
                let dataSerie = { name: e, data: adata };
                categories.forEach((ec: number) => {
                    const findHora = objectdata.find((f: any) => f.Hora == ec && f.Serie === e)
                    dataSerie.data.push(findHora == undefined ? 0 : findHora.Total)
                });

                ArraySeries.push(dataSerie);
            });

        else if (tipoGrafica == "0") {

            // armamos el total de todas las series
            let adata: number[] = [];
            let dataSerie = { name: 'Total General', data: adata };
            categories.forEach((ec: number) => {
                const findHora = objectdata.filter((f: any) => f.Hora == ec)
                dataSerie.data.push(findHora.length == 0 ? 0 : findHora.reduce((a: any, b: any) => b.Total + a, 0))

            });
            ArraySeries.push(dataSerie);
        } else if (tipoGrafica == "2") {
            seriesAgrupadas.forEach((e: any) => {
                let adata: number[] = [];
                let dataSerie = { name: e, data: adata };
                categories.forEach((ec: number) => {
                    const findHora = objectdata.find((f: any) => f.Fecha == ec && f.Serie === e)
                    dataSerie.data.push(findHora == undefined ? 0 : findHora.Total)
                });

                ArraySeries.push(dataSerie);
            });

        }else if (tipoGrafica == "3") {
             // armamos el total de todas las series
             let adata: number[] = [];
             let dataSerie = { name: 'Total General', data: adata };
             categories.forEach((ec: number) => {
                 const findHora = objectdata.filter((f: any) => f.Fecha == ec)
                 dataSerie.data.push(findHora.length == 0 ? 0 : findHora.reduce((a: any, b: any) => b.Total + a, 0))
 
             });
             ArraySeries.push(dataSerie);

        }


        options.series = ArraySeries; // se modifican las series
        options.options.xaxis.categories = categories.map((m: any) => (["2", "3"].includes(tipoGrafica)) ? moment(m).format(FormatoColombiaDDMMYYY) : m.toString()); // colocamos las categorias
        setChartOptions(options)


    };

    return (
        <>
            {/* {(opciones && opciones.options && opciones.series) && (<ReactApexChart key={`chardonavehiculo_${nameChart}`} options={opciones.options} series={opciones.series} type="donut" width={260} />)} */}
            <div key={`cc_alertasporhoras`}>
                {(chartOptions != undefined) && (
                    <>
                                           <div className="d-flex justify-content-start mx-4 "   >
                            <h6 className=" mt-2 mx-2">Tipo Gráfica:</h6>
                            <RadioGroup inline appearance="picker" value={tipoGrafica}
                                onChange={(e: any) => {
                                    setTipoGrafica(e)
                                }}
                            >
                                <Radio value="0">Total  Hora</Radio>
                                <Radio value="1">Alerta Hora</Radio>
                                <Radio value="2">Diario</Radio>
                                <Radio value="3">Total Diario</Radio>
                            </RadioGroup>
                        </div>
                        <ReactApexChart options={chartOptions.options} series={chartOptions.series}
                            type="line" height={300} />

                    </>

                )}
            </div>


        </>
    )
}