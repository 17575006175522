
import { createContext, useContext, useEffect,  useState } from "react";
import { useToaster } from "rsuite";
import { MensajeError } from "../../../../../_start/helpers/components/Toaster";
import { GetOrganizaciones, GetUsuariosActivos } from "../data/seguridaddata";


export interface ServiciosContextModel {
    loader: boolean;
    lstOrganizaciones: any[];
    lstRoles: any[];
    lstUsuarios: any[];
    setlstOrganizaciones: (listado: any[]) => void;
    setlstRoles: (listado: any[]) => void;
    setlstUsuarios: (listado: any[]) => void;
    setlloader: (loader: boolean) => void;
}

const ServcContext = createContext<ServiciosContextModel>({
    setlstOrganizaciones: (listado: any[]) => { },
    setlstRoles: (listado: any[]) => { },
    setlloader: (lloader: boolean) => { },
    loader: true,
    lstOrganizaciones: [],
    lstRoles: [],
    lstUsuarios: [],
    setlstUsuarios: (listado: any[]) => { }
});

export const ServiciosProvider: React.FC = ({ children }) => {
    const [lstOrganizaciones, setlstOrganizaciones] = useState<any[]>([]);
    const [lstRoles, setlstRoles] = useState<any[]>([]);
    const [lstUsuarios, setlstUsuarios] = useState<any[]>([]);
    const [loader, setlloader] = useState<boolean>(true);
    const value: ServiciosContextModel = {
        lstRoles,
        setlstRoles,
        lstOrganizaciones,
        setlstOrganizaciones,
        setlloader, loader,
        lstUsuarios,
        setlstUsuarios
    };
    return (<ServcContext.Provider value={value}> {children} </ServcContext.Provider>);
};

//Hace toda la magia de ir al servidor, traerse los datos y setearlos
export const GetDataSeguridad: React.FC = ({ children }) => {
    const toaster = useToaster();
    const { setlstRoles, setlstOrganizaciones, setlloader } = useDataSeguridad();
    //CONSULTA VEHICULOS OPERANDO
    let consulta = (children: any) => {
        // consultamos en la base de datos la informacion de vehiculos operando
        setlloader(true);
        GetOrganizaciones().then((response) => {
            if (response.status === 200) {
                setlstOrganizaciones(response.data)
                setlloader(false);

            } else
                toaster.push(MensajeError("Error Consultar Organizaciones", 'Error al consultar las Organizaciones'), {
                    placement: 'topCenter'
                });

        }).catch((e) => {
            toaster.push(MensajeError("Error Consultar Organizaciones", 'Error al consultar las Organizaciones'), {
                placement: 'topCenter'
            });
        }).finally(() => {
            setlloader(false);
        })

        GetUsuariosActivos().then((response) => {
            if (response.status === 200) {
                setlstRoles(response.data)
                setlloader(false);

            } else
                toaster.push(MensajeError("Error Consultar Usuarios", 'Error al consultar las Usuarios'), {
                    placement: 'topCenter'
                });

        }).catch((e) => {
            toaster.push(MensajeError("Error Consultar Usuarios", 'Error al consultar las Usuarios'), {
                placement: 'topCenter'
            });
        }).finally(() => {
            setlloader(false);
        })
    }
    useEffect(() => {

        consulta(children);

        return () => {
            setlstRoles([]);
            setlstOrganizaciones([]);
            // limpiamos todas las variables a ser detruidas
        };
    }, [children]);
    return <></>;
};

/** */
export function useDataSeguridad() {
    return useContext(ServcContext);
}


