import { Button, Col, Form, FormLabel, InputGroup, Modal, Row } from "react-bootstrap-v5";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../../_start/helpers/components/HtlmTooltip";
import { Box, Stack, Tooltip, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { Checkbox, useToaster } from "rsuite";
import { Delete, Edit, Trash } from "react-feather";
import { FiltroListaDistribucion, GestionListaDistribucion, GetListasDistribucion, getPlantillas, preop_DetalladoDistribucion, preop_ListaDistribucion, TiposPlantillas, Tooltips } from "../../../data/dataConfiguracion";
import { AxiosError, AxiosResponse } from "axios";
import { useDataConfiguracion } from "../../../core/ConfiguracionCore";
import { CargaListadoClientes } from "./ComponenteCliente";
import BlockUi from "@availity/block-ui";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MensajeError, MensajeSuccess } from "../../../../../../../_start/helpers/components/Toaster";
import { Add, Check, Clear, ClearAll, ClearAllOutlined } from "@mui/icons-material";
import { validarEmail } from "../../../../../Seguridad/Administracion/data/usuariosdata";
import confirmarDialog from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { DeserializarJSon, getMensajeErrorAxios } from "../../../../../../../_start/helpers/Helper";


/** Opcion que permite crear listas de distribyución para cuando los preoperacionales lleguen de forma incorrecta */
export const PREOP_ListaDistribucion: React.FC = () => {
    const { clienteSeleccionado, lstPlantillas, datosPreoperacional, setclienteSeleccionado, showObject, setshowObject, setlstPlantillas } = useDataConfiguracion();
    const [lstListas, setlstListas] = useState<any[]>([]);
    const [lstCorreosPlantilla, setLstCorreosPlantilla] = useState<any[]>([]);
    const toaster = useToaster();
    const [loading, setLoading] = useState<boolean>(false);
    const [TipoPlantilla, setTipoPlantilla] = useState<any[]>([])
    const [objetoLista, setobjetoLista] = useState<any>();
    const [titulo, setTitulo] = useState<string>('Nueva Lista Distribución');
    const [isEditing, setisEditing] = useState<boolean>(false);
    const [addCorreo, setAddCorreo] = useState<boolean>(false);
    const [objetoCorreo, setobjetoCorreo] = useState<any>();
    const [mailValid, setMailValid] = useState<boolean | null>(null);
    useEffect(() => {
        setTipoPlantilla(TiposPlantillas(lstPlantillas));
    }, [lstPlantillas])
    useEffect(() => {

        if (clienteSeleccionado?.ClienteId == undefined ? null : clienteSeleccionado?.ClienteId) {
            ConsultarGetListasDistribucion();
        }
        return () => { // cuando se desmonta el control limpia los datos
            setTipoPlantilla([]);
            setlstListas([])
        }
    }, [clienteSeleccionado]);

    //consulta todas las listas de distribución del cliente
    const ConsultarGetListasDistribucion = () => {
        GetListasDistribucion(clienteSeleccionado?.ClienteId).then((response: AxiosResponse<any>) => {
            setlstListas(response.data);
        }).catch((error: AxiosError<any>) => {
            toaster.push(MensajeError('Traer Listas', 'Error al cargar listas de distribución:' + error.message), { placement: 'topCenter' })
        });
    }

    const ControlTipoPlantilla = () => {
        return (
            <Form.Select onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema                        
                //valida que no exista una lista con la misma distribución
                if (!FiltroListaDistribucion.validarListaCategoria(lstListas, e.currentTarget.value))
                    setobjetoLista({
                        ...objetoLista, Categoria: e.currentTarget.value
                    });
                else
                    toaster.push(MensajeError('Adicionar Categoría', 'Ya existe una lista distribución con esa categoría, escoja otra'),
                        { placement: 'topCenter' })

            }} aria-label="" value={objetoLista?.Categoria ?? '0'}>
                <option key={'0'} value={'0'}>Seleccione Plantilla</option>
                {
                    TipoPlantilla?.map((element: any) => {
                        return (<option key={element} value={element}>{element}</option>)
                    })
                }
            </Form.Select>
        );
    }


    const NuevoRegistro = () => {
        setisEditing(true);
        // llenamo el obeto a modificar con la estructura necesaria
        setobjetoLista({
            ListaDistribucionId: 0,
            Categoria: '',
            Nombre: '',
            ClienteId: clienteSeleccionado.ClienteId,
            Correos: '',
            EsActivo: true
        });
        setTitulo('Nueva Lista Distribución')
        setLstCorreosPlantilla([]);
    }
    const EditarRegistro = (row: any) => {
        setisEditing(true);
        // llenamo el obeto a modificar con la estructura necesaria
        setobjetoLista({ ...row });
        const correos: any[] = DeserializarJSon(row.Correos)
        setLstCorreosPlantilla(Array.isArray(correos) ? correos : []);
        setTitulo('Editar Registro')
    }


    // adiciona y elimina correos de las listas distribución
    const AddDeleteCorreo = (isDelete: boolean, Correo: string | null = null) => {

        // si es una adición valida que tenga los datos necesarios para ser agregados
        if (!isDelete && (objetoCorreo.TipoEnvio === '' || !mailValid || objetoCorreo.Nombres === '')) {
            toaster.push(MensajeError('Adicionar Correo', 'Debe Diligenciar toda la información'), { placement: 'topCenter' })
            return;
        }
        let lstCorreosActuales: any[] = [...lstCorreosPlantilla];

        
        if (!isDelete) {
            // luego de pasar las validaciones valida que no se repita el correo y procede agregarlo
            const isExist = lstCorreosActuales.filter(f => f.Correo === objetoCorreo.Correo);
            if (isExist.length === 0)
                lstCorreosActuales.push(objetoCorreo);
            else {
                toaster.push(MensajeError('Adicionar Correo', 'Ya se encuentra el correo registrado, elija otro'), { placement: 'topCenter' })
                return;
            }
        }
        // si es eliminar simplemente el correo enviado lo saca de la lista y actualzia el objeto a trabajar
        if (isDelete) {
            lstCorreosActuales = lstCorreosActuales.filter(f => f.Correo !== Correo);
        }

        setLstCorreosPlantilla(lstCorreosActuales);
        setobjetoLista({ ...objetoLista, Correos: JSON.stringify(lstCorreosActuales) })
        setobjetoCorreo({ TipoEnvio: '', Correo: '', Nombres: '' })
        setAddCorreo(false);

    }

    const GuardarGestionListaDistribucion = (row: any | null = null) => {
        if (row === null && !FiltroListaDistribucion.ValidarCorreoPrincipal(lstCorreosPlantilla)) {
            toaster.push(MensajeError('Guardar Lista', 'Debe tener al menos un correo principal'), { placement: 'topCenter' })
            return;
        }
        confirmarDialog(() => {
            setLoading(true);

            GestionListaDistribucion(JSON.stringify(row ?? objetoLista)).
                then((response: AxiosResponse<any>) => {
                    if (response.status === 200) {
                        toaster.push(MensajeSuccess('Guardar Lista', 'Guardado exitosamente'), { placement: 'topCenter' });
                        setisEditing(false);
                        ConsultarGetListasDistribucion();
                    }

                }).catch((error: AxiosError<any>) => {
                    toaster.push(MensajeError('Guardar Lista', getMensajeErrorAxios(error)), { placement: 'topCenter' })
                }).finally(() => { setLoading(false); })
        }, `¿Esta seguro que desea guardar los cambios?`, 'Guardar');
    }
    return (<>
        <BlockUi tag='div' message="Cargando información..." keepInView blocking={loading} >
            <div className="form-horizontal  mx-auto">
                <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Cerrar"].title, Tooltips["Cerrar"].content)} >
                    <Button type="button" className="bt btn-sm" variant="secondary" onClick={() => {
                        setshowObject({ ...showObject, listas: false, panelCentral: true });
                    }}>
                        Cerrar
                    </Button>
                </HtmlTooltipStyled>
                <div className="ms-3 text-center">
                    <h4 className="mb-0">Configuración de correos  Clientes Preoperacional</h4>
                    <span className="text-muted m-3">Configuracion de listas de distribución para envío de correo</span>
                </div>
            </div>
            <hr className="m-2" />
            <div className="d-flex justify-content-start gap-4" style={{ width: '60%' }}>
                <FormLabel className="fs-4 mx-4 mt-2">Cliente:</FormLabel>
                {CargaListadoClientes(datosPreoperacional, setclienteSeleccionado, clienteSeleccionado)}
                <Button size="lg" onClick={() => { NuevoRegistro(); }}>Nueva</Button>
            </div>

            <Stack>

                <div hidden={!isEditing} >
                    <div className="m-5 text-center">
                        <span className=" fs-3 fw-bold text-center" >{titulo}</span>
                    </div>
                    <Form className="border m-3 bg-secondary" >
                        <Row className="m-2">
                            <Col sm={4}>
                                <Form.Group as={Row} className="mb-3 " >
                                    <Form.Label column sm="3" >Tipo:</Form.Label>
                                    <Col sm="9">
                                        <ControlTipoPlantilla />
                                    </Col>
                                </Form.Group>

                            </Col>
                            <Col sm={6}>
                                <Form.Group as={Row} className="mb-3 " >
                                    <Form.Label column sm="3" >Nombre:</Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder="Escriba nombre lista"
                                            value={objetoLista?.Nombre ?? 'General'}
                                            onChange={(event: any) => {
                                                setobjetoLista({
                                                    ...objetoLista, Nombre: event.currentTarget.value
                                                });
                                            }}
                                        ></Form.Control>
                                    </Col>
                                </Form.Group>


                            </Col>
                            <Col>
                                <Form.Label>Estado:</Form.Label>
                                <Checkbox
                                    checked={objetoLista?.EsActivo}
                                    onChange={(e: any, checked: boolean) => {
                                        setobjetoLista({
                                            ...objetoLista, EsActivo: checked
                                        });
                                    }}
                                ></Checkbox>

                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12"   >
                                <IconButton onClick={() => {
                                    setobjetoCorreo({});
                                    setAddCorreo(true);
                                }} className="text-danger fs-4 bg-transparent"> <Add color="error" /> Correo</IconButton>

                            </Col>
                        </Row>
                        <Row hidden={!addCorreo}>
                            <Col sm="12"   >
                                <Form className="border m-3 bg-secondary" >
                                    <Row className="m-2">
                                        <Col>
                                            <Form.Label>Tipo Correo:</Form.Label>
                                            <Form.Select value={objetoCorreo?.TipoEnvio}
                                                onChange={(e) => {
                                                    setobjetoCorreo({ ...objetoCorreo, TipoEnvio: e.currentTarget.value })
                                                }} >
                                                <option value=''>Seleccione</option>
                                                <option value='TO'>TO - Principal</option>
                                                <option value='CC'>CC - Copia</option>
                                                <option value='CCO'>CCO - Copia Oculta</option>
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Label>Nombre:</Form.Label>
                                            <Form.Control type="text" placeholder="Escriba nombre usuario"
                                                value={objetoCorreo?.Nombres}
                                                onChange={(e: any) => {
                                                    setobjetoCorreo({ ...objetoCorreo, Nombres: e.currentTarget.value })
                                                }}
                                            ></Form.Control>
                                        </Col>

                                    </Row>
                                    <Row className="m-2">
                                        <Col sm="8"   >
                                            <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                                                <Form.Label column sm="4" >Correo:</Form.Label>
                                                <Col sm="8">
                                                    <InputGroup>
                                                        <Form.Control placeholder="email@domain.com" autoComplete="off"
                                                            type="email" value={objetoCorreo?.Correo} onChange={(e) => {
                                                                const isValid = validarEmail(e.currentTarget.value)
                                                                setMailValid(isValid)
                                                                //  if (isValid)
                                                                setobjetoCorreo({ ...objetoCorreo, Correo: e.currentTarget.value })


                                                            }} />
                                                        {!mailValid ? (<Clear className="mt-4 mx-2" color="error" />) : (<Check className="mt-4 mx-2" color="success" />)}
                                                    </InputGroup>


                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="4"   >
                                            <Button type="button" className="btn btn-sm m-2" variant="primary" onClick={() => {
                                                AddDeleteCorreo(false);
                                            }}>
                                                Agregar
                                            </Button>
                                            <Button type="button" className="btn btn-sm m-2 bg-danger text-white" variant="secondary" onClick={() => {
                                                setAddCorreo(false);
                                            }}>
                                                Cerrar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12"   >
                                <MaterialReactTable enableColumnResizing
                                    localization={MRT_Localization_ES}
                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            muiTableHeadCellProps: {
                                                align: 'center',
                                            },
                                            size: 120,
                                        },
                                    }}
                                    columns={preop_DetalladoDistribucion}
                                    data={lstCorreosPlantilla}
                                    enableTopToolbar={false}
                                    enableColumnOrdering
                                    enableEditing
                                    enablePagination={false}
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enableRowVirtualization
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '600px' }, //give the table a max height
                                    }}
                                    renderRowActions={({ row }) => (
                                        <>
                                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                                <Tooltip arrow placement="left" title="Editar Registro">
                                                    <IconButton
                                                        onClick={() => {
                                                            confirmarDialog(() => { AddDeleteCorreo(true, row.original.Correo); }, 'Desea Eliminar este correo?', 'Eliminar')
                                                        }}
                                                    >
                                                        <Delete className="text-danger" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </>
                                    )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12"   >
                                <div className="d-flex justify-content-center">
                                    <Button type="button" className="btn btn-sm m-2" variant="primary" onClick={() => {
                                        GuardarGestionListaDistribucion(); // metodo que guarda toda la inforamción modificada
                                    }}>
                                        Guardar
                                    </Button>

                                    <Button type="button" className="btn btn-sm m-2 bg-danger text-white" variant="secondary" onClick={() => {
                                        setisEditing(false);
                                    }}>
                                        Cerrar
                                    </Button>

                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div hidden={isEditing}>
                    <MaterialReactTable enableColumnResizing
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 120,
                            },
                        }}
                        columns={preop_ListaDistribucion}
                        data={lstListas}
                        enableTopToolbar={false}
                        enableColumnOrdering
                        enableEditing
                        enablePagination={false}
                        enableStickyHeader
                        enableDensityToggle={false}
                        enableRowVirtualization
                        muiTableContainerProps={{
                            sx: { maxHeight: '600px' }, //give the table a max height
                        }}
                        renderRowActions={({ row }) => (
                            <>
                                <Box sx={{ display: 'flex', gap: '1rem' }}>
                                    <Tooltip arrow placement="left" title="Editar Registro">
                                        <IconButton
                                            onClick={() => {
                                                EditarRegistro(row.original);
                                            }}
                                        >
                                            <Edit className="text-success" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow placement="left" title={`${row.original.EsActivo ? 'Desactivar' : 'Activar'} Lista`}>
                                        <IconButton
                                            onClick={() => {
                                                confirmarDialog(() => {
                                                    GuardarGestionListaDistribucion({ ...row.original, EsActivo: !row.original.EsActivo })
                                                },
                                                    `Está seguro que desea ${row.original.EsActivo ? 'Desactivar' : 'Activar'} esta lista?`, 'Eliminar')
                                            }}
                                        >
                                            {row.original.EsActivo ? (<Clear className="text-danger" />) : (<Check className="text-success" />)}

                                        </IconButton>
                                    </Tooltip>

                                </Box>
                            </>
                        )
                        }
                    />
                </div>

            </Stack>

        </BlockUi>



    </>)
}