
import { useEffect, useState } from "react";
import { Indicador } from "./Indicadores/Indicador";
import { FiltroDashBoard, ContabilizarByColorCategoria, ContabilizarRequerimientosPorCategorias, Tooltips } from "../../data/PostVentaData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";
import { UserModelSyscaf } from "../../../../auth/models/UserModel";
import { formatSimpleJsonColombia, locateFormatPercentNDijitos } from "../../../../../../_start/helpers/Helper";
import { AxiosResponse } from "axios";
import { Form, Spinner } from "react-bootstrap-v5";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import moment from "moment";
import BlockUi from "@availity/block-ui";
import confirmarDialog, { } from "../../../../../../_start/helpers/components/ConfirmDialog";
import { SetEstadoSyscaf } from "../../../Tx/data/Reporte";
import { PageTitle } from "../../../../../../_start/layout/core";
import './style/dahsboard.css';
import { FiltroDashBoardBasic } from "../../data/dashboard";

import { TabNuevoRequerimientos } from "./components/TabNuevoReq";
import { ModalRequerimientosMasivos } from "../Requerimientos/Componentes/UpLoadRequerimientos";
import { EsPermitido, Operaciones, PermisosOpcion } from "../../../../../../_start/helpers/Axios/CoreService";
import { Button as Bu } from "@mui/material";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../_start/helpers/components/HtlmTooltip";
import { GetDataDashBoard, useDataDashBoard } from "../../core/DashboardCore";
import { ModalSinTx } from "./modals/Dash_SinTx";
import { ModalFallas } from "./modals/Dash_Fallas";
import { ModalNuevoRequerimiento } from "./modals/Dash_NuevoReq";
import { ModalEquiposEspeciales } from "./modals/Dash_EquiposEspeciales";
import { ModalListadoReq } from "./modals/Dash_ListadoReq";
import { dataIndicadores } from "../../mockData/indicadores";
import { ModalVehiculos } from "./modals/Dash_Assets";
import { GetDataRequerimientos } from "../../core/RequerimientosCore";
import { set } from "rsuite/esm/utils/dateUtils";
export default function PostVentaDashBoard() {
    const { lstVehiculosSinTx, lstEstadosSinTx, lstAdminFlota, lstFallaSeniales, AdminSeleccionado, SetAdminSeleccionado,
        ClienteSeleccionado, setClienteSeleccionado, lstRequerimientos, lstEstadosReq, loader : LoaderProvider
    } = useDataDashBoard();
    const permisosopcion = PermisosOpcion("DashBoard");
    const operacionesPermisos = Operaciones;
    const [showMasivos, setshowMasivos] = useState<boolean>(false);
    const [Recargar, setRecargar] = useState<boolean>(false);
    const [showRequerimientos, setshowRequerimientos] = useState<boolean>(false);
    const [showVehiculos, setShowVehiculos] = useState<boolean>(false);
    const [Estados, setEstados] = useState<any[]>([]);
    //Esta es para tomar la cantidad de muestra de vehiculos de transmision.

    const [showr, setShowr] = useState(false);
    const [dataConductores, setdataConductores] = useState<any[]>([]);

    const [RegistroSeleccionado, setRegistroSeleccionado] = useState<any>({});

    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const FechasSeniales = {
        FechaInicial: moment().add(-7, 'day').format(formatSimpleJsonColombia).toString(),
        FechaFinal: moment().format(formatSimpleJsonColombia).toString()
    }
    const vUser = user as UserModelSyscaf;

    const [loader, setLoader] = useState(false);


    //ESTA ES PARA EL MODAL DE BUSQUEDA DE EQUIPOS ESPECIALES.
    const [showEspecial, setshowEspecial] = useState<boolean>(false);


    //para guardar los datos orignales cuando esten filtrados.
    const [IndicadoresDatos, setIndicadoresDatos] = useState<any>({
        TotalClientes: 0,
        TotalUnidadesActivas: 0,
        TotalUnidadesFiltrada: 0,
        ClasificadoSites: {},
        ClasificadoAsset: {},
        TotalSinTx: 0,
        TotalFallas: 0,
    });
    const [DataEstadosPorColor, setDataEstadosPorColor] = useState<any[]>([]);
    const [DataEstadosPorColorR, setDataEstadosPorColorR] = useState<any[]>([]);


    const [isShowTableReq, setisShowTableReq] = useState<boolean>(false);

    //============================== VARIABLES LISTADOS CLIENTE Y ADMINISTRADOR===================   
    const Clientesseleccionados = { "ClienteId": "0", "clienteNombre": "Todas las bases" };
    const Administradorseleccionados = { "UsuarioId": "0", "Nombres": "Todos" };
    const [estadoSinTxSel, setestadoSinTxSel] = useState<number>(0);
    const [estadoReqSel, setestadoReqSel] = useState<string>("");
    const [semaforo, setSemaforo] = useState<number | null>(-1);
    const [showModalSinTx, setshowModalSinTx] = useState<any>(false);
    const [dataSinTxFiltrada, setdataSinTxFiltrada] = useState<any[]>([]);
    const [dataSenialesFiltrada, setdataSenialesFiltrada] = useState<any[]>([]);
    const [dataReqFiltrada, setdataReqFiltrada] = useState<any[]>([]);
    const [dataUnidadesFiltrada, setdataUnidadesFiltrada] = useState<any[]>([]);
    const [showModalSeniales, setshowModalSeniales] = useState<any>(false);
    const [fallaSeniales, setfallaSeniales] = useState<string>("");

    const [TxUltimaActualizacion, setTxUltimaActualizacion] = useState<string>("Sin Información");
    const [dataVehiculosModal, setdataVehiculosModal] = useState<any>({ EsFiltrado: false, property: null, valor: '' });
    //========================================================================================

    const Actualizarfiltros = () => {
        setdataSinTxFiltrada(FiltroDashBoardBasic.getFiltroAdminCliente(lstVehiculosSinTx,
            AdminSeleccionado.UsuarioId, ClienteSeleccionado.ClienteId
        ))
        // cada vez que hay cambio filtra los datos por admin y cliente seleccionado
        setdataSenialesFiltrada(FiltroDashBoardBasic.getFiltroAdminCliente(lstFallaSeniales,
            AdminSeleccionado.UsuarioId, ClienteSeleccionado.ClienteId
        ))

        setdataReqFiltrada(FiltroDashBoardBasic.getFiltroReqCliente(lstRequerimientos,
            AdminSeleccionado.UsuarioId, ClienteSeleccionado.ClienteId
        ));


        setdataUnidadesFiltrada(lstAdminFlota.filter(f =>
            (AdminSeleccionado.UsuarioId === "0" || f.UsuarioId === AdminSeleccionado.UsuarioId)
            && ((ClienteSeleccionado.ClienteId === "0" || f.ClienteId === ClienteSeleccionado.ClienteId))
        ));
        if (lstVehiculosSinTx.length > 0)
            setTxUltimaActualizacion(moment(lstVehiculosSinTx[0].fecha).format(formatSimpleJsonColombia));
    }

    const GenerarIndicadoresTotales = () => {
        let indicadores: any = { ...dataIndicadores };
        const clientes = FiltroDashBoardBasic.getClientePorAdminstador(lstAdminFlota, AdminSeleccionado.UsuarioId);

        indicadores.TotalClientes = clientes.length;
        indicadores.TotalUnidadesActivas = lstAdminFlota.length;
        indicadores.TotalUnidadesFiltrada = dataUnidadesFiltrada.length;
        indicadores.TotalSinTx = dataSinTxFiltrada.length;
        indicadores.TotalFallas = dataSenialesFiltrada.length;
        indicadores.ClasificadoSites = dataSinTxFiltrada.length;
        indicadores.ClasificadoAsset = dataSenialesFiltrada.length;
        setIndicadoresDatos(indicadores)


    }
    // realiza la primera consulta  para mostrar los datos 
    useEffect(() => {
        Actualizarfiltros();
        return () => {
            setdataConductores([]);
        }
    }, [lstAdminFlota, lstFallaSeniales, lstVehiculosSinTx,]);

    useEffect(() => {
        setLoader(LoaderProvider.LoaderGeneral() !==0 );

        if(Recargar && LoaderProvider.LoaderGeneral() !==0 )
            setRecargar(false);
        return () => {
            setdataConductores([]);
        }
    }, [LoaderProvider]);


    
    useEffect(() => {
        Actualizarfiltros();
        setisShowTableReq(false);
        return () => {
            setdataConductores([]);
        }
    }, [ClienteSeleccionado, AdminSeleccionado]);
    useEffect(() => {
        GenerarIndicadoresTotales();
    }, [dataReqFiltrada, dataSinTxFiltrada, dataSenialesFiltrada]);


    useEffect(() => {
        setEstados(lstEstadosReq);
        return () => {
            setEstados([])
        }
    }, [lstEstadosReq]);

    

    //ELIMINAR ESTE CODIGO.
    useEffect(() => {
        
            setDataEstadosPorColor(ContabilizarByColorCategoria(dataSinTxFiltrada, dataReqFiltrada, Estados));
            setDataEstadosPorColorR(ContabilizarRequerimientosPorCategorias(dataReqFiltrada, Estados,  dataSinTxFiltrada))

        

    }, [dataReqFiltrada, Estados, dataSinTxFiltrada])


    //FUNCION DE CREAR LOS REQUERIMIENTOS
    const CrearRequerimiento = (row: any) => {
        let data = (row.original == undefined ? row : row.original);
        setRegistroSeleccionado(data);
        const isNotSinTx: boolean = (row.diffAVL == undefined);
        // si el vehiculo está sin transmitir se le deberá cambiar el estado
        // si el vehiculo no está sin tranmisión crea el requerimiento
        if (data.estado == "Operando Normalmente" && isNotSinTx) {
            confirmarDialog(() => {
                const _estado = (Estados.filter((e: any) => e.estado == "En Proceso").length > 0 ? Estados.filter((e: any) => e.estado == "En Proceso")[0].estadoIdS : 7);
                setLoader(true);
                SetEstadoSyscaf((data.assetId == undefined ? data.AssetId.toString() : data.assetId.toString()),
                    String(_estado)).then((response: AxiosResponse) => {
                        if (response.status === 200)
                            setShowr(true);

                        setLoader(false);
                    }).catch(() => {
                        console.log("Error de cambio de estado")
                        setLoader(false);
                    })
            }, `¿El Vehículo con placa ${data.registrationNumber} del Cliente ${(data.clientenNombre == undefined ? data.clienteNombre : data.clientenNombre)} se encuentra operando normalmente?`, 'Si');

        }
        else
            setShowr(true);
    }

    //Componentes del los clientes
    const ComponenteClientes = () => {
        const clientes = FiltroDashBoardBasic.getClientePorAdminstador(lstAdminFlota, AdminSeleccionado.UsuarioId);

        return (
            <Form.Select className=" h-40px w-300px " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema

                let lstClientes = clientes?.filter((value: any) => {
                    return value.ClienteId === e.currentTarget.value;
                });

                setClienteSeleccionado(lstClientes.length > 0 ? lstClientes[0] : Clientesseleccionados);

            }} value={ClienteSeleccionado.ClienteId} aria-label="Default select example">
                <option value={0}>Todas las bases</option>
                {
                    clientes?.map((element: any) => {
                        return (<option key={element.ClienteId} value={element.ClienteId}>{element.clienteNombre}</option>)
                    })
                }
            </Form.Select>
        )
    }
    //Componente de los admins
    const ComponenteAdmin = () => {
        const adminsistradores = FiltroDashBoardBasic.getListadoAdministradores(lstAdminFlota);
        return (
            <Form.Select className=" h-40px w-300px " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let lstAdmin = adminsistradores?.filter((value: any) => {
                    return value.UsuarioId === e.currentTarget.value;
                });
                const admin = lstAdmin.length > 0 ? lstAdmin : [Administradorseleccionados];
                SetAdminSeleccionado(admin[0]);
                setClienteSeleccionado(Clientesseleccionados);

            }} value={AdminSeleccionado.UsuarioId} aria-label="Default select example">
                <option value={0}>Todos</option>
                {
                    adminsistradores.map((element: any) => {
                        return (<option key={element.UsuarioId} value={element.UsuarioId}>{element.Nombres}</option>)
                    })
                }
            </Form.Select>
        )
    }



    let EstadosPorColorCampos = (tipo: number) => {
        let EstadosPorColorCampos: MRT_ColumnDef<any>[] =
            [
                {
                    accessorKey: 'Estado',
                    header: '',
                    size: 40,
                    maxSize: 40,
                },
                {
                    accessorKey: 'Total',
                    header: 'Total',
                    Cell({ row, }) {
                        return <Bu onClick={() => {
                            ShowDetalle(row.original, null, tipo)
                        }} >{row.original.Total}</Bu>
                    },
                    size: 15,
                    maxSize: 15,
                },
                {
                    accessorKey: 'Rojo',
                    header: 'Rojo',
                    Cell({ row, }) {
                        return <Bu onClick={() => {
                            ShowDetalle(row.original, 3, tipo)
                        }} >{row.original.Rojo}</Bu>
                    },
                    size: 15,
                    maxSize: 15,
                },

                {
                    accessorKey: 'Amarillo',
                    header: 'Amarillo',
                    Cell({ row, }) {
                        return <Bu onClick={() => {
                            ShowDetalle(row.original, 2, tipo)
                        }} >{row.original.Amarillo}</Bu>
                    },
                    size: 15,
                    maxSize: 15,
                },
                {
                    accessorKey: 'Verde',
                    header: 'Verde',
                    Cell({ row, }) {
                        return <Bu onClick={() => {
                            ShowDetalle(row.original, 1, tipo)
                        }} >{row.original.Verde}</Bu>
                    },
                    size: 15,
                    maxSize: 15,
                }
            ];
        return EstadosPorColorCampos;
    }


    const ShowDetalle = (row: any, categoria: number | null, tipo: number) => {


        setSemaforo(categoria);
        setestadoReqSel(row.Estado === 'General' ? "-1" : row.Estado)
        if (row.Estado != "En Proceso" && tipo === 1) {
            const estadoFiltrado = lstEstadosSinTx.filter(f => f.estado === row.Estado);
            setestadoSinTxSel(row.Estado === 'General' ? -1 : estadoFiltrado[0]?.estadoIdS);
            setshowModalSinTx(true);
            setisShowTableReq(false);
        } else if
            (row.Estado == "En Proceso" && tipo === 1) {

            setisShowTableReq(true);
        }
        else {

            setshowRequerimientos(true);
        }
    };



    const calcularPorcentaje = (dataTx: any, dataUnidades: any) => {
        let por: any = "";
        if (dataUnidades > 0) {
            por = locateFormatPercentNDijitos(dataTx / dataUnidades, 2);
        }
        else por = "0%"
        return por;
    }

    return (
        <>
        {Recargar && (<GetDataDashBoard/>)}
            <PageTitle>Dashboard requerimientos</PageTitle>
            <BlockUi tag="div" keepInView blocking={loader ?? false} >
                <div className="row g-0 g-xl-5 g-xxl-8">
                    <div className="container">
                        <div className="row">
                            <div className="mt-5 ms-3 text-center">
                                <span className="fs-5 m-3 fw-bolder">{`Bienvenido  ${vUser.Nombres}, aquí encontrarás toda la información relevante de la semana.`} </span>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            {
                                (EsPermitido(permisosopcion, operacionesPermisos.verAdminsPostVenta)) && (
                                    <div className="col-5"> <div className="bg-secondary d-flex rounded">
                                        <label className="form-label mt-4 mx-4 fw-bolder">Admin:</label>
                                        <ComponenteAdmin />
                                    </div>  </div>)
                            }
                            <div className="col-5">
                                <div className="bg-secondary d-flex  rounded">
                                    <label className="form-label mt-4 mx-4 fw-bolder">Cliente:</label>
                                    <ComponenteClientes />
                                </div>
                            </div>
                            <div className="col-2">

                                <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Recargar"].title, Tooltips["Recargar"].content)} >
                                    <button onClick={() => {
                                        confirmarDialog(() => {
                                            setRecargar(true);
                                        }, `¿Esta seguro que recargar los datos del dashboard?`, 'Aceptar');
                                    }} className="btn bt-sm btn-success"><i className="bi-repeat"></i></button>
                                </HtmlTooltipStyled>


                            </div>
                        </div>

                        <div className="row">
                            <div className="row col-sm-12 col-md-12 col-xs-12 mx-auto">
                                <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto `}
                                    style={{ backgroundColor: "#b6fffe " }}                                >
                                    <div className="m-3 text-center">
                                        <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                            <span>{IndicadoresDatos.TotalClientes}</span></h2>
                                        <span className={`text-muted`}>Total Clientes</span>
                                    </div>
                                </div>
                                <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto `} style={{ backgroundColor: "#b6fffe " }}                                >
                                    <div className="m-3 text-center" >
                                        <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                            <span onClick={() => {
                                                setShowVehiculos(true);
                                                setdataVehiculosModal({ ...dataVehiculosModal, EsFiltrado: false, property: null });
                                            }} style={{ cursor: 'pointer' }}>{IndicadoresDatos.TotalUnidadesActivas}</span></h2>
                                        <span className={`text-muted`}>Total Assets</span>
                                    </div>
                                </div>
                                <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto   ${(vUser.perfil === '118' || AdminSeleccionado.UsuarioId === '0') && 'd-none'}`} style={{ backgroundColor: "#b6fffe " }}                                >
                                    <div className="m-3 text-center" >
                                        <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                            <span style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowVehiculos(true)
                                                    setdataVehiculosModal({ ...dataVehiculosModal, EsFiltrado: true, property: null });
                                                }
                                                }> {IndicadoresDatos.TotalUnidadesFiltrada}

                                            </span>
                                        </h2>
                                        <span className="fs-5 text-muted">
                                            {`(${locateFormatPercentNDijitos(IndicadoresDatos.TotalUnidadesFiltrada / IndicadoresDatos.TotalUnidadesActivas, 2)}) `}
                                        </span>
                                        <span className={`text-muted`}>Assets Admin</span>
                                    </div>
                                </div>
                                <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto `} style={{ backgroundColor: "#b6fffe " }}                                >
                                    <div className="m-3 text-center">
                                        <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                            <span style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowVehiculos(true)
                                                    setdataVehiculosModal({ ...dataVehiculosModal, EsFiltrado: true, property: 'SiteName', valor: 'Zona Nuevos' });
                                                }
                                                }>
                                                {FiltroDashBoardBasic.getTotalZonaNuevo(dataUnidadesFiltrada)}</span></h2>
                                        <span className="fs-5 text-muted">
                                            {`(${locateFormatPercentNDijitos(FiltroDashBoardBasic.getTotalZonaNuevo(dataUnidadesFiltrada) / IndicadoresDatos.TotalUnidadesFiltrada, 2)}) `}
                                        </span>
                                        <span className={`text-muted`}> Zona Nuevos</span>
                                    </div>
                                </div>
                                <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto `} style={{ backgroundColor: "#b6fffe " }}                                >
                                    <div className="m-3 text-center">
                                        <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                            <span style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowVehiculos(true)
                                                    setdataVehiculosModal({ ...dataVehiculosModal, EsFiltrado: true, property: 'SiteName', valor: 'OtrosSites' });
                                                }
                                                }>{FiltroDashBoardBasic.getTotalOtrosSites(dataUnidadesFiltrada)}</span></h2>
                                        <span className="fs-5 text-muted">
                                            {`(${locateFormatPercentNDijitos(FiltroDashBoardBasic.getTotalOtrosSites(dataUnidadesFiltrada) / IndicadoresDatos.TotalUnidadesFiltrada, 2)}) `}
                                        </span>
                                        <span className={`text-muted`}>Otros Sites</span>
                                    </div>
                                </div>
                                <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto `} style={{ backgroundColor: "#b6fffe " }}                                >
                                    <div className="m-3 text-center">
                                        <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                            <span style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowVehiculos(true)
                                                    setdataVehiculosModal({ ...dataVehiculosModal, EsFiltrado: true, property: 'SiteName', valor: 'Zona Decom' });
                                                }
                                                }>
                                                {FiltroDashBoardBasic.getTotalZonaDecom(dataUnidadesFiltrada)}</span></h2>
                                        <span className="fs-5 text-muted">
                                            {`(${locateFormatPercentNDijitos(FiltroDashBoardBasic.getTotalZonaDecom(dataUnidadesFiltrada) / IndicadoresDatos.TotalUnidadesFiltrada, 2)}) `}
                                        </span>
                                        <span className={`text-muted`}>Zona Decom</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-4 mt-1">
                        <Indicador ayuda={
                            <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Transmision"].title, Tooltips["Transmision"].content.replace("$", TxUltimaActualizacion))} >
                                <i className="float-end bi-info-circle-fill"></i></HtmlTooltipStyled>
                        } descripcion={`Cantidad total de ${IndicadoresDatos.TotalSinTx} vehiculos con problemas de transmisión y su porcentaje en relación de activos con fecha de ${TxUltimaActualizacion}`}
                            className=" shadow card-stretch  mb-5  mb-xxl-8"
                            Titulo={`Transmisión (${IndicadoresDatos.TotalSinTx}) ${calcularPorcentaje(IndicadoresDatos.TotalSinTx, IndicadoresDatos.TotalUnidadesFiltrada)}`}
                            Subtitulo="">
                            {/* begin::Info */}
                            <div className="fw-bolder text-muted text-center fs-6">
                                <div className="row align-items-start">

                                    {(FiltroDashBoard.getAssetSinTX(dataSinTxFiltrada, lstEstadosSinTx).sort((a: any, b: any) => {
                                        return a.Orden - b.Orden;
                                    }).map((val: any, index: any) => {
                                        return <div key={index} className="d-flex justify-content-between" style={{ width: "100%" }}>
                                            <div key={index + 1} title={val.Estado} className="fs-7  text-truncate" style={{ cursor: 'context-menu', textAlign: 'justify', width: '60%' }}>{val.Estado}: </div>


                                            <div style={{ width: '15%' }} key={index + 2} title={val.Estado} className="fs-7 " onClick={() => {
                                                setestadoSinTxSel(val.estadoIdS);
                                                setSemaforo(null);
                                                setshowModalSinTx(true);
                                            }
                                            }>
                                                <span title={val.Estado} style={{ cursor: 'pointer' }} className="rounded-pill p-1 shadow btn-primary">{val.Total}</span>
                                            </div>
                                            <div style={{ width: '25%' }} title={val.Estado} key={index + 3} className="fs-7   m-1">

                                                <span className="rounded-pill p-1 shadow">{locateFormatPercentNDijitos(val.Total / dataSinTxFiltrada.length, 2)}</span>
                                            </div>
                                        </div>
                                    }))}

                                </div>

                            </div>
                            {/* end::Info */}
                        </Indicador>

                    </div>
                    <div className="col-xl-4 mt-1">
                        <Indicador ayuda={<HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Seniales"].title, Tooltips["Seniales"].content.replace("$1", FechasSeniales.FechaInicial).replace("$2", FechasSeniales.FechaFinal))} ><i className="float-end bi-info-circle-fill"></i></HtmlTooltipStyled>} onClick={() => {
                            // setTipoReporte("0");
                            // setShow(true);
                        }} descripcion="Cantidad total de vehiculos con problemas de señal y su porcentaje en relación de activos"
                            className=" shadow card-stretch  mb-5  mb-xxl-8"
                            Titulo={`Señales (${IndicadoresDatos.TotalFallas}) ${calcularPorcentaje(IndicadoresDatos.TotalFallas, IndicadoresDatos.TotalUnidadesFiltrada)}`}
                            Subtitulo=""
                            path="/media/icons/duotone/Home/Home-heart.svg">
                            {/* begin::Info */}
                            <div className="row align-items-start">

                                {
                                    (FiltroDashBoard.getFallasAsset(dataSenialesFiltrada).map((val: any, index: any) => {
                                        return <div key={index} className="d-flex justify-content-between" style={{ width: "100%" }}>
                                            <div key={index + 1} title={val.Falla} className="fs-7  text-truncate" style={{ cursor: 'context-menu', textAlign: 'justify', width: '60%' }}>{val.Falla} </div>

                                            <div title={val.Falla} key={index + 2} className="fs-7   " style={{ width: '15%' }} onClick={() => {
                                                setshowModalSeniales(true);
                                                setfallaSeniales(val.Falla);
                                            }}>
                                                <span title={val.Falla} style={{ cursor: 'pointer' }} className="mt-5 rounded-pill p-1 shadow btn-primary">
                                                    {val.Total}
                                                </span>
                                            </div>
                                            <div title={val.Falla} key={index + 3} style={{ width: '25%' }} className="fs-7  rounded-pill m-1  btn-secundary">
                                                <span title={val.Falla} className="rounded-pill p-1 shadow">{locateFormatPercentNDijitos(val.Total / dataSenialesFiltrada.length, 2)}</span>
                                            </div>
                                        </div>
                                    }))
                                }
                            </div>
                            {/* end::Info */}
                        </Indicador>
                    </div>
                    {<div className="col-xl-4 mt-1">
                        <Indicador ayuda={<HtmlTooltipStyled placement="left" arrow
                            title={HtmlContentTitle(Tooltips["Ticket"].title, Tooltips["Ticket"].content.replace("$", (vUser.perfil == "118" ? vUser.Nombres : "todos los administradores")))} >
                            <i className="float-end bi-info-circle-fill"></i></HtmlTooltipStyled>}
                            descripcion="Cantidad total de tickets" className="shadow card-stretch  mb-5  mb-xxl-8"
                            Titulo={`Distribución de Activos`} Subtitulo="" >
                            <div className="row align-items-start">

                                {
                                    (Object.entries(FiltroDashBoardBasic.getDistribucionActivos(dataUnidadesFiltrada)).map((val: any, index: any) => {
                                        return <div key={index} className="d-flex justify-content-between" style={{ width: "100%" }}>
                                            <div key={index + 1} title={val[0]} className="fs-7  text-truncate" style={{ cursor: 'context-menu', textAlign: 'justify', width: '60%' }}>{val[0]} </div>

                                            <div title={val[0]} key={index + 2} className="fs-7   " style={{ width: '15%' }} onClick={() => {
                                                setShowVehiculos(true);
                                                setdataVehiculosModal({
                                                    ...dataVehiculosModal, EsFiltrado: true,
                                                    property: 'DeviceType', valor: val[0]
                                                });
                                            }}>
                                                <span title={val[0]} style={{ cursor: 'pointer' }} className="mt-5 rounded-pill p-1 shadow btn-primary">
                                                    {val[1]}
                                                </span>
                                            </div>
                                            <div title={val[0]} key={index + 3} style={{ width: '25%' }} className="fs-7  rounded-pill m-1  btn-secundary">
                                                <span title={val[0]} className="rounded-pill p-1 shadow">{locateFormatPercentNDijitos(val[1] / dataUnidadesFiltrada.length, 2)}</span>
                                            </div>
                                        </div>
                                    }))
                                }
                            </div>
                        </Indicador>

                    </div>}


                </div>
                {/* begin::Row */}
                <div className="pt-10">
                    <div className="row">
                        <div className="col-6">
                            <div className="mt-5 mx-4">
                                <h5>Requerimientos Vehículos</h5>
                                {/* Busqueda de equipos especiales */}
                                <button className="btn btn-sm btn-primary" onClick={() => {
                                    setshowEspecial(true);
                                }} title="Busqueda de equipos especiales" ><i className="bi bi-search"></i></button>
                                <>&nbsp;</>
                                {/* Carga masiva */}
                                <button className="btn btn-sm btn-warning" onClick={() => {
                                    setshowMasivos(true);
                                }} title="Carga masiva de requerimientos" ><i className="bi bi-upload"></i></button>
                            </div>
                            <TabNuevoRequerimientos CrearRequerimiento={CrearRequerimiento} />
                        </div>
                        <div className="col-6">
                            <div className="card shadow rounded" style={{ height: 600 }}>
                                {(DataEstadosPorColor.length > 0 ? <MaterialReactTable
                                    localization={MRT_Localization_ES}
                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            muiTableHeadCellProps: {
                                                align: 'center',
                                            },
                                            size: 100,
                                        },
                                    }}
                                    muiTableHeadCellProps={{
                                        sx: () => ({
                                            fontSize: 14,
                                            fontStyle: 'bold',
                                            color: 'rgb(27, 66, 94)'
                                        }),
                                    }}
                                    columns={EstadosPorColorCampos(1)}
                                    data={DataEstadosPorColor}
                                    enableColumnOrdering
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enablePagination={false}
                                    enableSorting={false}
                                    enableColumnActions={false}
                                    enableRowVirtualization
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '400px' }, //give the table a max height
                                    }}
                                    initialState={{ density: 'compact' }}
                                    enableColumnDragging={false}
                                    enableFilters
                                    enableTopToolbar={false}

                                /> : <Spinner className="mt-4" animation={"border"}> </Spinner>)}
                                {/* Espacio para tablas adicioonales  */}
                                {(isShowTableReq) && (DataEstadosPorColorR.length) && (<MaterialReactTable
                                    localization={MRT_Localization_ES}
                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            muiTableHeadCellProps: {
                                                align: 'center',
                                            },
                                            size: 100,
                                        },
                                    }}
                                    muiTableHeadCellProps={{
                                        sx: () => ({
                                            fontSize: 14,
                                            fontStyle: 'bold',
                                            color: 'rgb(27, 66, 94)'
                                        }),
                                    }}
                                    columns={EstadosPorColorCampos(2)}
                                    data={DataEstadosPorColorR}
                                    enableColumnOrdering
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enablePagination={false}
                                    enableSorting={false}
                                    enableColumnActions={false}
                                    enableRowVirtualization
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '400px' }, //give the table a max height
                                    }}
                                    initialState={{ density: 'compact' }}
                                    enableColumnDragging={false}
                                    enableFilters
                                    enableTopToolbar={false}

                                />)}

                            </div>
                        </div>
                    </div>
                </div>
            </BlockUi>

            {showModalSinTx && (<ModalSinTx
                estado={estadoSinTxSel}
                show={showModalSinTx}
                setShow={setshowModalSinTx}
                semaforo={semaforo} />)}

            {showModalSeniales && (<ModalFallas
                estado={fallaSeniales}
                show={showModalSeniales}
                setShow={setshowModalSeniales} />)}

            {(showr) && (<ModalNuevoRequerimiento
                row={RegistroSeleccionado}
                show={showr}
                setShow={setShowr} />)}

            {(showEspecial) && (<ModalEquiposEspeciales
                CrearRequerimiento={CrearRequerimiento}
                show={showEspecial}
                setShow={setshowEspecial} />)}            {/* end::Row */}

            {(showMasivos) && (<ModalRequerimientosMasivos
                showMasivos={showMasivos}
                setshowMasivos={setshowMasivos}
                setloader={setLoader} />)}

            {(showRequerimientos) && (<ModalListadoReq
                show={showRequerimientos}
                setShow={setshowRequerimientos}
                estado={estadoReqSel}
                semaforo={semaforo} />)}

            {(showVehiculos) && (<ModalVehiculos
                show={showVehiculos}
                setShow={setShowVehiculos}
                esFiltrado={dataVehiculosModal.EsFiltrado}
                property={dataVehiculosModal.property}
                valor={dataVehiculosModal.valor} />)}

        </>
    )
}


