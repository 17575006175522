export default function Cargando() {
  return <span className="indicator-progress" style={{ display: "block" }}>
    Cargando...{" "}
    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
  </span>
}
export const CargandoTitulo: React.FC<{ mensaje: string }> = ({ mensaje }) => {
  return<span className="indicator-progress text-primary fs-4 mx-4" style={{ display: "block" }}>
  {mensaje}
  <span className=" text-primary spinner-border spinner-border-sm align-middle ms-2"></span>
</span>

}

export function CargandoSyscaf(Titulo: string, subtitulo: string) {
  return <div style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#0077cc', // Color azul oceano
    padding: '20px',
    borderRadius: '8px',
    color: '#fff', // Color del texto
  }}>
    <span className="fs-5 fw-bold" style={{ margin: 0 }}>{Titulo}... </span>
    <br />
    {(subtitulo !== "") && (<p style={{ margin: 0 }}>{subtitulo}</p>)}

  </div>
}




