import { useDataRequerimientos } from "../../../core/RequerimientosCore"


export const PreguntasModelo = (row: any) => {
    const { ListadoDLPRespuesta, setListadoDLPRespuesta} = useDataRequerimientos();    
 return <input
                className="Respuestas form-check-input"
                type="checkbox"
                style={{ borderColor: '#eb3626' }}
                id={`${row.original.order}`}
                title={row.original.label}
                // data-rel={`${row.original.categoria}${row.original.order}`} 
                checked={row.original.Respuesta}
                value={row.original.Respuesta}
                onChange={(e: any)=>{
                    let listado = [...ListadoDLPRespuesta];
                    
                    setListadoDLPRespuesta(listado.map((a: any) => {
                        if (a.id == e.currentTarget.attributes['id'].value) {
                            a.Respuesta = e.target.checked;
                            a.disabledstate = (e.target.checked ? false : true);
                            a.disabledobs = (a.Estado != "" && e.target.checked ? false : true)
                        }
                        return a;
                    }));
                }}
            />
           
      
    
}