import { useEffect, useState, useRef } from "react";
import { PageTitle } from "../../../../../_start/layout/core";
import BlockUi from "@availity/block-ui";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { Campos, GetGruposSeguridad, GetListas, GetSitiosGrupoSeguridadClientes, SetGruposSeguridad, getClientesGrupoDeSeguridad, getListadoClientes, getListadoUsuarios, getUsuariosGrupoDeSeguridad, setClientesGrupoDeSeguridad, setSitiosGrupoDeSeguridad, setUsuariosGrupoDeSeguridad } from "../data/GruposSeguridad";
import { AxiosResponse } from "axios";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DeleteForever, BorderColor, GroupAdd, PersonAdd, Check, StickyNote2Sharp } from "@mui/icons-material";
import { Button, Modal } from "react-bootstrap-v5";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../_start/helpers/components/ConfirmDialog";
import { dualList } from "../models/GruposSeguridadModels";
import DualListBox from "react-dual-listbox";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { DeserializarJSon, EsJson } from "../../../../../_start/helpers/Helper";
export const Etiqueta = (valor: any) => {
    return valor ? <span className="badge bg-success">Activo</span> : <span className="badge bg-danger">Inactivo</span>
}
export default function GruposSeguridad() {
    const divCant = useRef(null);
    const [loader, setloader] = useState<boolean>(false);
    const [gruposSeguridad, setgruposSeguridad] = useState<any[]>([]);
    //Clientes select
    const [lstClientesDual, setlstClientesDual] = useState<dualList[]>([]);
    const [lstClientes, setlstClientes] = useState<dualList[]>([]);
    const [Clientes, setClientes] = useState<any[]>([]);
    const [selectedClientes, setselectedClientes] = useState([]);
    const [ClientesSelected, setClientesSelected] = useState("");
    //User Select
    const [lstUsuariosDual, setlstUsuariosDual] = useState<dualList[]>([]);
    const [lstSitiosDual, setlstSitiosDual] = useState<dualList[]>([]);
    const [lstUsuarios, setlstUsuarios] = useState<dualList[]>([]);
    const [lstSiglas, setlstSiglas] = useState<any[]>([]);
    const [selectedUsuarios, setselectedUsuarios] = useState([]);
    const [selectedSitios, setselectedSitios] = useState([]);
    const [UsuariosSelected, setUsuariosSelected] = useState("");
    const [SitiosSelected, setSitiosSelected] = useState("");
    const [descripcion, setdescripcion] = useState("");
    const [nombreGrupo, setnombreGrupo] = useState("");
    const [row, setrow] = useState<any>({});
    const [grupoSeguridadId, setgrupoSeguridadId] = useState<any>({});
    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showModal, setshowModal] = useState<boolean>(false);
    const [showModalClientes, setshowModalClientes] = useState<boolean>(false);
    const [showModalUsuarios, setshowModalUsuarios] = useState<boolean>(false);
    const [showModalSitios, setshowModalSitios] = useState<boolean>(false);
    const [tittleModal, settittleModal] = useState('');
    const [IsAutomatico, setIsAcutomatico] = useState<boolean>(false);
    useEffect(() => {
        Consultar();
        return () => { }
    }, [])

    useEffect(() => {
        setUsuariosSelected(selectedUsuarios.join());
        setClientesSelected(selectedClientes.join());
        setSitiosSelected(selectedSitios.join());
    }, [selectedUsuarios, selectedClientes, selectedSitios])
    const ConsultarGrupos = () =>{
        //Traemos los grupos de seguridad
        GetGruposSeguridad(null).then((response: AxiosResponse<any>) => {
            setgruposSeguridad(response.data);
            setRowCount(response.data.length);
        }).catch(() => {
            setIsError(true);
            setloader(false);
            setIsLoading(false);
            setIsRefetching(false)
        }).finally(() => {
            setloader(false);
        });
    }
    const Consultar = () => {
        setloader(true);
        setIsLoading(true);
        setIsRefetching(true)

        ConsultarGrupos();

        //traemos listado de clientes  
        getListadoClientes().then((response: AxiosResponse<any>) => {
            setlstClientes(response.data);
            setClientes(response.data);
        }).catch(() => {
            setIsError(true);
            setloader(false);
            setIsLoading(false);
            setIsRefetching(false)
        }).finally(() => {
            setloader(false);
        });

        getListadoUsuarios().then((response: AxiosResponse<any>) => {
            setlstUsuarios(response.data);
            setloader(false);
            setIsLoading(false);
            setIsRefetching(false)
        }).catch(() => {
            setIsError(true);
            setloader(false);
            setIsLoading(false);
            setIsRefetching(false)
        }).finally(() => {
            setloader(false);
        });

        GetListas().then((response: AxiosResponse<any>) => {
            setlstSiglas(response.data);
            setloader(false);
        }).catch(() => {
            setloader(false);
        }).finally(() => {
            setloader(false);
        });
    }
    const setDataModalEdit = (row: any) => {
        setrow(row);
        setnombreGrupo(row.NombreGrupo)
        setdescripcion(row.Descripcion);
        settittleModal(`Edición de grupos seguridad para ${row.NombreGrupo}`);
        setshowModal(true);

    }
    //permite editar nombre, descripción y estado de grupo de seguridad
    const setGrupoSeguridad = (clave: string, esactivo: boolean, grupoSeguridadId: number | null) => {
        confirmarDialog(() => {
            setloader(true);
            var gruposeguridadid = clave == '1' ? null : grupoSeguridadId == null ? row.GrupoSeguridadId : grupoSeguridadId;
            SetGruposSeguridad(nombreGrupo, descripcion, gruposeguridadid, clave, esactivo).then((response: AxiosResponse<any>) => {

                if (clave != '1') {
                    if (response.data.data[0]["Mensaje"] == 'Grupo de seguridad modificado Éxitosamente') {
                        let grupoSeguridad = (gruposSeguridad as any[]).map(function (m) {
                            if (m.GrupoSeguridadId == row.GrupoSeguridadId) {
                                m.NombreGrupo = nombreGrupo;
                                m.Descripcion = descripcion;
                            }
                            return m;
                        });
                        successDialog("Operación Éxitosa", "");
                        setgruposSeguridad(grupoSeguridad);
                    }
                    else if (response.data.data[0]["Mensaje"] == 'Grupo de seguridad inactivado Éxitosamente') {
                        let grupoSeguridad = (gruposSeguridad as any[]).map(function (m) {
                            if (m.GrupoSeguridadId == grupoSeguridadId) {
                                m.EsActivo = esactivo;
                            }
                            return m;
                        });
                        successDialog("Operación Éxitosa", "");
                        setgruposSeguridad(grupoSeguridad);
                    }
                    else errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
                } else {
                   let a = [...gruposSeguridad];
                   a.push(
                    {
                        "GrupoSeguridadId":response.data.data[0]["Id"],
                        "NombreGrupo":nombreGrupo,
                        "Descripcion":descripcion,
                        "EsActivo":esactivo,
                        "ClienteId":null,
                        "ClienteIdS":null,
                        "clienteNombre":null,
                        "nit":null
                    }
                   )
                    successDialog("Operación Éxitosa", "");
                    setgruposSeguridad(a);
                }


                setloader(false);
                setshowModal(false);
            }).catch(() => {
                errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
                setloader(false);
            });
        }, clave != '1' ? `Está seguro que desea modificar el grupo de seguridad` : `Está seguro que crear el grupo de seguridad`
            , clave != '1' ? "Modificar" : "Crear")

    }

    const setDualListClientes = (row: any) => {

        let dual = lstClientes.map((item: any) => {
            return { "value": item.ClienteId, "label": item.clienteNombre };
        }) as dualList[];

        setlstClientesDual(dual);
        setgrupoSeguridadId(row);

        getClientesGrupoDeSeguridad(row.original.GrupoSeguridadId).then((response: AxiosResponse<any>) => {

            let clienteIds = response.data.map((item: any) => {
                return (item.Clientes != null ? EsJson(item.Clientes) ? DeserializarJSon(item.Clientes) : String(item.ClienteId) : null );
            });
            setselectedClientes((clienteIds[0] == null ? clienteIds: clienteIds[0]));
            setloader(false);
            setIsLoading(false);
            setIsRefetching(false);
            settittleModal(`Asignación de clientes para ${row.original.NombreGrupo}`);
            setshowModalClientes(true);
        }).catch(() => {
            setIsError(true);
            setloader(false);
            setIsLoading(false);
            setIsRefetching(false)
        }).finally(() => {
            setloader(false);
        });
        
    }

    function SelectClientes() {
        return (
            <DualListBox ref={divCant} className=" mb-3 " canFilter
                options={lstClientesDual}
                selected={selectedClientes}
                onChange={(selected: any) => setselectedClientes(selected)}
            />
        );
    }

    const setDualListUsuarios = (row: any) => {

        let dual = lstUsuarios.map((item: any) => {
            return { "value": item.UserId, "label": item.NombreUsuario };
        }) as dualList[];

        setlstUsuariosDual(dual);
        setgrupoSeguridadId(row);

        getUsuariosGrupoDeSeguridad(row.original.GrupoSeguridadId).then((response: AxiosResponse<any>) => {

            let usuariosIds = response.data.map((item: any) => {
                return item.UserId;
            });
            ConsultarGrupos();
            setselectedUsuarios(usuariosIds);
            setloader(false);
            setIsLoading(false);
            setIsRefetching(false);
            settittleModal(`Asignación usuarios para ${row.original.NombreGrupo}`);
            setshowModalUsuarios(true);
        }).catch(() => {
            setIsError(true);
            setloader(false);
            setIsLoading(false);
            setIsRefetching(false)
        }).finally(() => {
            setloader(false);
        });
    }

    function SelectUsuarios() {
        return (
            <DualListBox className=" mb-3 " canFilter
                options={lstUsuariosDual}
                selected={selectedUsuarios}
                onChange={(selected: any) => setselectedUsuarios(selected)}
            />
        );
    }
    function SelectSitios() {
        return (
            <DualListBox className=" mb-3 " canFilter
                options={lstSitiosDual}
                selected={selectedSitios}
                onChange={(selected: any) => setselectedSitios(selected)}
            />
        );
    }
    const setClientesGrupoSeguridad = (row: any) => {
        confirmarDialog(() => {
            setClientesGrupoDeSeguridad(row.original.GrupoSeguridadId, (selectedClientes.length > 0 ? JSON.stringify(selectedClientes.filter(e => e !== null)):null)).then((response: AxiosResponse<any>) => {
                let clienteIds = response.data.map((item: any) => {
                    return item.clienteId;
                });
                ConsultarGrupos();
                setselectedClientes(selectedClientes);
                setloader(false);
                setIsLoading(false);
                setIsRefetching(false);
                successDialog("Operación Éxitosa", "");
            }).catch(() => {
                setIsError(true);
                errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
                setloader(false);
                setIsLoading(false);
                setIsRefetching(false)
            }).finally(() => {
                setloader(false);
            });
        }, `Está seguro que desea modificar los clientes del grupo de seguridad`
            , "Modificar")
        setshowModalClientes(false);
    }

    const setUsuariosGrupoSeguridad = (row: any) => {
        confirmarDialog(() => {
            setUsuariosGrupoDeSeguridad(row.original.GrupoSeguridadId, UsuariosSelected).then((response: AxiosResponse<any>) => {
                let usuariosIds = response.data.map((item: any) => {
                    return item.UserId;
                });
                ConsultarGrupos();
                setselectedUsuarios(usuariosIds);
                setloader(false);
                setIsLoading(false);
                setIsRefetching(false);
                successDialog("Operación Éxitosa", "");
            }).catch(() => {
                setIsError(true);
                errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
                setloader(false);
                setIsLoading(false);
                setIsRefetching(false)
            }).finally(() => {
                setloader(false);
            });
        }, `Está seguro que desea modificar los usuarios del grupo de seguridad`
            , "Modificar")

        setshowModalUsuarios(false);

    }
    const setSitiosGrupoSeguridad = (row: any) => {
        console.log(lstSiglas[0].ListaId);
        confirmarDialog(() => {
            setSitiosGrupoDeSeguridad(row.original.GrupoSeguridadId,String(lstSiglas[0].ListaId), SitiosSelected).then((response: AxiosResponse<any>) => {
                let Sites = response.data.map((item: any) => {
                    return item.SiteId;
                });
                ConsultarGrupos();
                setselectedSitios(Sites);
                setloader(false);
                setIsLoading(false);
                setIsRefetching(false);
                successDialog("Operación Éxitosa", "");
            }).catch(() => {
                setIsError(true);
                errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
                setloader(false);
                setIsLoading(false);
                setIsRefetching(false)
            }).finally(() => {
                setloader(false);
            });
        }, `Está seguro que desea modificar los sitios del grupo de seguridad`
            , "Modificar")

        setshowModalSitios(false);
    }
const GetSitios = (row:any) =>{
    GetSitiosGrupoSeguridadClientes(row.original.GrupoSeguridadId, (row.original.Clientes != null ? (JSON.parse(row.original.Clientes).length > 0 ? JSON.parse(row.original.Clientes).join():null):null)).then((response: AxiosResponse<any>) => {
        let SitiosSelected = response.data.filter((item: any) => {
            return (item.EsSeleccionado == 1) ;
        }).map((val:any) => val.siteId);

        let dual = response.data.map((item: any) => {
            return { "value": item.siteId, "label": item.sitioNombre };
        }) as dualList[];
        setgrupoSeguridadId(row);
        setselectedSitios(SitiosSelected);
        setlstSitiosDual(dual);
        setloader(false);
        setIsLoading(false);
        setIsRefetching(false);
        settittleModal(`Asignación de sitios para ${row.original.NombreGrupo}`);
        setshowModalSitios(true);
    }).catch(() => {
        setIsError(true);
        setloader(false);
        setIsLoading(false);
        setIsRefetching(false)
    }).finally(() => {
        setloader(false);
    });
}
    return (<>
        <PageTitle>Grupos de seguridad</PageTitle>
        <BlockUi tag="div" keepInView blocking={loader ?? false}  >
            {/* Este espacio es la cabecera de la pagina */}
            <div className="card">
                <div className="d-flex justify-content-between mb-2">
                    <div className="mx-auto">
                        <div className="ms-3 text-center">
                            <h3 className="mb-0">Grupos de seguridad</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="card">
                    <div className="row">
                        <div className="col-4">

                        </div>
                        <div className="col-4">

                        </div>
                        <div className="col-4 text-end">
                                <button className="m-2 ms-0 btn btn-sm btn-primary" type="button" onClick={() => {
                                    setIsAcutomatico(false);
                                    setshowModal(true);
                                    settittleModal('Crear grupo de seguridad');
                                    setnombreGrupo('');
                                    setdescripcion('');
                                }}>
                                    {/* <i className="bi-plus-square"></i> */}
                                    Nuevo</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="card">
                    {(gruposSeguridad.length != 0) && (<MaterialReactTable
                        // tableInstanceRef={ColumnasTablas['movil']}
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 75,
                                maxSize: 75
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: (theme) => ({
                                fontSize: 14,
                                fontStyle: 'bold',
                                color: 'rgb(27, 66, 94)'
                            }),
                        }}
                        columns={Campos}
                        data={gruposSeguridad}
                        enablePagination={false}
                        enableStickyHeader
                        enableDensityToggle={false}
                        enableRowVirtualization
                        enableEditing
                        editingMode="modal" //default         
                        enableColumnOrdering
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: 'Error al cargar información',
                                }
                                : undefined
                        }
                        onColumnFiltersChange={setColumnFilters}
                        onGlobalFilterChange={setGlobalFilter}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                        rowCount={rowCount}
                        initialState={{ density: 'compact' }}
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'block', gap: '1rem', marginLeft: 'auto', marginRight: 'auto' }}>
                                  {(!row.original.isAutomatic) && (<Tooltip arrow placement="top" title="Editar">
                                    <IconButton className="text-success" onClick={() => {
                                        setDataModalEdit(row.original);
                                        setIsAcutomatico(row.original.isAutomatic);
                                    }}>
                                        <BorderColor />
                                    </IconButton>
                                </Tooltip>)
                                }
                                <Tooltip arrow placement="top" title="Asignar Clientes">
                                    <IconButton className="text-warning" onClick={() => {
                                        setDualListClientes(row);
                                        setIsAcutomatico(row.original.isAutomatic);
                                    }}>
                                        <GroupAdd />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip arrow placement="top" title={"Sitios"}>
                                    <IconButton className="text-info" onClick={() => {
                                        GetSitios(row);
                                        setIsAcutomatico(row.original.isAutomatic);
                                    }
                                    }>
                                        <StickyNote2Sharp/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip arrow placement="top" title="Asociar Usuarios">
                                    <IconButton className="text-primary" onClick={() => {
                                        setDualListUsuarios(row);
                                           setIsAcutomatico(row.original.isAutomatic);
                                    }}>
                                        <PersonAdd />
                                    </IconButton>
                                </Tooltip>
                                {
                                    (!row.original.isAutomatic) && (<Tooltip arrow placement="top" title={row.original.esActivo ? "Inactivar" : "Activar"}>
                                    <IconButton className="text-danger" onClick={() => {
                                        setGrupoSeguridad('3', (row.original.EsActivo ? false:true), row.original.GrupoSeguridadId);
                                        setIsAcutomatico(row.original.isAutomatic);
                                    }
                                    }>
                                        {row.original.EsActivo ? <DeleteForever /> : <Check />}
                                     
                                    </IconButton>
                                </Tooltip>)
                                }
                                
                               
                            </Box>
                        )}
                        state={{
                            columnFilters,
                            globalFilter,
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching,
                            sorting,
                        }}
                        renderTopToolbarCustomActions={({ table }) => (
                            <Box
                                sx={{ justifyContent: 'flex-start', alignItems: 'center', flex: 1, display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >

                            </Box>
                        )}
                    />)}
                </div>

            </div>
        </BlockUi>
        <Modal
            show={showModal}
            onHide={setshowModal}
            size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{tittleModal}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-6">
                        <div className="form-control-sm">
                            <label className="control-label label-sm fw-bolder " htmlFor="nombregrupo" >Nombre Grupo:</label>
                            <input className="form-control  input input-sm " id={"nombregrupo"} onChange={(e) => setnombreGrupo(e.target.value)} value={nombreGrupo}></input>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-control-sm">
                            <label className="control-label label-sm fw-bolder" htmlFor="descripcion">Descripción:</label>
                            <textarea className="form-control  input input-sm " id={"descripcion"} onChange={(e) => setdescripcion(e.target.value)}
                                rows={3} value={descripcion}></textarea>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button  style={{display:(!IsAutomatico ? 'inline':'none')}} className="btn-sm" type="button" variant="primary" onClick={() => {
                    tittleModal != 'Crear grupo de seguridad' ?
                        setGrupoSeguridad('2', true, null) :
                        setGrupoSeguridad('1', true, null);
                }}>
                    Guardar
                </Button>
                <Button type="button" className="btn-sm" variant="secondary" onClick={() => { setshowModal(false); }}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showModalClientes} onHide={setshowModalClientes} size="lg">
            <Modal.Header closeButton> <Modal.Title>{tittleModal}</Modal.Title> </Modal.Header>
            <Modal.Body>
                <SelectClientes />
            </Modal.Body>
            <Modal.Footer>
                <Button  style={{display:(!IsAutomatico ? 'inline':'none')}} className="btn-sm" type="button" variant="primary" onClick={() => { setClientesGrupoSeguridad(grupoSeguridadId); }}>
                    Guardar
                </Button>
                <Button className="btn-sm" type="button" variant="secondary" onClick={() => { setshowModalClientes(false); }}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showModalUsuarios} onHide={setshowModalUsuarios} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{tittleModal}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectUsuarios />
            </Modal.Body>
            <Modal.Footer>
                <Button  style={{display:(!IsAutomatico ? 'inline':'none')}} className="btn-sm" type="button" variant="primary" onClick={() => { setUsuariosGrupoSeguridad(grupoSeguridadId); }}>
                    Guardar
                </Button>
                <Button className="btn-sm" type="button" variant="secondary" onClick={() => { setshowModalUsuarios(false); }}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showModalSitios} onHide={setshowModalSitios} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{tittleModal}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectSitios />
            </Modal.Body>
            <Modal.Footer>
                <Button style={{display:(!IsAutomatico ? 'inline':'none')}}  className="btn-sm " type="button" variant="primary" onClick={() => { setSitiosGrupoSeguridad(grupoSeguridadId); }}>
                    Guardar
                </Button>
                <Button className="btn-sm" type="button" variant="secondary" onClick={() => { setshowModalSitios(false); }}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}