
import { useEffect, useState } from "react";
import { ClientesFatiga} from "../../models/EventosActivos";
import { AxiosResponse } from "axios";
import {  Form } from "react-bootstrap-v5";
import { PageTitle } from "../../../../../../_start/layout/core";
import BlockUi from "@availity/block-ui";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { Campos, FiltrosData,  getConfiguraciones, setConfiguraciones } from "../../data/Configuracion";
import { Check,  Edit, Person } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { GetClientesFatiga } from "../../data/dashBoardData";
import { UpdateUsuarios } from "./modals/modalUsuarios";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../_start/helpers/components/ConfirmDialog";
import { EsPermitido, PermisosOpcion } from "../../../../../../_start/helpers/Axios/CoreService";
import { ModalNuevaAlerta } from "./modals/NuevaAlerta";
import { DeserializarJSon } from "../../../../../../_start/helpers/Helper";
import { ModalContactos } from "./modals/ContactosAlertas";
// import { defaultNuevoArchivoDTO } from "../../../Neptuno/models/neptunoDirectory";


export default function Parametrizacion() {

    const permisosopcion = PermisosOpcion("Configuración");
    const [show, setShow] = useState(false);
    const [lstClientes, setLstClientes] = useState<ClientesFatiga[]>([]);
    const [clienteSeleccionado, setclienteSeleccionado] = useState<ClientesFatiga>({ ClienteIdS: 0, ClienteId: 0, clienteNombre: "" });
    const [Cliente, setCliente] = useState("");
    const [loader, setLoader] = useState<boolean>(false);
    const [Data, setData] = useState<any[]>([]);
    const [DataFilltrada, setDataFilltrada] = useState<any[]>([]);
    const [Filtrado, setFiltrado] = useState<boolean>(false);
    const [Cargar, setcargar] = useState(false);

    /* Variables Deiby */
    const [show2, setShow2] = useState(false);

    const [registroSeleccionado, setregistroSeleccionado] = useState<any>();



    const handleClose4 = () => {
        settituloModalParametrizacion('');
        setShow4(false);
    };

    const [show4, setShow4] = useState(false);

    const [tituloModalParametrizacion, settituloModalParametrizacion] = useState('');
        const showModal4 = (titulo: string, tipo: string) => {
        settituloModalParametrizacion(titulo)
        setShow4(true);
    }
    const [dataContacto, setdataContacto] = useState<any[]>([]);

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [isError, setIsError] = useState(false);
    const [Titulo, setTitulo] = useState("Nueva Alerta")
    const cleanTableState = () => {
        setColumnFilters([]);
        setGlobalFilter('');
        setSorting([]);
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });

    }
    /* fin table */
    const ObtenerClientes = () => {
        GetClientesFatiga().then(
            (response) => {
                setLstClientes(response.data);
            }
        ).catch((error) => {
            errorDialog("Consultar Clientes", "Error al consultar clientes, no se puede desplegar informacion");
        });
    }

    /* ====================================================================================================================================*/
    //
    // ESPACIO PARA LOS USEEFECT 
    //
    /* ====================================================================================================================================*/
    useEffect(() => {
        ObtenerClientes();
    }, [])

    useEffect(() => {
        if (Cliente != null && Cliente != "" && Cliente != "0" && Cargar) {
            GetConfiguracionAlerta([]);
        }
        return () => {

        }
    }, [Cargar == true, Cliente])



    function GetConfiguracionAlerta(data: any) {
        data.nombre = null;
        data.esActivo = null;
        data.clienteId = Cliente;
        setLoader(true);
        setIsLoading(true)
        setIsRefetching(true)
        getConfiguraciones(data).then((response: AxiosResponse<any>) => {
            let Datos = response.data.data;
            setData(Datos);
            setFiltrado(false);
            setRowCount(response.data.data.length);
            setLoader(false);
            setIsLoading(false);
            setIsRefetching(false);
            setIsError(false);
            setcargar(false);
        }).catch(({ error }) => {
            console.log("Eror ", error);
            setIsError(true);
            setIsLoading(false);
            setIsRefetching(false);
            setLoader(false);
        })
    }
    function CargaListadoClientes() {
        return (
            <Form.Select className="mb-2 ms-2 mt-2" style={{ width: 300 }} onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let cliente = lstClientes.filter((value, index) => {
                    return value.ClienteIdS === Number.parseInt(e.currentTarget.value)

                })

                setclienteSeleccionado(cliente.length == 0 ? { ClienteIdS: 0, ClienteId: 0, clienteNombre: "" } : cliente[0]);
                if (cliente[0] != undefined) {
                    let _dt: any = FiltrosData.getEventos(Data, cliente[0].ClienteId);
                    setFiltrado(true)
                    setDataFilltrada(_dt);
                } else
                    setFiltrado(false);
                setCliente((cliente[0] == undefined ? "" : cliente[0].ClienteId.toString()));
                setcargar(true);

            }} aria-label="Default select example" value={clienteSeleccionado?.ClienteIdS}>
                <option value={0}>Seleccione</option>
                {
                    lstClientes.map((element, i) => {
                        return (<option key={element.ClienteIdS} value={element.ClienteIdS}>
                            {element.clienteNombre}</option>)
                    })
                }
            </Form.Select>
        );
    }


    const EditarCampos = (row: any) => {
        cleanTableState();
        setTitulo(`Edición de Alerta`);
        setregistroSeleccionado({ ...row.original, clave: 2, Detalles: DeserializarJSon(row.original.detalles) });
        setShow(true);

    }
    const CamposNuevos = () => {
        cleanTableState();
        setTitulo(`Nueva alerta`)
        setregistroSeleccionado(null);
        setShow(true)

    };
    const CambiarEstado = (row: any) => {
        let Datos : any = {};
        Datos['clave'] = "3";
        Datos['esActivo'] = (row.original.esActivo == true ? false : true);
        Datos['configuracionAlertaId'] = row.original.configuracionAlertaId;
        confirmarDialog(() => {
            setConfiguraciones(Datos).then((response: AxiosResponse<any>) => {
                successDialog("Opeación Éxitosa.", "");
                setShow(false);
                setcargar(true);
            }).catch(({ error }) => {
                errorDialog("Ha ocurrido un error", "");
                setShow(false);
            })
        }, `¿Esta seguro que desea cambiar el estado?`, 'Cambiar');
    }
    //Funcion que muestra la gestión de los contactos 
    // para las alertas de un cliente
    const modalContactos = (row: any) => {
        cleanTableState();
        setTitulo('Gestionar Contactos')
        setregistroSeleccionado(row);

        // el objetivo es tener el total de los contactos de todas las alertas
        // que se pueda usar como directorio de contactos con el fin de no repetir
        // la escritura en las demás alertas sin tener hacer una nueva opción
        const dataAgrupadaContactos = Data.reduce(
            (p: any, c: any) => {
                const contactosCliente = DeserializarJSon(c.contactos);
                if (contactosCliente != null && contactosCliente.length > 0) // si tiene listado de contactos
                    contactosCliente.forEach((cont: any) => {
                        // verificamos que no exista el correo , se escojerá la primera ocurrencia
                        const result = p.filter((f: any) => f.correo.toLowerCase().trim() === cont.correo.toLowerCase().trim());
                        if (result.length == 0)
                            p.push(cont) // si no existe se adiciona
                    });

                return p;
            }
            , [])

        setdataContacto(dataAgrupadaContactos)
        setShow2(true);
    }

    return (
        <>
            <PageTitle>Configuración Alertas Clientes</PageTitle>
            <BlockUi  tag="div" keepInView blocking={loader ?? false} >
                <div className="d-flex justify-content-between">
                    <div className="mx-auto">
                        <div className="ms-3 text-center">
                            <h3 className="mb-0">Configuración Alertas Clientes</h3>
                            <span className="text-muted m-3">Permite agregar nuevas alertas de eventos específicos de los clientes.</span>
                        </div>
                    </div>
                </div>

                <div className="card d-flex justify-content-start ">
                    <div className="bg-secondary d-flex flex-row  justify-content-start mt-2 rounded shadow">


                        <label className="form-label mt-4 mx-4">Selección Cliente:</label>
                        <CargaListadoClientes></CargaListadoClientes>
                        <button className="btn btn-sm btn-primary mb-2 ms-2 mt-2 " style={{ display: (clienteSeleccionado.ClienteIdS == 0) ? 'none' : 'block' }}
                            onClick={CamposNuevos}>Nueva Alerta</button>
                        { (EsPermitido(permisosopcion, "FATGADDUSER")) && ( 
                        <button className={`btn btn-sm btn-primary mb-2 ms-2 mt-2` }
                        style={{ display: (clienteSeleccionado.ClienteIdS == 0) ? 'none' : 'block' }}
                            onClick={() => {
                                showModal4('Configuración Usuarios Torre de Control', '');
                            }}>Usuarios</button>
                       )} 
                    </div>
                    <div className="mt-1 rounded">
                     <MaterialReactTable
                            localization={MRT_Localization_ES}
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 120,
                                },
                            }}
                            muiTableHeadCellProps={{
                                sx: (theme) => ({
                                    fontSize: 14,
                                    fontStyle: 'bold',
                                    color: 'rgb(27, 66, 94)'
                                }),
                            }}
                            columns={Campos}
                            data={(Filtrado ? DataFilltrada : Data)}
                            muiTableBodyCellProps={({ row, cell }) => ({

                                sx: {

                                    backgroundColor:

                                        cell.column.id == 'Min Rojo'  ? 'rgba(248, 215, 218, 1)' :
                                            cell.column.id == 'Max Verde' ? 'rgba(212, 237, 218, 1)' :
                                                cell.column.id == 'minAmber' || cell.column.id == 'maxAmber'
                                                    ? 'rgba(255, 243, 205, 1)' : ''
                                }

                            })}
                            enableColumnOrdering
                            enableEditing
                            editingMode="modal"
                            muiToolbarAlertBannerProps={
                                isError
                                    ? {
                                        color: 'error',
                                        children: 'Error al cargar información',
                                    }
                                    : undefined
                            }
                            onColumnFiltersChange={setColumnFilters}
                            onGlobalFilterChange={setGlobalFilter}
                            onPaginationChange={setPagination}
                            onSortingChange={setSorting}
                            rowCount={rowCount}
                            enableStickyHeader
                            enableDensityToggle={false}
                            enablePagination={false}
                            enableRowVirtualization
                            defaultColumn={{
                                minSize: 150, //allow columns to get smaller than default
                                maxSize: 400, //allow columns to get larger than default
                                size: 150, //make columns wider by default
                            }}
                            muiTableContainerProps={{
                                sx: { maxHeight: '400px' }, //give the table a max height
                            }}
                            initialState={{ density: 'compact' }}
                            state={{
                                columnFilters,
                                globalFilter,
                                isLoading,
                                pagination,
                                showAlertBanner: isError,
                                showProgressBars: isRefetching,
                                sorting,
                            }}
                            renderRowActions={({ row, table }) => (
                                <Box sx={{ display: 'block', gap: '1rem', marginLeft: 'auto', marginRight: 'auto' }}>

                                    <Tooltip arrow placement="top" title="Editar alerta">
                                        <IconButton onClick={() => {
                                            EditarCampos(row);
                                        }}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow placement="top" title="Contactos">
                                        <IconButton onClick={() => {
                                            modalContactos(row.original);
                                        }}>
                                            <Person />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow placement="top" title="Desactivar alerta">
                                        <IconButton onClick={
                                            () => {
                                                CambiarEstado(row);
                                            }}>
                                            <Check />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )}
                        />
                    </div>
                </div>

            </BlockUi>


            {(show) && (
                <ModalNuevaAlerta show={show} setShow={setShow} cliente={clienteSeleccionado} row={registroSeleccionado} titulo={Titulo} GetConfiguracionAlerta={GetConfiguracionAlerta} />
            )}
            {(show2) && (
                <ModalContactos show={show2} setShow={setShow2} cliente={clienteSeleccionado}
                    registroSeleccionado={registroSeleccionado} titulo={Titulo} GetConfiguracionAlerta={GetConfiguracionAlerta} DirContacto={dataContacto} />)}




            {(show4) && (
                <UpdateUsuarios show={show4} handleClose={handleClose4} title={tituloModalParametrizacion} Cliente={clienteSeleccionado} />

            )}
        </>

    )
}
