import MaterialReactTable from "material-react-table"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { Button, Modal } from "react-bootstrap-v5"
import { ColumnasPreguntasDiag } from "../../../../data/DiagnosticoData"
import { ConsecutivoVisual } from "../../../../data/Requerimientos"
import { EncabezadoHtml } from "../Encabezado"

type ProposModalDiagnostico = {
    ShowModalDiag: any,
    setShowModalDiag: any,
    ReqSeleccionado: any,
    
    InfDiag: any,
    InfObs: any
}
export const ModalDiagnostico: React.FC<ProposModalDiagnostico> = ({ ShowModalDiag, setShowModalDiag, ReqSeleccionado, InfDiag, InfObs }) => {
    return (
        <Modal show={ShowModalDiag} onHide={setShowModalDiag} backdropClassName="static" size="xl">
            <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                <Modal.Title>{`Informe DLP Requerimiento ${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                {/* Encabezado */}
                <div className="container mt-0 pt-0 w-100">
                    <div className="row w-100">
                        <div className="col-12">
                            <EncabezadoHtml ReqSeleccionado={ReqSeleccionado} ></EncabezadoHtml>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {(InfDiag.length != 0) && (<MaterialReactTable
                                localization={MRT_Localization_ES}
                                muiTableHeadCellProps={{
                                    sx: () => ({
                                        fontSize: 14,
                                        fontStyle: 'bold',
                                        color: 'rgb(27, 66, 94)'
                                    }),
                                }}
                                columns={ColumnasPreguntasDiag}
                                data={InfDiag}
                                enableColumnResizing
                                enableDensityToggle={false}
                                enablePagination={false}
                                enableRowVirtualization
                                enableGrouping
                                enableStickyHeader
                                enableStickyFooter
                                initialState={{
                                    density: 'compact',
                                    expanded: true, //expand all groups by default
                                    grouping: ['categoria'], //an array of columns to group by by default (can be multiple)
                                    sorting: [{ id: 'order', desc: false }], //sort by state by default
                                    columnVisibility: { order: false }
                                }}
                                muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                                muiTableContainerProps={{
                                    sx: { maxHeight: 400 }
                                }}
                            />)}
                        </div>
                    </div>
                    <div className="row mb-5 pb-5">
                        <div className="col-12  pt-5">
                            <label className="control-label label label-sm text-muted fw-bolder">Notas adicionales</label>
                            <textarea disabled rows={3} defaultValue={InfObs} className="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => setShowModalDiag(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}