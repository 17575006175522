import { Form } from "react-bootstrap-v5"
import { useDataRequerimientos } from "../../../core/RequerimientosCore";
type Props = {
    row: any;
};
export const SelectObservaciones: React.FC<Props> = ({ row }) => {
    return (<Form.Label

        className="input-sm  mb-3 mt-3 "
        id={`${row.original.id}`} >
        {row.original.RespuestaObservacion}
    </Form.Label>
    )
}
export const SelectObservacionesEnabled: React.FC<Props> = ({ row }) => {
    const { ListadoDLPRespuesta, setListadoDLPRespuesta } = useDataRequerimientos();
    const observaciones = row.original.observaciones ?? "";
    const observacionesNegativas = row.original.observacionesNegativas ?? "";
    return (
        <Form.Select
            // disabled={row.original.disabledobs}
            title="Seleccione una observacion"
            style={{ height: '40px' }}
            className="input-sm  mb-3 mt-3 "
            onChange={(e: any) => {
                
                let listado = [...ListadoDLPRespuesta];
                setListadoDLPRespuesta(listado.map((a: any) => {
                    a.RespuestaObservacion = (a.id == e.currentTarget.attributes['id'].value ? e.target.value : a.RespuestaObservacion);
                    return a;
                }));
            }}
            defaultValue={row.original.RespuestaObservacion}
            id={`${row.original.id}`}>
            <option value={"Seleccione"}>Seleccione</option>
            {
                (row.original.Estado == true ? observaciones.split(",").map((element: any) => {

                    return (<option key={element} value={element}>{element}</option>)
                }) : observacionesNegativas.split(",").map((element: any) => {

                    return (<option key={element} value={element}>{element}</option>)
                }))
            }
        </Form.Select>
    )
}