
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { GetDetallePeticiones } from "../data/datapeticiones";
import { useToaster } from "rsuite";
import { MensajeError } from "../../../../../_start/helpers/components/Toaster";
import moment from "moment";

export interface ServiciosContextModel {
    loader: boolean;
    lstContadorPeticiones: any[];
    lstPeticionesMalas: any[];
    setlstContadorPeticiones: (listado: any[]) => void;
    setlstPeticionesMalas: (listado: any[]) => void;
    setlloader: (loader: boolean) => void;
}

const ServcContext = createContext<ServiciosContextModel>({
    setlstContadorPeticiones: (listado: any[]) => { },
    setlstPeticionesMalas: (listado: any[]) => { },
    setlloader: (lloader: boolean) => { },
    loader: true,
    lstContadorPeticiones: [],
    lstPeticionesMalas: []
});

export const ServiciosProvider: React.FC = ({ children }) => {
    const [lstContadorPeticiones, setlstContadorPeticiones] = useState<any[]>([]);
    const [lstPeticionesMalas, setlstPeticionesMalas] = useState<any[]>([]);
    const [loader, setlloader] = useState<boolean>(true);
    const value: ServiciosContextModel = {
        lstPeticionesMalas,
        setlstPeticionesMalas,
        lstContadorPeticiones,
        setlstContadorPeticiones,
        setlloader, loader
    };
    return (<ServcContext.Provider value={value}> {children} </ServcContext.Provider>);
};

//Hace toda la magia de ir al servidor, traerse los datos y setearlos
export const GetDataServicios: React.FC = ({ children }) => {
    const toaster = useToaster();
    const { setlstPeticionesMalas, setlstContadorPeticiones, setlloader } = useDataServicios();

    const interval = useRef<any>();
    //CONSULTA VEHICULOS OPERANDO
    let consulta = (children: any) => {
        // consultamos en la base de datos la informacion de vehiculos operando
        if(children) {
            const mes : number = moment(children).month();
            const anio : number = moment(children).year();
            setlloader(true);
            GetDetallePeticiones(mes, anio).then((response) => {          
                if (response.status === 200) {
                    setlstContadorPeticiones(response.data.filter((f: any) =>  f.orden !== 5 ))
                    setlstPeticionesMalas(response.data.filter((f: any) =>  f.orden === 5 ))
                    setlloader(false);
                 
                } else
                    toaster.push(MensajeError("Error Consultar Peticiones", 'Error al consultar las peticiones'), {
                        placement: 'topCenter'
                    });
    
            }).catch((e) => {
                toaster.push(MensajeError("Error Consultar Peticiones", 'Error al consultar las peticiones'), {
                    placement: 'topCenter'
                });
            }).finally(() => {
                setlloader(false);
            })
        }
     

    }
    useEffect(() => {
        clearInterval(interval.current)
        if (children) {
            consulta(children);
            interval.current = setInterval(() => {
                consulta(children);
            }, 600000);           
        }

        return () => {
            clearInterval(interval.current);
            setlstPeticionesMalas([]);
            setlstContadorPeticiones([]);
            // limpiamos todas las variables a ser detruidas

        };
    }, [children]);
    return <></>;
};

/** */
export function useDataServicios() {
    return useContext(ServcContext);
}


