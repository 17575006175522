import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useDataRequerimientos } from "../../../core/RequerimientosCore";

type Props = {
    row: any;

  };
  export const RadiosEstadoEnabled: React.FC<Props>  = ({row}) =>{

    const { ListadoDLPRespuesta, setListadoDLPRespuesta} = useDataRequerimientos();
    const CambioEstado = (e: any) => {
        let listado = [...ListadoDLPRespuesta];
    
        setListadoDLPRespuesta(listado.map((a: any) => {
            a.Estado = (a.id == e.currentTarget.attributes['id'].value ? (e.currentTarget.attributes['value'].value == "Si" ? true : false) : a.Estado);
            a.disabledobs = ((a.id == e.currentTarget.attributes['id'].value && e.target.checked && !e.disabledstate) ? false : true);
            return a;
        }));
    }
    
     const value = row.original.Estado ? "Si" : "No";
    return (
        <div style={{ display: 'contents' }}>
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={(row.original.disabledstate) ? null :  value}
                name="radio-buttons-group"
                id={`${row.original.id}`}
                style={{ display: 'block' }}
                
            >
                <FormControlLabel value={row.original.Estado} control={
                <Radio disabled={row.original.disabledstate} value={"Si"} id={`${row.original.id}`} onChange={CambioEstado} />} label="Si" />
                <FormControlLabel value={!row.original.Estado} control={
                <Radio disabled={row.original.disabledstate} value={"No"} id={`${row.original.id}`} onChange={CambioEstado} />} label="No" />
            </RadioGroup>
        </FormControl>
    </div>
    )
  }

  export  const RadiosEstado: React.FC<Props>  = ({row}) =>{
    return(
            <div style={{ display: 'contents' }}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={(row.original.Estado ? "Si" : "No")}
                        name="radio-buttons-group"
                        style={{ display: 'block' }}
                    >
                        <FormControlLabel value={(row.original.Estado ? "Si" : "No")} control={<Radio disabled={true} value={"Si"} />} label="Si" />
                        <FormControlLabel value={(!row.original.Estado ? "Si" : "No")} control={<Radio disabled={true} value={"No"} />} label="No" />
                    </RadioGroup>
                </FormControl>
            </div>
        )
  }