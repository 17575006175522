import React, { useEffect, useState } from "react";
import { useDataPreoperacional } from "../../core/provider";
import { observaciones } from "../../models/dataModels";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import type {
    ColumnFiltersState,
    PaginationState,
    SortingState,
} from '@tanstack/react-table';
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap-v5"
import moment from "moment";
import { FechaServidor, formatableJson_HHMMss, formatSimpleJson } from "../../../../../../_start/helpers/Helper";
import { getEncabezados, Mov_cargarArchivo, setObservaciones } from "../../data/dataPreoperacional";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../_start/helpers/components/ConfirmDialog";
import { FormatoColombiaDDMMYYYHHmm } from "../../../../../../_start/helpers/Constants";
import { InfromacionBasicaEncuesta } from "./Modales/InformacionBasicaEncuesta";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import { AssignmentLate, AssignmentTurnedIn, AttachFile, Close } from "@mui/icons-material";
import { Save } from "react-feather";
import { PREOP_Tooltips } from "../../data/tablaPreoperacional";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";
import { UserModelSyscaf } from "../../../../auth/models/UserModel";


type Props = {
    show: boolean;
    handleClose: () => void;
    title?: string;
    row: any
    clienteid: string;
    fecha: any;
};

export const ModalObservaciones: React.FC<Props> = ({ show, handleClose, title, row, fecha, clienteid }) => {


    const isAuthorized = useSelector<RootState>(
        ({ auth }) => auth.user
    );


    const model = (isAuthorized as UserModelSyscaf);
    const confPreoperacionales = (JSON.parse(model.preoperacional) as any[]).filter(f => f.ClienteId === clienteid);

 
    const Clienteids : string =confPreoperacionales[0].ClienteIds;
    const fechaInicial = moment(fecha).format(formatSimpleJson);
    const fechaFinal = moment(fecha).add(1, 'days').format(formatSimpleJson);
    const { setEncabezados } = useDataPreoperacional();
    const [Data, setData] = useState<observaciones[]>([]);
    const [obervacionGestion, setobervacionGestion] = useState("");
    const [EsGestionadoM, setEsGestionadoM] = useState(row.EsGestionado ?? false);

    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);


    const obervacion = 'Observaciones';
    const [FileName, setFileName] = useState<any>("Ningún archivo seleccionado");
    const [imagenPreview, setImagenPreview] = useState<string | null>(null);
    const [isLoadImage, setisLoadImage] = useState(false);
    const [image, setImage] = useState<any>(null);
    let listadoCampos: MRT_ColumnDef<any>[] =

        [
            {
                accessorKey: 'fecha',
                header: 'Fecha',
                size: 100,
                Cell({ cell }) {
                    return (moment(cell.getValue() as Date).format(FormatoColombiaDDMMYYYHHmm))
                }
            },
            {
                accessorKey: 'value',
                header: 'Observaciones',
                size: 100
            },
            {
                accessorKey: 'UrlImagen',
                header: 'Imagen',
                size: 100,
                Cell({ cell }) {
                    const url = cell.getValue();

                    if (url)
                        return <a href={cell.getValue() as string} >Descargar </a>
                    else
                        return <></>

                }
            }

        ];

    useEffect(() => {

        if (row.Observaciones !== null && row.Observaciones !== "") {
            let json = JSON.parse(row.Observaciones);
            setData(json);
            setRowCount(json.length);
        }
        else {
            setData([]);
            setRowCount(0);
        }
    }, [])

    const getobservacion = (e: any) => {
        setobervacionGestion(e.target.value)
    };

    const setObservacion = (observacion: string, escerrado: number = 0, esaprobado: number = 0) => {

        confirmarDialog(() => {
            // se deber'a primero cargar el adjunbto con una respuesta positiva
            const AddObservaciones = (filename: string | null) => {
                let GestorObervaciones: any = {};

                GestorObervaciones = {
                    EncabezadoId: row.EncabezadoId,
                    fecha: moment(FechaServidor()).format(formatableJson_HHMMss),
                    value: observacion,
                    notificar: "false",
                    EsCerrado: escerrado,
                    esaprobado: esaprobado,
                    UrlImagen: filename

                };
                setObservaciones(JSON.stringify(GestorObervaciones), Clienteids ,row.isHistorical).then((response) => {
                    if (response.status === 200) {
                        successDialog("Operación Éxitosa", "");
                        setData([...Data, JSON.parse(JSON.stringify(GestorObervaciones))] as observaciones[]);
                        setobervacionGestion("");
                        setFileName(false);
                        setImage(null);
                        setisLoadImage(false);
                        setImagenPreview(null);
                        getEncabezados(row.ClienteId, fechaInicial, fechaFinal, null).then((response) => {
                            setEncabezados(response.data);
                            if (escerrado === 1) {
                                setEsGestionadoM(true);
                                handleClose();
                            }
                        });
                    }
                    else
                        errorDialog("Error al guardar observaciones", "");

                }).catch((error) => {
                    errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
                });
            }
            if (image != null) // si tiene imagen procedemos primero a cargar el archivo luego a guardar las observaciones
                Mov_cargarArchivo(image, `$${row.EncabezadoId}`, AddObservaciones);
            else
                AddObservaciones(null); // de lo contrario solo las observaciones

        }, PREOP_Tooltips[`${escerrado}${esaprobado}`].title
            , PREOP_Tooltips[`${escerrado}${esaprobado}`].boton)
    }

    // control para la imagen del reporte TENER UN SOLO CONTROL PARA CARGAR  IMAGENES Y VIDEOS
    const InputControl = () => {
        return <>
            <div>
                <div className="form-input" style={{ height: 200 }} onClick={(e: any) => {
                    let inputClick: any = document.querySelector(".input-field");
                    inputClick.click();
                }}>
                    <input accept="image/*" className="input-field" type="file" hidden
                        onChange={(files: any) => {
                            files.target.files[0] && setFileName(files.target.files[0].name);
                            if (files.target.files) {
                                setImage(files.target.files[0]);
                                const imageUrl = URL.createObjectURL(files.target.files[0]);
                                setImagenPreview(imageUrl);
                            }
                        }}
                    />
                    {imagenPreview ? <img crossOrigin="anonymous" alt={FileName} src={imagenPreview} height={200} width={220} /> : <>
                        <MdCloudUpload color="#1475cf" size={60} />
                        <p>Buscar archivo para cargar</p>
                    </>
                    }
                </div>
                <div className="Uploaded-row">
                    <AiFillFileImage color="#1475cf" />
                    <span className="Upload-content">{FileName}
                        <MdDelete onClick={() => {

                            setImage(null);
                            setisLoadImage(false);
                            setImagenPreview(null);
                            setFileName("");
                        }} />
                    </span>
                </div>
            </div>
        </>
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg">
                <Modal.Header className="m-0" closeButton>
                    <Modal.Title style={{ width: '100%' }}><label
                        className="fs-2 text-center"
                        style={{ width: '100%' }}>{title?.toLocaleUpperCase()}</label></Modal.Title>
                </Modal.Header>
                <Modal.Body className="mt-0">
                    <InfromacionBasicaEncuesta row={row} />
                    <div className="row">
                        <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12">
                            <div className="">
                                <label className="control-label label-sm font-weight-bold" htmlFor="comentario" style={{ fontWeight: 'bold' }}>Adicionar Comentario:</label>
                                <textarea className="form-control  input input-sm " id={obervacion} onChange={getobservacion} rows={3} value={obervacionGestion}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row col-12 " hidden={!isLoadImage}>
                        <div className="text-center">
                            <label className="label control-label fw-bolder">Adjunto Imagen</label>
                        </div>
                        <div className="controlbody">
                            <InputControl></InputControl>
                        </div>
                    </div>
                    <div className="mt-2 d-flex justify-content-end">
                        <OverlayTrigger placement="top" overlay={<Tooltip id={"sss"}>Cargar Imagen</Tooltip>}>
                            <Button type="button" className='mx-4' variant="secondary" onClick={() => {
                                setisLoadImage(true);
                            }}>
                                <AttachFile />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip id={"sss"}>Guardar Observación</Tooltip>}>
                            <Button type="button" className='mx-4' variant="primary" onClick={() => {
                                setObservacion(obervacionGestion, 0);
                            }}>
                                <Save />
                            </Button>
                        </OverlayTrigger>

                        {!EsGestionadoM ?
                            <OverlayTrigger placement="top" overlay={<Tooltip id={"sss"}>Guardar Sin Aprobar</Tooltip>}>
                                <Button type="button" className='mx-4' variant="danger" onClick={() => {
                                    setObservacion('Cierre Gestión Sin Aprobar', 1);
                                }}>
                                    <AssignmentLate /> <Close />
                                </Button>
                            </OverlayTrigger> : <></>}

                        {!EsGestionadoM ?
                            <OverlayTrigger placement="top" overlay={<Tooltip id={"sss"}>Guardar y Aprobar</Tooltip>}>
                                <Button type="button" variant="success"
                                    onClick={() => {
                                        setObservacion('Cierre Gestión Aprobado', 1, 1);
                                    }}>
                                    <AssignmentTurnedIn /> <Close />
                                </Button>
                            </OverlayTrigger> : <></>}

                    </div>

                </Modal.Body>
                <Modal.Body>
                    <MaterialReactTable
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 120,
                            },
                        }}
                        columns={listadoCampos}
                        data={Data}
                        // editingMode="modal" //default         
                        enableTopToolbar={false}
                        enableColumnOrdering
                        enablePagination={false}
                        enableStickyHeader
                        enableDensityToggle={false}
                        enableRowVirtualization
                        muiTableContainerProps={{
                            sx: { maxHeight: '600px' }, //give the table a max height
                        }}
                        // enableEditing
                        /* onEditingRowSave={handleSaveRowEdits}
                            onEditingRowCancel={handleCancelRowEdits}*/

                        onColumnFiltersChange={setColumnFilters}
                        onGlobalFilterChange={setGlobalFilter}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                        rowCount={rowCount}

                        state={{
                            columnFilters,
                            globalFilter,
                            pagination,
                            sorting,
                        }}

                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}
