import moment from "moment";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { getAlertasHistorical } from "../data/dashBoardData";
import { formatableJson_HHMMss } from "../../../../../_start/helpers/Helper";

// clase con los funciones  y datos a utiilizar
export interface FatigueHistoricalContextModel {

    //creo que para mapa
    setDataDetallado2: (DataAlertas2: any) => void;
    DataDetallado2?: any;
    setDataDetalladoFiltrado2: (DataAlertas2: any) => void;
    DataDetalladoFiltrado2?: any;
    setFiltrado2: (Filtrado2: boolean) => void;
    Filtrado2?: any

    //Para historical
    clienteIds?: string;
    setclienteIds: (clienteid: string) => void;
    fechaInicial?: Date;
    setfechaInicial: (fechainicial: Date) => void;
    fechaFinal?: Date;
    setfechaFinal: (fechafinal: Date) => void;
    alertasHistorical?: any;
    setalertasHistorical: (lstalertasHistorical: any[]) => void;
    top10?: any;
    settop10: (lsttop10: any[]) => void;
    iserror?: any;
    setError: (error: any) => void;
    loader2?: boolean;
    setloader2: (loader2: boolean) => void;
    indicadresExcel?: any;
    setindicadresExcel: (loader2: any) => void;

}

const FatigueHistoricalContext = createContext<FatigueHistoricalContextModel>({

    //mapa
    setFiltrado2: (Filtrado2: boolean) => { },
    setDataDetalladoFiltrado2: (DataAlertas: any) => { },
    setDataDetallado2: (DataDetallado: any) => { },
    //historical
    setclienteIds: (clienteid: string) => "",
    setfechaInicial: (fechainicial: Date) => (new Date()),
    setfechaFinal: (fechafinal: Date) => (new Date()),
    setalertasHistorical: (lstalertasHistorical: any[]) => { },
    settop10: (lsttop10: any[]) => { },
    setError: (error: any) => { },
    setloader2: (loader2: boolean) => { },
    setindicadresExcel: (loader2: any) => { }

});


const FatigueHistoricalProvider: React.FC = ({ children }) => {

    //mapa
    const [DataDetalladoFiltrado2, setDataDetalladoFiltrado2] = useState<any[]>([]);
    const [Filtrado2, setFiltrado2] = useState<boolean>(false);
    const [DataDetallado2, setDataDetallado2] = useState<any[]>([]);

    //Historical
    const [clienteIds, setclienteIds] = useState("");
    const [fechaInicial, setfechaInicial] = useState(new Date());
    const [fechaFinal, setfechaFinal] = useState(new Date());
    const [alertasHistorical, setalertasHistorical] = useState<any[]>([]);
    const [top10, settop10] = useState<any[]>([]);
    const [iserror, setError] = useState<any>({});
    const [loader2, setloader2] = useState<boolean>(false);
    const [indicadresExcel, setindicadresExcel] = useState<any>({});

    const value: FatigueHistoricalContextModel = {

        //mapa
        setDataDetalladoFiltrado2,
        DataDetalladoFiltrado2,
        DataDetallado2,
        setDataDetallado2,
        setFiltrado2,
        Filtrado2,
        //Historical
        clienteIds,
        setclienteIds,
        fechaInicial,
        setfechaInicial,
        fechaFinal,
        setfechaFinal,
        alertasHistorical,
        setalertasHistorical,
        top10,
        settop10,
        iserror,
        setError,
        loader2,
        setloader2, indicadresExcel, setindicadresExcel

    };

    return (
        <FatigueHistoricalContext.Provider value={value}>
            {children}
        </FatigueHistoricalContext.Provider>
    );
};

function useDataFatigueHistorical() {
    return useContext(FatigueHistoricalContext);
}

// se encarga de consultar la información 
// de los vehiculos operando y en una frecuencia de 5 min 
// segun parametrización que debe realizarse

const DataAlertasHistorical: React.FC = ({ children }) => {
    const { setalertasHistorical, setloader2, setError } = useDataFatigueHistorical();

    let consultaAlertas = (fechaInicial: any, fechaFinal: any, ClienteId: any) => {

        setloader2(true);
        // consultamos en la base de datos la informacion de vehiculos operando
        getAlertasHistorical(moment(fechaInicial).format(formatableJson_HHMMss)
            , moment(fechaFinal).endOf('day').format(formatableJson_HHMMss)
            , ClienteId.toString()).then((response) => {

                setalertasHistorical(response.data);
                setloader2(false);

            }).catch((error) => {
                setError({ accion: "alertas", error });
                setloader2(false);
            })

    }

    useEffect(() => {



        if (children && Object.keys(children).length != 0) {

            const variables: any = { ...children as any }

            consultaAlertas(variables.fechaInicial, variables.fechaFinal, variables.ClienteId);
            // console.log(variables.ClienteId);

        }
        return () => {
            setalertasHistorical([]);

        };
    }, [children]);
    return <></>;
};


export { FatigueHistoricalProvider, useDataFatigueHistorical, DataAlertasHistorical }