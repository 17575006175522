import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { useDataDashBoard } from "../../../core/DashboardCore";
import { RetornarEstadosFlujo } from "../../../data/dashboard";
import { formatableJson_HHMMss, GetFechaServidor } from "../../../../../../../_start/helpers/Helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../setup";
import { UserModelSyscaf } from "../../../../../auth/models/UserModel";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { SetRequerimiento } from "../../../data/PostVentaData";
import { AxiosError, AxiosResponse } from "axios";

export const ModalNuevoRequerimiento: React.FC<{ row: any, show: boolean, setShow: any }> = ({ row, show, setShow }) => {
    const { lstVehiculosSinTx, setlstVehiculosSinTx, lstEstadosReq
    } = useDataDashBoard();

    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    
    const isNotSinTx: boolean = (row.diffAVL == undefined);
    const vUser = user as UserModelSyscaf;
    const [loader, setLoader] = useState(false);
    const [Observaciones, setObservaciones] = useState<string>("");
    const [DatosEncabezado, setDatosEncabezado] = useState<any>({});
    const [EstadoReqNuevo, setEstadoReqNuevo] = useState<any>();
     //Se guardan los requirimientos
    useEffect(() => {
        const DatosCabecera = {
            administrador: vUser.Nombres,
            UsuarioAdministradorId: vUser.Id,
            assetid: row.AssetId,
            clienteid: row.ClienteId,
            registrationNumber: row.registrationNumber,
            description: row.assetsDescription,
            nombrecliente: row.clientenNombre,
            agente: null,
            UsuarioId: null,
            Fallas: (row.TFallas ?? 0),
            DiasSinTx: (row.diffAVL ?? 0),
            Categoria: (row.IsEspecial != undefined ? "Equipos Especiales" : (isNotSinTx ? row.TFallas == undefined ? "Con Tx" : "Con Falla" : "Sin Tx"))  // 
        };
        setDatosEncabezado(DatosCabecera)


         // seteamos el valor de estado para la creaci'on
         const EstadoAdmin = lstEstadosReq.find((f: any) => f.tipo === "Admin" && f.valor === "1")
         if (EstadoAdmin != null) {
             // traemos el siguiente estado
             let Flujo = RetornarEstadosFlujo(lstEstadosReq, EstadoAdmin.flujo);
             setEstadoReqNuevo(Flujo[0])
         }
    }, []);


    const EnviarRequerimiento = () => {
        const Fecha = GetFechaServidor().format(formatableJson_HHMMss)
        let Obervaciones = [{
            fecha: Fecha, observacion: (Observaciones == "" ? `Se crea el requerimiento para el vehiculo 
        ${DatosEncabezado['registrationNumber']} realizado por el administrador 
        ${DatosEncabezado['administrador']} el dia y hora ${Fecha}` : Observaciones),
            usuario: vUser.Nombres, estado: "Soporte - Sin Asignar"
        }]
        let Campos: any = {};
        Campos["Tipo"] = "Soporte";
        Campos["Cabecera"] = JSON.stringify(DatosEncabezado);
        Campos["Observaciones"] = JSON.stringify(Obervaciones);
        Campos["Estado"] = JSON.stringify(EstadoReqNuevo);
        confirmarDialog(() => {
            setLoader(true)
            SetRequerimiento(Campos).then((response: AxiosResponse<any>) => {
                if (response.statusText == "OK") {
                    let Inicio = String(response.data[0].Consecutivo).substring(0, 6);
                    let Final = String(response.data[0].Consecutivo).substring(6, String(response.data[0].Consecutivo).length);
                    successDialog(`Operación Éxitosa, # requerimiento ${Inicio}-${Final}`, "");
            
                };
                let data = [...lstVehiculosSinTx];
                let Registros = data.map((val: any) => {
                    if (val.AssetId != row.AssetId)
                        return val;
                }).filter((e) => e);
                setlstVehiculosSinTx(Registros);
           
                setLoader(false)
                
            }).catch((e: AxiosError<any>) => {
               
                let msj = (e.response?.data.includes("El requerimiento ya existe para ese vehiculo") ? "El requerimiento ya existe para ese vehiculo" : e.response?.statusText.toString());
                errorDialog((msj != undefined ? msj : "Error  al crear el requerimiento."), "");
                setLoader(false)
            }).finally(()=>{
                setShow(false)
            });
        }, `¿Esta completamente seguro que  desea crear el requerimiento?`, 'Guardar');

    }


    return (
        <Modal show={show} onHide={setShow} size="lg">
        <Modal.Header closeButton>
        <Modal.Title className="text-center fs-4" style={{ width: '100%' }}>
                    <label className="control-label label labels-sm fs-2 text-syscaf-azul">Nuevo Requerimiento</label><br />
                    <label className="control-label label labels-sm text-syscaf-azul">Placa:</label>
                    <span className="mx-4 fs-3 text-muted">{DatosEncabezado.registrationNumber}</span>
                </Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0  p-0">
            <div className="container">
                <div className="row mt-2">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="row">
                            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                                <div className="">
                                    <label className="mx-4 fs-6 fw-bolder">Cliente: </label>
                                </div>
                                <span className="mx-4 fs-5 text-muted">{DatosEncabezado.nombrecliente}</span>
                            </div>
                          
                            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                                <div className="">
                                    <label className="mx-4 fs-6 fw-bolder">Administrador (es) : </label>
                                </div>
                                <span className="mx-4 fs-5 text-muted">{DatosEncabezado.administrador}</span>
                            </div>
                            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                                <div className="">
                                    <label className="mx-4 fs-6 fw-bolder">Días sin Tx: </label>
                                </div>
                                <span className="mx-4 fs-5 text-muted">{(DatosEncabezado.DiasSinTx == undefined ? "0" : DatosEncabezado.DiasSinTx)}</span>
                            </div>
                            {
                                (DatosEncabezado.Fallas != 0) && (<div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                                    <div className="">
                                        <label className="mx-4 fs-6 fw-bolder">Fallas : </label>
                                    </div>
                                    <span className="mx-4 fs-6 text-muted">{(DatosEncabezado.Fallas == undefined ? "0" : DatosEncabezado.Fallas)}</span>
                                </div>)
                            }
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2">
                        <div className="mb-3 mx-4">
                            <label className="form-label fw-bolder">Observaciones</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1"
                                value={Observaciones}
                                onChange={(e) => setObservaciones(e.target.value)}
                                rows={3}>
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-sm btn-primary" onClick={EnviarRequerimiento}>Guardar</button>
            <button className="btn btn-sm btn-secundary" onClick={() => { setShow(false); }}>Cancelar</button>
        </Modal.Footer>
    </Modal>)
};