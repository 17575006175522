import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Button, Card, Form, Modal } from "react-bootstrap-v5";
import { columnasContacto, setContactosAlertas } from "../../../data/Configuracion";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { Box, Checkbox, IconButton, Tooltip } from "@mui/material";
import { DeleteForever, Edit } from "@mui/icons-material";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { DeserializarJSon } from "../../../../../../../_start/helpers/Helper";
type Props = {
    show: boolean;
    setShow: any;
    cliente: any;
    registroSeleccionado: any;
    titulo: string;
    GetConfiguracionAlerta: any,
    DirContacto: any[]
};

export const ModalContactos: React.FC<Props> = ({ show, setShow, cliente, registroSeleccionado, titulo, GetConfiguracionAlerta, DirContacto }) => {
    /* table state*/
    // verificamos que exista
    const DataContactos = registroSeleccionado.contactos == null ? [] : DeserializarJSon(registroSeleccionado.contactos);
    // sacamos del dir de contactos aquellos que ya tenga asignado el cliente
    DirContacto = DirContacto.filter((f: any) => DataContactos.
        filter((ff: any) => ff.correo.toLowerCase().trim() === f.correo.toLowerCase().trim()).length == 0)
    // fin de filtrar lo sque tiene el usuario

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [dDirContactos, sdDirContactos] = useState('d-none');
    const [dataContacto, setdataContacto] = useState<any[]>(DataContactos);
    const [contacto, setContacto] = useState<any>({
        tipo: "",
        nombre: "",
        numero: "",
        correo: ""
    });
    const [isRefetching, setIsRefetching] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorCorreo, seterrorCorreo] = useState<any>("");
    const [allClients, setallClients] = useState<boolean>(false);
    const [tipoModificacion, settipoModificacion] = useState<number>(0);


    const limpiarcampos = () => {
        setContacto({
            tipo: "",
            nombre: "",
            numero: "",
            correo: ""
        });
    }
    const setContactos = (correoEliminar: any, eliminar: boolean = false) => {

        const tipoGuardado = eliminar ? 3 : tipoModificacion;
        let encabezado: string = "";
        if ([1, 2].includes(tipoGuardado)) {
            // validamos correo
            if (!getCorreo(contacto.correo)) {
                return;
            }

            // validamos nombre y cargo
            if (contacto.tipo == "" || contacto.nombre == "" || contacto.numero == "") {
                errorDialog("Favor llene todos los campos", "");
                return;
            }
            // validamos que exista
            if (tipoGuardado == 1 && dataContacto.filter((ff: any) => ff.correo.toLowerCase().trim() === contacto.correo.toLowerCase()).length > 0) {
                errorDialog("El contacto ya existe, favor ingrese otro", "");
                limpiarcampos();
                return;
            }
        }
        let lstcontactos = [...dataContacto];
        switch (tipoGuardado) {
            case 1: lstcontactos.push(contacto);
                encabezado = "Adicionar";
                break;
            case 3: lstcontactos = lstcontactos.filter((ff: any) => ff.correo.toLowerCase().trim() !== correoEliminar.toLowerCase());
                encabezado = "Eliminar";
                break;
            case 2: lstcontactos = lstcontactos.filter((ff: any) => ff.correo.toLowerCase().trim() !== contacto.correo.toLowerCase());
                lstcontactos.push(contacto);
                encabezado = "Modificar";
                break;

        }
        setdataContacto(lstcontactos);
        const dataguardar = JSON.stringify(allClients ? contacto : lstcontactos);
        // validamos que no se encuentre ingresado en el listado 
        confirmarDialog(() => {
            setContactosAlertas(registroSeleccionado.configuracionAlertaId, cliente.ClienteId, dataguardar, (!allClients ? 1 : 2).toString() /* 1 para decir que es crud, 2 para aplicar a todos los clientes */).
                then((response) => {
                    successDialog("Operación Éxitosa", "");
                    setContacto({
                        tipo: "",
                        nombre: "",
                        numero: "",
                        correo: ""
                    });
                    setallClients(false)
                    GetConfiguracionAlerta([])
                    limpiarcampos();
                }).catch((error) => {
                    errorDialog("<i>Error comuniquese con el administrador<i/>", "");
                });
        }, `Esta seguro que desea ${encabezado} el contacto`, encabezado);

    }
    const getCorreo = (e: any) => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        regex.test(e) === false ? seterrorCorreo("Correo No Valido") : seterrorCorreo("")
        setContacto({ ...contacto, correo: e })
        return regex.test(e)
    };

    function CargaListadoDirectorioContactos() {
        return (
            <Form.Select className="mb-2 ms-2 mt-2" style={{ width: 300 }} onChange={(e) => {

                const seleccion = DirContacto.filter((ff: any) => ff.correo.toLowerCase().trim() === e.currentTarget.value);
                if (seleccion.length > 0)
                    setContacto(seleccion[0]);

            }} aria-label="Default select example" >
                <option value={0}>Seleccione</option>
                {
                    DirContacto.map((element, i) => {
                        return (<option key={element.correo} value={element.correo.toLowerCase().trim()}>
                            {`${element.nombre} - ${element.correo} - ${element.tipo}`}</option>)
                    })
                }
            </Form.Select>
        );
    }
    return (
        <Modal show={show} onHide={() => { setShow(false);  /**actualizamos los datos principales para que tome los cambios */ }} size="lg"> /
            <Modal.Header className=" fw-bolder  bg-secondary rounded m-4" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center", fontSize: 20
            }} closeButton={false}>
                <Modal.Title className="text-uppercase" style={{ fontSize: 20 }}>
                    {titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-0 mx-3">

                <div className="rounded shadow-sm mx-1 mt-0">
                    <span className="text-muted fs-6 mt-1 mx-2 pt-0">* Permite configurar los contactos por cada alerta del cliente, éstos aparecerán en la alerta específica.</span>

                    {(cliente != undefined && (cliente.ClienteIdS != undefined
                        || cliente.clienteNombre != undefined)) && (
                            <div className="d-flex justify-content-start mx-2">
                                <label className="control-label label-sm fw-bolder mx-2 mt-1 fs-4" >Cliente:</label>
                                <label className="control-label label-sm   mx-2 mt-1 fs-4 " >{cliente.clienteNombre}</label>
                            </div>
                        )}

                    {(registroSeleccionado != undefined && registroSeleccionado.nombre != undefined) && (
                        <div className="d-flex justify-content-start mx-2 mb-1">
                            <label className="control-label label-sm fw-bolder mx-2 mt-1 fs-4" >Alerta:</label>
                            <label className="control-label label-sm  mx-2 mt-1 fs-4 " >{registroSeleccionado.nombre}</label>
                        </div>
                    )}
                    <div className="d-flex justify-content-start mx-2 mb-1">
                        <Button type="button" variant="primary" onClick={() => {
                            settipoModificacion(1);
                        }}>
                            Nuevo Contacto
                        </Button>
                    </div>


                </div>
                <Card className={`rounded shadow-sm mt-2 pt-0 ${[1, 2].includes(tipoModificacion) ? "" : "d-none"} `}>
                    <span className="card-title fw-bolder bg-secondary rounded border-bottom  m-1 text-center fs-5">{`${tipoModificacion == 1 ? "Adicionar" : "Editar"
                        }`}</span>
                    <div className={`${tipoModificacion == 1 ? "" : "d-none"}`}>
                        <div className="d-flex justify-content-start mx-2 mb-1">
                            <Checkbox
                                onChange={(evento: any) => {
                                    sdDirContactos(dDirContactos.length > 0 ? "" : "d-none");
                                }}
                                id="BuscarDirContactos"
                            />
                            <label className="mt-2" style={{ fontWeight: 'bold' }} htmlFor="GestionarCritico">Directorio Contactos</label>
                        </div>
                        <div className={`d-flex justify-content-start mx-2 mb-1 ${dDirContactos}`}>
                            <label className="control-label label-sm fw-bolder mx-2 mt-1 fs-4" >Contacto:</label>
                            <CargaListadoDirectorioContactos />
                        </div>
                    </div>
                    <div className="row mx-2">
                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6 mt-1">
                            <div className="">
                                <label className="control-label label-sm font-weight-bold" htmlFor="comentario" style={{ fontWeight: 'bold' }}>Tipo:</label>
                                <input className="form-control  input input-sm " id={"nombregrupo"} placeholder="Selecione Tipo"
                                    onChange={(e) => {
                                        setContacto({ ...contacto, tipo: e.target.value })
                                    }} value={contacto.tipo}></input>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6 mt-1">
                            <div className="">
                                <label className="control-label label-sm font-weight-bold" htmlFor="comentario" style={{ fontWeight: 'bold' }}>Nombre:</label>
                                <input className="form-control  input input-sm " id={"nombregrupo"} placeholder="Ingrese Nombre"
                                    onChange={(e) => {
                                        setContacto({ ...contacto, nombre: e.target.value })
                                    }} value={contacto.nombre}></input>
                            </div>
                        </div>
                    </div>
                    <div className="row  mx-2">
                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6 mt-1">
                            <div className="">
                                <label className="control-label label-sm font-weight-bold" htmlFor="comentario" style={{ fontWeight: 'bold' }}>Número:</label>
                                <input type="number" className="form-control  input input-sm " id={"nombregrupo"} placeholder="Ingrese Número"
                                    onChange={(e) => {
                                        setContacto({ ...contacto, numero: e.target.value })
                                    }} value={contacto.numero}></input>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6 mt-1">
                            <div className="">
                                <label className="control-label label-sm font-weight-bold" htmlFor="comentario" style={{ fontWeight: 'bold' }}>Email:</label>
                                <input type="email" className="form-control  input input-sm " id={"nombregrupo"} placeholder="Ingrese Correo"
                                    onChange={(e) => { getCorreo(e.target.value) }} value={contacto.correo}></input>
                                <span className="text-danger">{errorCorreo}</span>
                            </div>
                        </div>
                    </div>
                    <div className={`d-flex justify-content-between  mx-2 mt-2 `}>
                        <div className={`d-flex justify-content-start   mx-2 ${tipoModificacion == 1? "" : "d-none"}`}>
                            <label className="control-label label-sm font-weight-boldc mt-4" htmlFor="comentario" style={{ fontWeight: 'bold' }}>Aplicar Todas las Alertas:</label>
                            <Checkbox
                                onChange={(evento: any, Check: boolean) => {
                                    setallClients(Check)
                                }}

                                id="todaslasAlertas"
                            />
                        </div>
                        <div className="d-flex justify-content-end  m-2">
                            <Button className="mx-2" type="button" variant="primary" onClick={() => {
                                setContactos(null);
                            }}>
                                Guardar
                            </Button>
                            <Button className="mx-2" type="button" variant="secondary" onClick={() => {
                                limpiarcampos();
                            }}>
                                Limpiar
                            </Button>
                            <Button className="mx-2" type="button" variant="danger" onClick={() => {
                                limpiarcampos();
                                settipoModificacion(0);
                            }}>
                                Cerrar
                            </Button>

                        </div>


                    </div>
                </Card>
                <Card className="border border-gray-300 mt-2 shadow-sm">
                    <span className="card-title fw-bolder bg-secondary rounded  border-bottom fs-5 m-1 text-center">Listado de contactos</span>
                    <MaterialReactTable
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center'
                                }
                            },
                        }}
                        columns={columnasContacto}
                        data={dataContacto}
                        enableTopToolbar
                        enableColumnOrdering
                        enableFilters
                        enablePagination={false}
                        enableColumnFilters={false}
                        enableEditing
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: 'Error al cargar información',
                                }
                                : undefined
                        }
                        onColumnFiltersChange={setColumnFilters}
                        onGlobalFilterChange={setGlobalFilter}
                        rowCount={columnasContacto.length}
                        initialState={{ density: 'compact' }}
                        state={{
                            columnFilters,
                            globalFilter,
                            //   isLoading,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderRowActions={({ row, table }) => (

                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <Tooltip arrow placement="left" title="Editar">
                                    <IconButton
                                        onClick={() => {
                                            settipoModificacion(2);
                                            setContacto(row.original);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip arrow placement="left" title="Eliminar">
                                    <IconButton onClick={() => {

                                        confirmarDialog(() => {
                                            setContactos(row.original.correo, true);
                                        }, "Eliminar Contacto", "Si")

                                    }}>
                                        <DeleteForever />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )
                        }
                    />
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" variant="secondary" onClick={() => { setShow(false) }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}