import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { useDataDashBoard } from "../../../core/DashboardCore";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { CategoriasPorColorTx, ColorRows } from "../../../../Tx/data/Reporte";
import { FiltroDashBoardBasic, getCampos } from "../../../data/dashboard";
import { MenuIconos } from "../../../../Tx/components/Reporte/Menu/MenuIconos";
import moment from "moment";
import { formatSimpleJsonColombia } from "../../../../../../../_start/helpers/Helper";

export const ModalSinTx: React.FC<{ estado: number, show: boolean, setShow: any, semaforo: number | null }> = ({ estado, show, setShow, semaforo }) => {

    const { lstVehiculosSinTx, AdminSeleccionado,
        ClienteSeleccionado, lstEstadosSinTx
    } = useDataDashBoard();

    const [loader, setLoader] = useState(false);
    const [dataVehiculoSinTx, setDataVehiculoSinTx] = useState<any[]>([]);
    const [TxUltimaActualizacion, setTxUltimaActualizacion] = useState<string>("Sin Información");
    const [EncabezadoTabla, setEncabezadoTabla] = useState<MRT_ColumnDef<any>[]>([]);

    useEffect(() => {      
        if (estado) {
            setTxUltimaActualizacion(moment(lstVehiculosSinTx[0].fecha).format(formatSimpleJsonColombia));
            // filtramos por el estado ids o generar para tener en cuenta todos los estados posibles
            // tambien se incluye el color que previamente está filtado 
            let data: any[] = CategoriasPorColorTx(FiltroDashBoardBasic.getFiltroAdminCliente(lstVehiculosSinTx,
                AdminSeleccionado.UsuarioId, ClienteSeleccionado.ClienteId
            ).filter(f => (estado === -1 || f.estadoIdS === estado)));
            data = data.filter(f =>
                (semaforo === null || f.Categoria === semaforo)
            );
            setDataVehiculoSinTx(data);
            // traemos las caracteristicas de la interfax sin tx para reutilizar datos
            setEncabezadoTabla(getCampos((e: any) => {
                return <MenuIconos setCargando={setLoader} Data={lstVehiculosSinTx} setData={setDataVehiculoSinTx} row={e} Estados={lstEstadosSinTx} place={"1"} />;
            }));
        }
    }, [estado, semaforo]);



    return (
        <Modal show={show} onHide={setShow} size="xl">
            <Modal.Header closeButton>
                <Modal.Title className="text-center fs-4" style={{ width: '100%' }}>
                    <label className="control-label label labels-sm fs-2 text-syscaf-azul">Listado Vehículos Sin Tx</label><br />
                    <label className="control-label label labels-sm text-syscaf-azul">Ultima actualización:</label>
                    <span className="mx-4 fs-3 text-muted">{TxUltimaActualizacion}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {(dataVehiculoSinTx.length > 0) && (<div className="table-responsive">
                    <MaterialReactTable
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                //   size: 100,
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: (theme) => ({
                                fontSize: 14,
                                fontStyle: 'bold',
                                color: 'rgb(27, 66, 94)'
                            }),
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                            sx: {
                                backgroundColor: ColorRows(row.original)
                            }
                        })}
                        columns={EncabezadoTabla}
                        data={dataVehiculoSinTx}
                        enableColumnOrdering
                        enableStickyHeader
                        enableDensityToggle={false}
                        enablePagination={false}
                        enableRowVirtualization
                        muiTableContainerProps={{
                            sx: { maxHeight: '350px' }, //give the table a max height
                        }}
                        initialState={
                            {
                                //  density: 'compact',
                                isLoading: loader
                            }
                        }
                    />
                </div>)}



            </Modal.Body>
        </Modal>)
};