import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useEffect, useRef, useState } from "react";
import { useDataDashboard } from "../../core/DashboardProvider";
import ReactApexChart from "react-apexcharts";
import { FiltroDataTX, listadoCamposTabla2, listadoCamposTablaClientes, opcionScatter, opcionesVertical } from "../../data/DashboardTx";

const Transmision: React.FC = () => {
    const { DataTx,   SemanaSeleccionada } = useDataDashboard()
    const [DataTxAdmin, setDataTxAdmin] = useState<any[]>([]);
    const [DataTxDetallado, setDataTxDetallado] = useState<any[]>([]);
    const [ListAdmins, setListAdmins] = useState<any[]>([]);
    const [PlacaSinTx, setPlacaSinTx] = useState<string>("0");
    const [ClienteSinTx, setClienteSinTx] = useState<string>("0");  
    //table state
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const [Scatter, setScatter] = useState<any>(null);
    const [AcumuladoParcial, setAcumuladoParcial] = useState<string>("0");
    const [VerticalTx, setVerticalTx] = useState<any>(null);
    const [AdminSel, setAdminSel] = useState<string>("0");
    //decimal de miles
    const format = (num: number) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    useEffect(() => {
        setScatter(opcionScatter)
        setVerticalTx(opcionesVertical);
    }, [])

    useEffect(() => {

        const semana: string = (SemanaSeleccionada !== undefined) ? SemanaSeleccionada["fecha"] : "-1";
        const dataSinTx = (DataTx !== undefined ? DataTx['Transmision'] : []);

        if (dataSinTx.length > 0) {
            // traemos el consolidado por clientes de la semana actual       
            let Clientes = FiltroDataTX.getConsolidadoPorCliente(dataSinTx, AdminSel, semana);

            setDataTxAdmin(Clientes);

            // traemos el detallado de la semana para la tabla general
            let detalladoSinTx = FiltroDataTX.getDetallado(dataSinTx, AdminSel, semana);
            setDataTxDetallado(detalladoSinTx);

            // totalizadores de las tabla
            // se puede totalizar dentro della misma tabla, [para una futura mejora]
            setPlacaSinTx(format(detalladoSinTx.reduce((p, c) => p + c.DiasSinTx, 0)));
            setClienteSinTx(format(Clientes.reduce((p, c) => p + c.DiasSinTx, 0)));

            // agrupamos los admin del detallado
            setListAdmins(FiltroDataTX.getAdmins(dataSinTx, "0", semana));
            RetornarSerie(dataSinTx);

            DatosClientes(dataSinTx);
        }
        return () => {
            setDataTxAdmin([]);
            setDataTxDetallado([]);
            setListAdmins([]);
        }
    }, [DataTx,  AdminSel]);


    const FiltrarByAdminsTx = (event: any) => {
        let Usuario: string = event.target.attributes['data-bs-target'].value.split("--")[1];
        setAdminSel(Usuario);
    }

    //funcion que llena la grafica de los clientes
    const DatosClientes = (data: any[]) => {
        // ubicamos la semana actual para pintar la grafica
        const semana: string = (SemanaSeleccionada != undefined) ? SemanaSeleccionada["fecha"] : "-1";

        const clientestado = FiltroDataTX.getConsolidadoPorClienteEstado(data, AdminSel, semana); // informacion sin filtrar
        const consolidadoEstados = FiltroDataTX.getConsolidadoEstados(clientestado); // agrupado por estados

        let arrayVertical: any[] = [];
        consolidadoEstados.forEach((f) => {
            let arrayestados: any[] = [];
            clientestado.forEach((ff) => {
                let estado = ff.estados.filter((fil: any) => fil.estado == f);
                arrayestados.push(estado.length == 0 ? 0 : estado[0].TotalGeneral)
            })
            arrayVertical.push({ "name": f, "data": arrayestados });
        })

        // se trunca los nombres
        let labels = clientestado.map((e) => {
            if (e.clienteNombre.length > 15) {
                let cadena = `${e.clienteNombre.substr(0, 15)}...`
                return cadena.trim()
            } else
                return e.clienteNombre.trim();

        });


        ApexCharts.exec('apexchart-verticalTX', 'updateOptions', {
            // Para los nombres de la serie
            //para que la lengenda me salga en la parte de abajo
            labels: labels,

        });
        // actializar los datos
        ApexCharts.exec('apexchart-verticalTX', 'updateSeries',
            arrayVertical
        );

    }


    const RetornarSerie = (data: any[]) => {
        const semana: string = (SemanaSeleccionada != undefined) ? SemanaSeleccionada["fecha"] : "-1";
    
        // trae la totalidad de los datos consoliddos por administrador de todas las semanas
        let dataAdminsConsolidado = FiltroDataTX.getConsolidadoPorSemanaAdmin(data, AdminSel, "-1");

        console.log(dataAdminsConsolidado)

        // trae el consolidado de los administradores de la semana actua'
        // con el fil de totalizar general
        let dataAdminsUltimaSemana = FiltroDataTX.getConsolidadoPorSemanaAdmin(data, AdminSel, semana);
        
         let getAdmins = FiltroDataTX.getAdmins(data, AdminSel, "-1");

         // traemos los nombres de los admins que seran los nombres de las series a mostrar en el grafico
         let getsNamesAdmins = getAdmins.filter(f => f.usuarioIds != "0").map(m => m.nombre);
        // consolidamos los labes que son las semanas a mostras
         let labelsSemana  = dataAdminsConsolidado.reduce( (p,c) => {
             const semana  = c.semana;
             const filter = p.filter((f: any) => f== semana);
             if(filter.length == 0)
               p.push(semana);
             return p;
         }, []).sort();

         const dataGrafica =  getsNamesAdmins.map( m =>  {
            return { name : m, data: [ ...dataAdminsConsolidado.filter( f=> f.Administrador == m).
                sort(function (a, b) { return a.semana - b.semana }).map( m=> m.TotalGeneral)] }
         })

        setAcumuladoParcial(dataAdminsUltimaSemana.reduce((p,c)=>  p + c.TotalGeneral  , 0).toString())
       // actializar los datos
        ApexCharts.exec('totalPorSemana', 'updateOptions', {
             xaxis: {
              categories: labelsSemana
            }
          });
        // actializar los datos
        ApexCharts.exec('totalPorSemana', 'updateSeries',
        dataGrafica
        );
    };
    

    return (
        <>
            <div className="row">
                <div style={{ display: (DataTx == undefined) ? 'none' : 'inline' }} className="col-sm-12 col-xl-12 col-md-12 col-lg-12" id="txpestana">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        {
                            (ListAdmins.length != 0) && (ListAdmins.map((val: any, index: any) => {
                                return (
                                    <li key={val.nombre} className="nav-item" role="presentation">
                                        <button key={val.nombre} onClick={FiltrarByAdminsTx} className={`nav-link text-success ${(index == 0 ? 'active' : '')} fw-bolder`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target={`#pills--${val.usuarioIds}`} type="button" role="tab" aria-controls="pills-profile" aria-selected="false">{val.nombre}</button>
                                    </li>
                                )
                            }))
                        }
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade show active border" id="rep_assets_lst_admon_detalle_tx">
                            <div className="row">
                                <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12 ">
                                    <div className="text-center"><span id="EstadoCantidad" style={{ fontSize: '26px' }}></span></div>
                                </div>
                                <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12">
                                    <div className="text-center">
                                        <div className="row">
                                            <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12">
                                                <label className="label label-sm control-label">Cantidad sin TX</label>
                                                <div>
                                                    <span className="" style={{ fontSize: '26px' }}>{AcumuladoParcial}</span>
                                                </div>
                                            </div>
                                            {/* <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                                                <label className="label label-sm control-label">Datos acumulado sin TX</label>
                                                <div>
                                                    <span className="" style={{fontSize:'26px'}}>{AcumuladoTotal}</span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    {
                                        (Scatter != null) && (Scatter.options != undefined) && (<ReactApexChart options={Scatter.options} series={Scatter.series} type="line" height={300} />)
                                    }
                                    {/* {(DataTx !=  undefined) &&(DataTx['Transmision'] !=  undefined) && (<TransmisionScatterChart className={"shadow-lg"}></TransmisionScatterChart>)} */}

                                </div>
                                <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12">
                                    {
                                        (VerticalTx != null) && (VerticalTx.options != undefined) && (<ReactApexChart options={VerticalTx.options} series={VerticalTx.series} type="bar" height={300} />)
                                    }
                                    {/* {(DataTx !=  undefined) &&(DataTx['Transmision'] !=  undefined) && (<TransmisionBarChart className={"shadow-lg"}></TransmisionBarChart>)} */}

                                </div>
                                <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6 pt-5">
                                    {(DataTx != undefined) && (DataTx['Transmision'] != undefined) && (<MaterialReactTable
                                        localization={MRT_Localization_ES}
                                        muiTableContainerProps={{
                                            sx: { maxHeight: '400px' }, //give the table a max height
                                        }}
                                        columns={listadoCamposTablaClientes}
                                        data={(DataTxAdmin != undefined ? DataTxAdmin : [])}
                                        enableTopToolbar={true}
                                        enableDensityToggle                                       
                                        enablePagination={false}
                                        initialState={{ density: 'compact' , sorting: [
                                            {
                                              id: 'DiasSinTx', //sort by age by default on page load
                                              desc: true,
                                            }
                                          ]}}
                                        enableStickyHeader
                                        
                                    
                                    />)}

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                                                <span className="fw-bolder">Total</span>
                                            </div>
                                            <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                                                <span className="fw-bolder text-rigth">{ClienteSinTx}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6 pt-5">
                                    {(DataTx != undefined) && (DataTx['Transmision'] != undefined) && (<MaterialReactTable
                                        localization={MRT_Localization_ES}
                                        muiTableContainerProps={{
                                            ref: tableContainerRef, //get access to the table container element
                                            sx: { maxHeight: '400px' }, //give the table a max height
                                        }}
                                        columns={listadoCamposTabla2}
                                        data={DataTxDetallado}
                                        enableDensityToggle
                                        enableFilters
                                        enablePagination={false}
                                        initialState={{ density: 'compact' , sorting: [
                                            {
                                              id: 'DiasSinTx', //sort by age by default on page load
                                              desc: true,
                                            }]}}
                                        enableStickyHeader
                                        enableStickyFooter
                                    />)}

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                                                <span className="fw-bolder">Total</span>
                                            </div>
                                            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">

                                            </div>
                                            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                                                <span className="fw-bolder text-center">{PlacaSinTx}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: (DataTx != undefined) ? 'none' : 'inline' }} className="col-sm-12 col-xl-12 col-md-12 col-lg-12 text-center" id="txpestananull">
                    <span className="font-weight-bold mb-3 text-muted" style={{ fontSize: '30px' }}>No hay datos que mostrar !!!</span>
                </div>
            </div>
        </>
    )
}
export { Transmision }