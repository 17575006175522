import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useEffect, useState } from "react";
import { FiltroDashBoardBasic, getCamposVehiculosSinTx } from "../../../data/dashboard";
import { useDataDashBoard } from "../../../core/DashboardCore";
import { PintarTablaVehiculosConFalla, PintarTablaVehiculosConTx, PintarTablaVehiculosSinTX } from "../../../data/PostVentaData";

type Props = {   
    CrearRequerimiento: (arg0: any) => void;
};
export const TabNuevoRequerimientos: React.FC<Props> = ({CrearRequerimiento
}) => {

    const { lstFallaSeniales, lstVehiculosSinTx, lstAdminFlota, ClienteSeleccionado, AdminSeleccionado,
        lstRequerimientos
    } = useDataDashBoard();

    const [LstSinTx, setLstSinTx] = useState<any[]>([]);
    const [LstConFalla, setLstConFalla] = useState<any[]>([]);
    const [LstConTx, setLstConTx] = useState<any[]>([]);
    const [activeTab, setactiveTab] = useState("");

    useEffect(() => { setactiveTab("#tab_1") }, []);

    useEffect(() => {
        const lstSinTxFiltrado  = FiltroDashBoardBasic.getFiltroAdminCliente(lstVehiculosSinTx,
            AdminSeleccionado.UsuarioId, ClienteSeleccionado.ClienteId
        );
        const lstFallaSenialesFiltrado  = FiltroDashBoardBasic.getFiltroAdminCliente(lstFallaSeniales,
            AdminSeleccionado.UsuarioId, ClienteSeleccionado.ClienteId
        );
        const lstAdminFlotaFiltrado  = FiltroDashBoardBasic.getFiltroAdminCliente(lstAdminFlota,
            AdminSeleccionado.UsuarioId, ClienteSeleccionado.ClienteId
        );

        const lstRequerimientosFiltrado  = FiltroDashBoardBasic.getFiltroReqCliente(lstRequerimientos,
            AdminSeleccionado.UsuarioId, ClienteSeleccionado.ClienteId
        );
       

            // trae a informacion de los veh'iculos que no tienen tranmision
            let SinTx = PintarTablaVehiculosSinTX(lstSinTxFiltrado, lstRequerimientosFiltrado, lstFallaSenialesFiltrado);
            // trae la infomracion de los vehiculos que tienen tranmision pero tienen falla
            let ConFalla = (PintarTablaVehiculosConFalla(lstSinTxFiltrado, lstRequerimientosFiltrado, lstFallaSenialesFiltrado))
            // trae la informacion  de los vehiculos que no presentan problemas
            let ConTx = PintarTablaVehiculosConTx(lstAdminFlotaFiltrado, lstSinTxFiltrado, lstRequerimientosFiltrado, lstFallaSenialesFiltrado)
            setLstConFalla(ConFalla)
            setLstSinTx(SinTx)
            setLstConTx(ConTx)

        
   
        //Espacio para contabilizar los requerimientos con colores.

        return () => {
            //prueba
        }
    }, [lstFallaSeniales, lstVehiculosSinTx, lstAdminFlota, ClienteSeleccionado, AdminSeleccionado])

    return (<>

        {/* begin::Nav */}
        <div className="me-sm-10 me-0">
            <ul className="nav nav-tabs nav-pills nav-pills-custom" >
                <li className="nav-item  mb-3" key="tabenc_1">
                    <a
                        onClick={() => setactiveTab("#tab_1")}
                        className={`nav-link w-140px   h-50px  ${activeTab === "#tab_1" ? "active" : ""
                            } fw-bolder`}
                        id="tab_1"                           >
                        <div  className="ps-1 text-center">
                            <span className={` nav-text  fw-bolder fs-6`} >
                                 SinTx
                            </span>
                        </div>
                    </a>
                </li>
                <li className="nav-item  mb-3" key="tabenc_2">
                    <a className={`nav-link w-140px   h-50px  ${activeTab === "#tab_2" ? "active" : ""
                        } fw-bolder`}
                        onClick={() => setactiveTab("#tab_2")}
                        id="tab_2"                           >
                        <div  className="ps-1 text-center">
                            <span className={` nav-text  fw-bolder fs-6`} >
                                Fallas Señales
                            </span>
                        </div>
                    </a>
                </li>
                <li className="nav-item  mb-3" key="tabenc_3">
                    <a className={`nav-link w-140px   h-50px ${activeTab === "#tab_3" ? "active" : ""
                        }  fw-bolder`}
                        onClick={() => setactiveTab("#tab_3")}
                        id="tab_3"                           >
                        <div  className="ps-1 text-center">
                            <span className={` nav-text  fw-bolder fs-6`} >
                                 Con Tx
                            </span>
                        </div>
                    </a>
                </li>
               

            </ul>

        </div>
        {/* end::Nav */}
        <div className="tab-content flex-grow-1" // style={{ paddingLeft: "0.23rem !important" }}
        >
            <div
                className={`tab-pane fade ${activeTab === "#tab_1" ? "show active" : ""
                    }`}
                id="tab1_content"
            >
                {/* begin::Content */}
                {/* begin::Cards */}
                <div className="overflow-auto">

                    {(activeTab === "#tab_1") &&
                        (

                            <div className="shadow rounded card">
                               
                                {(LstSinTx.length) && (<MaterialReactTable
                                    localization={MRT_Localization_ES}
                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            muiTableHeadCellProps: {
                                                align: 'center',
                                            },
                                            size: 100,
                                        },
                                    }}
                                    muiTableHeadCellProps={{
                                        sx: (theme) => ({
                                            fontSize: 11,
                                            fontStyle: 'bold',
                                            color: 'rgb(27, 66, 94)'
                                        }),
                                    }}
                                    columns={getCamposVehiculosSinTx(CrearRequerimiento,0)}
                                    data={LstSinTx}
                                    //enableColumnOrdering
                                    //    enableTableHead={false}
                                    enableTopToolbar={false}
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enablePagination={false}
                                    enableRowVirtualization
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '500px' }, //give the table a max height
                                    }}
                                    initialState={{ density: 'compact',showColumnFilters: true }}
                                />)}
                            </div>
                        )
                    }
                </div>
            </div>

            <div
                className={`tab-pane fade ${activeTab === "#tab_2" ? "show active" : ""
                    }`}
                id="tab2_content"
            >
                {/* begin::Content */}
                {/* begin::Cards */}
                <div className="overflow-auto">

                    {(activeTab === "#tab_2") &&

                        (
                            <div className="shadow rounded card">
                               
                            {(LstConFalla.length) && (<MaterialReactTable
                                localization={MRT_Localization_ES}
                                displayColumnDefOptions={{
                                    'mrt-row-actions': {
                                        muiTableHeadCellProps: {
                                            align: 'center',
                                        },
                                        size: 100,
                                    },
                                }}
                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        fontSize: 11,
                                        fontStyle: 'bold',
                                        color: 'rgb(27, 66, 94)'
                                    }),
                                }}
                                columns={getCamposVehiculosSinTx(CrearRequerimiento,1)}
                                data={LstConFalla}
                                //enableColumnOrdering
                                //    enableTableHead={false}
                                enableTopToolbar={false}
                                enableStickyHeader
                                enableDensityToggle={false}
                                enablePagination={false}
                                enableRowVirtualization
                                muiTableContainerProps={{
                                    sx: { maxHeight: '500px' }, //give the table a max height
                                }}
                                initialState={{ density: 'compact',showColumnFilters: true }}
                            />)}
                        </div>
                        )}
                </div>
            </div>

            <div
                className={`tab-pane fade ${activeTab === "#tab_3" ? "show active" : ""
                    }`}
                id="tab3_content"
            >
                {/* begin::Content */}
                {/* begin::Cards */}
                <div className="overflow-auto">
                    {(activeTab === "#tab_3") &&
                        (
                            <div className="shadow rounded card">
                               
                            {(LstConTx.length) && (<MaterialReactTable
                                localization={MRT_Localization_ES}
                                displayColumnDefOptions={{
                                    'mrt-row-actions': {
                                        muiTableHeadCellProps: {
                                            align: 'center',
                                        },
                                        size: 100,
                                    },
                                }}
                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        fontSize: 11,
                                        fontStyle: 'bold',
                                        color: 'rgb(27, 66, 94)'
                                    }),
                                }}
                                columns={getCamposVehiculosSinTx(CrearRequerimiento,2)}
                                data={LstConTx}
                                //enableColumnOrdering
                                //    enableTableHead={false}
                                enableTopToolbar={false}
                                enableStickyHeader
                                enableDensityToggle={false}
                                enablePagination={false}
                                enableRowVirtualization
                                muiTableContainerProps={{
                                    sx: { maxHeight: '500px' }, //give the table a max height
                                }}
                                initialState={{ density: 'compact', showColumnFilters: true }}
                               
                            />)}
                        </div>
                        )}
                </div>

            </div>
            

        </div>

    </>)
}