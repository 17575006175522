import {  Notification } from "rsuite";
export const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
    return (<Notification className="bg-light-danger" type={type} header={titulo}
        closable duration={10000}>
        {mensaje}
    </Notification>)
}
export function MensajeSuccess(Titulo: string, mensaje: string) {
    return message('success', Titulo, mensaje);
}

export function MensajeError(Titulo: string, mensaje: string) {
    return message('error', Titulo, mensaje);
}

