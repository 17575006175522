import moment from "moment";
import { GetClientes, Post_getConsultasDinamicasConAuthCore, Post_getconsultadinamicasUser } from "../../../../../_start/helpers/Axios/CoreService";
import { FechaServidor, formatFechasView } from "../../../../../_start/helpers/Helper";

export function ObtenerListadoCLientes() {
    var params: { [id: string]: string | null; } = {};

    return GetClientes(params);
}

export function GetInformeTransmision(ClienteId: string, FechaActual: string) {
    var params: { [id: string]: string | null | boolean; } = {};
    params["clienteIdS"] = (Number.parseInt(ClienteId) == 0 ? null : ClienteId);
    params["FechaActual"] = FechaActual;
    return Post_getconsultadinamicasUser({ Clase: "TXQueryHelper", NombreConsulta: "GetReporteTransmision", Pagina: null, RecordsPorPagina: null }, params);
}
export function SetEstadoSyscaf(assetId: string, estadoSyscafIdS: string) {
    var params: { [id: string]: string | null | boolean; } = {};
    params["assetId"] = assetId;
    params["estadoSyscafIdS"] = estadoSyscafIdS;
    return Post_getConsultasDinamicasConAuthCore({ Clase: "TXQueryHelper", NombreConsulta: "CambiarEstadoSyscaf", Pagina: null, RecordsPorPagina: null }, params);
}
export function GetEstadosTransmision(TipoIds: string) {
    var params: { [id: string]: string | null | boolean; } = {};
    params["TipoIds"] = (TipoIds == null || TipoIds == undefined ? "3" : TipoIds);

    return Post_getConsultasDinamicasConAuthCore({ Clase: "TXQueryHelper", NombreConsulta: "GetEstadosTransmision", Pagina: null, RecordsPorPagina: null }, params);
}

export const ColorRows = (Data: any) => {
    if (Data.ConfiguracionEstado != null) 
        return ColoruCategoria(Data, true);
    else
        return 'rgba(212, 237, 218, 1)';
}
export const Dias = (Data: any) => {
    if (Data.ConfiguracionEstado != null) {
        let Configuracion = JSON.parse(Data.ConfiguracionEstado)[0];
        let Fecha = moment(Configuracion.Fecha);
        //Fecha Actual
        let FechaActual = moment(Date());
        //Se calcula la diferencia pero estara en mili segundos.
        let Diferencia = FechaActual.diff(Fecha, 'days');
        return String(Diferencia);
    }
    else
        return '-';
}
export const FechaCambio = (Data: any) => {
    if (Data.ConfiguracionEstado != null) {
        let Configuracion = JSON.parse(Data.ConfiguracionEstado)[0];
        return moment(Configuracion.Fecha).format(formatFechasView);
    }
    else
        return '-';
}

export const CategoriasPorColorTx = (Data: any) => {
 
    let FechaActual = new Date().getTime();
    return Data.map((m: any, ind: number) => {
        let porc:any = 0;
        let fechaCambio:any = '';
        if (m.ConfiguracionEstado != null || m.estadoSyscaf == "En Proceso") {
            porc = ColoruCategoria(m, false);
            fechaCambio = (m.ConfiguracionEstado != null ? JSON.parse(m.ConfiguracionEstado)[0].Fecha:FechaActual )
        }else
            porc = 1;

        return {
            assetCodigo: m.assetCodigo,
            clienteNombre: (m.clienteNombre == undefined ? m.Cliente: m.clienteNombre) ,
            Sitio: m.Sitio,
            registrationNumber: m.registrationNumber,
            AVL: m.AVL,
            diffAVL: m.diffAVL,
            nombre: m.nombre,
            UsuarioId: m.UsuarioId,
            assetId: m.assetId,
            estadoSyscaf: m.estadoSyscaf,
            Cliente: m.Cliente,
            accion: m.accion,
            ConfiguracionEstado: m.ConfiguracionEstado,
            ClienteConfiguracionEstado: m.ClienteConfiguracionEstado,
            FechaCambio: fechaCambio,
            Categoria: porc,
            ClienteId:m.ClienteId,
            estadoIdS : m.estadoIdS

        }
    }
    ).sort((a:any, b:any) =>{
        return b.Categoria - a.Categoria;
    });
}

export const ColoruCategoria = (Data: any, EsColor:boolean ) =>{

    //Fecha Actual
    let FechaActual = new Date().getTime();
    let _ConfiguracionNull = [
        {
            "Fecha" : FechaActual,
            // "MinAmber":0,
            // "MaxAmber":0

        }
    ]

    let ConfiguracionClientePersonalizado = (JSON.parse((Data.ClienteConfiguracionEstado != null ? Data.ClienteConfiguracionEstado:"[]")).filter((val:any) =>{
        return val.estado == Data.estadoSyscaf
    })[0] != null ? JSON.parse((Data.ClienteConfiguracionEstado != null ? Data.ClienteConfiguracionEstado:"[]")).filter((val:any) =>{
        return val.estado == Data.estadoSyscaf
    })[0].Configuracion :[]);

    let configuracionAsset = (Data.ConfiguracionEstado != null ? JSON.parse(Data.ConfiguracionEstado):_ConfiguracionNull)
    let Configuracion:any = (ConfiguracionClientePersonalizado.length != 0 ? JSON.parse( ConfiguracionClientePersonalizado):configuracionAsset)[0];
    Configuracion.Fecha = (Data.ConfiguracionEstado != null ? JSON.parse(Data.ConfiguracionEstado) :_ConfiguracionNull )[0].Fecha
    //Fecha Desde que esta el Requerimientos en la ultima observacion.
    let Fecha = new Date(Configuracion.Fecha).getTime();
   
    //Se calcula la diferencia pero estara en mili segundos.
    let Diferencia = FechaActual - Fecha;
    // Convierte la diferencia en días
    var diasTranscurridos = Diferencia / (1000 * 60 * 60 * 24);
    let intTrans = parseInt(diasTranscurridos.toString());
  
    if(EsColor){
        let color =  (Configuracion.MinAmber == 0 && Configuracion.MaxAmber == 0   && Data.estadoSyscaf == "En Proceso" ? 'rgba(212, 237, 218, 1)' : ((intTrans < Configuracion.MinAmber) ? 'rgba(212, 237, 218, 1)' :
        (intTrans >= Configuracion.MinAmber && intTrans <= Configuracion.MaxAmber) ? 'rgba(255, 243, 205, 1)' :
            (intTrans > Configuracion.MaxAmber) ? 'rgba(248, 215, 218, 1)' : 'rgba(212, 237, 218, 1)'));
        return color;
    }
       
    else{
        let categoria =  (Configuracion.MinAmber == 0 && Configuracion.MaxAmber == 0  && Data.estadoSyscaf == "En Proceso" ? -1 : ((intTrans < Configuracion.MinAmber) ? 1 :
        (intTrans >= Configuracion.MinAmber && intTrans <= Configuracion.MaxAmber) ? 2 :
            (intTrans > Configuracion.MaxAmber) ? 3 : 1));
        return categoria;
}
    }
        