
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap-v5";
import { useDataCorreosTx } from "../../core/provider";
import { Clientes, ListaNotifacion } from "../../models/dataModels"
import { UpdateListaCorreos } from "./modalUpdatedListaCorreos";


export const FiltrosCorreos: React.FC = () => {

    //Data desde el provider
    const { Clientes, ClienteIdS, ListaNotifacion, ListaNotifacionId, setListaNotifacionId, setClienteId, setClienteIdS } = useDataCorreosTx();

    //Funciones de filtro - seteo
    const [lstListaNotifacion, setlstListaNotifacion] = useState<ListaNotifacion[]>([]);
    const [lstClientes, setlstClientes] = useState<any[]>([]);

    //Modal
    const [tituloModalCorreos, settituloModalCorreos] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => {
        settituloModalCorreos('');
        setShow(false);
    };

    const showModal = () => {
        setShow(true);
    }

    // setea la informacion local
    useEffect(() => {
        var lst = (Clientes as Clientes[]);
        setlstClientes(lst);
    }, [Clientes])

    useEffect(() => {
        if (ClienteIdS != 0 && ListaNotifacion.length > 0) {
            //filtramos la lista según el cliente id
            let filterListas = (ListaNotifacion as ListaNotifacion[]).filter(function (arr) {
                return (arr.ClienteIds == ClienteIdS)
            });
            if (filterListas.length > 0)
                setListaNotifacionId(filterListas[0].ListaClienteNotifacionId);

            if (filterListas.length === 0) {
                filterListas.push({
                    ListaClienteNotifacionId: -1,
                    ClienteIds: ClienteIdS,
                    clienteNombre: "",
                    NombreLista: "No tiene lista, cree una", EsActivo: true
                });
             setListaNotifacionId(0);
            }
            setlstListaNotifacion(filterListas);

        }

    }, [ClienteIdS, ListaNotifacion])


    function SelectClientes() {
        return (
            <Form.Select className=" mb-3 " name="clientes" value={ClienteIdS} onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema

                //validar con yuli si se puede obtener el key desde aquí                 
                setClienteIdS(e.currentTarget.value as any);
                if (Clientes.length > 0) {
                    //filtramos la lista según el cliente id
                    let clienteIdfilter = (Clientes as any[]).filter(function (arr) {
                        return (arr.clienteIdS == e.currentTarget.value)
                    });

                    // traemos la primera posicipon del filtro para actualizar el clinet id
                    const [clienteId] = clienteIdfilter;
                    setClienteId(clienteId?.ClienteId ?? -1)
                }
            }}>
                <option value={-1}>Seleccione</option>
                {(lstClientes) && (lstClientes).filter(f => f != undefined).map((cli: any) => {

                    return (
                        <option key={cli.ClienteId} value={cli.clienteIdS
                        }>
                            {cli.clienteNombre}
                        </option>
                    );
                })}
            </Form.Select>
        );
    }

    function SelectListaCorreos() {
        return (
            <Form.Select className=" mb-3 " name="reporte" value={ListaNotifacionId} onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
               
                    setListaNotifacionId(e.currentTarget.value as any);
            }}>
                {
                    lstListaNotifacion.map((rep) => {
                        return (
                            <option key={rep.ListaClienteNotifacionId} value={rep.ListaClienteNotifacionId}>
                                {`${rep.NombreLista}${rep.EsActivo ? '' : ' - (Inactivo)'}`}
                            </option>
                        );
                    })
                }
            </Form.Select>
        );
    }




    const modalSetCorreo = (title: string) => {
        settituloModalCorreos(title);
        showModal();
    }


    //Retornamos los controles de filtro
    return (
        <>
            <div className="row text-primary">
                <div className="col-sm-6 col-md-6 col-xs-6" >
                    <label className="control-label label label-sm  m-3" style={{ fontWeight: 'bold' }}>Seleccione cliente:</label>
                    <SelectClientes />
                </div>
                <div className="col-sm-6 col-md-6 col-xs-6" >
                    <label className="control-label label label-sm  m-3" style={{ fontWeight: 'bold' }}>Seleccione Lista:</label>
                    <SelectListaCorreos />
                </div>
            </div>
            <div className="row">
                <div className="mt-1 justify-content-end" style={{ textAlign: 'right' }}>
                    {(ListaNotifacionId > 0) && (
                        <Button type="button" variant="primary" className="m-2" onClick={() => { modalSetCorreo("Modificar Lista") }}>
                            Modificar
                        </Button>
                    )}
                    {(ClienteIdS > 0) && (
                        <Button type="button" variant="primary" className="m-2" onClick={() => { modalSetCorreo("Crear Lista") }}>
                            Crear
                        </Button>)}

                </div>
            </div>
          {(show) && (  <UpdateListaCorreos show={show} handleClose={handleClose} title={tituloModalCorreos} />)}
        </>
    )
}