import { MRT_ColumnDef } from "material-react-table";
import { PreguntasModelo } from "../components/Requerimientos/Componentes/Preguntas";
import { RadiosEstado } from "../components/Requerimientos/Componentes/RadiosEstado";
import { SelectObservaciones } from "../components/Requerimientos/Componentes/SelectObservaciones";

export let ColumnasPreguntasDiag: MRT_ColumnDef<any>[] =
[
    {
        accessorKey: 'categoria',
        header: 'Categoria',
        size: 90
    },
    {
        accessorKey: 'label',
        header: 'Pregunta',
        Cell({ cell, column, row, table, }) {
            return <label className="control-label label label-sm fw-bolder text-primary preguntas" title={row.original.label}>{row.original.label}</label>
        },
    },
    {
        accessorKey: 'Respuesta',
        header: 'Validado',
        size: 90,
        Cell({ cell, column, row, table, }) {
            return <div>{PreguntasModelo(row)}</div>
        },
    },
    {
        accessorKey: 'Estado',
        header: 'Correcto',
        Cell({ cell, column, row, table, }) {
            return <RadiosEstado row={row} />
        }
    },
    {
        accessorKey: 'observaciones',
        header: 'Observación',
        Cell({ cell, column, row, table, }) {
            return <SelectObservaciones  row={row}  />
        },
    },
    {
        accessorKey: 'order',
        header: 'orden',
        enableHiding: true,
    }
];