import { ClassNames } from "@emotion/react";
import { Post_getconsultadinamicasUser } from "../../../../../_start/helpers/Axios/CoreService";

export function GetEstados(tipo: string) {
    var params: { [id: string]: string | null | boolean; } = {};
    params["TipoIdS"] = tipo;
    return  Post_getconsultadinamicasUser({ Clase : "PortalQueryHelper",  NombreConsulta: "GetEstadosTx", Pagina :null, RecordsPorPagina :null}, params);
}

export function GetEstadosCliente(ClienteId: string) {
  var params: { [id: string]: string | null | boolean; } = {};
  params["ClienteId"] = ClienteId;
  return  Post_getconsultadinamicasUser({ Clase : "TxQueryHelper",  NombreConsulta: "GetEstadosClientes", Pagina :null, RecordsPorPagina :null}, params);
}


export function SetEstados(Datos: any) {
  var params: { [id: string]: string | null | boolean; } = {};
  params["Estado"] = Datos.Estado;
  params["TipoIdS"] = Datos.TipoIdS;
  params["Icono"] = Datos.Icono;
  params["Configuracion"] = Datos.Configuracion;
  params["EstadoIdS"] = Datos.EstadoIdS;
  params["Clave"] = Datos.Clave;
  return  Post_getconsultadinamicasUser({ Clase : "PortalQueryHelper",  NombreConsulta: "SetEstadosTX", Pagina :null, RecordsPorPagina :null}, params);
}

export function DeleteEstados(Datos: any) {
  var params: { [id: string]: string | null | boolean; } = {};
  params["TipoIdS"] = String(Datos.TipoIdS);
  params["EstadoIdS"] = String(Datos.EstadoIdS);

  return  Post_getconsultadinamicasUser({ Clase : "PortalQueryHelper",  NombreConsulta: "DeleteEstadosTx", Pagina :null, RecordsPorPagina :null}, params);
}
export function SetEstadosClienteEspecificos(ConfiguracionEstado: any, ClienteId:any) {
  var params: { [id: string]: string | null | boolean; } = {};
  params["ConfiguracionEstado"] = String(ConfiguracionEstado);
  params["ClienteId"] = String(ClienteId);
  return  Post_getconsultadinamicasUser({ Clase : "TxQueryHelper",  NombreConsulta: "EstadosClientesTxEspecifico", Pagina :null, RecordsPorPagina :null}, params);
}

let pagina = "https://icons.getbootstrap.com/"
export const Tooltips : any ={
    "Ayudageneral" : { title: "Ayuda", content : "Asegúrese de seleccionar el boton guardar para grabar los cambios." }
  , "Cerrar" : {title: "Cerrar", content : "Cancela todas las acciones y cierra el modal."}
  , "Guardar" : {title: "Guardar", content : "Graba todo el contenido en el servidor, para posteriormente consultarlo."}
  , "Eliminar": {title: "Eliminar", content : "Permite eliminar un estado definitivamente, esta acción no se puede deshacer."}
  , "Modificar": {title: "Modificar", content : "Permite editar o modificar un registro previamente guardado."}
  , "Nuevo": {title: "Nuevo", content : "Abre una ventana para agregar un nuevo estado."}
  , "Icono":{title: "Icono", content : `Por favor vaya a ${pagina} y escoja un icono, en el espacio Icon font seleccione el nombre del icono le aparecera el nombre asi <i class="bi bi-align-middle"></i>, solo debe seleccionar desde el segundo bi, asi ejemplo: bi-align-middle sin las comillas ni el resto de texto, lo copia, click derecho copiar y lo pega en el siguiente espacio, si lo hace correctamente vera la vista previa.` }
//   , "Flujo" : {title: "Flujo", content : "Se usa solo para agregar un nuevo flujo."}
  }
  

  export type EstadosTpos = {
    estadoIdS:string,
    estado:string,
    tipoIdS:string,
    Icono:string,
    Configuracion:string
  }