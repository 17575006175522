import { Button, Col, Container, Form, FormLabel, Modal, Row } from "react-bootstrap-v5";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../../_start/helpers/components/HtlmTooltip";
import { setUsuarioClientePreoperacional, TiposPlantillas, Tooltips, VincularUsuarios } from "../../../data/dataConfiguracion";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Box, Button as BTN, IconButton, Stack, Tooltip } from "@mui/material";
import { AddLink, Edit, Person, PersonOff } from "@mui/icons-material";
import { Cargos } from "../../../../../../../_start/helpers/Constants";
import confirmarDialog, { successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { AxiosError, AxiosResponse } from "axios";
import { useDataConfiguracion } from "../../../core/ConfiguracionCore";
import DualListBox from "react-dual-listbox";
import { CargaListadoClientes, consultarUsuario } from "./ComponenteCliente";
import { Checkbox, useToaster } from "rsuite";
import { MensajeError, MensajeSuccess } from "../../../../../../../_start/helpers/components/Toaster";
import { PREOP_ListadoUsuario } from "../../../data/dataConfiguracionPlantilla";
import { EsJson } from "../../../../../../../_start/helpers/Helper";

export const ModalusuarioCargo: React.FC = () => {
    const toaster = useToaster();
    const { lstUsuarios, Usuarios, clienteSeleccionado, datosPreoperacional, setclienteSeleccionado, setUsuarios, showObject, setshowObject,
        lstPlantillas
    } = useDataConfiguracion();
    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [data, setData] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState(0);
    const [row, setrow] = useState<any>({})
    const [detalleAdicionales, setDetallesAdicionales] = useState<any>({})
    const [showEdit, setshowEdit] = useState<boolean>(false)
    const [incluirUsuariosSyscaf, setIncluirUsuariosSyscaf] = useState<boolean>(true)
    const [vincular, setVincular] = useState<boolean>(false)
    const [userSelected, setuserSelected] = useState<string[]>([])
    const [lstUsuariosC, setlstUsuariosC] = useState<any[]>([])
    const [TipoPlantilla, setTipoPlantilla] = useState<any[]>([])
    useEffect(() => {
        setuserSelected(Usuarios.map(m => { return m.Id }))
        setData(Usuarios)
    }, [Usuarios])

    useEffect(() => {
        setTipoPlantilla(TiposPlantillas(lstPlantillas))
        return () => { // cuando se desmonta el control limpia los datos
            setTipoPlantilla([]);
        }
    }, [lstPlantillas])
    useEffect(() => {
        if (clienteSeleccionado)
            setlstUsuariosC(lstUsuarios.filter(f =>
                (f.ClienteId === "-1" && incluirUsuariosSyscaf ) || f.ClienteId == clienteSeleccionado.ClienteId
                || userSelected.includes(f.Id)
            ).map((u) => {
                return { label: `${u.Nombres} - ${u.UserName}`, value: u.Id }
            }))

    }, [lstUsuarios, clienteSeleccionado, incluirUsuariosSyscaf])


    const EditarUsuario = (row: any) => {
        setrow(row.original)
        setDetallesAdicionales(JSON.parse(row.original.DetallesAdicionales)?.preoperacional ?? {});
        setshowEdit(true);
    }

    const InactivarUsuario = (row: any) => {
        confirmarDialog(() => {
            setUsuarioClientePreoperacional(
                row.original.ClienteId,
                "2",
                row.original.DetallesAdicionales,
                (row.original.LockoutEnabled ? 'false' : 'true'),
                row.original.Id
            ).then((response: AxiosResponse<any>) => {
                setData(response.data);
                successDialog(response.data[0].Mensaje, "");
            }).catch((error: AxiosError<any>) => {
               
                toaster.push(MensajeError('Modificar Usuarios', error.message), { placement: "topCenter" });
            });
        }, `¿Esta seguro que desea cambiar de estado al usuario?`, 'Guardar');
    }
    const GuardarCargo = () => {
        // 

        let Detalles: any = !EsJson(row.DetallesAdicionales) ? { Tipo: "", preoperacional: {} } : JSON.parse(row.DetallesAdicionales);
        row.DetallesAdicionales = JSON.stringify({ ...Detalles, Tipo: row.Cargo, preoperacional: detalleAdicionales })
        confirmarDialog(() => {
            setUsuarioClientePreoperacional(
                row.ClienteId,
                "1",
                row.DetallesAdicionales,
                (row.LockoutEnabled),
                row.Id
            ).then((response: AxiosResponse<any>) => {
                setData(response.data);
                successDialog(response.data[0].Mensaje, "");
                setshowEdit(false);
            }).catch((error: AxiosError<any>) => {
                toaster.push(MensajeError('Modificar Usuarios', error.message), { placement: "topCenter" });
            });
        }, `¿Esta seguro que desea guardar el usuario?`, 'Guardar');
    }

    const ControlTipoPlantilla = () => {
        return (
            <Form.Select onChange={(e) => {
                setDetallesAdicionales({ ...detalleAdicionales, preop_tipo: e.currentTarget.value });

            }} aria-label="" value={detalleAdicionales?.preop_tipo ?? '0'}>
                <option key={'0'} value={'0'}>Seleccione Tipo Plantilla</option>
                {
                    TipoPlantilla?.map((element: any) => {
                        return (<option key={element} value={element}>{element}</option>)
                    })
                }
            </Form.Select>
        );
    }

    return (
        <>

            <Container>
                <div className="form-horizontal  mx-auto"  >
                    <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Cerrar"].title, Tooltips["Cerrar"].content)} >
                        <Button type="button" className="bt btn-sm" variant="secondary" onClick={() => {
                            setshowObject({ ...showObject, usuarios: false, panelCentral: true });
                        }}>
                            Cerrar
                        </Button>
                    </HtmlTooltipStyled>
                    <div className="ms-3 text-center">
                        <h4 className="mb-0">Configuración y vinculación Clientes Preoperacional</h4>
                        <span className="text-muted m-3">Configuración de usuarios</span>

                    </div>
                </div>
                <Stack direction="row" spacing={2} >
                    <FormLabel className="fs-4 mx-4 mt-2">Cliente:</FormLabel>
                    <div style={{ width: '50%' }}>
                        {CargaListadoClientes(datosPreoperacional, setclienteSeleccionado, clienteSeleccionado)}
                    </div>
                    <BTN hidden={vincular} title="Vincula" className="bg-primary mx-2" variant="contained"
                        startIcon={<AddLink />} onClick={() => {
                            setVincular(true);
                        }}>Vincular</BTN>
                </Stack>
                <hr className="m-0" />
                <Stack hidden={!vincular} className="">
                    <FormLabel className="fs-4 mx-auto ">Vincular y Desvincular Usuarios</FormLabel>
                    <Checkbox
                        checked={incluirUsuariosSyscaf}
                        onChange={(e: any, checked: boolean) => { setIncluirUsuariosSyscaf(checked) }}
                    >Incluir Usuarios SYSCAF?</Checkbox>
                    <hr className="m-2" />
                    <DualListBox className=" mb-3 " canFilter
                        options={lstUsuariosC}
                        selected={userSelected}
                        onChange={(selected: string[]) => {
                            const cliente: any = {
                                ClienteIds: clienteSeleccionado.ClienteIds,
                                ClienteId: clienteSeleccionado.ClienteId,
                                driver: -1,
                                nombre: clienteSeleccionado.clienteNombre,
                                sites: -1,
                                Tipo: clienteSeleccionado.TipoCliente

                            }
                            confirmarDialog(() => {
                                VincularUsuarios(JSON.stringify(selected), JSON.stringify(cliente), clienteSeleccionado.ClienteId).then((r: AxiosResponse) => {
                                    setuserSelected(selected);
                                    consultarUsuario(clienteSeleccionado, setUsuarios);
                                    toaster.push(MensajeSuccess('Vincular Usuarios', 'Guardar satisfactoriamente'), { placement: "topCenter" });
                                }).catch((error: AxiosError) => {
                                    toaster.push(MensajeError('Vincular Usuarios', error.message), { placement: "topCenter" });
                                });
                            }, 'Está seguro que desea modificar los usuarios?', 'Si');
                        }}
                    />
                    <div className="d-flex justify-content-center gap-3">
                        <Button size="sm" onClick={() => setVincular(false)} className="bg-secondary">Cerrar</Button>
                    </div>
                </Stack>
                <MaterialReactTable enableColumnResizing
                    localization={MRT_Localization_ES}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={PREOP_ListadoUsuario}
                    data={data}
                    enableTopToolbar={false}
                    enableColumnOrdering
                    enableEditing
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    enablePagination={false}
                    enableStickyHeader
                    enableDensityToggle={false}
                    enableRowVirtualization
                    muiTableContainerProps={{
                        sx: { maxHeight: '600px' }, //give the table a max height
                    }}
                    state={{
                        columnFilters,
                        globalFilter,

                        pagination,
                        sorting,
                    }}
                    renderRowActions={({ row, table }) => (
                        <>
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <Tooltip arrow placement="left" title="Editar cargo">
                                    <IconButton
                                        onClick={() => {
                                            EditarUsuario(row);
                                        }}
                                    >
                                        <Edit className="text-success" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip arrow placement="left" title="Inactivar usuario">
                                    <IconButton
                                        onClick={() => {
                                            InactivarUsuario(row);
                                        }}
                                    >
                                        {(row.original.LockoutEnabled ? <PersonOff className="text-danger"></PersonOff> : <Person className="text-success"></Person>)}
                                    </IconButton>
                                </Tooltip>
                                {/* <Tooltip arrow placement="left" title="Desvincular Usuario">
                                            <IconButton
                                                onClick={() => {
                                                    //EditarUsuario(row);
                                                }}
                                            >
                                                <LinkOff className="text-danger" />
                                            </IconButton>
                                        </Tooltip> */}
                            </Box>
                        </>
                    )
                    }
                />
            </Container>


            {(showEdit) && (<Modal className="border border-primary"
                show={showEdit}
                onHide={setshowEdit}
                size="lg">
                <Modal.Header className="fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-between">
                    <Modal.Title>Gestión y Vinculación de Usuarios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group as={Row} className="mb-3 " >
                                    <Form.Label column sm="3">Cargo:</Form.Label>
                                    <Col sm="9">
                                        <Form.Select className=" mb-3 " value={row?.Cargo} onChange={(e) => {
                                            let r = { ...row };
                                            r.Cargo = e.currentTarget.value;
                                            setrow(r);
                                            // buscamos el objeto completo para tenerlo en el sistema                  
                                            //setclienteSeleccionado(e.currentTarget.value)
                                        }} aria-label="">
                                            {
                                                Object.values(Cargos).map((element: any, i: any) => {
                                                    return (<option key={element} value={element}>{element}</option>)
                                                })
                                            }
                                        </Form.Select>
                                    </Col>
                                </Form.Group>


                            </Col>
                            <Col>
                                <Form.Label>Enviar Notificaciones Correo</Form.Label>
                                <Checkbox checked={detalleAdicionales?.preop_enviarNoti ?? true}

                                    onChange={(e: any, checked: boolean) => {
                                        setDetallesAdicionales({ ...detalleAdicionales, preop_enviarNoti: checked });
                                    }}
                                ></Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group as={Row} className="mb-3 " >
                                    <Form.Label column sm="3">Tipo Plantilla:</Form.Label>
                                    <Col sm="9">
                                        <ControlTipoPlantilla />
                                    </Col>
                                </Form.Group>


                            </Col>

                        </Row>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Guardar"].title, Tooltips["Guardar"].content)} >
                        <Button type="button" className="bt btn-sm" variant="primary" onClick={GuardarCargo}>
                            Guardar
                        </Button>
                    </HtmlTooltipStyled>
                    <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Cerrar"].title, Tooltips["Cerrar"].content)} >
                        <Button type="button" className="bt btn-sm" variant="secondary" onClick={() => {
                            setshowEdit(false);
                        }}>
                            Cerrar
                        </Button>
                    </HtmlTooltipStyled>
                </Modal.Footer>
            </Modal>)}

        </>
    )
}