import { Button, FormLabel, InputGroup, Modal } from "react-bootstrap-v5"
import { ActualizaDatosRequerimientos, ConsecutivoVisual, GetCabeceraOP, GetDatosRequerimientos, GetObservaciones, GetObservacionesCambioEstado, GuardarArchivoValidacion, NotificarRequerimiento, SetRequerimiento, StringNotificacion } from "../../../../data/Requerimientos"
import { EncabezadoHtml, EstadosEditar } from "../Encabezado"
import { ConsultasIniciales, useDataRequerimientos } from "../../../../core/RequerimientosCore"
import { DeserializarJSon, formatableJson_HHMMss, formatSimpleJson } from "../../../../../../../../_start/helpers/Helper"
import { RetornarEstadosFlujo, RetornarFlujo } from "../../../../data/dashboard"
import { useEffect, useState } from "react"
import { AxiosError, AxiosResponse } from "axios"
import moment from "moment"
import { useSelector } from "react-redux"
import { DatePicker, toaster } from "rsuite"
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../../_start/helpers/components/ConfirmDialog"
import { RootState } from "../../../../../../../../setup"
import { UserModelSyscaf } from "../../../../../../auth/models/UserModel"
import { MensajeError, message } from "../../../../../../../../_start/helpers/components/Toaster"
import "../../../../../../MODULOS/CHL/components/Modales/DetalleViajes"
import { Autocomplete, TextField } from "@mui/material"
import { Check, Clear } from "@mui/icons-material"
import { AddProgramacionTracking } from "../../../../data/Requerimientos/ordenprogramada"
import { error } from "console"
import { isBefore } from "rsuite/esm/utils/dateUtils"
import { NEP_UploadFile } from "../../../../../../../../apiurlstore"

type PropsModalEditar = {
    showedit: any,
    setshowedit: any
}
export const ModalEditarReq: React.FC<PropsModalEditar> = ({
    showedit,
    setshowedit
}) => {
    const { UserActual, lstEstadosReq, ReqSeleccionado,
        setloader, setlstRequerimientos, lstTecnicos } = useDataRequerimientos();
        const [Archivo, setArchivo] = useState<File | null>(null);
        const [Ruta, setRuta] = useState<string>("");
        const user = useSelector<RootState>(
            ({ auth }) => auth.user
        );
        //para las observaciones

        const vUser = user as UserModelSyscaf;
        const [EstadoRequerimientosSelect, setEstadoRequerimientosSelect] = useState<any[]>([]);
        const [EstadoRequerimientosSeleccionado, setEstadoRequerimientosSeleccionado] = useState<any>({ label: "Seleccione", valor: "0" });
        const [FechaOp, SetFechaOp] = useState<Date>();
        const [TecnicoAsignado, setTecnicoAsignado] = useState<any>();
        const [ObservacionesModificar, setObservacionesModificar] = useState<string>("");

    useEffect(() => {
        const EstadoActual: any = DeserializarJSon(ReqSeleccionado.original.Estado);
        const LstSigEstados = RetornarEstadosFlujo(lstEstadosReq, RetornarFlujo(lstEstadosReq, EstadoActual))
        setEstadoRequerimientosSeleccionado(LstSigEstados[0]);
        setEstadoRequerimientosSelect(LstSigEstados);
    }, []);

    const SeteoObservaciones = (e: any) => {
        e.stopPropagation();
        setObservacionesModificar(e.target.value);
    }
   
    const Guardar = () => {
        const EstadoActual = DeserializarJSon(ReqSeleccionado.original.Estado);
        const Cabecera = DeserializarJSon(ReqSeleccionado.original.Cabecera);
        let UsuarioId = ReqSeleccionado.original.UserId;
        // setCabecera(_Cabecera);
        let _obs = DeserializarJSon(ReqSeleccionado.original.Observaciones);

        if(Ruta != ""){
            _obs.push(
                {
                    "fecha":moment(FechaOp).format(formatableJson_HHMMss),
                    "observacion":Ruta,
                    "usuario":UserActual.Nombres,
                    "estado": `${EstadoRequerimientosSeleccionado.tipo} - ${EstadoRequerimientosSeleccionado.label}` ,
                    "Esdlp":false,
                    "EsImagen":true
                }
                );
        }
        // obsercacion por el cambio de estado
        GetObservacionesCambioEstado(_obs, EstadoRequerimientosSeleccionado, vUser, false);
        //Si llega a venir el estado de desinstalacion

        // obsercacion por el cambio de estado
        // notas adicionales
        if (ObservacionesModificar != "")
            GetObservaciones(_obs, ObservacionesModificar, EstadoRequerimientosSeleccionado, vUser, false, false);
        let _Cabecera = { ...Cabecera }
        if (EstadoRequerimientosSeleccionado.valor == 40) // orden programada
        {
            GetCabeceraOP(_Cabecera, moment(FechaOp).format(formatSimpleJson), TecnicoAsignado);


        }

        if (EstadoRequerimientosSeleccionado.tipo !== EstadoActual.tipo) {
            // si se encuentra entre el listados de agentes, automaticamente se le asigna
            // de lo contrario null
            const agenteAnterior = _Cabecera.LstAgentes.find((f: any) => f.tipo === EstadoRequerimientosSeleccionado.tipo);
            _Cabecera = {
                ..._Cabecera, agente: agenteAnterior?.Nombres,
                UsuarioId: agenteAnterior?.UserId
            }
        }

       

        // obtenemos la estructura a guardar de los requerimientos
        let Campos = GetDatosRequerimientos(ReqSeleccionado.original.Id, _Cabecera, _obs, EstadoRequerimientosSeleccionado);
        confirmarDialog(() => {
            setloader(true)
            SetRequerimiento(Campos).then((response: AxiosResponse<any>) => {
                if (response.statusText == "OK") {
                    successDialog(response.data[0][""], '');


                    ConsultasIniciales(setloader, vUser, setlstRequerimientos, toaster); //ACTUALIZAR DE FORMA LOCAL Y NO VOLVER A LLAMAR

                    if (UserActual.EsGestor && EstadoRequerimientosSeleccionado.tipoestado == "open") {
                        NotificarRequerimiento(UsuarioId, ReqSeleccionado.original.Id, StringNotificacion.replace("{UsuarioDestino}",
                            `${UserActual.Nombres}`).
                            replace("{Admin}", `${Cabecera.administrador}`).replace("{Consecutivo}", `${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`))
                    }
                    setloader(false);
                    // si el requerimiento es una orden programada se debera '
                    // agregar los datos en la tabla de orden programada
                    if (EstadoRequerimientosSeleccionado.valor == 40) // orden programada
                    {
                        let infoProgramacion = {
                            RequerimientoId: ReqSeleccionado.original.Id
                            , FechaProgramacion: moment(FechaOp).format(formatSimpleJson),
                            TecnicoId: TecnicoAsignado.userid,
                            Nombre: TecnicoAsignado.label,
                            RefSAMM: _Cabecera.RefSAMM
                        }
                        AddProgramacionTracking(JSON.stringify(infoProgramacion)).catch(
                            (error) => {
                                toaster.push(MensajeError('Adicionar Programación', error.message), { placement: 'topCenter' })
                            }
                        )

                    }

                }
            }).catch((error: AxiosError) => {
                toaster.push(MensajeError('Guardar Requerimiento', error.message), { placement: 'topCenter' })

            }).finally(() => { setshowedit(false); });
        }, `¿Esta seguro que desea agregar información al registro?`, 'Guardar');
    }


    return (
        <Modal show={showedit} onHide={setshowedit} size={"lg"}>
            <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                <Modal.Title>{`Edición del requerimiento ${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="row">
                    <div className="col-12">
                        <EncabezadoHtml ReqSeleccionado={ReqSeleccionado} ></EncabezadoHtml>
                    </div>
                    <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6 d-flex justify-content-start" >

                        <FormLabel className="fw-bold mx-4 fs-4 mt-6">Estados:</FormLabel>
                        <EstadosEditar
                            EstadoRequerimientos={EstadoRequerimientosSelect}
                            EstadoRequerimientosSeleccionado={EstadoRequerimientosSeleccionado}
                            setEstadoRequerimientosSeleccionado={setEstadoRequerimientosSeleccionado} />


                    </div>
                    <div className="col-12 d-flex justify-content-between" hidden={EstadoRequerimientosSeleccionado.valor != "40"}>

                        <InputGroup hidden={EstadoRequerimientosSeleccionado.valor != "40"}>
                            <FormLabel className="fw-bold mx-4 fs-4 mt-2">Fecha:</FormLabel>
                            <DatePicker
                                  disabledDate={date => isBefore(date ?? moment().toDate(), moment().add(-1, 'day').toDate())}
                                value={FechaOp} oneTap className="mx-4 mb- 1mt-1" format="dd/MM/yyyy" onChange={(value: any, event: any) => {
                                    SetFechaOp(value);
                                }}></DatePicker>
                            {FechaOp == undefined ? (<Clear className="mt-4 mx-2" color="error" />) : (<Check className="mt-4 mx-2" color="success" />)}
                        </InputGroup>
                        <InputGroup hidden={EstadoRequerimientosSeleccionado.valor != "40"}>
                            <FormLabel className="fw-bold mx-4 fs-4 mt-2">Técnico:</FormLabel>
                            <Autocomplete size="medium" style={{ width: 200 }}
                                isOptionEqualToValue={(option, value) => option.userid === value.userid}
                                //getOptionLabel={(option) => option.nombreCompuesto}
                                onChange={(event, newValue) => {
                                    if (newValue != null) {
                                        setTecnicoAsignado(newValue)
                                    } else setTecnicoAsignado(undefined)
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                options={lstTecnicos?.map((e: any) => {
                                    return { label: e.Nombres, userid: e.Id }
                                })}
                                value={TecnicoAsignado}
                                //   value={userid}
                                renderInput={(params) => (
                                    <TextField {...params} label="Escoja" />
                                )}

                            />
                            {TecnicoAsignado == undefined ? (<Clear className="mt-4 mx-2" color="error" />) : (<Check className="mt-4 mx-2" color="success" />)}

                        </InputGroup>
                    </div>
                    <div className="col-12">
                        <div className="form-control-sm">
                            <FormLabel className="fw-bold fs-4 mt-2">Observaciones:</FormLabel>
                            <textarea className="form-control" rows={3} onChange={
                                SeteoObservaciones
                            }></textarea>
                        </div>
                    </div>
                    {/* <div className="col-12" hidden={lstEstadosReq.filter((val:any) =>{
                        return val.valor == JSON.parse(ReqSeleccionado.original.Estado).valor
                    })[0].PermiteAdjunto != true} >
                        <div className="form-control-sm">
                            <FormLabel className="fw-bold fs-4 mt-2"> Archivo adjunto:</FormLabel>
                            <input className="form-control" onChange={handleFileChange} type="file" id="formFile"/>
                        </div>
                   </div> */}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btn-sm" variant="primary" onClick={() => {
                    Guardar(); // guardamos la informacion del requerimiento
                }}

                    disabled={(EstadoRequerimientosSeleccionado.valor == '40' && (FechaOp == undefined || TecnicoAsignado == undefined))}
                >
                    Guardar
                </Button>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => setshowedit(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
