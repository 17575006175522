//import { useState } from "react";
import { useSelector } from "react-redux";
import { UserModelSyscaf } from "../../../../app/modules/auth/models/UserModel";
import { RootState } from "../../../../setup";
import { KTSVG } from "../../../helpers";
import {  Nav, NavDropdown, Navbar } from "react-bootstrap-v5";

export function HeaderUserMenu() {
  // informacion del usuario almacenado en el sistema
  const isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.user
  );

  // convertimos el modelo que viene como unknow a modelo de usuario sysaf para los datos
  const model = (isAuthorized as UserModelSyscaf);

  // const [path, setPath] = useState<string | undefined>("/media/icons/duotone/General/User.svg")

  let GetTitle = (
    <KTSVG
      path="/media/icons/duotone/General/User.svg"
      className="svg-icon-1 svg-icon-dark"
    />)
  return (
    <>
      <Navbar  className=" menu-gray-600 menu-state-bg-light-primary fw-bold w-250px">
       
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Brand >
              <div className="fw-bold d-flex align-items-left bgi-no-repeat bgi-position-y-top rounded-top">
                <div className="mx-5 text-gray-600 text-center">
                  <span className="fw-bolder fs-5">{(model !== undefined) ? model.Nombres : 'Usuario'} </span>
                </div>
              </div>
            </Navbar.Brand>
            <Nav className="align-items-end">
              <NavDropdown className="justify-content-start" title={GetTitle} id="basic-nav-dropdown">
                <NavDropdown.Item href="/logout" className="text-center btn btn-active-color-primary rounded-0">
                  
                    <KTSVG
                      className="svg-icon-2x me-n1"
                      path="/media/icons/duotone/Navigation/Sign-out.svg"
                    />                

                  
                  <span className="fw-bolder fs-6 d-block pt-3">Salir</span>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
     
      </Navbar>


    </>
  );
}
