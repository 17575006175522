import React from "react";

import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Button, Modal } from "react-bootstrap-v5";
import { DeserializarJSon } from "../../../../../../_start/helpers/Helper";
import { GetColorRow, PREOP_listadoRespuesta } from "../../data/tablaPreoperacional";
import { Respuestas } from "../../models/dataModels";
import { InfromacionBasicaEncuesta } from "./Modales/InformacionBasicaEncuesta";

type Props = {
    show: boolean;
    handleClose: () => void;
    title?: string;
    row: any;
};
export const TablaRespuestas: React.FC<Props> = ({ show, handleClose, title, row }) => {
    const lstRespuestas : Respuestas[]= DeserializarJSon(row.Respuestas);
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl">
                <Modal.Header className="m-0" closeButton>
                    <Modal.Title style={{ width: '100%' }}><label
                        className="fs-2 text-center"
                        style={{ width: '100%' }}>{title?.toLocaleUpperCase()}</label></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InfromacionBasicaEncuesta row={row} />
                    <div className="row">
                        <MaterialReactTable
                            localization={MRT_Localization_ES}
                            columns={PREOP_listadoRespuesta}
                            data={lstRespuestas}
                            muiTableBodyRowProps={({ row }) => ({
                                sx: {
                                    backgroundColor: GetColorRow(row.original.Respuesta?.trim().toLowerCase(),
                                        row.original.CompareRed?.trim().toLowerCase())

                                },
                            })}
                            // editingMode="modal" //default         
                            enableTopToolbar={false}                     
                            enablePagination={false}                    
                            muiTableContainerProps={{
                                sx: { maxHeight: '400px' }, //give the table a max height

                            }}
                            enableDensityToggle={false}
                            enableRowVirtualization                          
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )

}