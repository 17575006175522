import { useEffect, useState } from "react";
import { useDataUsuarios } from "../../../core/usuariosProvider";
import { GetClaimsUsuario, ListaClaims, SetClaimsUsuario } from "../../../data/usuariosRolesdata";
import MaterialReactTable from "material-react-table";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../../_start/helpers/components/HtlmTooltip";
import { Autocomplete, Box, createFilterOptions, IconButton, TextField } from "@mui/material";
import { Check, Clear, Edit } from "@mui/icons-material";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Usuarios_Tooltips, columnasClaimUsuario } from "../../../data/usuariosdata";
import { useToaster } from "rsuite";
import { AxiosError } from "axios";
import { MensajeError } from "../../../../../../../_start/helpers/components/Toaster";
import { Button, Col, Form, Row } from "react-bootstrap-v5";
import confirmarDialog from "../../../../../../../_start/helpers/components/ConfirmDialog";

export const ECO_TabClaims: React.FC = () => {
    const toaster = useToaster();
    const { usuarioSel } = useDataUsuarios();
    const [lstClaims, setlstClaims] = useState<any[]>([]);
    const [lsSelectClaims, setlistaClaims] = useState<any[]>([]);
    const [loader, setloader] = useState<boolean>(false);
    const [dataClaim, setDataClaim] = useState<any>({});
    const [openForm, setOpenForm] = useState<boolean>(false);
    useEffect(() => {
        ClaimsUsuario();
        getListaClaims();
    }, [usuarioSel]);


    const ClaimsUsuario = () => {
        setloader(true);
        GetClaimsUsuario(usuarioSel.Id).then((response) => {
            setlstClaims(response.data);
        }).catch((error: AxiosError) => {
            toaster.push(MensajeError('Traer Claims', error.message), { placement: 'topCenter' })

        }).finally(() => { setloader(false) });
    }

    const getListaClaims = () => {
        ListaClaims().then((response) => {
            setlistaClaims(response.data);
        }).catch((error: AxiosError) => {
            toaster.push(MensajeError('Traer Claims', error.message), { placement: 'topCenter' })

        }).finally(() => { setloader(false) });
    }
    const GuardarClaims = (claim: any | null = null) => {
        setloader(true);
        claim = claim ?? dataClaim;
        SetClaimsUsuario(claim.Tipo, claim.id, claim.ClaimType, claim.ClaimValue, usuarioSel.Id).then((response) => {
            let claims = [...lstClaims];
            if (response.status == 200) {
                switch (claim.Tipo) {
                    case 1:
                        // adiciona a la lista actual 
                        const id: number = response.data[0].id;
                        claims.push({ ...claim, id })
                        setlstClaims(claims);
                        break;
                    case 2:
                        // update los datos
                        let item: any = claims.filter(f => f.id === claim.id)[0];
                        item.ClaimValue = claim.ClaimValue;
                        setlstClaims(claims);
                        break;
                    case 3:
                        // descarta de la lista el id recien eliminado
                        claims = claims.filter(f => f.id !== claim.id);
                        setlstClaims(claims);
                        break;

                }
            }
        }).catch((error: AxiosError) => {
            toaster.push(MensajeError('Traer Claims', error.message), { placement: 'topCenter' })

        }).finally(() => {
            setloader(false); clearForm();
            // limpiamos el formulario 
        });
    }

    const clearForm = () => {
        setOpenForm(false);
        setDataClaim({})
    }



    // permite adicionar al listado cuando no exista el parametro
    const filter = createFilterOptions<string>();
    return (<>
        <div className= {`vstack  border rounded m-2 gap-2 ${!openForm ? '' : 'bg-secondary' } `}>
            <div hidden={!openForm} className="mt-1 fs-4 fw-bold text-center text-syscaf-amarillo text-uppercase" style={{ width: '100%' }}> {dataClaim?.Tipo === 1 ? 'Nuevo' : 'Edición' } Claim</div>
            <hr hidden={!openForm} className="m-1" />
            <Form className="m-2" style={{ width: '100%' }} >
                <Row hidden={openForm} >
                    <Col sm="6">
                        <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                            <Form.Label column sm="4" >ClaimType:</Form.Label>
                            <Col sm="6">
                                <Autocomplete onChange={(event, newValue) => {

                                    if (newValue != null && newValue != "") {
                                        const isExist = lstClaims.filter(f => f.ClaimType == newValue);
                                        if (isExist.length == 0) {
                                            setOpenForm(true);
                                            setDataClaim({ id: - 1, ClaimType: newValue, Tipo: 1 })
                                        }
                                        else {
                                            toaster.push(MensajeError('Adicionar Claims', "El Claim ya se encuentra asignado"), { placement: 'topCenter' })
                                            clearForm();
                                        }

                                    }

                                }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options as string[], params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push(inputValue);
                                        }

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys

                                    options={lsSelectClaims?.map((element: any) => element.ClaimType)}
                                    getOptionLabel={(option: any) => {
                                        // // Value selected with enter, right from the input
                                        // if (typeof option === 'string') {
                                        //   return option;
                                        // }
                                        // Add "xxx" option created dynamically
                                        if (option) {
                                            return option;
                                        }
                                        // Regular option
                                        return option;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option}</li>}

                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Escoja el nuevo Claim" />
                                    )}

                                />
                            </Col>
                        </Form.Group>

                    </Col>
                </Row>
                <Row hidden={!openForm}>
                    <Col sm="6">
                        <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
                            <Form.Label column sm="4" >Tipo:</Form.Label>
                            <Col sm="6">
                                <Form.Control className="border bg-white rounded" plaintext readOnly defaultValue={dataClaim?.ClaimType} />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm="6">
                        <Form.Group as={Row} className="mb-3" controlId="formBasicPassword">
                            <Form.Label column sm="2">Valor:</Form.Label>
                            <Col sm="9">
                                <Form.Control type="text" value={dataClaim?.ClaimValue} onChange={(e) => {
                                    setDataClaim({ ...dataClaim, ClaimValue: e.currentTarget.value });
                                }} />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="bg-primary-light" hidden={!openForm}>
              
                    <Col sm="12">
                        <div className="d-flex justify-content-center gap-2 ">
                            <Button variant="primary" onClick={() => {
                                confirmarDialog(() => {
                                    GuardarClaims();
                                }, 'Desea Guardar el Claim?', 'Guardar');
                            }}>
                                Guardar
                            </Button> <Button variant="info" onClick={() => { clearForm(); }} >
                                Cancelar
                            </Button> </div></Col>
                </Row>


            </Form>

        </div>
        <MaterialReactTable
            localization={MRT_Localization_ES}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    size: 120
                },
            }}

            muiTableHeadCellProps={{
                sx: () => ({
                    fontSize: 14,
                    fontStyle: 'bold',
                    color: 'rgb(27, 66, 94)'
                }),
            }}
            columns={columnasClaimUsuario}
            data={lstClaims}
            enableColumnOrdering
            enableStickyHeader
            enableDensityToggle={false}
            enableSorting={true}
            enablePagination={false}
            enableRowVirtualization
            muiTableContainerProps={{
                sx: { maxHeight: '500px' }, //give the table a max height
            }}
            enableEditing={true}
            editingMode="modal"
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    {
                        <HtmlTooltipStyled placement={'top'} title={HtmlContentTitle(Usuarios_Tooltips["Editar"].title, Usuarios_Tooltips["Editar"].content)} >
                            <IconButton onClick={() => {
                                setDataClaim({ ...row.original, Tipo: 2 });
                                setOpenForm(true);
                            }}>
                                <Edit className="text-success"></Edit>
                            </IconButton>
                        </HtmlTooltipStyled>
                    }

                    <HtmlTooltipStyled placement={'top'} title={HtmlContentTitle(Usuarios_Tooltips["Inactivar"].title, Usuarios_Tooltips["Inactivar"].content)} >
                        <IconButton onClick={() => {
                            confirmarDialog(() => {
                                GuardarClaims({ ...row.original, Tipo: 3 })
                            }, 'Desea eliminar la propiedad del usuario? será eliminado de forma permamente.', 'Eliminar');

                        }}>
                            <Clear className="text-danger"></Clear>
                        </IconButton>
                    </HtmlTooltipStyled>

                </Box>
            )
            }
            state={{
                isLoading: loader

            }}
        />
    </>)
}