import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ECO_ContadorPeticiones } from "../../data/datapeticiones";
import { useDataServicios } from "../../core/serviciosProvider";
import { ECO_IndicadorPeticiones } from "./Indicadores/IndicadoresPeticion";
import { Stack } from "@mui/material";
import { Form } from "react-bootstrap-v5";
import { useEffect, useState } from "react";
import { FilroPeticiones } from "../../data/consultasGenerales";
import { DonaUsers } from "../Historial/Indicadores/Dona";


export const TabPeticiones: React.FC = () => {
    const { loader, lstContadorPeticiones } = useDataServicios();
    const filterDetallado = lstContadorPeticiones.filter(f => f.orden === 4);
    const [filtrado, setFiltrado] = useState<any[]>(filterDetallado);

    const [filtroGeneral, setFiltroGeneral] = useState<any>([]);

    useEffect(() => {
        const lstfiltro: any = lstContadorPeticiones.filter(f => f.orden == 1);
        setFiltroGeneral(lstfiltro);
        Filtrado(4)
        return () => {
            setFiltroGeneral([])
        }
    }, [lstContadorPeticiones])
    const Filtrado = (order: number) => {
        const filterDetallado = lstContadorPeticiones.filter(f => f.orden === order);
        setFiltrado(filterDetallado);
    }
    return (<>
         {(!loader) ? (<ECO_IndicadorPeticiones/>) : (<button className="btn btn-primary" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Cargando Indicador...
        </button>)} 
        {(!loader) ? (
            <Stack>
                <div className="d-flex justify-content-start">
                    <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                            inline
                            label="Detallado"
                            name="grupo1"
                            value={4}
                            onChange={(elem) => {
                                Filtrado(Number(elem.currentTarget.value))
                            }}
                            type={'radio'}
                            id={`inline-radio-1`}
                        />
                        <Form.Check
                            inline
                            label="Consolidado por Cliente"
                            value={2}
                            name="group1"
                            onChange={(elem) => {
                                Filtrado(Number(elem.currentTarget.value))
                            }}
                            type={'radio'}
                            id={`inline-radio-2`}
                        />
                        <Form.Check
                            inline
                            value={3}
                            name="group1"
                            onChange={(elem) => {
                                Filtrado(Number(elem.currentTarget.value))
                            }}
                            label="Consolidado por Petición"
                            type={'radio'}
                            id={`inline-radio-3`}
                        />   </div>
                </div>

                <div> <MaterialReactTable
                    localization={MRT_Localization_ES}
                    columns={ECO_ContadorPeticiones}
                    data={filtrado}
                    rowCount={filtrado.length}
                    enableColumnFilters={false}
                    enableColumnOrdering
                    enableColumnDragging={false}
                    //enablePagination={false}
                    enableStickyHeader
                    enableDensityToggle={false}

                    //enableRowVirtualization
                    defaultColumn={{
                        minSize: 40, //allow columns to get smaller than default
                        maxSize: 200, //allow columns to get larger than default
                        size: 150,
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: '500px' }, //give the table a max height
                    }}

                    initialState={{
                        pagination: {
                            pageIndex: 0,
                            pageSize: 100, //customize the default page size
                        },

                        showProgressBars: loader
                    }}

                /></div>

            </Stack>
        ) : (<button className="btn btn-primary" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Cargando Detalle...
        </button>)}</>)

}