import { useEffect, useState } from "react"
import { PageTitle } from "../../../../../../_start/layout/core";
import BlockUi from "@availity/block-ui";
import { DateRangePicker, Radio, RadioGroup, useToaster } from "rsuite";
import { FileDownload, Help, PunchClock, Search } from "@mui/icons-material";
import { Box, Button, IconButton, Stack } from "@mui/material";
import moment from "moment";

import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ColumnFiltersState, SortingState, PaginationState } from "@tanstack/react-table";
import { HistorialCambios, camposTablaHistorialCHL } from "../../data/historialdata";
import { ranges } from "../../../../../../_start/helpers/Constants";
import { HtmlTooltipStyled, HtmlContentTitle } from "../../../../../../_start/helpers/components/HtlmTooltip";
import { Tooltips } from "../../data/detalledata";
import { MensajeError } from "../../../../../../_start/helpers/components/Toaster";
import { formatSimpleJson } from "../../../../../../_start/helpers/Helper";
import { DescargarExcel } from "../../../../../../_start/helpers/components/DescargarExcel";


export default function CHLHistorial() {

    const { allowedMaxDays, allowedRange, combine } = DateRangePicker;
    const toaster = useToaster();

    const [loader, setLoader] = useState(false);
    const [RangoFechas, setJornadaRangoFechas] = useState<any>({
        Inicial: moment().add(-1, 'month').toDate(),
        Final: moment().toDate()
    });

    const [data, setData] = useState<any[]>([]);
    const [tipo, setTipo] = useState<string>("-1");
    // traemos la informacion de los parametros 

    useEffect(() => {
        Consultar();
    }, [RangoFechas]);

    const Consultar = () => {
        setLoader(true)
        HistorialCambios("914", tipo === '-1' ? null : tipo, null,
            moment(RangoFechas.Inicial).format(formatSimpleJson),
            moment(RangoFechas.Final).format(formatSimpleJson)).then((res: any) => {
                if (res.status == 200) {
                    setData(res.data)
                }
                else {
                    toaster.push(MensajeError(Tooltips['HC_errorDatos'].title, Tooltips['HC_errorDatos'].content), {
                        placement: 'topCenter'
                    });
                }

            }).catch((e) => {
                toaster.push(MensajeError(Tooltips['HC_errorDatos'].title, Tooltips['HC_errorDatos'].content), {
                    placement: 'topCenter'
                });
            }).finally(() => { setLoader(false); });
    }




    return (<>
        <PageTitle>Historial de Cambios </PageTitle>
        <BlockUi tag="div" keepInView blocking={loader ?? false}  >

            <div className="card card-rounded shadow mt-2" style={{ width: '100%' }}  >
                <Stack className="border shadow-sm mt-10">
                    <div className="mx-auto">
                        <div className="ms-3 text-center">
                            <h5 className="mb-0">Historial de Cambios<HtmlTooltipStyled arrow
                                title={HtmlContentTitle(Tooltips["Historial"].title, Tooltips["Historial"].content)
                                } >
                                <IconButton size="small"><Help /></IconButton>
                            </HtmlTooltipStyled></h5>

                        </div>
                    </div>
                    <div className="d-flex justify-content-start m-5">
                        <Stack >

                            <h4 className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Filtros: </h4>
                            <div className="d-flex justify-content-start"  >
                                <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Rango Fechas: </label>
                                {(combine && allowedMaxDays && allowedRange) && (
                                    <DateRangePicker
                                        disabledDate={combine(allowedMaxDays(31), allowedRange(
                                            moment().add(-12, 'month').startOf('day').toDate(), moment().startOf('day').toDate()
                                        ))} ranges={ranges} format="dd/MM/yyyy" caretAs={PunchClock}
                                        value={[RangoFechas.Inicial, RangoFechas.Final]}
                                        onChange={(value, e) => {
                                            if (value !== null) {
                                                const inicial: any = value[0];
                                                const final: any = value[1];

                                                setJornadaRangoFechas({
                                                    Inicial: inicial,
                                                    Final: final
                                                })
                                            }
                                        }}
                                    />)}

                                <div className="d-flex justify-content-start border rounded mx-2">
                                    <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-4">Tipo: </label>

                                    <RadioGroup name="radio-group-inline" inline defaultValue="Todos" value={tipo} onChange={(value: any) => {
                                        setTipo(value);
                                    }}>
                                        <Radio value="-1">Todos</Radio>
                                        <Radio color="green" value="ADICIONAR">Adicionar</Radio>
                                        <Radio color="yellow" value="EDITAR">Editar</Radio>
                                        <Radio className="mx-2" color="red" value="ELIMINAR">Eliminar</Radio>
                                    </RadioGroup>
                                </div>
                                <HtmlTooltipStyled arrow
                                    title={HtmlContentTitle(Tooltips["Busqueda"].title, Tooltips["Busqueda"].content)
                                    } >
                                    <Button className="ms-2 bg-primary " onClick={() => { Consultar() }}>
                                        <Search style={{ color: "white" }} />

                                    </Button>
                                </HtmlTooltipStyled>



                            </div>
                            <div className="d-flex flex-column mt-5">

                                <MaterialReactTable
                                    localization={MRT_Localization_ES}

                                    columns={camposTablaHistorialCHL}
                                    data={data}
                                    enableColumnFilters={false}
                                    enableColumnOrdering
                                    enableColumnDragging={false}
                                    enablePagination={false}
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enableRowVirtualization
                                    defaultColumn={{
                                        minSize: 40, //allow columns to get smaller than default
                                        maxSize: 200, //allow columns to get larger than default
                                        size: 150,
                                    }}
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '500px' }, //give the table a max height
                                    }}
                                    initialState={
                                        {
                                            expanded: true, //expand all groups by default
                                            grouping: ['TripId']
                                        }
                                    }
                                    renderTopToolbarCustomActions={({ table }) => (
                                        <Box
                                            sx={{ justifyContent: 'flex-end', alignItems: 'center', flex: 1, display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                        >
                                            <Button
                                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                                onClick={() => { DescargarExcel(data, camposTablaHistorialCHL, "Reporte Historial Cambios") }}
                                                startIcon={<FileDownload />}>
                                                Todo
                                            </Button>



                                        </Box>
                                    )}
                                />
                            </div>
                        </Stack>

                    </div>
                </Stack>

            </div>
        </BlockUi>
    </>
    )
}