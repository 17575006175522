import { TituloDashboardTx } from "../../../../_start/helpers/Texts/textosPorDefecto"
import { PageTitle } from "../../../../_start/layout/core"
import DashboardPrincipal from "./components/Dashboard/DashboardPrincipal"
import { CargaClientes, DashboardProvider, CargarSemanas, useDataDashboard, ExportarExcel, ActualizarUnidades } from "./core/DashboardProvider"
import { useEffect } from "react"


export default function Dashboard() {
    const { Cargando, setCargando, setshowChurn, showChurn } = useDataDashboard()
    useEffect(() => {
        setCargando(true);
        return () => { }
    }, [])

    return (
        <>
            <DashboardProvider>
                <PageTitle >{TituloDashboardTx}</PageTitle>             
                    <div className="d-flex  align-items-start  mt-1" >                    
                            <label className="label control-label label-sm fw-bolder mt-2 mx-4">Fecha:</label>
                            <CargarSemanas></CargarSemanas>                     
                            <label className="label  control-label label-sm fw-bolder mt-2 mx-4">Clientes:</label>
                            <CargaClientes></CargaClientes>                       
                            <ExportarExcel></ExportarExcel>
                                              </div>
                    <div className="row shadow">
                        <div className="col-sm-12 col-md-12 col-lg-123 col-xl-12 col-xxl-12">
                            <DashboardPrincipal></DashboardPrincipal>
                        </div>
                    </div>               
            </DashboardProvider>
        </>
    )
}
