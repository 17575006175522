import { Collapse, IconButton } from "@mui/material";
import { useDataUsuarios } from "../../../core/usuariosProvider";
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from "@mui/icons-material";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap-v5";

export const ECO_Usuario_InformacionBasica: React.FC = () => {
    const { usuarioSel, setUsuarioSel } = useDataUsuarios();
    const [showInfo, setShowInfo] = useState<boolean>(true);
    return (<div className="border rounded row mb-2  text-capitalize bg-secondary">
        <div className="col-12">
            <div className="d-flex justify-content-center">
                <span style={{ width: '100%', textAlign: 'center' }} className="fs-2 fw-bold text-capitalize text-syscaf-amarillo">Información Básica {!showInfo ? ` - (${usuarioSel.Nombres} ${usuarioSel.Apellidos})` : ''}</span>
                <IconButton onClick={() => {
                    setShowInfo(!showInfo)
                }}> {!showInfo ? (<KeyboardDoubleArrowDown  />) : (<KeyboardDoubleArrowUp  />)} </IconButton>
            </div>
            <hr className="m-1"></hr>

        </div>
        <Collapse in={showInfo}>
            <div className=" row mb-2 ">

                <Form className="m-2 " style={{ width: '100%' }} >
                    <Row  >
                        <Col sm="6">
                            <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                                <Form.Label column sm="4" >Nombres:</Form.Label>
                                <Col sm="6">
                                    <Form.Control type="text" value={usuarioSel?.Nombres} onChange={(e) => {
                                        let usr = { ...usuarioSel };
                                        usr.Nombres = e.currentTarget.value;
                                        setUsuarioSel(usr);
                                    }} />
                                </Col>
                            </Form.Group>

                        </Col>
                        <Col sm="6">
                            <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                                <Form.Label column sm="4" >Apellidos:</Form.Label>
                                <Col sm="6">
                                    <Form.Control type="text" value={usuarioSel?.Apellidos} onChange={(e) => {
                                        let usr = { ...usuarioSel };
                                        usr.Apellidos = e.currentTarget.value;
                                        setUsuarioSel(usr);
                                    }} />
                                </Col>
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row  >
                        <Col sm="6">
                            <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                                <Form.Label column sm="4" >Cargo:</Form.Label>
                                <Col sm="6">
                                    <Form.Control type="text" value={usuarioSel?.Cargo} onChange={(e) => {
                                        let usr = { ...usuarioSel };
                                        usr.Cargo = e.currentTarget.value;
                                        setUsuarioSel(usr);
                                    }} />
                                </Col>
                            </Form.Group>

                        </Col>
                        <Col sm="6">
                            <Form.Group as={Row} className="mb-3 " controlId="formBasicEmail">
                                <Form.Label column sm="4" >Teléfono:</Form.Label>
                                <Col sm="6">
                                    <Form.Control type="text" value={usuarioSel?.PhoneNumber} onChange={(e) => {
                                        let usr = { ...usuarioSel };
                                        usr.PhoneNumber = e.currentTarget.value;
                                        setUsuarioSel(usr);
                                    }} />
                                </Col>
                            </Form.Group>

                        </Col>
                    </Row>
                </Form>
            </div>
        </Collapse>
    </div>)

}