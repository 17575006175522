import MaterialReactTable from "material-react-table"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { Button, Modal } from "react-bootstrap-v5"
import { camposTablaDLP } from "../../../../data/DLP_Data"
import { ConsecutivoVisual, FiltroData, NotificarCorreo, NotificarPortal, SetDiagnostico, StringNtificacionAdmin } from "../../../../data/Requerimientos"
import { EncabezadoHtml } from "../Encabezado"
import { ConsultasIniciales, useDataRequerimientos } from "../../../../core/RequerimientosCore"
import { useEffect, useState } from "react"
import { DeserializarJSon, formatableJson_HHMMss, GetFechaServidor } from "../../../../../../../../_start/helpers/Helper"
import { AxiosResponse } from "axios"
import { useSelector } from "react-redux"
import { toaster } from "rsuite"
import confirmarDialog, { successDialog } from "../../../../../../../../_start/helpers/components/ConfirmDialog"
import { RootState } from "../../../../../../../../setup"
import { UserModelSyscaf } from "../../../../../../auth/models/UserModel"
import { RetornarEstadosFlujo, RetornarFlujo } from "../../../../data/dashboard"

type PropsDLP = {
    ModalDLP: any,
    setModalDLP: any,
    setShowSt: any
}
export const ModalSoporteDLP: React.FC<PropsDLP> = ({
    ModalDLP,
    setModalDLP,
    setShowSt
}) => {
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf; 
    const {UserActual, lstEstadosReq,  ListadoDLPRespuesta, setListadoDLPRespuesta , lstDLP, ReqSeleccionado, setloader,  setlstRequerimientos, setReqSeleccionado} = useDataRequerimientos();
    const [ObsDiagnostico, setObsDiagnostico] = useState<string>("");
    const [VaSt, setVaSt] = useState<boolean>(false);
    useEffect(()=>{
        GetListadoDLP(DeserializarJSon(ReqSeleccionado.original.Diagnostico)); // configuramos el listado para ser mostrado
    }, [ReqSeleccionado]);

       //VALIDACION DLP VERIFICA QUE SE CUMPLAN LAS CONDICIONES PARA MANDAR 
    // A ST O RESOLVER EL REQUERMIENTO
    useEffect(() => {
         let Validado = ListadoDLPRespuesta.filter((f) => {
            let compare = (f.CompareRed === "si" ? true : false);
            if (compare !=(((f.Respuesta === "" || !f.Respuesta ) ? compare : f.Estado))) {
                return f;
            }
        });
        setVaSt(Validado.length > 0);
    }, [ListadoDLPRespuesta])
  
    const GetListadoDLP = (Diagnostico: any[]) => {
        // te traes la informaci'on almacenada y verificas que no tenga datos
        let listado = [...lstDLP];

        const isdisable = (Diagnostico == null)
        setListadoDLPRespuesta(listado.map((a: any, index: any) => {
            a.Respuesta = (a.tipo == "check" ? false : "");
            a.disabledstate = isdisable;
            a.disabledobs = isdisable;
            a.Estado = "";
            a.RespuestaObservacion = "";
            if (Diagnostico != null && Diagnostico.length > 0) {
                let find = Diagnostico.find((f: any) => f.id == a.id);
                if (find) {
                    a.disabledstate = find.disabledstate;
                    a.disabledobs = find.disabledobs;
                    a.Respuesta = find.Respuesta;
                    a.RespuestaObservacion = find.RespuestaObservacion;
                    a.Estado = find.Estado;
                }
            }
            return a;
        }));
    }

    const GuardarDiagnostico = () => {
        let estado = DeserializarJSon(ReqSeleccionado.original.Estado);
        let _obs = DeserializarJSon(ReqSeleccionado.original.Observaciones);

        if (ObsDiagnostico != "")
            _obs.push(
                {
                    fecha: GetFechaServidor().format(formatableJson_HHMMss),
                    observacion: `DLP: ${ObsDiagnostico}`,
                    usuario: vUser.Nombres,
                    estado: `${estado.tipo} - ${estado.label}`,
                    Esdlp: true,
                }
            )
        //Para enviar al servidor
        let Campos:any = {};
        Campos["Diagnostico"] = JSON.stringify(ListadoDLPRespuesta);
        Campos["Observaciones"] = JSON.stringify(_obs);
        Campos["Estado"] = JSON.stringify(estado);
        Campos["Id"] = ReqSeleccionado.original.Id;
        confirmarDialog(() => {
            setloader(true)
            SetDiagnostico(Campos).then((response: AxiosResponse<any>) => {
                if (response.statusText == "OK") {
                    successDialog(response.data[0][""], '');
                                      // FiltroDatos(ReqFiltrados);
                    ConsultasIniciales(setloader, vUser, setlstRequerimientos, toaster); //ACTUALIZAR DE FORMA LOCAL Y NO VOLVER A LLAMAR
           

                }
            }).catch(({ error }) => {
            }).finally(()=>{  setloader(false);});
        }, `¿Esta seguro que desea guardar el registro?`, 'Guardar');
    }

     //Este resuelve el requerimiento
    //==========================================================================================================
    // RESUELVE EL REQUERIMIENTO
    //==========================================================================================================
    const ResolverRequerimiento = () => {
        // traemos el estado actual
        const EstadoActual = DeserializarJSon(ReqSeleccionado.original.Estado);
        const Cabecera = DeserializarJSon(ReqSeleccionado.original.Cabecera)
        let estado = RetornarEstadosFlujo(lstEstadosReq, RetornarFlujo(lstEstadosReq, EstadoActual))[0];
        let _obs = DeserializarJSon(ReqSeleccionado.original.Observaciones);
        _obs.push(
            {
                fecha: GetFechaServidor().format(formatableJson_HHMMss),
                observacion: ObsDiagnostico,
                usuario: vUser.Nombres,
                estado: `${estado.tipo} - ${estado.label}`,
                Esdlp: false
            }
        )
        //Para enviar al servidor
        let Campos:any = {};
        Campos["Diagnostico"] = JSON.stringify(ListadoDLPRespuesta);
        Campos["Observaciones"] = JSON.stringify(_obs);
        Campos["Estado"] = JSON.stringify({ tipo: estado.tipo, valor: estado.valor, label: estado.label, color: estado.color, tipoestado: estado.tipoestado });
        Campos["Id"] = ReqSeleccionado.original.Id;
        confirmarDialog(() => {
            setloader(true)
            SetDiagnostico(Campos).then((response: AxiosResponse<any>) => {
                if (response.statusText == "OK") {
                    successDialog(response.data[0][""], '');                  
                    ConsultasIniciales(setloader, vUser, setlstRequerimientos, toaster); //ACTUALIZAR DE FORMA LOCAL Y NO VOLVER A LLAMAR
           
                    let dataNotificacion:any = {};
                    dataNotificacion['UsuarioId'] = Cabecera.UsuarioAdministradorId;
                    dataNotificacion['RequerimientoId'] = ReqSeleccionado.original.Id;
                    dataNotificacion['Descripcion'] = StringNtificacionAdmin.replace("{Admin}", `${Cabecera.administrador}`)
                    .replace("{UsuarioDestino}", `${UserActual.Nombres}`).replace("{Consecutivo}", `${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`);
                    dataNotificacion['NotificarCorreo'] = NotificarCorreo;
                    dataNotificacion['NotificarPortal'] = NotificarPortal;
                    FiltroData.Notificar(dataNotificacion)
                    setloader(false);
                    setModalDLP(false)
                }

            }).catch(({ error }) => {
                console.log("Error", error)
            });
        }, `¿Esta seguro que desea guardar el registro como completado?`, 'Guardar');
    };
      //Inicializa las variables para enviar el requerimiento a Servicio Tenico
      const EnviarReqST = () => {
       
        let b = { ...ReqSeleccionado };
        b['ListadoDLPRespuesta'] = ListadoDLPRespuesta;
        b["Cargado"] = true;
        b["ObservacionDLP"] = ObsDiagnostico;
        b['Nombres'] = vUser.Nombres;
        setReqSeleccionado(b)
        setModalDLP(false);
        setShowSt(true);
    }
    return (
        <Modal show={ModalDLP} onHide={setModalDLP} size={"xl"}>
            <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                <Modal.Title>{`Check List DLP Requerimiento ${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="container mt-0 pt-0">
                    <div className="row">
                        <div className="col-12">
                            <EncabezadoHtml ReqSeleccionado={ReqSeleccionado} ></EncabezadoHtml>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {(ListadoDLPRespuesta.length != 0) && (<MaterialReactTable
                                localization={MRT_Localization_ES}
                                muiTableHeadCellProps={{
                                    sx: () => ({
                                        fontSize: 14,
                                        fontStyle: 'bold',
                                        color: 'rgb(27, 66, 94)'
                                    }),
                                }}
                                columns={camposTablaDLP}
                                data={ListadoDLPRespuesta}
                                enableColumnResizing
                                enableDensityToggle={false}
                                enablePagination={false}
                                enableRowVirtualization
                                enableGrouping
                                enableStickyHeader
                                enableStickyFooter
                                initialState={{
                                    density: 'compact',
                                    expanded: true, //expand all groups by default
                                    grouping: ['categoria'], //an array of columns to group by by default (can be multiple)
                                    sorting: [{ id: 'order', desc: false }], //sort by state by default
                                    columnVisibility: { order: false }
                                }}
                                muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                                muiTableContainerProps={{
                                    sx: { maxHeight: 400 }
                                }}
                            />)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12  pt-5">
                            <label className="control-label label label-sm text-muted fw-bolder">Notas adicionales</label>
                            <textarea rows={3} value={ObsDiagnostico} onChange={(e) => {
                                setObsDiagnostico(e.target.value);
                            }} className="form-control" ></textarea>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btn-sm" variant="warning" onClick={() => {
                    GuardarDiagnostico()
                }}>
                    Guardar
                </Button>
                <Button type="button" style={{ display: (!VaSt ? 'inline' : 'none') }} className="btn btn-sm" variant="primary" onClick={() => {
                    ResolverRequerimiento()
                }}>
                    Resolver
                </Button>
                <Button type="button" style={{ display: (VaSt ? 'inline' : 'none') }} className="btn btn-sm" variant="info"
                    onClick={EnviarReqST}>
                    Enviar ST
                </Button>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => setModalDLP(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}