import { Form } from "react-bootstrap-v5";
import { getUsuarioClientePreoperacional } from "../../../data/dataConfiguracion";
import { AxiosError, AxiosResponse } from "axios";

export function CargaListadoClientes(datosPreoperacional : any[], setclienteSeleccionado : any,clienteSeleccionado : any ) {
    return (
        <Form.Select  onChange={(e) => {
                        // buscamos el objeto completo para tenerlo en el sistema                        
            let filter = datosPreoperacional?.filter(f => f["ClienteIds"] == e.currentTarget.value);
            if (filter.length > 0) {
                setclienteSeleccionado(filter[0])
            }
        }} aria-label="" value={clienteSeleccionado?.ClienteIds}>
            <option selected key={'0'} value={'0'}>Seleccione Cliente</option>
            {
                datosPreoperacional?.map((element: any, i: any) => {
                    return (<option key={element['ClienteIds']} value={element['ClienteIds']}>{element['clienteNombre']}</option>)
                })
            }
        </Form.Select>
    );
}

export const consultarUsuario = (clienteSeleccionado : any, setUsuarios : any) =>{
    getUsuarioClientePreoperacional(String(clienteSeleccionado.ClienteId)).then((response: AxiosResponse<any>) => {
        setUsuarios(response.data);
    }).catch((error: AxiosError<any>) => {
        console.log(error);
    });

   
}