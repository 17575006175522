import React, { useEffect} from "react";
import { useLocation } from "react-router";
import { DrawerComponent } from "../../assets/ts/components";
const Content: React.FC = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location])

  return (
    <div className='container-fluid card card-rounded px-10 mt-3 rounded '  >     
      {children}
    </div>
  );
};

export { Content };
