import React, { useState } from "react";
import { Button, Col, Collapse, Container, Form, Row, Table } from "react-bootstrap-v5";
import { EsCerrado } from "../../../data/Requerimientos";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DescargarExcel } from "../../../../../../../_start/helpers/components/DescargarExcel";
import { DeserializarJSon, formatFechasView, formatViewHoraMinuto } from "../../../../../../../_start/helpers/Helper";
import moment from "moment";

type Props = {
    ReqSeleccionado: any;
    Admin?: any;
};
export const EncabezadoHtml: React.FC<Props> = ({ ReqSeleccionado }) => {
    const Encabezado = DeserializarJSon(ReqSeleccionado.original.Cabecera);
    const Estado = DeserializarJSon(ReqSeleccionado.original.Estado);
    const [textIndicador, settextIndicador] = useState("Mostrar")
    const [isVisible, initHs] = useState(false)
    const invokeCollapse = () => {
        settextIndicador(!isVisible ? "Ocultar" : "Mostrar")
        return initHs(!isVisible)
    }

    return (<div className="border mb-2 mt-1 rounded shadow-sm">
        <Container>
            <Row>
                <Col className="">
                    <label className="fs-6 fw-bolder">Cliente: </label>
                </Col>
                <Col>
                    <span className="fs-5 text-muted">{Encabezado.nombrecliente}</span>
                </Col>
                <Col>
                    <label className="fs-6 fw-bolder">Placa: </label>
                </Col>
                <Col>
                    <span className="fs-6 text-muted">{Encabezado.registrationNumber}</span>
                </Col>
                <Col>
                    <label className="fs-6 fw-bolder">Administrador: </label>
                </Col>
                <Col>
                    <span className="fs-5 text-muted text-truncated">{Encabezado.administrador}</span>
                </Col>
            </Row>
            <Row>
                <Col className="">
                    <label className="fs-6 fw-bolder">Días sin Tx: </label>
                </Col>
                <Col>
                    <span className="fs-5 text-muted">{(Encabezado.DiasSinTx ?? "0")}</span>
                </Col>
                <Col>
                    <label className="fs-6 fw-bolder">Estado actual: </label>
                </Col>
                <Col>
                    <span className="fs-5 text-muted">{Estado.label}</span>
                </Col>
                <Col>
                    <label className="fs-6 fw-bolder">Fallas : </label>
                </Col>
                <Col>
                    <span className="fs-6 text-muted">{(Encabezado.Fallas ?? "0")}</span>
                </Col>
            </Row>
            <Row>
                <Col className="">
                    {(Encabezado.RefSAMM) && (<label className="fs-6 fw-bolder">RefSAMM : </label>)}
                </Col>
                <Col>
                    {(Encabezado.RefSAMM) && (<span className="fs-6 text-muted">{Encabezado.RefSAMM}</span>)}
                </Col>
                <Col>
                    {(Encabezado.FechaProgramacionOP) && (<label className="fs-6 fw-bolder">Programación OS: </label>)}
                </Col>
                <Col>
                    {(Encabezado.FechaProgramacionOP) && (<span className="fs-6 text-muted">{moment(Encabezado.FechaProgramacionOP).format(formatFechasView)}</span>)}
                </Col>
                <Col>
                    {(Encabezado.Tecnico) && (<label className="fs-6 fw-bolder">Técnico Asignado: </label>)}
                </Col>
                <Col>
                    {(Encabezado.Tecnico) && (<span className="fs-6 text-muted text-truncate">{DeserializarJSon(Encabezado.Tecnico)?.label}</span>)}
                </Col>
            </Row>
        </Container>       
        {(Encabezado.InfoOS) && (<>
            <Button className=" p-2 mb-2 ms-2 mt-2" onClick={invokeCollapse}>
                {`${textIndicador} Resumen Ejecución OS`}
            </Button>
        </>)}
        {(isVisible) && (
             <Container>
             <Row>
                 <Col className="">
                 <label className="fs-6 fw-bolder">Garantía: </label>
                 </Col>
                 <Col>
                 <span className="fs-5 text-muted">{(Encabezado.InfoOS.Garantía ? "Sí" : "No")}</span>
                 </Col>
                 <Col>
                 <label className="fs-6 fw-bolder">Equipo fué Desmontado: </label>
                 </Col>
                 <Col>
                 <span className="fs-5 text-muted">{Encabezado.InfoOS.Desmontado ? "Sí" : "No"}</span>
                 </Col>
                 <Col>
                 <label className="fs-6 fw-bolder">Otras Observaciones : </label>
                 </Col>
                 <Col>
                 <span className="fs-6 text-muted">{Encabezado.InfoOS.Observaciones}</span>
                 </Col>
             </Row>
             
         
         </Container> 
           )}
    </div>
    )

}
type PropsClientes = {
    Clientes: any;
    ClienteSeleccionado: any;
    setClienteSeleccionado: any
};
//Clientes que ya tienen un requerimiento
export const SeleccionClientes: React.FC<PropsClientes> = ({ Clientes, ClienteSeleccionado, setClienteSeleccionado }) => {
    return (
        <div className="input-group mb-3">
            <span style={{ height: '40px' }} className="input-group-text mt-3" id="basic-addon1"><i className="bi-person-bounding-box"></i></span>
            <Form.Select title="Filtra por clientes" style={{ height: '40px' }} className="input-sm form-select mb-3 mt-3 " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let lstClientes = Clientes.filter((value: any) => {
                    return Number.parseInt(value.ClienteId) === Number.parseInt(e.currentTarget.value)
                })
                setClienteSeleccionado((lstClientes[0] ? lstClientes[0] : { "Cliente": "Todos", " ClienteId": "Todos" }));
            }} aria-label="Floating label select cliente">
                <option value={"Todos"}>Todos</option>
                {
                    Clientes?.map((element: any) => {
                        let flag = (element.ClienteId === ClienteSeleccionado.ClienteId)
                        return (<option selected={flag} key={element.ClienteId} defaultValue={element.ClienteId} value={element.ClienteId}>{element.Cliente}</option>)
                    })
                }
            </Form.Select>
        </div>
    );
}
type PropsEstados = {
    EstadoRequerimientos: any,
    EstadoRequerimientosSeleccionado: any,
    setEstadoRequerimientosSeleccionado: any
};
//PARA QUE PUEDAN SELECCIONAR UN ESTADO NUEVO.
export const EstadosEditar: React.FC<PropsEstados> = ({ EstadoRequerimientos, EstadoRequerimientosSeleccionado, setEstadoRequerimientosSeleccionado }) => {
    return (
        <div className="input-group mb-3">
            <span style={{ height: '40px' }} className="input-group-text mt-3" id="basic-addon1"><i className="bi-credit-card-2-front"></i></span>
            <Form.Select title="Estados para asignación" style={{ height: '40px' }} className="input-sm  mb-3 mt-3 "
                onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let lstEstado = (EstadoRequerimientos).filter((val: any) => {
                        return val.valor === e.currentTarget.value
                    })
                    setEstadoRequerimientosSeleccionado((lstEstado[0] ? lstEstado[0] : { "Estado": "Seleccione" }));
                    console.log(lstEstado[0])
                }} aria-label="Default select example" defaultValue={EstadoRequerimientosSeleccionado.valor}>
                {/* <option value={"Seleccione"}>Todos</option> */}
                {
                    EstadoRequerimientos?.map((element: any) => {
                        return (<option key={element.valor} value={element.valor}>
                            {`${element.tipo} - ${element.label}`}</option>)
                    })
                }
            </Form.Select>
        </div>
    );
}
type PropsAgentes = {
    Agentes: any,
    setAgentesSeleccionado: any,
    AgentesSeleccionado: any
}
//Pinta los agentes pero que ya esten registrados
export const SeleccionAgentes: React.FC<PropsAgentes> = ({ Agentes, setAgentesSeleccionado, AgentesSeleccionado }) => {
    return (
        <div className="input-group mb-3">
            <span style={{ height: '40px' }} className="input-group-text mt-3" id="basic-addon1"><i className="bi-person-vcard"></i></span>
            <Form.Select title="Filtra por agentes" style={{ height: '40px' }} className="input-sm  mb-3 mt-3 " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let lstagentes = Agentes.filter((value: any) => {
                    return value.UsuarioId === e.currentTarget.value
                })
                setAgentesSeleccionado((lstagentes[0] ? lstagentes[0] : { "Agente": "Todos", " UsuarioId": "Todos" }));

            }} aria-label="Default select example">
                <option value={"Todos"}>Todos</option>
                {
                    Agentes?.map((element: any) => {
                        let flag = (element.UsuarioId === AgentesSeleccionado.UsuarioId)
                        return (<option selected={flag} key={element.UsuarioId} defaultValue={element.UsuarioId} value={element.UsuarioId}>{element.Agente}</option>)
                    })
                }
            </Form.Select>
        </div>
    );
}

type PropsSelEstados = {
    Estados: any,
    setEstadoSeleccionado: any,
    EstadoSeleccionado: any
}
//Funcion que se utiliza para pintar los estados pero para el filtro ya que sino esta el estado en la tabla
//No lo muestra y si hay la necesidad de poner otro no lo encuentra.
export const SeleccionEstados: React.FC<PropsSelEstados> = ({ Estados, setEstadoSeleccionado, EstadoSeleccionado }) => {
    return (
        <div className="input-group mb-3">
            <span style={{ height: '40px' }} className="input-group-text mt-3" id="basic-addon1"><i className="bi-credit-card-2-front"></i></span>
            <Form.Select title="Filtra por estados" style={{ height: '40px' }} className="input-sm  mb-3 mt-3 " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let lstEstado = Estados.filter((value: any) => {
                    return value.Estado === e.currentTarget.value
                })
                setEstadoSeleccionado((lstEstado[0] ? lstEstado[0] : { "Estado": "Todos" }));

            }} aria-label="Default select example">
                <option value={"Todos"}>Todos</option>
                {
                    Estados?.map((element: any) => {
                        let flag = (element.Estado === EstadoSeleccionado.Estado)
                        return (<option selected={flag} key={element.Estado} defaultValue={element.Estado} value={element.Estado}>
                            {`${element.tipo} - ${element.Estado}`}</option>)
                    })
                }
            </Form.Select>
        </div>

    );
}
type PropsAgentesEdit = {
    perfil: any,
    UsuarioActual: any,
    Usuarios: any,
    setUsuarioSeleccionado: any
}

//PARA QUE PUEDAN SELECCIONAR UN USUARIO NUEVO.
export const AgentesEditar: React.FC<PropsAgentesEdit> = ({ perfil, UsuarioActual, Usuarios, setUsuarioSeleccionado }) => {


    return (
        <div className="input-group mb-3">
            <span style={{ height: '40px' }} className="input-group-text mt-3" id="basic-addon1"><i className="bi-credit-card-2-front"></i></span>
            <Form.Select disabled={!perfil.EsGestor}
                title="Asignar Agente a Requerimiento" style={{ height: '40px' }} className="input-sm  mb-3 mt-3 " onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let lstAgentes = Usuarios.filter((value: any) => {
                        return value.UserId === e.currentTarget.value
                    })
                    setUsuarioSeleccionado((lstAgentes[0] ? lstAgentes[0] : { Nombres: "Seleccione", UserId: "0" }));
                }} aria-label="Default select example" defaultValue={(perfil.EsGestor) ? "" : UsuarioActual.UserId} >
                {(perfil.EsGestor) && (<option value={"0"}>Selecione</option>)}
                {
                    Usuarios?.filter((f: any) => f.tipo == UsuarioActual.tipo).map((element: any) => {

                        return (<option key={element.UserId} value={element.UserId}>{element.Nombres}</option>)
                    })
                }
            </Form.Select>
        </div>

    );
}
export const DatosVacios = () => {
    return <div style={{
        textAlign: 'center'
    }}>
        <div className="text-center text-muted fw-bolder" style={{ lineHeight: '200px', margin: 'auto', fontSize: "20px" }}>
            No hay datos que mostrar
        </div>
    </div>
}

type PropsTablaReporte = {
    CamposReporte: any,
    DatosReporte: any
}

export const TablaReporte: React.FC<PropsTablaReporte> = ({ CamposReporte, DatosReporte }) => {
    return (
        <MaterialReactTable
            localization={MRT_Localization_ES}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    size: 100,
                },
            }}
            muiTableHeadCellProps={{
                sx: () => ({
                    fontSize: 14,
                    fontStyle: 'bold',
                    color: 'rgb(27, 66, 94)'
                }),
            }}
            columns={CamposReporte}
            data={DatosReporte}
            enableColumnOrdering
            enableStickyHeader
            enableDensityToggle={false}
            enablePagination={false}
            enableRowVirtualization
            muiTableContainerProps={{
                sx: { maxHeight: '400px' }, //give the table a max height
            }}
            renderTopToolbarCustomActions={() => (
                <Box
                    sx={{ justifyContent: 'flex-end', alignItems: 'center', flex: 1, display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <button title="Reporte a excel" style={{ display: "inline-block" }} className="btn btn-sm btn-primary" type="button" onClick={() => {
                        DescargarExcel(DatosReporte, CamposReporte, "Reporte requerimientos agrupados")
                    }}>
                        <i className="bi-file-earmark-excel"></i></button>

                </Box>
            )}
            initialState={{ density: 'compact' }}
        />
    )
}