import axios from "axios";
import { Auth_RefreshToken } from "../../apiurlstore";
import jwt_decode from "jwt-decode"
import * as auth from "../../app/modules/auth/redux/AuthRedux";
import { UserModelSyscaf } from "../../app/modules/auth/models/UserModel";
export default function setupAxios(axios: any, store: any, token : string | null) {
  // cada vez que se hace una peticion se ejecuta este interceptor que verifica si se deba refrescar el token o que 
  // se le adicione el access token a todas las peticiones.

  axios.interceptors.request.use(
    (config: any) => {
      
      let accessToken = token ?? localStorage.getItem("token");
  
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    
    function (response: any) {
      return response
    },
    function (error: any) {
      const errorResponse = error.response;
      if (isTokenExpiredError(errorResponse)) {
        //document.location.replace('/logout');
        return resetTokenAndReattemptRequest(store.dispatch);
      }
      return Promise.reject(error);
    }
  );

}

function isTokenExpiredError(errorResponse: any) {
  if (errorResponse.status === 401) {
    return true
  }
}

// resetea los datos e intenta conectarse nuevamente
export async function resetTokenAndReattemptRequest(dispatch: any ) {
  let accessToken = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("refresh");
  await  axios.post(Auth_RefreshToken, { AccessToken: accessToken, RefreshToken: refreshToken }).then(
      (data) => {
  
        if (data.status === 200) {
          // adiciona una nuevas credenciales de refresco
          const newTokens = data.data;
          var decoded = jwt_decode<UserModelSyscaf>(newTokens.token);          
          localStorage.setItem("token", newTokens.token);
          localStorage.setItem("refresh", newTokens.refreshToken)
          dispatch(auth.actions.setRefreshToken(decoded, newTokens.token, newTokens.refreshToken));
          return newTokens;
        }
        else {
          document.location.replace('/logout');
        }
      }
    ).catch((error) => {
     document.location.replace('/logout');
    });
  
}






