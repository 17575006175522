import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { useDataDashBoard } from "../../../core/DashboardCore";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ColorRows } from "../../../../Tx/data/Reporte";
import {  locateFormatPercentNDijitos } from "../../../../../../../_start/helpers/Helper";
import { CamposListadoAssets, FiltroDashBoardBasic } from "../../../data/dashboard";

export const ModalVehiculos: React.FC<{
    esFiltrado: boolean, show: boolean, setShow: any,
    property: any | null, valor: any | null
}> = ({ esFiltrado, show, setShow, property, valor }) => {

    const { AdminSeleccionado,
        ClienteSeleccionado, lstAdminFlota
    } = useDataDashBoard();
    const [dataVehiculo, setDataVehiculos] = useState<any[]>([]);
    const [dataVehiculoFiltrada, setDataVehiculosFiltrada] = useState<any[]>([]);
    const [filter, setFilter] = useState<string>('Todos');
    const [DeviceFilter, setDeviceFilter] = useState<string>('Todos');
    const [Indicadores, setIndicadores] = useState<any>({
        Total: 0,
        DisponibleMix: 0,
        OtrosEstadosMix: 0,
        ActivosFacturables: 0,
        ActivosNoFacturables: 0,
        OtrosSyscaf: 0
    });
    const sinonimo: any = {
        'SiteName': 'Sitio',
        'DeviceType': 'Tipo Dispositivo'
    }

    useEffect(() => {
        let indicadores: any = {
            Total: 0,
            DisponibleMix: 0,
            OtrosEstadosMix: 0,
            ActivosFacturables: 0,
            ActivosNoFacturables: 0,
            OtrosSyscaf: 0
        };
        let data = (!esFiltrado ? lstAdminFlota : lstAdminFlota.filter(f =>
            (AdminSeleccionado.UsuarioId === "0" || f.UsuarioId === AdminSeleccionado.UsuarioId)
            && ((ClienteSeleccionado.ClienteId === "0" || f.ClienteId === ClienteSeleccionado.ClienteId))
        ));

        if (property != null)
            data = data.filter(f =>
                (valor === 'OtrosSites' && !['Zona Nuevos', 'Zona Decom'].includes(f[property]))
                ||
                f[property] == valor);

        indicadores.Total = data.length;
        indicadores.DisponibleMix = data.filter(f => f.UserState === 'Available').length;
        indicadores.OtrosEstadosMix = data.filter(f => f.UserState !== 'Available').length;
        indicadores.ActivosFacturables = data.filter(f => f.Activofacturable.toLowerCase() === 'si').length;
        indicadores.ActivosNoFacturables = data.filter(f => f.Activofacturable.toLowerCase() === 'no').length;
        indicadores.OtrosSyscaf = data.filter(f => !['si', 'no'].includes(f.Activofacturable.toLowerCase())).length;

        setIndicadores(indicadores);
        setDataVehiculos(data);
        // filtro por estados y otros 
        data = data.filter(f => (filter === 'Todos')
            || (filter === 'Otros Estados' && f.UserState !== 'Available')
            || f.UserState === filter
            || (filter === 'Activos Facturables' && f.Activofacturable.toLowerCase() === 'si')
            || (filter === 'Activos No Facturables' && f.Activofacturable.toLowerCase() === 'no')
            || (filter === 'Otros SYSCAF' && !['si', 'no'].includes(f.Activofacturable.toLowerCase()))
        );

        // filtro por tipo de dispositivos
        data = data.filter(f => (DeviceFilter === 'Todos' || f.DeviceType === DeviceFilter)

        );
        setDataVehiculosFiltrada(data);



    }, [esFiltrado, property, filter, DeviceFilter]);



    return (
        <Modal show={show} onHide={setShow} size="xl">
            <Modal.Header closeButton>
                <Modal.Title className="text-center fs-4" style={{ width: '100%' }}>
                    <label className="control-label label labels-sm fs-2 text-syscaf-azul">
                        Assets -
                        {esFiltrado ? ` Administrador: ${AdminSeleccionado.Nombres}` : ' SYSCAF'}
                    </label><br />
                    {
                        property != null && ( <><label className="control-label label labels-sm fs-2 text-syscaf-azul">
                            {property != null && ` ${sinonimo[property]}: ${valor}`}
                        </label><br /></>)
                    }
                    
                    <span className={`text-muted mt-4`}>Filtrado Por : {filter} - Dispositivos :  {DeviceFilter} </span>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="row col-sm-12 col-md-12 col-xs-12 mx-auto">
                        <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto
                            ${Indicadores.Total === 0 && 'd-none'}
                            `}                                >
                            <div className="m-3 text-center">
                                <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                    <span onClick={() => {
                                        setFilter('Todos');
                                        setDeviceFilter('Todos');
                                    }} style={{ cursor: 'pointer' }}>
                                        {Indicadores.Total}</span></h2>
                                <span className={`text-muted`}>Total</span>
                            </div>
                        </div>

                        <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto  ${Indicadores.DisponibleMix === 0 && 'd-none'} `}                                >
                            <div className="m-3 text-center">
                                <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                    <span onClick={() => {
                                        setFilter('Available');
                                        setDeviceFilter('Todos');
                                    }} style={{ cursor: 'pointer' }}>
                                        {Indicadores.DisponibleMix}</span></h2>
                                <span className={`text-muted`}>Disponible Mix</span>
                            </div>
                        </div>
                        <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto   ${Indicadores.OtrosEstadosMix === 0 && 'd-none'}`}                                >
                            <div className="m-3 text-center">
                                <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                    <span onClick={() => {
                                        setFilter('Otros Estados');
                                        setDeviceFilter('Todos');
                                    }} style={{ cursor: 'pointer' }}>
                                        {Indicadores.OtrosEstadosMix}</span></h2>
                                <span className={`text-muted`}>Otros Estados Mix</span>
                            </div>
                        </div>
                        <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto  ${Indicadores.ActivosFacturables === 0 && 'd-none'}`}                                >
                            <div className="m-3 text-center">
                                <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                    <span onClick={() => {
                                        setFilter('Activos Facturables');
                                        setDeviceFilter('Todos');
                                    }} style={{ cursor: 'pointer' }}>
                                        {Indicadores.ActivosFacturables}</span></h2>
                                <span className={`text-muted`}>Activos Facturable</span>
                            </div>
                        </div>
                        <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto ${Indicadores.ActivosNoFacturables === 0 && 'd-none'}`}                                >
                            <div className="m-3 text-center">
                                <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                    <span onClick={() => {
                                        setFilter('Activos No Facturables');
                                        setDeviceFilter('Todos');
                                    }} style={{ cursor: 'pointer' }}>
                                        {Indicadores.ActivosNoFacturables}</span></h2>
                                <span className={`text-muted`}>Activos No Facturable</span>
                            </div>
                        </div>
                        <div className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto ${Indicadores.OtrosSyscaf === 0 && 'd-none'}`}                                >
                            <div className="m-3 text-center">
                                <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                    <span onClick={() => {
                                        setFilter('Otros SYSCAF');
                                        setDeviceFilter('Todos');
                                    }} style={{ cursor: 'pointer' }}>
                                        {Indicadores.OtrosSyscaf}</span></h2>
                                <span className={`text-muted`}>Otros SYSCAF</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row ${property === 'DeviceType' && 'd-none'} mt-2`}>
                    <div className="row col-sm-12 col-md-12 col-xs-12 mx-auto">
                        {
                            Object.entries(FiltroDashBoardBasic.getDistribucionActivos(dataVehiculo))
                                .sort( (a : any[] , b : any[])=> b[1] - a[1] ).map((val: any, index: any) => {

                                    return <div key={`indicadores-${val[0]}`} className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto `}                                >
                                        <div className="m-3 text-center">
                                            <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                                <span onClick={() => {
                                                    setFilter('Todos');
                                                    setDeviceFilter(val[0]);
                                                }} style={{ cursor: 'pointer' }}>
                                                    {val[1]}</span></h2>
                                            
                                            <span className={`text-muted`}>{val[0]}</span>
                                            <span className="fs-6 text-muted">
                                                {` (${locateFormatPercentNDijitos(val[1] / Indicadores.Total, 2)})`}
                                            </span>
                                        </div>
                                    </div>
                                })
                        }
                    </div>
                </div>

                {(dataVehiculo.length > 0) && (<div className="table-responsive">
                    <MaterialReactTable
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                //   size: 100,
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: (theme) => ({
                                fontSize: 14,
                                fontStyle: 'bold',
                                color: 'rgb(27, 66, 94)'
                            }),
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                            sx: {
                                backgroundColor: ColorRows(row.original)
                            }
                        })}
                        columns={CamposListadoAssets}
                        data={dataVehiculoFiltrada}
                        enableColumnOrdering
                        enableStickyHeader
                        enableDensityToggle={false}
                        enablePagination={false}
                        enableRowVirtualization
                        muiTableContainerProps={{
                            sx: { maxHeight: '350px' }, //give the table a max height
                        }}
                    />
                </div>)}



            </Modal.Body>
        </Modal>)
};