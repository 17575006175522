
import { PageTitle } from "../../../../../_start/layout/core";
import { useEffect, useState } from "react";
import { Box, IconButton, Tooltip, Button, Typography } from "@mui/material";
import { Checkbox, DateRangePicker, Notification, useToaster } from "rsuite";
import { formatableJson_HHMMss } from "../../../../../_start/helpers/Helper";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { DataTransformation, GetDetalleViajes, GetReporteCHL, camposTablaCHL, fncReporteCHL } from "../data/reportedata";
import { ColumnFiltersState, SortingState, PaginationState } from "@tanstack/react-table";
import moment from "moment";
import DualListBox from "react-dual-listbox";
import { Modal } from "react-bootstrap-v5";
import { FileDownload, Help, ListAlt, Person, PostAdd, QuestionAnswer, Search } from "@mui/icons-material";
import { DescargarExcelPersonalizado } from "../../../../../_start/helpers/components/DescargarExcel";
import { DetalleViajes } from "./Modales/DetalleViajes";
import { getAssetsClienteId, getConductoresClienteId, getDriversClienteId, getVehiculosClienteId } from "../../../../../_start/helpers/Axios/DWHService";
import { DefaultRowViaje, Tooltips } from "../data/detalledata";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../_start/helpers/components/HtlmTooltip";
import React from "react";
import BlockUi from "@availity/block-ui";

export default function ReporteCHL() {

    const clienteids: string = "914";
    const clienteid: string = "-2565557128338028355";
    const FlagDiaria = 12 * 3600;
    const FlagSemana = 169200; // horas semanales que se deben parametrizar eventualmente

    const [filtroInconsistencias, setFiltroInconsistencias] = useState<boolean>(false);
    const [agrupadoSemana, setAgrupadoSemana] = useState<boolean>(false);
    const [dataTransormada, setdataTransormada] = useState<any[]>([]);
    const [data, setdata] = useState<any[]>([]);
    const [fechaInicial, setFechaInicial] = useState<any>();
    const [fechaFinal, setFechaFinal] = useState<any>();

    const [lstConductores, setlstConductores] = useState<any[]>([]);
    const [lstCondSelect, setlstCondSelect] = useState<any[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);

    const [showModalDet, setShowModalDet] = useState<boolean>(false);
    const [dataSel, setdataSel] = useState<any>();
    const [dataDetalleSel, setdataDetalleSel] = useState<any[]>([]);
    const [nuevoRegistro, setNuevoRegistro] = useState<boolean>(false);
    const [lstConductoresSistema, setlstConductoresSistema] = useState<any[]>([]);
    const [lstAssetsSistema, setlstAssetsSistema] = useState<any[]>([]);
    const toaster = useToaster();

    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }

    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [isError, setIsError] = useState(false);
    const [cargando, setCargando] = useState(false);

    const Consultar = () => {
        if (fechaInicial != undefined && fechaFinal != undefined) {
            setlstCondSelect([]); // en cada consulta se limpia los datos seleccinoados de conductores
            setIsLoading(true)
            setIsRefetching(true)
            setIsError(false)
            GetReporteCHL(clienteids, moment(fechaInicial).format(formatableJson_HHMMss), moment(fechaFinal).format(formatableJson_HHMMss))
                .then((response) => {
                    if (response.status === 200) {
                        setdataTransormada(response.data)
                        setdata(response.data)
                        // seteamos la informacion de los conductores para los filtros
                        setlstConductores(DataTransformation.getDriverFromData(response.data))
                    }
                    else
                        toaster.push(message('error', "Reporte", "Error al extraer la información"), {
                            placement: 'topCenter'
                        }
                        )

                    setAgrupadoSemana(false)
                    setFiltroInconsistencias(false)
                }).catch((error) => {
                    toaster.push(message('error', "Reporte", "Error al extraer la información"), {
                        placement: 'topCenter'
                    })

                    setIsError(true)

                }).finally(() => {
                    setIsLoading(false)
                    setIsRefetching(false)
                });
        }
        else
            toaster.push(message('error', "Reporte", "Debe escoger las fechas"), {
                placement: 'topCenter'
            })
    }


    // seleccion de vehiculos
    function SelectVehiculos() {
        return (
            <DualListBox className=" mb-3 " canFilter
                options={lstConductores}
                selected={lstCondSelect}
                onChange={(selected: any) => {
                    // dejamos los seleccionados
                    setlstCondSelect(selected)

                }}
            />
        );
    }
    function DatosFiltrados() {
        let datosFiltrados = data;
        // filtra si hay conductores
        if (lstCondSelect.length > 0)
            datosFiltrados = DataTransformation.getDatosFiltrado(data, lstCondSelect);
        // filtra si se ha seleccinado por duracion
        if (filtroInconsistencias && !agrupadoSemana)
            datosFiltrados = DataTransformation.getDatosFiltradoPorBandera(datosFiltrados, "Duracion", FlagDiaria);

        // si hay consolidado semana se debe agrupar la informaci'on con la sumatoria de todo   
        if (agrupadoSemana && !filtroInconsistencias)
            datosFiltrados = DataTransformation.getDatosAgrupadoPorConductor(datosFiltrados);
        setdataTransormada(datosFiltrados)
    }
    const modalObervaciones = (row: any) => {
        setNuevoRegistro(false);
        setCargando(true);
        const IsValidDate = moment(row.Fecha).isValid();
        const fechaInicial = !IsValidDate ? row.Inicio : row.Fecha;
        const fechaFinal = !IsValidDate ? row.Fin : row.Fecha;
        setdataSel({ ...row, clienteids });
        GetDetalleViajes("914", moment(fechaInicial).format(formatableJson_HHMMss)
            , moment(fechaFinal).format(formatableJson_HHMMss)
            , row.driverId)
            .then((response) => {
                if (response.status === 200) {
                    setdataDetalleSel(response.data)
                    setShowModalDet(true)
                }
                else
                    toaster.push(message('error', "Detalle Viajes", "Error al extraer la información"), {
                        placement: 'topCenter'
                    }
                    )

                setIsLoading(false)
                setIsRefetching(false)

            }).catch((error) => {
                toaster.push(message('error', "Detalle Viajes", "Error al extraer la información"), {
                    placement: 'topCenter'
                })

            }).finally(() => {
                setCargando(false);
            });

    }

    const getLstAssets = () => {
        getAssetsClienteId(null,clienteid).then((response) => {
            if (response.status == 200) {
                setlstAssetsSistema(response.data);
            }
        }).catch(() => {
            toaster.push(message('error', 'Extraer Vehiculos', 'Error al extraer datos de conductores, no podrá crear registros, favor comuníquese con su administrador.'))
        })
    }
    const getLstDrivers = () => {
        getDriversClienteId(clienteid).then((response) => {
            if (response.status == 200) {
                setlstConductoresSistema(response.data);
            }
        }).catch(() => {
            toaster.push(message('error', 'Extraer Conductores', 'Error al extraer datos de conductores, no podrá crear registros, favor comuníquese con su administrador.'))
        });
    }


    // para que la informacion en memoria se limpie cuando se desmonte la opcion
    useEffect(() => {
        getLstAssets();
        getLstDrivers();
        return function () {
            setdata([])
            setdataTransormada([])
            setlstAssetsSistema([])
            setlstConductoresSistema([])
            setlstCondSelect([])
            setlstConductores([])
        }

    }, [])

    // se dispara cada vez que abran el modal
    useEffect(() => {

        setdataSel({ ...dataSel, clienteids, lstConductores: lstConductoresSistema, lstAssets: lstAssetsSistema });
    }, [nuevoRegistro, showModalDet])


    // se dispara cuando se selecciona vehiculos, y los filtros 
    // para reducir la información mostrada
    useEffect(() => {
        // filtramos por los conductores seleccionados
        DatosFiltrados();

    }, [lstCondSelect, filtroInconsistencias, agrupadoSemana])

    function mostarInconsistencias() {
        return dataTransormada.length === 0
            || agrupadoSemana
    }

    return (
        <>
            <BlockUi blocking={cargando} keepInView message="Cargando datos, favor espere..."  >


                <PageTitle>Reporte CHL</PageTitle>
                <div className="card card-rounded shadow mt-2" style={{ width: '100%' }}  >
                    <div className="d-flex justify-content-between mb-2">
                        <div className="mx-auto">
                            <div className="ms-3 text-center">
                                <h3 className="mb-0">Reporte Clasificación Jornada Laboral</h3>
                                <span className="text-muted m-3">Permite ver la información del calculo basado en los viajes registrados en el sistema.</span>
                            </div>
                        </div>
                    </div>
                    {/* {CHL_SelectDriver(lstConductoresSistema, {}, {})} */}
                    <div className="card bg-secondary d-flex justify-content-between m-1">

                        <div className="col-sm-8 col-md-8 col-xs-8 col-lg-8"> <label className="control-label label  label-sm m-2 mt-4" 
                        style={{ fontWeight: 'bold' }}>Fecha inicial: </label>

                            <DateRangePicker hoverRange="week" isoWeek ranges={[]} className="mt-2" format="dd/MM/yyyy"
                                // disabledDate={combine(allowedMaxDays(31))}
                                onChange={(value, e) => {
                                    if (value !== null) {
                                        setFechaInicial(value[0])
                                        setFechaFinal(value[1])

                                    }
                                }}
                            />
                            <HtmlTooltipStyled arrow
                                title={HtmlContentTitle(Tooltips["Busqueda"].title, Tooltips["Busqueda"].content)
                                } >
                                <Button className="ms-2 bg-primary " onClick={() => { Consultar() }}>
                                    <Search style={{ color: "white" }} />

                                </Button>
                            </HtmlTooltipStyled>
                            <HtmlTooltipStyled arrow
                                title={HtmlContentTitle(Tooltips["Conductores"].title, Tooltips["Conductores"].content)
                                } >
                                <Button className="ms-2 bg-primary " onClick={() => { setShowModal(true) }}>
                                    <Person color="info" /></Button>

                            </HtmlTooltipStyled>
                            <HtmlTooltipStyled arrow
                                title={HtmlContentTitle(Tooltips["NuevoViaje"].title, Tooltips["NuevoViaje"].content)
                                } >
                                <Button className="ms-2 bg-primary "
                                    onClick={() => {
                                        setdataSel(DefaultRowViaje);
                                        setNuevoRegistro(true);
                                        setShowModalDet(true);
                                    }}
                                >
                                    <PostAdd color="warning" />
                                </Button>
                            </HtmlTooltipStyled>
                            <Checkbox  
                                disabled={dataTransormada.length === 0 || filtroInconsistencias}
                                onChange={(value, checked) => {
                                    setAgrupadoSemana(checked)
                                }}

                            >Consolidado Semana                               
                            </Checkbox>
                            <HtmlTooltipStyled arrow 
                                    title={HtmlContentTitle(Tooltips["ConsolidadoSemana"].title, Tooltips["ConsolidadoSemana"].content)
                                    } >
                                    <IconButton size="small"><Help /></IconButton>
                                </HtmlTooltipStyled>

                            
                                <Checkbox
                                    disabled={
                                        mostarInconsistencias()
                                    } onChange={(value, checked) => {
                                        setFiltroInconsistencias(checked)
                                    }}
                                >Mostrar novedades Diarias</Checkbox>
                             <HtmlTooltipStyled arrow 
                                    title={HtmlContentTitle(Tooltips["Novedades"].title, Tooltips["Novedades"].content)
                                    } >
                                    <IconButton size="small"><Help /></IconButton>
                                </HtmlTooltipStyled>

                        </div>

                    </div>

                    <MaterialReactTable
                        localization={MRT_Localization_ES}
                        muiTableBodyCellProps={({ row, cell }) => ({
                            sx: {
                                // si la duración de las horas diarias es superior a la configurada por el usuario se colocará en rojo 
                                // se filtrará por esos datos y se descargará un reporte para solo ellos
                                backgroundColor:

                                    !agrupadoSemana && row.original.Duracion >= FlagDiaria ? 'rgba(248, 215, 218, 1)' :
                                        (agrupadoSemana && row.original.Duracion >= FlagSemana ? ' rgba(255, 243, 205, 1)' :
                                            'rgba(212, 237, 218, 1)')

                            }

                        })}
                        columns={camposTablaCHL}
                        data={dataTransormada}
                        enableColumnFilters={false}
                        enableColumnOrdering
                        enableColumnDragging={false}
                        enablePagination={false}
                        enableStickyHeader
                        enableDensityToggle={false}
                        enableRowVirtualization
                        defaultColumn={{
                            minSize: 40, //allow columns to get smaller than default
                            maxSize: 200, //allow columns to get larger than default
                            size: 80,
                        }}
                        muiTableContainerProps={{
                            sx: { maxHeight: '600px' }, //give the table a max height
                        }}
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: 'Error al cargar información',
                                }
                                : undefined
                        }
                        onColumnFiltersChange={setColumnFilters}
                        onGlobalFilterChange={setGlobalFilter}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}

                        state={{
                            columnFilters,
                            globalFilter,
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching,
                            sorting,
                        }}
                        enableEditing={true}
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', gap: '1rem' }}

                            >
                                <Tooltip arrow placement="left" title="Detalle"
                                >
                                    <IconButton
                                        onClick={() => {
                                            modalObervaciones(row.original);
                                        }}

                                    >
                                        <ListAlt />
                                    </IconButton>
                                </Tooltip>
                            </Box>

                        )}
                        renderTopToolbarCustomActions={({ table }) => (
                            <Box
                                sx={{ justifyContent: 'flex-end', alignItems: 'center', flex: 1, display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                                <Button
                                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                                    onClick={() => { DescargarExcelPersonalizado(dataTransormada, camposTablaCHL, "Reporte CHL", fncReporteCHL) }}
                                    startIcon={<FileDownload />}>
                                    Todo
                                </Button>
                                <Button
                                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                                    onClick={() => { DescargarExcelPersonalizado(DataTransformation.getDatosFiltradoPorBandera(data, "Duracion", FlagDiaria), camposTablaCHL, "Reporte Novedades Diarias CHL", fncReporteCHL) }}
                                    startIcon={<FileDownload />} >
                                    Novedades Diarias
                                </Button>


                            </Box>
                        )}
                    />

                </div>
            </BlockUi>

            {((showModalDet && lstAssetsSistema.length > 0) ||
                (showModalDet && nuevoRegistro && lstAssetsSistema.length > 0 && lstConductoresSistema.length > 0)) && (
                    <DetalleViajes title="Detalle Viajes" show={showModalDet} handleClose={setShowModalDet}
                        seleccionado={dataSel} data={dataDetalleSel} isNew={nuevoRegistro} RecargarInfo={Consultar} />
                )}
            {(showModal) && (
                <Modal show={showModal} onHide={setShowModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title> Filtro por vehiculos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12">
                                <SelectVehiculos />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" className="secondary" onClick={() => { setlstCondSelect([]); }}>
                            Limpiar
                        </Button>
                        <Button type="button" className="primary" onClick={() => { setShowModal(false); }}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}