import { MRT_ColumnDef, MRT_TableInstance } from "material-react-table";
import moment from "moment";
import { Post_getConsultasDinamicasConAuthCore, Post_getconsultadinamicasUser } from "../../../../../_start/helpers/Axios/CoreService";
import { Post_GetConsultasDinamicasDWH, Post_GetDetallesListas, Post_GetEstadosAssets } from "../../../../../_start/helpers/Axios/DWHService";
import { GetFechaServidor, formatSimple, formatableJson_HHMMss } from "../../../../../_start/helpers/Helper";
import axios from "axios";
import { CORE_GetInformacionModulosSyscaf } from "../../../../../apiurlstore";

// trae la plantilla para configurar los modulos
export function GetInformacionModulos() {
    return axios({
        method: 'get',
        url: CORE_GetInformacionModulosSyscaf,
        headers: { 'Content-Type': 'application/json' }
    });
};

// trae la plantilla para configurar los modulos
export function GetEventosPorCliente(ClienteId: string | null) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['ClienteId'] = ClienteId;

    return Post_GetConsultasDinamicasDWH({
        NombreConsulta: "GetEventosPorCliente", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params);
};


// trae la plantilla para configurar los modulos
export function GetPreferenciasDescargar(ClienteId: string | null, TipoPreferencia: string | null) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['ClientesId'] = ClienteId;
    params['TipoPreferencia'] = TipoPreferencia;

    return Post_GetConsultasDinamicasDWH({
        NombreConsulta: "GetPreferenciasDescargar", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params);
};


export function GetClientesAdministradores(clientesIds: string | null) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['clienteIdS'] = clientesIds;

    return Post_getconsultadinamicasUser({
        NombreConsulta: "GetClientesAdministradores", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params);
};
export function GetSites(Clienteid: string) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Clienteid'] = Clienteid;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "GetListaSitesPorCliente", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};
export function GetDrivers(Clienteid: string) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Clienteid'] = Clienteid;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "GetDriversByClienteID", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};
export function GetAdministradores(Clienteid: string) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Clienteid'] = Clienteid;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "GetAdministradoresByClienteID", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};
export function SetParametroSistema(Datos: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['ParamsSistema'] = JSON.stringify(Datos.DetallesDatos);
    params['ClienteId'] = Datos.ClienteId;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "ActualizarParamSistema", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};
type AssetType = {
    AssetId: string,
    AssetTypeId: string,
    Description: string,
    RegistrationNumber: string,
    SiteId: string,
    ClienteId: string,
    CreateBy: string,
    UserState: string,
    AdditionalDetails: string | null | undefined,
    Clave: string
}
//PARA OPERACIONES DE EDITAR
export function SetParametroAsset(Datos: AssetType) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['AssetId'] = Datos.AssetId;
    params['AssetTypeId'] = Datos.AssetTypeId;
    params['Description'] = Datos.Description;
    params['RegistrationNumber'] = Datos.RegistrationNumber;
    params['SiteId'] = Datos.SiteId;
    params['ClienteId'] = Datos.ClienteId;
    params['CreateBy'] = Datos.CreateBy;
    params['UserState'] = Datos.UserState;
    params['AdditionalDetails'] = Datos.AdditionalDetails;
    params['Clave'] = Datos.Clave;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "OperacionesAssets", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};
type DriverType = {
    DriverId: string,
    fmDriverId: string,
    employeeNumber: string,
    Name: string,
    SiteId: string,
    ClienteId: string,
    EsActivo: string,
    Clave: string
}
export function SetParametroDriver(Datos: DriverType) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['DriverId'] = Datos.DriverId;
    params['fmDriverId'] = Datos.fmDriverId;
    params['employeeNumber'] = Datos.employeeNumber;
    params['Name'] = Datos.Name;
    params['SiteId'] = Datos.SiteId;
    params['ClienteId'] = Datos.ClienteId;
    params['EsActivo'] = Datos.EsActivo;
    params['Clave'] = Datos.Clave;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "OperacionesDrivers", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};
type SiteType = {
    SiteId: string,
    SiteName: string,
    ClienteId: string,
    EsActivo: string,
    Clave: string
}
export function SetParametroSite(Datos: SiteType) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['ClienteId'] = Datos.ClienteId;
    params['SiteId'] = Datos.SiteId;
    params['SiteName'] = Datos.SiteName;
    params['EsActivo'] = Datos.EsActivo;
    params['Clave'] = Datos.Clave;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "OperacionesSites", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};

export function GetConfiguraciones(Tipo: string | null | undefined) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Tipo'] = Tipo;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "GetConfiguracionesGenerales", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};

export function GetTiposAsset(Tipo: string | null | undefined) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Nombres'] = Tipo;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "GetTiposAsset", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};
export function GetAssets(Clienteid: string) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Clienteid'] = Clienteid;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "GetAssets", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};



//FIN ESPACIO OPERACIONES DE EDITAR

// export function GetAssets(Clienteid: string) {
//     return GetVehiculos(Clienteid);
// };
export function GetAssetsEstados(Tipo: string) {
    return Post_GetEstadosAssets(Tipo);
};
export function GetDetallesListas(Sigla: string) {
    return Post_GetDetallesListas(Sigla);
};
export function GetConfiguracionAssets(Clienteid: string) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Clienteid'] = Clienteid;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "getConfigurationAssets", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};
export function updateAssets(Clienteid: string, estadoClienteId: string, notificacion: string, GeneraIMG: string, Trips: string, Metrics: string, Event: string, Position: string, ActiveEvent: string) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Clienteid'] = Clienteid;
    params['estadoClienteId'] = estadoClienteId;
    params['notificacion'] = notificacion;
    params['GeneraIMG'] = GeneraIMG;
    params['Trips'] = Trips;
    params['Metrics'] = Metrics;
    params['Event'] = Event;
    params['Position'] = Position;
    params['ActiveEvent'] = ActiveEvent;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "updateAssets", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};


export const Campos: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'clienteIdS',
            header: 'Id Syscaf',
            Header: 'Id Syscaf',
            size: 90
        },
        {
            accessorKey: 'ClienteId',
            header: 'Id Mix',
            Header: 'Id Mix',
            size: 90
        }, {
            accessorKey: 'clienteNombre',
            header: 'Nombre',
            Header: 'Cliente',
            Cell(row: any) {
                return (row.row.original.clienteNombre)
            },
            size: 100
        },
        {
            accessorKey: 'usuarioNombre',
            header: 'Administrador',
            Header: 'Administrador',
            size: 90
        },

        {
            accessorKey: 'FechaIngreso',
            header: 'Fecha ingreso',
            Cell(row: any) {
                return moment(row.row.original.fechaIngreso).format(formatSimple)
            },
            size: 90
        },
        {
            accessorKey: 'estado',
            header: 'Estado',
            Header: 'Estado',
            Cell(row: any) {
                return (<span className={(row.row.original.estado == 1 || row.row.original.estado == "Activo" ? "badge bg-primary" : "badge bg-danger")}>{row.row.original.estado == 1 || row.row.original.estado == "Activo" ? "Activo" : "Inactivo"}</span>)
            },
            size: 80
        }
    ];


export const DatosDefaultSystem: any = {
    "notificacion ": 1, "Trips": 1, "Metrics": 1, "Event": 1, "Position ": 0, "ActiveEvent": 0, "fatiga_conf": {
        "usuarios": [

        ],
        "IsSite": false,
        "Sites": [

        ],
        "Activos": [
        ]

    }
}

export const CamposRender = {
    "Driver": [{
        accessorKey: 'name',
        header: 'Operador',
        Cell(row: any) {
            return (row.row.original.clienteNombre)
        },

        size: 100
    },
    {
        accessorKey: 'employeeNumber',
        header: 'Numero',
        Cell(row: any) {
            return ((row.row.original.employeeNumber == null ? "-" : row.row.original.employeeNumber))
        },
        size: 100
    }
    ],
    // "AssetShort": [{
    //     accessorKey: 'assetId',
    //     header: 'ID',
    //     enableHiding: true,
    //     size: 100
    // }, {
    //     accessorKey: 'description',
    //     header: 'Asset',
    //     size: 100
    // },
    // {
    //     accessorKey: 'registrationNumber',
    //     header: 'Placa',
    //     size: 100
    // },
    // {
    //     accessorKey: 'estado',
    //     header: 'Estado TX',
    //     Cell(row: any) {
    //         let state = (
    //             row.row.original.userState == "Unverified" ?
    //                 <span className='badge bg-warning'>{row.row.original.userState} </span> :
    //                 row.row.original.userState == "New installation" ?
    //                     <span className='badge  bg-info'>{row.row.original.userState}</span> :
    //                     <span className='badge  bg-success'>{row.row.original.userState}</span>
    //         )
    //         return (state)
    //     },
    //     size: 100
    // }
    // ],
    "Asset": [
        {
            accessorKey: 'AssetId',
            header: 'ID',
            enableHiding: true,
            size: 150
        },
        {
            accessorKey: 'Description',
            header: 'Asset',
            size: 100
        },
        {
            accessorKey: 'RegistrationNumber',
            header: 'Placa',
            size: 100
        },

        {
            accessorKey: 'userState',
            header: 'Estado',
            Cell(row: any) {
                let state = (
                    row.row.original.UserState === "Unverified" ?
                        <span className='badge bg-warning'>{row.row.original.UserState} </span> :
                        row.row.original.UserState === "New installation" ?
                            <span className='badge bg-info'>{row.row.original.UserState}</span> :
                            row.row.original.UserState === "Decommissioned" ?
                                <span className='badge bg-danger'>{row.row.original.UserState}</span> :
                                <span className='badge bg-success'>{row.row.original.UserState}</span>
                )
                return (state)
            },
            enableHiding: true,
            size: 120
        },
        {
            accessorKey: 'Estado',
            header: 'Estado TX',
            Cell(row: any) {
                let state = (
                    row.row.original.estado === "Sin Respuesta del Cliente" ?
                        <span className='badge bg-warning'>{row.row.original.estado} </span> :
                        row.row.original.estado === "En Mantenimiento" ?
                            <span className='badge bg-info'>{row.row.original.estado}</span> :
                            row.row.original.estado === "Detenido" ?
                                <span className='badge bg-danger'>{row.row.original.estado}</span> :
                                <span className='badge bg-success'>{row.row.original.estado}</span>
                )
                return (state)
            },
            enableHiding: true,
            size: 165
        },
        {
            accessorKey: 'SiteName',
            header: 'Sitio',
            enableHiding: true,
            size: 100
        },
        {
            accessorKey: 'vertical',
            header: 'Vertical',
            size: 100
        },
        {
            accessorKey: 'clasificacion',
            header: 'Clasificación',
            enableHiding: true,
            size: 100
        },
        {
            accessorKey: 'CreatedDate',
            header: 'Fecha Creación',
            Cell(row: any) {
                return (moment(row.row.original.CreatedDate).format(formatSimple))
            },
            enableHiding: true,
            size: 100
        }
    ], "Sites": [{
        accessorKey: 'sitename',
        header: 'Sitio',
        size: 100
    },
    {
        accessorKey: 'padre',
        header: 'Pertenece',
        size: 100
    },
    {
        accessorKey: 'EsActivo',
        header: 'Estado',
        size: 200,
        Cell(row: any) {
            let state = (
                row.row.original.EsActivo ?
                    <span className='badge bg-success'>Activo</span> : <span className='badge bg-danger'>Eliminado </span>
            )
            return (state)
        },
    }
    ],
    "Administrador": [{
        accessorKey: 'Nombres',
        header: 'Nombres',
        size: 200
    },
    {
        accessorKey: 'Email',
        header: 'Email',
        size: 100
    }

    ]
};

export const ColumnasExportarExcel = [
    {
        accessorKey: 'BD_Cliente',
        header: 'BD_Cliente'
    }, {
        accessorKey: 'AssetId',
        header: 'AssetId',
    },
    {
        accessorKey: 'SiteName',
        header: 'SiteName',
    },
    {
        accessorKey: 'VehicleID',
        header: 'VehicleID',
    },
    {
        accessorKey: 'VehicleDescription',
        header: 'VehicleDescription',
    },
    {
        accessorKey: 'RegistrationNumber',
        header: 'RegistrationNumber',
    },
    {
        accessorKey: 'DriverOBC',
        header: 'DriverOBC',
    },
    {
        accessorKey: 'DriverCAN',
        header: 'DriverCAN',
    },
    {
        accessorKey: 'DriverOBCLoadDate',
        header: 'DriverOBCLoadDate',
    },
    {
        accessorKey: 'LastConfiguration',
        header: 'LastConfiguration',
    },
    {
        accessorKey: 'CreatedDate',
        header: 'CreatedDate',
    },
    {
        accessorKey: 'DeviceType',
        header: 'DeviceType',
    },
    {
        accessorKey: 'ConfigurationGroup',
        header: 'ConfigurationGroup',
    },
    {
        accessorKey: 'GPRSContext',
        header: 'GPRSContext',
    },
    {
        accessorKey: 'UnitIMEI',
        header: 'UnitIMEI',
    },
    {
        accessorKey: 'UnitSCID',
        header: 'UnitSCID',
    },
    {
        accessorKey: 'LastTrip',
        header: 'LastTrip',
    },
];

export const CamposEventosModulos = (eventos: any) => {
    const Campos: MRT_ColumnDef<any>[] = [{
        accessorKey: 'EventTypeId',
        header: 'Id',
        size: 100
    },
    {
        accessorKey: 'descriptionEvent',
        header: 'Evento',
        size: 100
    },
    {
        accessorKey: 'isActive',
        header: 'Activo',
        Cell({ cell, column, row, table, }) {
            return <input
                key={`check_isActive_${row.original.EventTypeId}`}
                type="checkbox"
                checked={row.original.isActive}
                onChange={(e) => {

                }}
            />
        },

        size: 100
    }
    ]
    return Campos
};


export type baseNuevoEvento = {
    PreferenciasIdS: number;
    EsActivo: boolean;
    FechaSistema: string;
    TipoPreferenciaId: number;
    EventTypeId: string;
    descriptionEvent: string;
    isActive: boolean;
    Parametrizacion: string | null;
    Match: string | null; ClientesId: string;
}

export const initNuevoEvento: baseNuevoEvento =
{
    PreferenciasIdS: -1,
    EsActivo: true,
    FechaSistema: GetFechaServidor().format(formatableJson_HHMMss),
    TipoPreferenciaId: 0,
    EventTypeId: "",
    isActive: false,
    Parametrizacion: null,
    Match: null,
    ClientesId: "",
    descriptionEvent: ""
}

export const UserStateStatus = (Configuracion: any[], Estado: any) => {
    return Configuracion.filter((val: any) => {
        return (val.Tipo == Estado);
    })
}
export const TiposSeleccionados = (Configuracion: any[], Tipo: any) => {
    return Configuracion.map((item: any) => {
        return { "AssetTypeId": item.AssetTypeId, "Nombre": item.Nombre, "Name": item.Name };
    }).filter((value: any, index: any) => {
        return value.AssetTypeId === parseInt(Tipo);
    })[0];
}


export function ModalEditar_EventosModulo(datosEventosSeleccionados: any, setDatosEventosSeleccionados: any) {
    const campos: MRT_ColumnDef<any>[] =
        [
            {
                accessorKey: 'EventTypeId',
                header: 'EventTypeId'
            },
            {
                accessorKey: 'descriptionEvent',
                header: 'Nombre'
            }, {
                accessorKey: 'isActive',
                header: 'Es Activo',
                Cell({ row }) {

                    return (<input key={`is_active_event_cliente${row.original.EventTypeId}`}
                        type="checkbox"
                        checked={row.original.isActive}
                        onChange={(e) => {
                            let isExist = datosEventosSeleccionados.filter(
                                (f: any) => f.EventTypeId == row.original.EventTypeId
                            );
                            if (isExist.length > 0)
                                isExist[0].isActive = e.target.checked;

                            setDatosEventosSeleccionados([...datosEventosSeleccionados])
                        }}
                    />)
                }
            }, {
                accessorKey: 'Parametrizacion',
                header: 'Parametrizacion',
                Cell({ row}) {
                    return (<input key={`parametrizacion_event_cliente${row.original.EventTypeId}`} type="text" defaultValue={row.original.Parametrizacion} onChange={(e) => {

                        let isExist = datosEventosSeleccionados.filter(
                            (f: any) => f.EventTypeId == row.original.EventTypeId
                        );
                        if (isExist.length > 0)
                            isExist[0].Parametrizacion = e.target.value;

                        setDatosEventosSeleccionados([...datosEventosSeleccionados])

                    }}></input>)
                }
            }, {
                accessorKey: 'Match',
                header: 'Match',
                Cell({ row }) {
                    return (<input type="text" defaultValue={row.original.Match} onChange={(e) => {
                        let isExist = datosEventosSeleccionados.filter(
                            (f: any) => f.EventTypeId == row.original.EventTypeId
                        );
                        if (isExist.length > 0)
                            isExist[0].Match = e.target.value;
                        setDatosEventosSeleccionados([...datosEventosSeleccionados])

                    }}></input>)
                }
            }
        ];
    return campos;
}
