import { useEffect, useState } from "react"
import { PageTitle } from "../../../../../../_start/layout/core";
import BlockUi from "@availity/block-ui";
import { DatePicker, DateRangePicker, InputNumber, Notification, useToaster } from "rsuite";
import { PunchClock } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import moment from "moment";
import { DeserializarJSon, FechaServidor } from "../../../../../../_start/helpers/Helper";
import { CHL_GetParametroCliente, FuncionesConfiguracion, PORTAL_GuardarPropiedadCliente, PORTAL_GuardarPropiedadClienteDWH } from "../../data/reportedata";
import Checkmark from "../../../../../../_start/helpers/components/checkmark";


export default function CHLConfiguracion() {
    const toaster = useToaster();

    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }
    // si no existe parametrizaci'on para el usuario
    // usamos los datos por defecto para compeltar la informacio0n
    const dataPorDefecto: any = {
        horassemanales: 169200,
        horasdiarias: 28200,
        jornada: [
            { tipo: 1, nombre: "Nocturna", horainicial: 0, horafinal: 21600 },
            { tipo: 2, nombre: "Diurna", horainicial: 21600, horafinal: 75600 },
            { tipo: 3, nombre: "Nocturna", horainicial: 75600, horafinal: 86400 },
            { tipo: 4, nombre: "Nocturna", horainicial: 0, horafinal: 21600 }
        ]
    }
    const [horasDiaria, setHorasDiarias] = useState<number>(169200);
    const [horasSemanales, setHorasSemanales] = useState<number>(28200);
    const [jornadaTexto, setJornadaTexto] = useState<any>({ dia: "", noche: "" });
    const [jornada, setJornada] = useState<any>();
    const [loader, setLoader] = useState(false);
    const [JornadaDiurna, setJornadaDiurna] = useState<any>({
        Inicial: moment().toDate(),
        Final: moment().toDate()
    });
    // traemos la informacion de los parametros 

    useEffect(() => {
        setLoader(true)
        CHL_GetParametroCliente("914").then((res: any) => {
            if (res.status == 200) {
                let parametros: any = dataPorDefecto;
                const paramsSistemas: any = DeserializarJSon(res.data[0].ParamsSistema);
                if (paramsSistemas.CHL_config) // revisamos si tiene los parametros necesarios  de lo contrario usa el por defecto
                    parametros = paramsSistemas.CHL_config;


                setHorasDiarias(parametros.horasdiarias); // seteamos los principales
                setHorasSemanales(parametros.horassemanales);
                setJornada(parametros);
                const FechaInicial : any = moment().startOf('day').add("second", parametros.jornada[1].horainicial);
                const FechaFinal : any = moment().startOf('day').add("second", parametros.jornada[1].horafinal);
                setJornadaDiurna({
                    Inicial: FechaInicial.toDate(),
                    Final: FechaFinal.toDate()
                })
                // seteamos la jornada en texto para ayuda visual al usuario de la joramada actia;
                setJornadaTexto(FuncionesConfiguracion.getJornadaTexto(
                    FechaInicial,
                    FechaFinal));
            }
            setLoader(false);
        }).catch((e) => {
            setLoader(false);
        });
    }, []);

    const GuardarPropiedades = (propiedad: string) => {
     
        PORTAL_GuardarPropiedadCliente("914", JSON.stringify(propiedad)).then((res: any) => {
            if (res.status == 200) {
                toaster.push(message('success', "CHL Configuración", "Actualizado correctamente."), {
                    placement: 'topCenter'
                });

                // guarda en la tabla de 
                PORTAL_GuardarPropiedadClienteDWH("914", JSON.stringify(propiedad));
            }
        }).catch((e) => {
            toaster.push(message('error', "CHL Configuración", "Error al guardar la información. "), {
                placement: 'topCenter'
            });
        });

    }

    return (<>
        <PageTitle>Configuración CHL</PageTitle>
        <BlockUi tag="div" keepInView blocking={loader ?? false}  >

            <div className="card card-rounded shadow mt-2" style={{ width: '100%' }}  >
                <Stack className="border shadow-sm mt-10">
                    <div className="mx-auto">
                        <div className="ms-3 text-center">
                            <h5 className="mb-0">Configuración Jornada Laboral</h5>
                            <span className="text-muted m-3">Permite configurar la jornada laboral para los cálculos del sistema.</span>

                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <Stack >
                            <div className="d-flex justify-content-center"  >
                                <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Seleccione Jornada Diurna: </label>
                                <DateRangePicker ranges={[]} format="HH:mm" caretAs={PunchClock}
                                    value={[JornadaDiurna.Inicial, JornadaDiurna.Final]}
                                    onChange={(value, e) => {
                                        if (value !== null) {

                                            const inicial: any = moment(value[0]);
                                            const final: any = moment(value[1]);
                                            // para mostrar los cambios que selecciono
                                            setJornadaTexto(FuncionesConfiguracion.getJornadaTexto(inicial, final));          // se genera el datos para ser guardado en el servidor
                                            setJornadaDiurna({
                                                Inicial: value[0],
                                                Final: value[1]
                                            })
                                            const objetoFinal = { ...jornada, jornada: FuncionesConfiguracion.getJornadaObjeto(inicial, final) };
                                            setJornada(objetoFinal); // seteamos la jornada
                                        }
                                    }}
                                />


                            </div>
                            <div className="d-flex flex-column">
                                <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Jornada Diurna Actual:
                                    <span className="ms-8 fw-bolder fs-2">
                                        {jornadaTexto.dia}
                                    </span>  </label>
                                <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Jornada Nocturna Actual:
                                    <span className="ms-2 fw-bolder fs-2">
                                        {jornadaTexto.noche}
                                    </span>  </label>

                            </div>
                        </Stack>

                    </div>
                </Stack>
                <Stack className="border shadow-sm mt-10">
                    <div className="mx-auto">
                        <div className="ms-3 text-center">
                            <h5 className="mb-0">Configuración Horas Laborables</h5>
                            <span className="text-muted m-3">Permite configurar el total de horas necesarias para los cálculos del sistema.</span>

                        </div>
                    </div>
                    <Stack  >
                        <div className="mx-auto">
                            <div className="d-flex justify-content-center"  >
                                <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-3" >Diaria (Horas): </label>
                                <DatePicker format="HH:mm"
                                    value={moment().startOf('day').add('second', horasDiaria).toDate()}
                                    caretAs={PunchClock} onChange={(value) => {
                                        const segundos: number = moment(value).diff(moment().startOf('day'), 'seconds');
                                        setHorasDiarias(segundos);
                                        setJornada({ ...jornada, horasdiarias: segundos });

                                    }
                                    } />

                            </div>
                        </div>
                        <div className="mx-auto">
                            <div className="d-flex justify-content-center"  >
                                <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-3" >Semanal: </label>
                                <InputNumber placeholder="HH" value={
                                    Math.floor(horasSemanales / 3600)} max={56} style={{ width: 70, height: 35 }}
                                    onChange={(value: any) => {
                                        let valor = value != "" ? value : "0";
                                        const TotalSegundos = ((parseInt(valor) * 60) + Math.floor(horasSemanales % 3600 / 60)) * 60;
                                        setHorasSemanales(TotalSegundos);
                                        setJornada({ ...jornada, horassemanales: TotalSegundos });
                                    }}
                                />
                                <span className="mx-2 mt-2">:</span>
                                <InputNumber placeholder="mm" max={59} style={{ width: 70, height: 35 }}
                                    value={Math.floor(horasSemanales % 3600 / 60)}
                                    onChange={(value: any) => {
                                        let valor = value != "" ? value : "0";

                                        const TotalSegundos = ((Math.floor(horasSemanales / 3600) * 60) + parseInt(valor)) * 60;
                                        setHorasSemanales(TotalSegundos);
                                        setJornada({ ...jornada, horassemanales: TotalSegundos });

                                    }}
                                />


                            </div>
                        </div>
                    </Stack>
                    <div className="d-flex justify-content-center mt-5">
                        <Button className="m-2  btn btn-sm btn-primary" onClick={() => { GuardarPropiedades(jornada) }}>Guardar</Button>
                    </div>
                </Stack>
            </div>
        </BlockUi>
    </>
    )
}