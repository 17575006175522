import { GetDataRequerimientos, RequerimientosProvider } from "../../core/RequerimientosCore";
import CreacionCentral from "./CreacionCentral";


export default function Creacion() {
    return (
        <RequerimientosProvider>
            <GetDataRequerimientos />
            <CreacionCentral />
        </RequerimientosProvider>
    )
}