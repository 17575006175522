/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDataFatigue } from "../../../core/provider";
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

type Props = {
    className: string;
    innerPadding?: string;
    tipoData?: number;
    nameChart?: string;
    titulo: string;
};

const IndicadorAlertasCriticas: React.FC<Props> = ({ className,  nameChart = "clasificacionFlota_", titulo }) => {


    const {  alertas } = useDataFatigue();
    const [opciones, setopciones] = useState<any>();

    //Pintamos indicador 2 donna
    useEffect(() => {

        let dataFiltrada = alertas.filter((item: any) => "Riesgo alto".indexOf(item.Criticidad) > -1);
        let agrupadoAlertas = dataFiltrada
            .reduce((p: any, c: any) => {
                let name = c.TipoAlerta;
                p[name] = p[name] ?? 0;
                p[name] = p[name] + 1;
                return p;
            }, {});

        if (Object.keys(agrupadoAlertas).length > 0) {

            let arrayChart : any[] = Object.values(agrupadoAlertas);
            // se determina de que tipo se necesita la informacion para mostrarla en los indicadores
            let labelsArray = Object.keys(agrupadoAlertas);
            let opciones: ApexOptions = {
                //colors: [color4, color2, color3],
                series: arrayChart,
     
                    chart: {
                        id: nameChart,
                        fontFamily: 'Montserrat',
                        type: 'donut',
                        dropShadow: {
                            enabled: true,
                            color: '#111',
                            top: -1,
                            left: 3,
                            blur: 3,
                            opacity: 0.2
                        }
                    },
                    stroke: {
                        width: 0,
                    },
                    plotOptions: {
                        pie: {
                            donut: {

                                labels: {
                                    show: true,
                                    value: {
                                        show: true,
                                        fontSize: '21px',
                                        offsetY: -7
                                    },
                                    total: {
                                        showAlways: true,
                                        show: true,
                                        fontSize: '12px',
                                    }
                                }
                            }
                        }
                    },
                    labels: labelsArray,
                    dataLabels: {
                        enabled: true,
                        formatter: function (val: number) {
                            return val.toFixed(1) + "%"
                        },
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontSize: '10px',
                        formatter: function(seriesName : any, opts: any) {
                            return [seriesName.slice(0, 6) + '...', "(", opts.w.globals.series[opts.seriesIndex], ")"].join(' ')
                        }
                    },
                   
                    theme: {
                        palette: 'palette2'
                    },
                    title: {
                        text: titulo,
                        align: 'center',
                    }
        


            };
            setopciones(opciones);
        }

    }, [alertas]);
    return (
        <div className={`card ${className}`}>
            {(opciones != undefined) && (
                <Chart key={`chardonaalertascriticas_${nameChart}`} options={opciones} series={opciones.series}
                    type="donut" />
            )}
        </div>
    );
};

export { IndicadorAlertasCriticas };



