import { GetDataDashBoard, RequerimientosProvider } from "../../core/DashboardCore";
import PostVentaDashBoard from "./DashBoardPanelCentral";

export default function HomePostVenta() {

    return (
        <RequerimientosProvider>
            <GetDataDashBoard></GetDataDashBoard> 
            <PostVentaDashBoard />
        </RequerimientosProvider>

    )
}