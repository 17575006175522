/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { CommonAlertProvider } from "../../../../../../_start/helpers/components/Alert";
import { PageTitle } from "../../../../../../_start/layout/core";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../_start/helpers/components/HtlmTooltip";
import { NuevoUsuario, Usuarios_Tooltips } from "../../data/usuariosdata";
import { GetDataUsuarios, UsuariosProvider, useDataUsuarios } from "../../core/usuariosProvider";
import { NuevoRegistro } from "./Modales/GestionUsuarios";
import { ECO_ListadoUsuarios } from "./Modales/Listado";
import { IconButton, Stack } from "@mui/material";
import { Help } from "@mui/icons-material";
export default function Registration() {
  return <CommonAlertProvider>
    <RegistrationComponent />
  </CommonAlertProvider>
}

const RegistrationComponent: React.FC = () => {
  const { setUsuarioSel, usuarioSel } = useDataUsuarios();
  const [show, setShow] = useState<boolean>(false);
  return (
    <>
      <UsuariosProvider>
        <GetDataUsuarios />
        <PageTitle>Gestión de usuarios</PageTitle>
        <div className="card card-rounded  mt-2" style={{ width: '100%' }}  >
          <Stack>
            <div className="mx-auto">
              <div className="ms-3 text-center">
                <h5 className="mb-0">Gestión de Usuarios<HtmlTooltipStyled arrow
                  title={HtmlContentTitle(Usuarios_Tooltips["principal"].title, Usuarios_Tooltips["principal"].content)
                  } >
                  <IconButton size="small"><Help /></IconButton>
                </HtmlTooltipStyled>

                </h5>

              </div>
            </div>
            <div className="d-flex justify-content-end">
              <HtmlTooltipStyled arrow title={HtmlContentTitle(Usuarios_Tooltips["Nuevo"].title, Usuarios_Tooltips["Nuevo"].content)} >
                <button disabled={show} className="btn btn-sm btn-primary" onClick={() => {
                  setUsuarioSel(NuevoUsuario);
                  setShow(true);
                }}><i className="bi-person-fill-add"></i> Nuevo</button>
              </HtmlTooltipStyled> </div>
          </Stack>

        </div>
        <div className="card row mt-3 bg-secondary">
          <div className="col-12">
            {(!show) ? (<ECO_ListadoUsuarios setshow={setShow} />) : (<NuevoRegistro show={show} setshow={setShow} />
            )}
          </div>
        </div>


      </UsuariosProvider>
    </>
  );
}
