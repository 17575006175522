import moment from "moment";
import React from "react"
import { Card } from "react-bootstrap-v5"
import { FormatoColombiaDDMMYYYHHmm } from "../../../../../../_start/helpers/Constants";

export const EncabezadoModales: React.FC<{ row: any }> = ({ row }) => {
    const fechaevento: any = moment(JSON.parse(row.DetalladoEventos).at(-1).EventDateTime as Date).format(FormatoColombiaDDMMYYYHHmm);
    const totaleventos: number = JSON.parse(row.DetalladoEventos).length;
    return <Card className="border border-gray-300  shadow-sm mx-2 mb-2">

        <span className="card-title fw-bolder  bg-secondary rounded  border-bottom fs-5 m-1 text-center">Información Básica</span>
        <div className="row mx-1 mt-2 ">

        <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12">
            <label className="mx-2 fs-6 fw-bolder">Alerta: </label> <span className="mx-1 fs-5 text-muted">{`${row.TipoAlerta}`}</span>
            </div>
        </div>
        <div className="row mx-1 mt-2 ">
            <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                <label className="mx-2 fs-6 fw-bolder">Último Evento: </label> <span className="mx-2 fs-5 text-muted">{`${fechaevento}`} </span>
            </div>
            <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
                <label className="mx-2 fs-6 fw-bolder">Total Eventos: </label> <span className="mx-2 fs-5 text-muted">{`${totaleventos}`} </span>
            </div>
        </div>
        <div className="row mx-1">
            <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
                <label className="mx-2 fs-6 fw-bolder" >Placa: </label> <span className="mx-2 fs-5 text-muted">{`${row.vehiculo}`}</span>
            </div>
            <div className="col-sm-8 col-xl-8 col-md-8 col-lg-8">
                <label className="mx-2 fs-6 fw-bolder" >Conductor: </label> <span className="mx-2 fs-5 text-muted">{`${row.conductor}`}</span>
            </div>
        </div>
    </Card>
}