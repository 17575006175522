
import { FiltroHistorial } from "../../../data/consultasGenerales";
import { Collapse, InputGroup } from "react-bootstrap-v5";
import { DonaUsers } from "./Dona";
import { useState } from "react";
import { Button } from "@mui/material";
import { QueryStats } from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import {  ECO_camposTablaResumidoModulos,  ECO_ModulosDetalladoUsuario, ECO_Tooltips } from "../../../data/datausuarios";
import { HtmlTooltipHelp } from "../../../../../../../_start/helpers/components/HtlmTooltip";


type Props = {
    data: any[];
    dataUsuarios: any[];
    innerPadding?: string;
};

export const TablaEntradaVsModulos: React.FC<Props> = ({ data, dataUsuarios, innerPadding = "" }) => {


    const [isVisible, setisVisible] = useState<boolean>(false);
    const [isVisibleD, setisVisibleD] = useState<boolean>(true);
    const [titulo, setTitulo] = useState<string>("Mostrar");
    const [titulod, setTituloD] = useState<string>("Ocultar");
    const [dataDetallada, setDataDetallada] = useState<any[]>([]);

    const IND_modulo = FiltroHistorial.getDistribucionPorModulos(data);

    /** Funcion que al seleccionar algun link de los totalizadoes muestra una tabla al lado con
     * la informacion detallada de cada uno
     */
    const callbackLinks = (detallado : any[]) => {
        setisVisible(true); // hacemos visible el panel
        setTitulo('Ocultar');
        setDataDetallada(FiltroHistorial.getDetalladoModuloPorUsuario(detallado));

    }
    return (
        <>
            <div className={`card card-stretch mb-5 mb-xxl-8`}>
                {/* <!--begin::Header--> */}
                <div className={` border-1 pt-5 `}>
                    <div className="mx-auto">
                        <div className="ms-3 text-center">
                            <h5 className="mb-0">Módulos</h5>
                            <span className="text-muted m-0 fs-5">Indicadores generales por Módulos.</span>
                        </div>
                    </div>

                    <div className={`d-flex justify-content-center m-2  flex-wrap border rounded `}>
                        <DonaUsers config={{ width: 500, height: 500 }} series={Object.values(IND_modulo)} labels={Object.keys(IND_modulo)} nameChart={`pormodulo`}
                            titulo="Ingresos Por Módulo" showLegend={true} showTotal={true} />
                    </div>

                </div>
                {/* <!--end::Header--> */}

                {/* <!--begin::Body--> */}
                <div className="card-body pt-2 pb-0 mt-n3 w-200">
                    <InputGroup>
                    <Button
                        onClick={() => { setisVisibleD(!isVisibleD); setTituloD(!isVisibleD ? "Ocultar" : "Mostrar") }}
                        startIcon={<QueryStats />}>
                        {titulod} Detallado Por Módulos 
                    </Button>
                    <HtmlTooltipHelp title={ECO_Tooltips["ClienteDetalle"].title} content={ECO_Tooltips["ClienteDetalle"].content} />
                    </InputGroup>
                    <InputGroup>
                    <Button hidden={!isVisible}
                        onClick={() => {
                            setisVisible(!isVisible);
                            setTitulo(!isVisible ? "Ocultar" : "Mostrar"); setDataDetallada([])
                        }}
                        startIcon={<QueryStats />}>
                        {titulo} Detallado Por Usuario 
                    </Button>
                    <div hidden={!isVisible}>
                    <HtmlTooltipHelp title={ECO_Tooltips["UsuariosRegistrados"].title} content={ECO_Tooltips["UsuariosRegistrados"].content} />
                    </div>
                    </InputGroup>
                    <div className="d-flex d-column">
                        <Collapse in={isVisibleD}>
                            <div className="m-2 h-400px" style={{ width: (isVisible && isVisibleD) ? '50%' : '100%' }}>
                                <MaterialReactTable
                                    columns={ECO_camposTablaResumidoModulos(callbackLinks)}
                                    data={FiltroHistorial.getDetalladoPorModulos(data)}
                                    muiTableBodyRowProps={({ row }) => ({
                                        sx: {

                                            backgroundColor:
                                                row.original.Categoria === 3 ? 'rgba(248, 215, 218, 1)' :
                                                    row.original.Categoria === 2 ? 'rgba(255, 243, 205, 1)' :
                                                        row.original.Categoria === 1 ? 'rgba(212, 237, 218, 1)' :
                                                            'rgba(255, 243, 205, 1)'
                                        }
                                    })}
                                    enablePagination={false}
                                    enableColumnFilters={false}
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enableRowVirtualization
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '400px' }, //give the table a max height
                                    }}                              
                                />

                            </div>
                        </Collapse>
                        <Collapse in={isVisible}>
                            <div style={{ width: (isVisible && isVisibleD) ? '50%' : '100%' }} >
                                <div className="d-flex justify-content-between m-2 flex-wrap overflow-auto h-400px border rounded" >
                                    <MaterialReactTable
                                        columns={ECO_ModulosDetalladoUsuario}
                                        data={dataDetallada}

                                        enablePagination={false}
                                        enableColumnFilters={false}
                                        enableStickyHeader
                                        enableDensityToggle={false}
                                        enableRowVirtualization
                                        muiTableContainerProps={{
                                            sx: { maxHeight: '400px' }, //give the table a max height

                                        }}

                                    />
                                </div>
                            </div>
                            {/* <!--end::Tap pane--> */}
                        </Collapse>
                    </div>


                </div>

            </div>
        </>
    )
}




