import { DeserializarJSon } from "../../../../../_start/helpers/Helper";
import moment from "moment";
import { FormatoColombiaDDMMYYYHHmmss } from "../../../../../_start/helpers/Constants";

function getUsuariosByProperty(historial: any[], propiedad: string) {
    return historial.reduce((p: any, c: any) => {
        // extraemos a quienes vamos agrupar
        const property: string = c[`${propiedad}`];
        // si existe trae los datos actuales los trae en caso no lo crea;
        p[property] = p[property] ?? [];
        p[property].push(c); // empujamos los datos
        return p;
    }, {});
}
export const FiltroUsuarios = {
    // agrupa datos nombre cliente
    getUsuariosByCliente: (usuarios: any[]) => {
        return getUsuariosByProperty(usuarios, 'ClienteNombre');
    },
    // trae todos los indicadores generales basados en los ingresos
    getIndicadoresGenerales: (usuarios: any[], historial: any[]) => {

        const HistorialPorCliente: any = FiltroHistorial.getUsuarioByCliente(historial); // trae la data del historial de usuairos agrupada por cliente
        const UsuariosActivos: any = FiltroHistorial.getUsuarioByUsuario(historial);// trae la data del historial de usuairos agrupada por usuario
        const UsuariosPorCliente: any = FiltroUsuarios.getUsuariosByCliente(usuarios);// trae la informacion de usuarios creados en el sistema agrupados por usuarios
        const usuariosSyscafConActividad: number = FiltroHistorial.getTotalUsuariosActivos(HistorialPorCliente['SYSCAF'] ?? []); //Total de usuarios activos  SYSCAF en el sistema
        const usuariosClienteConActividad: number = FiltroHistorial.getTotalUsuariosActivos(historial) - usuariosSyscafConActividad;
        const usuariosSyscafRegistrados: number = (UsuariosPorCliente['SYSCAF'] ?? [] as any[]).length ?? 0; // Total usuarios syscaf en el sistema

        const TotalUsuarios: number = usuarios.length; // total usuarios activos en el sistema
        const TotalUsuariosActivos: number = Object.keys(UsuariosActivos).length; // total de usuarios que estuvieron usando el sistema en el rango de fecha especificado
        const usuariosClientesRegistrados: number = TotalUsuarios - usuariosSyscafRegistrados;

        return {
            usuariosClienteConActividad, usuariosClientesRegistrados, TotalUsuariosActivos, usuariosSyscafRegistrados, usuariosSyscafConActividad
        }
    }
}


export const FiltroHistorial = {
    // agrupa datos nombre cliente

    getUsuarioByCliente: (historial: any[]) => {
        return getUsuariosByProperty(historial, 'NombreCliente');
    },
    // agrupa los datos por nombre
    getUsuarioByUsuario: (historial: any[]) => {
        return getUsuariosByProperty(historial, 'Nombres');
    },
    getTotalUsuariosActivos: (historial: any[]) => {
        return Object.keys(getUsuariosByProperty(historial, 'Nombres'))?.length ?? 0;
    },
    // trae la informacion de los usuarios activos e inactivos
    getClientesActivosVsInactivos: (usuarios: any[], historial: any[]) => {
        const UsuariosPorCliente: any = FiltroUsuarios.getUsuariosByCliente(usuarios);// trae la informacion de usuarios creados en el sistema agrupados por usuarios
        const HistorialPorCliente: any = FiltroHistorial.getUsuarioByCliente(historial); // trae la data del historial de usuairos agrupada por cliente
        return Object.entries(UsuariosPorCliente).map(
            (m: any) => {
                const Activos = FiltroHistorial.getTotalUsuariosActivos(HistorialPorCliente[m[0]] as any[] ?? []);
                const Inactivos = (m[1] as any[]).length - Activos;
                const Total = (m[1] as any[]).length;
                const porc: number = Math.round(Inactivos / Total * 100);

                return {
                    Cliente: m[0],
                    Total,
                    Activos,
                    Inactivos,
                    Categoria: porc >= 0 && porc < 33 ? 1 : porc >= 33 && porc < 66 ? 2 : 3

                }

            }
        )
    },
    getListadoTodosUsuariosPorCliente: (usuarios: any[], historial: any[], cliente: string) => {
        return FiltroUsuarios.getUsuariosByCliente(usuarios)[cliente];// trae la informacion de usuarios creados en el sistema agrupados por usuarios

    },
    getListadoUsuariosActivosPorCliente: (usuarios: any[], historial: any[], cliente: string) => {
        const usuariosTotales: any[] = FiltroUsuarios.getUsuariosByCliente(usuarios)[cliente];// trae la informacion de usuarios creados en el sistema agrupados por usuarios
        const hpc: any[] = FiltroHistorial.getUsuarioByCliente(historial)[cliente]; // trae la data del historial de usuairos agrupada por cliente

        return usuariosTotales.filter((f: any) => hpc.filter((ff: any) => ff.UserId === f.Id).length > 0);
    },
    getListadoUsuariosInActivosPorCliente: (usuarios: any[], historial: any[], cliente: string) => {
        const usuariosTotales: any[] = FiltroUsuarios.getUsuariosByCliente(usuarios)[cliente];// trae la informacion de usuarios creados en el sistema agrupados por usuarios
        const hpc: any[] = FiltroHistorial.getUsuarioByCliente(historial)[cliente]; // trae la data del historial de usuairos agrupada por cliente

        return usuariosTotales.filter((f: any) => hpc.filter((ff: any) => ff.UserId === f.Id).length === 0);
    },
    // devuelve el total de ingresos por usuario durante el tiempo especificado
    getDistribucionPorModulos: (historial: any[]) => {
        // hacemos un map de las opciones para poder contabilizarlas en su totalidad
        const lstOpciones: any[] = [];
        historial.forEach(m => {
            // agrupamos por modulo para luego contabilizarlos al final
            const opciones: any[] = m.Opciones != null ? DeserializarJSon(m.Opciones) : [];
            lstOpciones.push(...opciones.reduce((p: any[], c) => {
                const modulo = c.Modulo;
                if (!p.includes(modulo))
                    p.push(modulo);
                return p;

            }, [])); // lo retornamos como un array
        });

        // ahora reducimos el detallado para que nos de el % de ingreso en los modulos       
        return lstOpciones.reduce((p: any, c) => {
            p[c] = p[c] ?? 0;
            p[c]++;
            return p;

        }, {});

    },
    // devuelve el detallado de las opciones a las que se ingresa
    getDetalladoPorModulos: (historial: any[]) => {
        // hacemos un map de las opciones para poder contabilizarlas en su totalidad
        const lstOpciones: any[] = [];
        historial.forEach(m => {
            // agrupamos por modulo para luego contabilizarlos al final
            const opciones: any[] = m.Opciones != null ? DeserializarJSon(m.Opciones) : [];

            // agrupamos por modulo
            const agrupadoModulo: any[] = Object.entries(opciones.reduce((p: any, c) => {
                const modulo = c.Modulo;
                p[modulo] = p[modulo] ?? [];
                p[modulo].push({ Nombres: m.Nombres, UserId: m.UserId, ...c });
                return p;

            }, {})); // lo retornamos como un array

            // lo consolidamos en el array general
            agrupadoModulo.forEach((f) => {
                const isExist = lstOpciones.filter((filter) => filter.Modulo === f[0]);
                if (isExist.length === 0)
                    lstOpciones.push({ Modulo: f[0], Detallado: f[1] })
                else
                    isExist[0].Detallado.push(...f[1]);
            })

        });

        // ahora totalizamos en el detallado que mostrara en la tabla

        lstOpciones.forEach((f) => {
            const detallado = f.Detallado;

            const TotalUsuarios: number = Object.keys(detallado.reduce((p: any, c: any) => {
                p[c.UserId] = p[c.UserId] ?? 0;
                p[c.UserId]++;
                return p;
            }, {})).length;

            f.TotalUsuarios = TotalUsuarios;

            const ReducidoOpciones = detallado.reduce((p: any, c: any) => {
                p[c.Opcion] = p[c.Opcion] ?? 0;
                p[c.Opcion]++;
                return p;
            }, {});
            const TotalOpciones: number = Object.keys(ReducidoOpciones).length;

            f.TotalOpciones = TotalOpciones;
            f.LabelsOpciones = Object.entries(ReducidoOpciones);

        })
        return lstOpciones;

    },
    getDetalladoModuloPorUsuario(detallado: any[]) {
        const consolidado: any = detallado.reduce((p: any, c: any) => {
            const UserId: string = c.UserId;
            p[UserId] = p[UserId] ?? [];
            p[UserId].push(c);
            return p;

        }, {});

        return Object.entries(consolidado).map((f: any) => {

            return {
                Nombres: f[1][0].Nombres,
                UltimosIngresos: FiltroHistorial.getConsolidadoIngresosUsuario(f[1], f[0]),
                LabelsOpciones: FiltroHistorial.getConsolidadoOpcionesUsuario(f[1], f[0])
            }

        })
    },
    // filtra por usuario y trae el ultimo acceso
    getConsolidadoIngresosUsuario: (detallado: any[], UserId: string) => {
        const filrado: any[] = detallado.filter(f => f.UserId === UserId);
        const ReducidoOpciones = filrado.reduce((p: any, c: any) => {

            p[c.Opcion] = p[c.Opcion] ?? c.Date;

            if (moment(p[c.Opcion]).date() < moment(c.Date).date())
                p[c.Opcion] = c.Date;
            return p;
        }, {});
        return Object.entries(ReducidoOpciones);
    },
    // filtra por usuario y consolida opciones
    getConsolidadoOpcionesUsuario: (detallado: any[], UserId: string) => {
        const filrado: any[] = detallado.filter(f => f.UserId === UserId);
        const ReducidoOpciones = filrado.reduce((p: any, c: any) => {
            p[c.Opcion] = p[c.Opcion] ?? 0;
            p[c.Opcion]++;
            return p;
        }, {});
        return Object.entries(ReducidoOpciones);
    },

    // agrupa ultimos accesos de los usuarios
    AgruparUltimosAccesoPorModulo: (detallado: any[]) => {
        if (detallado !== undefined && detallado !== null) {
            let encabezados: string[] = [];
            let detalle: any[] = [];
            let TotalRows: number[] = [];
            const ReducidoModulos = detallado?.reduce((p: any, c: any) => {
                p[c.Modulo] = p[c.Modulo] ?? [];
                p[c.Modulo].push(c);
                return p;
            }, {});

            encabezados = Object.keys(ReducidoModulos);
            detalle.push(encabezados);
            const data: any[] = Object.values(ReducidoModulos);
            data.forEach((m: any[]) => { TotalRows.push(m.length) });

            let maxValue: number = Math.max(...TotalRows);

            for (var i = 0; i < maxValue; i++) {

                let tempData: string[] = [];
                encabezados.forEach(f => {
                    tempData.push(ReducidoModulos[f][i] ? `${ReducidoModulos[f][i].Opcion}-(
                        
                        ${moment(ReducidoModulos[f][i].UltimoIngreso).format(FormatoColombiaDDMMYYYHHmmss)})` : '')
                })
                detalle.push(tempData)
            }

            return detalle;
        }
        return [];
    }
}

export const FiltroAdmin = {
    getConsolidadoPorAdmin: (Admis: any[]) => {

        return Admis.reduce((p: any[], c: any) => {
            let userid: string = c.usuario;
            let usuarioNombre: string = c.usuarioNombre;


            if (userid !== '') {
                userid = userid.slice(0, -1); // le quitamos el ultimo caracter pues en la consulta es una coma
                usuarioNombre = usuarioNombre.slice(0, -1);

                // verificamos si en array de nuevos objetos ya existe un valor al usuario id

                const userSplit = userid.split(','); // como puden ser varios se debe realizar el split
                userSplit.forEach((f, ind: number) => {
                    const isExist = p.filter(ff => ff.userid === f); // buscar por el id del usuario 
                    if (isExist.length === 0) // si no existe ingresa uno nuevo
                        p.push({ userid: f, admin: usuarioNombre.split(',')[ind], clientes: [c.ClienteId] })
                    else
                        isExist[0].clientes.push(c.ClienteId) // seguimos adicionando los clientes
                })
            }
            else {
                const isExist = p.filter(ff => ff.userid === ''); // buscar por el id del usuario 
                if (isExist.length === 0) // si no existe ingresa uno nuevo
                    p.push({ userid: '', admin: 'Sin Asignar', clientes: [c.ClienteId] })
                else
                    isExist[0].clientes.push(c.ClienteId) // seguimos adicionando los clientes
            }
            return p;
        }, []);
    },

    usuariosFiltradosPorAdmin(datausuarios: any[], clientes: string[]) {

        return datausuarios.filter(f => clientes.filter(c => c === f.ClienteId).length > 0);

    },
    historialFiltradosPorAdmin(historial: any[], clientes: string[]) {

        return historial.filter(f => clientes.filter(c => c === f.ClienteId).length > 0);

    }
}


export const FilroPeticiones = {

    getDataDonaGeneral: (registro: any) => {
        return [ registro.Negativas,registro.Positivas]

    },
    // cuenta el total de las peticiones por clienten
    getTotalCliente: (registros: any[]) => {
        return registros.reduce((p, c) => {

            if (c.NombreNormalizado != null) {
                p[c.NombreNormalizado] = p[c.NombreNormalizado] ?? 0;
                p[c.NombreNormalizado] += c.Total; // incrementamos el valor
            }
            return p
        }, {})

    },

}
