import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { useToaster, Notification } from "rsuite";
import { GetSites, SetParametroSite } from "../../data/Clientes";
import { AxiosResponse } from "axios";
import confirmarDialog from "../../../../../../_start/helpers/components/ConfirmDialog";

type ModalSiteType = {
    showModal: boolean,
    setShowModal: any,
    ClienteSeleccionado: any,
    CamposSite:any,
    setCamposSite:any,
    setloader:any,
    Consultas:any,
    setConsultas:any,
    TipoCliente:any,
    Cliente:any,
    setDatosSites:any
}
export const ModalSites: React.FC<ModalSiteType> = ({ showModal, setShowModal, ClienteSeleccionado, setloader,CamposSite , setCamposSite, Consultas, setConsultas , TipoCliente, Cliente, setDatosSites}) => {
    const toaster = useToaster();
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }
    useEffect(() =>{
        let a = {...CamposSite};
        a.ClienteId = ClienteSeleccionado;
        setCamposSite(a);
    },[ClienteSeleccionado])
    const GuardarSites = () =>{
        confirmarDialog(() => {
            SetParametroSite(CamposSite).then((response: AxiosResponse<any>) => {
                toaster.push(message('success', "Sitios", response.data[0].Mensaje), {
                    placement: 'topCenter'
                });
                let Consult = [...Consultas]
                let nuevo = Consult[3].Data.map((val:any) =>{
                    if(String(val.siteid) == CamposSite.SiteId){
                        val.EsActivo = CamposSite.EsActivo;
                        val.SiteName = CamposSite.SiteName;
                    }
                    return val;
                })
                setDatosSites(nuevo);
                Consult[3].Data = nuevo;
                setConsultas(Consult);
                setShowModal(false);
                setloader(false);
            }).catch(() => {
                setloader(false);
                console.log("Error");
            })
        }, `Esta seguro que desea guardar el site`, 'Guardar');
        setloader(true);
    }
    const Validacion = () =>{
        if(CamposSite.SiteName == "" || CamposSite.SiteName == null || CamposSite.SiteName == "null" || CamposSite.SiteName ==undefined){
            toaster.push(message('error', "Sitios", "Ingrese el nombre del sitio"), {
                placement: 'topCenter'
            });
            return false;
        }
        return true;
    }

    return(
        <>
            <Modal show={showModal} onHide={setShowModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title className="text-center"> Configuración sitios</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="card  rounded shadow-sm">
                        <div className="card-body">
                            <div className="border mb-2 mt-1">
                                <div className=" mb-2 mt-1 d-flex justify-content-evenly">
                                    {(Cliente != undefined) && (<div className="mt-1">
                                        <div>
                                            <label className="mx-4 fs-6 fw-bolder">Cliente: </label>
                                        </div>
                                        <span className="mx-4 fs-5 text-muted">{Cliente.Nombre}</span>
                                    </div>)}
                                    <div className="mt-1">
                                        <div>
                                            <label className="mx-4 fs-6 fw-bolder">Id: </label>
                                        </div>
                                        <span className="mx-4 fs-5 text-muted">{CamposSite.SiteId}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">SiteName</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-SiteName"><i className="bi bi-text-left"></i></span>
                                                <input  onChange={(e:any) =>{
                                                         let a = {...CamposSite};
                                                         a.SiteName = e.target.value;
                                                         setCamposSite(a);
                                                }} value={CamposSite.SiteName} type="text" className="form-control" placeholder="Nombre del sitio" aria-label="SiteName" aria-describedby="basic-SiteName" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" variant="primary" onClick={GuardarSites}>
                            Guardar
                        </Button>
                        <Button type="button" variant="secondary" onClick={() => { setShowModal(false); }}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
        </>
        )
}