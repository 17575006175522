import { ConsecutivoVisual, GetDatosRequerimientos, NotificarRequerimiento, SetDiagnostico, SetRequerimiento } from "../../data/Requerimientos";
import confirmarDialog, { successDialog } from "../../../../../../_start/helpers/components/ConfirmDialog";
import { AxiosResponse } from "axios";
import { Button, Modal } from "react-bootstrap-v5";
import { useToaster, Notification } from "rsuite";
import { useState } from "react";
import { EncabezadoHtml } from "./Componentes/Encabezado";
import { DeserializarJSon, GetFechaServidor, formatableJson_HHMMss } from "../../../../../../_start/helpers/Helper";
import { ConsultasIniciales, useDataRequerimientos } from "../../core/RequerimientosCore";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";
import { UserModelSyscaf } from "../../../../auth/models/UserModel";
type Props = {
    show: boolean;
    handleClose: (show: any) => void;
}
const CreacionSt: React.FC<Props> = ({ show, handleClose }) => {
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;

    const { lstEstadosReq,  ListadoDLPRespuesta, ReqSeleccionado, setloader,  setlstRequerimientos, setReqSeleccionado} = useDataRequerimientos();
   
    const toaster = useToaster();
    const [RequerimientoSAMM, setRequerimientoSAMM] = useState<string>("")

    const [ObservacionRST, setObservacionRST] = useState<string>(ReqSeleccionado?.ObservacionDLP)
    const NotificacionST= "Hola {Admin}, Estás siendo notificado porque el agente {UsuarioDestino} ha enviado el requerimiento {Consecutivo} a servicio tecnico. Por favor, revisa la información. Saludos cordiales.";
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }


    //==========================================================================================================
    // RESUELVE EL REQUERIMIENTO
    //==========================================================================================================
    const AsignarST = () => {
        const Encabezado = DeserializarJSon(ReqSeleccionado.original.Cabecera)
        let estado = lstEstadosReq.filter((e: any) => e.tipo == "ST" && e.tipoestado == "noopen" &&  e.valor == "7" )[0];
        if (RequerimientoSAMM == null || RequerimientoSAMM == undefined || RequerimientoSAMM == "") {
            toaster.push(message('error', "Requerimiento", "Debe ingresar los datos de SAMM"), {
                placement: 'topEnd'
            });
            return false;
        };

        let _obs = DeserializarJSon(ReqSeleccionado.original.Observaciones);
        _obs.push(
            {
                fecha: GetFechaServidor().format(formatableJson_HHMMss),
                observacion: `Ref SAMM ${RequerimientoSAMM} con ${ObservacionRST}`,
                usuario: ReqSeleccionado.Nombres,
                Esdlp: true,
                estado: `${estado.tipo} - ${estado.label}`,
            }
        )
        let Cabecera = DeserializarJSon(ReqSeleccionado.original.Cabecera);
        // guardamos el requerimiento SAMM en la cabecera
        Cabecera = {...Cabecera, RefSAMM: RequerimientoSAMM}

        
        confirmarDialog(() => {
            let Campos:any = GetDatosRequerimientos(ReqSeleccionado.original.Id, Cabecera, _obs, estado);
        

            SetRequerimiento(Campos).then((response: AxiosResponse<any>) => {
                if (response.statusText == "OK") {
                    successDialog(response.data[0][""], '');

                    NotificarRequerimiento(Encabezado.UsuarioId, ReqSeleccionado.original.Id,
                        NotificacionST.replace("{Admin}", `${Encabezado.administrador}`).replace("{UsuarioDestino}", `Equipo Servicio Técnico`).replace("{Consecutivo}", `${Encabezado.Consecutivo}`))

                    //Para enviar al servidor El diagnostico
                    Campos["Diagnostico"] = JSON.stringify(ListadoDLPRespuesta);
                    Campos["Observaciones"] = Campos["Observaciones"];
                    Campos["Estado"] = Campos["Estado"];
                    Campos["Id"] = Campos["Id"];
                    SetDiagnostico(Campos).then((response: AxiosResponse<any>) => {
                        if (response.statusText == "OK") {
                            console.log("Diagnostico guardado")
                        }
                    }).catch(({ error }) => {
                        console.log("Error", error)
                    });
                }
                handleClose(false);
                ActualizarInformacionReq();
            }).catch((error: any) => {
                console.log("Error", error);
                handleClose(false);
            });
        }, `¿Esta seguro que desea enviar el requerimiento a servicio técnico`, "Guardar");
    };

    const Encabezado = DeserializarJSon(ReqSeleccionado.original.Cabecera)

      //Funcion para actualizar la tabla desde otro punto
      const ActualizarInformacionReq = () => {
        let estado = lstEstadosReq.filter((e: any) => e.tipo == "ST" && e.tipoestado == "noopen")[0];
        let _obs = DeserializarJSon(ReqSeleccionado.original.Observaciones);
        _obs.push(
            {
                fecha: GetFechaServidor().format(formatableJson_HHMMss),
                observacion: ReqSeleccionado?.ObservacionDLP,
                usuario: vUser.Nombres,
                estado: `${estado.tipo} - ${estado.label}`,
                Esdlp: false,
            }
        )
        let Campos:any = {};

        Campos["Diagnostico"] = JSON.stringify(ListadoDLPRespuesta);
        Campos["Observaciones"] = JSON.stringify(_obs);
        Campos["Estado"] = JSON.stringify({ tipo: estado.tipo, valor: estado.valor, label: estado.label, color: estado.color, tipoestado: estado.tipoestado });
        Campos["Id"] = ReqSeleccionado.original.Id;

        ConsultasIniciales(setloader, vUser, setlstRequerimientos, toaster); //ACTUALIZAR DE FORMA LOCAL Y NO VOLVER A LLAMAR
        // FiltroDatos(data);
    }

    return <>
        <Modal show={show} onHide={handleClose} size={"lg"}>
            <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Modal.Title>{`Requerimiento a servicio tecnico numero ${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-0  p-0">
                <div className="card">
                    <div className="row">
                        {(Encabezado != undefined) && (
                            <>
                                <EncabezadoHtml ReqSeleccionado={ReqSeleccionado} Admin={undefined}></EncabezadoHtml>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="row">

                                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6 m-5">
                                            <div className="">
                                                <label className="label label-sm fw-bolder">Numero SAMM</label>
                                            </div>
                                            <input className="form-control input input-sm" value={RequerimientoSAMM} onChange={(e) => {
                                                setRequerimientoSAMM(e.target.value);
                                            }} />
                                        </div>
                                        <div className="col-sm-11 col-xl-11 col-md-11 col-lg-11 m-5">
                                            <div className="">
                                                <label className="label label-sm fw-bolder">Observaciones</label>
                                            </div>
                                            <textarea className="form-control" value={ObservacionRST} onChange={(ff) => {
                                                setObservacionRST(ff.target.value);
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btn-sm" variant="info" onClick={() => {
                    AsignarST();
                }}>
                    Guardar
                </Button>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => handleClose(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
export default CreacionSt