import { AxiosResponse } from "axios";

import { ExclusionProvider, useDataExlusion } from "../core/ProviderExclusion";
import { getClientes } from "../../../APPS/Mobile/data/dataConfiguracion";
import ExclusionCentral from "./ExclusionCentral";

export default function Exclusion() {
    

    return(
        <ExclusionProvider>

            <ExclusionCentral/>
        </ExclusionProvider>
    )
}