import { Button, Modal } from "react-bootstrap-v5";
import { useEffect, useState } from "react";
import { useDataFatigue } from "../core/provider";
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import MaterialReactTable from 'material-react-table'
import type {
  ColumnFiltersState
} from '@tanstack/react-table';
import { Box, IconButton, Tooltip, Typography, createFilterOptions } from "@mui/material";
import { Message, VerifiedUser, Map, List, AirplanemodeInactive, Send } from "@mui/icons-material";
import { DeserializarJSon, EsJson, GetFechaServidor, formatableJson_HHMMss } from "../../../../../_start/helpers/Helper";
import {
  confirmarDialogTexto, setGestor, setNotificaciones, setObservaciones as GuardarObservaciones,
  informedefault
} from "../data/dashBoardData";
import confirmarDialog, { confirmarDialogConCancelAccion } from "../../../../../_start/helpers/components/ConfirmDialog";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../setup";
import { UserModelSyscaf } from "../../../auth/models/UserModel";
import moment from "moment";
import { useToaster, Notification } from "rsuite";
import { FormatoColombiaDDMMYYYHHmm } from "../../../../../_start/helpers/Constants";
import { MapTab2 } from "./Map";
import { NotificacionSyscaf } from "../../../../../_start/helpers/components/NotificacionSonora";
import { AxiosResponse } from "axios";
import '../styles/Style.css'
import { CardContainercolumnas } from "../data/Configuracion";
import { EncabezadoModales } from "./PanelCentral/EncabezadoModales";
import { EnvioReporteAlerta } from "./PanelCentral/EnvioReporteAlerta";
import { ModalObservaciones } from "./PanelCentral/ModalObservaciones";
import { ModalDetalladoEventos } from "./PanelCentral/ModalDetalladoEventos";

type Props = {
  isActive: boolean;
  isDetails: boolean;
  filtro: number;
  Clientes?: any,
  Operador: any
}

const CardContainerAlertas: React.FC<Props> = ({ filtro, Operador }) => {
  const isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.user
  );
  const model = (isAuthorized as UserModelSyscaf);

  const { alertas, clienteIds, setalertas, setDataDetalladoFiltrado,
    setFiltrado, setusuario, Cliente } = useDataFatigue();
  const [Informe, setInforme] = useState<any>(informedefault);
  const [InformeBase, setInformeBase] = useState<any>(informedefault);
  const [dataAlertas, setDataAlertas] = useState([]);
  const [AlertaSeleccionada, setAlertaSeleccionada] = useState<any>({});
  const TieneReporte = Cliente.Parametros?.fatiga_conf?.TieneReporte;
  const [showGestion, setShowGestion] = useState(false);
  const [show4, setShow4] = useState(false);

  const showModal = () => {
    setShowGestion(true);
  }

  const [showDetalleEventos, setDetalleEventos] = useState(false);
  const [showInforme, setShowInforme] = useState<boolean>(false);

  const handleCloseInforme = () => {
    setShowInforme(false);
  };

  const [title, settitle] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  //table state
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const toaster = useToaster();

  const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
    return (<Notification className="bg-light-danger" type={type} header={titulo}
      closable duration={10000}>
      {mensaje}
    </Notification>)
  }
  //primer cargue carga userid
  useEffect(() => {
    setusuario(model);
  }, [])

  useEffect(() => {

    if (alertas.length > 0) {
      let dataFiltrada = [];

      filtro == 0 ? dataFiltrada = alertas.filter((item: any) => item.Criticidad == "Riesgo alto" && item.EstadoGestion == null && item.EsVisible)
        : filtro == 1 ? dataFiltrada = alertas.filter((item: any) => item.Criticidad == "Riesgo moderado" && item.EstadoGestion == null && item.EsVisible)
          : filtro == 2 ? dataFiltrada = alertas.filter((item: any) => item.Criticidad == "Riesgo bajo" && item.EstadoGestion == null && item.EsVisible)
            : filtro == 3 ? dataFiltrada = alertas.filter((item: any) => item.EstadoGestion == 0 && item.EsVisible)
              : filtro == 4 ? dataFiltrada = alertas.filter((item: any) => item.EstadoGestion == 1 && item.EsVisible)
                : dataFiltrada = alertas.filter((item: any) => item.EstadoGestion == 2 && item.EsVisible);

      setDataAlertas(dataFiltrada);
      setRowCount(dataFiltrada.length);
    } else
      setDataAlertas([]);


  }, [alertas])

  useEffect(() => {
    setInformeBase((Cliente === undefined ? informedefault : Cliente.Parametros.fatiga_conf.InformeAlerta))
  }, [Cliente]);
  //listado campos tablas


  //gestión obervación
  const nombreGestor = (Operador.Operador != undefined) ? `${Operador.Usuario}(${Operador.Operador})` : Operador.Usuario;
  const setObservacion = (observacion: string, escerrado: string, EsAuto: boolean) => {
    let GestorObervaciones: any = {};
    const observaciones: any[] = DeserializarJSon(AlertaSeleccionada.Observaciones);
    const tiempoCierreAuto: any = DeserializarJSon(AlertaSeleccionada.Detalles).TiempoCierre;
    GestorObervaciones = {
      AlertaId: AlertaSeleccionada.AlertaId,
      fechaapertura: observaciones[0].fechaapertura,
      fechagestion: GetFechaServidor().format(formatableJson_HHMMss),
      value: observacion,
      EsCerrado: escerrado?.toString(),
      gestor: nombreGestor ?? model.Nombres,
      estadoGestion: escerrado == "false" && observacion == 'Se reabre Gestión' ? `Gestion reabierta` : escerrado == "false"
        && observacion == 'Se reasigna gestión' ? 'Gestión Reasignada' : escerrado == 'true' ? `Gestionado` : `En Gestion`
      ,
      EsAuto: EsAuto
    };

    confirmarDialog(() => {
      GuardarObservaciones(clienteIds as string, JSON.stringify(GestorObervaciones), tiempoCierreAuto).
        then((response) => {
          toaster.push(message('success', "Gestionar", "Gestión Guardada"), {
            placement: 'topCenter'
          });

          const lstAlertas: any[] = alertas.filter((f: any) => f.AlertaId == AlertaSeleccionada.AlertaId);
          if (lstAlertas.length > 0) {
            lstAlertas[0].Observaciones = JSON.stringify([...observaciones, GestorObervaciones]);
            lstAlertas[0].EstadoGestion = escerrado == "false" ? 0 : 1;
            if (escerrado != "false" && TieneReporte == 1) // verifica si tiene reporte para que le aparezca la opcion
              confirmarDialogConCancelAccion(() => {
                setShowGestion(false);
                GenerarInforme(AlertaSeleccionada);

              }, "¿Desea enviar informe de alertas?", "Si", "No", () => {
                setShowGestion(false)
                modalObervaciones(lstAlertas[0]);
              })
            else {
              setShowGestion(false)
              modalObervaciones(lstAlertas[0]);
            }
          }
        }).catch((error) => {
          toaster.push(message('error', "Gestionar", "Error al gestionar intente nuevamente"), {
            placement: 'topCenter'
          });
        });
    }, escerrado == "false" && observacion != 'Se reabre Gestión' ? `¿Esta seguro que desea agregar el comentario?` :
      escerrado == "false" && observacion == 'Se reasigna gestión' ? '¿Esta seguro que desea reasignarse la gestión?'
        : escerrado == 'true' ? `¿Esta seguro de terminar la gestión?` : `¿Esta seguro de reabrir la gestión?`
      , escerrado == "false" && observacion != 'Se reabre Gestión' ? escerrado == "false"
        && observacion == 'Se reasigna gestión' ? 'Reasignar'
        : "Guardar" : escerrado == 'true' ? "Terminar" : "Reabrir")
  }

  //gestión gestor
  const setGestorPreoperacional = (row: any) => {
    let alertaId: number = row.AlertaId;
    let tiempoCierre: string = JSON.parse(row.Detalles).TiempoCierre;
    setAlertaSeleccionada(row);

    //MAPA
    //mapa 
    let DataMapa = new Array()
    DataMapa = [...DataMapa, ...JSON.parse(row.DetalladoEventos)]
    setDataDetalladoFiltrado(DataMapa);
    setFiltrado(true);
    //areglo temporal primera muestra menos 5 horas tras actulizar se arregla
    let GestorObervaciones: any = {};
    let nombreGestor = (Operador.Operador != undefined) ? `${Operador.Usuario}(${Operador.Operador})` : Operador.Usuario;
    GestorObervaciones = {
      fechaapertura: GetFechaServidor().format(formatableJson_HHMMss),
      fechagestion: GetFechaServidor().format(formatableJson_HHMMss),
      value: "Gestor Asignado",
      EsCerrado: null,
      gestor: nombreGestor ?? model.Nombres,
      estadoGestion: `En Gestion`,
      EsAuto: true
    };

    confirmarDialog(() => {

      setGestor(clienteIds as string, '[' + JSON.stringify(GestorObervaciones) + ']', false, alertaId, nombreGestor ?? model.Nombres, tiempoCierre).then(() => {

        const lstAlertas: any[] = alertas.filter((f: any) => f.AlertaId == alertaId);
        if (lstAlertas.length > 0) {
          lstAlertas[0].Observaciones = JSON.stringify([GestorObervaciones]);
          setalertas(alertas);
          modalObervaciones(lstAlertas[0]);
        }


        toaster.push(message('success', "Asignar Gestor", "Gestor Asignado"), {
          placement: 'topCenter'
        });
      }).catch(() => {
        toaster.push(message('error', "Asignar Gestor", "Error al asignar gestor intente nuevamente"), {
          placement: 'topCenter'
        });
      });
    }, `¿Desea usted gestionar esta alerta?`, "Sí");
  }

  const InactivarAlerta = (row: any) => {
    let FechaActual = moment();
    let Notificacion: any = {};
    confirmarDialogTexto((e: any) => {
      //Fecha y hora de debe volver a notificar
      let f = moment(FechaActual).add(parseInt(e.value), "m");
      Notificacion = {
        "UsuarioId": model.Id,
        "EsNotificado": "false",
        "Mensaje": `La alerta ${row.original.TipoAlerta} de criticidad ${row.original.Criticidad} está en espera`,
        "FechaHoraNotificar": moment(f).format(FormatoColombiaDDMMYYYHHmm),
        "Vehiculo": row.original.vehiculo,
        "AlertaId": row.original.AlertaId,
        "Conductor": row.original.conductor
      }
      setNotificaciones(String(clienteIds), JSON.stringify(Notificacion), String(row.original.AlertaId)).then((response: AxiosResponse<any>) => {
        if (response.statusText == "OK") {
          toaster.push(NotificacionSyscaf({
            includeSonido: (true), duration: 5000, closable: true,
            type: "success", content: `Sera notificado dentro de ${e.value} minutos`, header: ` `
          }), {
            placement: 'topCenter'
          });
        }
      }).catch((error) => {
        toaster.push(message('success', "Mensaje", `Error al guardar ${e.value} en las notificaciones`), {
          placement: 'topCenter'
        });
      });
    }, "Tiempo máximo a suspender", "Inserte tiempo (Min) a suspender", '<i class="fa fa-thumbs-up"></i> Si', '<i class="fa fa-thumbs-down"></i> No', `${FechaActual}`, `${row.original.FechaCierreAuto}`, `${retornarLabel(row, row.original.FechaCierreAuto)}`);
  }
  const retornarLabel = (row: any, FechaCierre: any) => {
    //Pongo timeOut porque la fecha de cierre la seteo cuando doy click
    //Por lo tanto demora un tanto mientras FechaCierre cambia con el hook
    let FechaActual = moment().format(FormatoColombiaDDMMYYYHHmm);
    let _FechaCierre = moment(FechaCierre).format(FormatoColombiaDDMMYYYHHmm);
    return `<div className="badge badge-success fw-bolder" style="color:${(_FechaCierre < FechaActual ? "red" : "green")} ;"> <label className="badge badge-success fw-bolder" style={{color:"red"}}>Fecha cierre: ${moment(row.original.FechaCierreAuto).format(FormatoColombiaDDMMYYYHHmm)}</label></div>`
  }
  //modal
  const modalObervaciones = (row: any) => {
    setAlertaSeleccionada(row);
    showModal();
  }

  const modalDetalleEventos = (row: any) => {
    setAlertaSeleccionada(row)
    setDetalleEventos(true);
  }

  //Función para ir al mapa de marcial
  const IrToMap = (row: any) => {
    setAlertaSeleccionada(row);
    let Data = new Array()
    Data = [...Data, ...JSON.parse(row.DetalladoEventos)]
    setDataDetalladoFiltrado(Data);
    setFiltrado(true);
    showModals();
  };

  //Funciones para mapa tab
  const showModals = () => {
    settitle('Mapa')
    setShow4(true);
  }

  const handleCloseModals = () => {
    settitle('')
    setShow4(false);
  };

  const GenerarInforme = (row: any) => {
    setAlertaSeleccionada(row);
    let Observaciones = DeserializarJSon(row.Observaciones);
    const DetalladoEvento: any = DeserializarJSon(row.DetalladoEventos)[0];
    if (Observaciones.length > 0) {
      Observaciones = Observaciones.filter((p: any) => (!p?.EsAuto));
    }
    let i = { ...InformeBase };
    i.Evento = DetalladoEvento.evento;
    i.Hora = moment(DetalladoEvento.EventDateTime).format("HH:mm:ss");
    i.Placa = row.vehiculo;
    i.Conductor = row.conductor;
    i.Coordenadas = `${DetalladoEvento.Latitud}, ${DetalladoEvento.Longitud}`
    i.Contacto = (row.contactos != null ? (EsJson(row.contactos) ? DeserializarJSon(row.contactos) : row.contactos) : []);
    i.Observacion = Observaciones;
    i.Imagen = null;
    i.Video = null;
    i.Operador = (Operador.Operador != undefined) ? `${Operador.Usuario}(${Operador.Operador})` : "";
    i.Ubicacion = DetalladoEvento?.FormattedAddress;

    setInforme(i);
    setShowInforme(true);
  };

  // permite traet el objeto autocomplet y filtrarlo por la ruta seleccionada

  return (

    <>
      <MaterialReactTable
        localization={MRT_Localization_ES}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 140,
            muiTableHeadCellProps: {
              align: 'center'
            }
          },
        }}
        columns={CardContainercolumnas}
        data={dataAlertas}
        muiTableBodyRowProps={({ row }) => ({

          sx: {

            backgroundColor:
              row.original.Criticidad === 'Riesgo alto' ? 'rgba(248, 215, 218, 1)' :
                row.original.Criticidad === 'Riesgo moderado' ? 'rgba(255, 243, 205, 1)' :
                  row.original.Criticidad === 'Riesgo bajo' ? 'rgba(212, 237, 218, 1)' :
                    'rgba(255, 243, 205, 1)'
          }
        })}

        enableTopToolbar
        enableColumnOrdering
        enableEditing
        enableFilters
        enablePagination={false}
        enableColumnFilters={false}
        enableStickyHeader
        enableStickyFooter
        enableDensityToggle={false}
        enableRowVirtualization
        muiTableContainerProps={{
          sx: { maxHeight: '400px' }, //give the table a max height

        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: 'error',
              children: 'Error al cargar información',
            }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        rowCount={rowCount}
        enableExpandAll={false}
        enableRowDragging={false}

        renderDetailPanel={({ row }) => row.original.Cabecera == null ? null : (<Box className="bg-transparent" sx={{
          display: 'grid',
          margin: 'auto',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          width: '100%',
        }}
        >
          <Typography>Operación: {DeserializarJSon(row.original.Cabecera).Operacion}</Typography>
          <Typography>Ruta: {DeserializarJSon(row.original.Cabecera).Ruta}</Typography>
          <Typography>Ciudad: {DeserializarJSon(row.original.Cabecera).Ciudad}</Typography>
          <Typography>Zona: {DeserializarJSon(row.original.Cabecera).Zona}</Typography>
          <Typography>Contacto: {DeserializarJSon(row.original.Cabecera).ContactoHa}</Typography>
          <Typography>Motivo: {DeserializarJSon(row.original.Cabecera).Motivo}</Typography>
          <Typography>Ubicación: {DeserializarJSon(row.original.Cabecera).Ubicacion}</Typography>

        </Box>)}

        renderRowActions={({ row, table }) => (

          <div className="d-flex ">

            {(row.original.EstadoGestion != null || row.original.EstadoGestion == true) ?
              <Tooltip arrow placement="left" title="Detalle Gestión">
                <IconButton onClick={() => {
                  modalObervaciones(row.original);
                }}>
                  <Message />
                </IconButton>
              </Tooltip>
              :
              <></>}

            {((row.original.EsGestionable == undefined ? false : row.original.EsGestionable) == true && (filtro === 0 || filtro === 1 || filtro === 2)) ?

              <Tooltip arrow placement="left" title="Gestionar">
                <IconButton
                  onClick={() => {
                    setGestorPreoperacional(row.original);
                  }}
                >
                  <VerifiedUser />
                </IconButton>
              </Tooltip>
              : <></>
            }
            <Tooltip arrow placement="left" title="Detalle Eventos Alertas">
              <IconButton onClick={() => {
                modalDetalleEventos(row.original);
              }}>
                <List />
              </IconButton>
            </Tooltip>
            {/* //Para el mapa  Marcial */}
            <Tooltip arrow placement="top" title="Ver en el mapa">
              <IconButton onClick={(e: any) => {
                IrToMap(row.original);
              }} >
                <Map />
              </IconButton>
            </Tooltip>
            {(filtro === 3) ?
              <Tooltip arrow placement="top" title="Suspender alerta">
                <IconButton onClick={(e: any) => {
                  InactivarAlerta(row);
                }} >
                  <AirplanemodeInactive className="text-warning" />
                </IconButton>
              </Tooltip>
              : <></>
            }
            {
              (TieneReporte == 1 && row.original.EsGestionable && row.original.EstadoGestion == 1
                && row.original.EsCerradoAuto == null) ?
                <Tooltip arrow placement="top" title="Enviar Reporte">
                  <IconButton onClick={(e: any) => {

                    let idtimer = setTimeout(() => {
                      GenerarInforme(row.original);
                      clearTimeout(idtimer);
                    }, 300);
                  }} >
                    <Send className="text-warning" />
                  </IconButton>
                </Tooltip>
                : <></>
            }
          </div>
        )
        }
        // initialState={{ density: 'compact' }}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isRefetching
        }}
      />
      {/* //showGestion */}

      {(showGestion) &&
        (<ModalObservaciones
          showGestion={showGestion}
          setShowGestion={setShowGestion}
          setObservacion={setObservacion}
          AlertaSeleccionada={AlertaSeleccionada}
          GenerarInforme={GenerarInforme}
          Operador={Operador} clienteIds={Cliente} message={message}></ModalObservaciones>)}
      {/* //showDetalleEventos */}
      {(showDetalleEventos) && (
        <ModalDetalladoEventos showDetalleEventos={showDetalleEventos}
          setshowDetalleEventos={setDetalleEventos}
          AlertaSeleccionada={AlertaSeleccionada}></ModalDetalladoEventos>
      )}

      {/* modal mapa */}
      {(show4) && (<Modal
        show={show4}
        onHide={handleCloseModals}
        size="lg">
        <Modal.Header className=" fw-bolder  bg-secondary rounded m-4" style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", fontSize: 20
        }} closeButton={false}>
          <Modal.Title className="text-uppercase" style={{ fontSize: 20 }}>
            {title}</Modal.Title>
        </Modal.Header>
        <EncabezadoModales row={AlertaSeleccionada}></EncabezadoModales>
        {show4 && (<div className="m-2 rounded"><MapTab2 /></div>)}

        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={handleCloseModals}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal >)}


      {/* Modal informe */}
      {(showInforme) && (

        <EnvioReporteAlerta showInforme={showInforme}
          handleCloseInforme={handleCloseInforme}
          Informe={Informe}
          setInforme={setInforme}
          message={message}
          AlertaSeleccionada={AlertaSeleccionada}
          clienteIds={clienteIds}
          Operador={Operador}></EnvioReporteAlerta>
      )}

    </>
  );
}
export { CardContainerAlertas as CardContainerEventos }




