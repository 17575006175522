import React, { useState } from 'react';
import { DeserializarJSon } from '../../../../../../../_start/helpers/Helper';

const TreeNode : React.FC<{node: any}> = ({node }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const hijos : any[]  = Array.isArray(node.children) ? node.children :  DeserializarJSon(node.children);
  return (
    <li>
      {hijos.length > 0 && (
        <span onClick={handleToggle} style={{ cursor: 'pointer', marginRight: 5 }}>
          {expanded ? '▼' : '▶'}
        </span>
      )}
      {node.name}
    
      {hijos.length > 0 && expanded && (
        <ul className='mx-2' style={{ listStyleType: 'none', paddingLeft: 20 }}>
          {hijos?.map((childNode : any) => (
            <TreeNode key={childNode.id} node={childNode} />
          ))}
        </ul>
      )}
    </li>
  );
};

const TreeView: React.FC<{data: any}> = ({ data }) => {
  return (<div className='m-2 border rounded  overflow-auto text-syscaf-amarillo'  style={{ maxWidth:250, maxHeight:250,
     backgroundImage:`linear-gradient(to right, #1B4256 , #007675)`}}>
    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
      {data.map((node : any) => (
        <TreeNode key={node.id} node={node} />
      ))}
    </ul>
    </div>
  );
};

export default TreeView;
