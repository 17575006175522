import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap-v5"
import confirmarDialog, {  successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { Autocomplete, Box, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ColumnFiltersState, SortingState, PaginationState } from "@tanstack/react-table";
import { DeserializarJSon, FechaServidor } from "../../../../../../../_start/helpers/Helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../setup";
import { UserModelSyscaf } from "../../../../../auth/models/UserModel";
import { getConfiguracion, getUsuarios, setConfiguracion } from "../../../data/parametrizacionData";
import { useToaster } from "rsuite";
import { MensajeError } from "../../../../../../../_start/helpers/components/Toaster";
import { AxiosError } from "axios";
import { CargandoSyscaf, CargandoTitulo } from "../../../../../../../_start/helpers/components/Cargando";

type Props = {
    show: boolean;
    handleClose: () => void;
    title?: string;
    tipo: string;
};
export const UpdateUsuarios: React.FC<Props> = ({ show, handleClose, title, tipo }) => {
    const isAuthorized = useSelector<RootState>(
        ({ auth }) => auth.user
    );

    const model = (isAuthorized as UserModelSyscaf);
    const [isLoading, setisLoading] = useState<boolean>(false);
    const [isLoadingUsuarios, setisLoadingUsuarios] = useState<boolean>(false);
    const [userid, setuserid] = useState<any>();
    const [esGestor, setesGestor] = useState<boolean>(false);

    const [Data, setData] = useState<any[]>([]);
    const [DataFiltrada, setDataFiltrada] = useState<any[]>([]);
    const [usuarios, setusuarios] = useState<any[]>([]);
    ;
    const [isEditing, setIsEditing] = useState<boolean>(false);

    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const toaster = useToaster();

    let listadoCampos: MRT_ColumnDef<any>[] =

        [{
            accessorKey: 'UserId',
            header: 'UserId',
            size: 100
        },
        {
            accessorKey: 'Nombres',
            header: 'Usuario',
            size: 100
        },
        {
            accessorKey: 'EsGestor',
            header: 'Es Gestor',
            size: 50,
            Cell({ cell, column, row, table, }) {
                return (cell.getValue() == true) ? <span >Si</span>
                    : <span>No</span>
            },
        }
        ];

    useEffect(() => {
       
         setisLoading(true);
            getConfiguracion('COUSS').then((response) => {
                const data: any[] = DeserializarJSon(response.data[0].Configuracion);
                dataFiltrada(data);
            }).catch(() => {
                toaster.push(MensajeError('Extraer Parametrización', 'Error a consultar datos, recargue nuevamente'), { placement: 'topCenter' })

            }).finally(()=>{setisLoading(false)});

            setisLoadingUsuarios(true);
        getUsuarios('-1').then((response) => {
            setusuarios(response.data)
        }).catch(() => {
            toaster.push(MensajeError('Extraer Usuarios SYSCAF', 'Error a consultar datos, recargue nuevamente'), { placement: 'topCenter' })

        }).finally(()=>{setisLoadingUsuarios(false)});

        return () => {
            setData([])
            setDataFiltrada([])
        }

    }, [])

    const dataFiltrada = (data: any[]) => {
        setData(data);
        setDataFiltrada(data.filter(f => f.tipo === tipo));

    }

    function SelectUsuario() {
        return ( <>
        
        {(isLoadingUsuarios) ? <CargandoTitulo mensaje={"Cargando usuarios..."}/> :  <Autocomplete size="medium" style={{ width: 350 }}
                isOptionEqualToValue={(option, value) => option.userid === value.userid}
                getOptionLabel={(option) => option.nombreCompuesto}
                onChange={(event, newValue) => {
                    if (newValue != null) {
                        if (!ValidarUsuario(newValue.userid))
                            setuserid(newValue);
                        else
                            toaster.push(MensajeError('Asignar Usuario', 'El usuario ya se encuentra en la lista, escoja otro'), { placement: 'topCenter' })
                    }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={usuarios?.map((e: any) => {
                    return { nombreCompuesto: `${e.Nombres}-${e.Email}`, label: e.Nombres, userid: e.Id }
                })}
                value={userid}
                //   value={userid}
                renderInput={(params) => (
                    <TextField {...params} label="Escoja" />
                )}

            />} 
        </>
           

        );
    }

    const ValidarUsuario = (usuarioid: string) => {
        return DataFiltrada.filter(f => f.UserId === usuarioid).length > 0
    }


    const setUsers = (tipoModificacion: number, userEditar?: any) => {

        let movimientos: any = {};
        let mensaje: any = "";
        let tipoMovimiento: any = "";
        let _dataFiltrada = [...Data];
        let _usuario = tipoModificacion == 3 ? userEditar : userid;
        switch (tipoModificacion) {
            case 1:
                mensaje = "Agregar Usuario";
                tipoMovimiento = "Creación";
                _dataFiltrada.push({ UserId: _usuario.userid, Nombres: _usuario.label, EsGestor: esGestor, tipo })

                break;
            case 2:
                let data: any = _dataFiltrada.filter((f) => f.UserId === _usuario.userid && f.tipo === tipo)[0];
                data.EsGestor = esGestor;
                mensaje = "Editar Usuario";
                tipoMovimiento = "Edición";
                break;
            case 3:

            let elemento: any = _dataFiltrada.filter((f) => f.UserId === _usuario.userid && f.tipo === tipo)[0];
                const index = _dataFiltrada.indexOf(elemento);
                console.log(_dataFiltrada , index, _usuario.userid, tipo)
                if (index > -1) { // only splice array when item is found
                    _dataFiltrada.splice(index, 1); // 2nd parameter means remove one item only
                }
                mensaje = "Eliminar Usuario";
                tipoMovimiento = "Eliminacion";
                break;
        }



        movimientos = {
            fecha: FechaServidor(),
            usuario: model.Nombres,
            tipo: tipoMovimiento,
            mensaje: `${mensaje} ${_usuario.label} - ${_usuario.userid}`
        };


        confirmarDialog(() => {
            setConfiguracion('COUSS', JSON.stringify(_dataFiltrada), '[' + JSON.stringify(movimientos) + ']', `${tipoModificacion}`).then((response) => {
                successDialog("Operación Éxitosa", "");
                dataFiltrada(_dataFiltrada);
                setesGestor(false);
                setIsEditing(false);
                setuserid(undefined);
            }).catch((error: AxiosError) => {
                toaster.push(MensajeError('Guardar Usuario', 'Error al guardar usuario ' + error.message), { placement: 'topCenter' })

            });
        }, `Esta seguro que desea ${mensaje} ?`, tipoMovimiento);
    };
    return (
        <>
  
            <Modal
                show={show}
                onHide={handleClose}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card border shadow">
            
                        <Stack>
                            <span className="text-center fs-2 fw-bold " style={{ width: '100%' }}> {userid === undefined ? 'Nuevo' : 'Editar'} Usuarios</span>
                            <div className="row m-3">
                                <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                                    <label className="control-label label label-sm  m-3" htmlFor="señales" style={{ fontWeight: 'bold' }}>Usuario:</label>
                                    {
                                        (!isEditing) ? (
                                            <SelectUsuario />
                                        )
                                            : (
                                                <label className="control-label label label-sm  m-3" style={{ fontWeight: 'bold' }}>{userid.label}</label>
                                            )
                                    }

                                </div>
                                <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                                    <label className="control-label label label-sm  m-3" htmlFor="dlp" style={{ fontWeight: 'bold' }}>Es Gestor:</label>

                                    <input className=" m-3"
                                        type="checkbox"
                                        checked={esGestor}
                                        onChange={(e) =>
                                            setesGestor(e.target.checked)
                                        }
                                    />

                                </div>
                            </div>
                            <div className="d-flex justify-content-end gap-2 mx-2 mb-2">
                                <Button size="sm" type="button" variant="primary" onClick={() => {
                                    setUsers(isEditing ? 2 : 1, undefined);
                                }}>
                                    {!isEditing ? 'Adicionar' : 'Editar'}
                                </Button>
                                <Button hidden={(userid === undefined)} size="sm" type="button" variant="secondary" onClick={() => { setuserid(undefined); setIsEditing(false); }}>
                                    Cancelar
                                </Button>
                            </div>
                        </Stack>

                    </div>

                </Modal.Body>
                <Modal.Body>
                    { isLoading ? (       CargandoSyscaf("Cargando usuarios", "Favor epsere")) : (<MaterialReactTable
                            localization={MRT_Localization_ES}
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 120,
                                },
                            }}
                            columns={listadoCampos}
                            data={DataFiltrada}
                            enableTopToolbar={false}
                            enableColumnOrdering
                            enableEditing
                            enablePagination={false}

                            onColumnFiltersChange={setColumnFilters}
                            onGlobalFilterChange={setGlobalFilter}
                            onPaginationChange={setPagination}
                            onSortingChange={setSorting}

                            state={{
                                columnFilters,
                                globalFilter,
                                pagination,
                                sorting,
                            }}

                            renderRowActions={({ row, table }) => (
                                <>
                                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                                        <Tooltip arrow placement="left" title="modificar">
                                            <IconButton
                                                onClick={() => {
                                                    setuserid({ label: row.original.Nombres, userid: row.original.UserId });
                                                    setIsEditing(true)
                                                }}
                                            >
                                                <Edit color="primary" />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip arrow placement="left" title="eliminar">
                                            <IconButton
                                                onClick={() => {
                                                    setUsers(3, { label: row.original.Nombres, userid: row.original.UserId });
                                                }}
                                            >
                                                <Delete color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </>
                            )
                            }
                        />)}
                        
                    
                </Modal.Body>
                <Modal.Footer>

                    <Button type="button" variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );

}
