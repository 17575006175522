import BlockUi from "@availity/block-ui";
import { PageTitle } from "../../../../../_start/layout/core";
import { useEffect, useState } from "react";
import { DatePicker, Radio, RadioGroup, toaster } from "rsuite";
import moment from "moment";
import { isBefore, isAfter } from "rsuite/esm/utils/dateUtils";
import { Form, FormControl } from "react-bootstrap-v5";
import { FormControlLabel } from "@mui/material";
import { Insights_TreeConductores } from "./Insights_TreeConductores";
// import { getClientes } from "../../../APPS/Mobile/data/dataConfiguracion";
import { useDataExlusion } from "../core/ProviderExclusion";
import { AxiosError, AxiosResponse } from "axios";
import { getClientes } from "../../../MODULOS/Fatigue/data/Configuracion";
import { Insights_TreeAssets } from "./Insights_TreeAssets";
import { FechaServidor, FechaServidorString, format_YYYYMMDDHHmm, formatableJson_HHMMss, GetFechaServidor } from "../../../../../_start/helpers/Helper";
import { GetConsultas, Insights_Eventos, Insights_getEventos, Insigth_GetObservaciones, PostConsultas } from "../data/Insights_dataConfigAssets";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../setup";
import { UserModelSyscaf } from "../../../auth/models/UserModel";
import confirmarDialog from "../../../../../_start/helpers/components/ConfirmDialog";
import { MensajeError, MensajeSuccess, message } from "../../../../../_start/helpers/components/Toaster";
export default function ExclusionCentral() {
    //ESPACIO PARA LAS CONST
    const {clienteSeleccionado, setclienteSeleccionado, setClientes, Clientes, setEventos,Eventos, Filtros , setFiltros} = useDataExlusion();
    const [loader, setloader] = useState<boolean>(false);
    const [fechaSeleccionada, setFechaSeleccionada] = useState(moment().startOf('day'));
    const [selectedDate, setSelectedDate] = useState<any>(new Date());
      //table state
      const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
      const [globalFilter, setGlobalFilter] = useState('');
      const [sorting, setSorting] = useState<SortingState>([]);
      const [pagination, setPagination] = useState<PaginationState>({
          pageIndex: 0,
          pageSize: 50,
      });
      const [rowCount, setRowCount] = useState(0);
      const [Clave, setClave] = useState<string>("1");
      const [EstadoInicial, setEstadoInicial] = useState<string>("Programacion");
      const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;
    
    useEffect(() => {
            ConsultarClientesDatos();
            Consultar();
    }, [])

    const ConsultarClientesDatos = () => {
        getClientes("").then((response: AxiosResponse<any>) => {
                setClientes(response.data);
                setclienteSeleccionado(response.data[0]);
        }).catch(() => {
        });
    }
    const ConsultarEventos = () =>{
       

        Insights_getEventos(clienteSeleccionado.ClienteIdString).then((response:AxiosResponse<any>) =>{
            setEventos(response.data);
            setRowCount(response.data.length);
        }).catch((error:AxiosError<any>) =>{
            console.log("Error al consultar los eventos del cliente",error);
        });
    }
  const Consultar = () =>{
    GetConsultas(vUser.perfil).then((response:AxiosResponse<any>) =>{
        console.log(response.data);
    }).catch(() =>{
        console.log("Error al realizar las consultas");
    });
  }
const CargarClientes = () =>{
    return (
        <Form.Select defaultValue={clienteSeleccionado?.ClienteIdS} className=" m-2 " onChange={(e) => {
            // buscamos el objeto completo para tenerlo en el sistema
            let lstClientes = Clientes?.filter((value: any, index: any) => {
                return String(value.ClienteIdS) === e.currentTarget.value
            })
            let Cliente = (lstClientes[0] == undefined ? {
                clienteIdS: 0,
                ClienteId: "",
                clienteNombre: "Todos",
            } : lstClientes[0]);
            setclienteSeleccionado(Cliente);

            if(Filtros.Evento == true)
                ConsultarEventos();

            if(Cliente.clienteIdS != 0){
                let f = {...Filtros};
                f.ClienteId = Cliente.ClienteId;
                f.NombreCliente = Cliente.clienteNombre;
                setFiltros(f);
            }
            

        }} aria-label="Default select example">
            <option value={0}>Todos</option>
            {
                Clientes?.filter((val:any) =>{
                    return val.estadoClienteId == 1
                }).map((element: any, i: any) => {
                    let flag = (element.ClienteIdS === (clienteSeleccionado != undefined ? clienteSeleccionado?.ClienteIdS : 0))
                    return (<option key={element.ClienteIdS} value={(element.ClienteIdS != null ? element.ClienteIdS : 0)}>{element.clienteNombre}</option>)
                })
            }
        </Form.Select>
    );
}
   
    const handleDateChange = (date: any) => {
        let Actual:any = GetFechaServidor().format(formatableJson_HHMMss);
        let f = {...Filtros};
        f.FechaInicial =  (date == "false" ? "" : date);
        f.FechaFinal = (date == "false" ? "" : Actual); ;
        f.IntervaloFechaPersonalizado = false;
        f.FechaCustom = (date == "false" ? false : true);
        setFiltros(f);
    };

    const changeConductores = (e:any) =>{
        let f = {...Filtros};
        f.Isdrivers = true;
        f.Isassets = false;
        setFiltros(f);
    }
    const changeActivos = (e:any) =>{
        let f = {...Filtros};
        f.Isdrivers = false;
        f.Isassets = true;
        setFiltros(f);
    }

    const changeViaje = (e:any) =>{
        let f = {...Filtros};
        f.Viaje = true;
        f.Evento = false;
        setFiltros(f);
    }
    const changeEvento = (e:any) =>{
        let f = {...Filtros};
        f.Viaje = false;
        f.Evento = true;
        ConsultarEventos();
        setFiltros(f);
    }
    const CambioEstadoFechaInicial = (Date:any) => {
        let f = {...Filtros};
        f.FechaInicial =  moment(Date).format(formatableJson_HHMMss);
        f.IntervaloFechaPersonalizado = true;
        f.FechaCustom = false;
        setFiltros(f);
    }
    const CambioEstadoFechaFinal = (Date:any) => {
        let f = {...Filtros};
        f.FechaFinal = moment(Date).format(formatableJson_HHMMss);;
        f.IntervaloFechaPersonalizado = true;
        f.FechaCustom = false;
        setFiltros(f);
    }
    const Guardar = () => {
        let _obs:any[] = [];
        if (Filtros. Comentarios != "")
            Insigth_GetObservaciones(_obs, Filtros. Comentarios, EstadoInicial, vUser);
        else
            toaster.push(message('error', "Nueva Observación", "El campo no puede estar vacío"), {
                placement: 'topCenter'
            });

        confirmarDialog(() => {
            setloader(true);
            PostConsultas(Clave,vUser.Id,JSON.stringify(Filtros),EstadoInicial,JSON.stringify(_obs),clienteSeleccionado.ClienteId,null).
                then((response: AxiosResponse<any>) => {
                    if (response.status === 200) {
                        toaster.push(MensajeSuccess('Guardar', 'Guardado exitosamente'), { placement: 'topCenter' });
                     
                    }

                }).catch((error: AxiosError<any>) => {
                    toaster.push(MensajeError('Guardar Vehiculos', error.message), { placement: 'topCenter' })
                }).finally(() => { setloader(false); })
        }, `¿Esta seguro que desea guardar los cambios?`, 'Guardar');
    }

 
    return (
        <>
            <PageTitle>Interfaz de configuracion de exclusión de datos</PageTitle>
            <BlockUi tag="div" keepInView blocking={loader ?? false}  >
                <div className="container">
                    <div className="row">
                            <div className="col-4">
                                    <label className="control-label label label-sm fw-bolder">Cliente:</label>
                                    {(Clientes.length>0 ?<CargarClientes /> : <div><div className="spinner-border text-primary" role="status">
                                                            </div><span className="visually">Cargando clientes...</span></div>)} 
                            </div>
                            <div className="col-6 d-flex flex-row  justify-content-between">
                                <h6 className="fs-6 text-muted mt-8">Filtro en</h6>
                                <div className="form-check mt-8">
                                    <input className="form-check-input" type="radio" name="flexRadioConsulta" id="flexRadioActivos" value={Filtros.Isassets}  checked={Filtros.Isassets} onChange={changeActivos} />
                                    <label className="form-check-label" htmlFor="flexRadioActivos">
                                        Activos
                                    </label>
                                </div>
                                <div className="form-check mt-8">
                                    <input className="form-check-input" type="radio" name="flexRadioConsulta" id="flexRadioConductores" value={Filtros.Isdrivers} checked={Filtros.Isdrivers} onChange={changeConductores} />
                                    <label className="form-check-label" htmlFor="flexRadioConductores">
                                        Conductores
                                    </label>
                                </div>
                            </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 mt-5 ">
                            {(clienteSeleccionado.ClienteId != undefined  ) && (Filtros.Isassets) && (<Insights_TreeAssets/>)} 
                            {(clienteSeleccionado.ClienteId != undefined  ) && (Filtros.Isdrivers) && (<Insights_TreeConductores/>)} 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <h6 className="fs-6 text-muted">Intervalo de fecha especifico</h6>
                            <div className="mt-5">
                                <select className="form-control input-sm" onChange={(e) => handleDateChange(e.target.value)}>
                                <option value={"false"}>Seleccione</option>
                                    <option value={moment(FechaServidor()).subtract(1, 'days').format(formatableJson_HHMMss)}>Ayer</option>
                                    <option value={moment().subtract(6, 'days').format(formatableJson_HHMMss)}>Ultimos 7 dias</option>
                                    <option value={moment().subtract(1, 'months').format(formatableJson_HHMMss)}>Mes anterior</option>
                                    <option value="2024-03-17">Mes actual</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <h6 className="fs-6 text-muted">Intervalo de fecha personalizado</h6>
                            <div className="row">
                                <div className="col-6">
                                    <label className="label label-sm control-label">Inicial</label>
                                    <DatePicker id="Inicial" format="dd/MM/yyyy" className="m-2 mt-3" value={(Filtros.FechaInicial=="" ? selectedDate: moment(Filtros.FechaInicial,formatableJson_HHMMss).toDate())}
                                        onSelect={(e) => { CambioEstadoFechaInicial(moment(e)) }} />
                                </div>
                                <div className="col-6">
                                    <label className="label label-sm control-label">Final</label>
                                    <DatePicker id="Final" format="dd/MM/yyyy" className="m-2 mt-3" value={(Filtros.FechaFinal=="" ? selectedDate: moment(Filtros.FechaFinal,formatableJson_HHMMss).toDate())}
                                        onSelect={(e) => { CambioEstadoFechaFinal(moment(e)) }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <h6 className="fs-6">Tipo de consulta</h6>
                        </div>
                        <div className="col-6 mt-5 d-flex flex-row  justify-content-between">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" onChange={changeViaje} value={Filtros.Viaje} checked={Filtros.Viaje} name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Viajes
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" onChange={changeEvento} value={Filtros.Evento} checked ={Filtros.Evento} name="flexRadioDefault" id="flexRadioDefault2" />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    Eventos
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <h6 className="fs-6">Comentarios</h6>
                        </div>
                        <div className="col-12">
                            <textarea rows={1} value={Filtros.Comentarios} onChange={(e:any) =>{
                                let f = {...Filtros}
                                f.Comentarios = e.target.value;
                                setFiltros(f);
                            }} className="form-control"></textarea>
                        </div>
                        <div className="col-12"  hidden={Filtros.Viaje}>
                            <h6 className="fs-6">Filtro</h6>
                        </div>
                        <div hidden={Filtros.Viaje} className="col-12">
                                        <MaterialReactTable
                                        localization={MRT_Localization_ES}
                                        displayColumnDefOptions={{
                                            'mrt-row-actions': {
                                                muiTableHeadCellProps: {
                                                    align: 'center',
                                                },
                                                size: 120,
                                            },
                                        }}
                                        muiTableHeadCellProps={{
                                            sx: () => ({
                                                fontSize: 11,
                                                fontStyle: 'bold',
                                                color: 'rgb(27, 66, 94)'
                                            }),
                                        }}
                                        columns={Insights_Eventos(Filtros,setFiltros)}
                                        data={Eventos}
                                        initialState={{ density: 'compact' }}
                                        enableColumnOrdering
                                        enableColumnResizing
                                        enableColumnDragging={false}
                                        enablePagination={true}
                                        enableStickyHeader
                                        enableStickyFooter
                                        enableDensityToggle={false}
                                        onColumnFiltersChange={setColumnFilters}
                                        onGlobalFilterChange={setGlobalFilter}
                                        onPaginationChange={setPagination}
                                        onSortingChange={setSorting}
                                        rowCount={rowCount}
                                        enableTableFooter
                                        muiTableContainerProps={{ sx: { maxHeight: '200px' } }}
                                        />
                        </div>
                        <div className="col-12 mt-5">
                            <button className="btn btn-sm btn-secondary float-end"><i className="bi-trash"></i> Cancelar</button>
                            <button className="btn btn-sm btn-primary float-end me-2" onClick={Guardar}><i className="bi-save"></i> Guardar</button>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </>
    )
}