import { MRT_ColumnDef } from "material-react-table";
import { Post_GetConsultasDinamicasDWH, Post_GetConsultasDinamicasUserDWH, Post_getDynamicValueProcedureDWHTabla } from "../../../../../_start/helpers/Axios/DWHService";
import { DeserializarJSon, msToTimeSeconds } from "../../../../../_start/helpers/Helper";
import moment from "moment";
import { FormatoColombiaDDMMYYY, FormatoColombiaDDMMYYYHHmmss } from "../../../../../_start/helpers/Constants";
import { Post_getConsultasDinamicasConAuthCore } from "../../../../../_start/helpers/Axios/CoreService";
import { Autocomplete, TextField } from "@mui/material";

export const CHL_SelectDriver = (conductores: any[], rowSel: any, setRowSel: any) => {

  return conductores != undefined ? <Autocomplete size="medium" style={{ width: 350 }}
    onChange={(event, newValue) => {

      if (newValue != null) {
        setRowSel({ ...rowSel, DriverId: newValue.id, Operador: newValue.label });
      }


    }}

    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    options={conductores?.map((e: any) => {
      return { label: e.name, id: e.DriverIdString }
    })}
    value={rowSel?.Operador}
    renderInput={(params) => (
      <TextField {...params} label="Escoja" />
    )}

  /> : <></>

}

export const CHL_SelectVehiculo = (lstAssets: any[], rowSel: any, setRowSel: any) => {

  return lstAssets != undefined ? <Autocomplete size="medium" style={{ width: 200 }}
    onChange={(event, newValue) => {
      if (newValue != null) {
        setRowSel({ ...rowSel, AssetId: newValue.id, Placa: newValue.label });
      }


    }}
    value={rowSel?.Placa}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    options={lstAssets?.map((e: any) => {
      return { label: e.Description, id: e.AssetIdString }
    })}
    renderInput={(params) => (
      <TextField {...params} label="Escoja" />
    )}

  /> : <></>

}

// listado de funciones personalizado para los reportes para exportar en excel
export const fncReporteCHL = [
  {
    name: "Fecha",
    getData: (data: any) => {
      return moment(data).isValid() ? moment(data).format(FormatoColombiaDDMMYYY) : "N/A"
    }
  },
  {
    name: "Inicio",
    getData: (data: any) => {
      return moment(data).format(FormatoColombiaDDMMYYYHHmmss)
    }
  },
  {
    name: "Fin",
    getData: (data: any) => {
      return moment(data).format(FormatoColombiaDDMMYYYHHmmss)
    }
  },
  {
    name: "Duracion",
    getData: (data: number) => {
      return msToTimeSeconds(data)
    }
  },
  {
    name: "DuracionSemana",
    getData: (data: number) => {
      return msToTimeSeconds(data)
    }
  },
  {
    name: "HET",
    getData: (data: any) => {
      const TotalHoras = DeserializarJSon(data.JornadaExtra)?.reduce(
        (p: number, c: any) => p + c.tiempo, 0
      );

      return msToTimeSeconds(TotalHoras ?? 0);
    }
  }, {
    name: 'HDO',
    getData: (row: any) => {
      const TotalHoras = DeserializarJSon(row.JornadaOrdinaria)?.filter(
        (f: any) => f.tipo === 'Diurna' && f.jornada === 'Ordinario'
      ).reduce(
        (p: number, c: any) => p + c.tiempo, 0
      );

      return msToTimeSeconds(TotalHoras ?? 0);

    }
  }
  , {
    name: 'HNO',
    getData: (row: any) => {
      const TotalHoras = DeserializarJSon(row.JornadaOrdinaria)?.filter(
        (f: any) => f.tipo === 'Nocturna' && f.jornada === 'Ordinario'
      ).reduce(
        (p: number, c: any) => p + c.tiempo, 0
      );

      return msToTimeSeconds(TotalHoras ?? 0);

    }
  }, {
    name: 'HDF',
    getData: (row: any) => {
      const TotalHoras = DeserializarJSon(row.JornadaOrdinaria)?.filter(
        (f: any) => f.tipo === 'Diurna' && f.jornada === 'Dominical Festivo'
      ).reduce(
        (p: number, c: any) => p + c.tiempo, 0
      );

      return msToTimeSeconds(TotalHoras ?? 0);

    }
  }, {
    name: 'HNF',
    getData: (row: any) => {
      const TotalHoras = DeserializarJSon(row.JornadaOrdinaria)?.filter(
        (f: any) => f.tipo === 'Nocturna' && f.jornada === 'Dominical Festivo'
      ).reduce(
        (p: number, c: any) => p + c.tiempo, 0
      );

      return msToTimeSeconds(TotalHoras ?? 0);

    }
  }, {
    name: 'HDE',

    getData: (row: any) => {
      const TotalHoras = DeserializarJSon(row.JornadaExtra)?.filter(
        (f: any) => f.tipo === 'Diurna' && f.jornada === 'Ordinario'
      ).reduce(
        (p: number, c: any) => p + c.tiempo, 0
      );

      return msToTimeSeconds(TotalHoras ?? 0);

    }
  }, {
    name: 'HNE',
    getData: (row: any) => {
      const TotalHoras = DeserializarJSon(row.JornadaExtra)?.filter(
        (f: any) => f.tipo === 'Nocturna' && f.jornada === 'Ordinario'
      ).reduce(
        (p: number, c: any) => p + c.tiempo, 0
      );

      return msToTimeSeconds(TotalHoras ?? 0);

    }
  }, {
    name: 'HDEF',
    getData: (row: any) => {
      const TotalHoras = DeserializarJSon(row.JornadaExtra)?.filter(
        (f: any) => f.tipo === 'Diurna' && f.jornada === 'Dominical Festivo'
      ).reduce(
        (p: number, c: any) => p + c.tiempo, 0
      );

      return msToTimeSeconds(TotalHoras ?? 0);

    }
  }
  , {
    name: 'HNEF',
    getData: (row: any) => {
      const TotalHoras = DeserializarJSon(row.JornadaExtra)?.filter(
        (f: any) => f.tipo === 'Nocturna' && f.jornada === 'Dominical Festivo'
      ).reduce(
        (p: number, c: any) => p + c.tiempo, 0
      );

      return msToTimeSeconds(TotalHoras ?? 0);

    }
  }


]
// listado de campos a extraer 
export let camposTablaCHL: MRT_ColumnDef<any>[] =

  [
    {
      accessorKey: 'WeekYear',
      header: 'Semana'
    },
    {
      accessorKey: 'Fecha',
      header: "Fecha",

      Cell({ row }) {

        return moment(row.original.Fecha).isValid() ? moment(row.original.Fecha).format(FormatoColombiaDDMMYYY) :
          row.original.Fecha
          ;
      },
    }
    , {
      accessorKey: 'Dia',
      header: "Dia"
    }, {
      accessorKey: 'Operador',
      header: "Operador",
      size: 300
    }
    , {
      accessorKey: 'Inicio',
      header: 'Inicio',

      Cell({ cell, column, row, table, }) {
        return moment(row.original.Inicio).format(FormatoColombiaDDMMYYYHHmmss);
      },
      size: 150
    }, {
      accessorKey: 'Fin',
      header: 'Fin',

      Cell({ cell, column, row, table, }) {
        return moment(row.original.Fin).format(FormatoColombiaDDMMYYYHHmmss);
      },
      size: 150
    }, {
      accessorKey: 'Duracion',
      header: 'Duracion',
      Cell({ cell, column, row, table, }) {
        return msToTimeSeconds(row.original.Duracion);
      }
    }, {
      accessorKey: 'DuracionSemana',
      header: 'DuracionSemana',
      Cell({ cell, column, row, table, }) {
        return row.original.DuracionSemana == undefined ? "N/A" : msToTimeSeconds(row.original.DuracionSemana);
      }
    }, {
      accessorKey: 'HET',
      header: 'Extras Totales',
      Cell({ cell, column, row, table, }) {
        const TotalHoras = DeserializarJSon(row.original.JornadaExtra)?.reduce(
          (p: number, c: any) => p + c.tiempo, 0
        );

        return msToTimeSeconds(TotalHoras ?? 0);

      }
    }, {
      accessorKey: 'HDO',
      header: 'Diurnas Ord',
      Cell({ cell, column, row, table, }) {
        const TotalHoras = DeserializarJSon(row.original.JornadaOrdinaria)?.filter(
          (f: any) => f.tipo === 'Diurna' && f.jornada === 'Ordinario'
        ).reduce(
          (p: number, c: any) => p + c.tiempo, 0
        );

        return msToTimeSeconds(TotalHoras ?? 0);

      }
    }
    , {
      accessorKey: 'HNO',
      header: 'Nocturnas Ord',
      Cell({ cell, column, row, table, }) {
        const TotalHoras = DeserializarJSon(row.original.JornadaOrdinaria)?.filter(
          (f: any) => f.tipo === 'Nocturna' && f.jornada === 'Ordinario'
        ).reduce(
          (p: number, c: any) => p + c.tiempo, 0
        );

        return msToTimeSeconds(TotalHoras ?? 0);

      }
    }, {
      accessorKey: 'HDF',
      header: 'Diurnas Festivos',
      Cell({ cell, column, row, table, }) {
        const TotalHoras = DeserializarJSon(row.original.JornadaOrdinaria)?.filter(
          (f: any) => f.tipo === 'Diurna' && f.jornada === 'Dominical Festivo'
        ).reduce(
          (p: number, c: any) => p + c.tiempo, 0
        );

        return msToTimeSeconds(TotalHoras ?? 0);

      }
    }, {
      accessorKey: 'HNF',
      header: 'Nocturna Festivos',
      Cell({ cell, column, row, table, }) {
        const TotalHoras = DeserializarJSon(row.original.JornadaOrdinaria)?.filter(
          (f: any) => f.tipo === 'Nocturna' && f.jornada === 'Dominical Festivo'
        ).reduce(
          (p: number, c: any) => p + c.tiempo, 0
        );

        return msToTimeSeconds(TotalHoras ?? 0);

      }
    }, {
      accessorKey: 'HDE',
      header: 'Diurnas Extra',
      Cell({ cell, column, row, table, }) {
        const TotalHoras = DeserializarJSon(row.original.JornadaExtra)?.filter(
          (f: any) => f.tipo === 'Diurna' && f.jornada === 'Ordinario'
        ).reduce(
          (p: number, c: any) => p + c.tiempo, 0
        );

        return msToTimeSeconds(TotalHoras ?? 0);

      }
    }, {
      accessorKey: 'HNE',
      header: 'Nocturna Extra',
      Cell({ cell, column, row, table, }) {
        const TotalHoras = DeserializarJSon(row.original.JornadaExtra)?.filter(
          (f: any) => f.tipo === 'Nocturna' && f.jornada === 'Ordinario'
        ).reduce(
          (p: number, c: any) => p + c.tiempo, 0
        );

        return msToTimeSeconds(TotalHoras ?? 0);

      }
    }, {
      accessorKey: 'HDEF',
      header: 'Diurna Extra F',
      Cell({ cell, column, row, table, }) {
        const TotalHoras = DeserializarJSon(row.original.JornadaExtra)?.filter(
          (f: any) => f.tipo === 'Diurna' && f.jornada === 'Dominical Festivo'
        ).reduce(
          (p: number, c: any) => p + c.tiempo, 0
        );

        return msToTimeSeconds(TotalHoras ?? 0);

      }
    }
    , {
      accessorKey: 'HNEF',
      header: 'Nocturna Extra F',
      Cell({ cell, column, row, table, }) {
        const TotalHoras = DeserializarJSon(row.original.JornadaExtra)?.filter(
          (f: any) => f.tipo === 'Nocturna' && f.jornada === 'Dominical Festivo'
        ).reduce(
          (p: number, c: any) => p + c.tiempo, 0
        );

        return msToTimeSeconds(TotalHoras ?? 0);

      }
    }


  ];



export function GetReporteCHL(ClienteIds: string, FechaIni: string, FechaFinal: string) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['ClienteIds'] = ClienteIds;
  params['FechaIni'] = FechaIni;
  params['FechaFinal'] = FechaFinal;
  return Post_GetConsultasDinamicasDWH({
    NombreConsulta: "GetReporteCHL", Clase: "CHLQueryHelper",
    Pagina: null,
    RecordsPorPagina: null
  }, params)
};


export function GetDetalleViajes(ClienteIds: string, Fecha: string, FechaFin: string, DriverId: string) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['Fecha'] = Fecha;
  params['FechaFin'] = FechaFin;
  params['DriverId'] = DriverId;
  return Post_getDynamicValueProcedureDWHTabla({
    NombreConsulta: "DetalleViajes", Clase: "CHLQueryHelper", tabla: ClienteIds
  }, params)
};

export const DataTransformation =
{
  // obtiene los nombres para colocar un filtro de conductores
  getDriverFromData: (data: any[]) => {
    return data.reduce((p: any, c: any) => {
      if (p.filter((f: any) => f.value === c.Operador).length === 0)
        p.push({ "value": c.Operador, "label": c.Operador });
      return p;

    }, [])
  },
  getDatosFiltradoPorBandera: (data: any[], campo: string, flag: any) => {
    // retorna la informacion filtrada por el campo y el valor que se requiera
    return data.filter((f: any) => f[campo] >= flag)
  },
  getDatosFiltrado: (data: any[], drivers: any[]) => {
    // retrona la informacion filtrada por conductores
    return data.filter((f: any) => (drivers.length === 0 || drivers.includes(f.Operador)))
  },
  getDatosAgrupadoPorConductor: (data: any[]) => {
    // usamos un reduce para consolidar los datos donde el conductor es el protagonista
    // la información seguirá mostránmdose en segundos

    return data.reduce((p, c) => {
      const Operador = c.Operador; // bandera de agrupacion
      const WeekYear = c.WeekYear;

      let isExist = p.find((f: any) => f.Operador == Operador && f.WeekYear === WeekYear); // verificamos que exista el componente para sumar o para crear

      if (isExist == undefined) // si no existe insertamos el nuevo
        p.push({
          Dia: "N/A",
          Fecha: "N/A",
          driverId: c.driverId,
          Operador, WeekYear, Duracion: c.Duracion, DuracionSemana: c.Duracion,
          Inicio: c.Fecha,
          Fin: c.Fecha,
          JornadaExtra: c.JornadaExtra != null ? DeserializarJSon(c.JornadaExtra) : [],
          JornadaOrdinaria: c.JornadaOrdinaria != null ? DeserializarJSon(c.JornadaOrdinaria) : []
        })
      else { // de lo contrario procedemos a sumar informacion correspondiente a duracion y a las jornadas

        if (isExist.Fin < c.Fecha) isExist.Fin = c.Fecha;
        if (isExist.Inicio > c.Fecha) isExist.Inicio = c.Fecha; // si la fecha de inicio debe ser menor o igual a la fecha el nuevo elemento
        isExist.Duracion = isExist.Duracion + c.Duracion;
        isExist.DuracionSemana = isExist.Duracion;
        if (c.JornadaExtra != null) isExist.JornadaExtra = [...isExist.JornadaExtra, ...DeserializarJSon(c.JornadaExtra)];
        if (c.JornadaOrdinaria != null) isExist.JornadaOrdinaria = [...isExist.JornadaOrdinaria, ...DeserializarJSon(c.JornadaOrdinaria)];
      }

      return p; // para aseguranos que la info se retorne 
    }, []);


  }

}


export const FuncionesConfiguracion = {
  // segun la fecha escogida, formatea el texto para mostrar los labes al cliente final
  // jornada diurna y nocturna
  getJornadaTexto: (inicio: any, fin: any) => {
    const jornadaDiurna: string = `${inicio.format("HH:mm")} - ${fin.format("HH:mm")}`;
    const jornadaNocturna: string = `${fin.format("HH:mm")} - ${inicio.format("HH:mm")}`;

    return { dia: jornadaDiurna, noche: jornadaNocturna };

  },
  // genera un json con los datos de la jornada laboral
  getJornadaObjeto: (inicio: any, fin: any) => {
    const inicioSegundos: number = inicio.diff(moment().startOf('day'), 'seconds');
    const finSegundos: number = fin.diff(moment().startOf('day'), 'seconds');
    const Horasdia: number = 24 * 3600;

    const result: any = [
      { "tipo": 1, "nombre": "Nocturna", "horainicial": 0, "horafinal": inicioSegundos },
      { "tipo": 2, "nombre": "Diurna", "horainicial": inicioSegundos, "horafinal": finSegundos },
      { "tipo": 3, "nombre": "Nocturna", "horainicial": finSegundos, "horafinal": Horasdia },
      { "tipo": 4, "nombre": "Nocturna", "horainicial": 0, "horafinal": inicioSegundos }
    ]

    return result;
  }
}

export function CHL_GetParametroCliente(ClienteIds: string) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['clienteIdS'] = ClienteIds;
  params['Estado'] = "-1";
  params['clienteId'] = null;
  return Post_getConsultasDinamicasConAuthCore({
    NombreConsulta: "GetListadoCliente", Clase: "PORTALQueryHelper",
    Pagina: null,
    RecordsPorPagina: null
  }, params)
};

export function PORTAL_GuardarPropiedadCliente(ClienteIds: string, ValorPropiedad: string) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['ClienteIds'] = ClienteIds;
  params['Propiedad'] = "$.CHL_config";
  params['ValorPropiedad'] = ValorPropiedad;
  return Post_getConsultasDinamicasConAuthCore({
    NombreConsulta: "ActualizarPropiedad_Objeto", Clase: "PORTALQueryHelper",
    Pagina: null,
    RecordsPorPagina: null
  }, params)
};

export function PORTAL_GuardarPropiedadClienteDWH(ClienteIds: string, ValorPropiedad: string) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['ClienteIds'] = ClienteIds;
  params['Propiedad'] = "$.CHL_config";
  params['ValorPropiedad'] = ValorPropiedad;
  return Post_GetConsultasDinamicasUserDWH({
    NombreConsulta: "ActualizarPropiedad_Objeto", Clase: "PORTALQueryHelper",
    Pagina: null,
    RecordsPorPagina: null
  }, params)
};



