export interface EventoActivo {
    EventId : number;
    AssetId : number;
    registrationnumber: string;
    description : string;
    DriverId: number;
    name: string;
    descriptionevent : string;
    EventDateTime: Date ;
    Latitud : number;
    Longitud: number;
    evento:string;
}

export interface ClientesFatiga 
{
    ClienteIdS : number; 
    ClienteId : number; 
    clienteNombre : string;
}

export interface NuevoConfiguracionDTO
{
    clave: string ;
    nombre: string;  
    tiempo: string;
    condicion: string;
    clienteId: string;
    configuracionAlertaId: string;
    esActivo: string;
    Detalles: Detalles;
    maxAmber : string;
    minAmber : string;
}
export interface Detalles {
    TiempoCierre:string;
    TipoAlerta:string|null;
    Gestionar:any[];
    Sonido:any[] ;
    VerDashboard:boolean;
 };
 
 export const defaultNuevoDetallesDTO: Detalles = {
     TiempoCierre: "300",
     TipoAlerta: null,
     Gestionar: ["Riesgo alto", "Riesgo moderado", "Riesgo bajo"],
     VerDashboard: true,
     Sonido: ["Riesgo alto", "Riesgo moderado", "Riesgo bajo"]
 };
 
export const defaultNuevoConfiguracionDTO: NuevoConfiguracionDTO = {
    clave: "1",
    nombre: "",  
    tiempo: "30",
    condicion: "",
    clienteId: "",
    configuracionAlertaId: "0",
    esActivo: "true",
    Detalles: defaultNuevoDetallesDTO,
    maxAmber : "4",
    minAmber : "2"
};


