import moment from "moment";
import { createContext, useContext, useEffect,  useState } from "react";
import { FormatoSerializacionYYYY_MM_DD_HHmmss } from "../../../../../_start/helpers/Constants";
import { getIndicadoresHistorical } from "../data/dashBoardData";
import { formatSimpleJson, formatableJson_HHMMss } from "../../../../../_start/helpers/Helper";
// clase con los funciones  y datos a utiilizar
export interface FatigueIndicadoresContextModel {
    setDataIndicadores: (data: any) => void;
    dataIndicadores?: any;
    loader?: boolean;
    setloader: (loader: boolean) => void;
}

const FatigueIndicadoresContext = createContext<FatigueIndicadoresContextModel>({
    setDataIndicadores: (data: any) => { },
    setloader: (loader: boolean) => { }
});

const FatigueIndicadoresProvider: React.FC = ({ children }) => {
    //mapa
    const [dataIndicadores, setDataIndicadores] = useState<any[]>([]);
    const [loader, setloader] = useState<boolean>(false);
    const value: FatigueIndicadoresContextModel = {
        dataIndicadores, setDataIndicadores,
        loader, setloader
    };
    return (
        <FatigueIndicadoresContext.Provider value={value}>
            {children}
        </FatigueIndicadoresContext.Provider>
    );
};

function useDataIndicadoresHistorical() {
    return useContext(FatigueIndicadoresContext);
}

// se encarga de consultar la información 
// de los vehiculos operando y en una frecuencia de 5 min 
// segun parametrización que debe realizarse

const GetIndicadores: React.FC = ({ children }) => {
    const { setDataIndicadores, setloader } = useDataIndicadoresHistorical();

    let consultaAlertas = (fechaInicial: any, fechaFinal: any, ClienteId: any) => {
        setloader(true);
        // consultamos en la base de datos la informacion de vehiculos operando
        getIndicadoresHistorical(moment(fechaInicial).format(formatableJson_HHMMss)
            , moment(fechaFinal).endOf('day').format(formatableJson_HHMMss)
            , ClienteId.toString()).then((response) => {
                setDataIndicadores(response.data);
               
                setloader(false);
            }).catch((error) => {
                setloader(false);
            })
    }

    useEffect(() => {
        if (children && Object.keys(children).length != 0) {
            const variables: any = { ...children as any }
            consultaAlertas(variables.fechaInicial, variables.fechaFinal, variables.ClienteId);
        
        }
        return () => {
            setDataIndicadores([]);

        };
    }, [children]);
    return <></>;
};


export { FatigueIndicadoresProvider, useDataIndicadoresHistorical, GetIndicadores }