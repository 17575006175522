import { MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import { FormatoColombiaDDMMYYYHHmmss } from "../../../../../_start/helpers/Constants";
import { msToTimeSeconds } from "../../../../../_start/helpers/Helper";
import { Post_GetConsultasDinamicasDWH } from "../../../../../_start/helpers/Axios/DWHService";
export let camposTablaHistorialCHL: MRT_ColumnDef<any>[] =
  [
    {
      accessorKey: 'Tipo',
      header: 'Tipo'
    },
    {
      accessorKey: 'TripId',
      header: "Id Viaje"
    }
    , {
      accessorKey: 'Placa',
      header: "Placa"
    }, {
      accessorKey: 'Operador',
      header: "Operador"

    }
    , {
      accessorKey: 'tripStart',
      header: 'Inicio Viaje',

      Cell({ cell, column, row, table, }) {
        return moment(row.original.tripStart).format(FormatoColombiaDDMMYYYHHmmss);
      }
    }, {
      accessorKey: 'tripEnd',
      header: 'Fin Viaje',

      Cell({ cell, column, row, table, }) {
        return moment(row.original.tripEnd).format(FormatoColombiaDDMMYYYHHmmss);
      }
    }, {
      accessorKey: 'Duracion',
      header: 'Duracion',
      Cell({ cell, column, row, table, }) {
        return msToTimeSeconds(row.original.Duracion);
      }
    }, {
      accessorKey: 'FechaSistema',
      header: 'Fecha Sistema',

      Cell({ cell, column, row, table, }) {
        return moment(row.original.FechaSistema).format(FormatoColombiaDDMMYYYHHmmss);
      }
    }, {
      accessorKey: 'Usuario',
      header: "Usuario"
    }
  ];


export function HistorialCambios(ClienteIds: string, Tipo: string | null,  usuario: string | null,
  FechaInicial : string, FechaFinal : string
) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['Tipo'] = Tipo;
  params['ClienteIds'] = ClienteIds; 
  params['UsuarioId'] = usuario;
  params['FechaInicial'] = FechaInicial;
  params['FechaFinal'] = FechaFinal;
  return Post_GetConsultasDinamicasDWH({
      NombreConsulta: "HistorialCambios", Clase: "CHLQueryHelper",
      Pagina: null,
      RecordsPorPagina: null
  }, params)
};




  