// APP EBUS
import NivelCarga from "../../modules/MODULOS/Ebus/NivelCarga";
import ParqueoInteligente from "../../modules/MODULOS/Ebus/ParqueoInteligente";
import EventoCarga from "../../modules/MODULOS/Ebus/EventoCarga";
import Configuracion from "../../modules/MODULOS/Ebus/Configuracion";
import NoCargas from "../../modules/MODULOS/Ebus/components/Diagnostico/NoCargas";

import ReporteOdometro from "../../modules/MODULOS/Ebus/components/Reportes/Odometro";
import ReporteAlarmas from "../../modules/MODULOS/Ebus/components/Reportes/Alarmas";
import ReporteComparacionOdometro from "../../modules/MODULOS/Ebus/components/Reportes/ComparacionOdometro";
import ReporteConductorNoId from "../../modules/MODULOS/Ebus/components/Reportes/ConductorNoId";
import ReporteNivelCarga from "../../modules/MODULOS/Ebus/components/Reportes/NivelCarga";
import ZPOperadorMovil from "../../modules/MODULOS/Ebus/components/Reportes/ZPOperadorMovil";
import ReporteSafety from "../../modules/MODULOS/Ebus/components/Reportes/Safety";
import ReporteViaje from "../../modules/MODULOS/Ebus/components/Reportes/GraficaViajes";
import ReporteEficiencia from "../../modules/MODULOS/Ebus/components/Reportes/Eficiencia";




// APP CHL
import  ReporteCHL  from "../../modules/MODULOS/CHL/components/Reporte";
import CHLHistorial from "../../modules/MODULOS/CHL/components/Configuracion/HistorialCambios";
import CHLConfiguracion from "../../modules/MODULOS/CHL/components/Configuracion/configuracion";
// APP TORRE DE CONTROL
import IndicadoresHistoricos from "../../modules/MODULOS/Fatigue/IndicadoresHistoricos";
import AlertasHistoricas from "../../modules/MODULOS/Fatigue/alertasHistoricas";
import Operadores from "../../modules/MODULOS/Fatigue/components/Configuracion/Operadores";
import FatigueDashboard from "../../modules/MODULOS/Fatigue/dashboard";
import Parametrizacion from "../../modules/MODULOS/Fatigue/components/Configuracion/Parametrizacion";

export let ListaModulos : any [] = [];
// APP EBUS
ListaModulos.push( { Component:NivelCarga , url : "/ebus/NivelCarga" })
ListaModulos.push( { Component:ParqueoInteligente , url : "/ebus/ParqueoInteligente" })
ListaModulos.push( { Component: EventoCarga, url : "/ebus/EventoCarga" })
ListaModulos.push( { Component:Configuracion , url : "/ebus/Configuracion" })
ListaModulos.push( { Component: NoCargas, url : "/ebus/diagnostico" })
ListaModulos.push( { Component: ReporteOdometro, url : "/ebus/reportes/odometro" })
ListaModulos.push( { Component:ReporteAlarmas , url : "/ebus/reportes/alarmas" })
ListaModulos.push( { Component: ReporteComparacionOdometro, url : "/ebus/reportes/codometro" })
ListaModulos.push( { Component: ReporteConductorNoId, url : "/ebus/reportes/noconductor" })
ListaModulos.push( { Component: ReporteNivelCarga, url : "/ebus/reportes/nivelcarga" })
ListaModulos.push( { Component: ZPOperadorMovil, url : "/ebus/reportes/zpoperadormovil" })
ListaModulos.push( { Component: ReporteSafety, url : "/ebus/reportes/safety" })
ListaModulos.push( { Component: ReporteViaje, url : "/ebus/reportes/viajes" })
ListaModulos.push( { Component: ReporteEficiencia, url : "/ebus/reportes/eficiencia" })
// APP TORRE DE CONTROL
ListaModulos.push( { Component: FatigueDashboard, url : "/fatigue/dashboard" })
ListaModulos.push( { Component: AlertasHistoricas, url : "/fatigue/alertashistoricas" })
ListaModulos.push( { Component: IndicadoresHistoricos, url : "/fatigue/indicadores" })
ListaModulos.push( { Component: Parametrizacion, url : "/fatigue/configuracion" })
ListaModulos.push( { Component: Operadores, url : "/fatigue/historiaoperadores" })
// APP TORRE DE CONTROL
ListaModulos.push( { Component: ReporteCHL, url : "/CHL/reporte" })
ListaModulos.push( { Component: CHLConfiguracion, url : "/CHL/configuracion" })
ListaModulos.push( { Component: CHLHistorial, url : "/CHL/historial" })