import { Box, IconButton } from "@mui/material";
import { useDataSeguridad } from "../core/seguridadProvider";
import { Edit } from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ECO_SeguridadOrg } from "../data/seguridaddata";

export const ECO_SeguridadOrganizaciones: React.FC = () => {
    const { lstOrganizaciones } = useDataSeguridad();
    return (<MaterialReactTable
        localization={MRT_Localization_ES}
        columns={ECO_SeguridadOrg}
        data={lstOrganizaciones}
        rowCount={lstOrganizaciones.length}
        enableColumnFilters={false}
        enableColumnOrdering
        enableColumnDragging={false}
        //enablePagination={false}
        enableStickyHeader
        enableDensityToggle={false}
        //enableRowVirtualization
        defaultColumn={{
            minSize: 40, //allow columns to get smaller than default
            maxSize: 200, //allow columns to get larger than default
            size: 150,
        }}
        muiTableContainerProps={{
            sx: { maxHeight: '500px' }, //give the table a max height
        }}
        initialState={{
            pagination: {
                pageIndex: 0,
                pageSize: 100, //customize the default page size
            },
        }}
        enableEditing
        renderRowActions={({ row, table }) => (
            <Box
                sx={{ justifyContent: 'flex-end', alignItems: 'center', flex: 1, display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
            >
                <IconButton color="primary" onClick={() => table.setEditingRow(row)} >
                    <Edit />
                </IconButton>

            </Box>
        )}
    />)
}