import { useEffect, useState } from "react";
import { useToaster } from "rsuite";
import { Checkbox } from "@mui/material";
import BlockUi from "@availity/block-ui";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../_start/helpers/components/HtlmTooltip";
import { Button, Form, FormLabel } from "react-bootstrap-v5";
import confirmarDialog from "../../../../../_start/helpers/components/ConfirmDialog";
import { MensajeError } from "../../../../../_start/helpers/components/Toaster";
import { AxiosError, AxiosResponse } from "axios";
import { useDataExlusion } from "../core/ProviderExclusion";
import { ConfigDriver, GetConductores, Tooltips } from "../data/Insights_dataConfigAssets";
import { getConductoresClienteId } from "../../../../../_start/helpers/Axios/DWHService";
import { GetSites } from "../../../Seguridad/Administracion/data/Clientes";
import { getClientes } from "../../../APPS/Mobile/data/dataConfiguracion";
/**Permite configurar los Conductores que se utilizaran para la aplicación, la extracción puede ser de sitio o vehículos específico 
 * de cada sitio. 
 * En el servicio que extrae la información se filtrará esta información por los vehículos seleccionados, si no hay ninguno
 * se asumirá que serán todos los que cuenta el cliente.
 */
export const Insights_TreeConductores: React.FC = () => {
 
    const toaster = useToaster();
    // const [lstConductoresCliente, setlstConductoresCliente] = useState<any[]>([]);
    const [lstSites, setLstSites] = useState<any[]>([]);
    const [lstConductoresOriginal, setlstConductoresOriginal] = useState<any[]>([]);
    // const [lstSeleccionados, setLstSeleccionados] = useState<any[]>([]);
    const [nodosExpandidos, setNodosExpandidos] = useState<any[]>([]);
    const [filtro, setFiltro] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [lstConductoresCliente, setlstConductoresCliente] = useState<any[]>([]);
    
const {clienteSeleccionado, setClientes, Clientes, lstSeleccionadosConductores, setlstSeleccionadosConductores, Filtros, setFiltros } = useDataExlusion();
    useEffect(() => {
        // iniciamos obteniendo los sites que la fuente princupal del contro
        if (clienteSeleccionado !== undefined && clienteSeleccionado.ClienteId !== '0') {
            consultarGetSites(); // obtiene la información de los sites
            // ConsultarClientesDatos(); // ontiene las propiedades del cliente seleccionado
        }

        return () => {
            setlstConductoresCliente([])
        }

    }, [clienteSeleccionado]);

    // trae la información de los assets que se han guardado en los clientes
    // useEffect(() => {
    //     // traemos los datos guardados en el control
    //     if (Clientes.length > 0)
    //         setLstSeleccionados( [])

    //     return () => {
    //         setLstSeleccionados([])
    //     }

    // }, [Clientes]);
    useEffect(() => {
        // cada vez que seleccionamos o quitamos actualizamos el array para que se actualize la visualización
        const lstConductoresFiltrados = lstConductoresOriginal.filter(f =>
        (filtro === '' ||
            (f.registrationNumber.toUpperCase().includes(filtro.toUpperCase()) || f.description.toUpperCase().includes(filtro.toUpperCase()))))
            let lstDatosanidados =  ConfigDriver.getSitesAnidadosTree(lstSites, null, lstConductoresFiltrados, lstSeleccionadosConductores);
            // let lstDatosanidados = ConfigDriver.getSitesAnidadosTree(lstSites, null, lstConductoresFiltrados, lstSeleccionados);
            setlstConductoresCliente(lstDatosanidados);
    }, [lstSeleccionadosConductores, filtro]);

    const consultarConductores = (lstSitesAnidados: any[]) => {
        setLoading(true)
        GetConductores(String(clienteSeleccionado.ClienteIdString)).then((response: AxiosResponse) => {
            if (response.status === 200) {
                // guardamos el array original para actualizar los selecciondfos
                setlstConductoresOriginal(response.data);
                // transformamos los datos en un array anidado para pderse mostrar en el treeview
                let lstDatosanidados = ConfigDriver.getSitesAnidadosTree(lstSitesAnidados, null, response.data, lstSeleccionadosConductores);
                setlstConductoresCliente(lstDatosanidados);
            }
        }).catch((error: AxiosError) => {
            toaster.push(MensajeError('Error al cargar vehículos', error.message), { placement: 'topCenter' })
        }).finally(() => { setLoading(false) });
    }
    const consultarGetSites = () => {
        setLoading(true)
        GetSites(String(clienteSeleccionado.ClienteIdString)).then((response: AxiosResponse) => {
            if (response.status === 200) {
                setLstSites(response.data);
                // consultamos los vehículos apenas la información de los sites esté disponible
                consultarConductores(response.data)
            }
        }).catch((error: AxiosError) => {
            toaster.push(MensajeError('Error al cargar Sites', error.message), { placement: 'topCenter' })
        }).finally(() => { setLoading(false) });
    }


    // const ConsultarClientesDatos = () => {
    //     getClientes(String(clienteSeleccionado.ClienteIdS)).then((response: AxiosResponse<any>) => {
    //         if (response.status === 200){
                
    //         }
    //             // setClientes(response.data);
    //     }).catch((error: AxiosError<any>) => {
    //         toaster.push(MensajeError('Error al cargar Datos del Cliente', error.message), { placement: 'topCenter' })
    //     });
    // }
    // guarda la información de los Conductores en params sistema preop_config con la propiedad assets en donde se guardarán 
    // los assets permitidos para traer información de eventos del preoperacional y poderlos filtrar en las consultas 
    // que se mostrarán en el listado general
    const GuardarlistadoConductores = () => {
       
        confirmarDialog(() => {
            // setLoading(true);
            // setDataParamSistema(clienteSeleccionado.ClienteIds, "$.preop_config.assets", JSON.stringify(lstSeleccionados)).
            //     then((response: AxiosResponse<any>) => {
            //         if (response.status === 200) {
            //             toaster.push(MensajeSuccess('Guardar Conductores', 'Guardado exitosamente'), { placement: 'topCenter' });
            //             ConsultarClientesDatos();
            //         }

            //     }).catch((error: AxiosError<any>) => {
            //         toaster.push(MensajeError('Guardar Conductores', error.message), { placement: 'topCenter' })
            //     }).finally(()=>{ setLoading(false);})
        }, `¿Esta seguro que desea guardar los cambios?`, 'Guardar');
    }

    const TreeNodeDriver: React.FC<{ node: any }> = ({ node }) => {
        // toggle que indica que va a esar el nodo expandido o no
        // verificamos del listado si existe el nodo extendido para setearlo 
        const isExpanded = nodosExpandidos.find(f => f === node.SiteId)
        const [expanded, setExpanded] = useState(node.Node === 1 ? isExpanded != null : false);
        const id = node.Node === 1 ? node.SiteId : node.DriverId; // toca determinar si es nodo o un hijo con ello viene el comportamiento del control
        const handleToggle = () => {
            const nuevoEstado = !expanded;
            setExpanded(nuevoEstado);
            let lstNodosExpandidos = [...nodosExpandidos];

            if (node.Node === 1)
                if (nuevoEstado) {
                    if (!lstNodosExpandidos.includes(id)) lstNodosExpandidos.push(id)
                }
                else
                    lstNodosExpandidos = lstNodosExpandidos.filter(f => f !== id);
            setNodosExpandidos(lstNodosExpandidos);
        };

        // verifica que exista la propiedad y sea de tipo array de lo contrario lo plasma en o
        const hijos: any[] = Array.isArray(node.subRows) ? node.subRows : [];
        const isRoot = hijos.length > 0;
        const classnameNode: string = isRoot ?  'fs-4 text-green fw-bold' : 'fs-3 text-syscaf-primary'

        // verificamos si desde el nodo existe en varios niveles Conductores seleccionados

        let allAssets:any[] = ConfigDriver.getAssetsAnidados(hijos); // listado de assets en todos los niveles inferiores
        let selectForNode = allAssets.filter(f => lstSeleccionadosConductores.includes(f));  // seleccionados para el nodo en específico 
        const totalAssetsNodo = allAssets.length;
        const toalAsstesNodoSel = selectForNode.length;
        return (<>
            <li>
                {isRoot && (
                    <span className='text-syscaf-amarillo' onClick={handleToggle} style={{ cursor: 'pointer', marginRight: 5 }}>
                        {expanded ? '▼' : '▶'}
                    </span>
                )}
                <Checkbox
                   color={(totalAssetsNodo !== toalAsstesNodoSel) ? "warning" : "primary"}
                    checked={node.Node === 1 ? toalAsstesNodoSel > 0 : node.seleccionado}
                    onChange={(ev: any, checked: boolean) => {

                        //marcamos los nodos expandidos o no para que a pesar de los cambios los demás elementos nose colapsen
                        let lstNodosExpandidos = [...nodosExpandidos];

                        if (node.Node === 1)
                            if (checked) {
                                if (!lstNodosExpandidos.includes(id)) lstNodosExpandidos.push(id)
                            }
                        setNodosExpandidos(lstNodosExpandidos);


                        // esto es importante pues detemrina a quienes se adiciona no no.
                        let seleccionados:any[] = [...lstSeleccionadosConductores]; // hacemos una copia de los seleccioandos
                        // verificamos que no existan en el array y si se quita se deschulea e la accion
                        if (node.Node !== 1) // si no es nodo y es hijo, se verifica y se agrega/ elimina al array                         
                            if (checked) {
                                if (!seleccionados.includes(id)) seleccionados.push(id)
                            }
                            else
                                seleccionados = seleccionados.filter(f => f !== id)

                        if (node.Node === 1)    // si es nodo debemos buscar todos los hijos y adiconarlos al array.
                        { // traemos todos los hijos que tiene y verificamos que no existan

                            allAssets.forEach(
                                f => {
                                    if (checked) {
                                        if (!seleccionados.includes(f)) seleccionados.push(f);
                                    }
                                    else
                                        seleccionados = seleccionados.filter(fff => fff != f)
                                }
                            );
                        }
                        setlstSeleccionadosConductores(seleccionados); // seteamos la información
                        let f = {...Filtros};
                        f.drivers = seleccionados;
                        setFiltros(f);
                    }} />
                <span className={classnameNode}> {node.Node == 1 ?
                    `${node.Sitio} ( ${toalAsstesNodoSel} de ${totalAssetsNodo} seleccionados)`
                    : `${node.name}`}</span>
                {isRoot && expanded && (
                    <ul className='mx-2' style={{ listStyleType: 'none', paddingLeft: 20 }}>
                        {hijos?.map((childNode: any) => {
                            return <TreeNodeDriver key={childNode.subRows?.length > 0 ? childNode.SiteId : childNode.DriverId} node={childNode} />
                        })}
                    </ul>
                )}
            </li>

        </>
        );
    };

    //backgroundImage:`linear-gradient(to right, #1B4256 , #007675)`
    const TreeViewDriver: React.FC = () => {
        return (<div className='m-2 border rounded  overflow-auto' >
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {lstConductoresCliente.map((node: any) => {
                    if (node.subRows.length > 0)
                        return (<TreeNodeDriver key={node.SiteId} node={node} />)
                }
                )}
            </ul>
        </div>
        );
    };
    return (

        <BlockUi tag='div' message="Cargando información..." keepInView blocking={loading} >
            <TreeViewDriver />
        </BlockUi>
    )
}