import { MRT_ColumnDef } from "material-react-table";
import { Form } from "react-bootstrap-v5";
import { errorDialog } from "../../../../../_start/helpers/components/ConfirmDialog";
import moment from "moment";
import { FormatoColombiaDDMMYYYHHmm } from "../../../../../_start/helpers/Constants";

export const Usuarios_Tooltips: any = {
    "Nuevo": { title: "Nuevo", content: "Permite adicionar un nuevo usuario." }
    , "Editar": { title: "Editar", content: "Permite editar un usuario." }
    , "Inactivar": { title: "Inactivar", content: "Permite inactivar un usuario." }
    , "Modulos": { title: "Modulos", content: "Permite editar un modulo de los usuarios." }
    , "Opciones": { title: "Opciones", content: "Permite agregar o editar una opción donde ingresar el usuario." }
    , "Cerrar": { title: "Cerrar", content: "Cierra la ventana." }
    , "Guardar": { title: "Guardar", content: "Guarda la informacion contenida en dicha ventana." }
    , "principal": { title: "Gestión de Usuarios", content: "Opción que permite gestionar un usuario especifico, inhabilitarlo, asignarles roles." }

}
export const columnasTabla: MRT_ColumnDef<any>[]
    = [ {
            accessorKey: 'Estado',
            header: 'Estado',
            Cell({ row, }) {
                return <span className={(row.original.LockoutEnabled === 1 ? 'badge bg-success' : 'badge bg-danger')}>{row.original.Estado}</span>
            },
        },
        {
            accessorKey: 'ClienteNombre',
            header: 'Cliente'
        },
        {
            accessorKey: 'Nombres',
            header: 'Nombres'
        },
        {
            accessorKey: 'Email',
            header: 'Correo'
        },
        {
            accessorKey: 'Perfil',
            header: 'Perfil'
        },
       
        {
            accessorKey: 'DetallesAdicionales',
            header: 'Cargo',

            Cell({ row, }) {
                return <span >{(row.original.DetallesAdicionales == null ? "-" : JSON.parse(row.original.DetallesAdicionales).Tipo)}</span>
            },
        },
        {
            accessorKey: 'PhoneNumber',
            header: 'Telefono'
        }
        
    ];

export const NuevoUsuario = {
    "Nombres": "",
    "Apellidos": "",
    "Cargo": "",
    "PerfilId": "",
    "ClienteId": "",
    "Correo": "",
    "esGenerico": false,
    "AsignarContrasenia": false,
    "Password": "",
    "ConfirmaPassword": "",
    "UserName": "",
    "UpdatePassword": false,
    isNew: true
};

export const PerfilesSelect: React.FC<{ Usuario: any, Perfiles: any[], setUsuario: any }> = ({ Usuario, Perfiles, setUsuario }) => {
    return (
        <div className="mx-2 input-group mb-3">
            <span style={{ height: '40px' }} className="input-group-text mt-3" id="basic-addon1"><i className="bi-credit-card-2-front"></i></span>
            <Form.Select title="Filtra por perfiles" value={Usuario.PerfilId} style={{ height: '40px' }} className="input-sm  mb-3 mt-3 " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let lstPerfil = Perfiles.filter((value: any) => {
                    return String(value.PerfilId) === e.currentTarget.value
                })
                let p = { ...Usuario };
                p.PerfilId = (lstPerfil.length > 0 ? lstPerfil[0].PerfilId : { "PerfilId": 0, "Nombres": "Todos" });
                setUsuario(p);
            }} aria-label="Default select example">
                <option value={"0"}>Seleccione</option>
                {
                    Perfiles?.map((element: any) => {
                        return (<option key={element.PerfilId} value={element.PerfilId}>
                            {`${element.Nombres}`}</option>)
                    })
                }
            </Form.Select>
        </div>

    );
}

//Componentes del los clientes
export const ComponenteClientes: React.FC<{ ClienteId: any, Clientes: any[], callBack: any }> = ({ ClienteId, Clientes, callBack }) => {
    return (
        <div className="input-group mb-3">
            <span style={{ height: '40px' }} className="input-group-text mt-3" id="basic-addon1"><i className="bi-file-earmark-person-fill"></i></span>
            <Form.Select style={{ height: '40px' }} className="input-sm  mb-3 mt-3 " onChange={(e) => {
               callBack(e)
            }} value={ClienteId} aria-label="Default select example">
                <option value={0}>Todos los clientes</option>
                {
                    Clientes?.map((element: any) => {
                        return (<option key={element.ClienteId} value={element.ClienteId}>{element.clienteNombre}</option>)
                    })
                }
            </Form.Select>
        </div>
    )
}


//Valida que el email es correcto
export const validarEmail = (email: any) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}
//Crea passsword aleatoriamente
export const generarContrasena = (longitud: number) => {
    const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerCase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specialChars = "!@#$%^&*()_+[]{}|;:,.<>?";

    // Al menos un carácter de cada categoría
    const allChars = upperCase + lowerCase + numbers + specialChars;
    let password = '';

    password += upperCase[Math.floor(Math.random() * upperCase.length)];
    password += lowerCase[Math.floor(Math.random() * lowerCase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    // Rellenar el resto de la contraseña con caracteres aleatorios
    for (let i = 4; i <= longitud; i++) {
        password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Mezclar los caracteres para asegurarse de que estén distribuidos aleatoriamente
    password = password.split('').sort(() => 0.5 - Math.random()).join('');

    return password;

}

//Funcion que valida los datos del usuario
export const ValidarUsuario = (Usuario: any) => {
    //Nombres
    if (Usuario.Nombres === "" || Usuario.Nombres === null || Usuario.Nombres === undefined) {
        errorDialog("Primer Nombre es requerido", "");
        return false;
    }
    if (Usuario.Nombres.length < 3) {
        errorDialog("Mínimo 3 Símbolos", "");
        return false;
    }
    if (Usuario.Nombres.length > 50) {
        errorDialog("Máximo 50 Símbolos", "");
        return false;
    }
    //Apellidos
    if (Usuario.Apellidos === "" || Usuario.Apellidos === null || Usuario.Apellidos === undefined) {
        errorDialog("El Apellidos es requerido", "");
        return false;
    }
    if (Usuario.Apellidos.length < 3) {
        errorDialog("Mínimo 3 Símbolos", "");
        return false;
    }
    if (Usuario.Apellidos.length > 50) {
        errorDialog("Máximo 50 Símbolos", "");
        return false;
    }
    //Correo
    if (Usuario.Correo === "" || Usuario.Correo === null || Usuario.Correo === undefined) {
        errorDialog("El correo es requerido", "");
        return false;
    }
    if (!validarEmail(Usuario.Correo)) {
        errorDialog("Error formato email", "");
        return false;
    }
    //    Password
    if ((Usuario.AsignarContrasenia ?? false) || (Usuario.UpdatePassword ?? false)) {
        if (Usuario.Password === "" || Usuario.Password === null || Usuario.Password === undefined) {
            errorDialog("La contraseña es requerida", "");
            return false;
        }
        if (Usuario.Password.length < 8) {
            errorDialog("Mínimo 8 Símbolos", "");
            return false;
        }
        if (Usuario.Password.length > 50) {
            errorDialog("Máximo 50 Símbolos", "");
            return false;
        };
        //Confirmacion de password
        if (Usuario.ConfirmaPassword === "" || Usuario.ConfirmaPassword === null || Usuario.ConfirmaPassword === undefined) {
            errorDialog("Confirmación Contraseña es requierida", "");
            return false;
        }
        if (Usuario.Password !== Usuario.ConfirmaPassword) {
            errorDialog("Contraseña y Confirmación Contraseña no coinciden", "");
            return false;
        }
    }
    
    if (Usuario.PerfilId === 119) {
        if (Usuario.ClienteId === null || Usuario.ClienteId === undefined || Usuario.ClienteId === "") {
            errorDialog("Debe seleccionar el cliente al cual pertenece", "");
            return false;
        }
    }
    return true;
}


export const columnasClaimUsuario: MRT_ColumnDef<any>[]
    = [
        {
            accessorKey: 'id',
            header: 'id'
        },
        {
            accessorKey: 'ClaimType',
            header: 'Tipo'
        },
        {
            accessorKey: 'ClaimValue',
            header: 'Valor'
        }
    ];


    
export const columnasHistorialUsuario: MRT_ColumnDef<any>[]
= [
    {
        accessorKey: 'LogId',
        header: 'LogId'
    },
    {
        accessorKey: 'StartDate',
        header: 'Fecha Inicio',
        Cell(row: any) {
            return moment(row.row.original.StartDate).format(FormatoColombiaDDMMYYYHHmm)
        },
    },
    {
        accessorKey: 'EndDate',
        header: 'Fecha Final',
        Cell(row: any) {
            return moment(row.row.original.EndDate).format(FormatoColombiaDDMMYYYHHmm)
        },
    },
    {
        accessorKey: 'Opcion',
        header: 'Opción'
    },
    {
        accessorKey: 'Date',
        header: 'Fecha Ingreso',
        Cell(row: any) {
            return moment(row.row.original.Date).format(FormatoColombiaDDMMYYYHHmm)
        },
    }
];
