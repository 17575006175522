
import Chart from "react-apexcharts";
import { getCSSVariableValue } from "../../../../../../../_start/assets/ts/_utils";
import { ApexOptions } from "apexcharts";

type Props = {
    series: any[];
    labels: string[];
    nameChart: string;
    config?: any;
    titulo?: string;
    showTotal?: boolean;
    showLegend?: boolean;

};

export const DonaUsers: React.FC<Props> = ({ series, labels, nameChart, config, titulo, showTotal = false, showLegend = false }) => {
    //OTRAS UNIDADES
    const colorsuccess = getCSSVariableValue("--bs-success");
    const colorwarning = getCSSVariableValue("--bs-warning");
    const colorblue = getCSSVariableValue("--bs-primary");
    const colordanger = getCSSVariableValue("--bs-danger");
    const colorinfo = getCSSVariableValue("--bs-info");


    let opcionesOtrasUnidades: ApexOptions = {
        series: [],
        chart: {
            id: nameChart,
            fontFamily: 'Montserrat',
            type: 'donut',
            dropShadow: {
                enabled: true,
                color: '#111',
                top: -1,
                left: 3,
                blur: 3,
                opacity: 0.2
            }
        },
        stroke: {
            width: 0,
        },
        plotOptions: {
            pie: {
                donut: {

                    labels: {

                        show: true,
                        value: {
                            show: true,
                            fontSize: '18px',
                            offsetY: -7
                        },
                        total: {
                            showAlways: true,
                            show: showTotal,
                            fontSize: '18px',
                            formatter: function (w: any) {
                                return w.globals.seriesTotals.reduce((a: any, b: any) => {
                                    return a + b
                                }, 0).toFixed(0)
                            }
                        }
                    }
                }
            }
        },
        labels: [],
        dataLabels: {
            offsetY: -5,
            enabled: true,
            formatter: function (val: number) {
                return val.toFixed(0) + "%"
            },
            style: {
                colors: ['#000']
            },
            background: {
                enabled: true,
                foreColor: '#fff'
            },
        },

        legend: {
            show: showLegend,
            position: (labels.length <= 5) ? 'bottom' : 'right',
            fontSize: '12px',
            formatter: function (seriesName: any, opts: any) {
                return [seriesName, "(", opts.w.globals.series[opts.seriesIndex].toFixed(0), ")"].join('')
            }
        },
        theme: {
            palette: 'palette4'
        },



    };
    if (titulo !== '')
        opcionesOtrasUnidades.title = {  text: titulo, align: 'center', };
    opcionesOtrasUnidades.series = series; // se modifican las series
    opcionesOtrasUnidades.labels = labels; // se modifican los labesl
    if (labels.length <= 5)
        opcionesOtrasUnidades.colors = [colordanger, colorsuccess, colorwarning, colorblue, colorinfo];

    return (
        <>
            <div key={`div_chardonavehiculo_${nameChart}`}>
                {(opcionesOtrasUnidades !== undefined) && (
                    (config) ? (
                        <Chart width={config.width} height={config.height} key={`eco_usuarios_${nameChart}`} options={opcionesOtrasUnidades} series={opcionesOtrasUnidades.series}
                            type="donut" />) : (<Chart key={`eco_usuarios_${nameChart}`} options={opcionesOtrasUnidades} series={opcionesOtrasUnidades.series}
                                type="donut" />)

                )}
            </div>


        </>
    )
}