import { useEffect,  useState } from "react";
import BlockUi from "@availity/block-ui";
import { IndicadorDona } from "./IndicadoresGestion/dona";
import { useDataIndicadoresHistorical } from "../../core/providerIndicadores";
import { AlertasHoras } from "./IndicadoresGestion/alertasXhoras";

export default function IndicadoresHistorical() {


    const { loader, dataIndicadores } = useDataIndicadoresHistorical();
    const [activeTab, setactiveTab] = useState("");
    useEffect(() => { setactiveTab("#tab_1") }, []);  

    // html por defecto cuando no exista valores
    const DefaultChart = () => {
        return (<div className="card card-stretch mb-3 mb-xxl-4">
            <div className="card-body ">
                <div className="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center h-125px" >
                    <div className="fw-bolder fs-1 text-gray-800 position-absolute top-50 start-45">
                        0
                    </div>
                </div>
            </div>
        </div>)
    }

    return (
        <>

            <BlockUi tag="div" keepInView blocking={loader ?? false}  >
                <div className="card shadow border" >

                    {/* begin::Nav */}
                    <div className="me-sm-10 me-0">
                        <ul className="nav nav-tabs nav-pills nav-pills-custom" >
                            <li className="nav-item  mb-3" key="tabenc_1">
                                <a
                                    onClick={() => setactiveTab("#tab_1")}
                                    className={`nav-link w-140px   h-50px  ${activeTab === "#tab_1" ? "active" : ""
                                        } fw-bolder`}
                                    id="tab_1"                           >
                                    <div style={{ width: 110 }} className="ps-1 text-center">
                                        <span className={` nav-text  fw-bolder fs-6`} >
                                            Por Alertas
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item  mb-3" key="tabenc_2">
                                <a className={`nav-link w-140px   h-50px  ${activeTab === "#tab_2" ? "active" : ""
                                    } fw-bolder`}
                                    onClick={() => setactiveTab("#tab_2")}
                                    id="tab_2"                           >
                                    <div style={{ width: 200 }} className="ps-1 text-center">
                                        <span className={` nav-text  fw-bolder fs-6`} >
                                            Por Tiempo Gestión
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item  mb-3" key="tabenc_3">
                                <a className={`nav-link w-140px   h-50px ${activeTab === "#tab_3" ? "active" : ""
                                    }  fw-bolder`}
                                    onClick={() => setactiveTab("#tab_3")}
                                    id="tab_3"                           >
                                    <div style={{ width: 110 }} className="ps-1 text-center">
                                        <span className={` nav-text  fw-bolder fs-6`} >
                                            Por Agentes
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item  mb-3" key="tabenc_4">
                                <a className={`nav-link w-140px   h-50px ${activeTab === "#tab_4" ? "active" : ""
                                    }  fw-bolder`}
                                    onClick={() => setactiveTab("#tab_4")}
                                    id="tab_3"                           >
                                    <div style={{ width: 150 }} className="ps-1 text-center">
                                        <span className={` nav-text  fw-bolder fs-6`} >
                                            Alertas por Horas
                                        </span>
                                    </div>
                                </a>
                            </li>

                        </ul>
                        {/* <button className="m-2 ms-0 btn btn-sm btn-primary" type="button" onClick={() => {  console.log(indicadresExcel)}}>
                  <i className="bi-file-earmark-excel"></i></button> */}
                    </div>
                    {/* end::Nav */}

                    <div className="tab-content flex-grow-1" // style={{ paddingLeft: "0.23rem !important" }}
                    >
                        <div
                            className={`tab-pane fade ${activeTab === "#tab_1" ? "show active" : ""
                                }`}
                            id="tab1_content"
                        >
                            {/* begin::Content */}
                            {/* begin::Cards */}
                            <div className="overflow-auto">

                                {(activeTab === "#tab_1") &&

                                    (<div>
                                        <div className="d-flex justify-content-between m-2  ">

                                            {dataIndicadores.length > 0 ? <IndicadorDona className={"mt-4 mb-xxl-4 h-200px w-200px "}
                                                nameChart="Cat_Riesgo1" tipoData={5} titulo={"Categorización por Riesgo"} />
                                                : <DefaultChart />}
                                            {dataIndicadores.length > 0 ? <IndicadorDona className={"riesgos"}
                                                nameChart="Cat_Riesgo2" tipoData={6} titulo={"Categorización por Alertas Críticas"} />
                                                : <DefaultChart />}
                                        </div>
                                        <div className="d-flex justify-content-between m-2  ">
                                            {dataIndicadores.length > 0 ? <IndicadorDona className={"mb-xxl-4"}
                                                nameChart="Cat_Riesgo3" tipoData={7} titulo={"Categorización por Estado Crítico"} />
                                                : <DefaultChart />}


                                            {dataIndicadores.length > 0 ? <IndicadorDona className={"mb-xxl-4"}
                                                nameChart="Cat_Riesgo4" tipoData={8} titulo={"Categorización por Estado"} />
                                                : <DefaultChart />} 


                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>

                        <div
                            className={`tab-pane fade ${activeTab === "#tab_2" ? "show active" : ""
                                }`}
                            id="tab2_content"
                        >
                            {/* begin::Content */}
                            {/* begin::Cards */}
                            <div className="overflow-auto">

                                {(activeTab === "#tab_2") &&

                                    (
                                        <div className="row g-5">
                                            <div className="col-sm-4">
                                                {dataIndicadores.length > 0 ? <IndicadorDona className={"card-stretch mb-3 mb-xxl-4"}
                                                    nameChart="promedio_Tiempo" tipoData={1} titulo={"Prom Tiempo por Riesgo (min)"} />
                                                    : <DefaultChart />}
                                            </div>
                                            <div className="col-sm-4">
                                                {dataIndicadores.length > 0 ? <IndicadorDona className={"card-stretch mb-3 mb-xxl-4"}
                                                 nameChart="total_Tiempo" tipoData={2}
                                                    titulo={"Total Tiempo por Riesgo (horas)"} />
                                                    : <DefaultChart />}
                                            </div>
                                            <div className="col-sm-4">
                                                {dataIndicadores.length > 0 ? <IndicadorDona className={"card-stretch mb-3 mb-xxl-4"}
                                                    nameChart="total_Tiempo_Alerta" tipoData={3} titulo={"Total Tiempo por Alerta (horas)"} />
                                                    : <DefaultChart />}
                                            </div>
                                        


                                        </div>
                                    )}
                            </div>
                        </div>

                        <div
                            className={`tab-pane fade ${activeTab === "#tab_3" ? "show active" : ""
                                }`}
                            id="tab3_content"
                        >
                            {/* begin::Content */}
                            {/* begin::Cards */}
                            <div className="overflow-auto">
                                {(activeTab === "#tab_3") &&
                                    (
                                        <div className="row g-5">
                                            <div className="col-sm-4">
                                                {dataIndicadores.length > 0 ?
                                                    <IndicadorDona className={"card-stretch mb-3 mb-xxl-4"} nameChart="promedio_TiempoAgente" tipoData={10} titulo={"Promedio Tiempo x Agente (min)"} />
                                                    : <DefaultChart />}
                                            </div>
                                        </div>
                                    )}
                            </div>

                        </div>
                        <div
                            className={`tab-pane fade ${activeTab === "#tab_4" ? "show active" : ""
                                }`}
                            id="tab3_content"
                        >
                            <AlertasHoras />
                        </div>

                    </div>

                </div>

            </BlockUi>
</>
)
}