import { Post_GetConsultasDinamicasUserCore, Post_GetConsultasDinamicasUserDWH } from "../../../../../_start/helpers/Axios/DWHService";


export const FiltroClientesModulos = {
    // trae los modulos actuales y los actualzia con los nuevos si han cambiado cambian de valor
    getModulosActualizados: (ModulosActuales: any[], ModulosModicados: any[]) => {
   // busca y actializa segun cambios sino adiciona en caso dado ingresen nuevo
        ModulosModicados?.forEach((f: any) => {
            const isExists = ModulosActuales.find(find => find.modulo.trim() === f.modulo.trim());
            if (isExists) {
                isExists.valor = f.valor;
                isExists.tipoparametro = f.tipoparametro;
            }
            else
                ModulosActuales.push(f);

        })

        return ModulosActuales;
    }
}

export function SetPreferenciasDescargarPorCliente(ClienteId : string, Evento: string ) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['ClienteId'] = ClienteId;
    params['Evento'] = Evento;
    return Post_GetConsultasDinamicasUserDWH({
        NombreConsulta: "SetPreferenciasDescargarPorCliente", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};

export function SetPreferenciasDescargarPorClienteCORE(ClienteId : string, Evento: string ) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['ClienteId'] = ClienteId;
    params['Evento'] = Evento;
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "SetPreferenciasDescargarPorCliente", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};



