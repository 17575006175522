import { useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap-v5";
import { NuevoConfiguracionDTO, defaultNuevoConfiguracionDTO } from "../../../models/EventosActivos";
import { GetEventos, Validar, setConfiguraciones } from "../../../data/Configuracion";
import { AxiosResponse } from "axios";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { TabConfiguracion } from "./NuevaAlertaTabClasificacion";
import { TabEventos } from "./NuevaAlertaTabEventos";
import { TabGeneral } from "./NuevaAlertaTabGeneral";
import { useToaster, Notification } from "rsuite";

type Props = {
    show: boolean;
    setShow : any;
    cliente: any;
    row: any | null;
    titulo: string;
    GetConfiguracionAlerta : any
};

export const ModalNuevaAlerta: React.FC<Props> = ({ show,setShow, cliente, row, titulo, GetConfiguracionAlerta }) => {

    const toaster = useToaster();
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }
    const [tempEventos, setTempEventos] = useState<any[]>([]) 
    const [Configuracion, setConfiguracion] = useState<NuevoConfiguracionDTO>(row ?? defaultNuevoConfiguracionDTO)

    useEffect(() => {
        if (cliente.ClienteId != null)
            ObtenerEventos(cliente.ClienteId.toString()); // consulta los eventos del cliente seleccionado
    }, [])

    const Guardar = () => {
        let Confi = { ...Configuracion };
        if (Confi.tiempo == "0" && Confi.Detalles.TipoAlerta == null) {
            toaster.push(message('error', "Guardar Configuración", "Debe seleccionar un Tipo de Alerta"), {
                placement: 'topCenter'
            });
            return;
        }
        //Armo los detalles de las configuacion.
        let _detallesLocal : any = {};
        _detallesLocal["TiempoCierre"] = Confi.Detalles.TiempoCierre;
        _detallesLocal["TipoAlerta"] = Confi.Detalles.TipoAlerta;
        _detallesLocal["Gestionar"] = Confi.Detalles.Gestionar;
        _detallesLocal["VerDashboard"] = Confi.Detalles.VerDashboard;
        //Para enviar al server.
        let Datos : any = {};
        Datos['clave'] = Confi.clave;
        Datos['nombre'] = Confi.nombre;
        Datos['tiempo'] = Confi.tiempo;
        Datos['condicion'] = Confi.condicion;
        Datos['clienteId'] = cliente.ClienteId;
        Datos['configuracionAlertaId'] = Confi.configuracionAlertaId;
        Datos['esActivo'] = true;
        Datos['Detalles'] = JSON.stringify(Confi.Detalles);
        Datos['maxAmber'] = Confi.maxAmber;
        Datos['minAmber'] = Confi.minAmber;
        if (Validar(Confi.nombre, Confi.condicion)) {
            confirmarDialog(() => {
                setConfiguraciones(Datos).then((response: AxiosResponse<any>) => {
                    (response.data.exitoso == true ? successDialog("Opeación Éxitosa.", "") : errorDialog(response.data.mensaje, ""));
                    setShow(false);
                    GetConfiguracionAlerta([]);
                }).catch(({ error }) => {
                    errorDialog("Ha ocurrido un error", "");

                })
            }, `Esta seguro que desea guardar la configuración`, 'Guardar');
        }
    };
    const ObtenerEventos = (cliente: any) => {
        if (cliente != "" && cliente != "0" && cliente != undefined && cliente != null)
            GetEventos(cliente).
                then((response: AxiosResponse<any>) => {
                    let Eventos = response.data.map((e: any) => {
                        return { EventId: e.eventTypeId, Descripcion: (e.CustomName == null ? e.descriptionEvent : e.CustomName) }
                    })
                    setTempEventos(Eventos);
                    //    setDataEventos(Eventos);
                }).catch((e) => {
                    errorDialog("Consulta eventos Activos", "No hay datos que mostrar");
                });
    }

    return <>
        <Modal show={show} onHide={setShow} size="xl">
            <Modal.Header className=" fw-bolder  bg-secondary rounded  " style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }} closeButton={false}>
                <Modal.Title className="text-uppercase" style={{ fontSize: 18 }}>
                    {titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mt-0 pt-0">

                <div className="border mb-2 mt-1 rounded shadow-sm">
                    {(cliente != undefined && (cliente.ClienteIdS != undefined
                        || cliente.clienteNombre != undefined)) && (
                            <div className="d-flex justify-content-start mx-2">
                                <label className="control-label label-sm fw-bolder mx-2 mt-1 fs-4" >Cliente:</label>
                                <label className="control-label label-sm   mx-2 mt-1 fs-4 " >{cliente.clienteNombre}</label>
                            </div>
                        )}

                    {(cliente != undefined && cliente.nombre != undefined) && (
                        <div className="d-flex justify-content-start mx-2 mb-1">
                            <label className="control-label label-sm fw-bolder mx-2 mt-1 fs-4" >Alerta:</label>
                            <label className="control-label label-sm  mx-2 mt-1 fs-4 " >{cliente.nombre}</label>
                        </div>
                    )}
                </div>
                <div className="shadow-sm rounded border">
                    <Tabs
                        defaultActiveKey="gestion"
                        className="mb-3  rounded "
                    >
                        <Tab eventKey="gestion" title={'General'} className=" mx-1">
                            <TabGeneral Configuracion={Configuracion} setConfiguracion={setConfiguracion} />
                        </Tab>
                        <Tab eventKey="eventos" title={'Eventos'} className="mx-1">
                            <TabEventos row={row} eventos={tempEventos} objetoGuardar={Configuracion} setObjetoGuardar={setConfiguracion} />
                        </Tab>
                        <Tab eventKey="clasificacion" className=" mx-1" title={'Clasificación'} >
                            <TabConfiguracion row={row} objetoGuardar={Configuracion} setObjetoGuardar={setConfiguracion} />
                        </Tab>

                    </Tabs>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    variant="primary"
                    className="btn btn-sm"
                    onClick={() => {
                        Guardar();
                    }}>
                    Guardar
                </Button>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => { setShow(false); }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}