import axios from "axios";
import { Post_getconsultadinamicasUser } from "../../../../_start/helpers/Axios/CoreService";
import { PerfilDTO } from "../models/PerfilModel";
import { REGISTER_URL, UPDATESTATE_URL, UPDATE_URL } from "../redux/AuthCRUD";
export async function ModificarPerfil(Data: PerfilDTO) {
    var params: { [id: string]: string | null; } = {};
    params["Imagen"] = Data.Imagen;
    params["Nombres"] = Data.Nombre;
    params["Telefono"] = Data.Telefono;
    // hacemos la consulta 
    return Post_getconsultadinamicasUser({ Clase: "NEPQueryHelper", NombreConsulta: "SetPerfilUsersById", Pagina: null, RecordsPorPagina: null }, params);
};

export async function GetDataUser() {
    var params: { [id: string]: string | null; } = {};
    // hacemos la consulta 
    return Post_getconsultadinamicasUser({ Clase: "PortalQueryHelper", NombreConsulta: "getDataUsers", Pagina: null, RecordsPorPagina: null }, params);
}

export async function GetUser(ClienteId: string | null, PerfilId: string | null) {
    var params: { [id: string]: string | null; } = {};
    params["ClienteId"] = ClienteId;
    params["PerfilId"] = PerfilId;

    // hacemos la consulta 
    return Post_getconsultadinamicasUser({ Clase: "PortalQueryHelper", NombreConsulta: "GetUser", Pagina: null, RecordsPorPagina: null }, params);
}




export const PostUsuario = (Usuario: any, isNew: any) => {
    return axios.post((isNew) ? REGISTER_URL : UPDATE_URL, {
        email: Usuario.Correo,
        nombres: `${Usuario.Nombres} ${Usuario.Apellidos}`,
        clienteId: (Usuario.ClienteId === "" ? "-1" : Usuario.ClienteId),
        password: Usuario.Password,
        perfilId: Usuario.PerfilId,
        detallesAdicionales: Usuario.DetallesAdicionales,
        esGenerico: Usuario.esGenerico,
        id: Usuario.Id,
        UpdatePassword: Usuario.UpdatePassword,
        PhoneNumber: Usuario.PhoneNumber
    });  
}

export const EstateChange = (Id: any) => {
    return axios({
        method: 'post',
        url: UPDATESTATE_URL,
        data: Id,
        headers: { 'Content-Type': 'application/json', 'accept': 'text/plain' }
    });
}

export const DividirNombre = (NombreCompleto: string) => {
    let NombresApellidos = NombreCompleto.split(" ").map((val: any) => {
     
            return (val !== null || val != "" || val !== undefined) ? val : undefined;
    }).filter((e: any) => e);

    let Nombres = (NombresApellidos.length >= 3 ? `${NombresApellidos[0]} ${NombresApellidos[1]}` : `${NombresApellidos[0]}`);
    let Apellidos = (NombresApellidos.length > 3 ? `${NombresApellidos[2]} ${NombresApellidos[3]}` : ((NombresApellidos.length === 2 ? `${NombresApellidos[1]}` : `${NombresApellidos[2]}`)));
    return [Nombres, Apellidos]

}