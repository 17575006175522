import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap-v5"
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ColumnFiltersState, SortingState, PaginationState } from "@tanstack/react-table";
import { Tooltips, categoriasEstados, getConfiguracion, setConfiguracion } from "../../../data/parametrizacionData";
import { FechaServidor } from "../../../../../../../_start/helpers/Helper";
import { Box, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../setup";
import { UserModelSyscaf } from "../../../../../auth/models/UserModel";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../../_start/helpers/components/HtlmTooltip";
import { Perfiles } from "../../../../../../../_start/helpers/Constants";
import { CargandoTitulo } from "../../../../../../../_start/helpers/components/Cargando";



type Props = {
  show: boolean;
  handleClose: () => void;
  title?: string;
  setloader:any
};

export const UpdateRequerimientos: React.FC<Props> = ({ show, handleClose, title, setloader }) => {

  const isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.user
  );
  const [disabled, setdisabled] = useState<any>(false);
  const model = (isAuthorized as UserModelSyscaf);
  const Colores: any[] = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark"
  ];
  
  const [Adicionales, setAdicionales] = useState<any>({
    "activo": "1",
    "esindicadorST": "false",
    "esindicadorSoporte": "false",
    "esindicadorAsignado": "false",
  });
  const [Categorias, setCategorias] = useState<any[]>([]);
  const [CategoriaSeleccionada, setCategoriaSeleccionada] = useState<any>("");
  const [tipo, settipo] = useState("");
  const [valor, setvalor] = useState("");
  const [label, setlabel] = useState("");
  const [color, setcolor] = useState("");
  const [Ambar, setAmbar] = useState<any[]>([]);
  const [showModal, setshowModal] = useState(false);
  const [lstFlujos, setlstFlujos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose2 = () => {
    settituloModalParametrizacion('');
    settipo("");
    setlabel("");
    setvalor("");
    setshowModal(false);
  };

  const showModals = () => {
    settituloModalParametrizacion('Editar estado de requerimientos')
    setshowModal(true);
  }

  const [tituloModalParametrizacion, settituloModalParametrizacion] = useState('');
  const [Data, setData] = useState<any[]>([]);

  //table state
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });


  const [rowCount, setRowCount] = useState(0);
  const Sigla = "OERS";
  let listadoCampos: MRT_ColumnDef<any>[] =

    [
      {
        accessorKey: 'valor',
        header: 'ID',
        size: 100,
        sortDescFirst: false,
      },
      {
        accessorKey: 'tipo',
        header: 'Tipo',
        size: 100,
      },
      {
        accessorKey: 'label',
        header: 'Etiqueta',
        size: 100
      },
      {

        header: 'Max Verde',
        size: 100,
        Cell({ cell, column, row, table, }) {
          let i = (row.original.MinAmber == null || row.original.MinAmber == undefined ||row.original.MinAmber == 0  ?  "-"  : parseInt(row.original.MinAmber) - 1)
          return (i)
        },
      },
      {
        accessorKey: 'MinAmber',
        header: 'Min Ambar',
        size: 100,
        Cell({ cell, column, row, table, }) {
          let i = (row.original.MinAmber == null || row.original.MinAmber == undefined  || row.original.MinAmber == 0 ?  "-"  : row.original.MinAmber);
          return (i);
        },
      },
      {
        accessorKey: 'MaxAmber',
        header: 'Max Ambar',
        size: 100,
        Cell({ cell, column, row, table, }) {
          let i = (row.original.MaxAmber == null || row.original.MaxAmber == undefined || row.original.MaxAmber == 0 ? "-" : row.original.MaxAmber);
          return (i);
        },
      },
      {

        header: 'Min Rojo',
        size: 100,
        Cell({ cell, column, row, table, }) {
          let i = (row.original.MaxAmber == null || row.original.MaxAmber == undefined  || row.original.MaxAmber == 0 ?  "-" : parseInt(row.original.MaxAmber) + 1)
          return (i)
        },
      },
      {
        accessorKey: 'flujo',
        header: 'Flujo',
        Cell({ cell, column, row, table, }) {
          let ret = Data.filter((val: any) => {
            if (row.original.flujo != undefined) {
              return (row.original.flujo.includes(parseInt(val.valor)) ?? val);
            }
          }).map((e: any) => `${e.tipo} - ${e.label}`).join().replaceAll(",", ", ");
          return <>{ret}</>
        },
        size: 100
      }
    ];
  useEffect(() => {
    setLoading(true);
    getConfiguracion(Sigla).then((response) => {
      if (response.data.length > 0) {
        JSON.parse(response.data[0].Configuracion) ? setData(JSON.parse(response.data[0].Configuracion).sort((a:any, b:any) => {
          return a.tipo.localeCompare(b.tipo);
        }) as any[])
          : setData([]);
        setCategorias(categoriasEstados(JSON.parse(response.data[0].Configuracion).sort()));
        setLoading(false);
      }
    }).catch(() =>{
      setLoading(false);
    });

  }, []);

  function Selecttipo() {
    return (
      <Form.Select className=" mb-3 " name="tipo" value={tipo} onChange={(e) => {
        // buscamos el objeto completo para tenerlo en el sistema


        settipo(e.currentTarget.value as any);
      }}>
        <option value={0}>Selecione tipo</option>
        <option value={'Admin'}>Admin</option>
        <option value={'Soporte'}>Soporte</option>
        <option value={'ST'}>ST</option>


      </Form.Select>
    );
  }

  function SelectFlujo() {
    return (
      <Form.Select className=" mb-3 " name="tipo" onChange={(e) => {
        // buscamos el objeto completo para tenerlo en el sistema

        //validar con yuli si se puede obtener el key desde aquí                 

        let fls = Data.filter((val: any) => {
          return (val.valor == e.currentTarget.value)
        })
        let fl = [...lstFlujos];
        let found = fl.filter((v: any) => {
          return (v.valor == e.currentTarget.value)
        });
        if (found.length > 0)
          errorDialog("<i>Ya existe el estado<i/>", "");
        else
          fl.push(fls[0]);

        setlstFlujos(fl);

      }}>
        <option value={0}>Selecione flujo</option>

        {(JSON.parse(JSON.stringify(Data))).map((cli: any, index: any) => {

          return (
            <option key={index} value={cli.valor}>{cli.tipo}-{cli.label}</option>
          );
        })}

      </Form.Select>
    );
  }

  const ColoresSelect = () => {
    return (
      <Form.Select className=" mb-3 " name="tipo" value={color} onChange={(e) => {
        setcolor(e.currentTarget.value as any);
      }}>
        <option value={"null"}>Selecione color</option>

        {(JSON.parse(JSON.stringify(Colores))).map((element: any, index: any) => {

          return (
            <option className={`form-control badge bg-${element}`} key={index} value={element
            }>
              {element}
            </option>
          );
        })}

      </Form.Select>
    )
  }

  const CategoriaSelect = () => {
    return (
      <Form.Select className=" mb-3 " name="tipo" value={CategoriaSeleccionada} onChange={(e) => {
        setCategoriaSeleccionada(e.currentTarget.value as any);
      }}>
        <option value={"null"}>Selecione tipo estado</option>
        {Categorias.map((element: any) => {
          return (
            <option className={""} key={element} value={element
            }>
              {element}
            </option>
          );
        })}

      </Form.Select>
    )
  }
  const modalSetParametrizacion = (row: any) => {
    setlstFlujos(Data.filter((val: any) => {
      if (row.flujo != undefined) {
        return (row.flujo.includes(parseInt(val.valor)) ?? val);
      }
    }));
    let am = [...Ambar];
    am[0] = ((row.MinAmber == undefined || row.MinAmber == null ? 0 : row.MinAmber));
    am[1] = ((row.MaxAmber == undefined || row.MaxAmber == null ? 0 : row.MaxAmber));
    setAmbar(am);
    settipo(row.tipo);
    setlabel(row.label);
    setvalor(row.valor);
    setcolor(row.color);
    setCategoriaSeleccionada(row.tipoestado);
    setAdicionales({
      "activo": row.activo,
      "esindicadorST": row.esindicadorST,
      "esindicadorSoporte": row.esindicadorSoporte,
      "esindicadorAsignado": row.esindicadorAsignado,
    })
    showModals();
  }

  const Guardar = (tipoModificacion:any, labelEditar?: any) =>{
    setLoading(true);
    let parametrosRequerimientos: any = {};
        let movimientos: any = {};
        let mensaje: any = "";
        let tipoMovimiento: any = "";
        let conf:any[] = Data;
        
        if(tipoModificacion == "2" || tipoModificacion == "3"){
          conf = Data.filter(lis => lis.label != (tipoModificacion == "2" ? label : labelEditar));
        }
    
        if (tipoModificacion == "1") {
          mensaje = "Se agrega nueva configuración";
          tipoMovimiento = "Creación";
        }
        else if (tipoModificacion == "2") {
          mensaje = "Se edita configuración";
          tipoMovimiento = "Edición";
        }
        else {
          mensaje = "Se elimina configuración";
          tipoMovimiento = "Eliminacion";
        }
        let flujo = lstFlujos.map((val: any) => parseInt(val.valor));
        const activo = Adicionales.activo
        const esindicadorST = Adicionales.esindicadorST;
        const esindicadorSoporte = Adicionales.esindicadorSoporte;
        const esindicadorAsignado = Adicionales.esindicadorAsignado;
        const tipoestado = CategoriaSeleccionada;
        let MinAmber = Ambar[0];
        let MaxAmber = Ambar[1];
        parametrosRequerimientos = {
          tipo,
          label,
          valor,
          flujo,
          color,
          MinAmber,
          MaxAmber,
          activo,
          esindicadorST,
          esindicadorSoporte,
          esindicadorAsignado,
          tipoestado
        };
    
    
        movimientos = {
          fecha: FechaServidor(),
          usuario: model.Nombres,
          tipo: tipoMovimiento,
          mensaje,
          movimiento : parametrosRequerimientos
        };
        if (tipoModificacion == "1" || tipoModificacion == "2")
          conf.push(parametrosRequerimientos);
        confirmarDialog(() => {
          setConfiguracion(Sigla, JSON.stringify(conf), '[' + JSON.stringify(movimientos) + ']', tipoModificacion).then((response) => {
            successDialog("Operación Éxitosa", "");
            setData(JSON.parse(JSON.stringify(conf)) as any[]);
            settipo("");
            setlabel("");
            setvalor("");
            setAmbar([0, 0]);
            setAdicionales({
              "activo": "1",
              "esindicadorST": "false",
              "esindicadorSoporte": "false",
              "esindicadorAsignado": "false",
            })
            handleClose2();
            setLoading(false);
          }).catch((error) => {
            setLoading(false);
            errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
          });
          // }
    
        }, tipoModificacion == "1" ? `Esta seguro que desea agregar la configuracion` : tipoModificacion == "2" ? `Esta seguro de modificar la configurción`
          : `Esta seguro de eliminar la configurción`
          , "Guardar");

  }
  const ValidarEstadoCritico = (Data:any[], Estado:any) =>{
    let dat = Data.filter((lis:any) => {
      return lis.label == Estado;
    });
    if(dat[0].flujo.length>0){
      errorDialog("Error el estado tiene uno o varios estados asignados","");
      return false;
    }
    
    
    return true;
  }
  const setRequerimientos = (tipoModificacion: any, labelEditar?: any) => {
    if(tipoModificacion == "3"){
      if(ValidarEstadoCritico(Data,labelEditar)){
          Guardar(tipoModificacion,labelEditar);
      }
    }
    else
      Guardar(tipoModificacion,labelEditar )
  };

  const del = (e: any) => {

    let fl = [...lstFlujos];
    let fls = fl.findIndex((val: any) => {
      return (val.label == e.label)
    });
    fl.splice(fls, 1);
    setlstFlujos(fl);
  }
  const InsertarFlujos = () => {
    return (
    
      <div className="container card shadow-sm">
        <div className="row">
          <div className="col-4">
            <label className="control-label label label-sm  m-3" htmlFor="requerimientos" style={{ fontWeight: 'bold' }}>Categoria o tipo estado:</label>
            <CategoriaSelect />
          </div>
          <div className="col-4">
            <label className="control-label label label-sm  m-3" htmlFor="color" style={{ fontWeight: 'bold' }}>Color:</label>
            <ColoresSelect />
          </div>

          <div className="col-4">
            <label className="control-label label label-sm  m-3" htmlFor="requerimientos" style={{ fontWeight: 'bold' }}>Flujo:</label>
            <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Flujo"].title, Tooltips["Flujo"].content)} >
              <span><i className="bi-app-indicator text-danger"></i></span>
            </HtmlTooltipStyled>
            <SelectFlujo />
          </div>
          <div className="col-12">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Nombre</th>
                  <th>Color</th>
                  <th>Configurar</th>
                </tr>
              </thead>
              <tbody>
                {(lstFlujos.length == 0 ? <tr>
                  <td style={{ textAlign: "center" }} colSpan={4}>Sin flujo de estados</td>
                </tr> : <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>)}
                {(lstFlujos.length > 0) && (lstFlujos.map((m: any) => {
                  return <tr key={m.tipo}>
                    <td>{m.tipo}</td>
                    <td>{m.label}</td>
                    <td><span className={`badge bg-${(m.color == undefined ? "dark" : m.color)}`}>{(m.color == undefined ? "Sin identificar" : m.color)}</span></td>
                    <td> <span className="pe-auto " style={{ cursor: "pointer" }} onClick={(e: any) => {
                      del(m);
                    }}><Delete className="text-danger" /></span> </td>
                  </tr>
                }))}
              </tbody>
            </Table>
          </div>
        </div></div>)
  }
  
  
  return (
    <>
     
      <Modal
        show={show}
        onHide={handleClose}
        fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row  w-100">
            <div className="col-6">

            </div>
            <div className="col-6 float-end"  style={{textAlign: 'right'}}>
              <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Nuevo"].title, Tooltips["Nuevo"].content)} >
                  <button className="btn btn-sm btn-primary" onClick={(e: any) => {
                    setshowModal(true);
                    setlstFlujos([]);
                    settituloModalParametrizacion("Nuevo estado");
                    setdisabled(false);

                  }}><i className="bi-plus"></i>Nuevo </button>
              </HtmlTooltipStyled>
            </div>
          </div>
          <div className="mt-5">
            <MaterialReactTable
              localization={MRT_Localization_ES}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 120,
                },
              }}
              columns={listadoCampos}
              data={Data}
              // editingMode="modal" //default         
              enableTopToolbar={false}
              enableColumnOrdering
              enableEditing
              enablePagination={false}
              muiTableBodyCellProps={({ row, cell }) => ({
                sx: {
                  backgroundColor:

                    cell.column.id == 'Min Rojo' && (cell.row.original.MinAmber != null || cell.row.original.MinAmber != undefined ) && (cell.row.original.MinAmber != 0 ) ? 'rgba(248, 215, 218, 1)' :
                      cell.column.id == 'Max Verde' && (cell.row.original.MaxAmber != null || cell.row.original.MaxAmber != undefined ) && (cell.row.original.MaxAmber != 0) ? 'rgba(212, 237, 218, 1)' :
                        cell.column.id == 'MinAmber' && (cell.row.original.MinAmber != null || cell.row.original.MinAmber != undefined ) && (cell.row.original.MinAmber != 0)  || cell.column.id == 'MaxAmber' &&
                        (cell.row.original.MaxAmber != null || cell.row.original.MaxAmber != undefined) && (cell.row.original.MaxAmber != 0)  ? 'rgba(255, 243, 205, 1)' : '#ffffff'
                }

              })}
           
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              rowCount={rowCount}

              state={{
                columnFilters,
                globalFilter,
                isLoading:loading,
                pagination,
             
                sorting,
              }}
              initialState= {
                {
                  density: 'compact',
                  sorting: [
                    { id: 'tipo', desc: false }, //then sort by city in descending order by default
                  ],
                }
               
              }
              
              // initialState={{ density: 'compact',  sorting: [{id: 'tipo',  desc: false, }]}}
              renderRowActions={({ row, table }) => (
                <>
                  <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Modificar"].title, Tooltips["Modificar"].content)} >
                        <IconButton hidden={(row.original.Requerido == "true") && (model.perfil !== Perfiles.SuperAdmin) }
                              onClick={() => {
                      
                                  modalSetParametrizacion(row.original);
                                  setdisabled(true);
                           
                              }
                            }
                            >
                        <Edit className="text-success" />
                        </IconButton>
                    </HtmlTooltipStyled>
                     <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Eliminar"].title, Tooltips["Eliminar"].content)} >
                      <IconButton hidden={(row.original.Requerido == "true") && (model.perfil !== Perfiles.SuperAdmin) }
                        onClick={() => {                          
                            setRequerimientos("3", row.original.label);}}
                      >
                        <Delete className="text-danger" />
                      </IconButton>
                      </HtmlTooltipStyled>
                  </Box>
                </>
              )
              }
             
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Cerrar"].title, Tooltips["Cerrar"].content)} >
            <Button className="bt btn-sm" type="button" variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </HtmlTooltipStyled>
        </Modal.Footer>
      </Modal>
  
      <Modal className="border border-primary"
        show={showModal}
        onHide={handleClose2}
        size="lg">
        <Modal.Header className="fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-between">
          <Modal.Title>{tituloModalParametrizacion}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 w-100">
              <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Ayudageneral"].title, Tooltips["Ayudageneral"].content)} >
                <i className="text-danger  text-right  float-end fw-bolder bi-app-indicator"></i>
              </HtmlTooltipStyled>
            </div>
            <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
              <label className="control-label label label-sm  m-3" style={{ fontWeight: 'bold' }}>Tipo:</label>
              <Selecttipo />
            </div>
            <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
              <label className="control-label label label-sm  m-3" htmlFor="label" style={{ fontWeight: 'bold' }}>Etiqueta:</label>
              <input disabled={disabled} className="form-control  input input-sm mb-3" id="label" placeholder="Ingrese la etiqueta del estado" type="text" value={label} onChange={(e) => { setlabel(e.target.value); }} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
              <label className="control-label label label-sm  m-3" htmlFor="valor" style={{ fontWeight: 'bold' }}>Valor:</label>
              <input disabled={disabled} className="form-control  input input-sm mb-3" id="valor" placeholder="Ingrese Valor" type="text" value={valor} onChange={(e) => { setvalor(e.target.value); }} />
            </div>
            <div className="col-3">
              <label className="control-label label label-sm  m-3" htmlFor="MinAmbar" style={{ fontWeight: 'bold' }}>Ambar Min:</label>
              <input className="form-control  input input-sm mb-3" id="MinAmbar" placeholder="Ingrese Min" type="number" value={Ambar[0]} onChange={(e) => {
                let am = [...Ambar];
                am[0] = parseInt(e.target.value);
                setAmbar(am);
              }} />
            </div>
            <div className="col-3">
              <label className="control-label label label-sm  m-3" htmlFor="MaxAmbar" style={{ fontWeight: 'bold' }}>Ambar Max:</label>
              <input className="form-control  input input-sm mb-3" id="MaxAmbar" placeholder="Ingrese Max" type="number" value={Ambar[1]} onChange={(e) => {
                let am = [...Ambar];
                am[1] = parseInt(e.target.value);
                setAmbar(am);
              }} />
            </div>
          </div>
          <InsertarFlujos />
          <div className="container card shadow-sm mt-5" style={{display:(model.perfil === Perfiles.SuperAdmin ? 'inline':'none')}}>
            <div className="row">
              <div className="col-3">
                <div className="mb-0">
                  <label className="control-label label label-sm" htmlFor="activo" style={{ fontWeight: 'bold', fontSize: '11px' }}>Estado:</label>
                </div>
                <input className="m-3" type="checkbox" id="activo" defaultChecked={(Adicionales.activo == "1" ? true : false)} value={(Adicionales.activo == "1" ? "true" : "false")} onChange={(e) => {
                  let da = { ...Adicionales };
                  da.activo = (e.currentTarget.checked ? "1" : "0");
                  setAdicionales(da);
                }} />
              </div>
              <div className="col-3">
                <div className="mb-0">
                  <label className="control-label label label-sm" htmlFor="esindicadorST" style={{ fontWeight: 'bold', fontSize: '11px' }}>¿Indicador St Pro?:</label>
                </div>
                <input className="mb-3" type="checkbox" id="esindicadorST" defaultChecked={(Adicionales.esindicadorST == "true" ? true : false)} value={Adicionales.esindicadorST} onChange={(e) => {
                  let da = { ...Adicionales };
                  da.esindicadorST = String(e.currentTarget.checked);
                  setAdicionales(da);
                }} />
              </div>
              <div className="col-3">
                <div className="mb-0">
                  <label className="control-label label label-sm" htmlFor="esindicadorAsignado" style={{ fontWeight: 'bold', fontSize: '11px' }}>¿Indicador de asignado?:</label>
                </div>
                <input className="mb-3" type="checkbox" id="esindicadorAsignado" defaultChecked={(Adicionales.esindicadorAsignado == "true" ? true : false)} value={Adicionales.esindicadorAsignado} onChange={(e) => {
                  let da = { ...Adicionales };
                  da.esindicadorAsignado = String(e.currentTarget.checked);
                  setAdicionales(da);
                }} />
              </div>
              <div className="col-3">
                <div className="mb-0">
                  <label className="control-label label label-sm" htmlFor="esindicadorSoporte" style={{ fontWeight: 'bold', fontSize: '11px' }}>¿Indicador de soporte?:</label>
                </div>
                <input className="mb-3" type="checkbox" id="esindicadorSoporte" defaultChecked={(Adicionales.esindicadorSoporte == "true" ? true : false)} value={Adicionales.esindicadorSoporte} onChange={(e) => {
                  let da = { ...Adicionales };
                  da.esindicadorSoporte = String(e.currentTarget.checked);
                  setAdicionales(da);
                }} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Guardar"].title, Tooltips["Guardar"].content)} >
            <Button type="button" className="bt btn-sm" variant="primary" onClick={() => {
              (tituloModalParametrizacion == "Nuevo estado" ? setRequerimientos("1", null) : setRequerimientos("2", null));
            }}>
              Guardar
            </Button>
          </HtmlTooltipStyled>

          <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Cerrar"].title, Tooltips["Cerrar"].content)} >
            <Button type="button" className="bt btn-sm" variant="secondary" onClick={handleClose2}>
              Cerrar
            </Button>
          </HtmlTooltipStyled>

        </Modal.Footer>
      </Modal>
    </>
  );

}
