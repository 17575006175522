import { Button, Modal } from "react-bootstrap-v5";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../_start/helpers/components/HtlmTooltip";
import { Tooltips } from "../../data/Estados";
import { errorDialog } from "../../../../../../_start/helpers/components/ConfirmDialog";


type Props = {
    show: boolean;
    setshow: any;
    Data: any;
    setData: any
    loader: any;
    setloader: any,
    Guardar: (data: any) => void;
    DatosCompletos:any
};

export const ModalEstado: React.FC<Props> = ({ show, setshow, Data, setData, Guardar, DatosCompletos }) => {
   
    const ValidarOrden =  (Orden:any) =>{
       return  DatosCompletos.filter((val:any) =>{
            return (val.Configuracion !== null && JSON.parse(val.Configuracion)[0].Orden == Orden)
        })
    };
    const GuardarFinal = () => {
        let dta = { ...Data };
        dta.Configuracion = JSON.stringify(dta.Configuracion);
        dta.EstadoIdS = String(dta.EstadoIdS);
        dta.TipoIdS = String(dta.TipoIdS)
        Guardar(dta);
    }

    return (
        <>
            <Modal className="border border-primary"
                show={show}
                onHide={setshow}
                size="lg">
                <Modal.Header className="fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-between">
                    <Modal.Title>{Data.ModalTitulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 w-100">
                            <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Ayudageneral"].title, Tooltips["Ayudageneral"].content)} >
                                <i className="text-danger  text-right  float-end fw-bolder bi-app-indicator"></i>
                            </HtmlTooltipStyled>
                        </div>
                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                            <label className="control-label label label-sm  m-3" htmlFor="label" style={{ fontWeight: 'bold' }}>Estado:</label>
                            <input className="form-control  input input-sm mb-3" id="label" placeholder="Ingrese la etiqueta del estado" type="text" value={Data.Estado} onChange={(e) => {
                                let est = { ...Data };
                                est.Estado = e.currentTarget.value;
                                setData(est);
                            }} />
                        </div>
                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                            <label className="control-label label label-sm  m-3" htmlFor="icono" style={{ fontWeight: 'bold' }}>Icono:
                            <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Icono"].title, Tooltips["Icono"].content)} >
                                <i className="text-danger  float-end fw-bolder  bi-aspect-ratio"></i>
                            </HtmlTooltipStyled>
                                </label> 
                            <div className="input-group mb-3">
                                <span className="input-group-text input-group-sm" style={{ height: "43px" }} id="basic-addon1"><i className={`${Data.Icono}`}></i></span>
                                <input className="form-control  input input-sm mb-3" id="icono" placeholder="Ingrese texto icono" type="text" value={Data.Icono} onChange={(e) => {
                                    let est = { ...Data };
                                    est.Icono = e.currentTarget.value;
                                    setData(est);
                                }} />
                            </div>
                        </div>
                    </div>
                    <h6>Configuración</h6>
                    <div className="container card shadow-sm mt-3">
                        <div className="row">
                            <div className="col-3">
                                <label className="control-label label label-sm  m-3" style={{ fontWeight: 'bold' }}>Orden:</label>
                                <input className="form-control  input input-sm mb-3" id={Data.Configuracion[0].Orden} placeholder={`${Data.Configuracion[0].Orden}`} type="text" value={Data.Configuracion[0].Orden} onChange={(e) => {
                                   
                                   let a = ValidarOrden( e.currentTarget.value);
                                   console.log(a);
                                   if(a.length>0){
                                        errorDialog("El orden ya existe, no se pueden repetir.", "");
                                   }else{
                                    let est = { ...Data };
                                    est.Configuracion[0].Orden = e.currentTarget.value;
                                    setData(est);
                                   }
                                }} />
                            </div>
                        </div>
                    </div>

                    <div className="container card shadow-sm mt-3">
                        <div className="row ">
                            <div className="col-3">
                                <div>
                                    <label className="control-label label label-sm  m-3" htmlFor="Semaforizado" style={{ fontWeight: 'bold' }}>Semaforizado:</label>
                                </div>
                                <input id="Semaforizado" type="checkbox" defaultChecked={Data.Configuracion[0].Semaforizado} value={Data.Configuracion[0].Semaforizado} onChange={(e) => {
                                    let est = { ...Data };
                                    est.Configuracion[0].Semaforizado = e.currentTarget.checked;
                                    setData(est);
                                }} />
                            </div>
                            <div style={{ display: (Data.Configuracion[0].Semaforizado ? 'inline' : 'none') }} className="col-3">
                                <label className="control-label label label-sm  m-3" style={{ fontWeight: 'bold' }}>Amber Min:</label>
                                <input className="form-control  input input-sm mb-3" id="MinAmbar" placeholder="Ingrese Min" type="number" value={Data.Configuracion[0].MinAmber} onChange={(e) => {
                                    let est = { ...Data };
                                    est.Configuracion[0].MinAmber = e.currentTarget.value;
                                    setData(est); 
                                }} />
                            </div>
                            <div style={{ display: (Data.Configuracion[0].Semaforizado ? 'inline' : 'none') }} className="col-3">
                                <label className="control-label label label-sm  m-3" style={{ fontWeight: 'bold' }}>Amber Max:</label>
                                <input className="form-control  input input-sm mb-3" id="MaxAmbar" placeholder="Ingrese Min" type="number" value={Data.Configuracion[0].MaxAmber} onChange={(e) => {
                                       let est = { ...Data };
                                       est.Configuracion[0].MaxAmber = e.currentTarget.value;
                                       setData(est); 
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="container card shadow-sm mt-3">
                        <div className="row">
                            <div className="col-3">
                                <div>
                                    <label className="control-label label label-sm  m-3"  htmlFor={'Posponer'} style={{ fontWeight: 'bold' }}>Posponer:</label>
                                </div>
                                <input id="Posponer" defaultChecked={Data.Configuracion[0].PermitePosponer} placeholder={`${Data.Configuracion[0].PermitePosponer}`} type="checkbox" value={Data.Configuracion[0].Orden} onChange={(e) => {
                                    let est = { ...Data };
                                    est.Configuracion[0].PermitePosponer = e.currentTarget.checked;
                                    setData(est);
                                }} />
                            </div>
                            <div style={{ display: (Data.Configuracion[0].PermitePosponer ? 'inline' : 'none') }} className="col-3">
                                <label className="control-label label label-sm  m-3" htmlFor="CantidadMaxDias" style={{ fontWeight: 'bold' }}>Dias maximos:</label>
                                <input className="form-control  input input-sm mb-3" id="CantidadMaxDias" placeholder="Cantidad maxima" type="number" value={Data.Configuracion[0].CantidadMax} onChange={(e) => {
                                     let est = { ...Data };
                                     est.Configuracion[0].CantidadMax = e.currentTarget.value;
                                     setData(est); 
                                }} />
                            </div>
                            <div style={{ display: (Data.Configuracion[0].PermitePosponer ? 'inline' : 'none') }} className="col-3">
                                <label className="control-label label label-sm  m-3" htmlFor="CantidadDias" style={{ fontWeight: 'bold' }}>Cantidad dias:</label>
                                <input className="form-control  input input-sm mb-3" id="CantidadDias" placeholder="Cantidad dias" type="number" value={Data.Configuracion[0].Cantidad} onChange={(e) => {
                                    
                                    let est = { ...Data };
                                    if(parseInt(e.currentTarget.value) > parseInt(est.Configuracion[0].CantidadMax === undefined ? 0:est.Configuracion[0].CantidadMax)){
                                        errorDialog("La cantidad de dias no puede ser mayor a la cantidad maxima.", "");
                                    }else{
                                        est.Configuracion[0].Cantidad = e.currentTarget.value;
                                        setData(est); 
                                    }
                                    
                                }} />
                            </div>
                            <div style={{ display: (Data.Configuracion[0].PermitePosponer ? 'inline' : 'none') }} className="col-3">
                                <label className="control-label label label-sm  m-3" htmlFor="Veces" style={{ fontWeight: 'bold' }}>Veces:</label>
                                <input className="form-control  input input-sm mb-3" id="Veces" placeholder="Cantidad veces" type="number" value={Data.Configuracion[0].Veces} onChange={(e) => {
                                     let est = { ...Data };
                                     est.Configuracion[0].Veces = e.currentTarget.value;
                                     setData(est); 
                                }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Guardar"].title, Tooltips["Guardar"].content)} >
                        <Button type="button" className="bt btn-sm" variant="primary" onClick={GuardarFinal}>
                            Guardar
                        </Button>
                    </HtmlTooltipStyled>

                    <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Cerrar"].title, Tooltips["Cerrar"].content)} >
                        <Button type="button" className="bt btn-sm" variant="secondary" onClick={(e: any) => {
                            setshow(false);
                        }}>
                            Cerrar
                        </Button>
                    </HtmlTooltipStyled>

                </Modal.Footer>
            </Modal>
        </>
    )
}