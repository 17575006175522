import { useEffect, useState } from "react";
import { PageTitle } from "../../../../_start/layout/core";
import { AlertasHistorical } from "./components/AlertasHistorical";
import { ClientesFatiga } from "./models/EventosActivos";
import { Button, Collapse, Form } from "react-bootstrap-v5";
import { GetClientesFatiga } from "./data/dashBoardData";
import { errorDialog } from "../../../../_start/helpers/components/ConfirmDialog";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import { ranges } from "../../../../_start/helpers/Constants";
import { FatigueHistoricalProvider, DataAlertasHistorical } from "./core/providerHistorical";
import { FatigueIndicadoresProvider, GetIndicadores } from "./core/providerIndicadores";
import IndicadoresHistorical from "./components/Indicadores/indicadores";
export default function AlertasHistoricas() {
    //children
    const [children, setchildren] = useState<any>({});
    //Clientes
    const [clienteSeleccionado, setclienteSeleccionado] = useState<ClientesFatiga>();
    const [lstClientes, setLstClientes] = useState<ClientesFatiga[]>([]);
    const [fechaInicial, setfechaInicial] = useState(new Date());
    const [fechaFinal, setfechaFinal] = useState(new Date());
    const [ClienteId, setClienteId] = useState<any>();
    const [textIndicador, settextIndicador] = useState("Mostrar")
    const [isVisible, initHs] = useState(false)

    const invokeCollapse = () => {
        settextIndicador(!isVisible ? "Ocultar" : "Mostrar")
        return initHs(!isVisible)
    }
    //filtros
    useEffect(() => {

        let clienteIsd: any = clienteSeleccionado?.ClienteIdS

        setClienteId(clienteIsd != undefined ? clienteIsd : 0);

    }, [clienteSeleccionado])

// traemos los datos iniciales como los clientes
// seteamos variables iniciales 
    useEffect(() => {

        GetClientesFatiga().then(
            (response) => {
                setLstClientes(response.data);
            }

        ).catch((error) => {
            errorDialog("Consultar Clientes", "Error al consultar clientes, no se puede desplegar informacion");
        })

        setClienteId(0);
        setfechaInicial(moment().startOf('month').toDate());
        setfechaFinal(moment().endOf('month').endOf('day').toDate());

    }, [])


    const getAlertasHistoricas = () => {

        let Children: any = {};

        Children = {
            fechaInicial,
            fechaFinal,
            ClienteId: ClienteId.toString()
        };


        setchildren(Children);
    }



    function CargaListadoClientes() {
        return (
            <Form.Select className=" mb-3 " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let cliente = lstClientes.filter((value, index) => {
                    return value.ClienteIdS === Number.parseInt(e.currentTarget.value)

                })
                if (lstClientes)
                    setclienteSeleccionado(cliente[0])
            }} defaultValue={clienteSeleccionado?.ClienteIdS} >
                <option>Seleccione</option>
                {
                    lstClientes.map((element) => {

                        return (<option key={`listadocliente_${element.ClienteIdS}`} value={element.ClienteIdS}>{element.clienteNombre}</option>)
                    })
                }
            </Form.Select>
        );
    }

    //Datetime picker
    function DatePicker() {
        return (
            <DateRangePicker className="mb-3 mt-1" format="dd/MM/yyyy" ranges={ranges} value={[fechaInicial as Date, fechaFinal as Date]}
                onChange={(value, e) => {
                    if (value !== null) {
                        setfechaInicial(value[0]);
                        setfechaFinal(value[1])
                    }
                }}
            />
        )
    }

    return (
        <>
            <FatigueHistoricalProvider>
                <PageTitle >Alertas e Indicadores Históricas </PageTitle>
                <DataAlertasHistorical>{children}</DataAlertasHistorical>
                <div className="d-flex justify-content-between">
                    <div className="mx-auto">
                        <div className="ms-3 text-center">
                            <h3 className="mb-0">Alertas e Indicadores Históricos</h3>
                            <span className="text-muted m-3">Permite visualizar el historial de la alertas en un rango de fechas.</span>
                        </div>
                    </div>
                </div>
                <div className="container-fluid card card-rounded bg-transparent mt-1" style={{ width: '100%' }}   >
                    <div className="row  col-sm-12 col-md-12 col-xs-12 
                    rounded border  mt-1 mb-2 shadow-sm bg-secondary text-primary" style={{ width: '100%' }}  >


                        <div className="d-inline-flex col-xl-5 mt-2">
                            <h6 className=" mt-3 mx-2">Cliente:</h6>
                            <CargaListadoClientes />
                        </div>

                        <div className="d-inline-flex col-xl-5 mt-2">
                            <h6 className=" mt-3 mx-2">Fechas:</h6>
                            <DatePicker />
                        </div>
                        <div className="d-inline-flex col-xl-2 ">
                            <Button className="m-3  btn btn-sm btn-primary" onClick={() => { getAlertasHistoricas() }}><i className="bi-search"></i></Button>
                            <Button variant="success" className="m-3  btn btn-sm" onClick={invokeCollapse}>
                            {`${textIndicador} Indicadores`}
                        </Button>
                        </div>
                       
                    </div>

                    <Collapse in={isVisible}>
                        <div className="row g-0 g-xl-5 g-xxl-8 rounded border  mt-1 mb-2 shadow-sm bg-secondary text-primary">                        
                        <FatigueIndicadoresProvider>
                            <GetIndicadores>{children}</GetIndicadores>
                            <IndicadoresHistorical />
                        </FatigueIndicadoresProvider>
                        </div>
                    </Collapse>
                    <div className="row g-0 g-xl-5 g-xxl-8 rounded border  mt-1 mb-2 shadow-sm bg-secondary text-primary">
                        <div className="col-xl-12">
                            <AlertasHistorical className="card-stretch mb-5 mb-xxl-8"></AlertasHistorical>
                        </div>
                    </div>
                </div>
            </FatigueHistoricalProvider>
        </>
    )
}