import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { useDataDashBoard } from "../../../core/DashboardCore";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { CategoriasPorColorTx, ColorRows } from "../../../../Tx/data/Reporte";
import {  CamposSenial, FiltroDashBoardBasic } from "../../../data/dashboard";

export const ModalFallas: React.FC<{ estado: string, show: boolean, setShow: any }> = ({ estado, show, setShow }) => {

    const { lstFallaSeniales, AdminSeleccionado,    ClienteSeleccionado   } = useDataDashBoard();

    const [loader, setLoader] = useState(false);
    const [datalstFallaSeniales, setDatalstFallaSeniales] = useState<any[]>([]);
    const [TxUltimaActualizacion, setTxUltimaActualizacion] = useState<string>("Sin Información");


    useEffect(() => {

      //  setTxUltimaActualizacion(moment(lstVehiculosSinTx[0].fecha).format(formatSimpleJsonColombia));
        const data =FiltroDashBoardBasic.getFiltroAdminCliente(lstFallaSeniales,
            AdminSeleccionado.UsuarioId, ClienteSeleccionado.ClienteId
        ).filter(f => f.Falla === estado);

        setDatalstFallaSeniales(data);
        // traemos las caracteristicas de la interfax sin tx para reutilizar datos
     
    }, [lstFallaSeniales]);
    return (
        <Modal show={show} onHide={setShow} size="xl">
            <Modal.Header closeButton>
                <Modal.Title className="text-center fs-2" style={{ width: '100%' }}>
                    <label className="control-label label labels-sm text-syscaf-azul">Listado Vehículos con Falla</label>            
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {(datalstFallaSeniales.length > 0) && (<div className="table-responsive">
                    <MaterialReactTable
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                             //   size: 100,
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: (theme) => ({
                                fontSize: 14,
                                fontStyle: 'bold',
                                color: 'rgb(27, 66, 94)'
                            }),
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                            sx: {
                                backgroundColor: ColorRows(row.original)
                            }
                        })}
                        columns={CamposSenial}
                        data={datalstFallaSeniales}
                        enableColumnOrdering
                        enableStickyHeader
                        enableDensityToggle={false}
                        enablePagination={false}
                        enableRowVirtualization
                        muiTableContainerProps={{
                            sx: { maxHeight: '350px' }, //give the table a max height
                        }}
                        initialState={
                            {
                              //  density: 'compact',
                                isLoading: loader
                            }
                        }
                    />
                </div>)}



            </Modal.Body>
        </Modal>)
};