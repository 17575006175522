import MaterialReactTable, { MRT_Cell, MRT_ColumnDef, MaterialReactTableProps } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Button } from "react-bootstrap-v5";
import { AutoComplete, InputGroup } from "rsuite";
import MemberIcon from '@rsuite/icons/Member';
import { GetCondiciones, GetListaClientes, GuardarCondiciones } from "../data/ReportesData";
import { useCallback, useEffect, useState } from "react";
import { ClienteDTO, InicioCliente } from "../../../../../_start/helpers/Models/ClienteDTO";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../_start/helpers/components/ConfirmDialog";
import { AxiosResponse } from "axios";
import { PageTitle } from "../../../../../_start/layout/core/PageData";
import BlockUi from "@availity/block-ui";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { Edit, Search } from "@mui/icons-material";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { Check } from "react-feather";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../setup";
import { EsPermitido, Operaciones, PermisosOpcion } from "../../../../../_start/helpers/Axios/CoreService";
import { UserModelSyscaf } from "../../../auth/models/UserModel";
import { useToaster, Notification } from "rsuite";
export default function Condiciones() {
    // informacion del usuario almacenado en el sistema
    const isAuthorized = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const permisosopcion = PermisosOpcion("Señales");
    const operacionesPermisos = Operaciones;
    const toaster = useToaster();
    // convertimos el modelo que viene como unknow a modelo de usuario sysaf para los datos
    const model = (isAuthorized as UserModelSyscaf);

    const [loader, setloader] = useState<boolean>(false);
    const [Clientes, setClientes] = useState<ClienteDTO[]>([InicioCliente]);
    const [LstClientes, setlstClientes] = useState<string[]>([]);
    const [ClienteSeleccionado, setClienteSeleccionado] = useState<ClienteDTO>(InicioCliente);
    const [Datos, setDatos] = useState<any[]>([]);
    //Para as tablas
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [edit, setEdit] = useState<boolean>(false);
    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    //Fin tablas
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({});

    interface CreateModalProps {
        columns: MRT_ColumnDef<any>[];
        onClose: () => void;
        onSubmit: (values: any) => void;
        open: boolean;
    }
    //consulta los clientes
    useEffect(
        () => {
            GetListaClientes().then((response: AxiosResponse<any>) => {
                setClientes(response.data);
                // setClienteSeleccionado(response.data[0]);
                let lstClientes = response.data.map((dat: any) => {
                    return dat.clienteNombre;
                })
                setlstClientes(lstClientes);
            }).catch((error) => {
                errorDialog("<i>Eror al consultar los clientes</i>", "")
            })
            return () => { };
        }, []
    )
    //Selecciona al cliente
    const SeleccionCliente = (e: any) => {
        let selected = Clientes.filter((val: any) => {
            return (val.clienteNombre == e)
        })
        setClienteSeleccionado(selected[0]);
    }
    //Carga los datos iniciales de las condiciones ademas de actualizar la info cada vez que se selecciona un cliente
    useEffect(() => {
        if (ClienteSeleccionado != undefined)
            ObtenerDatos();
        return () => { }
    }, [ClienteSeleccionado?.clienteIdS])
    //Consulta la informacion de las condiciones
    const ObtenerDatos = () => {
        let Cliente = (ClienteSeleccionado != undefined ? String(ClienteSeleccionado?.clienteIdS) : "0");
        setloader(true)
        setIsError(false)
        setIsLoading(true)
        setIsRefetching(true)
        GetCondiciones(Cliente).then((response: AxiosResponse<any>) => {
            setDatos(response.data);
            setRowCount(response.data.length);
            setIsLoading(false);
            setIsRefetching(false);
            setloader(false);
        }).catch(() => {
            setloader(false)
            setIsError(true);
            setIsLoading(false)
            setIsRefetching(false)
        })
    }
    //Listado de las columnas, metodos de cada columna
    let CamposTabla: MRT_ColumnDef<any>[] = [
        {
            accessorKey: 'clienteNombre',
            header: 'Cliente',
            Cell({ cell, column, row, table, }) {
                return (row.original.clienteNombre == null ? "- Todos -":row.original.clienteNombre)
            },
            size: 100
        },
        {
            accessorKey: 'Nombre',
            header: 'Nombre',
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
            }),
            size: 100
        },
        {
            accessorKey: 'Descripcion',
            header: 'Descripcion',
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
            }),
            size: 100
        },
        {
            accessorKey: 'Ocurrencias',
            header: 'Ocurrencias',
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
            }),
            size: 100
        },
        {
            accessorKey: 'Distancia',
            header: 'Distancia',
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
            }),
            size: 100
        },
        {
            accessorKey: 'Tiempo',
            header: 'Tiempo',
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
            }),
            size: 100
        },
        {
            accessorKey: 'Valor',
            header: 'Valor',
            enableEditing: true,
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
            }),
            size: 100
        },
        {
            accessorKey: 'EsActivo',
            header: 'Estado',
            size: 100,
            enableEditing: false,
            Edit: ({ cell, column, row, table, }) => {

            },
            Cell({ cell, column, row, table, }) {
                const value = (row.original != undefined) ? row.original.EsActivo : row;
                let label = (row.original.EsActivo) ? <span className="badge bg-success">Activo</span> : <span className="badge bg-danger">Inactivo</span>
                return <>{
                    label
                }</>
            }
        }
    ]

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<any>,
        ): MRT_ColumnDef<any>['muiTableBodyCellEditTextFieldProps'] => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
            };
        },
        [validationErrors],
    )
    const handleSaveRowEdits: MaterialReactTableProps<any>['onEditingRowSave'] =
        async ({ exitEditingMode, row, values }) => {

            if (!Object.keys(validationErrors).length) {
                confirmarDialog(() => {
                    //se envia al server
                    let data = { ...values }
                    data.CondicionId = String(row.original.CondicionId);
                    data.ClienteIds = String(row.original.ClienteIds == undefined || row.original.ClienteIds == null ? "" : row.original.ClienteIds);
                    Guardar(data);
                }, `¿Esta seguro que desea editar el registro?`, 'Guardar');
                exitEditingMode();
            }
        };

    //Cuando cancela
    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };
    //Funcion para guardar las condiciones
    const Guardar = (row: any) => {
        setloader(true);
        row.Clave = (row.Clave == null || row.Clave == undefined || row.Clave == "" ? "2" : row.Clave);
        //Insertar un objeto a guardar
        GuardarCondiciones(row).then(() => {
            toaster.push(message('success', "Condiciones", "Cambios guardados éxitosamente"), {
                placement: 'topCenter'
            });
            let Cambios = [...Datos];
            Cambios = Cambios.map((a: any) => {
                if (a.CondicionId == row.CondicionId)
                    a = row;
                return a;
            });
            setDatos(Cambios);
            setloader(false);
        }).catch(() => {
            toaster.push(message('error', "Condiciones", "Ha ocurrido un error al guardar"), {
                placement: 'topCenter'
            });
            setloader(false);
        }
        );
    }

    const CreateNewAccountModal = ({
        open,
        columns,
        onClose,
        onSubmit,
    }: CreateModalProps) => {
        const [values, setValues] = useState<any>(() =>
            columns.reduce((acc, column) => {
                acc[column.accessorKey ?? ''] = '';
                return acc;
            }, {} as any),
        );

        const handleSubmit = () => {
            //put your validation logic here
            onSubmit(values);
            onClose();
        };
        return (
            <Dialog open={open}>
                <DialogTitle textAlign="center">Create New Account</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                                gap: '1.5rem',
                            }}
                        >
                            {CamposTabla.map((column, index: any) => (
                                <TextField
                                    key={column.id}
                                    label={column.header}
                                    // name={column.accessorKey}
                                    value={index}
                                    onChange={(e) =>
                                        setValues({ ...values, [e.target.name]: e.target.value })
                                    }
                                />
                            ))}
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{ p: '1.25rem' }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button color="secondary" onClick={handleSubmit} variant="contained">
                        Create New Account
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }
    const CambiarEstado = (row: any) => {
        row.original.Clave = (row.original.Clave == null || row.original.Clave == undefined || row.original.Clave == "" ? "3" : row.original.Clave);
        row.original.EsActivo = (row.original.EsActivo ? false : true);
        row.original.CondicionId = String(row.original.CondicionId);
        confirmarDialog(() => {
            //Insertar un objeto a guardar
            GuardarCondiciones(row.original).then(() => {
                toaster.push(message('success', "Condiciones", "Cambios guardados éxitosamente"), {
                    placement: 'topCenter'
                });
                let Cambios = [...Datos];
                Cambios = Cambios.map((a: any) => {
                    if (a.CondicionId == row.original.CondicionId)
                        a = row.original;
                    return a;
                });
                setDatos(Cambios);
            }).catch(() => {
                toaster.push(message('error', "Condiciones", "Ha ocurrido un error al guardar"), {
                    placement: 'topCenter'
                });
            });
        }, `¿Esta seguro que desea cambiar el estado al registro?`, 'Guardar');
    };
    //retorna la pagina
    return (
        <>
            <PageTitle>Condiciones señales</PageTitle>
            <BlockUi tag="div" keepInView blocking={loader}  >
                <div className="card">
                    <div className="d-flex justify-content-between">
                        <div className="mx-auto">
                            <div className="ms-3 text-center">
                                <h3 className="mb-0">Señales condiciones</h3>
                                <span className="text-muted m-3">Permite editar las condiciones.</span>
                            </div>
                        </div>
                    </div>
                    <div className="card  d-flex justify-content-start">
                        <div className="bg-secondary d-flex flex-row  justify-content-start mt-2 rounded shadow">
                            <label className="form-label mt-4 mx-4">Cliente:</label>
                            <InputGroup className="mt-2 mb-2" inside style={{ width: 300}}>
                                <InputGroup.Addon>
                                    <MemberIcon />
                                </InputGroup.Addon>
                                <AutoComplete placeholder="Seleccione un cliente" onSelect={SeleccionCliente} data={LstClientes}>
                                </AutoComplete>
                            </InputGroup>
                        </div>
                        <div className="mt-2 rounded">
                            {(Datos.length != 0) && (<MaterialReactTable
                                // tableInstanceRef={ColumnasTablas['movil']}
                                localization={MRT_Localization_ES}
                                enableColumnFilters={false}
                                initialState={{ density: 'compact' }}
                                enableColumnOrdering
                                enableColumnDragging={false}
                                enablePagination={false}
                                enableStickyHeader
                                enableDensityToggle={false}
                                enableRowVirtualization
                                displayColumnDefOptions={{
                                    'mrt-row-actions': {
                                        muiTableHeadCellProps: {
                                            align: 'center',
                                        },
                                        size: 120,
                                    },
                                }}
                                columns={CamposTabla}
                                data={Datos}
                                editingMode="modal"
                                enableEditing
                                onEditingRowSave={handleSaveRowEdits}
                                onEditingRowCancel={handleCancelRowEdits}
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                            color: 'error',
                                            children: 'Error al cargar información',
                                        }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onGlobalFilterChange={setGlobalFilter}
                                enableRowNumbers
                                // onPaginationChange={setPagination}
                                onSortingChange={setSorting}
                                // rowCount={rowCount}
                                muiTableContainerProps={{
                                    sx: { maxHeight: '400px' }, //give the table a max height
                                }}
                                defaultColumn={{
                                    minSize: 100, //allow columns to get smaller than default
                                    maxSize: 150, //allow columns to get larger than default
                                    size: 150, //make columns wider by default
                                }}
                                enableRowActions={true}
                                renderRowActions={({ row, table }) => (
                                    <Box sx={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', gap: '1rem' }}>
                                        {(EsPermitido(permisosopcion, operacionesPermisos.Modificar)) && (<Tooltip arrow placement="top" title="Editar condición">
                                            <IconButton onClick={() => { table.setEditingRow(row) }} >
                                                <Edit className="text-center text-success" />
                                            </IconButton>
                                        </Tooltip>)}
                                        {(EsPermitido(permisosopcion, operacionesPermisos.Modificar)) && (<Tooltip arrow placement="top" title="Cambiar de estado">
                                            <IconButton onClick={() => { CambiarEstado(row) }}>
                                                <Check className="text-center text-warning" />
                                            </IconButton>
                                        </Tooltip>)}
                                    </Box>
                                )}
                                state={{
                                    columnFilters,
                                    globalFilter,
                                    isLoading,
                                    showAlertBanner: isError,
                                    showProgressBars: isRefetching,
                                    sorting,
                                }}
                            />)}
                            
                        </div>
                    </div>
                </div>
            </BlockUi>
            <CreateNewAccountModal
                columns={CamposTabla}
                open={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={Guardar}
            />
        </>
    )
}


