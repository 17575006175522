import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap-v5";
import { EsJson } from "../../data/Configuracion";
import { useDataFatigue } from "../../core/provider";
import { DeserializarJSon } from "../../../../../../_start/helpers/Helper";


type PropsEncabezados = {
    vUser:any,
    ObjetoGeneral:any,
    setObjetoGeneral:any
}
export const EncabezadoHtml:React.FC<PropsEncabezados> = ({vUser, ObjetoGeneral, setObjetoGeneral}) =>{
    const {Cliente} = useDataFatigue();
    useEffect(() =>{
        let Parametros = (EsJson(Cliente.ParamsSistema) ? DeserializarJSon(Cliente.ParamsSistema) : null);
        setlstOperadores((Parametros != null ? Parametros.fatiga_conf.controlador : []));
    },[Cliente])
    const [lstOperadores, setlstOperadores] = useState<any[]>([]);
    const [OperadorSeleccionado, setOperadorSeleccionado] = useState<any>({ Id: "", Nombres: "" });
    function ListadoOperadores() {
        return (
            <Form.Select className="mb-2 ms-2 mt-2" style={{ width: 300 }} onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let _Oeprador = lstOperadores.filter((value) => {
                    return value === e.currentTarget.value;
                })
                let o = { ...ObjetoGeneral };
                o.Operador = (_Oeprador.length > 0 ? _Oeprador[0] : "");
                o.ClienteIds = Cliente.ClienteIds;
                setObjetoGeneral(o);
                setOperadorSeleccionado(_Oeprador[0]);
            }} aria-label="Default select example" value={OperadorSeleccionado}>
                <option value={0}>Seleccione</option>
                {
                    lstOperadores.map((element: any) => {
                        return (<option key={element} value={element}>
                            {element}</option>)
                    })
                }
            </Form.Select>
        );
    }
     return (
        <>
            <div className="border mb-2 mt-1 rounded shadow-sm d-flex justify-content-between">
                <div className="mt-1">
                    <div>
                        <label className="mx-4 fs-6 fw-bolder">Usuario: </label>
                    </div>
                    <div className="mt-1">
                        <span className="mx-4 fs-5 text-muted mt-5">{vUser.Nombres}</span>
                    </div>
                </div>
                <div className="mt-1">
                    <div>
                        <label className="control control-label label-sm fw-bolder">Clientes</label>
                    </div>
                    <div className="mt-1">
                        <span className="mx-4 fs-5 text-muted mt-5"> {Cliente.clienteNombre}</span>
                    </div>
                </div>
                <div className="mt-1">
                    <div>
                        <label className="control control-label label-sm fw-bolder">Controlador u Operador</label>
                    </div>
                    <ListadoOperadores></ListadoOperadores>
                </div>
            </div>
        </>
    )
}