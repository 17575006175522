
type Props = {
    row: any
};

export const InfromacionBasicaEncuesta: React.FC<Props> = ({ row }) => {

    const getEstadoPreop = (row: any) => {
        return ((row.EsGestionado)) ?
            <span className="badge bg-primary">Gestionado</span> :
            (row.Estado === 1 && (row.EsGestionado == null)) ? <span className="badge bg-danger">No Gestionado</span> :
                (row.Estado === 1 && (!row.EsGestionado)) ? <span className="badge bg-warning">En Gestion</span> :
                    ""
    }
    return <>
        <div className="border mb-4 mt-0 rounded  border d-flex flex-column">
            <label className="fs-5 text-center"
                htmlFor="comentario" style={{ fontWeight: 'bold', width: '100%' }}>Información Básica</label>
            <div className="d-flex justify-content-between">
                <div className="mt-1" style={{  width: '33%' }}>
                    <div>
                        <label className="mx-4 fs-6 fw-bolder">Conductor: </label>
                    </div>
                    <div className="mt-1">
                        <span className="mx-4 fs-5 text-muted mt-5">{row.Conductor}</span>
                    </div>
                </div>
                <div className="mt-1" style={{  width: '33%' }}>
                    <div>
                        <label className="control control-label label-sm fw-bolder">Fecha</label>
                    </div>
                    <div className="mt-1" >
                        <span className="mx-4 fs-5 text-muted mt-5"> {row.FechaHoraString}</span>
                    </div>
                </div>
                <div className="mt-1" style={{  width: '33%' }}>
                    <div>
                        <label className="control control-label label-sm fw-bolder">Activo</label>
                    </div>
                    <div className="mt-1">
                        <span className="mx-4 fs-5 text-muted mt-5"> {row.Description}</span>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-start">
                <div className="mt-1" style={{  width: '33%' }}>
                    <div>
                        <label className="mx-4 fs-6 fw-bolder">Estado Gestion: </label>
                    </div>
                    <div className="mt-1">
                        <span className="mx-4 fs-4 text-muted mt-5">{getEstadoPreop(row)}</span>
                    </div>
                </div>
                <div className="mt-1" style={{  width: '33%' }}>
                    <div>
                        <label className="control control-label label-sm fw-bolder">Gestor</label>
                    </div>
                    <div className="mt-1">
                        <span className="mx-4 fs-5 text-muted mt-5"> {row.GetorNombre}</span>
                    </div>
                </div>
                
            </div>
        </div>
    </>
}
