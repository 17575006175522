import { Form, Modal, Collapse, Button } from "react-bootstrap-v5"
import {  GetDrivers,  SetParametroAsset } from "../../data/Clientes"
import { useEffect, useState } from "react"
import { AxiosError, AxiosResponse } from "axios"
import { useToaster, Notification } from "rsuite";
import confirmarDialog from "../../../../../../_start/helpers/components/ConfirmDialog";
type ModalAssetType = {
    showModal: boolean,
    setShowModal: any,
    ClienteSeleccionado: any,
    ConfiguracionGeneral: any
    setloader: any,
    Consultas: any,
    setConsultas: any,
    TipoCliente: any,
    Cliente?: any,
    Sitios: any,
    setDatosAssets:any
}
export const ModalAsset: React.FC<ModalAssetType> = ({ setDatosAssets, showModal, setShowModal, ClienteSeleccionado, ConfiguracionGeneral, setloader, Consultas, setConsultas, TipoCliente, Cliente, Sitios }) => {
    const toaster = useToaster();
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }
    const drv = { "DriverId": "null", "name": "Seleccione" };
    const [isVisible, initHs] = useState(ConfiguracionGeneral.isVisible)
    const [Configuracion, setConfiguracion] = useState<any>(
        ConfiguracionGeneral
    );

    const [textIndicador, settextIndicador] = useState(ConfiguracionGeneral.isVisible ? "Sin detalles adicionales" : "Agregar detalles adicionales");


    useEffect(() => {
        ConsultasCombos(null, null);
        return () => {
            //setConfiguracion([]);
        }
    }, [ClienteSeleccionado]);

    const ConsultasCombos = (Tipo: null | string | undefined, Estado: null | string | undefined) => {
        setloader(true);
        let c = { ...Configuracion };
        if (c.Sitios == undefined || c.Drivers.length == 0) {
            GetDrivers(ClienteSeleccionado).then((response: AxiosResponse<any>) => {
                c.Drivers = response.data;
                setConfiguracion(c);
                setloader(false);
            }).catch(() => {
                console.log("error");
                setloader(false);
            });
        }
    }
    const ComponenteAdministradores = () => {
        return (
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-conductor"><i className="bi bi-file-earmark-person-fill"></i></span>
                <Form.Select aria-label="conductor" aria-describedby="basic-conductor" className=" h-40px w-250px text-truncate " onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let admin = Configuracion.Administradores.filter((value: any, index: any) => {
                        return value.UsuarioId === e.currentTarget.value;
                    });
                    let d = { ...Configuracion };
                    d.AdditionalDetails.AdminSeleccionado = (admin.length > 0 ? admin[0].UsuarioId : "null");
                    d.AdditionalDetails["Administrador de flota"] = `${admin[0].UsuarioId} - ${admin[0].Nombre}`;
                    setConfiguracion(d);
                    //Validamos si hay un cliente seleccionado y filtramos por ese cliente
                }} value={Configuracion.AdditionalDetails.AdminSeleccionado}>
                    <option className="text-truncate" value={"null"}>Seleccione</option>
                    {
                        Configuracion.Administradores?.map((element: any, i: any) => {
                            return (<option key={element.UsuarioId} value={element.UsuarioId}>{element.Nombre}</option>)
                        })
                    }
                </Form.Select>
            </div>
        )
    }

    const ComponenteSitios = () => {
        return (
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-conductor"><i className="bi bi-file-earmark-person-fill"></i></span>
                <Form.Select aria-label="conductor" aria-describedby="basic-conductor" className=" h-40px w-250px text-truncate " onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let Sitio = Sitios.filter((value: any, index: any) => {
                        return String(value.siteid) === e.currentTarget.value;
                    });
                    let d = { ...Configuracion };
                    d.SitioSeleccionado = (Sitio.length > 0 ? Sitio[0] : { "siteid": "null", "sitename": "Seleccione" });
                    d.SiteId = d.SitioSeleccionado.siteid
                    setConfiguracion(d);
                    //Validamos si hay un cliente seleccionado y filtramos por ese cliente
                }} value={Configuracion.SiteId}>
                    <option className="text-truncate" value={"null"}>Seleccione</option>
                    {
                        Sitios?.map((element: any, i: any) => {
                            return (<option key={element.siteid} value={element.siteid}>{element.sitename}</option>)
                        })
                    }
                </Form.Select>
            </div>
        )
    }

    //Componentes del los clientes
    const ComponenteConductor = () => {
        return (
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-conductor"><i className="bi bi-file-earmark-person-fill"></i></span>
                <Form.Select aria-label="conductor" aria-describedby="basic-conductor" className=" h-40px w-250px text-truncate " onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let lstDriver = Configuracion.Drivers.filter((value: any, index: any) => {
                        return String(value.DriverId) === e.currentTarget.value;
                    });
                    let d = { ...Configuracion };
                    d.DriverSeleccionado = (lstDriver.length > 0 ? lstDriver[0] : drv);
                    setConfiguracion(d);
                    //Validamos si hay un cliente seleccionado y filtramos por ese cliente
                }} value={Configuracion.DriverSeleccionado.DriverId}>
                    <option className="text-truncate" value={"null"}>Seleccione</option>
                    {
                        Configuracion.Drivers?.map((element: any, i: any) => {
                            return (<option className="text-truncate" key={element.DriverId} value={element.DriverId}>{element.name}</option>)
                        })
                    }
                </Form.Select>
            </div>
        )
    }

    const ComponenteEstados = () => {
        return (
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-estados"><i className="bi bi-file-earmark-person"></i></span>
                <Form.Select aria-label="estados" aria-describedby="basic-estados" className=" h-40px w-250px " onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let lstDriver = Configuracion.Estados?.filter((value: any, index: any) => {
                        return value === e.currentTarget.value;
                    });
                    let es = { ...Configuracion };
                    es.EstadoSeleccionado = (lstDriver.length > 0 ? lstDriver[0] : drv);
                    setConfiguracion(es);
                    //  setDriverSeleccionado((lstDriver.length > 0 ? lstDriver[0] : drv))
                    //Validamos si hay un cliente seleccionado y filtramos por ese cliente
                }} value={Configuracion.EstadoSeleccionado}>
                    <option value={"null"}>Seleccione</option>
                    {
                        Configuracion.Estados?.map((element: any, i: any) => {
                            return (<option key={element} value={element}>{element}</option>)
                        })
                    }
                </Form.Select>
            </div>
        )
    }
    const ComponenteTiposAsset = () => {
        return (
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-Tipos"><i className="bi bi-file-earmark-person"></i></span>
                <Form.Select aria-label="Tipos" aria-describedby="basic-Tipos" className=" h-40px w-250px " onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let lstDriver = Configuracion.Tipos?.filter((value: any, index: any) => {
                        return value.AssetTypeId === parseInt(e.currentTarget.value);
                    });
                    let c = { ...Configuracion };
                    c.TipoSeleccionado = (lstDriver.length > 0 ? lstDriver[0] : drv);
                    setConfiguracion(c);
                    //  setDriverSeleccionado((lstDriver.length > 0 ? lstDriver[0] : drv))
                    //Validamos si hay un cliente seleccionado y filtramos por ese cliente
                }} value={Configuracion.TipoSeleccionado.AssetTypeId}>
                    <option value={0}>Seleccione</option>
                    {
                        Configuracion.Tipos?.map((element: any, i: any) => {
                            return (<option key={element.AssetTypeId} value={element.AssetTypeId}>{(element.Nombre == null || element.Nombre == undefined ? element.Name : element.Nombre)}</option>)
                        })
                    }
                </Form.Select>
            </div>
        )
    }
    const ComponenteVerticales = () => {
        return (
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-conductor"><i className="bi bi-file-earmark-person-fill"></i></span>
                <Form.Select aria-label="conductor" aria-describedby="basic-conductor" className=" h-40px w-250px text-truncate " onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let lstDriver = Configuracion.Vertical.filter((value: any, index: any) => {
                        return value === e.currentTarget.value;
                    });
                    let d = { ...Configuracion };
                    d.AdditionalDetails.VerticalSeleccionado = (lstDriver.length > 0 ? lstDriver[0] : drv);
                    setConfiguracion(d);
                    //Validamos si hay un cliente seleccionado y filtramos por ese cliente
                }} value={Configuracion.AdditionalDetails.VerticalSeleccionado}>
                    <option className="text-truncate" value={"null"}>Seleccione</option>
                    {
                        Configuracion.Vertical?.map((element: any, i: any) => {
                            return (<option key={element} value={element}>{element}</option>)
                        })
                    }
                </Form.Select>
            </div>
        )
    }
    const ComponentePaquetes = () => {
        return (
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-conductor"><i className="bi bi-file-earmark-person-fill"></i></span>
                <Form.Select aria-label="conductor" aria-describedby="basic-conductor" className=" h-40px w-250px text-truncate " onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let lstDriver = Configuracion.Paquetes.filter((value: any, index: any) => {
                        return value === e.currentTarget.value;
                    });
                    let d = { ...Configuracion };
                    d.AdditionalDetails.PaqueteSeleccionado = (lstDriver.length > 0 ? lstDriver[0] : drv);
                    setConfiguracion(d);
                    //Validamos si hay un cliente seleccionado y filtramos por ese cliente
                }} value={Configuracion.AdditionalDetails.PaqueteSeleccionado}>
                    <option className="text-truncate" value={"null"}>Seleccione</option>
                    {
                        Configuracion.Paquetes?.map((element: any, i: any) => {
                            return (<option key={element} value={element}>{element}</option>)
                        })
                    }
                </Form.Select>
            </div>
        )
    }
    const ComponenteActivos = () => {
        return (
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-conductor"><i className="bi bi-file-earmark-person-fill"></i></span>
                <Form.Select aria-label="conductor" aria-describedby="basic-conductor" className=" h-40px w-250px text-truncate " onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let lstDriver = Configuracion.Activos.filter((value: any, index: any) => {
                        return value === e.currentTarget.value;
                    });
                    let d = { ...Configuracion };
                    d.AdditionalDetails.ActivoSeleccionado = (lstDriver.length > 0 ? lstDriver[0] : drv);
                    setConfiguracion(d);
                    //Validamos si hay un cliente seleccionado y filtramos por ese cliente
                }} value={Configuracion.AdditionalDetails.ActivoSeleccionado}>
                    <option className="text-truncate" value={"null"}>Seleccione</option>
                    {
                        Configuracion.Activos?.map((element: any, i: any) => {
                            return (<option key={element} value={element}>{element}</option>)
                        })
                    }
                </Form.Select>
            </div>
        )
    }

    const ControlOBC = () => {
        return (
            <>
                <span className="input-group-text" id="basic-OBC"><i className="bi bi-1-circle"></i></span>
                <Form.Select aria-label="conductor" aria-describedby="basic-conductor" className=" h-40px w-250px text-truncate " onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let lstDriver = Configuracion.OBC.filter((value: any, index: any) => {
                        return value === e.currentTarget.value;
                    });
                    let d = { ...Configuracion };
                    d.AdditionalDetails.OBCSeleccionado = (lstDriver.length > 0 ? lstDriver[0] : drv);
                    setConfiguracion(d);
                    //Validamos si hay un cliente seleccionado y filtramos por ese cliente
                }} value={Configuracion.AdditionalDetails.OBCSeleccionado}>
                    <option className="text-truncate" value={"null"}>Seleccione</option>
                    {
                        Configuracion.OBC.map((element: any, index: any) => {
                            return (<option key={index} className="text-truncate" value={element}>{element}</option>)
                        })
                    }
                </Form.Select>
            </>

        )
    };

    const ControlMIx = () => {
        return <>
            <span className="input-group-text" id="basic-Mix"><i className="bi bi-1-circle"></i></span>
            <Form.Select aria-label="conductor" aria-describedby="basic-conductor" className=" h-40px w-250px text-truncate " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema

                let lstDriver = Configuracion.MIX.filter((value: any, index: any) => {
                    return value === e.currentTarget.value;
                });
                let d = { ...Configuracion };
                d.AdditionalDetails.MIXSeleccionado = (lstDriver.length > 0 ? lstDriver[0] : drv);
                setConfiguracion(d);
                //Validamos si hay un cliente seleccionado y filtramos por ese cliente
            }} value={Configuracion.AdditionalDetails.MIXSeleccionado}>
                <option className="text-truncate" value={"null"}>Seleccione</option>
                {
                    Configuracion.MIX.map((element: any, index: any) => {
                        return (<option key={element} value={element}>{element}</option>)
                    })
                }
            </Form.Select>
        </>
    }
    const invokeCollapse = () => {
        settextIndicador(!isVisible ? "Sin detalles adicionales" : "Agregar detalles adicionales")
        return initHs(!isVisible)
    }

    //Espacio para los sets
    const GuardarAsset = () => {
        let Campos = {
            AssetId: Configuracion.AssetId,
            AssetTypeId: String(Configuracion.TipoSeleccionado.AssetTypeId),
            Description: Configuracion.Description,
            RegistrationNumber: Configuracion.RegistrationNumber,
            SiteId: String(Configuracion.SiteId),
            ClienteId: Configuracion.ClienteId,
            CreateBy: Configuracion.CreateBy,
            UserState: (Configuracion.EstadoSeleccionado == "" || Configuracion.EstadoSeleccionado == "null" ? "Activo" : Configuracion.EstadoSeleccionado),
            Clave: Configuracion.Clave,
            AdditionalDetails: (isVisible ? JSON.stringify({
                "Administrador de flota": Configuracion.AdditionalDetails["Administrador de flota"],
                "Vertical": Configuracion.AdditionalDetails["VerticalSeleccionado"],
                "Paquete comercial": Configuracion.AdditionalDetails["PaqueteSeleccionado"],
                "Activo facturable": Configuracion.AdditionalDetails["ActivoSeleccionado"],
                "OBC Syscaf": Configuracion.AdditionalDetails["OBCSeleccionado"],
                "Mix Vision": Configuracion.AdditionalDetails["MIXSeleccionado"],
                "Serial sim card OBC": Configuracion.AdditionalDetails["Serial sim card OBC"],
                "Linea OBC": Configuracion.AdditionalDetails["Linea OBC"],
                "MV Syscaf": Configuracion.AdditionalDetails["MV Syscaf"],
                "Serial sim card MV": Configuracion.AdditionalDetails["Serial sim card MV"],
                "Linea MV": Configuracion.AdditionalDetails["Linea MV"]
            }):null ) 
        };
        if (Validacion()) {
            confirmarDialog(() => {
                setloader(true);
                SetParametroAsset(Campos).then((response: AxiosResponse<any>) => {
                    toaster.push(message('success', "Vehiculos", response.data[0].Mensaje), {
                        placement: 'topCenter'
                    });
                    let Consult = [...Consultas]
                    let nuevo:any[] = [];
                    if(Campos.Clave == "2") {
                        nuevo =  Consult[1].Data.map((val:any) =>{
                            if(String(val.AssetId) == Campos.AssetId){
                                val.AssetTypeId = String(Campos.AssetTypeId);
                                val.Description = Campos.Description;
                                val.RegistrationNumber = Campos.RegistrationNumber;
                                val.SiteId = String(Campos.SiteId);
                                val.UserState = Campos.UserState;
                                val.Clave = Campos.Clave;
                                val.AdditionalDetails = Campos.AdditionalDetails;
                            }
                            return val;
                        })
                    }else{
                        Campos.AssetId = response.data[0].Id;
                        Consult[1].Data.push(Campos)
                        nuevo = Consult[1].Data;
                    };
                    Consult[1].Data = nuevo;
                    setDatosAssets(nuevo);
                    setConsultas(Consult);
                   // Reconsultar();
                    setloader(false);
                    setShowModal(false);
                }).catch((error: AxiosError<any>) => {
                    setloader(false);
                    console.log("Error");
                    toaster.push(message('error', "Vehiculos", `Error: ${error}`), {
                        placement: 'topCenter'
                    });
                    setShowModal(false);
                })
            }, `¿Esta seguro que desea guardar el vehiculo?`, 'Guardar');
        }

    }

    const Validacion = () => {
        if (Configuracion.TipoSeleccionado.AssetTypeId == "" || Configuracion.TipoSeleccionado.AssetTypeId == undefined || Configuracion.TipoSeleccionado.AssetTypeId == null) {
            toaster.push(message('error', "Vehiculos", "Debe seleccionar un  tipo de vehiculo."), {
                placement: 'topCenter'
            });
            return false;
        }
        if (Configuracion.RegistrationNumber == "" || Configuracion.RegistrationNumber == undefined || Configuracion.RegistrationNumber == null) {
            toaster.push(message('error', "Vehiculos", "Ingrese un RegistrationNumber."), {
                placement: 'topCenter'
            });
            return false;
        }
        if (Configuracion.Description == "" || Configuracion.Description == undefined || Configuracion.Description == null) {
            toaster.push(message('error', "Vehiculos", "Ingrese un Description."), {
                placement: 'topCenter'
            });
            return false;
        }
        if (Configuracion.Description.length > 200) {
            toaster.push(message('error', "Vehiculos", "Description demasiado grande."), {
                placement: 'topCenter'
            });
            return false;
        }
        if (Configuracion.RegistrationNumber.length > 200) {
            toaster.push(message('error', "Vehiculos", "RegistrationNumber demasiado grande."), {
                placement: 'topCenter'
            });
            return false;
        }
        if (Configuracion.SiteId == "null" || Configuracion.SiteId == "" || Configuracion.SiteId == null || Configuracion.SiteId == undefined) {
            toaster.push(message('error', "Vehiculos", "Seleccione un sitio."), {
                placement: 'topCenter'
            });
            return false;
        }
        return true;
    }
    
    return (
        <>
            <Modal show={showModal} onHide={setShowModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">{`Configuración vehiculo`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card  rounded shadow-sm">
                        <div className="card-body">
                            <div className="border mb-2 mt-1">
                                <div className=" mb-2 mt-1 d-flex justify-content-evenly">
                                    {(Cliente != undefined) && (<div className="mt-1">
                                        <div>
                                            <label className="mx-4 fs-6 fw-bolder">Cliente: </label>
                                        </div>
                                        <span className="mx-4 fs-5 text-muted">{Cliente}</span>
                                    </div>)}
                                    <div className="mt-1">
                                        <div>
                                            <label className="mx-4 fs-6 fw-bolder">AssetId: </label>
                                        </div>
                                        <span className="mx-4 fs-5 text-muted">{Configuracion.AssetId}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-1 card shadow-sm">
                        <div className="card-body">
                            <div className="container">
                                <div className="row">

                                    <div className="col-4">
                                        <div className="input-group mb-3">
                                            <label className="control-label label-sm fw-bolder">Tipo vehiculo</label>
                                        </div>
                                        <div className="input-group mb-3">
                                            <ComponenteTiposAsset />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group mb-3">
                                            <label className="control-label label-sm fw-bolder">Descripción</label>
                                        </div>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-Descripcion"><i className="bi bi-card-text"></i></span>
                                            <input onChange={(e: any) => {
                                                let a = { ...Configuracion };
                                                a.Description = e.target.value;
                                                setConfiguracion(a);
                                            }} type="text" value={Configuracion.Description} className="form-control" placeholder="Descripción vehiculo" aria-label="Descripcion" aria-describedby="basic-Descripcion" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group mb-3">
                                            <label className="control-label label-sm fw-bolder">RegistrationNumber</label>
                                        </div>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-RegistrationNumber"><i className="bi bi-text-left"></i></span>
                                            <input onChange={(e: any) => {
                                                let a = { ...Configuracion };
                                                a.RegistrationNumber = e.target.value;
                                                setConfiguracion(a);
                                            }} type="text" value={Configuracion.RegistrationNumber} className="form-control" placeholder="RegistrationNumber vehiculo" aria-label="RegistrationNumber" aria-describedby="basic-RegistrationNumber" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group mb-3">
                                            <label className="control-label label-sm fw-bolder">Estado</label>
                                        </div>
                                        <div className="input-group mb-3">
                                            <ComponenteEstados />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group mb-3">
                                            <label className="control-label label-sm fw-bolder">Conductor</label>
                                        </div>
                                        <ComponenteConductor />
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group mb-3">
                                            <label className="control-label label-sm fw-bolder">Sitio</label>
                                        </div>
                                        <div className="input-group mb-3">
                                            <ComponenteSitios />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 pt-9">
                        <Button variant="success" onClick={invokeCollapse}>
                            {`${textIndicador}`}
                        </Button>

                    </div>
                    <Collapse in={isVisible}>
                        <div id="collapsePanel" className="row g-0 g-xl-5 g-xxl-8 rounded border mt-1 mb-2 pb-2 shadow-sm text-primary">

                            <div className="card-body">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">Administrador de flota</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <ComponenteAdministradores/>
                                                {/* <input onChange={(e: any) => {
                                                    let a = { ...Configuracion };
                                                    a.AdditionalDetails["Administrador de flota"] = e.target.value;
                                                    setConfiguracion(a);
                                                }} value={Configuracion.AdditionalDetails["Administrador de flota"]} type="text" className="form-control" placeholder="Administrador" aria-label="Administrador" aria-describedby="basic-Administrador" /> */}
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">Vertical</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <ComponenteVerticales />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">Paquete comercial</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <ComponentePaquetes />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">Activo facturable</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <ComponenteActivos />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">OBC Syscaf</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <ControlOBC />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">Mix Vision</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <ControlMIx />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">Serial sim card OBC</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-Serial"><i className="bi bi-1-circle"></i></span>
                                                <input onChange={(e: any) => {
                                                    let a = { ...Configuracion };
                                                    a.AdditionalDetails["Serial sim card OBC"] = e.target.value;
                                                    setConfiguracion(a);
                                                }} type="text" value={Configuracion.AdditionalDetails["Serial sim card OBC"]} className="form-control" placeholder="Serial OBC Sim" aria-label="Serial" aria-describedby="basic-Serial" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">Linea OBC</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-Linea"><i className="bi bi-1-circle"></i></span>
                                                <input onChange={(e: any) => {
                                                    let a = { ...Configuracion };
                                                    a.AdditionalDetails["Linea OBC"] = e.target.value;
                                                    setConfiguracion(a);
                                                }} type="text" value={Configuracion.AdditionalDetails["Linea OBC"]} className="form-control" placeholder="Linea OBC" aria-label="Linea" aria-describedby="basic-Linea" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">MV Syscaf</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-MV"><i className="bi bi-1-circle"></i></span>
                                                <input onChange={(e: any) => {
                                                    let a = { ...Configuracion };
                                                    a.AdditionalDetails["MV Syscaf"] = e.target.value;
                                                    setConfiguracion(a);
                                                }} type="text" value={Configuracion.AdditionalDetails["MV Syscaf"]} className="form-control" placeholder="MV Syscaf" aria-label="MV" aria-describedby="basic-MV" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">Serial sim card MV</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-SerialsimMV "><i className="bi bi-1-circle"></i></span>
                                                <input onChange={(e: any) => {
                                                    let a = { ...Configuracion };
                                                    a.AdditionalDetails["Serial sim card MV"] = e.target.value;
                                                    setConfiguracion(a);
                                                }} type="text" value={Configuracion.AdditionalDetails["Serial sim card MV"]} className="form-control" placeholder="Serial sim card MV" aria-label="SerialsimMV" aria-describedby="basic-SerialsimMV" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <label className="control-label label-sm fw-bolder">Linea MV</label>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-LineaMV "><i className="bi bi-1-circle"></i></span>
                                                <input onChange={(e: any) => {
                                                    let a = { ...Configuracion };
                                                    a.AdditionalDetails["Linea MV"] = e.target.value;
                                                    setConfiguracion(a);
                                                }} type="text" value={Configuracion.AdditionalDetails["Linea MV"]} className="form-control" placeholder="Linea MV" aria-label="LineaMV" aria-describedby="basic-LineaMV" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary" onClick={GuardarAsset}>
                        Guardar
                    </Button>
                    <Button type="button" variant="secondary" onClick={() => { setShowModal(false); }}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}