import axios from "axios";
import { Auth_SetLogOpcion } from "../../../../apiurlstore";

export function SetLogOpcion(Opcion: string, Id: number) {
    var params: { [id: string]: string | null; } = {};
    params["Opcion"] = Opcion;
    params["OpcionId"] = `${Id}`;
    // hacemos la consulta 
 
    return axios({
        method: 'post',
        url: Auth_SetLogOpcion,
        data: JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json'
          }
    });
};
