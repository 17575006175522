import { useEffect, useRef, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, Tooltip, useMap } from "react-leaflet";
import { useDataFatigue } from "../core/provider";
import { EventoActivo } from "../models/EventosActivos";
import { toAbsoluteUrl } from "../../../../../_start/helpers";
import L from 'leaflet';
import 'leaflet-routing-machine';
import { useDataFatigueHistorical } from "../core/providerHistorical";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

L.Marker.prototype.options.icon = L.icon({
    iconUrl: toAbsoluteUrl('/media/syscaf/iconbus.png'),
    iconSize: [40, 40]
});

export function MapTab2() {

    //Data
    const { DataDetallado2, Filtrado2, DataDetalladoFiltrado2 } = useDataFatigueHistorical();

    
    const { DataDetallado, Filtrado, DataDetalladoFiltrado } = useDataFatigue();

    const [show, setshowp] = useState<boolean>(false);
    const [zoom, setzoom] = useState<number>(13);
    const [map, setMap] = useState<EventoActivo[]>([]);
    const [activePark, setActivePark] = useState<EventoActivo>();
    const [centerLatitud, setcenterLatitud] = useState<number>(0);
    const [centerLongitud, setcenterLongitud] = useState<number>(0);
    const [isClustering, setisClustering] = useState<boolean>(true);
    const [instance, setinstance] = useState<any>();

    const mapRef = useRef();

    //  whenCreated={setMap}
    const here = {
    apiKey: 'h7cWVY3eEiZeilhreUhv07kKMJMizDl6elWoN7cb8wg'
    }

    //h7cWVY3eEiZeilhreUhv07kKMJMizDl6elWoN7cb8wg
    const style = 'reduced.night';
    const CapaBasicNight = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?apiKey=${here.apiKey}&ppi=320`;
    //const CapaHibrida = `https://2.traffic.maps.ls.hereapi.com/maptile/2.1/traffictile/newest/hybrid.traffic.day/{z}/{x}/{y}/512/png8?apiKey=${here.apiKey}&ppi=320`;
    // 
    
    function CreateRoutineMachineLayer () {
        let data: any = null;
        const mapa = useMap();
        if(mapa != null){
        // dependiendo del uso de la informaci'on se usa los datos
        // lo ideal es tener una vairable en donde se tenga la informacion base
        // y otra que siempre contenga el filtrado 
        if (Filtrado || Filtrado2) {
            if ((DataDetalladoFiltrado != undefined && DataDetalladoFiltrado.length > 0) || (DataDetalladoFiltrado2 != undefined && DataDetalladoFiltrado2.length > 0)) {
                data = DataDetalladoFiltrado != undefined ? DataDetalladoFiltrado : DataDetalladoFiltrado2;
            }
        } else {
            if ((DataDetallado != undefined && DataDetallado.length > 0) || (DataDetallado2 != undefined && DataDetallado2.length > 0)) {
                data = DataDetallado != undefined ? DataDetallado : DataDetallado2;
            }
        }
        if(instance == undefined ){
        const instancia = L.Routing.control({
        
            waypoints:
                data.map((m: any) => L.latLng(m.Latitud, m.Longitud))
            ,
            lineOptions: {
                styles: [{ color: "#6FA1EC", weight: 4 }],
                extendToWaypoints: false, missingRouteTolerance: 0
            },            
            show: false,
            addWaypoints: false,
            routeWhileDragging: false,
            
            fitSelectedRoutes: true,
            showAlternatives: false
        }).addTo(mapa);
        

        setinstance(instancia)
       }
    }

     return <></>
    };

    useEffect(() => {

        let data: any = null;

        if (Filtrado || Filtrado2) {
            if ((DataDetalladoFiltrado != undefined && DataDetalladoFiltrado.length > 0) || (DataDetalladoFiltrado2 != undefined && DataDetalladoFiltrado2.length > 0)) {
                data = DataDetalladoFiltrado != undefined ? DataDetalladoFiltrado : DataDetalladoFiltrado2;
            }
        } else {
            if ((DataDetallado != undefined && DataDetallado.length > 0) || (DataDetallado2 != undefined && DataDetallado2.length > 0)) {
                data = DataDetallado != undefined ? DataDetallado : DataDetallado2;
            }
        }

        if (data != null && data.length > 0) {
            setMap(data);
            const totalPoints = Number.parseInt(Math.round(data.length / 2).toString()) - 1;
            const AvgPointLatitud = data[totalPoints].Latitud;
            const AvgPointLongitud = data[totalPoints].Longitud;
            setcenterLatitud(AvgPointLatitud)
            setcenterLongitud(AvgPointLongitud)
            setzoom(12);
            setshowp(true);

        }


    }, [DataDetallado, Filtrado, DataDetalladoFiltrado, DataDetallado2, Filtrado2, DataDetalladoFiltrado2])
   
    function Puntos() {
 
        return (
            <>
                {map != undefined && map.length > 0 &&
                    map.map((park: any, index: any) => {
                        return (
                            <Marker
                                title={(park.evento == "" || park.evento == null ? park.EventTypeId : park.evento)}
                                key={index}
                                position={[
                                    Number.parseFloat(park.Latitud),
                                    Number.parseFloat(park.Longitud)
                                ]}
                                
                                icon={L.icon({
                                    iconUrl: toAbsoluteUrl('/media/syscaf/iconbus.png'),
                                    iconSize: [40, 40]

                                })}
                                eventHandlers={{
                                    click: (e: any) => {
                                        setActivePark(park);
                                    },
                                }}>
                                <Tooltip className="bg-transparent border-0  text-white fs-8" direction="right" offset={[13, 0]} opacity={1} permanent>
                                    {(park.evento == "" || park.evento == null ? park.EventId : park.evento)}
                                </Tooltip>
                            </Marker>
                        );
                    })}
            </>
        );
    }

    function RenderPopUp() {
        const mapa = useMap();
        return (<>
            {activePark && (
                <Popup
                    position={[
                        activePark.Latitud,
                        activePark.Longitud
                    ]}
                    onClose={() => {
                        setActivePark(undefined);
                        mapa.setView(
                            [
                                centerLatitud,
                                centerLongitud
                            ],
                            zoom
                        );
                        setisClustering(true)
                    }}
                    onOpen={() => {
                        mapa.setView(
                            [
                                activePark.Latitud,
                                activePark.Longitud
                            ],
                            zoom
                        );
                    }}
                >
                    <div className="card shadow-sm  border">
                        {/* <div className="card-header">
                            <div className="card-title "> <p className="text-center">EVENTO</p></div>
                        </div> */}
                        <div className="card-body fs-2 bg-secondary border">
                            {activePark.evento}
                        </div>
                    </div>
                </Popup>


            )}
        </>)
    }

    return (
        <>
           {(show) && (
                        <MapContainer
                            id="mapcontainter"
                            center={[centerLatitud, centerLongitud]}
                            zoom={zoom}
                            className=" ml-4"
                            style={{ height: 500 }}
                            mapRef={mapRef}

                        >
                            <TileLayer
                                url={CapaBasicNight}
                            />
                            <RenderPopUp />
                            {/**  si son todos aplicamos el clustering, si se filtra lo desagregamos*/}

                            <Puntos />

                            {/* {createRoutineMachineLayer()} */}

                            <CreateRoutineMachineLayer/>
                        </MapContainer>
                    )}
        </>
    );
}