import { MRT_ColumnDef } from "material-react-table";
import { Post_getConsultasDinamicasConAuthCore } from "../../../../../_start/helpers/Axios/CoreService";
import { Post_GetConsultasDinamicasUserCore } from "../../../../../_start/helpers/Axios/DWHService";

export function GetOrganizaciones() {
    var params: { [id: string]: string | null | undefined; } = {};
    params['OrganizacionId'] = null;
    params['EsActivo'] = null;

    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetOrganizaciones", Clase: "AdmQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
export function GetUsuariosActivos() {
    var params: { [id: string]: string | null | undefined; } = {};
    params['UsuarioId'] = null;

    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "getDataUsers", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}

export const ECO_Seguridad = {
    "ErrorConsultar": { title: "Consultar Datos", content: "Error al consultar datos favor vuelva a consultar." },

    "Historial": { title: "Módulo Gestión Seguridad", content: "Permite gestionar los accesos a los usuarios actuales y nuevos, permite la creación de roles y asignárselo a un usuario específico." }
}
export function GetPerfilesUsuarios() {
    // llamamos la informacion de los perfiles
    var params: { [id: string]: string | null; } = {};
    params["Sigla"] = "SYS_PER";
    params["SiglaD"] = null;
   return  Post_getConsultasDinamicasConAuthCore({ Clase: "PortalQueryHelper", NombreConsulta: "GetDetallesListaBySisglas", 
        Pagina: 1, RecordsPorPagina: 100 },
        params)
}

export function GetDetalleListaBySigla(SiglaDetalleLista : string) {
    // llamamos la informacion de los perfiles
    var params: { [id: string]: string | null; } = {};
    params["Sigla"] = null;
    params["SiglaD"] = SiglaDetalleLista;
   return  Post_getConsultasDinamicasConAuthCore({ Clase: "PortalQueryHelper", NombreConsulta: "GetDetallesListaBySisglas", 
        Pagina: 1, RecordsPorPagina: 100 },
        params)
}


export function GetListaBySigla(Sigla : string) {
    // llamamos la informacion de los perfiles
    var params: { [id: string]: string | null; } = {};
    params["Sigla"] = Sigla;
    params["SiglaD"] = null;
   return  Post_getConsultasDinamicasConAuthCore({ Clase: "PortalQueryHelper", NombreConsulta: "GetDetallesListaBySisglas", 
        Pagina: 1, RecordsPorPagina: 100 },
        params)
}






export const ECO_SeguridadOrg: MRT_ColumnDef<any>[] =

    [
        {
            accessorKey: 'OrganizacionId',
            header: 'Id'
        },
        {
            accessorKey: 'Nombre',
            header: "Nombre",
            Cell({ cell }) {
                return (cell.getValue() as string).toLocaleUpperCase();
            }
        },
        {
            accessorKey: 'Descripcion',
            header: "Descripcion"
        }
        , {
            accessorKey: 'Categorizacion',
            header: "Categorizacion"
        }, {
            accessorKey: 'EsActivo',
            header: "Estado",
            Cell({ row }) {
                return row.original.EsActivo ?
                    <span className="badge bg-success">Activo</span> : <span className="badge bg-danger">Inactivo</span>

            }
        }

    ];

