import { Form, Modal, Collapse, Button } from "react-bootstrap-v5"
import { GetDrivers, SetParametroDriver } from "../../data/Clientes"
import { AxiosResponse } from "axios"
import { useToaster, Notification } from "rsuite";
import { useEffect } from "react";
import confirmarDialog from "../../../../../../_start/helpers/components/ConfirmDialog";
type ModalDriverType = {
    showModal: boolean,
    setShowModal: any,
    ClienteSeleccionado: any
    setloader:any,
    CamposDriver:any,
    setCamposDriver:any,
    Consultas:any,
    setConsultas:any,
    TipoCliente:any,
    Cliente?:any,
    Sitios:any
}

export const ModalDriver: React.FC<ModalDriverType> = ({ showModal, setShowModal, ClienteSeleccionado , setloader, CamposDriver, setCamposDriver, Consultas, setConsultas, TipoCliente, Cliente, Sitios}) => {
    const toaster = useToaster();
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }
    useEffect(() =>{

    },[ClienteSeleccionado])
    const GuardarDriver = () =>{
        if(Validacion()){
            confirmarDialog(() => {
                setloader(true);
                SetParametroDriver(CamposDriver).then((response: AxiosResponse<any>) => {
                    toaster.push(message('success', "Conductores", response.data[0].Mensaje), {
                        placement: 'topCenter'
                    });

                    let Consult = [...Consultas];
                    let nuevo = Consult[2].Data.map((val:any) =>{
                        if(String(val.DriverId) == CamposDriver.DriverId){
                            val.EsActivo=CamposDriver.EsActivo;
                            val.Name=CamposDriver.Name;
                            val.SiteId=CamposDriver.SiteId;
                            val.employeeNumber=CamposDriver.employeeNumber;
                            val.fmDriverId=CamposDriver.fmDriverId;
                        }
                        return val;
                    })
                    Consult[2].Data =nuevo;
                    setConsultas(Consult);
                    setloader(false);
                    setShowModal(false);
                }).catch(() => {
                    setloader(false);
                    console.log("Error");
                })
            }, `¿Esta seguro que desea guardar el conductor?`, 'Guardar');
        }
    }
    const consultarDrivers = () => {
            setloader(true);
            GetDrivers(ClienteSeleccionado).then((response: AxiosResponse<any>) => {
                let Consult = [...Consultas]
                Consult[2].Cliente = ClienteSeleccionado;
                Consult[2].Data = response.data;
                Consult[2].TipoCliente = TipoCliente;
                setConsultas(Consult);
            }).catch(() => {
                console.log("error");
                setloader(false);
            });
       
    }
    const ComponenteSitios = () => {
        return (
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-conductor"><i className="bi bi-file-earmark-person-fill"></i></span>
                <Form.Select aria-label="conductor" aria-describedby="basic-conductor" className=" h-40px w-245px text-truncate " onChange={(e) => {
                    // buscamos el objeto completo para tenerlo en el sistema
                    let Sitio = Sitios.filter((value: any, index: any) => {
                        return String(value.siteid) === e.currentTarget.value;
                    });
                    let d = { ...CamposDriver };
                    d.SitioSeleccionado = (Sitio.length > 0 ? Sitio[0] : { "siteid": "null", "sitename": "Seleccione" });
                    d.SiteId = String(d.SitioSeleccionado.siteid)
                    setCamposDriver(d);
                    //Validamos si hay un cliente seleccionado y filtramos por ese cliente
                }} value={CamposDriver.SiteId}>
                    <option className="text-truncate" value={"null"}>Seleccione</option>
                    {
                        Sitios?.map((element: any, i: any) => {
                            return (<option key={element.siteid} value={element.siteid}>{element.sitename}</option>)
                        })
                    }
                </Form.Select>
            </div>
        )
    }
 const Validacion = () =>{
    if(CamposDriver.Name == "" || CamposDriver.Name == null || CamposDriver.Name == "null" || CamposDriver.Name ==undefined){
        toaster.push(message('error', "Conductores", "Ingrese el nombre del condutor"), {
            placement: 'topCenter'
        });
        return false;
    }
    if(CamposDriver.SiteId == "" || CamposDriver.SiteId == null || CamposDriver.SiteId == "null" || CamposDriver.SiteId ==undefined){
        toaster.push(message('error', "Conductores", "Seleccione un sitio"), {
            placement: 'topCenter'
        });
        return false;
    }
    if(CamposDriver.employeeNumber.length >100){
        toaster.push(message('error', "Conductores", "employeeNumber demasiado extenso."), {
            placement: 'topCenter'
        });
        return false;
    }
    if(CamposDriver.Name.length >200){
        toaster.push(message('error', "Conductores", "Nombre demasiado extenso."), {
            placement: 'topCenter'
        });
        return false;
    }
    
    return true;
 }
    return(
    <>
        <Modal show={showModal} onHide={setShowModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="text-center"> Configuración conductores</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card  rounded shadow-sm">
                        <div className="card-body">
                            <div className="border mb-2 mt-1">
                                <div className=" mb-2 mt-1 d-flex justify-content-evenly">
                                    {(Cliente != undefined) && (<div className="mt-1">
                                        <div>
                                            <label className="mx-4 fs-6 fw-bolder">Cliente: </label>
                                        </div>
                                        <span className="mx-4 fs-5 text-muted">{Cliente.clienteNombre}</span>
                                    </div>)}
                                    <div className="mt-1">
                                        <div>
                                            <label className="mx-4 fs-6 fw-bolder">Id: </label>
                                        </div>
                                        <span className="mx-4 fs-5 text-muted">{CamposDriver.DriverId}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="input-group mb-3">
                                            <label className="control-label label-sm fw-bolder">Name</label>
                                        </div>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-RegistrationNumber"><i className="bi bi-text-left"></i></span>
                                            <input onChange={(e:any) =>{
                                                let cd = {...CamposDriver};
                                                cd.Name = e.target.value;
                                                setCamposDriver(cd);
                                            }} value={CamposDriver.Name} type="text" className="form-control" placeholder="Nombre conductor" aria-label="RegistrationNumber" aria-describedby="basic-RegistrationNumber" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group mb-3">
                                            <label className="control-label label-sm fw-bolder">employeeNumber</label>
                                        </div>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-Descripcion"><i className="bi bi-card-text"></i></span>
                                            <input onChange={(e:any) =>{
                                                let cd = {...CamposDriver};
                                                cd.employeeNumber = e.target.value;
                                                setCamposDriver(cd);
                                            }} value={CamposDriver.employeeNumber} type="text" className="form-control" placeholder="Numero de empleado o documento" aria-label="Descripcion" aria-describedby="basic-Descripcion" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group mb-3">
                                            <label className="control-label label-sm fw-bolder">fmDriverId</label>
                                        </div>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-fmDriverId"><i className="bi bi-card-text"></i></span>
                                            <input onChange={(e:any) =>{
                                                let cd = {...CamposDriver};
                                                cd.fmDriverId = e.target.value;
                                                setCamposDriver(cd);
                                            }} value={CamposDriver.fmDriverId}  type="number" className="form-control" placeholder="fmDriverId" aria-label="fmDriverId" aria-describedby="basic-fmDriverId" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group mb-3">
                                            <label className="control-label label-sm fw-bolder">Sitio</label>
                                        </div>
                                        <div className="input-group mb-3">
                                            <ComponenteSitios/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary" onClick={GuardarDriver}>
                        Guardar
                    </Button>
                    <Button type="button" variant="secondary" onClick={() => { setShowModal(false); }}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
    </>
    )
}
