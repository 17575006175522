import { AxiosResponse } from "axios";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { SetEstadoSyscaf } from "../../../data/Reporte";

type Props = {
    setCargando?:any,
    row:any,
    Data?:any
    setData?:any
    Estados:any[],
    place:any
}
export const MenuIconos: React.FC<Props> = ({setCargando,row, Data, setData, Estados, place}) => {
    // const TipoIds = "3";
    // const [Estados, setEstados] = useState<any[]>([]);
    //Consulta los estados.
    // const ConsultarEstados = () => {
    //     GetEstadosTransmision(TipoIds).then((response: AxiosResponse<any>) => {
    //       setEstados(response.data);
    //     }).catch(({ error }) => {
    //      console.log(error);
    //     });
    //   }
      //UseEffect
    //   useEffect(() =>{
    //     ConsultarEstados();
    //   },[])
    const CambiarEstado = (event: any) => {
        let Estado = event.target.attributes.id.value;
        let AssetId = event.target.attributes['data-target'].value;
        let NombreEstado = event.target.attributes['data-rel'].value;
        confirmarDialog(() => {
          setCargando(true);
          SetEstadoSyscaf(AssetId, Estado).then((response: AxiosResponse) => {
            if (response.statusText === "OK") {
              let vehiculo = ((place == "1" ? Data.dataTx : Data)  as any[]).map((lis) => {
                if (lis.assetId === AssetId) {
                  lis.estadoSyscaf = NombreEstado;
                }
                return lis;
              });
              if(place == "1"){
                let a = {...Data};
                a.dataTx = vehiculo;
                setData(a);
              }else{
                setData(vehiculo);
              }
            }
            successDialog("¡Operación Éxitosa!", "");
            setCargando(false);
          }).catch(() => {
            errorDialog("Ha ocurrido un error al cambiar el estado del activo", "");
            setCargando(false);
          })
        }, `¿Esta seguro que desea cambiar el estado del activo?`, 'Guardar')
      };
      
    return (

        <div className="dropdown show ">
        <a  className="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className='bi-menu-button-wide primary'></i>
          {/* style={{ backgroundColor: 'white' }}  */}
        </a>
        <div style={{ zIndex: 2 }} className="dropdown-menu position-relative mb-5" aria-labelledby="dropdownMenuLink">
          {
            Estados.map((val, index) => {
              return (
                <a id={`${val.estadoIdS}`} key={val.estadoIdS + index} data-rel={`${val.estado}`} className="dropdown-item" onClick={CambiarEstado} data-target={row}>
                  <i title={val.estado} className={val.Icono}></i>
                  {<>&nbsp;</>}
                  {val.estado}
                </a>
              )
            })
          }
        </div>
      </div>
    )
  }