import MaterialReactTable from "material-react-table";
import { ECO_camposTablausuarios } from "../../data/datausuarios";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, Button } from "@mui/material";
import { Cached, FileDownload } from "@mui/icons-material";
import { DescargarExcel } from "../../../../../../_start/helpers/components/DescargarExcel";
import { KTSVG } from "../../../../../../_start/helpers";
import { GetFechaServidor } from "../../../../../../_start/helpers/Helper";
import moment from "moment";

type Props = {
    data: any[];
    ConsultarUsuarios?: any;
};


export const TabUsuarios: React.FC<Props> = ({ data, ConsultarUsuarios }) => {

    return (

        <MaterialReactTable
            localization={MRT_Localization_ES}
            columns={ECO_camposTablausuarios}
            data={data.map(m => {
                m.dias = m.UltimoIngreso ? GetFechaServidor().diff(moment(m.UltimoIngreso), 'days') : null;
                return m;
            })}
            enableColumnFilters={false}
            enableColumnOrdering
            enableColumnDragging={false}
            // enablePagination={false}
            enableStickyHeader
            enableDensityToggle={false}
            // enableRowVirtualization
            defaultColumn={{
                minSize: 40, //allow columns to get smaller than default
                maxSize: 200, //allow columns to get larger than default
                size: 150,
            }}

            muiTableContainerProps={{
                sx: { maxHeight: '500px' }, //give the table a max height
            }}
            initialState={
                {
                    pagination: {
                        pageIndex: 0,
                        pageSize: 100, //customize the default page size
                    },
                    sorting: [
                        {
                            id: 'dias', //sort by age by default on page load
                            desc: true,
                        }
                        ,
                        {
                            id: 'UltimoIngreso', //sort by age by default on page load
                            desc: true,
                        }

                    ]
                }
            }
            muiTableBodyRowProps={({ row }) => ({

                sx: {

                    backgroundColor:
                        row.original.dias >= 31 || row.original.dias == null ? 'rgba(248, 215, 218, 1)' : // rojo
                            row.original.dias >= 10 && row.original.dias < 31 ? 'rgba(255, 243, 205, 1)' : // amarillo
                                row.original.dias >= 0 && row.original.dias < 10 ? 'rgba(212, 237, 218, 1)' : // verde
                                    '' // por defecto
                }
            })}

            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ justifyContent: 'flex-end', alignItems: 'center', flex: 1, display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        onClick={() => { ConsultarUsuarios() }}
                        startIcon={<Cached />}>
                        Actualizar Datos
                    </Button>
                    <Button
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        onClick={() => { DescargarExcel(data, ECO_camposTablausuarios, "Reporte Listado Usuarios") }}
                        startIcon={<FileDownload />}>
                        Descargar Datos
                    </Button>
                </Box>
            )}
        />

    )

}