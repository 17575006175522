export const TituloNeptuno = "NEPTUNO - Gestor Documental.";
export const TituloNeptunoLogs = "NEPTUNO - Logs de archivos.";
export const TituloNivelCarga = "eBus Nivel de carga";
export const TituloParqueo = "eBus Parqueo inteligente";
export const TituloEventoCarga = "eBus Evento de carga";
export const TituloConfiguracionEbus  = "eBus Configuración";
export const TituloReportes = "eBus Reportes"
export const TituloReporteTx  = "Reporte transmisión";
export const TituloDashboardTx  = "Dashboard soporte";
export const TituloReporteUltimoOdometro = "Comparación Odometro"
export const TituloReporteZP = "Reportes Operador y Movil"