//GetInfoDashBoardAdmin

import moment from "moment";
import { Post_GetConsultasDinamicasUserCore, Post_GetConsultasDinamicasUserDWH } from "../../../../../_start/helpers/Axios/DWHService";
import { FormatoSerializacionYYYY_MM_DD } from "../../../../../_start/helpers/Constants";
import { CategoriasPorColorTx } from "../../Tx/data/Reporte";
import { Post_getConsultasDinamicasConAuthCore } from "../../../../../_start/helpers/Axios/CoreService";


export function GetInfoDashBoardAdmin() {
    var params: { [id: string]: string | null | undefined; } = {};
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetInfoDashBoardAdmin", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params);
}

export function GetInfoDashBoardAdminClientes() {
    var params: { [id: string]: string | null | undefined; } = {};
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetInfoDashBoardAdminClientes", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params);
}
export function GetInfoDashBoardAdminAsset() {
    var params: { [id: string]: string | null | undefined; } = {};
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetInfoDashBoardAdminAsset", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params);
}
export function GetInfoDashBoardAdminConductores() {
    var params: { [id: string]: string | null | undefined; } = {};
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetInfoDashBoardAdminConductores", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params);
}
export function GetInfoDashBoardAdminVehiculosSinTx() {
    var params: { [id: string]: string | null | undefined; } = {};
    params["clienteIds"] = null;
    params["FechaActual"] = moment().format(FormatoSerializacionYYYY_MM_DD);
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetReporteTransmision", Clase: "TXQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params);
}
export function GetInfoDashBoardAdminTickets() {
    var params: { [id: string]: string | null | undefined; } = {};
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetInfoDashBoardAdminTickets", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params);
}


export function GetFallasSeniales(ClienteIds: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["ClienteIds"] = ClienteIds;
    return Post_GetConsultasDinamicasUserDWH({
        NombreConsulta: "TotalFallasPorRangoFechasIndicadores", Clase: "SIGQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
//Guarda los requerimientos
export function SetRequerimiento(Datos: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["Tipo"] = Datos.Tipo;
    params["Cabecera"] = Datos.Cabecera;
    params["Observaciones"] = Datos.Observaciones;
    params["Estado"] = Datos.Estado;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "CrearRequerimiento", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
//Se obtiene la parametrizacion por tipo
export function GetLista() {
    var params: { [id: string]: string | null | undefined; } = {};
    params["Sigla"] = "GOIREQ";
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "getListas", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
//Todos los parametros para tipos dinamicamente.
export function GetDetalleLista(ListaId: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["ListaId"] = ListaId;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "getDetalleListas", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
//Consulta la lista de los requerimientos
export function GettRequerimiento(FechaInicial: any | null, FechaFinal: any | null) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['FechaInicial'] = FechaInicial;
    params['FechaFinal'] = FechaFinal;
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetRequerimientos", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
//Consulta el listado o el vehiculo especial para crear un requerimiento.
export function GetVehiculoEspecial(Placa: string) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Placa'] = Placa;
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetVehiculoEspecial", Clase: "TxQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params);
}
//Consulta que valida el listado de requerimiento.
export function ValidarPlacasRequerimiento(Datos: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["String"] = Datos;
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "ValidarRequerimientosMasivos", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}

//Consulta que inserta los requimientos masivos ya listos
export function InsertarRequerimientosMasivos(Datos: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["StringRequerimientos"] = Datos;
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "CrearRequerimientosMasivos", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}



//ESTA FUNCION HACE QUE SE EJECUTE LA TABLA DE LOS VEHICULOS SIN TX
//SE SACA APARTE PARA PODER MANEJARLO CON UN USESTATE
export const PintarTablaVehiculosSinTX = (data: any[],
    Requerimientos: any[], dataSeniales: any[]
) => {

    //Saco a aparte todos los sin respuesta y operando normalmente de TX.
    let filtro = data;//FiltroDashBoardData.getFiltroSinTx(data); -- se quita el filtro por peticion del cliente
    //Organizo el que tenga mayor cantidad de dias primero.
    let muestraFinal = FiltroDashBoardData.getOrdenados(filtro);
    //Elimino los vehiculos con un requerimiento creado o activo
    Requerimientos.map((item: any) => {
        let index = muestraFinal.findIndex((element) => element.AssetId == item.assetid);
        if (index != -1)
            muestraFinal.splice(index, 1);
    })
    //Verifico si los Vehiculos o la muestra de los vehiculos tienen falla de señales.
    if (dataSeniales.length != 0)
        FiltroDashBoardData.getVehiculosFallas(muestraFinal, dataSeniales);

    return muestraFinal;

}

export const PintarTablaVehiculosConFalla = (data: any[],
    Requerimientos: any[], dataSeniales: any[]) => {
    //PARA LOS VEHICULOS CON REQUERIMIENTOS
    let Vehiculosrequerimientos: any[] = [];
    //Saco a aparte todos los sin respuesta y operando normalmente de TX.
    let LstSinTx = FiltroDashBoardData.getFiltroSinTx(data);


    // sacamos los vehiculos que no tengan tranmision
    let ConSenial = dataSeniales.filter(f =>
        LstSinTx.length == 0 ||
        LstSinTx.filter(ff => ff.assetId == f.AssetId).length == 0);

    // filtramos las señales que tengan requerimientos activos

    let result = ConSenial.filter(f => Requerimientos.filter(ff => ff.assetid == f.AssetId).length == 0)
        .map(m => {
            return {
                AssetId: m.AssetId,
                clientenNombre: m.clienteNombre,
                registrationNumber: m.registrationNumber, TFallas: m.TFallas,
                ClienteId: m.ClienteId,
                assetsDescription: m.assetsDescription
            }
        });

    return result;

}
export function GetAdminisCliente(ClienteId: string) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["ClienteId"] = ClienteId;
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetAdministradoresByClienteID", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
export function GetAdminis() {
    var params: { [id: string]: string | null | undefined; } = {};
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetUsuariosAdmin", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
export const PintarTablaVehiculosConTx = (Assets: any[], LstSinTx: any[],
    Requerimientos: any[], LstSinSenial: any[]) => {
    //PARA LOS VEHICULOS CON REQUERIMIENTOS
    let Vehiculosrequerimientos: any[] = [];

    // sacamos los vehiculos que no tengan tranmision
    let FiltradoSinTx = Assets.filter(f =>
        LstSinTx.length == 0 ||
        LstSinTx.filter(ff =>
            ff.AssetId == f.AssetId).length == 0);


    let FiltradoSinFallas = FiltradoSinTx.filter(f =>
        LstSinSenial.length == 0 || LstSinSenial.filter(ff => ff.Id == f.AssetId).length == 0);


    // filtramos las señales que tengan requerimientos activos

    let result = FiltradoSinFallas.filter(f => Requerimientos.filter(ff => ff.assetid == f.AssetId).length == 0)
        .map(m => {
            return {
                AssetId: m.AssetId,
                clientenNombre: m.Base,
                registrationNumber: m.RegistrationNumber,
                ClienteId: m.ClienteId,
                assetsDescription: m.Description
            }
        });


    return result;
}



//Para los filtros y tratados de datos
export const FiltroDashBoardData = {
    getFiltroSinTx: (data: any[]) => {
        return data.filter((item: any, index: any) => {
            let estado = item.estadoSyscaf;
            if (estado == "Sin Respuesta del Cliente" || estado == "Operando Normalmente")
                return item;
        });
    },
    getOrdenados: (data: any[]) => {
        return data.sort(function (a, b) {
            return b.diffAVL - a.diffAVL;
        });
    },
    getVehiculosFallas: (data: any[], dt: any[]) => {
        return data.map((a: any) => {
            const r = dt.filter(f => f.AssetId.trim() == a.AssetId.trim());
            a.TFallas = 0;

            if (r.length > 0) {
                a.ClienteId = r[0].ClienteId
                a.TFallas = r[0].TFallas;
            }
        });
    },
    getEmpresasAgrupadas: function (data: any[]) {
        return data.reduce((p: any, c: any) => {
            let name = c.ClienteId;
            p[name] = p[name] ?? [];
            p[name].push(c);
            return p;
        }, {})
    },
    EsJson: (dato: any) => {
        try {
            JSON.parse(dato);
        } catch (e) {
            return false;
        }
        return true;
    },
    // consolida la informacion de las empresas, conductores y unidades en un solo objeto
    InformacionEmpresas: function (lstEmpresas: any[], lstConductores: any[], lstUnidades: any[]) {
        let Empresa = this.getEmpresasAgrupadas(lstEmpresas);
        let EmpCond = Object.entries(Empresa).map((elem: any) => {
            let conductoresEmpresas: any[] = [];
            let conductores = lstConductores.filter((f: any) => f["ClienteId"] === elem[0]);
            let asset = lstUnidades.filter((f: any) => f["ClienteId"] === elem[0]);

            conductoresEmpresas.push({ "ClienteId": elem[0], "clienteNombre": elem[1][0].clienteNombre, "Conductores": conductores.length, "Assets": asset.length })
            return conductoresEmpresas;
        });

        let data = EmpCond.map((val: any) => {
            return val[0];
        });

        return data; // retornamos el unico valor con los tres listadso
    },
    getTituloModal: (TipoReporte: string) => {
        let titulo: string = "";
        switch (TipoReporte) {
            case '1':
                titulo = "Listado de vehiculos sin Tx";
                break;
            case '2':
                titulo = "Listado de unidades activas";
                break;
            case '3':
                titulo = "Listado de vehiculos sin señales o problemas";
                break;
            case '4':
                titulo = "Listado de conductores";
                break;
            case '5':
                titulo = "Listado de vehiculos semaforizados";
                break;
            default:
                titulo = "Listado de empresas";
                break;
        }

        return titulo;
    }
}
export const FiltroDashBoard = {
    getAssetSinTX: function (data: any[], Estados: any) {
        return data.reduce((p, c) => {
            let EstadoOrden = 0;
            if (Estados.length > 0) {
                Estados = Estados.filter((ff: any) => ff.Configuracion != null)
                let a = (c.ConfiguracionEstado != null ? JSON.parse(c.ConfiguracionEstado).Orden : Estados.map((e: any) => {
                    if (e.Configuracion != null && e.estado == c.estadoSyscaf)
                        return JSON.parse(e.Configuracion)[0];
                }));

                a = (a != undefined ? a.filter((f: any) => f) : [{
                    "MinAmber": "0",
                    "MaxAmber": "0",
                    "Orden": "0",
                    "Semaforizado": "false",
                    "PermitePosponer": false
                }]);
                EstadoOrden = (a[0] == undefined ? 1000 : a[0].Orden);
            }

            const Estado = c.estadoSyscaf;
            const estadoIdS = c.estadoIdS;
            let exist = p.filter((f: any) => f.Estado == Estado);
            if (exist.length == 0)
                p.push({ Estado: Estado, Total: 1, Orden: EstadoOrden, estadoIdS });
            else
                exist[0].Total++;
            return p;
        },
            []);
    },
    getFallasAsset: function (data: any[]) {
        return data.reduce((p, c) => {
            const falla = c.Falla;
            let exist = p.filter((f: any) => f.Falla == falla);
            if (exist.length == 0)
                p.push({ Falla: falla, Total: 1 });
            else
                exist[0].Total++;
            return p;
        }, []);
    },
    getTickets: function (data: any[]) {
        return data.reduce((p, c) => {
            const falla = c.Estado;
            let exist = p.filter((f: any) => f.Falla == falla);
            if (exist.length == 0)
                p.push({ Falla: falla, Total: 1 });
            else
                exist[0].Total++;
            return p;
        }, []);
    },
    getFiltroClientes: function (cliente: any, Data: any[]) {
        if (cliente === 0)
            return Data;
        else
            return Data.filter((val) => {
                return val.ClienteId == cliente;
            })
    },

    getFiltroClientesAndAdministrador: function (cliente: any, Data: any[], UsuarioId: any) {
        return Data.filter((val) => {
            return val.ClienteId == cliente && val.UsuarioId == UsuarioId;
        })
    },
    getFiltroadministradores: function (UsuarioId: any, Data: any[]) {
        return Data.filter((val) => {
            return val.UsuarioId == UsuarioId;
        })
    },
    getFiltroadministradoresAndClientes: function (UsuarioId: any, Data: any[], cliente: any) {
        return Data.filter((val) => {
            return val.UsuarioId == UsuarioId && val.ClienteId == cliente;
        })
    },
    getFiltroUnidades: function (cliente: any, Data: any[]) {
        return Data.filter((val) => {
            return val.ClienteId == cliente;
        })
    },
    getFiltroClientesTickets: function (cliente: any, Data: any[]) {
        return Data.filter((val) => {
            return val.Base.toLowerCase() == cliente.toLowerCase();
        })
    },
    getFiltroClientesTicketsUsuarios: function (cliente: any, Data: any[], Usuario: any) {
        return Data.filter((val) => {
            return val.Base.toLowerCase() == cliente.toLowerCase() && val.Administrador.toLowerCase() == Usuario.toLowerCase();
        })
    },
    getFiltroAdministradoresTickets: function (Nombres: any, Data: any[]) {
        return Data.filter((val) => {
            return val.Administrador.toLowerCase() == Nombres.toLowerCase();
        })
    },
    getFiltroAdministradoresTicketsCliente: function (Nombres: any, Data: any[], cliente: any) {
        return Data.filter((val) => {
            return val.Administrador.toLowerCase() == Nombres.toLowerCase() && val.Base == cliente;
        })
    },
}
export const getClientesDash = (data: any[]) => {
    return data.filter((e: any) => e.estado == 1).map((val: any) => {
        return {
            "ClienteId": val.ClienteId, "clienteIdS": val.clienteIdS, "clienteNombre": val.clienteNombre,
            "AdminsId": val.usuario
        };
    })
}
//Funcion que carga la grafica
export const RetornarSerie = (data: any[]) => {
    var dataChart = data.filter((e: any) => {
        return e;
    });
    //UNIDADES SIN TX TORTA
    let dataEstados: any[] = FiltroDashBoard.getAssetSinTX(dataChart, []);
    let labels = dataEstados.reduce((p, c) => {
        const Estado = c.Estado;
        let exist = p.filter((f: any) => f == Estado);
        if (exist.length == 0)
            p.push(Estado);
        return p;
    }, []);
    let datos = dataEstados.map((m: any) => {
        return { "Estado": m.Estado, "Total": m.Total }
    });
}

export const ClienteDatosTablasData = (Data: any, ClientesData: any, Estado: any) => {
    return Data.filter((val: any) => {
        if (ClientesData.ClienteSeleccionado.ClienteId != 0 && ClientesData.AdminSeleccionado.UsuarioId != 0)
            return (val.ClienteId == ClientesData.ClienteSeleccionado.ClienteId && val.Falla.toLowerCase() == Estado.toLowerCase() && val.UsuarioId == ClientesData.AdminSeleccionado.UsuarioId)
        else if (ClientesData.AdminSeleccionado.UsuarioId != 0 && ClientesData.ClienteSeleccionado.ClienteId == 0)
            return (val.UsuarioId == ClientesData.AdminSeleccionado.UsuarioId && val.Falla.toLowerCase() == Estado.toLowerCase())
        else if (ClientesData.ClienteSeleccionado.ClienteId != 0 && ClientesData.AdminSeleccionado.UsuarioId == 0)
            return (val.ClienteId == ClientesData.ClienteSeleccionado.ClienteId && val.Falla.toLowerCase() == Estado.toLowerCase())
        else
            return (val.Falla.toLowerCase() == Estado.toLowerCase())
    })
};


export const ClienteDatosTablasDataTx = (Data: any, ClientesData: any, Estado: any) => {
    return Data.filter((val: any) => {
        if (ClientesData.ClienteSeleccionado.ClienteId != 0 && ClientesData.AdminSeleccionado.UsuarioId != 0)
            return (val.ClienteId == ClientesData.ClienteSeleccionado.ClienteId && val.estadoSyscaf.toLowerCase() == Estado.toLowerCase() && val.UsuarioId == ClientesData.AdminSeleccionado.UsuarioId)
        else if (ClientesData.AdminSeleccionado.UsuarioId != 0 && ClientesData.ClienteSeleccionado.ClienteId == 0)
            return (val.UsuarioId == ClientesData.AdminSeleccionado.UsuarioId && val.estadoSyscaf.toLowerCase() == Estado.toLowerCase())
        else if (ClientesData.ClienteSeleccionado.ClienteId != 0 && ClientesData.AdminSeleccionado.UsuarioId == 0)
            return (val.ClienteId == ClientesData.ClienteSeleccionado.ClienteId && val.estadoSyscaf.toLowerCase() == Estado.toLowerCase())
        else
            return (val.estadoSyscaf.toLowerCase() == Estado.toLowerCase())
    })
}


export const ColorRow = (Data: any, Estados: any) => {
    if (Data.Consecutivo != null && Estados.length > 0) {
        return ColoruCategoria(Data, true, Estados);
    }
    else
        return '';


}

export const ColoruCategoria = (Data: any, EsColor: boolean, Estados: any) => {
    let obs = JSON.parse(Data.Observaciones);
    let lons = obs.filter((val: any) => {

        return val.observacion.includes("Requerimiento Asignado");
    });
    //Fecha Desde que esta el Requerimientos en la ultima observacion.
    let ObservacionFecha = (lons.length > 0 ? new Date(lons[lons.length - 1].fecha).getTime() : new Date().getTime());
    //Fecha Actual
    let FechaActual = new Date().getTime();
    //Se calcula la diferencia pero estara en mili segundos.
    let Diferencia = FechaActual - ObservacionFecha;
    // Convierte la diferencia en días
    var diasTranscurridos = Diferencia / (1000 * 60 * 60 * 24);
    let Estado = JSON.parse(Data.Estado);
    let Valor = Estado.valor;
    let EstadoGeneral = Estados.filter((val: any) => {
        return (val.valor == Valor);
    });
    let intTrans = parseInt(diasTranscurridos.toString());
    let MinAmber = (Estado.MinAmber == undefined || Estado.MinAmber == null ? ((EstadoGeneral.length > 0 ? EstadoGeneral[0].MinAmber : 0)) : Estado.MinAmber);
    let MaxAmber = (Estado.MaxAmber == undefined || Estado.MaxAmber == null ? ((EstadoGeneral.length > 0 ? EstadoGeneral[0].MaxAmber : 0)) : Estado.MaxAmber);
    if (EsColor)
        return (MinAmber == 0 && MaxAmber == 0 ? '' : ((intTrans < MinAmber) ? 'rgba(212, 237, 218, 1)' :
            (intTrans >= MinAmber && intTrans <= MaxAmber) ? 'rgba(255, 243, 205, 1)' :
                (intTrans > MaxAmber) ? 'rgba(248, 215, 218, 1)' : 'rgba(255, 243, 205, 1)'))
    else
        return (MinAmber == 0 && MaxAmber == 0 ? 0 : ((intTrans < MinAmber) ? 1 :
            (intTrans >= MinAmber && intTrans <= MaxAmber) ? 2 :
                (intTrans > MaxAmber) ? 3 : 0));
}
//adicionalmente hago un sort para ordenarlos por porc que seria una categoria de colores.
export const CategoriasPorColor = (Data: any, Estados: any) => {
    return Data.map((m: any, ind: number) => {
        let Cabecera = JSON.parse(m.Cabecera);
        let obs = JSON.parse((m.Observaciones != undefined ? m.Observaciones : "[]"));
        let lons = obs.filter((val: any) => {

            return val.observacion.includes("Requerimiento Asignado");
        });
        let porc: any = 0;
        let fechaCambio: any = '';
        porc = ColoruCategoria(m, false, Estados);
        fechaCambio = (lons.length > 0 ? new Date(lons[lons.length - 1].fecha).getTime() : new Date().getTime())
        return {
            Id: m.Id,
            Consecutivo: (m.Consecutivo == undefined ? "" : m.Consecutivo),
            Categoria: Cabecera.Categoria,
            nombrecliente: Cabecera.nombrecliente,
            ClienteId: Cabecera.clienteid,
            registrationNumber: Cabecera.registrationNumber,
            UsuarioId: Cabecera.UsuarioAdministradorId,
            FechaCambio: fechaCambio,
            Porc: porc,
            Cabecera: m.Cabecera,
            FechaCreacion: m.FechaCreacion,
            Observaciones: m.Observaciones,
            Estado: m.Estado,
            TotalCreados: (m.TotalCreados == undefined ? "0" : m.TotalCreados),
            TotalCerrados: (m.TotalCerrados == undefined ? "0" : m.TotalCerrados),
            Diagnostico: m.Diagnostico
        }
    }
    ).sort((a: any, b: any) => {
        return b.Porc - a.Porc;
    });
}

export const DiasCambios = (Data: any) => {
    if (Data.Observaciones != null) {
        let obs = JSON.parse(Data.Observaciones);
        let lons = obs.filter((val: any) => {
            return val.observacion.includes("Requerimiento Asignado");
        });
        if (lons.length == 0 && obs.length == 1)
            lons = obs
        //Fecha Desde que esta el Requerimientos en la ultima observacion.
        let ObservacionFecha = (lons.length > 0 ? new Date(lons[lons.length - 1].fecha).getTime() : new Date().getTime())
        //Fecha Actual
        let FechaActual = moment(Date());
        //Se calcula la diferencia pero estara en mili segundos.
        let Diferencia = FechaActual.diff(ObservacionFecha, 'days');
        return String(Diferencia);
    }
    else
        return '-';
}
export const FechaCambio = (Data: any) => {
    if (Data.Observaciones != null) {
        let obs = JSON.parse(Data.Observaciones);
        let lons = obs.filter((val: any) => {
            return (val.observacion.includes("Requerimiento Asignado", "Se crea el requerimiento"));
        });
        if (lons.length == 0 && obs.length == 1)
            lons = obs
        return (lons.length > 0 ? lons[lons.length - 1].fecha : "");
    }
    else
        return '-';
}




export const ContabilizarByColorCategoria = (dataTx: any[], Requerimientos: any[], EstadosRequerimientos: any[]) => {
    //Saco las categorias de los requerimientos por color
    // let CategoriaR = CategoriasPorColor(Requerimientos, EstadosRequerimientos).filter((val:any) =>val.Porc != 0);
    //Saco las categorias de los vehiculos sin tx
    let CategoriaT = CategoriasPorColorTx(dataTx).filter((val: any) => val.Categoria != 0).filter((e: any) => e);
    //saco el total de registros con color
    let ArrayGeneral = [
        {
            "Estado": "General", "Total": CategoriaT.length,
            "Rojo": CategoriaT.filter((val: any) => val.Categoria == 3).length,
            "Amarillo": CategoriaT.filter((val: any) => val.Categoria == 2).length,
            "Verde": CategoriaT.filter((val: any) => val.Categoria == 1).length,
            estadoIds: -1
        },
        {
            "Estado": "", "Total": "", "Rojo": "", "Amarillo": "", "Verde": "", estadoIds: 0
        }
    ];
    //Agrupo por Estado TX
    let agrupadoEstado = CategoriaT.reduce((p: any, c: any) => {
        let name = c.estadoSyscaf;
        p[name] = p[name] ?? [];
        p[name].push(c);
        return p;
    }, [])
    //Ingreso cada estado y sus valores
    Object.keys(agrupadoEstado).map((val: any) => {

        let row: any = {};
        if (val === 'En Proceso') {
            const Data = ContabilizarRequerimientosPorCategorias(Requerimientos, EstadosRequerimientos, dataTx);
            const FiltradoEnProceso = Data.filter(f => f.Estado === 'General')[0];
            row.Estado = val;
            row.Total = FiltradoEnProceso.Total;
            row.Rojo = FiltradoEnProceso.Rojo;
            row.Amarillo = FiltradoEnProceso.Amarillo;
            row.Verde = FiltradoEnProceso.Verde;

        }
        else
            row = {
                Estado: val, 
                "Total": agrupadoEstado[val].length,
                "Rojo": agrupadoEstado[val].filter((val: any) => val.Categoria == 3).length,
                "Amarillo": agrupadoEstado[val].filter((val: any) => val.Categoria == 2).length,
                "Verde": agrupadoEstado[val].filter((val: any) => val.Categoria == 1).length
            }

        row.estadoIds = agrupadoEstado[val][0].estadoIdS
        ArrayGeneral.push(row);
    })

    return ArrayGeneral
}
export const lstRequerimientosEnProceso = (Requerimientos: any, EstadosRequerimientos: any, Vehiculos: any | null) => {
    let assets: any[] = Vehiculos.filter((f: any) => {
        return (f.estadoSyscaf == "En Proceso");
    }).map((e: any) => {
        return e.assetId
    });


    let Sinfiltrar = CategoriasPorColor(Requerimientos, EstadosRequerimientos);
    let m = Sinfiltrar.filter((f: any) => assets.includes(JSON.parse(f.Cabecera).assetid));


    let agrupadoEstado = m.reduce((p: any, c: any) => {
        const estado = JSON.parse(c.Estado);
        let name = `${estado.tipo}-${estado.label}`;
        p[name] = p[name] ?? [];
        p[name].push(c);
        return p;
    }, []);
    return { agrupadoEstado, sinagrupar: m };
}
export const ContabilizarRequerimientosPorCategorias = (Requerimientos: any, EstadosRequerimientos: any, Vehiculos: any | null) => {
    let assets: any[] = Vehiculos.filter((f: any) => {
        return (f.estadoSyscaf == "En Proceso");
    }).map((e: any) => {
        return e.assetId
    });


    let Sinfiltrar = CategoriasPorColor(Requerimientos, EstadosRequerimientos);
    let m = Sinfiltrar.filter((f: any) => assets.includes(JSON.parse(f.Cabecera).assetid));


    let ArrayGeneral = [
        {
            "Estado": "General",
            "Total": m.length,
            "Rojo": m.filter((val: any) => val.Porc == 3).length,
            "Amarillo": m.filter((val: any) => val.Porc == 2).length,
            "Verde": m.filter((val: any) => val.Porc == 1 || val.Porc == 0).length
        },
        {
            "Estado": "", "Total": "", "Rojo": "", "Amarillo": "", "Verde": ""
        }
    ];

    let agrupadoEstado = lstRequerimientosEnProceso(Requerimientos, EstadosRequerimientos, Vehiculos).agrupadoEstado;
    //Ingreso cada estado y sus valores
    Object.keys(agrupadoEstado).map((val: any) => {
        ArrayGeneral.push({
            "Estado": val, "Total": agrupadoEstado[val].length, "Rojo": agrupadoEstado[val].filter((val: any) => val.Porc == 3).length, "Amarillo": agrupadoEstado[val].filter((val: any) => val.Porc == 2).length, "Verde": agrupadoEstado[val].filter((val: any) => val.Porc == 1 || val.Porc == 0).length
        })
    })
    //Retorno el array compuesto
    return ArrayGeneral
}


export const ContabilizarRequerimientos = (Requerimientos: any, EstadosRequerimientos: any, Todos: boolean, Vehiculos: any | null) => {
    let assets: any[] = Vehiculos.filter((f: any) => {
        return (f.estadoSyscaf == "En Proceso");
    }).map((e: any) => {
        return e.assetId
    });
    let Sinfiltrar = CategoriasPorColor(Requerimientos, EstadosRequerimientos);
    let m = Sinfiltrar.filter((f: any) => assets.includes(JSON.parse(f.Cabecera).assetid));
    return m
}

export const ContabilizarDataTx = (dataTx: any) => {
    return CategoriasPorColorTx(dataTx).filter((val: any) => val.Categoria != 0).filter((e: any) => e);
}

export const Tooltips: any = {
    "Cerrar": { title: "Cerrar", content: "Cancela todas las acciones y cierra el modal." }
    , "Recargar": { title: "Recargar", content: "Recarga los datos del dashboard" }
    , "Guardar": { title: "Guardar", content: "Graba todo el contenido en el servidor, para posteriormente consultarlo." }
    , "Nuevo": { title: "Nuevo", content: "Limpia los campos para agregar un nuevo registro." }
    , "Transmision": { title: "Transmision", content: `Cantidad total de vehiculos con problemas de transmisión y su porcentaje en relación de activos, con fecha $` }
    , "Seniales": { title: "Señales", content: `Cantidad total de vehiculos con problemas de señal y su porcentaje en relación de activos con fecha $1 al $2` }
    , "Ticket": { title: "Tickets", content: `Cantidad total de tickets diferentes a cerrados o resueltos para $` }
}
