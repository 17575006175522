import React from "react";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { MasterInit } from "./MasterInit";
import { PageDataProvider,  useTheme } from "./core";

import { Header } from "./components/header/Header";


const MasterLayout: React.FC = ({ children }) => {
  const  { displayMenu} = useTheme();
  return (
    <PageDataProvider>
      <div className="d-flex flex-column flex-root">
       
          <div
            className= {`"bg-light-dark  ${!displayMenu ? 'wrapper' :  '' }  d-flex flex-column flex-row-fluid "`} 
            id="kt_wrapper"
          >
            <Header />
            <div className="d-flex flex-column flex-column-fluid ">
             <AsideDefault /> 
              <div
                className="content fs-6 d-flex flex-column-fluid px-2 "
                id="kt_content"
              >
                <Content>{children}</Content>
              </div>
            </div>
            <Footer />
          </div>
          {/* <Sidebar />*/}

       
      </div>
      <ScrollTop />
      <MasterInit />
      {/*   <ExploreMain />*/}
    </PageDataProvider>
  );
};

export { MasterLayout };
