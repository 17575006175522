
import { Button, Modal } from "react-bootstrap-v5";
import { EncabezadoModales } from "./EncabezadoModales";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { listadoEventos } from "../../data/dashBoardData";
import { useState } from "react";
import { ColumnFiltersState } from "@tanstack/react-table";
import { DeserializarJSon } from "../../../../../../_start/helpers/Helper";

type Props = {
    showDetalleEventos: boolean;
    setshowDetalleEventos: any;
    AlertaSeleccionada: any;
};
export const ModalDetalladoEventos: React.FC<Props> =
    ({ showDetalleEventos, setshowDetalleEventos, AlertaSeleccionada }) => {

        const DataDetalleEventos: any[] = DeserializarJSon(AlertaSeleccionada.DetalladoEventos) ?? [];
        
        const [isError, setIsError] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [isRefetching, setIsRefetching] = useState(false);
        //table state
        const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
        const [globalFilter, setGlobalFilter] = useState('');
        return (<Modal
            show={showDetalleEventos}
            onHide={setshowDetalleEventos}
            size="lg">
            <Modal.Header className=" fw-bolder  bg-secondary rounded m-4" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center", fontSize: 20
            }} closeButton={false}>
                <Modal.Title className="text-uppercase" style={{ fontSize: 20 }}>
                    Detallado Eventos</Modal.Title>
            </Modal.Header>

            <EncabezadoModales row={AlertaSeleccionada}></EncabezadoModales>

            <div className="m-2">
                <MaterialReactTable
                    localization={MRT_Localization_ES}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={listadoEventos}
                    data={DataDetalleEventos}
                    // editingMode="modal" //default         
                    enableTopToolbar={false}
                    enableColumnOrdering
                    // enableEditing
                    /* onEditingRowSave={handleSaveRowEdits}
                        onEditingRowCancel={handleCancelRowEdits}*/
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                color: 'error',
                                children: 'Error al cargar información',
                            }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    rowCount={DataDetalleEventos.length}
                    initialState={{
                        density: 'compact'
                    }}
                    state={{
                        columnFilters,
                        globalFilter,
                        isLoading,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching
                    }}
                />
            </div>

            <Modal.Footer>
                <Button type="button" variant="secondary" onClick={()=>{setshowDetalleEventos(false)}}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>)

    }