import { useEffect, useState } from "react";
import { UpdateRequerimientos } from "./modals/modalRequerimientos";
import { UpdateDLP } from "./modals/ModalDLP";
import { UpdateTickets } from "./modals/ModalSeñalesTicket";
import { UpdateUsuarios } from "./modals/modalUsuarios";
import { PageTitle } from "../../../../../../_start/layout/core";
import BlockUi from "@availity/block-ui";

export default function Parametrizacion() {
    const [loader, setloader] = useState<boolean>(false);

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);

    const [tituloModalParametrizacion, settituloModalParametrizacion] = useState('');
    const [tipoUsuario, settipoUsuario] = useState('');
    
    const handleClose = () => {
        settituloModalParametrizacion('');        
        setShow(false);  
       // setloader(true);      
    };

    const showModal = () => {
        settituloModalParametrizacion('Parametrizar estado de los requerimientos')
        setShow(true);
    }

    const handleClose2 = () => {
        settituloModalParametrizacion('');        
        setShow2(false);        
    };

    const showModal2 = () => {
        settituloModalParametrizacion('Parametrizar DLP')
        setShow2(true);
    }

    const handleClose3 = () => {
        settituloModalParametrizacion('');        
        setShow3(false);        
    };

    const showModal3 = () => {
        settituloModalParametrizacion('Parametrizar DLP')
        setShow3(true);
    }

    const handleClose4 = () => {
        settituloModalParametrizacion('');        
        setShow4(false);        
    };

    const showModal4 = (titulo : string, tipo: string) => {
      
        settituloModalParametrizacion(titulo)
        settipoUsuario(tipo);
        setShow4(true);
    }

    useEffect(() =>{
        setloader(false);
    },[])
    return (
        <>
 <PageTitle>Configuración Parámetros Requerimientos</PageTitle>
 <BlockUi tag="div" keepInView blocking={loader ?? false}  >
            <div className="col-lg-12">
                <h4 className="fw-bolder mb-4 mt-4">Parametrización</h4>
                {/* begin::Row */}
                <div className="row g-5">
                    <div className="col-sm-4">
                        <a
                            onClick={showModal}
                            className="card card-custom bg-light-primary hoverable shadow-none min-h-125px mb-5"
                        >
                            <div className="card-body d-flex flex-column flex-center text-center">
                                <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                    Requerimientos
                                </h3>
                                <p className="mb-0 text-gray-600">
                                    Parametrización
                                </p>
                            </div>
                        </a>
                    </div>

                    <div className="col-sm-4">
                        <a onClick={showModal2} className="card card-custom bg-light-warning hoverable shadow-none min-h-125px mb-5">
                            <div className="card-body d-flex flex-column flex-center text-center">
                                <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                    DLP
                                </h3>
                                <p className="mb-0 text-gray-600">
                                    Listado
                                </p>
                            </div>
                        </a>
                    </div>
                    
                    <div className="col-sm-4">
                        <a
                            onClick={showModal3}
                            className="card card-custom bg-light-success hoverable shadow-none min-h-125px mb-5"
                        >
                            <div className="card-body d-flex flex-column flex-center text-center">
                                <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                    Señales y tickets
                                </h3>
                                <p className="mb-0 text-gray-600">
                                    Tiempo
                                </p>
                            </div>
                        </a>
                    </div>

                    <h4 className="fw-bolder mb-4">Usuarios</h4>
                    <div className="col-sm-4">
                        <a
                            onClick={() => {
                                showModal4('Configuración Usuarios Soporte', 'Soporte');
                            }}
                            className="card card-custom bg-light-success hoverable shadow-none min-h-125px mb-5"
                        >
                            <div className="card-body d-flex flex-column flex-center text-center">
                                <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                    Grupo
                                </h3>
                                <p className="mb-0 text-gray-600">
                                    Soporte
                                </p>
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-4">
                        <a
                             onClick={() => {
                                showModal4('Configuración Usuarios ST', 'ST');
                            }}
                            className="card card-custom bg-light-success hoverable shadow-none min-h-125px mb-5"
                        >
                            <div className="card-body d-flex flex-column flex-center text-center">
                                <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                    Grupo
                                </h3>
                                <p className="mb-0 text-gray-600">
                                    ST
                                </p>
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-4">
                        <a
                             onClick={() => {
                                showModal4('Configuración Usuarios Ingeniería', 'Ing');
                            }}
                            className="card card-custom bg-light-success hoverable shadow-none min-h-125px mb-5"
                        >
                            <div className="card-body d-flex flex-column flex-center text-center">
                                <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                    Grupo
                                </h3>
                                <p className="mb-0 text-gray-600">
                                    Ingeniería                                </p>
                            </div>
                        </a>
                    </div>
                    {/* end::Row */}
                </div>
            </div> 
            </BlockUi>
                 {(show) && (<UpdateRequerimientos show={show} handleClose={handleClose} title={tituloModalParametrizacion} setloader={setloader} />)} 
           
            {(show2) && (<UpdateDLP show={show2} handleClose={handleClose2} title={tituloModalParametrizacion} />)}
            {(show3) && (<UpdateTickets show={show3} handleClose={handleClose3} title={"Señales y tickets}"} />)}
            {(show4) && (<UpdateUsuarios show={show4} handleClose={handleClose4} title={tituloModalParametrizacion} tipo={tipoUsuario} />)}
        </>
    )
}