
import { PageTitle } from "../../../../_start/layout/core";
import { GetDataSeguridad, ServiciosProvider } from "./core/seguridadProvider";
import { IconButton } from "@mui/material";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../_start/helpers/components/HtlmTooltip";
import { ECO_Seguridad as tooltip } from "./data/seguridaddata";
import { Help } from "@mui/icons-material";
import { ECO_SeguridadOrganizaciones } from "./components/Organizaciones";

export default function ECO_Seguridad() {
    return (<>
        <ServiciosProvider>
            <GetDataSeguridad />
            <PageTitle>Historial de Usuarios Activos </PageTitle>
            <div className="card card-rounded shadow mt-2" style={{ width: '100%' }}  >
                <div className="mx-auto">
                    <div className="ms-3 text-center">
                        <h5 className="mb-0">Seguridad Opciones<HtmlTooltipStyled arrow
                            title={HtmlContentTitle(tooltip["Historial"].title, tooltip["Historial"].content)
                            } >
                            <IconButton size="small"><Help /></IconButton>
                        </HtmlTooltipStyled></h5>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column mt-5">
                <ECO_SeguridadOrganizaciones />
                {/* <Tabs defaultActiveKey="usuariosActivos" className="mb-3  rounded " >
                    <Tab eventKey="usuariosActivos" title={'Ingresos Usuarios'} className="mx-1">

                        <TabIndicadores data={dataFiltrada} dataUsuarios={dataUsuariosFiltrada} />
                    </Tab>
                    <Tab eventKey="TablaEntradaVsModulos" title={'Ingresos Módulos'} className="mx-1">
                        <TablaEntradaVsModulos data={dataFiltrada} dataUsuarios={dataUsuariosFiltrada} />
                    </Tab>
                    <Tab eventKey="usuarios" title={'Usuarios Sistema'} className="mx-1">
                        <TabUsuarios data={dataUsuariosFiltrada} ConsultarUsuarios={ConsultarUsuarios} />
                    </Tab>
                    <Tab eventKey="gestion" title={'Historial Usuarios'} className=" mx-1">
                        <TabHistorial data={dataFiltrada}/>
                    </Tab>


                </Tabs> */}
            </div>
        </ServiciosProvider>
    </>
    )
}