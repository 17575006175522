import { Button, Container, FormLabel, Modal, Spinner, Table } from "react-bootstrap-v5";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../../_start/helpers/components/HtlmTooltip";
import { Autocomplete, TextField } from "@mui/material";
import { GetEventosPorCliente } from "../../../../../Seguridad/Administracion/data/Clientes";
import { useEffect, useState } from "react";
import { useToaster, IconButton } from "rsuite";
import { Delete } from "react-feather";
import { getClientes, setDataParamSistema, Tooltips } from "../../../data/dataConfiguracion";
import { AxiosError, AxiosResponse } from "axios";
import confirmarDialog, { successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { useDataConfiguracion } from "../../../core/ConfiguracionCore";
import { CargaListadoClientes } from "./ComponenteCliente";
import { MensajeError } from "../../../../../../../_start/helpers/components/Toaster";
import { getMensajeErrorAxios } from "../../../../../../../_start/helpers/Helper";


export const ModalEventoMTO: React.FC = () => {
    const defaultParams = [{
        preop_config: {
            FrecEventoMtto: 0,
            FrecWeb: 0,
        }
    }]
    const { Clientes, clienteSeleccionado, datosPreoperacional, setclienteSeleccionado, setClientes, showObject, setshowObject, loader } = useDataConfiguracion();
    const [datosEventos, setDatosEventos] = useState<any[]>([]);
    const [cargandoEventos, setCargandoEventos] = useState<boolean>(false);
    const [datosEventosSeleccionados, setDatosEventosSeleccionados] = useState<any[]>([]);
    const toaster = useToaster();
    const [ParamSistema, setParamSistema] = useState<any[]>(defaultParams);
    useEffect(() => {
        if (Clientes.length > 0)
            setParamSistema(Clientes.map((e: any) => JSON.parse(e.ParamsSistema)).map((val: any) => {
                if (val.preop_config == undefined)
                    val.preop_config = {
                        FrecEventoMtto: 0,
                        FrecWeb: 0,
                    }
                if (val.preop_config.FrecWeb == undefined)
                    val.preop_config.FrecWeb = 0;

                if (val.preop_config.FrecEventoMtto == undefined)
                    val.preop_config.FrecEventoMtto = 0;

                return val;
            }));
        else
            setParamSistema(defaultParams)
    }, [Clientes])
    useEffect(() => {
        if (datosEventos.length > 0)
            setDatosEventosSeleccionados(datosEventos.filter((e: any) => {
                return e.eventTypeId == ParamSistema[0].preop_config.eventomvtto
            }));
    }, [datosEventos, ParamSistema])

    useEffect(() => {
        if (clienteSeleccionado !== undefined && clienteSeleccionado.ClienteId !== '0')
            GetEventos(clienteSeleccionado);
    }, [clienteSeleccionado])

    const GetEventos = (ClienteId: any) => {
        setCargandoEventos(true)
        GetEventosPorCliente(ClienteId.ClienteId).then((response) => {
            if (response.status == 200) {
                setDatosEventos(response.data)
            }
            else {
                toaster.push(MensajeError("Configurando eventos", "Error a cargar eventos."), { placement: 'topCenter' });
            }
            setCargandoEventos(false)
        }).catch((error) => 
            {
                toaster.push(MensajeError('Guardar ', getMensajeErrorAxios(error)), { placement: 'topCenter' })
            }).finally(()=> { setCargandoEventos(false)});
    }
    const ConsultarClientesDatos = () => {
        getClientes(String(clienteSeleccionado.ClienteIds)).then((response: AxiosResponse<any>) => {
            setClientes(response.data);
        }).catch((error: AxiosError<any>) => {
            toaster.push(MensajeError('Guardar ', getMensajeErrorAxios(error)), { placement: 'topCenter' })
        });
    }
    const GuardarEventoMTO = () => {
        confirmarDialog(() => {
            setDataParamSistema(clienteSeleccionado.ClienteIds, "$.preop_config", JSON.stringify(ParamSistema[0].preop_config)).then((response: AxiosResponse<any>) => {
                successDialog("Operacion éxitosa", response.data[0]);
                Limpiar();
                GetEventos(clienteSeleccionado);
                setshowObject({ ...showObject, configuracion: false, panelCentral: true })
                ConsultarClientesDatos();

            }).catch((error: AxiosError<any>) => {
                toaster.push(MensajeError('Guardar ', getMensajeErrorAxios(error)), { placement: 'topCenter' })
            })
        }, `¿Esta seguro que desea guardar los cambios?`, 'Guardar');
    }
    const Limpiar = () => {
        let p: any = [...ParamSistema];
        p[0].preop_config = {
            FrecEventoMtto: 0,
            FrecWeb: 0,
        }
        setParamSistema(p);
    }
    return (<>
        <Modal className="border border-primary"
            show={showObject.configuracion}
            onHide={() => { setshowObject({ ...showObject, configuracion: false, panelCentral: true }) }}
            size="lg">
            <Modal.Header className="fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-between">
                <Modal.Title>Configuación de evento de movimiento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <div className="d-flex justify-content-start gap-4">
                        <FormLabel className="fs-4 mx-4 mt-2">Cliente:</FormLabel>
                        {CargaListadoClientes(datosPreoperacional, setclienteSeleccionado, clienteSeleccionado)}

                    </div>
                    <hr className="m-2" />
                    <div className="d-flex align-items-start flex-column">
                        <span className="text-muted fs-6" > *Permite configurar el evento de mix que se usará como evento de movimiento</span>
                        <div className="card round shadow d-flex flex-row">
                            <label className="m-2 fs-5"> Eventos: </label>
                            <Autocomplete
                                style={{ width: 300 }}
                                className="mx-2 ms-2"
                                disablePortal
                                id="combo-box-demo"
                                options={datosEventos.map((m: any) => { return { Id: m.eventTypeId, label: m.descriptionEvent } })}
                                renderInput={(params) => <TextField {...params} />
                                }
                                onChange={async (event, newValue: any) => {

                                    if (newValue != null && newValue != undefined && Object.keys(newValue).length > 0) {
                                        let isExist = datosEventosSeleccionados.filter(
                                            (f: any) => f.EventTypeId == newValue.Id
                                        );
                                        if (isExist.length == 0) {

                                            (datosEventosSeleccionados.length > 0 ? datosEventosSeleccionados[0] = {
                                                EventTypeId: newValue.Id,
                                                descriptionEvent: newValue.label, isActive: true,
                                                ClientesId: String(clienteSeleccionado.clienteIdS)
                                            } : datosEventosSeleccionados.push({
                                                EventTypeId: newValue.Id,
                                                descriptionEvent: newValue.label, isActive: true,
                                                ClientesId: String(clienteSeleccionado.clienteIdS)
                                            }));
                                            setDatosEventosSeleccionados([...datosEventosSeleccionados])
                                            let s = [...ParamSistema];
                                            s[0].preop_config = {
                                                "eventomvtto": String(newValue.Id)
                                            };
                                            setParamSistema(s);

                                        } else {
                                            toaster.push(MensajeError("Adicionar Evento", "El evento ya se encuentra en la lista"), { placement: 'topCenter' });
                                        }
                                    }
                                }}
                            />
                            {cargandoEventos && <Spinner className="mt-4" animation={"border"}> </Spinner>}
                        </div>

                        <div style={{ height: 100 }} className="card round mt-3 overflow-auto">

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className="fw-bolder">Opciones</th>
                                        <th className="fw-bolder">EventoId</th>
                                        <th className="fw-bolder">Nombre</th>
                                        <th className="fw-bolder">EsActivo</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {datosEventosSeleccionados.map((m: any) => (
                                        <tr key={m.EventTypeId}>
                                            <td> <IconButton size="xs" style={{ display: "block" }} onClick={() => {
                                                const NoEliminados = datosEventosSeleccionados.filter((f: any) => f.EventTypeId != m.EventTypeId);
                                                setDatosEventosSeleccionados(NoEliminados);
                                            }}>
                                                <Delete className="text-danger" />
                                            </IconButton></td>
                                            <td>{m.EventTypeId}</td>
                                            <td>{m.descriptionEvent}</td>
                                            <td><input
                                                disabled
                                                type="checkbox"
                                                checked={m.isActive}
                                                onChange={(e) => {
                                                    let isExist = datosEventosSeleccionados.filter(
                                                        (f: any) => f.EventTypeId == m.EventTypeId
                                                    );
                                                    if (isExist.length > 0)
                                                        isExist[0].isActive = e.target.checked;

                                                    setDatosEventosSeleccionados([...datosEventosSeleccionados])
                                                }}
                                            /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <hr className="m-2" />
                    {loader && <><Spinner className="mt-4" size="sm" animation={"border"} /> Cargando.... </>}
                    <div className={` ${loader && ' d-none'}`}>
                        <span className="text-muted fs-6" > *Frecuencia de actualización datos y Frecuencia de extracción eventos​ </span>
                        <div className="card round shadow mt-5" style={{width:'100%'}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="label label-sm control-label ft-bolder">Frecuencia de refresco (min)</label>
                                    <input type="number" value={ParamSistema[0].preop_config.FrecWeb} onChange={
                                        (e: any) => {
                                            const value = parseInt(e.currentTarget.value);
                                            if (value > 0) {
                                                let s = [...ParamSistema];
                                                s[0].preop_config.FrecWeb = value;
                                                setParamSistema(s);
                                            }

                                        }

                                    } className="form-control input input-sm" />
                                </div>
                                <div className="col-md-6">
                                    <label className="label label-sm control-label ft-bolder">Frecuencia de extracción (min)</label>
                                    <input type="number" value={ParamSistema[0].preop_config.FrecEventoMtto} onChange={
                                        (e: any) => {
                                            const value = parseInt(e.currentTarget.value);
                                            if (value > 0) {
                                                let s = [...ParamSistema];
                                                s[0].preop_config.FrecEventoMtto = value;
                                                setParamSistema(s);
                                            }

                                        }
                                    } className="form-control input input-sm" />
                                </div>
                            </div>
                        </div>

                    </div>
                </Container>

            </Modal.Body>
            <Modal.Footer>
                <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Guardar"].title, Tooltips["Guardar"].content)} >
                    <Button type="button" className="bt btn-sm" variant="primary" onClick={GuardarEventoMTO}>
                        Guardar
                    </Button>
                </HtmlTooltipStyled>

                <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Cerrar"].title, Tooltips["Cerrar"].content)} >
                    <Button type="button" className="bt btn-sm" variant="secondary" onClick={() => {
                        setshowObject({ ...showObject, configuracion: false, panelCentral: true })
                    }}>
                        Cerrar
                    </Button>
                </HtmlTooltipStyled>

            </Modal.Footer>
        </Modal>
    </>)
}