import { Close } from "@mui/icons-material";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";

import { Modal, InputGroup, Button, Form } from "react-bootstrap-v5";
import { toaster } from "rsuite";
import { DeserializarJSon, GetFechaServidor, ValidaCorreo, base64ToArrayBuffer, formatableJson_HHMMss } from "../../../../../../_start/helpers/Helper";
import confirmarDialog from "../../../../../../_start/helpers/components/ConfirmDialog";
import {  useRef, useState } from "react";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { AiFillFile, AiFillFileImage } from "react-icons/ai";
import { GetAlertasPorUsuarios, Get_EnviarInformeTorreControl, Get_InformeTorreControl,  setObservaciones } from "../../data/dashBoardData";
import { AxiosError, AxiosResponse } from "axios";
import { FormatoColombiaDDMMYYY } from "../../../../../../_start/helpers/Constants";
import { useDataFatigue } from "../../core/provider";

type Props = {
    showInforme: boolean;
    handleCloseInforme: any;
    Informe: any;
    setInforme: any; // setea la informaciu'on del informe a trabajar,
    message: any;
    AlertaSeleccionada: any;
    clienteIds: any;
    Operador: any;
};
export const EnvioReporteAlerta: React.FC<Props> = ({ showInforme, handleCloseInforme, Informe,
    setInforme, message, AlertaSeleccionada, clienteIds, Operador }) => {
    const tiempoCierreAuto = JSON.parse(AlertaSeleccionada.Detalles).TiempoCierre;

    const [errorCorreo, setErrorCorreo] = useState<string>("");
    const [DatosEscogidos, setDatosEscogidos] = useState<any>();
    const [dataRuta, setDataRuta] = useState<string[]>([]);
    const [dataZona, setDataZona] = useState<string[]>([]);
    const [FileName, setFileName] = useState<any>("Ningún archivo seleccionado");
    // permite adicionar al listado cuando no exista el parametro
    const filter = createFilterOptions<string>();
    // constante para los controles inputs 
    const [imagenPreview, setImagenPreview] = useState<string | null>(null);
    const [videoPreview, setVideoPreview] = useState<string | null>(null);
    const [fileNameVideo, setFileNameVideo] = useState<string>("Ningún archivo seleccionado");
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const {setalertas} = useDataFatigue();

    // control para la imagen del reporte
    const InputControl = () => {
        return <>
            <div>
                <div className="form-input" style={{height:200}} onClick={(e: any) => {
                    let inputClick: any = document.querySelector(".input-field");
                    inputClick.click();
                }}>
                    <input accept="image/*" className="input-field" type="file" hidden
                        onChange={(files: any) => {
                            files.target.files[0] && setFileName(files.target.files[0].name);
                            if (files.target.files) {

                                let i = { ...Informe };
                                i.Imagen = files.target.files[0];
                                setInforme(i);

                                const imageUrl = URL.createObjectURL(files.target.files[0]);
                                setImagenPreview(imageUrl);

                            }
                        }}
                    />
                    {imagenPreview ? <img crossOrigin="anonymous" alt={FileName} src={imagenPreview} height={200} width={220} /> : <>
                        <MdCloudUpload color="#1475cf" size={60} />
                        <p>Buscar archivo para cargar</p>
                    </>
                    }
                </div>
                <div className="Uploaded-row">
                    <AiFillFileImage color="#1475cf" />
                    <span className="Upload-content">{FileName}
                        <MdDelete onClick={() => {
                            setFileName("Ningún archivo seleccionado");
                            let i = { ...Informe };
                            i.Imagen = null;
                            setInforme(i);
                            setImagenPreview(null);
                        }} />
                    </span>
                </div>
            </div>
        </>
    }
    // control para el video del reporte
    const VideoInputControl = () => {
        const handleFileChange = (files: any) => {
            if (files.target.files) {
                const originalFileName = files.target.files[0].name;
                const truncatedFileName = truncateFileName(originalFileName, 30);
                setFileNameVideo(truncatedFileName);
                let videoInfo = { ...Informe };
                videoInfo.Video = files.target.files[0];
                setInforme(videoInfo);

                const videoUrl = URL.createObjectURL(files.target.files[0]);
                setVideoPreview(videoUrl);

                if (inputRef.current) {
                    inputRef.current.value = "";
                }
            }
        };

        const handleDelete = () => {
            setFileNameVideo("Ningún archivo seleccionado");
            let videoInfo = { ...Informe };
            videoInfo.Video = null;
            setInforme(videoInfo);
            setVideoPreview(null);
        };

        const truncateFileName = (name: string, maxLength: number) => {
            if (name.length > maxLength) {
                return name.substring(0, maxLength - 3) + '...';
            }
            return name;
        };



        return (
            <div>
                <div className="form-input"style={{height:200}} onClick={() => inputRef.current?.click()}>
                    <input
                        ref={inputRef}
                        accept="video/*"
                        className="input-field"
                        type="file"
                        hidden
                        onChange={handleFileChange}
                    />
                    {videoPreview ?
                        <video controls crossOrigin="anonymous" src={videoPreview} height={200} width={220}></video>
                        :
                        <>
                            <MdCloudUpload color="#1475cf" size={60} />
                            <p>Buscar archivo para cargar</p>
                        </>
                    }
                </div>
                <div className="Uploaded-row">
                    <AiFillFile color="#1475cf" />
                    <span className="Upload-content">{fileNameVideo}
                        <MdDelete onClick={handleDelete} />
                    </span>
                </div>
            </div>
        )
    }

    const Exportar = (Informe: any, Descarga: boolean) => {

        let InformeExcel: any = {};
        InformeExcel.Evento = Informe.Evento;
        InformeExcel.Hora = Informe.Hora
        InformeExcel.Operacion = DatosEscogidos.Operacion
        InformeExcel.Ruta = DatosEscogidos.Ruta
        InformeExcel.Ciudad = DatosEscogidos.Ciudad
        InformeExcel.Zona = DatosEscogidos.Zona
        InformeExcel.Placa = Informe.Placa
        InformeExcel.Conductor = Informe.Conductor
        InformeExcel.Operador = Informe.Operador
        InformeExcel.ContactoHa = Informe.ContactoHa
        InformeExcel.Contacto = DatosEscogidos.Contacto.join(",")
        InformeExcel.Motivo = DatosEscogidos.Motivo
        InformeExcel.Ubicacion = (Informe.Ubicacion == null || Informe.Ubicacion == "" ? "-" : Informe.Ubicacion)
        InformeExcel.Coordenadas = Informe.Coordenadas
        InformeExcel.Observacion = Informe.Observacion
        InformeExcel.VersionConductor = Informe.VersionConductor
        InformeExcel.Imagen = Informe.Imagen
        InformeExcel.Video = Informe.Video;

        if (Descarga)
            DescargarInforme(InformeExcel);
        else
            EnviarInforme(InformeExcel)
    }
    function DescargarInforme(Infome: any) {
        setIsLoading(true);
        if (!Informe.Ruta) {
            alert("Debe ingresar una ruta antes de enviar el informe.");
            return;
        }

        if (!Informe.Motivo) {
            alert("Debe ingresar un motivo antes de enviar el informe.");
            return;
        }
        const formData = new FormData();
        formData.append('Evento', Informe.Evento);
        formData.append('Hora', Informe.Hora);
        formData.append('Operacion', Informe.Operacion);
        formData.append('Ruta', Informe.Ruta);
        formData.append('Ciudad', Informe.Ciudad);
        formData.append('Zona', Informe.Zona);
        formData.append('Placa', Informe.Placa);
        formData.append('Conductor', Informe.Conductor);
        formData.append('ContactoHa', Informe.ContactoHa);
        formData.append('Operador', Informe.Operador);
        formData.append('Contacto', Informe.Contacto);
        formData.append('Motivo', Informe.Motivo);
        formData.append('Ubicacion', Informe.Ubicacion);
        formData.append('Coordenadas', Informe.Coordenadas);
        formData.append('Observacion', Informe.Observacion);
        formData.append('Imagen', Informe.Imagen);
        formData.append('Video', Informe.Video);

        Get_InformeTorreControl(formData).then((response: AxiosResponse<any>) => {
            const pdfBytes = base64ToArrayBuffer(response?.data);

            // Crea un Blob con los bytes del PDF
            const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

            // Crea una URL para el Blob y configura el enlace de descarga
            const pdfUrl = window.URL.createObjectURL(pdfBlob);
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = `InformeAlerta ${GetFechaServidor().format(FormatoColombiaDDMMYYY)}.pdf`;

            // Agrega el enlace al DOM y simula un clic para iniciar la descarga
            document.body.appendChild(a);
            a.click();

            // Elimina el enlace del DOM después de la descarga
            document.body.removeChild(a);

            // Revoca la URL del Blob
            window.URL.revokeObjectURL(pdfUrl);

            setIsLoading(false);
        }).catch((error: AxiosError<any>) => {
            console.log(error.message.toString());
            setIsLoading(false);
        });
    }

    function EnviarInforme(Informe: any) {

        if (!Informe.Ruta) {
            toaster.push(
                message('error', 'Envío de Informe', 'Debe ingresar una ruta antes de enviar el informe.'),
                { placement: 'topCenter' }
            );
            return;
        }

        if (!Informe.Motivo) {
            toaster.push(
                message('error', 'Envío de Informe', '"Debe ingresar un motivo antes de enviar el informe."'),
                { placement: 'topCenter' }
            );
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('Evento', Informe.Evento);
        formData.append('Hora', Informe.Hora);
        formData.append('Operacion', Informe.Operacion);
        formData.append('Ruta', Informe.Ruta);
        formData.append('Ciudad', Informe.Ciudad);
        formData.append('Zona', Informe.Zona);
        formData.append('Placa', Informe.Placa);
        formData.append('Conductor', Informe.Conductor);
        formData.append('Operador', Informe.Operador);
        formData.append('ContactoHa', Informe.ContactoHa);
        formData.append('VersionConductor', Informe.VersionConductor);
        formData.append('Contacto', Informe.Contacto);
        formData.append('Motivo', Informe.Motivo);
        formData.append('Ubicacion', Informe.Ubicacion);
        formData.append('Coordenadas', Informe.Coordenadas);
        formData.append('Observacion', Informe?.Observacion?.map((m: any) => { return m.value }).join('<br\>'));
        formData.append('Imagen', Informe.Imagen);
        formData.append('Video', Informe.Video);
        formData.append('AlertaId', AlertaSeleccionada.AlertaId);
        formData.append('ClienteIds', clienteIds);

        Get_EnviarInformeTorreControl(formData)
            .then((response: AxiosResponse<any>) => {
                let GestorObervaciones: any = {};
                GestorObervaciones = {
                    AlertaId: AlertaSeleccionada.AlertaId,
                    fechaapertura: DeserializarJSon(AlertaSeleccionada.Observaciones)[0].fechaapertura,
                    fechagestion: GetFechaServidor().format(formatableJson_HHMMss),
                    value: "Reporte enviado a los siguientes destinatarios:" + Informe.Contacto +
                        "\n Ruta: " + Informe.Ruta +
                        "\n Motivo: " + Informe.Motivo,
                    EsCerrado: true,
                    gestor: Operador.Nombres,
                    estadoGestion: `Gestionado`,
                    EsAuto: true
                };
                setObservaciones(clienteIds as string,
                    JSON.stringify(GestorObervaciones), tiempoCierreAuto).then((response) => {
                        toaster.push(message('success', "Gestionar", "Gestión Guardada"), {
                            placement: 'topCenter'
                        });
                    }).catch((error) => {
                        toaster.push(message('error', "Gestionar", "Error al gestionar intente nuevamente"), {
                            placement: 'topCenter'
                        });
                    });
                handleCloseInforme();
                setIsLoading(false);

                toaster.push(
                    message('success', 'Envio de notificaciones', 'Operación Éxitosa.'),
                    { placement: 'topCenter' }
                );

                GetAlertasPorUsuarios(clienteIds).then((response)=> {
                    setalertas(response.data);
                })

            }).catch((error: AxiosError<any>) => {
                toaster.push(
                    message('error', 'Envio de notificaciones', error.message.toString()),
                    { placement: 'topCenter' }
                );
                setIsLoading(false);
            });
    }
    return (<Modal
        show={showInforme}
        onHide={handleCloseInforme}
        size="lg">
        <Modal.Header className=" fw-bolder  bg-secondary rounded m-4 mb-0" style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", fontSize: 20
        }} closeButton={false}>
            <Modal.Title className="text-uppercase" style={{ fontSize: 20 }}>
                Informe de alertas</Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="row d-flex justify-content-center align-items-center">

                <div className="col-12 text-center bg-primary fw-bolder text-white fz-12">
                    REPORTE DE NOVEDAD
                </div>
                <div className="col-6 text-center fw-bolder">EVENTO</div>
                <div className="col-6 input-sm  mb-3 mt-3 rounded">{Informe.Evento}</div>
                <div className="col-6 text-center fw-bolder">HORA EVENTO</div>
                <div className="col-6 input-sm  mb-3 mt-3 rounded">{Informe.Hora}</div>
                <div className="col-6 text-center fw-bolder">OPERACIÓN</div>
                <div className="col-6 input-sm  mb-3 mt-3 rounded">
                    <Form.Select
                        title="Seleccione una operación"
                        style={{ height: '40px' }}
                        className="input-sm  mb-3 mt-3 "
                        onChange={(e: any) => {
                            let a = { ...DatosEscogidos };
                            a.Operacion = e.target.value;
                            a.Ruta = "";
                            const rutas = Informe.Ruta[e.target.value];
                            if (rutas.length > 0)
                                setDataRuta(rutas)
                            setDatosEscogidos(a);
                        }}
                        aria-label="Default select example">
                        <option value={"Seleccione"}>Seleccione</option>
                        {
                            Informe?.Operacion?.map((element: any) => {
                                return (<option key={element} defaultValue={element} value={element}>{element}</option>)
                            })
                        }
                    </Form.Select>
                </div>
                <div className="col-6 text-center fw-bolder">RUTA</div>
                <div className="col-6">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dataRuta}
                        renderInput={(params) => <TextField {...params} label="Rutas"
                        />
                        }
                        onChange={(event, newValue) => {
                            let valor = "";
                            if (typeof newValue === 'string') {
                                valor = newValue;
                            }

                            if (valor != "") {
                                let a = { ...DatosEscogidos };
                                a.Ruta = valor;
                                setDatosEscogidos(a);
                            }
                        }}
                    />
                </div>
                <div className="col-6 text-center fw-bolder">CIUDAD</div>
                <div className="col-6 input-sm  mb-3 mt-3 rounded">
                    <Form.Select
                        title="Seleccione una Ciudad"
                        style={{ height: '40px' }}
                        className="input-sm  mb-3 mt-3 "
                        onChange={(e: any) => {
                            let a = { ...DatosEscogidos };
                            a.Ciudad = e.target.value;
                            a.Zona = "";
                            const zonas = Informe.Zona[e.target.value];
                            if (zonas?.length > 0)
                                setDataZona(zonas)
                            setDatosEscogidos(a);
                        }}
                        aria-label="Default select example">
                        <option value={"Seleccione"}>Seleccione</option>
                        {
                            Informe?.Ciudad?.map((element: any) => {
                                return (<option key={element} defaultValue={element} value={element}>{element}</option>)
                            })
                        }
                    </Form.Select>
                </div>
                <div className="col-6 text-center fw-bolder">ZONA</div>
                <div className="col-6">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dataZona}
                        renderInput={(params) => <TextField {...params} label="Zonas"
                        />
                        }
                        onChange={(event, newValue) => {
                            let valor = "";
                            if (typeof newValue === 'string') {
                                valor = newValue;
                            }

                            if (valor != "") {
                                let a = { ...DatosEscogidos };
                                a.Zona = valor;
                                setDatosEscogidos(a);
                            }
                        }}
                    />
                </div>
                <div className="col-6 text-center fw-bolder">PLACA</div>
                <div className="col-6 input-sm  mb-3 mt-3 rounded">{Informe.Placa}</div>
                <div className="col-6 text-center fw-bolder">NOMBRE DE CONDUCTOR</div>
                <div className="col-6 input-sm  mb-3 mt-3 rounded">{Informe.Conductor}</div>
                <div className="col-6 text-center fw-bolder">CONTROLADOR</div>
                <div className="col-6 input-sm  mb-3 mt-3 rounded">{Informe.Operador}</div>
                <div className="col-6 text-center fw-bolder">CONTACTO</div>
                <div className="col-6 input-sm mb-3 mt-3 rounded">
                    <input
                        type="text"
                        onChange={(e: any) => {
                            let a = { ...Informe };
                            a.ContactoHa = e.target.value;
                            setInforme(a);
                        }}
                        className="form-control"
                    />
                </div>
                <div className="col-6 text-center fw-bolder">VERSIÓN CONDUCTOR</div>
                <div className="col-6 input-sm mb-3 mt-3 rounded">
                    <input
                        type="text"
                        onChange={(e: any) => {
                            let a = { ...Informe };
                            a.VersionConductor = e.target.value;
                            setInforme(a);
                        }}
                        className="form-control"
                    />
                </div>
                <div className="col-6 text-center fw-bolder">DESTINATARIOS</div>
                <div className="col-6">
                    <Autocomplete onChange={(event, newValue) => {
                        let valor = "";
                        if (typeof newValue === 'string') {
                            valor = newValue;
                        }

                        if (valor != "" && ValidaCorreo(valor)) {
                            let a = { ...DatosEscogidos };
                            a.Contacto = a.Contacto ?? [];
                            if (!a.Contacto.includes(valor)) {
                                a.Contacto.push(valor);
                                setDatosEscogidos(a);
                                setErrorCorreo("");
                            }
                            else
                                setErrorCorreo("El correo ya se encuentra en la lista.")

                        }
                        else
                            setErrorCorreo("Formato incorrecto correo.")

                    }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options as string[], params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push(inputValue);
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys

                        options={Informe?.Contacto?.map((element: any) => element.correo)}
                        getOptionLabel={(option: any) => {
                            // // Value selected with enter, right from the input
                            // if (typeof option === 'string') {
                            //   return option;
                            // }
                            // Add "xxx" option created dynamically
                            if (option) {
                                return option;
                            }
                            // Regular option
                            return option;
                        }}
                        renderOption={(props, option) => <li {...props}>{option}</li>}

                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} label="Escoja los destinatarios" />
                        )}

                    />
                    {errorCorreo}
                    {(DatosEscogidos != undefined) && DatosEscogidos?.Contacto?.map((element: any) => {
                        return (<InputGroup key={`correo_${element}`}>
                            <Button variant="outline-secondary" size="sm" id="button-addon1" onClick={() => {
                                const deleteCorreo = () => {
                                    let a = { ...DatosEscogidos };
                                    a.Contacto = DatosEscogidos.Contacto.filter((correo: any) => correo !== element);
                                    setDatosEscogidos(a);
                                }
                                confirmarDialog(deleteCorreo, "Desea remover el correo seleccionado?", "Si")
                            }}>
                                <Close></Close>
                            </Button>
                            <Form.Label className="mt-2"
                            >{element}</Form.Label>
                        </InputGroup>)
                    }
                    )}
                </div>
                <div className="col-6 text-center fw-bolder">MOTIVO VALIDACIÓN</div>
                <div className="col-6">
                    <Form.Select
                        title="Seleccione una motivo"
                        style={{ height: '40px' }}
                        className="input-sm  mb-3 mt-3 "
                        onChange={(e: any) => {
                            let a = { ...DatosEscogidos };
                            a.Motivo = e.target.value;
                            setDatosEscogidos(a);
                        }}
                        aria-label="Default select example">
                        <option value={"Seleccione"}>Seleccione</option>
                        {
                            Informe?.Motivo?.map((element: any) => {
                                return (<option key={element} defaultValue={element} value={element}>{element}</option>)
                            })
                        }
                    </Form.Select>
                </div>
                <div className="col-6 text-center fw-bolder">UBICACIÓN</div>
                <div className="col-6 input-sm  mb-3 mt-3 rounded">{Informe.Ubicacion}</div>
                <div className="col-6 text-center fw-bolder">COORDENADAS</div>
                <div className="col-6 input-sm  mb-3 mt-3 rounded">{Informe.Coordenadas}</div>
                <div className="col-6 text-center fw-bolder">OBSERVACIÓN</div>
                <div className="col-6 input-sm  mb-3 mt-3 rounded">{
                    Informe?.Observacion?.map((m: any, index: any) => {
                        return <p key={`${index}_parrafoobservaciones`}>{m.value}</p>
                    })}</div>

                <div className="col-12 ">
                    <div className="text-center">
                        <label className="label control-label fw-bolder">Adjunto Imagen</label>
                    </div>
                    <div className="controlbody">
                        <InputControl></InputControl>
                    </div>
                </div>
                <div className="col-12 ">
                    <div className="text-center">
                        <label className="label control-label fw-bolder">Adjunto Video</label>
                    </div>
                    <div className="controlbody">
                        <VideoInputControl></VideoInputControl>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div>
                <button
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                        if (DatosEscogidos?.Contacto != undefined && DatosEscogidos?.Contacto?.length > 0) {

                            Exportar(Informe, false);
                        } else
                            toaster.push(message('error', "Reporte", "Debe seleccionar un contacto antes de continuar."), {
                                placement: 'topCenter'
                            });

                    }}
                >
                    Enviar correo
                </button>
            </div>
            {isLoading && (
                <div style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: '#0077cc', // Color azul oceano
                    padding: '20px',
                    borderRadius: '8px',
                    color: '#fff', // Color del texto
                }}>
                    <h4 style={{ margin: 0 }}>Enviando Datos... </h4>
                    <br />
                    <p style={{ margin: 0 }}>Los tiempos varían según el tamaño del video</p>
                </div>
            )}
            <Button className="btn-sm" type="button" variant="secondary" onClick={handleCloseInforme}>
                Cerrar
            </Button>
        </Modal.Footer>
    </Modal>)
}


