import { Button, FormLabel, InputGroup, Modal } from "react-bootstrap-v5"
import { ConsecutivoVisual } from "../../../../data/Requerimientos"
import { EncabezadoHtml } from "../Encabezado"
import { ConsultasIniciales, useDataRequerimientos } from "../../../../core/RequerimientosCore"
import { DeserializarJSon, formatableJson_HHMMss, formatSimpleJson } from "../../../../../../../../_start/helpers/Helper"
import { useEffect, useState } from "react"
import { AxiosError, AxiosResponse } from "axios"
import moment from "moment"
import { useSelector } from "react-redux"
import { DatePicker, toaster } from "rsuite"
import confirmarDialog, { successDialog } from "../../../../../../../../_start/helpers/components/ConfirmDialog"
import { RootState } from "../../../../../../../../setup"
import { UserModelSyscaf } from "../../../../../../auth/models/UserModel"
import { MensajeError } from "../../../../../../../../_start/helpers/components/Toaster"
import "../../../../../../MODULOS/CHL/components/Modales/DetalleViajes"
import { Autocomplete, TextField } from "@mui/material"
import { Check, Clear } from "@mui/icons-material"
import { UpdateProgramacionTracking } from "../../../../data/Requerimientos/ordenprogramada"
import { FormatoColombiaDDMMYYY } from "../../../../../../../../_start/helpers/Constants"
import { isBefore } from "rsuite/esm/utils/dateUtils"

type PropsModalEditar = {
    showedit: any,
    setshowedit: any
}
export const ModalEditarProgramacion: React.FC<PropsModalEditar> = ({
    showedit,
    setshowedit
}) => {
    const { lstEstadosReq, ReqSeleccionado,
        setloader, lstTecnicos, setlstRequerimientos } = useDataRequerimientos();

    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    //para las observaciones

    const vUser = user as UserModelSyscaf;
    const [FechaOp, SetFechaOp] = useState<Date>();
    const [TecnicoAsignado, setTecnicoAsignado] = useState<any>();
    const [TecnicoActual, setTecnicoTecnicoActual] = useState<any>();
    const [ObservacionesModificar, setObservacionesModificar] = useState<string>("");
    const [Cabecera, setCabecera] = useState<any>();
    //RefTrackId
    useEffect(() => {
        const cabecera = DeserializarJSon(ReqSeleccionado.original.Cabecera);
        setCabecera(cabecera)
        const TecnicoActual = DeserializarJSon(cabecera.Tecnico)
        setTecnicoTecnicoActual(TecnicoActual ?? { label: "No seleccionado", userid: "0" })
        SetFechaOp(moment(cabecera.FechaProgramacionOP).toDate())
        setTecnicoAsignado(TecnicoActual ?? { label: "No seleccionado", userid: "0" })
    }, []);

    const SeteoObservaciones = (e: any) => {
        e.stopPropagation();
        setObservacionesModificar(e.target.value);
    }


    const Guardar = () => {
        const Cabecera = DeserializarJSon(ReqSeleccionado.original.Cabecera);
        let mensaje = 'Cambios:'
        if (Cabecera.FechaProgramacionOP !== moment(FechaOp).format(formatSimpleJson))
            mensaje += ` Fecha de Programación de  ${moment(Cabecera.FechaProgramacionOP).format(FormatoColombiaDDMMYYY)} a  ${moment(FechaOp).format(FormatoColombiaDDMMYYY)}`

        if (TecnicoActual.userid !== TecnicoAsignado.userid)
            mensaje += ` Técnico de  ${DeserializarJSon(Cabecera.Tecnico)?.label ?? 'No Asignado'} a  ${TecnicoAsignado.label}`


        let infoProgramacion = {
            Id: Cabecera.RefTrackId,
            RequerimientoId: ReqSeleccionado.original.Id,
            FechaProgramacion: moment(FechaOp).format(formatSimpleJson),
            TecnicoId: TecnicoAsignado.userid,
            Nombre: TecnicoAsignado.label,
            Modificaciones: JSON.stringify({ tipo: 'Modificacion', UsuarioId: vUser.Id, Data: mensaje }),
            ObservacionesModificar:  `Razón: ${ObservacionesModificar}  -  ${mensaje}`
        }

        confirmarDialog(() => {
            setloader(true)
            UpdateProgramacionTracking(JSON.stringify(infoProgramacion)).then((response: AxiosResponse<any>) => {
                if (response.status == 200) {
                    ConsultasIniciales(setloader, vUser, setlstRequerimientos, toaster);
                    successDialog('Actualizar Programación Requerimiento', 'Se ha actualizado correctamente');
                }
            }).catch((error: AxiosError) => {
                toaster.push(MensajeError('Actualizar Programación Requerimiento', error.message), { placement: 'topCenter' })

            }).finally(() => { setshowedit(false); setloader(false) });
        }, `¿Esta seguro que desea modificar la información del requerimiento?`, 'Guardar');
    }

    return (
        <Modal show={showedit} onHide={setshowedit} size={"lg"}>
            <Modal.Header className=" fw-bolder  rounded  m-4 d-flex justify-content-center align-items-center">
                <Modal.Title className="text-syscaf-azul">{`Edición del requerimiento ${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="row">
                    <div className="col-12 m-2">
                        <EncabezadoHtml ReqSeleccionado={ReqSeleccionado} ></EncabezadoHtml>
                    </div>

                    <div className="col-12 d-flex justify-content-between" >

                        <InputGroup >
                            <FormLabel className="fw-bold mx-4 fs-4 mt-2">Nueva Fecha:</FormLabel>
                            <DatePicker
                             disabledDate={date => isBefore(date ?? moment().toDate(), moment().add(-1, 'day').toDate())}
                            onClean={() => {
                                SetFechaOp(moment(Cabecera.FechaProgramacionOP).toDate())
                            }} value={FechaOp} oneTap className="mx-4 mb- 1mt-1" format="dd/MM/yyyy"
                                onChange={(value: any, event: any) => {
                                    SetFechaOp(value);
                                }}></DatePicker>
                            {FechaOp == undefined ? (<Clear className="mt-4 mx-2" color="error" />) : (<Check className="mt-4 mx-2" color="success" />)}
                        </InputGroup>
                        <InputGroup >
                            <FormLabel className="fw-bold mx-4 fs-4 mt-2">Técnico:</FormLabel>
                            <Autocomplete size="medium" style={{ width: 200 }}
                                isOptionEqualToValue={(option, value) => option.userid === value.userid}
                                //getOptionLabel={(option) => option.nombreCompuesto}
                                onChange={(event, newValue) => {
                                    if (newValue != null) {
                                        setTecnicoAsignado(newValue)
                                    } else setTecnicoAsignado(undefined)
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                options={lstTecnicos?.map((e: any) => {
                                    return { label: e.Nombres, userid: e.Id }
                                })}
                                renderInput={(params) => (
                                    <TextField {...params} label="Escoja" />
                                )}

                            />
                            {TecnicoAsignado == undefined ? (<Clear className="mt-4 mx-2" color="error" />) : (<Check className="mt-4 mx-2" color="success" />)}

                        </InputGroup>
                    </div>
                    <div className="col-12">
                        <div className="form-control-sm">
                            <FormLabel className="fw-bold fs-4 mt-2">Observaciones:</FormLabel>
                            <textarea className="form-control" rows={3} onChange={
                                SeteoObservaciones
                            }></textarea>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btn-sm" variant="primary" onClick={() => {
                     Guardar(); // guardamos la informacion del requerimiento 

                }}

                    disabled={(moment(FechaOp).format(formatSimpleJson) === Cabecera?.FechaProgramacionOP)
                        || (TecnicoAsignado?.label == TecnicoActual?.label) && ObservacionesModificar == ''}
                >
                    Guardar
                </Button>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => setshowedit(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
