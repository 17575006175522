import { createContext, useContext, useEffect, useState } from "react";
import { ClientesData, FiltroDashBoardBasic } from "../data/dashboard";
import { GetClientesAdministradores } from "../../../Seguridad/Administracion/data/Clientes";
import { getClientesDash, GetFallasSeniales, GetInfoDashBoardAdminAsset, GetInfoDashBoardAdminVehiculosSinTx } from "../data/PostVentaData";
import { AxiosError, AxiosResponse } from "axios";
import { FiltroData, GetConfiguracion, GetRequerimientosDashboard } from "../data/Requerimientos";
import { GetEstadosTransmision } from "../../Tx/data/Reporte";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../setup";

export interface DashBoardContextModel {
    loader: any;
    datosClientes: any;
    lstVehiculosSinTx: any[];
    lstAdminFlota: any[];
    lstClientesAdmin: any[];
    lstEstadosReq: any[];
    lstEstadosSinTx: any[];
    lstRequerimientos: any[];
    lstFallaSeniales: any[];
    ClienteSeleccionado: any;
    AdminSeleccionado: any;
    setloader: (loader: any) => void;
    setlstVehiculosSinTx: (listado: any[]) => void;
    setlstAdminFlota: (listado: any[]) => void;
    setlstClientesAdmin: (listado: any[]) => void;
    setdatosClientes: (datos: any) => void;
    setlstEstadosReq: (datos: any) => void;
    setlstEstadosSinTx: (datos: any) => void;
    setlstRequerimientos: (datos: any) => void;
    setlstFallaSeniales: (datos: any) => void;
    setClienteSeleccionado: (datos: any) => void;
    SetAdminSeleccionado: (datos: any) => void;
}
const ServcContext = createContext<DashBoardContextModel>({
    loader: {},
    datosClientes: {},
    lstVehiculosSinTx: [],
    lstAdminFlota: [],
    lstClientesAdmin: [],
    lstEstadosReq: [],
    lstEstadosSinTx: [],
    lstRequerimientos: [],
    lstFallaSeniales: [],
    ClienteSeleccionado: {},
    AdminSeleccionado: {},
    setloader: (_listado: any) => { },
    setlstVehiculosSinTx: (_listado: any[]) => { },
    setlstAdminFlota: (_listado: any[]) => { },
    setlstClientesAdmin: (_listado: any[]) => { },
    setdatosClientes: (_listado: any) => { },
    setlstEstadosReq: (_listado: any) => { },
    setlstEstadosSinTx: (_listado: any) => { },
    setlstRequerimientos: (_listado: any) => { },
    setlstFallaSeniales: (_listado: any) => { },
    setClienteSeleccionado: (_listado: any) => { },
    SetAdminSeleccionado: (_listado: any) => { },
});


export const RequerimientosProvider: React.FC = ({ children }) => {

    const [loader, setloader] = useState<any>({
        UnidadesActivas: false,
        Seniales: false,
        // Drivers: false,
        //Tickets: false,
        SinTx: false,
        //Clientes: false,
        Requerimientos: false,
        Admin: false,
        EstadosReq: false,
        EstadosSinTx: false,
        LoaderGeneral: function () {
            const array = [this.UnidadesActivas, this.Seniales, this.SinTx, this.Requerimientos, this.Admin
            ]
            return array.reduce((sum, next) => sum + next, 0);
        },
        lstErrors: []
    });
    const Clientesseleccionados = { "ClienteId": "0", "clienteNombre": "Todas las bases" };
    const Administradorseleccionados = { "UsuarioId": "0", "Nombres": "Todos" };
    //Objeto para el uso de los clientes y administradores.
    const [AdminSeleccionado, SetAdminSeleccionado] = useState<any>(Administradorseleccionados);
    const [ClienteSeleccionado, setClienteSeleccionado] = useState<any>(Clientesseleccionados);
    const [lstVehiculosSinTx, setlstVehiculosSinTx] = useState<any[]>([]);
    const [lstAdminFlota, setlstAdminFlota] = useState<any[]>([]);
    const [lstClientesAdmin, setlstClientesAdmin] = useState<any[]>([]);
    const [datosClientes, setdatosClientes] = useState<any>(ClientesData);
    const [lstEstadosReq, setlstEstadosReq] = useState<any[]>([]);
    const [lstEstadosSinTx, setlstEstadosSinTx] = useState<any[]>([]);
    const [lstRequerimientos, setlstRequerimientos] = useState<any[]>([]);
    const [lstFallaSeniales, setlstFallaSeniales] = useState<any[]>([]);
    const value: DashBoardContextModel = {
        loader, lstVehiculosSinTx, lstAdminFlota, lstClientesAdmin, datosClientes, lstEstadosReq,
        lstEstadosSinTx, lstRequerimientos, lstFallaSeniales,
        setloader, setlstVehiculosSinTx, setlstAdminFlota, setlstClientesAdmin,
        setdatosClientes, setlstEstadosReq, setlstEstadosSinTx, setlstRequerimientos,
        setlstFallaSeniales,
        AdminSeleccionado, SetAdminSeleccionado,
        ClienteSeleccionado, setClienteSeleccionado
    };
    return (<ServcContext.Provider value={value}> {children} </ServcContext.Provider>);
};

/** */
export function useDataDashBoard() {
    return useContext(ServcContext);
}



//Hace toda la magia de ir al servidor, traerse los datos y setearlos
export const GetDataDashBoard: React.FC = ({ children }) => {
    const { setloader, loader, setlstClientesAdmin, setdatosClientes, datosClientes,
        setlstAdminFlota, setlstVehiculosSinTx, setlstEstadosReq, setlstEstadosSinTx, setlstRequerimientos,
        setlstFallaSeniales, lstClientesAdmin
    } = useDataDashBoard();
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    // iniciamos la cadena de consultas
    // primero todo aquellos que no tiene relación con los demás datos y puede consultarse de forma independiente

    useEffect(() => {
        // INFORMACION CLIENTE Y ADMINISTRADORES
        const fetchData = async () => {
            setloader({ ...loader, Admin: true });
            await GetClientesAdministradores(null).then(
                (result) => {
                    let Clientes = getClientesDash(result.data);
                    setlstClientesAdmin(Clientes); // setea los clientes relacionados con  los administradores de flota

                    setloader({ ...loader, Admin: false });
                }).catch((e: AxiosError) => {
                    let lstloader = { ...loader, Admin: false };
                    lstloader.push(`Error al consutar los clientes ${e.message}`)
                    setloader(lstloader);
                });



            // INFORMACION DE LOS ASSETS SEGUN USUARIOS Y CON ADMINSITRADORES RELACIONOADOS
            setloader({ ...loader, UnidadesActivas: true });
            await GetInfoDashBoardAdminAsset().then(
                (result) => {
                    let data = result.data;
                    setlstAdminFlota(data);
                    // setDatosOriginales(Original);
                    setloader({ ...loader, UnidadesActivas: false });
                }).catch((e: AxiosError) => {
                    let lstloader = { ...loader, UnidadesActivas: false };
                    lstloader.push(`Error al consutar los Vehiculos ${e.message}`)
                    setloader(lstloader);
                });

            // INFORMACION DE LOS VEHICULOS SIN TRANSMISION
            setloader({ ...loader, SinTx: true });
            await GetInfoDashBoardAdminVehiculosSinTx().then(
                (result) => {
                    setlstVehiculosSinTx(result.data);
                    setloader({ ...loader, SinTx: false });
                }).catch((e: AxiosError) => {
                    let lstloader = { ...loader, SinTx: false };
                    lstloader.push(`Error al consutar los vehículos sin TX ${e.message}`)
                    setloader(lstloader);
                });

            // SE TRAE LOS ESTADOS DE LOS REQUERIMIENTOS CON EL FIN DE CONTABILIZAR LOS INDICADORES DE REQUERIMIENTOS
            setloader({ ...loader, EstadosReq: true });
            GetConfiguracion(null).then((response: AxiosResponse<any>) => {
                if (response.data.length != 0) {

                    let States = FiltroData.getConfiguracionSigla(response.data, "OERS");
                    setlstEstadosReq(States[0]);

                }
                setloader({ ...loader, EstadosReq: true });
            }).catch(({ e }) => {
                let lstloader = { ...loader, EstadosReq: false };
                lstloader.push(`Error al consutar los estados de los requerimientos ${e.message}`)
                setloader(lstloader);
            })

            // SE TRAEN NLOS ESTADOS DE VEHICULOS SIN TRANSMISION PARA MOSTRAR EN EL MODAL LA INFO 
            setloader({ ...loader, EstadosSinTx: true });
            GetEstadosTransmision("3").then((response: AxiosResponse<any>) => {
                setlstEstadosSinTx(response.data);
                setloader({ ...loader, EstadosSinTx: false });
            }).catch(({ e }) => {
                let lstloader = { ...loader, EstadosSinTx: false };
                lstloader.push(`Error al consutar los estados de los requerimientos ${e.message}`)
                setloader(lstloader);
            });


            setloader({ ...loader, Requerimientos: true });
            //Obtengo los requimientos del sistema para revisar cuales vehiculos tienen algun requerimiento ya creado
            await GetRequerimientosDashboard().then((response: AxiosResponse<any>) => {
                if (response.data.length != 0) {
                    setlstRequerimientos(response.data);
                }
                setloader({ ...loader, Requerimientos: false });
            }).catch(({ e }) => {
                let lstloader = { ...loader, Requerimientos: false };
                lstloader.push(`Error al consutar los Requerimientos ${e.message}`)
                setloader(lstloader);
            });
        }
        fetchData()
        return () => {
            setlstClientesAdmin([]);
            setlstAdminFlota([]);
            setlstVehiculosSinTx([]);
            setlstEstadosReq([]);
            setlstEstadosSinTx([]);
            setlstRequerimientos([]);
        }
    }, []);


    useEffect(() => {
        // traemos la informacion de las seniales
        if (lstClientesAdmin.length > 0)
            setloader({ ...loader, Seniales: true });
        GetFallasSeniales(FiltroDashBoardBasic.getListadoClientesByClienteIds(lstClientesAdmin).join()).then((result: AxiosResponse<any>) => {
            setlstFallaSeniales(result.data);
            setloader({ ...loader, Seniales: false });
        }).catch(({ e }) => {
            let lstloader = { ...loader, Seniales: false };
            lstloader.push(`Error al consutar las seniales ${e.message}`)
            setloader(lstloader);
        });

    }, [lstClientesAdmin]);
    return <></>;

};

