/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDataFatigue } from "../../../core/provider";

import { getCSSVariableValue } from "../../../../../../../_start/assets/ts/_utils";
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

type Props = {
    className: string;
    innerPadding?: string;
    tipoData?: number;
    nameChart?: string;
    titulo: string;
};

export const IndicadorOperando: React.FC<Props> = ({ className, nameChart = "clasificacionFlota_", titulo }) => {


    const { vehiculosOperacion } = useDataFatigue();

    const colorsuccess = getCSSVariableValue("--bs-success");
    const colordanger = "#424749";



    let colorsArray: string[] = [colorsuccess, colordanger];

    const [opciones, setopciones] = useState<any>();

    //Pintamos indicador 2 donna
    useEffect(() => {



        if (Object.keys(vehiculosOperacion).length > 0) {
            let arrayChart : any[] = Object.values(vehiculosOperacion);
            // se determina de que tipo se necesita la informacion para mostrarla en los indicadores
            let labelsArray = Object.keys(vehiculosOperacion);
            let opciones: ApexOptions ={
                chart: {
                  id: nameChart,
                  fontFamily: 'Montserrat',
                  type: 'donut',
                },
                series: arrayChart,
                colors: colorsArray,
                labels: labelsArray,
                legend: {
                    show: true,
                    fontSize: '10px',
                    position: 'bottom',
                    formatter: function (seriesName: any, opts: any) {
                        return [seriesName, "(", opts.w.globals.series[opts.seriesIndex], ")"].join(' ')
                    }
                },
                dataLabels: {
                  enabled: true,
                  formatter: function (val: number) {
                    return val.toFixed(1) + "%"
                  },
                },
                theme :{
                   palette: 'palette2'
                },                  
                   
                title: {
                    text: titulo,
                    align: 'center',
                }
            ,
                plotOptions:{
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                value: {
                                    show: true,
                                    fontSize: '21px',
                                    offsetY: -7
                                },
                                total: {
                                    showAlways: true,
                                    show: true,
                                    fontSize: '12px',
                                }
                            }
                        }
                    }
                }
            
              };
            
            
           
            setopciones(opciones);
        }

    }, [vehiculosOperacion]);
    return (
        <div className={`card ${className}`}>
            {(opciones != undefined) && (
                <Chart key={`chardonaalertascriticas_${nameChart}`} options={opciones} series={opciones.series}
                    type="donut" />
            )}
        </div>
    );
};





