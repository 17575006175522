/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useDataFatigue } from "../../../core/provider";
import { getCSSVariableValue } from "../../../../../../../_start/assets/ts/_utils";

type Props = {
    className: string;
    innerPadding?: string;
    tipoData?: number;
    nameChart?: string;
    titulo: string;
};

export const IndicadorRiesgo: React.FC<Props> = ({ className, nameChart = "clasificacionFlota_", titulo }) => {


    const { alertas } = useDataFatigue();

    const colorsuccess = getCSSVariableValue("--bs-success");
    const colorwarning = getCSSVariableValue("--bs-warning");
    const colordanger = getCSSVariableValue("--bs-danger");



    let colorsArray: string[] = [colordanger ,colorwarning,colorsuccess,  ];
  
    const [opciones, setopciones] = useState<any>();

    //Pintamos indicador 2 donna
    useEffect(() => {

        let agrupadocriticidad = alertas
        .reduce((p: any, c: any) => {
          let name = c.Criticidad;
          p[name] = p[name] ?? [];
          p[name].push(c);
          return p;
        }, {});

        let criticidad = {};
  
      if (Object.keys(agrupadocriticidad).length > 0) {
        criticidad =   {
          "Alto": agrupadocriticidad['Riesgo alto'] == undefined ? 0 : agrupadocriticidad['Riesgo alto'].length,
          "Moderado": agrupadocriticidad['Riesgo moderado'] == undefined ? 0 : agrupadocriticidad['Riesgo moderado'].length,
          "Bajo": agrupadocriticidad['Riesgo bajo'] == undefined ? 0 : agrupadocriticidad['Riesgo bajo'].length
        };
      }
        if (Object.keys(agrupadocriticidad).length > 0) {
            let arrayChart: any[] = Object.values(criticidad);
            // se determina de que tipo se necesita la informacion para mostrarla en los indicadores
            let labelsArray: any[] = Object.keys(criticidad);
            let opciones: ApexOptions = {
                
                series: arrayChart,
         
                    colors: colorsArray,
                    chart: {
                        id: nameChart,
                        fontFamily: 'Montserrat',
                        type: 'donut',
                        dropShadow: {
                            enabled: true,
                            color: '#111',
                            top: -1,
                            left: 3,
                            blur: 3,
                            opacity: 0.2
                        }
                    },
                    stroke: {
                        width: 0,
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    value: {
                                        show: true,
                                        fontSize: '21px',
                                        offsetY: -7
                                    },
                                    total: {
                                        showAlways: true,
                                        show: true,
                                        fontSize: '12px',
                                    }
                                }
                            }
                        }
                    },
                    labels: labelsArray,
                    dataLabels: {
                        enabled: true,
                        formatter: function (val: number) {
                            return val.toFixed(1) + "%"
                        },
                    },
                    legend: {
                        show: true,
                         fontSize: '10px',
                        position: 'bottom',
                        formatter: function(seriesName : any, opts: any) {
                            return [seriesName, "(", opts.w.globals.series[opts.seriesIndex], ")"].join(' ')
                        }
                    },                  
                   
                    title: {
                        text: titulo,
                        align: 'center',
                    }
                


            };
            setopciones(opciones);
        }

    }, [alertas]);
    return (
        <div className={`card ${className}`}>
            {(opciones != undefined) && (
                <Chart key={`chardonaalertascriticas_${nameChart}`} options={opciones} series={opciones.series}
                    type="donut" />
            )}
        </div>
    );
};





