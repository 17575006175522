import axios from "axios";
import moment from "moment";
import { ARCHIVO_DownloadFileBase64, DWH_getconsultadinamicasprocedure, MOVIL_getReportesPorTipo, NEP_DownloadFileBase64, NEP_UploadFile } from "../../../../../apiurlstore";

import { Post_getConsultasDinamicasConAuthCore } from "../../../../../_start/helpers/Axios/CoreService";
import { formatSimpleJson } from "../../../../../_start/helpers/Helper";
import { errorDialog } from "../../../../../_start/helpers/components/ConfirmDialog";
const contenedor = 'preoperacional';

export function getVehiculosOperando(ClienteIds: string, Fecha: string) {

  var params: { [id: string]: string | null; } = {};
  params["ClienteIds"] = ClienteIds;
  params["Fecha"] = Fecha;
  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedure,
    data: JSON.stringify(params),
    headers: { 'Content-Type': 'application/json' },
    params: { Clase: "FATGQueryHelper", NombreConsulta: "GetVehiculosOperacion" }
  });
};

export function getEncabezados(ClienteId: string, Fecha: string, FechaF: string, EncabezadoId: string | null) {
  var params: { [id: string]: string | null; } = {};
  params["ClienteId"] = ClienteId;
  params["FechaInicial"] = Fecha;
  params["FechaFinal"] = FechaF;
  params["UsuarioId"] = null;
  params["EncabezadoId"] = EncabezadoId;

  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "MOVQueryHelper",
    NombreConsulta: "GetPreoperacionalByUsuario",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function getVehiculosSinPreoperacional(ClienteIds: string, Fecha: string) {

  var params: { [id: string]: string | null; } = {};
  params["ClienteIds"] = ClienteIds;
  params["Fecha"] = Fecha;

  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedure,
    data: JSON.stringify(params),
    headers: { 'Content-Type': 'application/json' },
    params: { Clase: "MOVQueryHelper", NombreConsulta: "GetEventosSubtrips" }
  });
};



export function setGestor(UserId: string, Observaciones: string, EsGestionado: boolean, EncabezadoId: number
  , ClienteIds: string, isHistorical: boolean

) {
  var params: { [id: string]: string | null; } = {};
  params["UserId"] = UserId;
  params["Observaciones"] = Observaciones;
  params["EsGestionado"] = `${EsGestionado ? 1 : 0}`;
  params["EncabezadoId"] = EncabezadoId.toString();
  params["ClienteIds"] = ClienteIds.toString();
  params["isHistorical"] = `${isHistorical ? 1 : 0}`;

  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "MOVQueryHelper",
    NombreConsulta: "SetGestor",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function setObservaciones(Observaciones: string, ClienteIds: string, isHistorical: any) {
  var params: { [id: string]: string | null; } = {};
  params["data"] = Observaciones;
  params["ClienteIds"] = ClienteIds.toString();
  params["isHistorical"] = `${isHistorical ? 1 : 0}`;

  // hacemos la consulta 
  return Post_getConsultasDinamicasConAuthCore({
    Clase: "MOVQueryHelper",
    NombreConsulta: "SetObservaciones",
    Pagina: null,
    RecordsPorPagina: null
  },
    params);
};

export function GetReportePorTipo(ClienteId: string, FechaInicial: any, FechaFinal: any, TipoReporte: number) {

  var params: { [id: string]: string | null; } = {};
  params["ClienteId"] = ClienteId;
  params["FechaInicial"] = (FechaInicial != null) ? moment(FechaInicial).format(formatSimpleJson) : FechaInicial;
  params["FechaFinal"] = (FechaFinal != null) ? moment(FechaFinal).format(formatSimpleJson) : FechaInicial;
  params["Tipo"] = TipoReporte.toString();
  return axios({
    method: 'get',
    url: MOVIL_getReportesPorTipo,
    headers: { 'Content-Type': 'application/json' },
    params: params
  });
}


export async function DescargarFileBase64(nombrearchivo: string) {
  return axios({
    method: 'get',
    url: ARCHIVO_DownloadFileBase64,
    params: { nombrearchivo, container: contenedor }

  })

}


export async function Mov_cargarArchivo(archivo: any, path: string, callback : any) {
  const formData = new FormData();
  formData.append("archivo", archivo);
  formData.append("src", path);
  formData.append('nombre', ``);
  formData.append('contenedor', contenedor);
  axios({
    method: 'post',
    url: NEP_UploadFile,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(
    t => {
      if (t.status == 200) // si todo bien procedemos a guardar las observaciones
        {
          callback(t.data);// la respuesta es la url del servicio
          // que sera guardado en las observaciones para su posterior descargue
        }
   
      else {
        errorDialog("Error al cargar archivo", `<i>${t.data}.</i>`);
 
      }
    }
  ).catch((e) => {
    errorDialog("Error al cargar archivo", "<i>Favor comunicarse con su administrador.</i>");
  
  });

}

