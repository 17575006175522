import { useToaster } from "rsuite";
import { ObtenerOrganizaciones, useDataUsuarios } from "../../../core/usuariosProvider";
import { MensajeError, MensajeSuccess } from "../../../../../../../_start/helpers/components/Toaster";

import { useEffect, useRef, useState } from "react";
import DualListBox from "react-dual-listbox";
import { dualList, dualListReact } from "../../../models/GruposSeguridadModels";
import { ComponenteModulo, ComponenteRolesModulo, ECO_ConsultasRoles, GetListaRolPorOrganizacion, GetOpcionesPorRol, GetOpcionesPorUsuario, SetOrganizacionesUsuario, SetRolesUsuario } from "../../../data/usuariosRolesdata";
import { AxiosError, AxiosResponse } from "axios";
import BlockUi from "react-block-ui";
import confirmarDialog from "../../../../../../../_start/helpers/components/ConfirmDialog";
import TreeView from "./TreeView";
import { DeserializarJSon } from "../../../../../../../_start/helpers/Helper";
export const Eco_TabModuloyRoles: React.FC = () => {
    const toaster = useToaster();

    const { usuarioSel, lstOrganizaciones, setloader, setlstOrganizaciones } = useDataUsuarios();


    const [lstModulos, setlstModulos] = useState<dualList[]>([]);
    const [modulosSel, setmodulosSel] = useState<string[]>([]);
    const [lstRolesOrg, setlstRolesOrg] = useState<dualListReact[]>([]);
    const [lstRolesSel, setlstRolesSel] = useState<string[]>([]);
    const [moduloSel, setModuloSel] = useState<number>(0);
    const [moduloRolSel, setModuloRolSel] = useState<number>(0);
    const [loadRoles, setLoadRoles] = useState<boolean>(false);
    const [loadOrganizacionees, setloadOrganizacionees] = useState<boolean>(false);
    const [loadMenuRoles, setLoadMenuRoles] = useState<boolean>(false);
    const [lstOpcionesRol, setlstOpcionesRol] = useState<any[]>([]);
    const [lstOpcionesUsuario, setlstOpcionesUsuario] = useState<any[]>([]);

    useEffect(() => {
        // disparador inicial el cual actualiza las listas dependiendo del usuario seleccionado
        if (lstOrganizaciones.length > 0) {
            setlstModulos(ECO_ConsultasRoles.getOrganizacionesDual(lstOrganizaciones));
            setmodulosSel(ECO_ConsultasRoles.getOrgAsignadas(lstOrganizaciones, usuarioSel.Id))

            ConsultarOpcionesUsuario();

        }
        return () => { // cuando se desmonte el componente se libere espacio de memoria
            setlstModulos([]);
            setmodulosSel([]);
            setlstRolesOrg([]);
        }
    }, [lstOrganizaciones]);

    useEffect(() => {
        // se dispara cada vez que se seleccione un módulo para llenar el dropdownlist de roles y 
        // que se pueda seleccionar los roles que se tengan disponibles para asingar o quitar
        setlstOpcionesRol([]);
        if (moduloSel != 0)
            GetRolesPorOrganizacion();
        else {
            setlstRolesOrg([])
            setlstRolesSel([])
        }

    }, [moduloSel]);

    useEffect(() => {
        // se dispara cada vez que se seleccione un módulo para llenar el dropdownlist de roles y 
        // que se pueda seleccionar los roles que se tengan disponibles para asingar o quitar
        if (moduloRolSel != 0)
            ConsultarOpcionesRol();
        else
            setlstOpcionesRol([]);

    }, [moduloRolSel]);

    const GetRolesPorOrganizacion = () => {
        setloadOrganizacionees(true);
        GetListaRolPorOrganizacion(moduloSel).then((response: AxiosResponse) => {
            if (response.status == 200) {
                setlstRolesOrg(ECO_ConsultasRoles.getRolesUsuarioDual(response.data));
                setlstRolesSel(ECO_ConsultasRoles.getRolesUsuarioAsignadas(response.data, usuarioSel.Id));

            }
        }
        ).catch((error: AxiosError) => {
            toaster.push(MensajeError('Error al cargar Roles', 'Favor actualice la página para recargar la información'), {
                placement: 'topCenter'
            })
        }).finally(() => { setloadOrganizacionees(false) });
    }
    const SetOrganizacionesUsuarios = (lstOrganizaciones: string[]) => {
        setLoadRoles(true);
        SetOrganizacionesUsuario(lstOrganizaciones, usuarioSel.Id).then((response: AxiosResponse) => {
            if (response.status == 200) {
                toaster.push(MensajeSuccess('Guardar Organizaciones Usuario', 'Actualizado exitosamente.'), {
                    placement: 'topCenter'
                });
                setmodulosSel(lstOrganizaciones);
                ObtenerOrganizaciones(toaster, setloader, setlstOrganizaciones);
                ConsultarOpcionesUsuario();
            }
        }
        ).catch((error: AxiosError) => {
            toaster.push(MensajeError('Guardar Organizaciones Usuario', 'Favor actualice la página para recargar la información'), {
                placement: 'topCenter'
            })
        }).finally(() => { setLoadRoles(false) });
    }

    const GuardarRolesUsuario = (lstRoles: string[]) => {
        setLoadRoles(true);
        SetRolesUsuario(lstRoles, moduloSel, usuarioSel.Id).then((response: AxiosResponse) => {
            if (response.status == 200) {
                toaster.push(MensajeSuccess('Guardar Roles Usuario', 'Actualizado exitosamente.'), {
                    placement: 'topCenter'
                });
                setlstRolesSel(lstRoles);
                
                // consultamos nuevamente el menú para actualizar con el rol asingado
                ConsultarOpcionesUsuario();
            }
        }
        ).catch((error: AxiosError) => {
            toaster.push(MensajeError('Guardar Roles Usuario', 'Favor actualice la página para recargar la información'), {
                placement: 'topCenter'
            })
        }).finally(() => { setLoadRoles(false) });
    }
    const ConsultarOpcionesRol = () => {
        setLoadMenuRoles(true);
        GetOpcionesPorRol(moduloRolSel).then((response: AxiosResponse) => {
            if (response.status == 200) {
                if (response.data[0].Opciones != null)
                    setlstOpcionesRol(DeserializarJSon(response.data[0].Opciones));
                else
                    setlstOpcionesRol([]);
            }
        }
        ).catch((error: AxiosError) => {
            toaster.push(MensajeError('Error Consultar Opciones Roles', 'Favor actualice la página para recargar la información'), {
                placement: 'topCenter'
            })
        }).finally(() => { setLoadMenuRoles(false) });
    }

    const ConsultarOpcionesUsuario = () => { 
        setLoadMenuRoles(true);
        GetOpcionesPorUsuario(usuarioSel.Id).then((response: AxiosResponse) => {
            if (response.status == 200) {
                if (response.data[0].Opciones != null)
                    { const opciones  = ECO_ConsultasRoles.getOpcionesUsuario(  DeserializarJSon(response.data[0].Opciones));
                        setlstOpcionesUsuario (opciones);
                        console.log(opciones)
                    }
                   
                else
                    setlstOpcionesUsuario([]);
            }
        }
        ).catch((error: AxiosError) => {
            toaster.push(MensajeError('Error Consultar Opciones Roles', 'Favor actualice la página para recargar la información'), {
                placement: 'topCenter'
            })
        }).finally(() => { setLoadMenuRoles(false) });
    }



    function SelectModulos() {
        return (<> {lstModulos.length > 0 ?
            (<DualListBox className=" mb-3 " canFilter
                options={lstModulos}
                selected={modulosSel}
                onChange={(selected: string[]) => {
                    const callback = () => {
                        SetOrganizacionesUsuarios(selected)
                    }
                    confirmarDialog(callback, 'Guardar Módulos', 'Guardar');
                    //
                }}
            />
            ) : (<></>)}</>);
    }
    function SelectRoles() {
        return (<> {lstRolesOrg.length > 0 ?
            (<DualListBox className=" mb-3 " canFilter
                options={lstRolesOrg}
                selected={lstRolesSel}
                onChange={(selected: string[]) => {
                    const callback = () => {
                        GuardarRolesUsuario(selected)
                    }
                    confirmarDialog(callback, 'Guardar Roles', 'Guardar');

                }


                }
            />
            ) : (<></>)}</>);
    }


    return (<>
        <div className="row m-2">
            <div className="col-6">
                <span style={{ width: '100%', textAlign: 'center' }} className="fs-2 fw-bold">Módulos</span>
                <div className="mt-1 d-flex justify-content-start">
                    <BlockUi loader={loadOrganizacionees} message='Guardando Módulos.. favor espere'>
                        <SelectModulos />
                    </BlockUi>
                </div>
            </div>
            <div className="col-6 mt-1" >
                <span style={{ width: '100%', textAlign: 'center' }} className="fs-2 fw-bold">Preview Acceso</span>
                <div className="container h-400" >
                    <BlockUi loader={loadMenuRoles} message='Cargando vista favor espere...'>
                        <TreeView data={lstOpcionesUsuario} />
                    </BlockUi>
                </div>
            </div>
        </div>
        <div className="row m-2">

            <div className="col-6">
                <span style={{ width: '100%', textAlign: 'center' }} className="fs-2 fw-bold">Roles Módulos</span>
                <ComponenteModulo moduloSel={moduloSel} lstModulo={ECO_ConsultasRoles.getOrgAsignadasDropDownList(lstOrganizaciones, usuarioSel.Id)} setModuloSel={setModuloSel} />
                <BlockUi loader={loadRoles} message='Cargando roles.. favor espere'>
                    <SelectRoles />
                </BlockUi>
            </div>
            <div className="col-6">
                <span style={{ width: '100%', textAlign: 'center' }} className="fs-2 fw-bold">Preview Role</span>
                <ComponenteRolesModulo moduloRolSel={moduloRolSel} lstRolesModulo={lstRolesOrg} setRolModuloSel={setModuloRolSel} />
                <div className="container h-400" >
                    <BlockUi loader={loadMenuRoles} message='Cargando vista favor espere...'>
                        <TreeView data={lstOpcionesRol} />
                    </BlockUi>
                </div>
            </div>
        </div>
    </>)
}