import { Button, Modal } from "react-bootstrap-v5"
import {  EncabezadoHtml,  SeleccionAgentes, SeleccionClientes, SeleccionEstados } from "./Encabezado"
import React, { useState } from "react"
import { ConsecutivoVisual, getTablaDetalleRequerimiento } from "../../../data/Requerimientos"
import { DeserializarJSon } from "../../../../../../../_start/helpers/Helper"

type PropsModalFiltros = {
    ShowFiltros: any,
    setShowFiltros: any,
    Clientes: any,
    ClienteSeleccionado: any,
    setClienteSeleccionado: any,
    Agentes: any,
    setAgentesSeleccionado: any,
    AgentesSeleccionado: any,
    Estados: any,
    setEstadoSeleccionado: any,
    EstadoSeleccionado: any
}

export const ModalFiltros: React.FC<PropsModalFiltros> = ({
    ShowFiltros,
    setShowFiltros,
    Clientes,
    ClienteSeleccionado,
    setClienteSeleccionado,
    Agentes,
    setAgentesSeleccionado,
    AgentesSeleccionado,
    Estados,
    setEstadoSeleccionado,
    EstadoSeleccionado
}) => {
    return (
        <Modal show={ShowFiltros} onHide={setShowFiltros} size={"lg"}>
            <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                <Modal.Title>Filtros por clientes, agentes y estados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-6">
                        <div className="form-control-sm">
                            <label className="control-label label label-sm" style={{ fontWeight: 'bold' }}>Clientes: </label>
                            <SeleccionClientes Clientes={Clientes} ClienteSeleccionado={ClienteSeleccionado} setClienteSeleccionado={setClienteSeleccionado} />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-control-sm">
                            <label className="control-label label label-sm" style={{ fontWeight: 'bold' }}>Agentes: </label>
                            <SeleccionAgentes Agentes={Agentes} setAgentesSeleccionado={setAgentesSeleccionado} AgentesSeleccionado={AgentesSeleccionado} />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-control-sm">
                            <label className="control-label label label-sm" style={{ fontWeight: 'bold' }}>Estados: </label>
                            <SeleccionEstados Estados={Estados} setEstadoSeleccionado={setEstadoSeleccionado} EstadoSeleccionado={EstadoSeleccionado} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => setShowFiltros(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}



type PropsModalDetalles = {
    show: any,
    setshow: any,
    ReqSeleccionado: any,
    Detalles: any,
    GuardarObservaciones: any,
    UserActual : any
}
export const ModalDetalles: React.FC<PropsModalDetalles> = ({ show, setshow, ReqSeleccionado,  
    GuardarObservaciones, UserActual }) => {
    const DetallesDatos: any[] = DeserializarJSon(ReqSeleccionado.original.Observaciones)
    const EstadoActual : any = DeserializarJSon(ReqSeleccionado.original.Estado)
    const [textIndicador, settextIndicador] = useState("Adicionar")
    const [comentario, SetComentario] = useState("")
    const [isVisible, initHs] = useState(false)
    const invokeCollapse = () => {
        settextIndicador(!isVisible ? "Cerrar" : "Adicionar")
        return initHs(!isVisible)
    }
    return (
        <Modal show={show} onHide={setshow} size={"lg"}>
            <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                <Modal.Title>{`Detalles del Requerimiento ${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <EncabezadoHtml ReqSeleccionado={ReqSeleccionado} ></EncabezadoHtml>
                        </div>
                        <div className="col-12">
                            {(EstadoActual.tipoestado !== "closed")&& (  <Button className=" p-2 mb-2 ms-2 mt-2" onClick={invokeCollapse}>
                                {`${textIndicador}  Comentarios`}
                            </Button>)}
                          
                            {(isVisible) && (<div className="col-12">
                                <div className="">
                                    <label className="label label-sm fw-bolder">Nuevo Comentario</label>
                                </div>
                                <textarea className="form-control" value={comentario} onChange={(ff) => {

                                    SetComentario(ff.target.value)
                                }} />
                                <Button className=" p-2 mb-2 ms-2 mt-2" onClick={() => {
                                    if (comentario != ""){
                                        GuardarObservaciones(comentario);
                                        SetComentario("");
                                        invokeCollapse();
                                    }
                                }}>
                                    Adicionar
                                </Button>
                            </div>)}
                        </div>


                        <div className="col-12">
                            {(DetallesDatos.length != 0) && (show) && (
                                getTablaDetalleRequerimiento(DetallesDatos, UserActual)
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => setshow(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


