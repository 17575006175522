import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { useEffect, useState } from "react"
import TreeView from "./TreeView";
import { DeserializarJSon } from "../../../helpers/Helper";

export function AsideMenuMain() {
  // informacion del usuario almacenado en el sistema

  const menu = useSelector<RootState>(
    ({ auth }) => auth.menu
  );
  const [lstmenuview, setmenuview] = useState<any[]>([]);
  const Gethijos = (opciones: any[], opcionpadreid: number) => {
    return [...opciones.filter(f => f.OpcionPadreId === opcionpadreid)];

  }
  const GetPadres = (opciones: any[]) => {
    return [...opciones.filter(f => f.OpcionPadreId === null)
      .map(m => {
        m.opciones = Gethijos(opciones, m.OpcionId);
        return m;
      })
    ]
  }

  useEffect(() => {
    const lstOpciones: any[] = (menu as any[]);
    // primero sacamos las categorizaciones
    if (lstOpciones !== undefined && lstOpciones !== null) {
      setmenuview(lstOpciones.reduce((p: any[], c) => {
        const cat = c.categorizacion ?? "SYSCAF";  // si no hay categoria se asigna no categorizado
        const filtro: any[] = p.filter(f => f.OpcionId == cat);

        const showOrg: any = (c.configuracion === undefined) ? false : DeserializarJSon(c.configuracion).showOrg;
        let opciones = showOrg ?
          {
            OpcionId: Math.round(Math.random() * 3500),
            NombreOpcion: c.Nombre,
            opciones: [...GetPadres(c.opciones)]
          }
          : GetPadres(c.opciones)
        if (filtro.length === 0)
          p.push({
            OpcionId: cat,
            NombreOpcion: cat,
            opciones: Array.isArray(opciones) ? opciones : [opciones]
          })

        else {
          if (Array.isArray(opciones))
            Array.prototype.push.apply(filtro[0].opciones, opciones)
          else
            filtro[0].opciones.push(opciones)
        }

        return p;
      }, []));
    }
  }, [menu]);



  return (<>

    {(lstmenuview.length > 0) && (
      <TreeView data={lstmenuview} />
    )}</>
  );
}


