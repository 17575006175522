import { createContext, useContext, useState } from "react";
import { getClientes } from "../../../APPS/Mobile/data/dataConfiguracion";
import { AxiosResponse } from "axios";
import { error } from "console";

export interface ExlusionContextModel {
    loader: boolean;
    setloader: (loader: boolean) => void;
    clienteSeleccionado:any;
    setclienteSeleccionado:(clienteSeleccionado:any) => void;
    Clientes: any,
    setClientes: (Clientes: any[]) => void;
    lstSeleccionadosAsset:any
    setlstSeleccionadosAsset: (SeleccionadosAsset: any[]) => void;
    lstSeleccionadosConductores:any
    setlstSeleccionadosConductores: (lstSeleccionadosConductores: any[]) => void;
    Eventos:any;
    setEventos: (Eventos: any[]) => void;
    Filtros:any,
    setFiltros:(Filtros: any) => void;
}
const ServcContext = createContext<ExlusionContextModel>({
    setloader: (lloader: boolean) => { },
    loader: true,
    setclienteSeleccionado:(clienteSeleccionado) =>{},
    clienteSeleccionado:{},
    Clientes: [],
    setClientes: (Clientes: any[]) => { },
    lstSeleccionadosAsset:[],
    setlstSeleccionadosAsset:(SeleccionadosAsset: any[]) => { },
    lstSeleccionadosConductores:[],
    setlstSeleccionadosConductores:(SeleccionadosConductores: any[]) => { },
    Eventos:[],
    setEventos:(Eventos: any[]) => { },
    Filtros:{},
    setFiltros:(Filtros: any) => { }
});



export const ExclusionProvider: React.FC = ({ children }) => {
    const [loader, setloader] = useState<boolean>(true);
    const [clienteSeleccionado, setclienteSeleccionado] = useState<any>({

    });
    const [lstSeleccionadosAsset, setlstSeleccionadosAsset] = useState<any[]>([]);
    const [lstSeleccionadosConductores, setlstSeleccionadosConductores] = useState<any[]>([]);
    const [Eventos, setEventos] = useState<any[]>([]);
    const [Clientes, setClientes] = useState<any[]>([]);
    const [Filtros, setFiltros] = useState<any>({
        ClienteId:"",
        NombreCliente:"",
        Isassets:true,
        Isdrivers:false,
        assets : null,
        drivers :null,
        IntervaloFechaPersonalizado:false,
        FechaCustom :false,
        FechaInicial:"",
        FechaFinal:"",
        Evento :false,
        Viaje:true,
        Viajes : {
            condVelocidod :"" ,
            valorVelocidad : ""
        },
        Eventos : [],
        Comentarios:""
    });
    const value: ExlusionContextModel = {
        setloader, 
        loader,
        clienteSeleccionado, 
        setclienteSeleccionado,
        Clientes,
        setClientes,
        lstSeleccionadosAsset,
        setlstSeleccionadosAsset,
        lstSeleccionadosConductores,
        setlstSeleccionadosConductores,
        Eventos,
        setEventos,
        Filtros,
        setFiltros
    };
 
   
    return (<ServcContext.Provider value={value}> {children} </ServcContext.Provider>);
}

export function useDataExlusion() {

    return useContext(ServcContext);
}
