
import L from "leaflet";
import { MarkerClusterGroup } from "leaflet";
import { useEffect, useState } from "react";
import { useDataNivelCarga } from "../../core/NivelCargaProvider";
import { TablaDTO } from "../../models/NivelcargaModels";
import { Box, Typography } from "@mui/material";
import ReactDOMServer from "react-dom/server";
import "leaflet.markercluster";


const Mapa: React.FC = () => {

    // datos del provider
    const { DatosMapa, markerSeleccionado } = useDataNivelCarga()
    const [map, setMap] = useState<any>();
    const [show, setshowp] = useState<boolean>(false);
    const [markers, setMarkers] = useState<MarkerClusterGroup>();


    const [zoom, setzoom] = useState<number>(13);


    // actiualiza la informacion de todos los vehiculos
    //  whenCreated={setMap}
    const here = {
        apiKey: 'h7cWVY3eEiZeilhreUhv07kKMJMizDl6elWoN7cb8wg'
    }
    //h7cWVY3eEiZeilhreUhv07kKMJMizDl6elWoN7cb8wg
    const style = 'reduced.night';
    const CapaBasicNight = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?apiKey=${here.apiKey}&ppi=320`;
    const CapaHibrida = `https://2.traffic.maps.ls.hereapi.com/maptile/2.1/traffictile/newest/hybrid.traffic.day/{z}/{x}/{y}/512/png8?apiKey=${here.apiKey}&ppi=320`;
    // const CapaTraficoDia = `https://2.traffic.maps.ls.hereapi.com/maptile/2.1/traffictile/newest/normal.traffic.day/{z}/{x}/{y}/512/png8?apiKey=${here.apiKey}&ppi=320`;

    useEffect(() => {
        let datosmapa = (DatosMapa as TablaDTO[])
        if (DatosMapa != undefined && DatosMapa.length > 0) {
            var map = L.map('map').setView([Number.parseFloat(datosmapa[0].latitud),
            Number.parseFloat(datosmapa[0].longitud)], 13);
            // L.tileLayer(CapaHibrida, { maxZoom: 19 }).addTo(map);
            L.tileLayer(CapaBasicNight, { maxZoom: 19 }).addTo(map);
            var markers: MarkerClusterGroup = new L.MarkerClusterGroup();
            var points: any[] | undefined = DatosMapa.map((park) => {
                const markerPoint = L.marker([Number.parseFloat(park.latitud),
                Number.parseFloat(park.longitud)], {
                    title: park.placa,
                    alt: park.placa,
                });

                markerPoint.addEventListener('click', function (e: any) {
                    setzoom(16)

                });
                markerPoint.bindPopup(ReactDOMServer.renderToString(RenderPopUp(park)), { autoClose: false, offset: [0, -20] }).openPopup();
                markerPoint.bindTooltip(park.placa, { permanent: true, offset: [20, 0] }).openTooltip();
                return markerPoint;
            });

            markers.addLayers(points);
            // markers.on('clusterclick', function (a) { alert('Cluster Clicked'); });
            markers.on('click', function (a) { console.log(a); });
            markers.addTo(map);
            setMarkers(markers);
            setMap(map);
            setshowp(true)

        }
        return () => {
            if (map != null)
                map.remove();
        }
    }
        , [DatosMapa]
    );

    // CUANDO EL MARKER EST'E SELECCIONADO
    // MUESTRA ELPOPUP EN EL PUNTO
    useEffect(
        () => {
            if (map != null)
                markers?.getLayers().forEach((marker: any) => {
                    const point = marker as any;

                    if (point.options.title == markerSeleccionado?.placa) {
                        // permite mostrar el popup de los vehiculos aunque este agrupado
                        markers.zoomToShowLayer(point, function () {
                            point.openPopup();
                        });
                    }
                }
                )
        }
        , [markerSeleccionado]
    );





    //Funcion que ubica los iconos para el soc
    const getIconSoc = (data: any) => {
        const soc = data == null ? "" : data.toFixed(0);
        let icon = 'bi-battery-half';
        let color = '#00B050';
        if (data <= 100 && data >= 80) {
            icon = 'bi-battery-full';
        } else {
            if (data <= 79 && data >= 60) {
                icon = 'bi-battery-full rotate-45-verde';
                color = '#92D050';
            } else {
                if (data <= 59 && data >= 40) {
                    icon = 'bi-battery-full rotate-45-yellow';
                    color = '#ff0';
                } else {
                    if (data <= 39 && data >= 20) {
                        icon = 'bi-battery-full rotate-45-naranja';
                        color = '#ffa500';
                    } else {
                        if (data <= 19 && data >= 0) {
                            icon = 'bi-battery-half';
                        } else {
                            icon = 'bi-battery-full';
                            color = '';
                        }
                    }
                }

            }
        }

        return (<><i className={icon} style={{ color: color }}></i><span style={{ fontSize: "15px" }}> {soc}%</span></>)
            ;
    }

    //ReactDOMServer.renderToString(


    function RenderPopUp(activePark: any) {

        return (
            <div className="shadow-sm  border">
                <div className="fs-4 bg-secondary border m-0 px-0">
                    <p className="text-center">{activePark.placa}</p></div>
                <div className=" m-0 px-0">
                    <Box className="mx-2"
                        sx={{
                            display: 'grid',
                            margin: 'auto',
                            gridTemplateColumns: '1fr 1fr ',
                            gridTemplateRows: '30px',

                        }}
                    >
                        <Typography > Soc:</Typography>
                        <Typography >{getIconSoc(activePark.soc)}</Typography>
                        <Typography>Operador:</Typography>
                        <Typography >{activePark.driver}</Typography>
                    </Box>

                </div>

            </div>
        )
    }




    return (<>
        <div id="map" className=" ml-4"
            style={{ height: 700 }}></div>
    </>
    );

}

export { Mapa };
