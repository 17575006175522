import { Button, Modal } from "react-bootstrap-v5";
import { useDataDashBoard } from "../../../core/DashboardCore";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { FiltroDashBoardBasic } from "../../../data/dashboard";
import { lstRequerimientosEnProceso } from "../../../data/PostVentaData";
import { useEffect, useState } from "react";
import { Campos as CamposRequerimientos, ConsecutivoVisual, getTablaDetalleRequerimiento } from '../../../data/Requerimientos'
import { Box, IconButton, Tooltip } from "@mui/material";
import { EncabezadoHtml } from "../../Requerimientos/Componentes/Encabezado";
import { Details } from "@mui/icons-material";
export const ModalListadoReq: React.FC<{ show: boolean, setShow: any, estado: string, semaforo: number | null }> = ({ show, setShow, estado, semaforo }) => {

    const { lstRequerimientos, lstEstadosReq, lstVehiculosSinTx, AdminSeleccionado, ClienteSeleccionado } = useDataDashBoard();

    const [dataReq, setDataReq] = useState<any[]>([]);

    const [showdetalles, setshowdetalles] = useState<any>(false);
    const [DetallesDatos, DetallesModal] = useState<any>();
    useEffect(
        () => {
            const lstSinTxFiltrado = FiltroDashBoardBasic.getFiltroAdminCliente(lstVehiculosSinTx,
                AdminSeleccionado.UsuarioId, ClienteSeleccionado.ClienteId
            );
            const lstRequerimientosFiltrado = FiltroDashBoardBasic.getFiltroReqCliente(lstRequerimientos,
                AdminSeleccionado.UsuarioId, ClienteSeleccionado.ClienteId
            );

            const reqfiltrados = lstRequerimientosEnProceso(lstRequerimientosFiltrado, lstEstadosReq,  lstSinTxFiltrado);

            setDataReq((estado === "-1" ? reqfiltrados.sinagrupar : reqfiltrados.agrupadoEstado[estado]).
                filter((val: any) => (semaforo === null || val.Porc == semaforo)))

        }, []
    )

    return (
        <>
            <Modal show={show} onHide={setShow} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{"Listado de requerimientos de tx"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MaterialReactTable
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 100,
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: () => ({
                                fontSize: 14,
                                fontStyle: 'bold',
                                color: 'rgb(27, 66, 94)'
                            }),
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                            sx: {
                                backgroundColor:
                                    row.original.Porc === 1 ? 'rgba(212, 237, 218, 1)' :
                                        row.original.Porc === 2 ? 'rgba(255, 243, 205, 1)' :
                                            row.original.Porc === 3 ? 'rgba(248, 215, 218, 1)' : 'rgba(255, 243, 205, 1)'
                            }
                        })}
                        columns={CamposRequerimientos}
                        data={dataReq}
                        enableColumnOrdering
                        enableStickyHeader
                        enableDensityToggle={false}
                        enablePagination={false}
                        enableRowVirtualization
                        muiTableContainerProps={{
                            sx: { maxHeight: '400px' }, //give the table a max height
                        }}
                        //    initialState={{ density: 'compact' }}
                        enableEditing={true}
                        editingMode="modal"
                        renderRowActions={({ row }) => (
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                {/* Para mostar los detallas */}
                                {<Tooltip arrow placement="left" title="Detalle de requerimientos">
                                    <IconButton onClick={() => { DetallesModal(row); setshowdetalles(true) }}>
                                        <Details className="text-primary" />
                                    </IconButton>
                                </Tooltip>}
                            </Box>
                        )}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-secundary" onClick={() => { setShow(false); }}>Cerrar</button>
                </Modal.Footer>
            </Modal>

            {(showdetalles) && (<Modal show={showdetalles} onHide={() => { setshowdetalles(false); setShow(true); }} size={"lg"}>
                <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                    <Modal.Title>{`Detalles del Requerimiento ${ConsecutivoVisual(DetallesDatos.original.Consecutivo)}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <EncabezadoHtml ReqSeleccionado={DetallesDatos} ></EncabezadoHtml>
                            </div>
                            <div className="col-12">
                                {(DetallesDatos.length != 0) && (showdetalles) && (
                                    getTablaDetalleRequerimiento(JSON.parse(DetallesDatos.original.Observaciones), "")
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => { setshowdetalles(false); setShow(true); }}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>)}
        </>)
};