import FileSaver from "file-saver";
import XLSX from 'sheetjs-style';
import React from "react";
import { Button } from "react-bootstrap-v5";
import moment from "moment";
import { AxiosResponse } from "axios";
import { errorDialog } from "../../../../../../_start/helpers/components/ConfirmDialog";
import { DeserializarJSon, formatSimpleJson } from "../../../../../../_start/helpers/Helper";
import { useDataPreoperacional } from "../../core/provider";
import { getEncabezados } from "../../data/dataPreoperacional";
import { Preoperacional, sinPreoperacional } from "../../models/dataModels";

type Props = {
    NombreArchivo: string;
    tipoDescarga: number;
    lstPreoperacional?: Preoperacional[];
    FechaInicial: any;
    FechaFinal: any;
    ClienteId: any;
}
export const ExportarExcel: React.FC<Props> = ({ NombreArchivo, tipoDescarga, lstPreoperacional, FechaInicial,
    FechaFinal, ClienteId }) => {
    const { vehiculosSinPreoperacional, setVisible } = useDataPreoperacional();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;carset=UTF-8';
    const fileExtension = '.XLSX';
    let listadoObjeto: any = [];
    const crearExcel = (respuestas: Preoperacional[]) => {
        listadoObjeto = [];
        respuestas.forEach((item) => {
            let Objeto: any = {};
            Objeto["Conductor"] = item.Conductor;
            Objeto["Fecha y Hora"] = item.FechaHoraString;
            Objeto["Cliente"] = item.clienteNombre;
            Objeto["Vehículo"] = item.Description;
            Objeto["Gestor"] = item.GetorNombre;
            Objeto["Estado Gestión"] = item.EsGestionado ? "Gestionado" :
                item.Estado === 1 && item.EsGestionado === null ? "No Gestionado" :
                    item.Estado === 1 && !item.EsGestionado ? "En Gestión" : "";

            const lstrespuestas: any[] = DeserializarJSon(item.Respuestas);
            lstrespuestas.forEach((arr) => {

                let valor = arr.Pregunta;
                let Respuesta = arr.Respuesta;

                Objeto[valor] = Respuesta;
            })

            Objeto["Estado"] = item.Estado === 0 ? "Aprobado" : "No Aprobado";

            listadoObjeto.push(Objeto);
        })
        const ws = XLSX.utils.json_to_sheet(listadoObjeto);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver(data, `${NombreArchivo}${fileExtension}`);
        setVisible(false);
    }
    const exportarExcel = async () => {


        if (tipoDescarga === 0) {

            setVisible(true);
            if (lstPreoperacional?.length === 0)
                getEncabezados(ClienteId, moment(FechaInicial).format(formatSimpleJson),
                    moment(FechaFinal).format(formatSimpleJson), null).then((respuesta: AxiosResponse<Preoperacional[]>) => {

                        if (respuesta.data.length > 0) {
                            crearExcel(respuesta.data);
                        } else {
                            errorDialog("No hay datos que exportar", "");
                            setVisible(false);
                        }

                    });

            else
                crearExcel(lstPreoperacional ?? []);



        }
        else {
            if ((vehiculosSinPreoperacional as sinPreoperacional[]).length > 0) {
                let finaldata = (vehiculosSinPreoperacional as sinPreoperacional[]).map((item) => {
                    let Objeto: any = {};
                    Objeto["Conductor"] = item.Conductor;
                    Objeto["Vehículo"] = item.Vehiculo;
                    Objeto["Descripción"] = item.RegistrationNumber;
                    Objeto["Fecha Primer Viaje"] = moment(item.FechaViaje).format("DD-MM-YYYY HH:mm");
                    Objeto["Kms Recorrido"] = item.DistanciaRecorrida;
                    Objeto["Nro Viajes"] = item.CantViajes;
                    return Objeto;
                })
                const ws = XLSX.utils.json_to_sheet(finaldata);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver(data, `${NombreArchivo}${fileExtension}`);
                setVisible(false);
            } else {
                errorDialog("No hay datos que exportar", "");
            }
        }


    }


    return (
        <div className="justify-content-end" style={{ textAlign: 'right' }}>
            <Button type="button" variant="primary" onClick={() => { exportarExcel(); }}>
                Descargar
            </Button>
        </div>
    )
} 