import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useEffect, useState } from "react";
import { getCSSVariableValue } from "../../../../../../../_start/assets/ts/_utils";
import { useDataIndicadoresHistorical } from "../../../core/providerIndicadores";


type Props = {
  className?: string;
  innerPadding?: string;
  tipoData?: number;
  nameChart: string;
  titulo?: string;
};

export const IndicadorDona: React.FC<Props> = ({ className, innerPadding = "", tipoData = 1, nameChart, titulo }) => {
  //OTRAS UNIDADES
  const colorsuccess = getCSSVariableValue("--bs-success");
  const colorwarning = getCSSVariableValue("--bs-warning");
  const colorblue = getCSSVariableValue("--bs-primary");
  const colordanger = getCSSVariableValue("--bs-danger");
  const colorinfo = getCSSVariableValue("--bs-info");

  let opcionesOtrasUnidades: ApexOptions = {
    chart: {
      id: nameChart,
      fontFamily: 'Montserrat',
      type: 'donut',
    },
    series: [],
    labels: [],
    legend: {
      show: true,
      // position: 'rigth',
      fontSize: '12px',
      formatter: function (legendName: any, opts: any) {
                return legendName.slice(0, 10) + '..' + "(" +`${opts.w.globals.series[opts.seriesIndex].toFixed(1)}` + ")"
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(1) + "%"
      },
    },
    theme :{
       palette: 'palette2'
    },
    plotOptions:{
      pie :{
        donut :{
          labels :{
            show: true,
            value: {
              show: true,
              fontSize: '21px',
              offsetY: -7
            },
            total: {
              showAlways: true,
              show: true,
              fontSize: '12px',
              formatter: function (w: any) {
                return w.globals.seriesTotals.reduce((a: any, b: any) => {
                  return a + b
                }, 0).toFixed(1)
              }
            }
          }
        }
      }
    }

  };



  const { dataIndicadores } = useDataIndicadoresHistorical();
  const [chartOptions, setChartOptions] = useState<any>();

  useEffect(() => {
    if (dataIndicadores.length > 0)
      PintarGrafica()


  }, [dataIndicadores]);


  // funcion que se encarga de pintar las graficas con los resultados listos
  const PintarGrafica = () => {
    let objectdata = dataIndicadores.filter(
      (f: any) => f.Tipo == tipoData
    )
    let labels = new Array();
    let series = new Array();
    const Colores: any = {
      "Gestionado": colorsuccess,
      "Abierto": colorblue,
      "Sin Gestionar": colorwarning,
      "Cerrado Auto": colordanger,
      "Pendiente": colorinfo,
      "Riesgo alto": colordanger,
      "Riesgo moderado": colorwarning,
      "Riesgo bajo": colorsuccess,
    };


    let colorsArray: string[] = [];
    objectdata.forEach((elem: any) => {
      labels.push(elem.Serie);
      series.push(elem.Total);
      if (Colores[elem.Serie] != undefined)
        colorsArray.push(Colores[elem.Serie])
    });


    opcionesOtrasUnidades.series = series; // se modifican las series
    opcionesOtrasUnidades.labels = labels; // se modifican los labesl
    if (colorsArray.length > 0)
      opcionesOtrasUnidades.colors = colorsArray;

    if (className == "riesgos")
      opcionesOtrasUnidades.colors = ['#FF0000', '#FFA500', '#FFFF00', '#008000', '#0000FF', '#4B0082', '#EE82EE', '#A52A2A', '#5F9EA0', '#FF69B4'];
    setChartOptions(opcionesOtrasUnidades)


  };

  return (
    <>
     <div key={`div_chardonavehiculo_${nameChart}`}>
        {(chartOptions != undefined) && (
          <Chart width={400} height={400} key={`chardonavehiculo_${nameChart}`} options={chartOptions} series={chartOptions.series}
            type="donut" />

        )}
      </div>


    </>
  )
}