import axios from "axios";
import {
  ASSET_getAssets, ASSET_GetAssetsClienteId, ASSET_GetAssetsEstados,
  ASSET_GetClientesClienteIds, BASES_getDetalleListas, CORE_getconsultadinamicasUser,
  CORE_getconsultadinamicasUserDWH, DescargasPlantillas, DRIVER_GetDriversClienteId,
  DWH_getconsultadinamicasprocedure, DWH_getconsultadinamicasprocedureparamNulleables,
  DWH_getDynamicValueProcedureDWHTabla, EBUS_GetClientesUsuarios,
  EBUS_GetColumnasDatatable, EBUS_getEventActiveRecargaByDayAndClient,
  EBUS_getEventActiveViajesByDayAndClient, EBUS_GetListaClientesActiveEvent,
  EBUS_GetListadoClientesUsuario, EBUS_GetLocations, EBUS_GetTiempoActualizacion,
  EBUS_GetUltimaPosicionVehiculos, EBUS_GetUsuariosEsomos, EBUS_SetClientesActiveEvent,
  EBUS_SetColumnasDatatable,
  TX_GetListaSemana, TX_GetSnapShotTickets, TX_GetSnapShotTransmision, TX_GetUnidadesActivas
} from "../../../apiurlstore";
import { ParamsEndPointDynamic } from "../Models/paramsConsultasDinamicas";

export function Post_GetConsultasDinamicasDWH(props: ParamsEndPointDynamic, body: any) {
  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedure,
    data: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}
export function Post_getDynamicValueProcedureDWHTabla(props: any, body: any) {
  return axios({
    method: 'post',
    url: DWH_getDynamicValueProcedureDWHTabla,
    data: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}

export function Post_GetConsultasDinamicasUserCore(props: ParamsEndPointDynamic, body: any) {
  return axios({
    method: 'post',
    url: CORE_getconsultadinamicasUser,
    data: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}

export function Post_GetConsultasDinamicasUserDWH(props: ParamsEndPointDynamic, body: any) {
  return axios({
    method: 'post',
    url: CORE_getconsultadinamicasUserDWH,
    data: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}


export function getVehiculosCliente(ClienteIds: string | null, UsertState: string) {
  return axios({
    method: 'get',
    url: ASSET_GetClientesClienteIds,
    headers: { 'Content-Type': 'application/json' },
    params: { ClienteIds, UsertState }
  });
}

export function getVehiculosClienteId(ClienteId: string | null, UsertState: string | null) {
  return axios({
    method: 'get',
    url: ASSET_GetAssetsClienteId,
    headers: { 'Content-Type': 'application/json' },
    params: { ClienteId, UsertState }
  });
}

export function getConductoresClienteId(ClienteId: string | null) {
  return axios({
    method: 'get',
    url: DRIVER_GetDriversClienteId,
    headers: { 'Content-Type': 'application/json' },
    params: { ClienteId }
  });
}


export function getClientesEBUS() {
  return axios({
    method: 'get',
    url: EBUS_GetClientesUsuarios,
    headers: { 'Content-Type': 'application/json' },
    params: {}
  });
}

export function Post_EventActiveViajesByDayAndClient(props: any) {
  return axios({
    method: 'post',
    url: EBUS_getEventActiveViajesByDayAndClient,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}

export function Post_SetColumnasDatatable(props: any) {
  return axios({
    method: 'post',
    url: EBUS_SetColumnasDatatable,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}
export function Post_GetColumnasDatatable(props: any) {
  return axios({
    method: 'post',
    url: EBUS_GetColumnasDatatable,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}
export function Post_GetTiempoActualizacion(props: any) {
  return axios({
    method: 'post',
    url: EBUS_GetTiempoActualizacion,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}


export function Post_GetUltimaPosicionVehiculos(props: any) {
  return axios({
    method: 'post',
    url: EBUS_GetUltimaPosicionVehiculos,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}


export function Post_EBUS_getEventActiveRecargaByDayAndClient(props: any) {
  return axios({
    method: 'post',
    url: EBUS_getEventActiveRecargaByDayAndClient,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}



export function GetClientesActiveEvent(props: any) {
  return axios({
    method: 'post',
    url: EBUS_GetListaClientesActiveEvent,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}
export function setClientesActiveEvent(props: any) {
  return axios({
    method: 'post',
    url: EBUS_SetClientesActiveEvent,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}

export function Post_GetLocations(props: any) {
  return axios({
    method: 'post',
    url: EBUS_GetLocations,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}
export function Post_GetClientesUsuarios(props: any) {
  return axios({
    method: 'post',
    url: EBUS_GetUsuariosEsomos,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}
export function Post_GetListadoClientesUsuario(props: any) {
  return axios({
    method: 'post',
    url: EBUS_GetListadoClientesUsuario,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}

/* TX */
export function Post_GetListaSemanas(props: any) {
  return axios({
    method: 'get',
    url: TX_GetListaSemana,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}

export function Post_UnidadesActivas(props: any) {
  return axios({
    method: 'get',
    url: TX_GetUnidadesActivas,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}

export function Post_GetSnapShotTickets(props: any) {
  return axios({
    method: 'get',
    url: TX_GetSnapShotTickets,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}

export function Post_GetSnapShotTransmision(props: any) {
  return axios({
    method: 'get',
    url: TX_GetSnapShotTransmision,
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}
export function Post_GetEstadosAssets(tipoIdS: string | null) {
  return axios({
    method: 'get',
    url: ASSET_GetAssetsEstados,
    headers: { 'Content-Type': 'application/json' },
    params: { tipoIdS }
  });
}
export function Post_GetDetallesListas(Sigla: string | null) {
  return axios({
    method: 'get',
    url: BASES_getDetalleListas,
    headers: { 'Content-Type': 'application/json' },
    params: { Sigla }
  });
}
export function GetVehiculos(ClienteId: string | null) {
  return axios({
    method: 'get',
    url: ASSET_getAssets,
    headers: { 'Content-Type': 'application/json' },
    params: { ClienteId }
  });
}

export function Post_getDynamicProcedureDWH(props: any, body: any) {
  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedure,
    data: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}


export function Post_GetConsultasDinamicasProcedure(props: ParamsEndPointDynamic, body: any) {
  return axios({
    method: 'post',
    url: DWH_getconsultadinamicasprocedureparamNulleables,
    data: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    params: props
  });
}


export function GetPlantilaRequerimientos(NombreArchivo: any) {
  return axios({
    method: 'post',
    url: DescargasPlantillas,
    data: JSON.stringify(NombreArchivo),
    headers: { 'Content-Type': 'application/json' },
    responseType: 'blob',
    params: {}
  });
}

export function getDriversClienteId(ClienteId: string) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['ClienteId'] = ClienteId;
  params['ClienteIds'] = null;
  return Post_GetConsultasDinamicasDWH({
    NombreConsulta: "GetDriversByClienteID", Clase: "PortalQueryHelper",
    Pagina: null,
    RecordsPorPagina: null
  }, params)
};
export function getDriversClienteIds(ClienteIds: string) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['ClienteId'] = null;
  params['ClienteIds'] = ClienteIds;
  return Post_GetConsultasDinamicasDWH({
    NombreConsulta: "GetDriversByClienteID", Clase: "PortalQueryHelper",
    Pagina: null,
    RecordsPorPagina: null
  }, params)
};



export function getAssetsClienteId(UsertState: string | null, ClienteId: string) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['ClienteId'] = ClienteId;
  params['ClienteIds'] = null;
  params['UserState'] = UsertState;
  return Post_GetConsultasDinamicasDWH({
    NombreConsulta: "GetAssetsByFiltros", Clase: "PortalQueryHelper",
    Pagina: null,
    RecordsPorPagina: null
  }, params)
};
export function getAssetsClienteIds(UsertState: string | null, ClienteIds: string) {
  var params: { [id: string]: string | null | undefined; } = {};
  params['ClienteId'] = null;
  params['ClienteIds'] = ClienteIds;
  params['UserState'] = UsertState;
  return Post_GetConsultasDinamicasDWH({
    NombreConsulta: "GetAssetsByFiltros", Clase: "PortalQueryHelper",
    Pagina: null,
    RecordsPorPagina: null
  }, params)
};




