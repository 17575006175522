import moment from "moment";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import confirmarDialog, { errorDialog } from "../../../../../_start/helpers/components/ConfirmDialog";
import { DeserializarJSon, EsJson, FechaServidor } from "../../../../../_start/helpers/Helper";
import { GetAlarmas, GetAlertasPorUsuarios, ObjetoParametros, getVehiculosOperando } from "../data/dashBoardData";
import { EventoActivo } from "../models/EventosActivos";
import { AxiosError, AxiosResponse } from "axios";
import { useToaster, Notification } from "rsuite";
import { RootState } from "../../../../../setup";
import { useSelector } from "react-redux";
import { UserModelSyscaf } from "../../../auth/models/UserModel";
import { GetOperadores, GetParametros, GetUsuarios, SetOperadores } from "../data/Operadores";
import { ModalControlador } from "../components/ModalesCompartidos/Modales";
// clase con los funciones  y datos a utiilizar
export interface FatigueContextModel {

    vehiculosOperacion?: any;
    setvehiculosOperacion: (vehiculos: any) => void;
    listadoEventosActivos?: EventoActivo[];
    setlistadoEventosActivos: (eventos: EventoActivo[]) => void;
    ListadoVehiculoSinOperacion?: any[];
    setListadoVehiculoSinOperacion: (lstvehiculos: any[]) => void;
    alertas?: any;
    setalertas: (lstalertas: any[]) => void;
    iserror?: any;
    setError: (error: any) => void;
    DataAlertas?: any;
    setDataAlertas: (DataAlertas: any) => void;
    DataDetallado?: any;
    DataDetalladoFiltrado?: any;
    Filtrado?: any
    setDataDetallado: (DataAlertas: any) => void;
    setDataDetalladoFiltrado: (DataAlertas: any) => void;
    setFiltrado: (Filtrado: boolean) => void;
    activeTab?: string;
    setActiveTab: (tabGlobal: string) => void;
    loader?: boolean;
    setloader: (loader: boolean) => void;
    UserId?: string;
    setUserId: (id: string) => void;
    clienteIds?: string;
    setclienteIds: (clienteid: string) => void;
    fechaInicial?: Date;
    setfechaInicial: (fechainicial: Date) => void;
    fechaFinal?: Date;
    setfechaFinal: (fechafinal: Date) => void;
    alertasHistorical?: any;
    setalertasHistorical: (lstalertasHistorical: any[]) => void;
    usuario?: any;
    setusuario: (usuario: any) => void;
    InfoUsuario?: any;
    setInfoUsuario: (Id: any) => void;
    Cliente?: any;
    setCliente: (Id: any) => void;

}

const FatigueContext = createContext<FatigueContextModel>({
    setvehiculosOperacion: (vehiculos: any) => { },
    setlistadoEventosActivos: (eventos: EventoActivo[]) => { },
    setListadoVehiculoSinOperacion: (lstvehiculos: any[]) => { },
    setalertas: (lstalertas: any[]) => { },
    setError: (error: any) => { },
    setDataAlertas: (DataAlertas: any) => { },
    setDataDetallado: (DataDetallado: any) => { },
    setFiltrado: (Filtrado: boolean) => { },
    setDataDetalladoFiltrado: (DataAlertas: any) => { },
    setActiveTab: (tabGlobal: string) => { },
    setUserId: (id: string) => "",
    setclienteIds: (clienteid: string) => "",
    setloader: (loader: boolean) => { },
    setfechaInicial: (fechainicial: Date) => (new Date()),
    setfechaFinal: (fechafinal: Date) => (new Date()),
    setalertasHistorical: (lstalertasHistorical: any[]) => { },
    setusuario: (usuario: any) => { },
    setInfoUsuario: (Id: any) => { },
    setCliente: (Cliente: any) => { }
});


const FatigueProvider: React.FC = ({ children }) => {
    const [vehiculosOperacion, setvehiculosOperacion] = useState<any>({});
    const [listadoEventosActivos, setlistadoEventosActivos] = useState<EventoActivo[]>([]);
    const [ListadoVehiculoSinOperacion, setListadoVehiculoSinOperacion] = useState<any[]>([]);
    const [alertas, setalertas] = useState<any[]>([]);
    const [iserror, setError] = useState<any>({});
    const [DataAlertas, setDataAlertas] = useState<any[]>([]);
    const [DataDetallado, setDataDetallado] = useState<any[]>([]);
    const [DataDetalladoFiltrado, setDataDetalladoFiltrado] = useState<any[]>([]);
    const [Filtrado, setFiltrado] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>("#tab1");
    const [loader, setloader] = useState<boolean>(false);
    const [UserId, setUserId] = useState("");
    const [clienteIds, setclienteIds] = useState("");
    const [fechaInicial, setfechaInicial] = useState(new Date());
    const [fechaFinal, setfechaFinal] = useState(new Date());
    const [alertasHistorical, setalertasHistorical] = useState<any[]>([]);
    const [usuario, setusuario] = useState<any>({});
    const [Id, setId] = useState<any>({});
    const [Cliente, setCliente] = useState<{}>();

    const value: FatigueContextModel = {
        vehiculosOperacion,
        setvehiculosOperacion,
        listadoEventosActivos,
        setlistadoEventosActivos,
        ListadoVehiculoSinOperacion,
        setListadoVehiculoSinOperacion,
        alertas,
        setalertas,
        iserror,
        setError,
        DataAlertas,
        setDataAlertas,
        DataDetallado,
        setDataDetallado,
        setDataDetalladoFiltrado,
        DataDetalladoFiltrado,
        setFiltrado,
        Filtrado,
        activeTab,
        setActiveTab,
        loader,
        setloader,
        UserId,
        setUserId,
        clienteIds,
        setclienteIds,
        fechaInicial,
        setfechaInicial,
        fechaFinal,
        setfechaFinal,
        alertasHistorical,
        setalertasHistorical,
        usuario,
        setusuario,
        InfoUsuario: Id,
        setInfoUsuario: setId,
        setCliente,
        Cliente
    };
    return (
        <FatigueContext.Provider value={value}>
            {children}
        </FatigueContext.Provider>
    );
};

function useDataFatigue() {
    return useContext(FatigueContext);
}

// se encarga de consultar la información 
// de los vehiculos operando y en una frecuencia de 5 min 
// segun parametrización que debe realizarse

const DataVehiculoOperando: React.FC = ({ children }) => {
    const [show, setshow] = useState(false);
    const [EsUsuario, setEsUsuario] = useState<any>(true);
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const [UsuarioSeleccionado, setUsuarioSeleccionado] = useState<any>({ Id: "", Nombres: "" });
    const vUser = user as UserModelSyscaf;
    const [ObjetoGeneral, setObjetoGeneral] = useState<any>({
        Clave: "1",
        Operador: "",
        FechaInicio: FechaServidor(),
        ClienteIds: "",
        FechaFinal: null,
        Id: null
    })
    const [Recargar, setRecargar] = useState<boolean>(false);
    const [Data, setData] = useState<any[]>([]);
    const { setvehiculosOperacion, setInfoUsuario: setId, setListadoVehiculoSinOperacion, setalertas, setError, setCliente,
        setDataAlertas, setloader, setclienteIds, setDataDetalladoFiltrado } = useDataFatigue();
    const interval = useRef<any>();
    const GetTiempo = () => {
        let tiempo = 60000;
        interval.current = setInterval(() => {
            consultaAlertas(children as string);
        }, tiempo);
    }
    //CONSULTA VEHICULOS OPERANDO
    let consulta = (children: string) => {
        // consultamos en la base de datos la informacion de vehiculos operando
        getVehiculosOperando(children, FechaServidor()).then(
            (response) => {
                let datos = response.data[0];
                // traemos la informacion del  objeto a traer y la seteamos 
                // al objeto que tendrá la información en el contexto                 
                setvehiculosOperacion({
                    "Operando": datos["TotalOperando"],
                    "No Operando": datos["TotalVehiculosSinOpera"]
                });
                setListadoVehiculoSinOperacion(response.data);
            }
        ).catch((error) => {
            setError({ accion: "DataVehiculoOperando", error });
        });

        //let datetemp = moment("2023-06-06 09:19:05.990").toDate()
        GetAlarmas(children, FechaServidor(), moment(FechaServidor()).add("8", "hours").toDate()).then((response: AxiosResponse<any>) => {
            setDataAlertas(response.data);
        }).catch((error: any) => {
            console.log("Error : ", error);
        });
        GetTiempo();
    }

    const toaster = useToaster();
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }
    // CONSULTA EVENTOS ACTIVOS POR MINUTO
    let consultaAlertas = (children: string) => {
        setloader(true);
        // consultamos en la base de datos la informacion de vehiculos operando
        GetAlertasPorUsuarios(children).then(
            (response) => {
                if (response.data[0].Error) {
                    toaster.push(message('warning', response.data[0].Mensaje, `Información de configuración `), {
                        placement: 'topCenter'
                    });
                    setalertas([]);
                    setloader(false);
                } else {
                    setalertas(response.data);
                    setloader(false);
                }
            }
        ).catch((error) => {
            setError({ accion: "alertas", error });
            setloader(false);
        })

    }

    const TodoDefault = () => {
        let o = { ...ObjetoGeneral };
        o.Clave = null;
        o.Operador = "";
        setObjetoGeneral(o);
    }
    const Guardar = () => {
        //Tomamos los valores
        let o = { ...ObjetoGeneral };
        o.Operador = JSON.stringify({ "Operador": o.Operador });
        //Seteamos operador
        setObjetoGeneral(o);
        //Validamos si el usuario que tenemos seleccionado ya no esta asociado a un controlador
        let Existe = Data.filter((f: any) => {
            if (f.UsuarioId == o.UsuarioId && f.FechaFinal == null)
                return f;
        });
        //Si esta asociado emitimos un mensaje que ya esta asociado.
        if (Existe.length > 0) {
            toaster.push(message('error', "Operador", "El usuario ya se encuentra asociado"), {
                placement: 'topEnd'
            });
            TodoDefault();
            return false;
        }
        //Validamos que haya seleccionado un controlador del listado.
        if (o.Operador == "" || o.Operador == undefined || o.Operador == null || o.Operador == '{"Operador":""}') {
            toaster.push(message('error', "Operador", "Seleccione un operador"), {
                placement: 'topEnd'
            });
            return false;
        }
      
        //Guardarmos el operador o asociacion.
        confirmarDialog(() => {
            SetOperadores(o).then((response: AxiosResponse<any>) => {
                toaster.push(message('success', "Operador", "Operación Éxitosa."), {
                    placement: 'topEnd'
                });
                //Esta parte es para guardar y mostrar todo local sin ir al server
                let dt = [...Data];
                let OO = { ...ObjetoGeneral };
                OO.Usuario = UsuarioSeleccionado.Nombres;
                OO.NombreOperador = OO.Operador;
                dt.push(OO);
                setData(dt);
                setshow(false);
                //Una vez mostrado todo lo dejo en default para iniciar con un nuevo usuario
                TodoDefault();
                setObjetoGeneral(o);
                setEsUsuario(true);
                setRecargar(true);
            }).catch((error: AxiosError<any>) => {
                setData([]);
            });
        }, `¿Esta seguro que desea asignar el operador?`, 'Guardar');
    }
    //Consulta los parametros del cliente
    const ParametrosCliente = (ClienteIds: any) => {
        let _data: any = []

        GetParametros(null, ClienteIds).then((response: any) => {
            _data = { ...response.data[0] };
            _data.Parametros = (EsJson(_data.ParamsSistema) ? DeserializarJSon(_data.ParamsSistema) : ObjetoParametros);
            _data.ClienteIds = ClienteIds;
            //Se setean los datos al cliente seleccionado
            setCliente({ClienteIds ,  ..._data})

            //Se verifica si el cliente esta marcado para tener controladores
            if ((_data.Parametros.fatiga_conf.TieneControlador != null ||
                _data.Parametros.fatiga_conf.TieneControlador != undefined
                || _data.Parametros.fatiga_conf.TieneControlador == 1) && (vUser.esGenerico == "True")) {
                //Como tiene contraladores se traen el listado
                GetOperadores({ Operador: null }).then((response: AxiosResponse<any>) => {
                    //Una vez consultados los operadores revisamos si el usuario que esta dentro del sistema no tiene un controlador asociado

                    if (response.data.length > 0) {
                        let EsOperador: any = response.data[0];
                        //si esta asociado, y no ha cerrado la jornada se consultan las alertas
                        //y se setean los valores que vayamos a necesitar despues.
                        setId({ Usuario: vUser.Nombres, Id: EsOperador.IdOperador, Operador: EsOperador.NombreOperador });
                        let o = { ...ObjetoGeneral };
                        o.Id = EsOperador.IdOperador;
                        setObjetoGeneral(o);

                        consultaProceso(ClienteIds);
                    }
                    else {
                        toaster.push(message('error', "Operador", "Debe escoger un operador para continuar"), {
                            placement: 'topCenter'
                        });
                        setshow(true);
                    }
                }).catch((error: AxiosError<any>) => {
                    console.log(error.response?.data);
                });
            }
            //Sino lo esta consulta las alertas
            else {
                consultaProceso(ClienteIds);
            }

        }).catch((error: any) => {
            console.log(error);
        });



    }

    const consultaProceso = (ClienteIds: any) => {
        consulta(ClienteIds.toString());
        consultaAlertas(ClienteIds.toString());
        setclienteIds(ClienteIds.toString());
    }
    useEffect(() => {
        setalertas([]);
        if (children) {
            if (interval.current != 0)
                clearInterval(interval.current);
            if (children) {
                ParametrosCliente(children.toString());
                let o = { ...ObjetoGeneral };
                o.ClienteIds = children.toString();
                setObjetoGeneral(o);
            }
        }
        return () => {
            setvehiculosOperacion([]);
            setalertas([]);
            clearInterval(interval.current);
            setId({});
            setDataAlertas([])
            setListadoVehiculoSinOperacion([]);
            setDataAlertas([]);
            setDataDetalladoFiltrado([]);



        };
        // }

    }, [children, Recargar]);
    return <>
        {(show) && (
            <ModalControlador
                show={show}
                setshow={setshow}
                Guardar={Guardar}
                vUser={vUser}
                ObjetoGeneral={ObjetoGeneral}
                setObjetoGeneral={setObjetoGeneral}
            />)}

    </>;
};



export { FatigueProvider, useDataFatigue, DataVehiculoOperando }