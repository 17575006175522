import { MRT_ColumnDef } from "material-react-table";
import { Post_getconsultadinamicasUser } from "../../../../../_start/helpers/Axios/CoreService";
import { Post_GetConsultasDinamicasDWH } from "../../../../../_start/helpers/Axios/DWHService";
import { formatableJson_HHMMss, GetFechaServidor } from "../../../../../_start/helpers/Helper";

export function GetConductores(ClienteId: string ) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['ClienteId'] = ClienteId;
    return Post_getconsultadinamicasUser({ Clase: "PortalQueryHelper", NombreConsulta: "GetConductoresPorCliente", Pagina: null, RecordsPorPagina: null }, params);
}

export function Insights_getVehiculosClienteId(ClienteId: string | null, UsertState: string | null) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['ClienteId'] = ClienteId;
    params['userstate'] = UsertState;
    return Post_getconsultadinamicasUser({ Clase: "PortalQueryHelper", NombreConsulta: "getVehiculosClienteIdInsights", Pagina: null, RecordsPorPagina: null }, params);
}
export function Insights_getEventos(ClienteId: string ) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['ClienteId'] = ClienteId;
    return Post_GetConsultasDinamicasDWH({ Clase: "PortalQueryHelper", NombreConsulta: "getEventosClienteInsights", Pagina: null, RecordsPorPagina: null }, params);
}
    
//Trae los consultas
export function GetConsultas(Perfil: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["PerfilId"] = String(Perfil);
    return Post_getconsultadinamicasUser({
        NombreConsulta: "GetConsultaExclusionDatos", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
//Inserta las consultas
export function PostConsultas(Clave: any,UsuarioId: any,Filtros: any,Estado: any,Observaciones: any,ClienteId: any,Id:any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["Clave"] = String(Clave);
    // params["UsuarioId"] = String(UsuarioId);
    params["Filtros"] = String(Filtros);
    params["Estado"] = String(Estado);
    params["Observaciones"] = String(Observaciones);
    params["ClienteId"] = String(ClienteId);
    params["Id"] = String(Id);
    return Post_getconsultadinamicasUser({
        NombreConsulta: "InsertConsultaExclusionDatos", Clase: "PortalQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}


export const ConfigDriver = {
    getSitesAnidadosTree: (Sites: any[], SitePadreId: string | null, lstDrivers: any[], lstSeleccionados: string[]) => {
        // ahora iteramos para conseguir los hijos recursivamente
        const result = Sites.filter(f => f.SitePadreId === SitePadreId)
            .map((value: any) => {
                let hijos = ConfigDriver.getSitesAnidadosTree(Sites, value.siteid, lstDrivers, lstSeleccionados);
                let Drivers = lstDrivers.filter(f => f.SiteId === value.siteid).map(mp => {
                    return {
                        Node: 0,
                        SiteId: value.SiteId,
                        Sitio: '', 
                        tipo: mp.tipo, 
                        name: mp.name,
                        DriverId: mp.DriverId,
                        seleccionado: lstSeleccionados.includes(mp.DriverId)
                    }
                });
                const listMerge: any[] = Drivers.concat(hijos);      
                return {
                    Node: 1,
                    SiteId: value.siteid,
                    Sitio: value.sitename,
                    tipo: '',
                    name: '',
                    // registrationNumber: '',
                    // AssetId: '',  
                    DriverId:'',
                    subRows: listMerge,
                    seleccionado: false,
                    // name:''
                }
            });
        

            return result;
    },
    getAssetsAnidados: (lstVehiculoAnidado: any[]) => {
        // ahora iteramos para conseguir los hijos recursivamente
        let lstDrivers: any[] = [];
        const getDataHijos = (subRows: any[]) => {
            if (subRows)
                subRows.forEach((value: any) => {
                    if (value.Node === 1) // si dentro de él contiene un subnodo  se extraen los hijos
                    {
                        getDataHijos(value.subRows)
                    }
                    else
                    lstDrivers.push(value.DriverId) // si no se hace push al array
                });

        }
        getDataHijos(lstVehiculoAnidado);
        return lstDrivers
    }

}

export const ConfigAssets = {
    getSitesAnidadosTree: (Sites: any[], SitePadreId: string | null, lstVehiculos: any[], lstSeleccionados: string[]) => {
        // ahora iteramos para conseguir los hijos recursivamente
        const result = Sites.filter(f => f.SitePadreId === SitePadreId)
            .map((value: any) => {
                let hijos = ConfigAssets.getSitesAnidadosTree(Sites, value.siteid, lstVehiculos, lstSeleccionados);
                let vehiculos = lstVehiculos.filter(f => f.SiteId === value.siteid).map(mp => {
                    return {
                        Node: 0,
                        SiteId: value.siteid,
                        Sitio: '', tipo: mp.Tipo, description: mp.Description, registrationNumber: mp.RegistrationNumber,
                        AssetId: mp.AssetIdString,
                        seleccionado: lstSeleccionados.includes(mp.AssetIdString)
                    }
                });
                const listMerge: any[] = vehiculos.concat(hijos);      
                return {
                    Node: 1,
                    SiteId: value.siteid,
                    Sitio: value.sitename,
                    tipo: '',
                    description: '',
                    registrationNumber: '',
                    AssetId: '',
                    subRows: listMerge,
                    seleccionado: false
                }
            });
        

            return result;
    },
    getAssetsAnidados: (lstVehiculoAnidado: any[]) => {
        // ahora iteramos para conseguir los hijos recursivamente
        let lstVehiculos: any[] = [];
        const getDataHijos = (subRows: any[]) => {
            if (subRows)
                subRows.forEach((value: any) => {
                    if (value.Node === 1) // si dentro de él contiene un subnodo  se extraen los hijos
                    {
                        getDataHijos(value.subRows)
                    }
                    else
                        lstVehiculos.push(value.AssetId) // si no se hace push al array
                });

        }
        getDataHijos(lstVehiculoAnidado);
        return lstVehiculos
    }

}
export const Tooltips : any ={
    "Cerrar" : {title: "Cerrar", content : "Cancela todas las acciones y cierra el modal."}
   , "Guardar" : {title: "Guardar", content : "Graba todo el contenido en el servidor, para posteriormente consultarlo."}
   , "Nuevo" : {title: "Nuevo", content : "Limpia los campos para agregar un nuevo registro."}
   }
   
   export const Insights_Eventos = (Datos: any, SetDatos:any) => {
    //Para seleccionar y guardar en el array los eventos
    const onchageSeleccion = (e:any, row:any) =>{
        row.original.Seleccionado = e.target.checked;
        let d = {...Datos};
        let index = d.Eventos.  findIndex ((num:any) =>{
           return num.IdEvento === row.original.IdEvento 
        });
        if(index === -1)
            d.Eventos.push(row.original);
        else
            d.Eventos.splice(index,1);
        SetDatos(d);
    }
    const onchageSelectDropDownFiltroValor = (e:any, row:any) =>{
        let d = {...Datos};
        d.Eventos = d.Eventos.map((val:any) =>{
            if(val.IdEvento == row.original.IdEvento ){
                val.FiltroValor = e.target.value;
            }
            return {
                "Descripcion": val.Descripcion,
                "IdEvento":val.IdEvento,
                "Tipo":val.Tipo,
                "FiltroValor":val.FiltroValor,
                "Valor": val.Valor,
                "FiltroDuracion":val.FiltroDuracion,
                "Duracion":val.Duracion
            };
        })
        SetDatos(d);
    };
    //Valor del evento
    const onchageValor = (e:any, row:any) =>{
        let d = {...Datos};
        d.Eventos = d.Eventos.map((val:any) =>{
            if(val.IdEvento == row.original.IdEvento ){
                val.Valor =  e.target.value;
            }
            return {
                "Descripcion": val.Descripcion,
                "IdEvento":val.IdEvento,
                "Tipo":val.Tipo,
                "FiltroValor": val.FiltroValor,
                "Valor": val.Valor,
                "FiltroDuracion":val.FiltroDuracion,
                "Duracion":val.Duracion
            };
        })
        SetDatos(d);
    };
    //Valor del fitro de duracion
    const onchageSelectDropDownFiltroDuracion = (e:any, row:any) =>{
        let d = {...Datos};
        d.Eventos = d.Eventos.map((val:any) =>{
            if(val.IdEvento == row.original.IdEvento ){
                val.FiltroDuracion = e.target.value;
            }
            return {
                "Descripcion": val.Descripcion,
                "IdEvento":val.IdEvento,
                "Tipo":val.Tipo,
                "FiltroValor": val.FiltroValor,
                "Valor": val.Valor,
                "FiltroDuracion":val.FiltroDuracion,
                "Duracion":val.Duracion
            };
        })
        SetDatos(d);
    };
   //Duracion HH:MM:SS
    const onchageDuracion = (e:any, row:any) =>{
        let d = {...Datos};
        d.Eventos = d.Eventos.map((val:any) =>{
            if(val.IdEvento == row.original.IdEvento ){
                val.Duracion = e.target.value;
            }
            return {
                "Descripcion": val.Descripcion,
                "IdEvento":val.IdEvento,
                "Tipo":val.Tipo,
                "FiltroValor": val.FiltroValor,
                "Valor": val.Valor,
                "FiltroDuracion":val.FiltroDuracion,
                "Duracion":val.Duracion
            };
        })
        SetDatos(d);
    };
    //Retorna todo
    let Insights_Eventos :  MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'Seleccion',
            header: 'Seleccion',
            Cell({ cell, column, row, table, }) {
                return <input  onChange={(e:any) =>{
                    onchageSeleccion(e, row);
                }}  type="checkbox"/>
            },
            size:120,
        },
        {
            accessorKey: 'Descripcion',
            header: 'Descripcion del evento'
        },
        {
            accessorKey: 'IdEvento',
            header: 'Id de evento'
        }, {
            accessorKey: 'Tipo',
            header: 'Tipo de evento'
        },
        {
            accessorKey: 'FiltroValor',
            header: 'Filtro de valor del evento',
            Cell({ cell, column, row, table, }) {
                return <div className="input-group input-group-sm"><select disabled={( row.original.Seleccionado == false || row.original.Seleccionado == undefined ? true:false)}  className="form-control input-sm input-group-sm" onChange={(e:any) =>{
                    onchageSelectDropDownFiltroValor(e, row);
                }}>{
                    DropDownFiltro.map((val:any) =>{
                            return (<option key={val} value={val}>{val}</option>)
                    })
                }</select></div>
            },
        }
        ,
        {
            accessorKey: 'Valor',
            header: 'Valor del evento',
            Cell({ cell, column, row, table, }) {
                return  <div className="input-group input-group-sm"><input  disabled={( row.original.Seleccionado == false || row.original.Seleccionado == undefined ? true:false)} className="form-control input input-sm input-group-sm" onChange={(e:any) =>{
                    onchageValor(e, row);
                }} type="number"/></div>
            },
        },
        {
            accessorKey: 'FiltroDuracion',
            header: 'Filtro de duración',
            Cell({ cell, column, row, table, }) {
                return <div className="input-group input-group-sm"><select  disabled={( row.original.Seleccionado == false || row.original.Seleccionado == undefined ? true:false)} className="form-control input-sm input-group-sm" onChange={(e:any) =>{
                    onchageSelectDropDownFiltroDuracion(e, row);
                }} >{
                    DropDownFiltro.map((val:any) =>{
                            return (<option key={val} value={val}>{val}</option>)
                    })
                }</select></div>
            },
        },
        {
            accessorKey: 'Duracion',
            header: 'Duración',
            Cell({ cell, column, row, table, }) {
                return  <div className="input-group input-group-sm">
                                <input  disabled={( row.original.Seleccionado == false || row.original.Seleccionado == undefined ? true:false)}className="form-control input input-sm input-group-sm" placeholder="HH:MM:SS" onChange={(e:any) =>{
                    onchageDuracion(e, row);
                }}  type="text"/>
                </div>
            },
        }
        ];
        return Insights_Eventos;
    }
    export const DropDownFiltro = [
       "Ninguno",
       "Mayor que",
       "Menor que",
       "Igual a"
    ]

    export const Insigth_GetObservaciones = (arrayObservaciones: any[], observacion: string, Estado: any, vUser: any) => {
        arrayObservaciones.push({
            fecha: GetFechaServidor().format(formatableJson_HHMMss),
            observacion: observacion,
            usuario: vUser.Nombres,
            estado: `${Estado}`,
        })
    
    }