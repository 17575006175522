
import { useEffect, useState } from "react";
import { useDataFatigue } from "../core/provider";
import { listTabs } from "../data/tabListPanelCentral";
import BlockUi from "@availity/block-ui";
import { CardContainerEventos } from "./CardContainerAlertas";
import { DescargarExceFatiga } from "../../../../../_start/helpers/components/DescargarExcel";
import { Notification, useToaster } from "rsuite";
import { FiltroDataAlerta, NotificarCambios, columnsPrincipal, columnsDetalleEventos, fncReporteAlarma, getAlertas, setFinalizarJornada } from "../data/dashBoardData";
import confirmarDialog from "../../../../../_start/helpers/components/ConfirmDialog";
import { CerrarJornadaControaldor } from "../data/Operadores";
import { AxiosError, AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { UserModelSyscaf } from "../../../auth/models/UserModel";
import { RootState } from "../../../../../setup";
import { useHistory, withRouter } from 'react-router-dom';
import { Bienvenidos } from '../../../../pages/Principal';


type Props = {
  className: string;
  innerPadding?: string;
  Cliente?: any;
};

const FAG_PanelCentral: React.FC<Props> = ({ className, innerPadding = "", Cliente }) => {
  const location = useHistory();
  const user = useSelector<RootState>(
    ({ auth }) => auth.user
  );
  const vUser = user as UserModelSyscaf;
  const toaster = useToaster();

  const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
    return (<Notification className="bg-light-danger" type={type} header={titulo}
      closable duration={10000}>
      {mensaje}
    </Notification>)
  }

  let idinterval: number = 0;

  const { listadoEventosActivos, DataAlertas, DataDetallado, loader, activeTab, alertas,  usuario,
    setalertas, setActiveTab, clienteIds, InfoUsuario, Cliente : dataClienteParametros } = useDataFatigue();

  const [totalCriticos, settotalCriticos] = useState(0);
  const [totalModerados, settotalModerados] = useState(0);
  const [totalBajos, settotalBajos] = useState(0);
  const [totalEnGestion, settotalEnGestion] = useState(0);
  const [totalGestionados, settotalGestionados] = useState(0);
  const [totalPendientes, settotalPendientes] = useState(0);

  const [dataAlertasObservaciones, setdataAlertasObservaciones] = useState([]);
  const [dataAlertasEventos, setdataAlertasEventos] = useState([]);
  // objeto que guardara los contadores por tipo de riesgo
  // evaluara si hay nuevos elementos y los notificara en la plataforma
  // no importa si estan gestionados
  const [contadoresTotales, setcontadoresTotales] = useState({});

  useEffect(() => {
    settotalCriticos(alertas.filter((item: any) => item.Criticidad == "Riesgo alto" && item.EstadoGestion == null).length);
    settotalModerados(alertas.filter((item: any) => item.Criticidad == "Riesgo moderado" && item.EstadoGestion == null).length);
    settotalBajos(alertas.filter((item: any) => item.Criticidad == "Riesgo bajo" && item.EstadoGestion == null).length);
    settotalEnGestion(alertas.filter((item: any) => item.EstadoGestion == 0).length);
    settotalGestionados(alertas.filter((item: any) => item.EstadoGestion == 1).length);
    settotalPendientes(alertas.filter((item: any) => item.EstadoGestion == 2).length);
  }, [alertas]);

 


  // TRAE LA INFORMACION DE EVENTOS ACTIVOS POR DIA
  useEffect(() => {
    setTab(parseInt((activeTab != undefined ? activeTab?.replace('#tab', '') : '1')));
  }, [listadoEventosActivos]);


  useEffect(() => {
    if (activeTab == `#tab2`)
      setTab(2);
  }, [activeTab])



  useEffect(() => {

    let arrayObservaciones = new Array();
    let arrayEventos = new Array();

    if (alertas.length != 0) {


      alertas.forEach((element: any) => {

        let objprincipal = {
          AlertaId: element["AlertaId"],
          TipoAlerta: element["TipoAlerta"],
          vehiculo: element["vehiculo"],
          conductor: element["conductor"],
          EventDateTime: element["EventDateTime"],
          DetalladoEventos: element["DetalladoEventos"],
          EstadoGestion: element["EstadoGestion"],
          gestor: element["gestor"]
        }

        const detalle = JSON.parse(element["Observaciones"]);
        const EnvioReporte = element["Cabecera"] != null ? JSON.parse(element["Cabecera"]) : null;
        if (detalle == null && EnvioReporte == null) {
          arrayObservaciones.push(objprincipal);
        } else {
          if (EnvioReporte != null)
            objprincipal = { ...objprincipal, ...EnvioReporte };
          if (detalle != null)
            detalle.forEach((element2: any) => {
              arrayObservaciones.push({ ...objprincipal, ...element2 });
            });
        }

      }); // fin merge data

      setdataAlertasObservaciones(arrayObservaciones as []);

      alertas.forEach((element: any) => {

        let objprincipal = { AlertaId: element["AlertaId"] }

        const detalle = JSON.parse(element["DetalladoEventos"]);

        if (detalle == null) {
          arrayEventos.push(objprincipal);
        } else {
          detalle.forEach((element2: any) => {

            arrayEventos.push({ ...objprincipal, ...element2 });
          });
        }

      })

      setdataAlertasEventos(arrayEventos as []);


      // consolida los datos  actuales 
      const agrupadoActual = FiltroDataAlerta.getAgrupadoPorAlerta(alertas, clienteIds ?? "0");
      // verifica y notifica 
      var idTimeout = setTimeout(() => {
        NotificarCambios(FiltroDataAlerta.getSinNotificar(agrupadoActual, contadoresTotales, clienteIds ?? "0"), toaster);
        clearTimeout(idTimeout);
      }, 1000);

      setcontadoresTotales(agrupadoActual);


    }

  }, [alertas])



  const setTab = (tabNumber: number) => {
    setActiveTab(`#tab${tabNumber}`);
    
    const element = document.querySelector(
      `#tab${tabNumber}_chart`
    ) as HTMLElement;
    if (!element) {
      return;
    }

  };

  const finalizarJornada = () => {
    let alertasPosponer = alertas.filter((item: any) => item.gestor == usuario.Nombres && item.EstadoGestion == 0 && item.EsVisible)
    let alertasId = alertasPosponer.map((item: any) => { return item.AlertaId }).join();
    let Observaciones: any = {};
    Observaciones = {
      gestor: usuario.Nombres
    };

    confirmarDialog(() => {
      setFinalizarJornada(clienteIds as string, '[' + JSON.stringify(Observaciones) + ']').then((response) => {
        toaster.push(message('success', "Gestionar", "Jornada Finalizada"), {
          placement: 'topCenter'
        });

        getAlertas(clienteIds as string).then((response) => {
          setalertas(response.data);
        });

      }).catch((error) => {
        toaster.push(message('error', "Gestionar", "Error al intentar finalizar jornada"), {
          placement: 'topCenter'
        });
      });
      // Si es un usuario gnerico ejecuto esta parte sino no lo hago.
      if (Boolean(vUser.esGenerico)) {
        CerrarJornadaControaldor(String(InfoUsuario.Id)).then((resp: AxiosResponse<any>) => {
          location.push("/bienvenido");
          withRouter(Bienvenidos);
          console.log("Controlador desasociado.");
        }).catch((error: AxiosError<any>) => {
          console.log("Error: ", error);
        });
      }
    }, `Esta seguro de finalizar la jornada`, "Finalizar")
  }



  return (
    <div className={`card ${className}`}>
      <BlockUi tag="div" keepInView blocking={loader ?? false}  >
        {/* begin::Header */}
        <div className="card-header align-items-center border-0">
          <div className="card-title flex-column">
            <span className="fw-bolder text-dark fs-5 ms-4 me-20">Panel de Gestión de Riesgos {
              (InfoUsuario.Operador != undefined) ? `(Operador: ${InfoUsuario.Operador})` : ""}

            </span>
            <button className="m-2  btn btn-sm btn-primary" type="button" onClick={() => {
              DescargarExceFatiga(dataAlertasObservaciones, dataAlertasEventos, columnsPrincipal, columnsDetalleEventos, `Alertas`, fncReporteAlarma)
            }}>
              <i className="bi-file-earmark-excel"></i>
              <i className="bi-download"></i>Gestión
            </button>
            <button className="m-2  btn btn-sm btn-danger" type="button" onClick={() => {
              finalizarJornada();
            }}>
              <i className="bi-stoplights"></i>
              <i className="bi-journal-x"></i> Jornada
            </button>
          </div>

        </div>
        {/* end::Header */}
        {/* begin::Body */}

 { (dataClienteParametros) && (
        <div className="card-body pt-0">
          <div className=" flex-wrap flex-xxl-nowrap justify-content-center justify-content-md-start pt-4">
            {/* begin::Nav */}
            <div className="me-sm-10 me-0">
              <ul className="nav nav-tabs nav-pills nav-pills-custom" >
                {listTabs.map((tab, idx) => {
                  idx++;
                  return (<li className="nav-item  mb-3" key={`tabenc_${idx}`}>
                    <a
                      onClick={() => setTab(idx)}
                      className={`nav-link w-120px ${tab.bgColor}  h-50px ${activeTab === `#tab${idx}` ? "active " : ""
                        } fw-bolder`}
                      id={`tab${idx}`}
                      style={{
                        backgroundColor: tab.backgroudColor
                      }}
                    >
                      <div style={{ width: 120 }} className="ps-1 text-center">
                        <span className={` nav-text  text-${tab.textColor} fw-bolder fs-6`} >
                          {tab.titulo} {`(${idx == 1 ? totalCriticos : idx == 2 ? totalModerados : idx == 3 ? totalBajos
                            : idx == 4 ? totalEnGestion : idx == 5 ? totalGestionados : idx == 6 ? totalPendientes : tab.subtitulo
                            })`}
                        </span>

                      </div>
                    </a>
                  </li>
                  )
                })}
              </ul>
            </div>
            {/* end::Nav */}

            {/* begin::Tab Content */}
            <div className="tab-content flex-grow-1" // style={{ paddingLeft: "0.23rem !important" }}
            >

              <div
                className={`tab-pane fade ${activeTab === "#tab1" ? "show active" : ""
                  }`}
                id="tab1_content"
              >
                {/* begin::Content */}
                {/* begin::Cards */}
                <div className="overflow-auto">

                  {(activeTab === "#tab1") &&
                    // verificamos que exista datos para poder ingresar los datos en el contenedor 
                    (<CardContainerEventos isActive={true} isDetails={false} filtro={0} Clientes={Cliente}
                      Operador={InfoUsuario} />)
                  }
                </div>
              </div>
              <div
                className={`tab-pane fade ${activeTab === "#tab2" ? "show active" : ""
                  }`}
                id="tab2_content"
              >
                {/* begin::Content */}
                {/* begin::Cards */}
                <div className="overflow-auto">

                  {(activeTab === "#tab2") &&
                    // verificamos que exista datos para poder ingresar los datos en el contenedor 
                    (<CardContainerEventos isActive={true} isDetails={false} filtro={1} Clientes={Cliente} Operador={InfoUsuario} />)
                  }
                </div>
              </div>
              <div
                className={`tab-pane fade ${activeTab === "#tab3" ? "show active" : ""
                  }`}
                id="tab3_content"
              >
                {/* begin::Content */}
                {/* begin::Cards */}
                <div className="overflow-auto">

                  {
                    (activeTab === "#tab3") &&
                    // verificamos que exista datos para poder ingresar los datos en el contenedor 
                    (<CardContainerEventos isActive={true} isDetails={false} filtro={2} Clientes={Cliente} Operador={InfoUsuario} />)
                  }
                </div>
              </div>
              <div
                className={`tab-pane fade ${activeTab === "#tab4" ? "show active" : ""
                  }`}
                id="tab4_content"
              >
                {/* begin::Content */}
                {/* begin::Cards */}
                <div className="overflow-auto">

                  {
                    (activeTab === "#tab4") &&
                    // verificamos que exista datos para poder ingresar los datos en el contenedor 
                    (<CardContainerEventos isActive={true} isDetails={false} filtro={3} Clientes={Cliente} Operador={InfoUsuario} />)
                  }
                </div>
              </div>
              <div
                className={`tab-pane fade ${activeTab === "#tab5" ? "show active" : ""
                  }`}
                id="tab5_content"
              >
                {/* begin::Content */}
                {/* begin::Cards */}
                <div className="overflow-auto">

                  {
                    (activeTab === "#tab5") &&
                    // verificamos que exista datos para poder ingresar los datos en el contenedor 
                    (<CardContainerEventos isActive={true} isDetails={false} filtro={4} Operador={InfoUsuario} />)
                  }
                </div>
              </div>

              <div
                className={`tab-pane fade ${activeTab === "#tab6" ? "show active" : ""
                  }`}
                id="tab6_content"
              >
                {/* begin::Content */}
                {/* begin::Cards */}
                <div className="overflow-auto">

                  {
                    (activeTab === "#tab6") &&
                    // verificamos que exista datos para poder ingresar los datos en el contenedor 
                    (<CardContainerEventos isActive={true} isDetails={false} filtro={5} Operador={InfoUsuario} />)
                  }
                </div>
              </div>


              {/* end::Content  */}






            </div>
            {/* end::Tab Content */}
          </div>
        </div>
) }

        {/* end: Card Body */}
      </BlockUi>
    </div>
  );
};

export { FAG_PanelCentral };
