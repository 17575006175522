import { Form } from "react-bootstrap-v5";
import { Post_getConsultasDinamicasConAuthCore, Post_getconsultadinamicasUser } from "../../../../../_start/helpers/Axios/CoreService";
import React from "react";
import moment from "moment";
import { formatableJson_HHMMss } from "../../../../../_start/helpers/Helper";

export async function GetListaRolPorOrganizacion(OrganizacionId: number) {
    var params: { [id: string]: string | null; } = {};
    params["OrganizacionId"] = `${OrganizacionId}`;
    // hacemos la consulta 
    return Post_getconsultadinamicasUser({ Clase: "ADMQueryHelper", NombreConsulta: "GetListaRolPorOrganizacion", Pagina: null, RecordsPorPagina: null }, params);
};

export async function SetOrganizacionesUsuario(organizacionesSel: any[], UsuarioId: string) {
    var params: { [id: string]: string | null; } = {};
    params["lstOrganizaciones"] = organizacionesSel.join(',');
    params["UsuarioId"] = `${UsuarioId}`;
    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({ Clase: "ADMQueryHelper", NombreConsulta: "SetOrganizacionesUsuario", Pagina: null, RecordsPorPagina: null }, params);
};
export async function SetRolesUsuario(lsRoles: any[], OrganizacionId: number, UsuarioId: string) {
    var params: { [id: string]: string | null; } = {};
    params["lsRoles"] = lsRoles.join(',');
    params["OrganizacionId"] = `${OrganizacionId}`;
    params["UsuarioId"] = `${UsuarioId}`;
    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({ Clase: "ADMQueryHelper", NombreConsulta: "SetRolesUsuario", Pagina: null, RecordsPorPagina: null }, params);
};
export async function GetOpcionesPorRol(RolId: number) {
    var params: { [id: string]: string | null; } = {};
    params["RolId"] = `${RolId}`;
    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({ Clase: "ADMQueryHelper", NombreConsulta: "GetOpcionesPorRol", Pagina: null, RecordsPorPagina: null }, params);
};

export async function GetOpcionesPorUsuario(UsuarioId: string) {
    var params: { [id: string]: string | null; } = {};
    params["UsuarioId"] = `${UsuarioId}`;
    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({ Clase: "ADMQueryHelper", NombreConsulta: "GetOpcionesPorUsuario", Pagina: null, RecordsPorPagina: null }, params);
};


export async function GetClaimsUsuario( UsuarioId : string) {
    var params: { [id: string]: string | null; } = {};
    params["UsuarioId"] = `${UsuarioId}`;

    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({ Clase: "ADMQueryHelper", NombreConsulta: "GetClaimsUsuario", Pagina: null, RecordsPorPagina: null }, params);
};
export async function GetLogUsuarioPorUsuario( UsuarioId : string,  FechaInicial : Date, FechaFinal : Date) {
    var params: { [id: string]: string | null; } = {};
    params["UsuarioId"] = `${UsuarioId}`;
    params["FechaInicial"] = `${moment(FechaInicial).format(formatableJson_HHMMss)}`;
    params["FechaFinal"] =  `${moment(FechaFinal).endOf('day').format(formatableJson_HHMMss)}`;

    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({ Clase: "PORTALQueryHelper", NombreConsulta: "GetLogUsuarioPorUsuario", Pagina: null, RecordsPorPagina: null }, params);
};

export async function SetClaimsUsuario(Tipo: number, ClaimId: number, ClaimType: string | null, ClaimValue: string | null, UsuarioId : string | null) {
    var params: { [id: string]: string | null; } = {};
    params["Tipo"] = `${Tipo}`;
    params["ClaimId"] = `${ClaimId}`;
    params["ClaimType"] = ClaimType;
    params["ClaimValue"] = ClaimValue;
    params["UsuarioId"] = UsuarioId;

    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({ Clase: "ADMQueryHelper", NombreConsulta: "SetClaimsUsuario", Pagina: null, RecordsPorPagina: null }, params);
};

export async function ListaClaims( ) {
    var params: { [id: string]: string | null; } = {};
    return Post_getConsultasDinamicasConAuthCore({ Clase: "ADMQueryHelper", NombreConsulta: "ListaClaims", Pagina: null, RecordsPorPagina: null }, params);
};






export const ECO_ConsultasRoles = {

    getOrganizaciones: (lstOrganizaciones: any[]) => {
        return lstOrganizaciones.filter(f => f.EsActivo)
    },
    getOrganizacionesDual: (lstOrganizaciones: any[]) => {
        return lstOrganizaciones.filter(f => f.EsActivo).map(m => { return { value: m.OrganizacionId, label: m.Nombre } });
    },
    getOrgAsignadas: (lstOrganizaciones: any[], UserId: string) => {
        const lstOrga = ECO_ConsultasRoles.getOrganizaciones(lstOrganizaciones);
        //.map(m => { return { value: m.OrganizacionId, label: m.Nombre }}
        return lstOrga.filter((f: any) => f.Usuarios?.indexOf(UserId) > -1).map(m => (m.OrganizacionId as string));

    },
    getOrgAsignadasDropDownList: (lstOrganizaciones: any[], UserId: string) => {
        const lstOrga = ECO_ConsultasRoles.getOrganizaciones(lstOrganizaciones);
        //.map(m => { return { value: m.OrganizacionId, label: m.Nombre }}
        return lstOrga.filter((f: any) => f.Usuarios?.indexOf(UserId) > -1).map((m: any) => {
            return { OrganizacionId: m.OrganizacionId, Nombre: m.Nombre }
        });

    },
    getOrgNoAsignadas: (lstOrganizaciones: any[], UserId: string) => {
        const lstOrga = ECO_ConsultasRoles.getOrganizaciones(lstOrganizaciones);
        //.map(m => { return { value: m.OrganizacionId, label: m.Nombre }}
        return lstOrga.filter((f: any) => f.Usuarios?.indexOf(UserId) === -1).map(m => (m.OrganizacionId as string));

    },
    getRolesUsuarioDual: (lstroles: any[]) => {

        return lstroles.map(m => { return { value: m.RolId, label: m.Nombre } });
    },
    getRolesUsuarioAsignadas: (lstroles: any[], UserId: string) => {
        return lstroles.filter((f: any) => f.Usuarios?.indexOf(UserId) > -1).map(m => (m.RolId as string));

    },
    getOpcionesUsuario: (lstOpciones: any[]) => {
        const categorizaciones = lstOpciones.reduce((p: any[], c) => {
            const cat = c.OrganizacionId; // si no hay categoria se asigna no categorizado
            const filtro: any[] = p.filter(f => f.id === cat);
            if (filtro.length === 0)
                p.push({
                    id: cat,
                    name: c.Modulo,
                    children: [c]
                },)

            else
                filtro[0].children.push(c);
            return p;
        }, []);

        return categorizaciones;

    },
}

export const ComponenteModulo: React.FC<{ moduloSel: any, lstModulo: any[], setModuloSel: any }> = ({ moduloSel, lstModulo, setModuloSel }) => {
    return (
        <div className="input-group mb-3">
            <span style={{ height: '40px' }} className="input-group-text mt-3" id="basic-addon1"><i className="bi-file-earmark-person-fill"></i></span>
            <Form.Select style={{ height: '40px' }} className="input-sm  mb-3 mt-3 " onChange={(e) => {
                setModuloSel(e.currentTarget.value);
            }} value={moduloSel} aria-label="Default select example">
                <option value={0}>Seleccione Módulo</option>
                {
                    lstModulo?.map((element: any, i: any) => {
                        return (<option key={element.OrganizacionId} value={element.OrganizacionId}>{element.Nombre}</option>)
                    })
                }
            </Form.Select>
        </div>
    )
}

export const ComponenteRolesModulo: React.FC<{ moduloRolSel: any, lstRolesModulo: any[], setRolModuloSel: any }> =
    ({ moduloRolSel, lstRolesModulo, setRolModuloSel }) => {
        return (
            <div className="input-group mb-3">
                <span style={{ height: '40px' }} className="input-group-text mt-3" id="basic-addon1"><i className="bi-file-earmark-person-fill"></i></span>
                <Form.Select style={{ height: '40px' }} className="input-sm  mb-3 mt-3 " onChange={(e) => {
                    setRolModuloSel(e.currentTarget.value);
                }} value={moduloRolSel} aria-label="Default select example">
                    <option value={0}>Seleccione Rol</option>
                    {
                        lstRolesModulo?.map((element: any, i: any) => {
                            return (<option key={element.value} value={element.value}>{element.label}</option>)
                        })
                    }
                </Form.Select>
            </div>
        )
    }

