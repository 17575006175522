import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useState } from "react";
import { Tab } from "react-bootstrap-v5";
import { CamposColores } from "../../../data/Configuracion";
type Props = {      
    row: any;
    objetoGuardar : any;
    setObjetoGuardar : any;
};

export const TabConfiguracion: React.FC<Props> = ({  row, objetoGuardar ,setObjetoGuardar}) => {

    const original = row == null ? {maxAmber : 4, minAmber :2} : row;
    const [errorMaximo, seterrorMaximo] = useState<any>("");
    const [errorMinimo, seterrorMinimo] = useState<any>("");
    const [maximo, setmaximo] = useState<number>(original.maxAmber); // seteamos los principales
    const [minimo, setminimo] = useState<number>(original.minAmber);
    const [dataColores, setdataColores] = useState<any[]>([original] );// si es nuevo se colocan los valores por defecto, de lo contrario lo que viene del
   
    const setValores = () => {
        const [colores] = dataColores;
        const valores = { ...colores };
        valores.maxAmber = maximo;
        valores.minAmber = minimo;
        if (parseInt(maximo.toString()) < parseInt(minimo.toString())) {
            seterrorMaximo("El máximo no puede ser menor");
            seterrorMinimo("El mínimo no puede ser mayor o igual al máximo");
        }
        else {
            seterrorMaximo("");
            seterrorMinimo("");
            setdataColores([valores]);
            // seteamos los valores que se van a guardar en el padre
            setObjetoGuardar({...objetoGuardar, ...valores})
        }
    };
    return <>

    <div className="row mt-2 rounded shadow-sm border mx-1 mb-1">
        <span className="text-muted fs-6 mb-1">* Permite configurar los valores mínimo y máximo de la clasificación de la alerta de riesgo moderado, el riesgo bajo y crítico serán en base a los valores ingresados para el riesgo moderado.</span>

        <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
            <label className="control-label label-sm font-weight-bold" htmlFor="Valores" style={{ fontWeight: 'bold' }}>Valor mínimo amber:</label>
            <div className="input-group mb-3 mt-1">
                <span className="input-group-text"><i className="fas fa-pen"></i></span>
                <input type="number" name="minimo" placeholder="Ingrese Valor mínimo amber"
                    className="form-control input-sm" value={minimo} onBlur={setValores} onChange={(e: any) => setminimo(e.target.value)} />
                <span className="text-danger">{errorMinimo}</span>
            </div>
        </div>
        <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
            <label className="control-label label-sm font-weight-bold" htmlFor="Valores" style={{ fontWeight: 'bold' }}>Valor máximo amber:</label>
            <div className="input-group mb-3 mt-1">
                <span className="input-group-text"><i className="fas fa-pen"></i></span>
                <input type="number" name="maximo" placeholder="Ingrese valor máximo amber" className="form-control input-sm" value={maximo} onBlur={setValores} onChange={(e: any) => setmaximo(e.target.value)} />
                <span className="text-danger">{errorMaximo}</span>
            </div>
        </div>
    </div>
    <div className="rounded shadow-sm border mx-1 mb-1">
        <MaterialReactTable
            localization={MRT_Localization_ES}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center'
                    }
                },
            }}
            columns={CamposColores}
            data={dataColores}
            muiTableBodyCellProps={({ row, cell }) => ({

                sx: {

                    backgroundColor:

                        cell.column.id == 'Min Rojo' ? 'rgba(248, 215, 218, 1)' :
                            cell.column.id == 'Max Verde' ? 'rgba(212, 237, 218, 1)' :
                                'rgba(255, 243, 205, 1)'
                }

            })}
            enableTopToolbar
            enableColumnOrdering
            enableFilters ={false}
            enablePagination={false}
            enableColumnFilters={false}     
          
            rowCount={dataColores.length}
            initialState={{ density: 'compact' }}
            
        /></div></>

     
}