import BlockUi from "@availity/block-ui";
import { PageTitle } from "../../../../../../_start/layout/core";
import { useEffect, useState } from "react";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import {  GetOperadoresH} from "../../data/Operadores";
import {  AxiosResponse } from "axios";
import moment from "moment";
import { FormatoColombiaDDMMYYYHHmm, ranges } from "../../../../../../_start/helpers/Constants";
import { useToaster, Notification, DateRangePicker } from "rsuite";
import { UserModelSyscaf } from "../../../../auth/models/UserModel";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";
import {  formatSimpleJson,  msToTimeSeconds } from "../../../../../../_start/helpers/Helper";
import { PunchClock, Search } from "@mui/icons-material";
import { MensajeError } from "../../../../../../_start/helpers/components/Toaster";
import { Button } from "react-bootstrap-v5";


export default function Operadores() {
    const toaster = useToaster();
    const [loader, setLoader] = useState<boolean>(false);
    const [Data, setData] = useState<any[]>([]);
    /* table state*/
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
  
    const [RangoFechas, setJornadaRangoFechas] = useState<any>({
        Inicial: moment().add(-6, 'month').toDate(),
        Final: moment().toDate()
    });

    const [ObjetoGeneral, setObjetoGeneral] = useState<any>({

        FechaInicio: moment().add(-6, 'month').format(formatSimpleJson),
        FechaFinal: moment().format(formatSimpleJson)
    })
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;
    let Campos: MRT_ColumnDef<any>[] =
        [
           
            {
                accessorKey: 'Cliente',
                header: 'Cliente'
            },
            {
                accessorKey: 'Usuario',
                header: 'Usuario',
            },
            {
                accessorKey: 'NombreOperador',
                header: 'Operador'
            },
            {
                accessorKey: 'FechaInicio',
                header: 'Fecha inicio',
                Cell({ row, }) {
                    return moment(row.original.FechaInicio).format(FormatoColombiaDDMMYYYHHmm);
                },
            },
            {
                accessorKey: 'FechaFinal',
                header: 'Fecha final',
                Cell({ row, }) {
                    return (row.original.FechaFinal == null ? "" : moment(row.original.FechaFinal).format(FormatoColombiaDDMMYYYHHmm));
                },
            }
            ,
            {
                accessorKey: 'OperadorId',
                header: 'Tiempo',
                Cell({ row, }) {
                    return (row.original.FechaFinal == null ? "En uso" :
                        msToTimeSeconds(moment(row.original.FechaFinal).diff(moment(row.original.FechaInicio), 'seconds')))
                    //.format(FormatoColombiaDDMMYYYHHmm));
                },
            }
        ];
    //USEEFFECT
    useEffect(() => {

        Operadores();
    }, [])
    //FUNCIONES
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }

    const Operadores = () => {
        setLoader(true);
        GetOperadoresH(ObjetoGeneral).then((response: AxiosResponse<any>) => {
            if (response.status == 200)
                setData(response.data)
        }).catch(() => {
            setData([]);
            toaster.push(MensajeError("Cargar Info", "Error al cargar listado de operadores"))
        }).finally(() => {
            setLoader(false);
        });
    }

    const { allowedMaxDays, allowedRange, combine } = DateRangePicker;


    return <>
        <PageTitle>Histórico de usuarios a controladores</PageTitle>

        <BlockUi tag="div" keepInView blocking={loader} message="Consultando datos..." >
        <div className="d-flex justify-content-between">
                <div className="mx-auto">
                    <div className="ms-3 text-center">
                        <h3 className="mb-0">Controladores y Operadores</h3>
                        <span className="text-muted m-3">Permite visualizar el historial de los usuarios asociados a los Controladores.</span>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-start m-5">

                <h4 className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Filtros: </h4>
                <div className="d-flex justify-content-start"  >
                    <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Rango Fechas: </label>
                    {(combine && allowedMaxDays && allowedRange) && (
                        <DateRangePicker
                            disabledDate={combine(allowedMaxDays(180), allowedRange(
                                moment().add(-12, 'month').startOf('day').toDate(), moment().startOf('day').toDate()
                            ))} ranges={ranges} format="dd/MM/yyyy" caretAs={PunchClock}
                            value={[RangoFechas.Inicial, RangoFechas.Final]}
                            onChange={(value, e) => {
                                if (value !== null) {
                                    const inicial: any = value[0];
                                    const final: any = value[1];

                                    setJornadaRangoFechas({
                                        Inicial: inicial,
                                        Final: final
                                    })

                                    setObjetoGeneral({
                                        FechaInicio: moment(value[0]).format(formatSimpleJson),
                                        FechaFinal: moment(value[1]).format(formatSimpleJson)
                                    })
                                }
                            }}
                        />)}


                    <Button variant="success" className=" p-2 mb-2 ms-2 mt-2" onClick={()=>{ Operadores()}}>
                       <Search/>
                    </Button>

                </div>



            </div>
         
            <div className="card d-flex justify-content-start ">

                <div className="mt-1 rounded">
                    {(Data != undefined) && (<MaterialReactTable
                        localization={MRT_Localization_ES}
                        muiTableHeadCellProps={{
                            sx: () => ({
                                fontSize: 14,
                                fontStyle: 'bold',
                                color: 'rgb(27, 66, 94)'
                            }),
                        }}
                        columns={Campos}
                        data={Data}
                        enableColumnOrdering
                       
                        onColumnFiltersChange={setColumnFilters}
                        onGlobalFilterChange={setGlobalFilter}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                       
                        enableStickyHeader
                        enableDensityToggle={false}
                        enablePagination={false}
                        enableRowVirtualization
                        defaultColumn={{
                            minSize: 150, //allow columns to get smaller than default
                            maxSize: 400, //allow columns to get larger than default
                            size: 150, //make columns wider by default
                        }}
                        muiTableContainerProps={{
                            sx: { maxHeight: '400px' }, //give the table a max height
                        }}
                        initialState={{ density: 'compact' }}
                        state={{
                            columnFilters,
                            globalFilter,                         
                            pagination,                          
                            sorting,
                        }}
                    />)}
                </div>
            </div>

        </BlockUi>

    </>
}