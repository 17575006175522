/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDataFatigue } from "../../../core/provider";
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { getCSSVariableValue } from "../../../../../../../_start/assets/ts/_utils";

type Props = {
    className: string;
    innerPadding?: string;
    tipoData?: number;
    nameChart?: string;
    titulo: string;
};

export const IndicadorGestion: React.FC<Props> = ({ className, nameChart = "clasificacionFlota_", titulo }) => {


    const { alertas } = useDataFatigue();

    const colorsuccess = getCSSVariableValue("--bs-success");
    const colorwarning = getCSSVariableValue("--bs-warning");
    const colorblue = getCSSVariableValue("--bs-primary");
    const colordanger = getCSSVariableValue("--bs-danger");
    const colorinfo = getCSSVariableValue("--bs-info");


    const [opciones, setopciones] = useState<any>();

    // paleta de colores por serie
    const Colores: any = {
        "Gestionado": colorsuccess,
        "Abierto": colorblue,
        "Sin Gestionar": colorwarning,
        "Cerrado Auto": colordanger,
        "Pendiente": colorinfo
    };
    let colorsArray: string[] = [];
    //Pintamos indicador 2 donna
    useEffect(() => {

        let dataFiltrada = alertas.filter((item: any) => "Riesgo alto".indexOf(item.Criticidad) > -1).
            map((m: any) => {
                m.TipoGestion = (m.EstadoGestion == 1 && m.EsCerradoAuto) ? "Cerrado Auto" :
                    (!m.EsCerradoAuto && m.EstadoGestion == 1) ? "Gestionado" :
                        (m.EstadoGestion == null) ? "Sin Gestionar" : "Abierto";
                return m;
            });



        let agrupadoAlertas = dataFiltrada
            .reduce((p: any, c: any) => {
                let name = c.TipoGestion;
                p[name] = p[name] ?? 0;
                p[name] = p[name] + 1;
                return p;
            }, {});

   

        if (Object.keys(agrupadoAlertas).length > 0) {
            let labelsArray = Object.keys(agrupadoAlertas) as string[];
            let arrayChart: any[] = Object.values(agrupadoAlertas);

            labelsArray.forEach((f) => {
                colorsArray.push(Colores[f])
            })

            // se determina de que tipo se necesita la informacion para mostrarla en los indicadores

            let opciones: ApexOptions = {
                series: arrayChart,
                colors: colorsArray,
                labels: labelsArray,
                chart: {
                    id: nameChart,
                    fontFamily: 'Montserrat',
                    type: 'donut',
                    dropShadow: {
                        enabled: true,
                        color: '#111',
                        top: -1,
                        left: 3,
                        blur: 3,
                        opacity: 0.2
                    }
                },
                stroke: {
                    width: 0,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                value: {
                                    show: true,
                                    fontSize: '21px',
                                    offsetY: -7
                                },
                                total: {
                                    showAlways: true,
                                    show: true,
                                    fontSize: '12px',
                                }
                            }
                        }
                    }
                },
              
                dataLabels: {
                    enabled: true,
                    formatter: function (val: number) {
                        return val.toFixed(1) + "%"
                    },
                },
                legend: {
                    show: true,
                    fontSize: '10px',
                    position: 'bottom',
                    formatter: function (seriesName: any, opts: any) {
                        return [seriesName, "(", opts.w.globals.series[opts.seriesIndex], ")"].join(' ')
                    }
                },

                title: {
                    text: titulo,
                    align: 'center',
                },



            };
            setopciones(opciones);
        }


    }, [alertas]);
    return (
        <div className={`card ${className}`}>
            {(opciones != undefined) && (
                <Chart key={`chardonaalertascriticas_${nameChart}`} options={opciones} series={opciones.series}
                    type="donut" />
            )}
        </div>
    );
};





