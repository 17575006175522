import { MRT_ColumnDef } from "material-react-table";
import { GetClientes, Post_SetGrupoSeguridad,  Post_getConsultasDinamicasConAuthCore } from "../../../../../_start/helpers/Axios/CoreService";
import { Etiqueta } from "../components/GruposSeguridad";


export function GetGruposSeguridad(clientesIdS: string | null){
    var params: { [id: string]: string | null; } = {};
    params["clienteIdS"] =  clientesIdS;
    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({
        Clase: "AdmQueryHelper",
        NombreConsulta: "ConsultarGrupoSeguridadClientes",
        Pagina: null,
        RecordsPorPagina: null
    },
        params);
    
};

export function GetSitiosGrupoSeguridadClientes(grupoSeguridadId:string, clientesIdS: string | null){
    var params: { [id: string]: string | null; } = {};
    params["clienteIds"] =  clientesIdS;
    params["GrupoSeguridadID"] = String(grupoSeguridadId) ;
    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({
        Clase: "AdmQueryHelper",
        NombreConsulta: "ConsultarSitiosGrupoSeguridadCliente",
        Pagina: null,
        RecordsPorPagina: null
    },
        params);
    
};
export function SetGruposSeguridad(nombre:string, descripcion:string, grupoSeguridadId:string, clave: string, esactivo: boolean) {
    let params : { [id: string]: string | null | boolean; } = {};
    params['Clave'] = clave;
    params['Nombre'] = nombre;
    params['Descripcion'] = descripcion;
    params['EsActivo'] = esactivo;
    params['GrupoSeguridadId'] = grupoSeguridadId;
    return Post_SetGrupoSeguridad(params);
}

export function getListadoClientes() {
    var params: { [id: string]: string | null; } = {};
  
    return GetClientes(params);
}

export function getListadoUsuarios() {
    var params: { [id: string]: string | null; } = {};
    params["OrganizacionId"] = '1';

    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({
        Clase: "AdmQueryHelper",
        NombreConsulta: "GetListadoUsuarioOrganizacion",
        Pagina: null,
        RecordsPorPagina: null
    },
        params);
};

export function GetListas() {
    var params: { [id: string]: string | null; } = {};
    params["Sigla"] = 'GSPSYS';

    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({
        Clase: "PortalQueryHelper",
        NombreConsulta: "GetListasBySiglas",
        Pagina: null,
        RecordsPorPagina: null
    },
        params);
};

export function getClientesGrupoDeSeguridad(grupoSeguridadId: string | null) {
    var params: { [id: string]: string | null; } = {};
    params["grupoSeguridadId"] = grupoSeguridadId ? grupoSeguridadId.toString() : null;

    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({
        Clase: "AdmQueryHelper",
        NombreConsulta: "getClientesGruposDeSeguridad",
        Pagina: null,
        RecordsPorPagina: null
    },
        params);
};

export function setClientesGrupoDeSeguridad(grupoSeguridadId: string, clienteIds: string|null) {
    var params: { [id: string]: string | null; } = {};
    params["GrupoSeguridadId"] = grupoSeguridadId.toString();
    params["Clientes"] = clienteIds;

    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({
        Clase: "AdmQueryHelper",
        NombreConsulta: "setClientesGrupoDeSeguridad",
        Pagina: null,
        RecordsPorPagina: null
    },
        params);
};
export function setSitiosGrupoDeSeguridad(grupoSeguridadId: string, TipoSeguridadId: string, Sites:string) {
    var params: { [id: string]: string | null; } = {};
    params["GruposeguridadId"] = String(grupoSeguridadId);
    params["TipoSeguridadId"] = String(TipoSeguridadId);
    params["Sites"] = String(Sites);
    
    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({
        Clase: "AdmQueryHelper",
        NombreConsulta: "SetSitiosAgrupoSeguridad",
        Pagina: null,
        RecordsPorPagina: null
    },
        params);
};



// export function GetGruposSeguridad(grupoSeguridadId: string, clienteIds: string) {
//     var params: { [id: string]: string | null; } = {};
//     params["grupoSeguridadId"] = grupoSeguridadId.toString();
//     params["clienteIds"] = clienteIds;

//     // hacemos la consulta 
//     return Post_getConsultasDinamicasConAuthCore({
//         Clase: "AdmQueryHelper",
//         NombreConsulta: "setClientesGrupoDeSeguridad",
//         Pagina: null,
//         RecordsPorPagina: null
//     },
//         params);
// };

export function getUsuariosGrupoDeSeguridad(grupoSeguridadId: string | null) {
    var params: { [id: string]: string | null; } = {};
    params["grupoSeguridadId"] = grupoSeguridadId ? grupoSeguridadId.toString() : null;

    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({
        Clase: "AdmQueryHelper",
        NombreConsulta: "getUsuariosGruposDeSeguridad",
        Pagina: null,
        RecordsPorPagina: null
    },
        params);
};

export function setUsuariosGrupoDeSeguridad(grupoSeguridadId: string, usuariosIds: string) {
    var params: { [id: string]: string | null; } = {};
    params["grupoSeguridadId"] = grupoSeguridadId.toString();
    params["usuariosIds"] = usuariosIds;

    // hacemos la consulta 
    return Post_getConsultasDinamicasConAuthCore({
        Clase: "AdmQueryHelper",
        NombreConsulta: "setUsuariosGrupoDeSeguridad",
        Pagina: null,
        RecordsPorPagina: null
    },
        params);
};

export const Campos: MRT_ColumnDef<any>[] =
        [{
            accessorKey: 'NombreGrupo',
            header: 'Grupo de Seguridad',
            maxSize: 100
        },
       
        {
            accessorKey: 'Descripcion',
            header: 'Descripción',
            maxSize: 80
        },
        {
            accessorKey: 'EsActivo',
            header: 'Estado',
            Cell({ cell, column, row, table, }) {
                return Etiqueta( cell.getValue()) 
            },
            maxSize: 60
        }
        ];

  