import { Notification } from "rsuite";
import { toAbsoluteUrl } from "../AssetHelpers";
import { ReactNode } from "react";
import { MessageType } from "rsuite/esm/Notification/Notification";


type props = { 
   includeSonido : boolean;
   duration: number;
   closable? : boolean | undefined;
   type : MessageType ;
   content : ReactNode;
   header :  ReactNode;
}
export const NotificacionSyscaf = (props : props) => {
    let className : string;
    className=`bg-light-${props.type.replace('error', 'danger')}` ;
 
return (
    <Notification { ...{duration : props.duration,closable : props.closable,type : props.type,
       className, header : props.header} } >
        {
            (props.includeSonido) && (      <audio  src={toAbsoluteUrl("/media/sounds/alerts/marimba.mp3")} autoPlay={true}></audio>)
        }
       {props.content}
    </Notification>
)
;

}