import { MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import { FormatoColombiaDDMMYYYHHmmss } from "../../../../../_start/helpers/Constants";
import { formatableJson_HHMMss, msToTimeSeconds } from "../../../../../_start/helpers/Helper";
import { Post_GetConsultasDinamicasDWH } from "../../../../../_start/helpers/Axios/DWHService";

export const CHL_DetalleViajesCampos: MRT_ColumnDef<any>[] =

    [{
        accessorKey: 'Placa',
        header: 'Placa',
        size: 100
    }, {
        accessorKey: 'Operador',
        header: 'Operador',
        size: 100
    },
    {
        accessorKey: 'tripStart',
        header: 'Inicio',
        size: 100,
        Cell({ cell, column, row, table, }) {
            return (moment(row.original.tripStart).format(FormatoColombiaDDMMYYYHHmmss))
        }
    },
    {
        accessorKey: 'tripEnd',
        header: 'Fin',
        size: 100,
        Cell({ cell, column, row, table, }) {
            return (moment(row.original.tripEnd).format(FormatoColombiaDDMMYYYHHmmss))
        }
    },

    {
        accessorKey: 'Duracion',
        header: 'Duración',
        Cell({ cell, column, row, table, }) {
            return (msToTimeSeconds(row.original.Duracion))
        }
    }

    ];


export const DefaultRowViaje = {
    TripId: -1,
    Placa: "",
    tripStart: moment().format(formatableJson_HHMMss),
    tripEnd: moment().format(formatableJson_HHMMss),
    Duracion: 0,
    AssetId: 0,
    DriverId: 0,
    WeekYear: 0,
    Conductor: ""
}

export const Tooltips: any = {
    "NuevoViaje": { title: "Nuevo Registro de Viaje", content: "Permite adicionar registros faltantes, la información se volverá a procesar en la próxima ejecución" }
    , "Conductores": { title: "Búsqueda por Conductores", content: "Se basa en el listado de contuctores traído de la consulta previamente realizada." }
    , "Busqueda": { title: "Botón Buscar", content: "Permite traer información en el rango de fecha definido." }
    , "ConsolidadoSemana": { title: "Consolidado Semanal", content: "Muestra por operador la suma total de las horas generadas en la semana en un solo registro, muestra en rojo aquellos que superen la jornada semanal." }
    , "Novedades": { title: "Novedades Diarias", content: "Muestra en rojo aquellos viajes que superen 12 horas de conducción." }
    , "Historial": { title: "Historial de Cambios", content: "Permite ver todos los movimientos realizados que generaron cambios en el cálculo." }
    , "HC_errorDatos": { title: "Error Consultar Datos", content: "No se pudo obtener los datos, intente nuevamente." }
}

export const TipoGuardado: any = {
    "1": "EDITAR",
    "2": "ADICIONAR",
    "3": "ELIMINAR"
}

export function GestionViajes(ClienteIds: string, Tipo: string, Data: string, usuario: string) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Tipo'] = Tipo;
    params['ClienteIds'] = ClienteIds;
    params['Data'] = Data;
    params['UsuarioId'] = usuario;
    return Post_GetConsultasDinamicasDWH({
        NombreConsulta: "GestionViajes", Clase: "CHLQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};

