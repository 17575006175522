import { MRT_ColumnDef } from "material-react-table";

export const preop_columnasplantillas: MRT_ColumnDef<any>[] =
    [{
        accessorKey: 'TipoPlantilla',
        header: 'Tipo',
        size: 100
    }, {
        accessorKey: 'Nombre',
        header: 'Nombre',
        size: 100
    },
    {
        accessorKey: 'Observaciones',
        header: 'Observaciones',
        size: 100
    },
    {
        accessorKey: 'EsActivo',
        header: 'Estado',
        Cell({ row, }) {
            return <span className={(row.original.EsActivo == 1 ? 'badge bg-success' : 'badge bg-danger')}>{(row.original.EsActivo == 1 ? "Activo" : "Inactivo")}</span>
        },
        size: 80
    },
    {
        accessorKey: 'Usuario',
        header: 'Usuario',
        size: 80
    }
    ];

export const preop_preguntasPlantilla: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'Descripcion',
            header: 'Descripcion',
            size: 80
        },
        {
            accessorKey: 'Valores',
            header: 'Valores',
            size: 100
        },
        {
            accessorKey: 'Secuencia',
            header: 'Secuencia',
            size: 100
        },

        {
            accessorKey: 'Agrupador',
            header: 'Agrupador',
            size: 80
        },
        {
            accessorKey: 'EsActivo',
            header: 'Estado',
            Cell({ row, }) {
                return <span className={(row.original.EsActivo == 1 ? 'badge bg-success' : 'badge bg-danger')}>{(row.original.EsActivo == 1 ? "Activo" : "Inactivo")}</span>
            },
            size: 80
        }
    ];

export const PREOP_ListadoUsuario: MRT_ColumnDef<any>[] =
    [{
        accessorKey: 'Nombres',
        header: 'Usuario',
        size: 100
    }, {
        accessorKey: 'UserName',
        header: 'UserName',
        size: 100
    },
    {
        accessorKey: 'LockoutEnabled',
        header: 'Estado',
        Cell({ row, }) {
            return <span className={(row.original.LockoutEnabled == 1 ? 'badge bg-success' : 'badge bg-danger')}>{(row.original.LockoutEnabled == 1 ? "Activo" : "Inactivo")}</span>
        },
        size: 100
    },
    {
        accessorKey: 'DetallesAdicionales',
        header: 'Cargo',
        Cell({ row, }) {
            let i = JSON.parse(row.original.DetallesAdicionales);
            return <span>{(i == null ? '' : i.Tipo)}</span>
        },
        size: 80
    }];