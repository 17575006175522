import { ListComponents   } from "./Syscaf";
import { ListaClientes } from "./Clientes";
import { ListaModulos } from "./modulos";
import { ListaApps  } from "./apps";
import { ListaAuth } from "./seguridad";

export let ListRoutes : any[] = [];

ListRoutes.push(...ListComponents);
ListRoutes.push(...ListaClientes);
ListRoutes.push(...ListaModulos);
ListRoutes.push(...ListaApps);
ListRoutes.push(...ListaAuth);