import { useEffect, useState } from "react";
import { getPreguntasPlantillas, getTipos, setPreguntasPlantillas, Tooltips } from "../../../data/dataConfiguracion";
import { AxiosError, AxiosResponse } from "axios";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap-v5";
import { Box, IconButton, Tooltip } from "@mui/material";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../../_start/helpers/components/HtlmTooltip";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useDataConfiguracion } from "../../../core/ConfiguracionCore";
import { Check, Edit } from "@mui/icons-material";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { preop_preguntasPlantilla } from "../../../data/dataConfiguracionPlantilla";
import { Checkbox, useToaster } from "rsuite";


export const Preguntas: React.FC = () => {
    const { clienteSeleccionado, model, plantillaSel,
        showObject, setshowObject
    } = useDataConfiguracion();
  const toaster = useToaster();
    const [TipoPregunta, setTipoPregunta] = useState<any[]>([]);
    const pr = {
        ClienteId: (clienteSeleccionado?.ClienteId == undefined ? null : clienteSeleccionado?.ClienteId),
        UsuarioId: model.Id,
        EsActivo: true,
        Agrupador: "",
        Clave: "1",
        CompareRed: "",
        Descripcion: "",
        EsObligatorio: true,
        IsRedLine:false,
        PreguntaId: "",
        Secuencia: "",
        TienesImagenes: false,
        TipoPreguntaId: "",
        Valores: ""
    };
    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [data, setData] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState(0);
    const [Pregunta, setPregunta] = useState<any>(pr)
    const [showM, setshowM] = useState<boolean>(false);
    const [isLoading, setisLoading] = useState<boolean>(false);
    useEffect(() => {
        ConsultarPreguntas(`${plantillaSel.PlantillaId}`);
    }, [])
    useEffect(() => {
        ObtenerTiposPreguntas();
    }, [clienteSeleccionado])

    const ObtenerTiposPreguntas = () => {
        getTipos().then((response: AxiosResponse<any>) => {
            setTipoPregunta(response.data);
        }).catch((error : AxiosError) => {
            toaster.push(MensajeError('Consultar Preguntas ', getMensajeErrorAxios(error)), { placement: 'topCenter' })
        })
    };

    const TiposPreguntas = () => {
        return <>
            <Form.Select className="input input-sm" defaultValue={Pregunta.TipoPreguntaId} onChange={(e) => {
                let p = { ...Pregunta };
                p.TipoPreguntaId = e.currentTarget.value;
                setPregunta(p);
            }} aria-label="">
                {
                    TipoPregunta.map((element: any) => {
                        return (<option key={element.TipoId} value={element.TipoId}>{element.Nombres}</option>)
                    })
                }
            </Form.Select>
        </>
    }
    const ConsultarPreguntas = (PlantillaId: string) => {
        setisLoading(true)
        getPreguntasPlantillas(PlantillaId).then((response: AxiosResponse<any>) => {
            setData(response.data.sort((a: any, b: any) => a.Secuencia - b.Secuencia));
        }).catch((error : AxiosError) => {
            toaster.push(MensajeError('Consultar Preguntas ', getMensajeErrorAxios(error)), { placement: 'topCenter' })
        }).finally(()=>{setisLoading(false)})
    }
    const EditarPreguntas = (row: any) => {
        let r = row.original;
        let p = { ...Pregunta };
        r.Clave = "2";
        p = r;
        setPregunta(p);
        setshowM(true);
    };
    const GuardarPreguntas = () => {
        confirmarDialog(() => {
            setPreguntasPlantillas(Pregunta).then((response: AxiosResponse<any>) => {
                setData(response.data.sort((a: any, b: any) => a.Secuencia - b.Secuencia));
                successDialog(response.data[0].Mensaje, "");
                setshowM(false);
            }).catch((error : AxiosError) => {
                toaster.push(MensajeError('Guardar Preguntas ', getMensajeErrorAxios(error)), { placement: 'topCenter' })
            })
        }, `Esta seguro que desea guardar la pregunta`, 'Guardar');
    }
    const InactivarPregunta = (row: any) => {
        let r = row.original;
        let p = { ...Pregunta };
        r.Clave = "3";
        console.log("Antes: ", r.EsActivo);
        r.EsActivo = (r.EsActivo == true ? false : true);     
        p = r;
        setPregunta(p);
        confirmarDialog(() => {
            setPreguntasPlantillas(r).then((response: AxiosResponse<any>) => {
                setData(response.data.sort((a: any, b: any) => a.Secuencia - b.Secuencia));
                successDialog(response.data[0].Mensaje, "");
            }).catch((error : AxiosError) => {
                toaster.push(MensajeError('Cambiar estado Preguntas ', getMensajeErrorAxios(error)), { placement: 'topCenter' })
            });
        }, `¿Esta seguro que desea cambiar el estado a la pregunta?`, 'Guardar');
    }
    const Nuevo = () => {
        let p = { ...Pregunta, ...pr, PlantillaId: plantillaSel.PlantillaId };
        setPregunta(p)
        setshowM(true);
    }

    return (
        <>
            <div className="form-horizontal  mx-auto">
                <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Cerrar"].title, Tooltips["Cerrar"].content)} >
                    <Button type="button" className="bt btn-sm" variant="secondary" onClick={() => {
                        setshowObject({ ...showObject, plantillas: true, panelCentral: false, preguntas: false });
                    }}>
                        Cerrar
                    </Button>
                </HtmlTooltipStyled>
                <div className="ms-3 text-center">
                    <h4 className="mb-0">Configuración de preguntas plantilla</h4>
                </div>
            </div>
            <div className="container">
                <Row>
                    <Col md={6} >
                        <span className="fs-4 fw-bold">Cliente: {plantillaSel.clienteNombre}</span>
                    </Col>
                    <Col md={6}>

                    </Col>
                </Row>
                <Row>
                    <Col md={6} >
                        <span className="fs-4 fw-bold">Tipo: {plantillaSel.TipoPlantilla}</span>
                    </Col>
                    <Col md={6} className="d-flex gap-2">
                        <span className="fs-4 fw-bold">Plantilla:</span>
                        <span className="fs-4 fw-bold"> {plantillaSel.Nombre}</span>
                        <span className={(plantillaSel.EsActivo == 1 ? 'badge bg-success' : 'badge bg-danger')}>{(plantillaSel.EsActivo == 1 ? "Activo" : "Inactivo")}</span>

                    </Col>
                </Row>




            </div>
            <hr className="m-2" />
            <div className="row">
                <div className="col-6  ">
                    <label className="control-label label label-sm fw-bolder"></label>
                    <div className="input-group">
                        <button onClick={Nuevo} disabled={(plantillaSel.PlantillaId == null || plantillaSel.PlantillaId == undefined)} className="btn btn-sm btn-primary float-end"><i className="bi-building-fill-add"></i> Nueva</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="mt-5">
                {isLoading && <><Spinner className="mt-4" size="sm" animation={"border"} /> Cargando.... </>}
                    {(data.length > 0 ? <MaterialReactTable
                        enableColumnResizing
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 120,
                            },
                        }}
                        columns={preop_preguntasPlantilla}
                        data={data}
                        enableTopToolbar={false}
                        enableColumnOrdering
                        enableEditing
                        onColumnFiltersChange={setColumnFilters}
                        onGlobalFilterChange={setGlobalFilter}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                        rowCount={rowCount}
                        enablePagination={false}
                        enableStickyHeader
                        enableDensityToggle={false}
                        muiTableContainerProps={{
                            sx: { maxHeight: '600px' }, //give the table a max height
                        }}
                        state={{
                            columnFilters,
                            globalFilter,

                            pagination,
                            sorting,
                        }}
                        renderRowActions={({ row }) => (
                            <>
                                <Box sx={{ display: 'flex', gap: '1rem' }}>
                                    <Tooltip arrow placement="left" title="Editar cargo">
                                        <IconButton
                                            onClick={() => {
                                                EditarPreguntas(row);
                                                // setInactivo(true);
                                            }}
                                        >
                                            <Edit className="text-success" />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip arrow placement="left" title="Inactivar usuario">
                                        <IconButton
                                            onClick={() => {
                                                InactivarPregunta(row);
                                            }}
                                        >
                                            <Check className="text-danger" />
                                        </IconButton>
                                    </Tooltip>

                                </Box>
                            </>
                        )
                        }
                    /> : <div className="d-flex justify-content-center" role="status">
                        No hay datos que mostrar
                    </div>)}
                </div>
            </div>

            <Modal className="border border-primary"
                show={showM}
                onHide={setshowM}
                size="xl">
                <Modal.Header className="fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-between">
                    <Modal.Title>Configuración Preguntas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card shadow-sm p-2">

                        <Form className="m-2 " style={{ width: '100%' }} >
                            <Row  >
                                <Col sm="6">
                                    <Form.Group as={Row} className="mb-3 " >
                                        <Form.Label column sm="3" >Tipo Pregunta:</Form.Label>
                                        <Col sm="9">
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1"><i className="bi-activity"></i></span>
                                                <TiposPreguntas />
                                            </div>
                                        </Col>
                                    </Form.Group>

                                </Col>
                                <Col sm="6" >
                                    <Form.Group as={Row} className="mb-3 " >
                                        <Form.Label column sm="3" >Valores:</Form.Label>
                                        <Col sm="9">
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1"><i className="bi-activity"></i></span>
                                                <input className="form-control input input-sm" type="text" placeholder="Valores" value={Pregunta.Valores} onChange={(e: any) => {
                                                    let p = { ...Pregunta };
                                                    p.Valores = e.currentTarget.value;
                                                    setPregunta(p);
                                                }} />
                                            </div>
                                        </Col>
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row  >
                                <Col sm="4">
                                    <Form.Group as={Row} className="mb-3 " >
                                        <Form.Label column sm="4" >Secuencia:</Form.Label>
                                        <Col sm="6">
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1"><i className="bi-activity"></i></span>

                                                <input className="form-control input input-sm" type="number" placeholder="Secuencia" value={Pregunta.Secuencia} onChange={(e: any) => {
                                                    let p = { ...Pregunta };
                                                    p.Secuencia = e.currentTarget.value;
                                                    setPregunta(p);
                                                }} />
                                            </div>
                                        </Col>
                                    </Form.Group>

                                </Col>
                                <Col sm="4"  className="d-flex justify-content-start gap-1">
                                    
                                        <Form.Label  className="mt-3" >Tiene Imagenes?:</Form.Label>
                                      
                                            <Checkbox checked={Pregunta.TienesImagenes}
                                                onChange={(e: any, checked: boolean) => {
                                                    let p = { ...Pregunta };
                                                    p.TienesImagenes = checked;
                                                    setPregunta(p);
                                                }}></Checkbox>
                                       

                                </Col>
                                <Col sm="4"  className="d-flex justify-content-start gap-1">
                                   
                                        <Form.Label className="mt-3" >Es Obligatorio?:</Form.Label>
                                      
                                            <Checkbox checked={Pregunta.EsObligatorio}
                                                onChange={(e: any, checked: boolean) => {
                                                    let p = { ...Pregunta };
                                                    p.EsObligatorio = checked;
                                                    setPregunta(p);
                                                }}></Checkbox>                                      
                                </Col>
                            </Row>
                            <Row  >

                                <Col sm="6">
                                    <Form.Group as={Row} className="mb-3 " >
                                        <Form.Label column sm="3" >Agrupador:</Form.Label>
                                        <Col sm="6">
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1"><i className="bi-activity"></i></span>
                                                <input className="form-control input input-sm" type="text" placeholder="Agrupador" value={Pregunta.Agrupador} onChange={(e: any) => {
                                                    let p = { ...Pregunta };
                                                    p.Agrupador = e.currentTarget.value;
                                                    setPregunta(p);
                                                }} />
                                            </div>
                                        </Col>
                                    </Form.Group>

                                </Col>
                                <Col sm="6">
                                    <Form.Group as={Row} className="mb-3 " >
                                        <Form.Label column sm="3" >Pregunta:</Form.Label>
                                        <Col sm="9">
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1"><i className="bi-activity"></i></span>
                                                <input className="form-control input input-sm" type="text" placeholder="Descripción" value={Pregunta.Descripcion} onChange={(e: any) => {
                                                    let p = { ...Pregunta };
                                                    p.Descripcion = e.currentTarget.value;
                                                    setPregunta(p);
                                                }} />
                                            </div>
                                        </Col>
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row  >


                                <Col sm="3" className="d-flex justify-content-start gap-1">
                                    <Form.Label className="mt-3" >Es Linea Roja?:</Form.Label>
                                    <Checkbox checked={Pregunta.IsRedLine}
                                        onChange={(e: any, checked: boolean) => {
                                            let p = { ...Pregunta };
                                            p.IsRedLine = checked;
                                            p.EsObligatorio = checked;
                                            setPregunta(p);
                                        }}></Checkbox>
                                </Col>
                                <Col sm="6" hidden={!Pregunta.IsRedLine}>
                                    <Form.Group as={Row} className="mb-3 " >
                                        <Form.Label column sm="3" >Respuesta Linea:</Form.Label>
                                        <Col sm="9">
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1"><i className="bi-activity"></i></span>
                                                <input className="form-control input input-sm" type="text" placeholder="Respuesta"
                                                    value={Pregunta.CompareRed} onChange={(e: any) => {
                                                        let p = { ...Pregunta };
                                                        p.CompareRed = e.currentTarget.value;
                                                        setPregunta(p);
                                                    }} />
                                            </div>
                                        </Col>
                                    </Form.Group>

                                </Col>
                                <Col sm="3" className="d-flex justify-content-start gap-1">
                                    <Form.Label className="mt-3"  >Estado:</Form.Label>
                                    <Checkbox checked={Pregunta.EsActivo}
                                        onChange={(e: any, checked: boolean) => {
                                            let p = { ...Pregunta };
                                            p.EsActivo = checked;
                                            setPregunta(p);
                                        }}></Checkbox>



                                </Col>
                            </Row>
                        </Form>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Guardar"].title, Tooltips["Guardar"].content)} >
                        <Button type="button" className="btn btn-sm float-end" variant="primary" onClick={GuardarPreguntas}>
                            Guardar
                        </Button>
                    </HtmlTooltipStyled>
                    <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Cerrar"].title, Tooltips["Cerrar"].content)} >
                        <Button type="button" className="bt btn-sm" variant="secondary" onClick={() => {
                            setshowM(false);
                        }}>
                            Cancelar
                        </Button>
                    </HtmlTooltipStyled>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function MensajeError(arg0: string, arg1: any): import("react").ReactNode {
    throw new Error("Function not implemented.");
}


function getMensajeErrorAxios(error: AxiosError<unknown, any>): any {
    throw new Error("Function not implemented.");
}
