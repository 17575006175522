const tab1 : any = {  icon: "", iconColored : "", titulo: "Críticos", subtitulo : "", 
textColor:"danger", backgroudColor:"rgba(248, 215, 218, 1)", bgColor: ""}
const tab2 : any = {  icon: "", iconColored : "", titulo: "Moderados", subtitulo : "",
textColor:"warning", backgroudColor:"rgba(255, 243, 205, 1)", bgColor: ""}
const tab3 : any = {  icon: "", iconColored : "", titulo: "Bajos", subtitulo : "",
textColor:"success", backgroudColor:"rgba(212, 237, 218, 1)", bgColor: ""}
const tab4 : any = {  icon: "", iconColored : "", titulo: "En Gestión", subtitulo : "",
textColor:"white", backgroudColor:"", bgColor: "bg-primary"}
const tab5 : any = {  icon: "", iconColored : "", titulo: "Gestionados", subtitulo : "",
textColor:"white", backgroudColor:"", bgColor: "bg-success"}
const tab6 : any = {  icon: "", iconColored : "", titulo: "Pendientes", subtitulo : "",
textColor:"white", backgroudColor:"", bgColor: "bg-warning"}
export const listTabs : any[] = [tab1, tab2, tab3, tab4, tab5, tab6/*, tab6*/]


