import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { RootState } from "../setup";
import { ThemeProvider as ThemeProviderGeneral } from "../_start/layout/core";
import { MasterLayout } from "../_start/layout/MasterLayout";
import { Logout } from "./modules/auth/Logout";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { PublicRoutes } from "./routing/PublicRoutes";
import { createTheme, ThemeProvider } from '@mui/material';
import { CustomProvider } from "rsuite"
import esEs from 'rsuite/locales/es_ES';
import { UserModelSyscaf } from "./modules/auth/models/UserModel";
import jwt_decode from "jwt-decode"
import moment from "moment";
import { FechaServidor } from "../_start/helpers/Helper";
import { resetTokenAndReattemptRequest } from "../setup/axios/SetupAxios";
// "rsuite": "^5.34.0",
type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );
  const tableTheme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: "Montserrat"
        }
      }),
    [],
  );
  const SessionExpirada = () => {
    const token: string | null = localStorage.getItem("token");
    // verificamos si en nuestro storage local se encuentra el token
    if (token != null) {
      var decoded = jwt_decode<UserModelSyscaf>(token);
      // al momento de que ingrese a la pantalla se revisa 
      if (moment.unix(decoded.exp).toDate() < FechaServidor()) {
        resetTokenAndReattemptRequest(dispatch);
      }
    }
    return false;
  }
  useEffect(() => {
  
    // se ejecuta cada vez que entran por
    SessionExpirada();
    // adicionamos el evento que cada vez que cambia de pesta;a valida si ha expirado 
    // su token
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        SessionExpirada();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <BrowserRouter basename={basename}>
      <CustomProvider locale={esEs}>
        <ThemeProviderGeneral>
          <ThemeProvider theme={tableTheme}>
            <Switch>
              <Route path="/logout" component={Logout} />
              {!isAuthorized ? (
                <Route>
                  <PublicRoutes />
                </Route>
              ) : (
                <>
                  <MasterLayout>
                    <PrivateRoutes />
                  </MasterLayout>
                </>
              )}
            </Switch>
          </ThemeProvider>
        </ThemeProviderGeneral>
      </CustomProvider>

    </BrowserRouter>
  );
};

export { App };

