import { Button, Modal } from "react-bootstrap-v5"
import { ActualizaDatosRequerimientos, ConsecutivoVisual, GetDatosRequerimientos, GetObservaciones, NotificarRequerimiento, SetRequerimiento, SinUsuario, StringNotificacion } from "../../../../data/Requerimientos"
import { EncabezadoHtml, AgentesEditar } from "../Encabezado"
import { ConsultasIniciales, useDataRequerimientos } from "../../../../core/RequerimientosCore"
import { useEffect, useState } from "react"
import { useToaster } from "rsuite"
import { AxiosResponse } from "axios"
import { useSelector } from "react-redux"
import confirmarDialog, { successDialog } from "../../../../../../../../_start/helpers/components/ConfirmDialog"
import { message } from "../../../../../../../../_start/helpers/components/Toaster"
import { DeserializarJSon, GetFechaServidor, formatableJson_HHMMss } from "../../../../../../../../_start/helpers/Helper"
import { RootState } from "../../../../../../../../setup"
import { UserModelSyscaf } from "../../../../../../auth/models/UserModel"
import { RetornarEstadosFlujo, RetornarFlujo } from "../../../../data/dashboard"
type PropsModalAsignacion = {
    ShowAsignacion: any,
    setShowAsignacion: any
}
export const ModalAsignacion: React.FC<PropsModalAsignacion> = ({
    ShowAsignacion,
    setShowAsignacion
}) => {
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;
    const { UserActual, lstUsuarios, ReqSeleccionado, lstEstadosReq,
        setloader, setlstRequerimientos, perfil } = useDataRequerimientos();
    const [UsuarioSeleccionado, setUsuarioSeleccionado] = useState<any>(SinUsuario);
    const toaster = useToaster();

    useEffect(() => {
        if (!perfil.EsGestor)
            setUsuarioSeleccionado(UserActual);

    }, [])

    //Guarda la asignacion de agente a Requerimiento
    const GuardarAsginacion = () => {

        let UsuarioId = (UsuarioSeleccionado.UserId == undefined ? vUser.Id : UsuarioSeleccionado.UserId);
        let NombreUsuario = (UsuarioSeleccionado.Nombres == undefined ? vUser.Nombres : UsuarioSeleccionado.Nombres);
        if (UsuarioId == "0") // verificamos que escoja un agente
            toaster.push(message('error', "Asignar Requerimiento", "Debe Escoger un Agente."), {
                placement: 'topCenter'
            });
        let flagG = (UsuarioId != "0")
        if (flagG) {
            const cabecera = DeserializarJSon(ReqSeleccionado.original.Cabecera)
            // traemos la informacion de la cabecera y actualizamos los datos relevantes
            // se guardara el historial de agentes por si es regresado se pueda saber que agente lo ten'ia anteriormente
            const userSeleccionado = { ...UsuarioSeleccionado, Fecha: GetFechaServidor().format(formatableJson_HHMMss) };
            let _Cabecera = {
                ...cabecera,
                UsuarioId: UsuarioId,
                agente: NombreUsuario,
                LstAgentes: cabecera.LstAgentes == undefined ? [userSeleccionado] : [...cabecera.LstAgentes, userSeleccionado]

            }
            // cuando se asigna debe cambiar estado a asignado
            let Estado = DeserializarJSon(ReqSeleccionado.original.Estado);
            console.log(Estado, lstEstadosReq ,  RetornarFlujo(lstEstadosReq, Estado))

            const SiguienteEstado =
                (Estado.tipoestado != "noopen") ? [] :
                    RetornarEstadosFlujo(lstEstadosReq, RetornarFlujo(lstEstadosReq, Estado))

            if (SiguienteEstado.length > 0)
                Estado = SiguienteEstado[0];
            let _obs = DeserializarJSon(ReqSeleccionado.original.Observaciones);
            GetObservaciones(_obs, `Requerimiento Asignado a ${NombreUsuario} Agente de ${(UsuarioSeleccionado.tipo == undefined ? "Soporte" : UsuarioSeleccionado.tipo)}.`, Estado, vUser, false, false)

            let Campos = GetDatosRequerimientos(ReqSeleccionado.original.Id, _Cabecera, _obs, Estado);
            confirmarDialog(() => {
                setloader(true)
                SetRequerimiento(Campos).then((response: AxiosResponse<any>) => {
                    if (response.statusText == "OK") {
                        successDialog(response.data[0][""], '')

                        // FiltroDatos(data);
                        ConsultasIniciales(setloader, vUser, setlstRequerimientos, toaster); //ACTUALIZAR DE FORMA LOCAL Y NO VOLVER A LLAMAR

                        if (UserActual.EsGestor) { // notificamos requerimiento si es gestior
                            NotificarRequerimiento(UsuarioId, ReqSeleccionado.original.Id,
                                StringNotificacion.replace("{UsuarioDestino}", `${UsuarioSeleccionado.Nombres}`).replace("{Admin}",
                                    `${cabecera.administrador}`).replace("{Consecutivo}", `${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`))
                        }
                        setloader(false);
                        setShowAsignacion(false);
                    }
                }).catch((error: any) => {

                    toaster.push(message('error', "Asignar Requerimiento", "Error al asignar requerimiento, favor comunicarse con su Administrador."), {
                        placement: 'topCenter'
                    });
                    setloader(false);
                });
            }, `¿Esta seguro que desea asignar el requerimiento al agente?`, 'Si');
        }
    }

    return (
        <Modal show={ShowAsignacion} onHide={setShowAsignacion} size={"lg"}>
            <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                <Modal.Title>{`Asignar Agente a Requerimiento -  ${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <EncabezadoHtml ReqSeleccionado={ReqSeleccionado}></EncabezadoHtml>
                    </div>
                    <div className="col-6">
                        <div className="form-control-sm">
                            <label className="control-label label label-sm" style={{ fontWeight: 'bold' }}>Agente: </label>
                            <AgentesEditar
                                perfil={perfil}
                                UsuarioActual={UserActual}
                                setUsuarioSeleccionado={setUsuarioSeleccionado}
                                Usuarios={lstUsuarios} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btn-sm" variant="primary" onClick={() => {
                    GuardarAsginacion()
                }}>
                    Guardar
                </Button>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => setShowAsignacion(false)}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}



