import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../../setup"
import { PageTitle } from "../../../../_start/layout/core"
import { UserModelSyscaf } from "../../auth/models/UserModel"
import { DataVehiculoOperando, PreoperacionalProvider } from "./core/provider"
import { Indicadores } from "./components/Gestion/Indicadores"
import { MOV_PanelCentral } from "./components/Gestion/panelCentral"
import { Form } from "react-bootstrap-v5"
import moment from "moment"
import { EsJson, FechaServidor, formatSimpleJson } from "../../../../_start/helpers/Helper"
import { isArray } from "lodash"
import { DatePicker, Radio, RadioGroup } from "rsuite"

export default function Preoperacional() {

    // Traemos información del usuario Logueado
    const isAuthorized = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const model = (isAuthorized as UserModelSyscaf);
   
    // Variables de seteo
    const [Fecha, setFecha] = useState(FechaServidor());
    const [datosPreoperacional, sedatosPreoperacional] = useState<any[]>([]);
    const [Chidlren, setChildren] = useState("");
    const [Filtro, setFiltro] = useState("2");
    const [clienteSeleccionado, setclienteSeleccionado] = useState<string>('');
    const [clienteId, setclienteId] = useState<string>('');

    //Control Fecha
    function FechaInicialControl() {

        //Datetime picker

        return (
            <DatePicker oneTap className="mb- 1mt-1" format="dd/MM/yyyy" value={Fecha}
                onSelect={(value, e) => {
                    if (value !== null) {
                        setFecha(value);
                    }
                }}
            />
        );
    }

    // Inicializamos y asignamos el objeto children que usara el provider para modificarse
    let params: any = {};
    useEffect(() => {
        if (EsJson(model.preoperacional)) {
           const  datosPreoperacional = JSON.parse(model.preoperacional) as any[];
           sedatosPreoperacional(datosPreoperacional);
            setclienteSeleccionado(datosPreoperacional[0]['ClienteIds']);
            setclienteId(datosPreoperacional[0]['ClienteId']);
        }
    }, []);

    // Use effect que setea fecha y children cuando cambia el usuario
    useEffect(() => {
        params = {
            clienteid: clienteId,
            clienteIdS: clienteSeleccionado,
            fecha: moment(Fecha).format(formatSimpleJson),
            userId: (model.Id?.toString())
        };
        setChildren(params);
    }, [clienteSeleccionado]);

    useEffect(() => {
        Consultar();
    }, [Fecha]);


    // Función consulta con baese en los controles y usuario 
    const Consultar = () => {
        params = {
            clienteid: clienteId,
            clienteIdS: clienteSeleccionado,
            fecha: Fecha,
            userId: (model.Id?.toString())
        };
        setChildren(params);
    }

    function CargaListadoClientes() {

        return (
            <Form.Select className=" mb-3 " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema                  
                setclienteSeleccionado(e.currentTarget.value)
                let filter = datosPreoperacional.filter(f => f["ClienteIds"] == e.currentTarget.value);
                if (filter.length > 0) {
                    setclienteId(filter[0]['ClienteId']);
                }

            }} aria-label="" value={clienteSeleccionado}>
                {
                    datosPreoperacional?.map((element: any, i: any) => {
                        return (<option key={element['ClienteIds']} value={element['ClienteIds']}>{element['nombre']}</option>)
                    })
                }
            </Form.Select>
        );
    }
    function CargarSites() {

        let filtrado = datosPreoperacional.filter((value: any, index: any) => {

            return value["ClienteIds"] == clienteSeleccionado
        })
        // validamos que sean todos los sitios
        // o los especificamente selecccionados
        let arraySites: any[] = [];
        if (filtrado.length > 0) {
            if (isArray(filtrado[0]['sites'])) {
                filtrado[0]['sites'].map((value: any) => {
                    arraySites.push({ siteid: value['siteid'], nombre: value['nombre'] })
                });
            } else {
                arraySites.push({ siteid: filtrado[0]['sites'], nombre: "Todos" })
            }
        }
        return (
            <Form.Select className=" mb-3 " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema                  
                //setclienteSeleccionado(e.currentTarget.value)
            }} aria-label="" value={datosPreoperacional[0]['sites']}>
                {
                    arraySites.map((element: any, i: any) => {
                        return (<option key={element.siteid} value={element.siteid}>{element.nombre}</option>)
                    })
                }
            </Form.Select>
        );
    }

    //Validamos que children si tengo información
    if (datosPreoperacional.length > 0) {
        //Retornamos pagina principal
        return (
            <>
                <PreoperacionalProvider>
                    <PageTitle >Preoperacional App</PageTitle>
                    <DataVehiculoOperando>{Chidlren}</DataVehiculoOperando>
                    <div className=" g-0 g-xl-10 g-xxl-8 mt-2" >
                        <div className="row rounded shadow-sm bg-secondary mt-2">
                            <div className="col-sm-6 col-md-6 col-xs-6 mt-2">
                                <label className="control-label label text-primary label-sm" style={{ fontWeight: 'bold' }}>Cliente:</label>
                                <CargaListadoClientes />
                            </div>
                            <div className="col-sm-6 col-md-6 col-xs-6 mt-2">
                                <label className="control-label label text-primary label-sm" style={{ fontWeight: 'bold' }}>Site:</label>
                                <CargarSites />
                            </div>
                            <div className="row    m-2">

                                <div className="d-flex justify-content-start mx-4 "   >
                                    <h6 className=" mt-3 mx-4">Fecha:</h6>
                                    <FechaInicialControl />

                                    <RadioGroup className="mx-4 fw-bold bg-white" inline appearance="picker" value={Filtro}
                                        onChange={(e: any) => {
                                            setFiltro(e)
                                        }}
                                    >
                                        <Radio value={"2"}>Todos </Radio>
                                        <Radio value={"0"}>Aprobadas</Radio>
                                        <Radio value={"1"}>No Aprobados</Radio>


                                    </RadioGroup>
                                    <button className="btn btn-sm btn-primary mx-2" title="Consultar" type="button" onClick={Consultar}><i className="bi-search"></i>Consultar</button>

                                </div>


                            </div>
                        </div>


                        <div className="row mt-2">
                            <Indicadores />
                        </div>


                        <div className="row rounded shadow-sm bg-secondary mt-2">
                            <div className="col-xl-12">
                                <MOV_PanelCentral className="card-stretch mb-5 mb-xxl-8" clienteid={clienteId}
                                    fecha={moment(Fecha)} filtro={Filtro} />
                            </div>
                        </div>
                    </div>
                </PreoperacionalProvider>
            </>
        )
    } else
        return <> <span>Usuario no se encuentra configurado en el sistema.</span></>


}

