import { Button, Modal } from "react-bootstrap-v5"
import { EncabezadoHtml } from "./EncabezadoHtml"
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import {Bienvenidos} from '../../../../../pages/Principal'
type propsModalControlador = {
    show:any,
    setshow:any,
    Guardar:any,
    vUser:any,
    ObjetoGeneral:any,
    setObjetoGeneral:any
}
export const ModalControlador:React.FC<propsModalControlador> = ({show, setshow,Guardar, vUser, ObjetoGeneral, setObjetoGeneral}) =>{
    const location = useHistory();
const Cancelar = () =>{
    setshow(false);
    location.push("/bienvenido");
	withRouter(Bienvenidos);
}

return<Modal show={show} onHide={setshow} size={"lg"}>
<Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
    <Modal.Title>Asociación a Operador</Modal.Title>
</Modal.Header>
<Modal.Body className="p-0">
    <div className="row mx-5">
        <div className="col-12">
            <EncabezadoHtml vUser={vUser}  ObjetoGeneral={ObjetoGeneral} setObjetoGeneral={setObjetoGeneral}></EncabezadoHtml>
        </div>
    </div>
</Modal.Body>
<Modal.Footer>
    <Button type="button" className="btn btn-sm" variant="primary" onClick={() => {
        Guardar()
    }}>
        Guardar
    </Button>
    <Button type="button" className="btn btn-sm" variant="secondary" onClick={Cancelar}>
        Cerrar
    </Button>
</Modal.Footer>
</Modal>
}