import { Post_GetConsultasDinamicasUserCore } from "../../../../../_start/helpers/Axios/DWHService";
import { Organizaciones } from "../../../../../_start/helpers/Constants";


export function SetOperadores(ObjetoGeneral:any) {
    var params: { [id: string]: string | null; } = {};
    params['Clave'] = ObjetoGeneral.Clave;
    params['Operador'] = ObjetoGeneral.Operador;
    params['ClienteIds'] = ObjetoGeneral.ClienteIds;
    params['FechaInicio'] = ObjetoGeneral.FechaInicio;
    params['FechaFinal'] = ObjetoGeneral.FechaFinal;
    params['Id'] = ObjetoGeneral.Id;

    return Post_GetConsultasDinamicasUserCore({
      Clase: "FATGQueryHelper", NombreConsulta: "GuardarModificarOperadores",
      Pagina: null,
      RecordsPorPagina: null
    } ,params);
  }
  
  export function GetUsuarios() {
    var params: { [id: string]: string | null; } = {};
    params['OrganizacionId'] = Organizaciones.FATIGUE;
    return Post_GetConsultasDinamicasUserCore({
      Clase: "FATGQueryHelper", NombreConsulta: "ConsultarUsuarioOperadores",
      Pagina: null,
      RecordsPorPagina: null
    } ,params);
  }

  export function GetOperadores(ObjetoGeneral:any) {
    var params: { [id: string]: string | null; } = {};
    params['Operador'] = ObjetoGeneral.Operador;
    return Post_GetConsultasDinamicasUserCore({
      Clase: "FATGQueryHelper", NombreConsulta: "ConsultarOperadores",
      Pagina: null,
      RecordsPorPagina: null
    } ,params);
  }
  export function GetOperadoresH(ObjetoGeneral:any) {
    var params: { [id: string]: string | null; } = {};
    params['FechaInicio'] = ObjetoGeneral.FechaInicio;
    params['FechaFinal'] = ObjetoGeneral.FechaFinal;
    return Post_GetConsultasDinamicasUserCore({
      Clase: "FATGQueryHelper", NombreConsulta: "ConsultarOperadoresH",
      Pagina: null,
      RecordsPorPagina: null
    } ,params);
  }
  

  export function GetParametros(ClienteId:any|null, ClienteIds:any|null) {
    var params: { [id: string]: string | null; } = {};
    params['ClienteId'] = ClienteId;
    params['ClienteIds'] = ClienteIds;
    return Post_GetConsultasDinamicasUserCore({
      Clase: "FATGQueryHelper", NombreConsulta: "getParamterosCliente",
      Pagina: null,
      RecordsPorPagina: null
    } ,params);
  }
  
  export function CerrarJornadaControaldor(Id:any|null) {
    var params: { [id: string]: string | null; } = {};
    params['Id'] = Id;
    return Post_GetConsultasDinamicasUserCore({
      Clase: "FATGQueryHelper", NombreConsulta: "CerrarJornada",
      Pagina: null,
      RecordsPorPagina: null
    } ,params);
  }
  

  