import {  useState } from "react"
import { DatePicker } from "rsuite";
import { Help, PunchClock } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import moment from "moment";
import { PageTitle } from "../../../../../_start/layout/core";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../_start/helpers/components/HtlmTooltip";
import { ECO_Tooltips} from "../data/datausuarios";
import {  Tab, Tabs } from "react-bootstrap-v5";
import { GetDataServicios, ServiciosProvider } from "../core/serviciosProvider";
import { TabErrores } from "./Servicios/PeticionTabErrores";
import { TabPeticiones } from "./Servicios/PeticionTabContador";
export default function ECO_PeticionesServicios() {  
    const [Fecha, setFecha] = useState<any>(moment().toDate());
  
    return (<>
        <ServiciosProvider>
            <GetDataServicios>{Fecha}</GetDataServicios>
            <PageTitle>Historial Peticiones </PageTitle> 
                <div className="card card-rounded shadow mt-2" style={{ width: '100%' }}  >
                    <Stack className="border shadow-sm mt-10">
                        <div className="mx-auto">
                            <div className="ms-3 text-center">
                                <h5 className="mb-0">Historial de Peticiones<HtmlTooltipStyled arrow
                                    title={HtmlContentTitle(ECO_Tooltips["Historial"].title, ECO_Tooltips["Historial"].content)
                                    } >
                                    <IconButton size="small"><Help /></IconButton>
                                </HtmlTooltipStyled></h5>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start m-5">
                            <Stack >
                                <h4 className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Filtros: </h4>
                                <div className="d-flex justify-content-start"  >
                                    <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Mes: </label>
                                    <DatePicker
                                        format="MMM yyyy" caretAs={PunchClock}
                                        value={Fecha}
                                        onChange={(value, e) => {
                                            setFecha(value);
                                        }}
                                    />
                                </div>
                            </Stack>
                        </div>
                    </Stack>

                </div>
                <div className="d-flex flex-column mt-5">
                <Tabs defaultActiveKey="TabPeticiones" className="mb-3  rounded " >
                    <Tab eventKey="TabPeticiones" title={'Consolidado Peticiones'} className="mx-1">
                        <TabPeticiones />
                    </Tab>                
                    <Tab eventKey="TabErrores" title={'Error Peticiones'} className="mx-1">
                        <TabErrores />
                    </Tab>       
                </Tabs>
                </div>        
        </ServiciosProvider>
    </>
    )
}