import React, { useState } from 'react';
import { SetLogOpcion } from './AsideData';
import { Link, useLocation } from 'react-router-dom';
import { checkIsActive } from '../../../helpers';
import { Button } from 'react-bootstrap-v5';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';



const TreeNodeAside: React.FC<{ node: any }> = ({ node }) => {
    const [expanded, setExpanded] = useState(false);
    const { pathname } = useLocation();
    const handleToggle = () => {
        setExpanded(!expanded);
    };
    const hijos: any[] = node.opciones ?? [];
    return (
        <li className='' style={{whiteSpace : 'nowrap'}}>

            {hijos.length > 0 ? (<Button className="flex btn btn-sm bg-transparent text-syscaf-amarillo " onClick={handleToggle}>
                {expanded ? (<ArrowDropDown  style={{ color: 'white' }} />) : (<ArrowRight style={{ color: 'white' }} />)}
                <span className='fs-5' style={{whiteSpace : 'nowrap'}}> {node.NombreOpcion}</span></Button>) : (
                <div key={`link-${node.OpcionId}-${node.NombreOpcion}`} 
                className={`mx-6 mb-2 ${checkIsActive(pathname, node.Controlador) ? "border" : ""} rounded`} >
                    <Link
                        className={`text-white`} to={node.Controlador}
                        onClick={() => {  // enviamos al servidor 
                            SetLogOpcion(node.NombreOpcion, node.OpcionId);
                        }}
                    >
                        <i className={` mx-1 fs-3  ${node.Logo}`}></i>
                        <span className='menu-title fs-7' style={{whiteSpace : 'nowrap'}}> {node.NombreOpcion}</span>
                    </Link>
                </div>
            )}
            {hijos.length > 0 && expanded && (
                <ul className='mx-1' style={{ listStyleType: 'none', paddingLeft: 10 }}  >
                    {hijos?.map((childNode: any)  =>{
                         let toUrl = childNode.Controlador;
                       
                         if (childNode.ParametrosAdicionales) {                         
                           try {
                             const pa: any = JSON.parse(childNode.ParametrosAdicionales);
                             const url: string = pa.url ? btoa(pa.url) : '';
                             toUrl = toUrl + `/${childNode.NombreOpcion}/${url}`;
                           } catch (e) {
                   
                           }
                         }
                         childNode.Controlador = toUrl;
                        return ( <TreeNodeAside key={childNode.OpcionId} node={childNode} />)
                    }


                       
                    )}
                </ul>
            )}
        </li>
    );
};

const TreeView: React.FC<{ data: any }> = ({ data }) => {  
    return (
        <div className='rounded   text-syscaf-amarillo'>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {data.map((node: any) => (
                    <TreeNodeAside key={node.OpcionId} node={node} />
                ))}
            </ul>
        </div>
    );
};

export default TreeView;
