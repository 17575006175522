import { BaseIndicador } from "./components/Indicadores_Pc";
import { PageTitle } from "../../../../_start/layout/core";
import { FAG_PanelCentral } from "./components/panelCentral";
import { DataVehiculoOperando, FatigueProvider } from "./core/provider";
import { useEffect,  useState } from "react";
import { GetClientesFatiga } from "./data/dashBoardData";
import { ClientesFatiga } from "./models/EventosActivos";
import { Button, Collapse, Form } from "react-bootstrap-v5";
import { NotificacionSyscaf } from "../../../../_start/helpers/components/NotificacionSonora";
import { toaster } from "rsuite";
import { IndicadorAlertasCriticas } from "./components/Indicadores/IndicadoresPanelCentral/IndicadorAlertasCriticas";
import { IndicadorGestion } from "./components/Indicadores/IndicadoresPanelCentral/IndicadorGestion";
import { IndicadorRiesgo } from "./components/Indicadores/IndicadoresPanelCentral/IndicadorRiesgo";
import { IndicadorOperando } from "./components/Indicadores/IndicadoresPanelCentral/IndicadorOperando";

export default function FatigueDashboard() {


    const [lstClientes, setLstClientes] = useState<ClientesFatiga[]>([]);
    const [clienteSeleccionado, setclienteSeleccionado] = useState<ClientesFatiga>();
    const [textIndicador, settextIndicador] = useState("Mostrar")
    const [isVisible, initHs] = useState(false)

    const invokeCollapse = () => {
        settextIndicador(!isVisible ? "Ocultar" : "Mostrar")
        return initHs(!isVisible)
    }
    let arrayTotal: [] = [];
    let arrayTotalSinGestionar: any[] = [];

    arrayTotalSinGestionar = arrayTotal.filter((m) => {
        return (m["EsGestionado"] != 1);
    });

    useEffect(() => {
        GetClientesFatiga().then(
            (response) => {
                setLstClientes(response.data);
            }
        ).catch((error) => {
            toaster.push(NotificacionSyscaf({
                includeSonido: true, duration: 3500, closable: true,
                type: "error", content: "Error al consultar clientes, no se puede desplegar informacion", header: "Consultar Clientes"
            }), {});

        })
    }, []);



    function CargaListadoClientes() {
        return (
            <Form.Select className="p-2 mb-2 ms-2 mt-2" style={{ width: 300 }} onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let cliente = lstClientes.filter((value, index) => {
                    return value.ClienteIdS === Number.parseInt(e.currentTarget.value)
                })
                if (cliente.length > 0) {
                    setclienteSeleccionado(cliente[0])

                }
            }} value={clienteSeleccionado?.ClienteIdS} >
                <option>Seleccione</option>
                {
                    lstClientes?.map((element) => {
                        return (<option key={`listadocliente_${element.ClienteIdS}`} value={element.ClienteIdS}>{element.clienteNombre}</option>)
                    })
                }
            </Form.Select>
        );
    }


    return (

        <>
            {/**  FatigueProvider proveedor de datos a traves de la aplicacion
             * nos garantiza que todos los datos que se obtengan sus hijos tengan acceso a consultarlo sin necesidad de pasarlo
             * con parametros o sesiones
            */}


            <FatigueProvider>

                <PageTitle >Torre de Control </PageTitle>
                <DataVehiculoOperando>{clienteSeleccionado?.ClienteIdS}</DataVehiculoOperando>
                {/* begin::Row */}
                <div className="container-fluid card card-rounded bg-transparent mt-1" style={{ width: '100%' }}   >

                    <div className="bg-secondary d-flex  rounded shadow">
                        <label className="p-2 form-label mt-4 mx-4">Selección Cliente:</label>
                        <CargaListadoClientes />

                        <Button variant="success" className=" p-2 mb-2 ms-2 mt-2" onClick={invokeCollapse}>
                            {`${textIndicador} Indicadores`}
                        </Button>
                    </div>
                    <Collapse in={isVisible}>
                        <div id="collapsePanel" className="row g-0 g-xl-5 g-xxl-8 rounded border  mt-1 mb-2 shadow-sm bg-secondary text-primary">
                            <div className="col-xl-3">
                                <BaseIndicador className={"card-stretch mb-1 mb-xxl-2"} pathIcon='' >
                                    {/*Contenido que quiero mostar dentro del indicador*/}

                                    <IndicadorOperando className={""} nameChart="estadoflota" titulo="Estado Flota" />
                                </BaseIndicador>
                            </div>
                            <div className="col-xl-3">
                                <BaseIndicador className={"card-stretch mb-1 mb-xxl-2"}   >
                                    {/*Contenido que quiero mostar dentro del indicador*/}
                                    <IndicadorRiesgo className={""} nameChart="Operando_Riesgo" tipoData={2} titulo={"Categorización por Riesgo"} />
                                </BaseIndicador>
                            </div>
                            <div className="col-xl-3">
                                <BaseIndicador className={"card-stretch mb-1 mb-xxl-2"} titulo={""} subtitulo={""}  >

                                    {/*Contenido que quiero mostar dentro del indicador*/}
                                    <IndicadorAlertasCriticas className={""} nameChart="Operando_Alertas" tipoData={3} titulo={"Categorización Alertas Críticas"} />
                                </BaseIndicador>
                            </div>
                            <div className="col-xl-3">
                                <BaseIndicador className={"card-stretch mb-1 mb-xxl-2"} titulo={""} subtitulo={""}  >
                                    {/*Contenido que quiero mostar dentro del indicador*/}
                                    <IndicadorGestion className={""} nameChart="gestion_alertas_criticas" titulo={"Gestión Alertas Críticas"} />
                                </BaseIndicador>
                            </div>
                        </div>
                    </Collapse>
                    <FAG_PanelCentral Cliente={clienteSeleccionado} className=""></FAG_PanelCentral>

                </div>

                {/* end::Row */}



            </FatigueProvider>
        </>
    )
}