import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ECO_camposDetallado } from "../../data/datapeticiones";
import { useDataServicios } from "../../core/serviciosProvider";
import { useEffect, useState } from "react";


export const TabErrores: React.FC = () => {
    const { loader, lstPeticionesMalas } = useDataServicios();

    return (<>
  {(!loader) ? (
        <MaterialReactTable
            localization={MRT_Localization_ES}
            columns={ECO_camposDetallado}
            data={lstPeticionesMalas}
            rowCount={lstPeticionesMalas.length}
            enableColumnFilters={false}
            enableColumnOrdering
            enableColumnDragging={false}
            //enablePagination={false}
            enableStickyHeader
            enableDensityToggle={false}

            //enableRowVirtualization
            defaultColumn={{
                minSize: 40, //allow columns to get smaller than default
                maxSize: 200, //allow columns to get larger than default
                size: 150,
            }}
            muiTableContainerProps={{
                sx: { maxHeight: '500px' }, //give the table a max height
            }}
          
            initialState={{
                pagination: {
                    pageIndex: 0,
                    pageSize: 100, //customize the default page size
                },
                
                showProgressBars :loader
            }}

        />) : (<span className="fs-3 fw-bold"> Cargando datos.....</span>)}</>)

}