import React, { useState } from "react";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Button, Modal } from "react-bootstrap-v5"
import moment from "moment";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Add, Cancel, Delete, Edit, Save } from "@mui/icons-material";
import { DateRangePicker, useToaster } from "rsuite";
import { confirmarDialogConCancelAccion } from "../../../../../../_start/helpers/components/ConfirmDialog";
import { CHL_DetalleViajesCampos, DefaultRowViaje, GestionViajes, TipoGuardado } from "../../data/detalledata";
import { CHL_SelectDriver, CHL_SelectVehiculo } from "../../data/reportedata";
import "./DetalleViajesStyle.css"
import { formatableJson_HHMMss, msToTimeSeconds } from "../../../../../../_start/helpers/Helper";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { MensajeError, MensajeSuccess, message } from "../../../../../../_start/helpers/components/Toaster";
import BlockUi from "@availity/block-ui";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";
import { UserModelSyscaf } from "../../../../auth/models/UserModel";

type Props = {
    show: boolean;
    handleClose: any;
    title?: string;
    seleccionado: any;
    data: any[],
    isNew: boolean,
    RecargarInfo: any
};

export const DetalleViajes: React.FC<Props> = ({ show, handleClose, title, data, seleccionado, isNew, RecargarInfo }) => {
    const toaster = useToaster();

    const isAuthorized = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const model = (isAuthorized as UserModelSyscaf);

    const { allowedMaxDays, allowedRange, combine } = DateRangePicker;
    const clienteids = seleccionado.clienteids;
    const titulos = { nuevo: "Nuevo Registro", modificar: "Editar Registro" }

    const rowCount: number = data.length;
    const [isRefetching, setIsRefetching] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [titulo, setTitulo] = useState<string>(isNew ? titulos.nuevo : "");
    const [errorData, seterrorData] = useState<string>("");
    const [editVisible, setEditVisible] = useState<boolean>(isNew);
    const [rowSel, setRowSel] = useState<any>({
        ...DefaultRowViaje
    });
    const [localData, setLocalData] = useState<any[]>(isNew ? [] : data.map((m) => {
        m.Operador = seleccionado?.Operador;
        return m;
    }));

    const clearData = (isEditVisible: boolean, titulo: string) => {
        setRowSel({
            ...DefaultRowViaje, Operador: seleccionado?.Operador, DriverId: seleccionado?.driverId,
            tripStart: moment(seleccionado?.Fecha).format(formatableJson_HHMMss),
            tripEnd: moment(seleccionado?.Fecha).format(formatableJson_HHMMss)
        });
        setEditVisible(isEditVisible);
        setTitulo(titulo);
    }

    const ValidarDatos = (Tipo: string) => {
        if (TipoGuardado['3'] != Tipo) { // solo se valida cuando se adiciona o se edita, la eliminación no tiene validación
            let mensaje: string[] = [];
            if (isNew && rowSel.DriverId === 0) {
                mensaje.push("Debe seleccionar un Conductor!.")

            }
            if (rowSel.AssetId === 0) {
                mensaje.push("Debe seleccionar un Vehículo!.")

            }
            if (rowSel.Duracion <= 0) {
                mensaje.push("La duración del viaje debe ser mayor a 0!.")

            }
            if (rowSel.Duracion / 3600 > 18) {
                mensaje.push("La duración del viaje NO debe ser mayor a 18 Horas!.")

            }
            if (mensaje.length > 0) {
                toaster.push(MensajeError(titulo, mensaje.join(' ')), {
                    placement: 'topCenter'
                });
                return false;
            }
        }
        return true;
    }

    const DataToTable = (Tipo: string, Id: any = {}) => {
        if (ValidarDatos(Tipo)) { // si es ingreso o es edición valida los campos necesarios  si falla para la acción
            let localDataAdd: any[] = [...localData]; // copiamos el array
            let preDataEnviar = { ...rowSel, Tipo }
            if (Tipo === TipoGuardado['2']) { // si es tipo 2 debemos setear un id temporal incremental para la tabla en el modal 
                const ultId = Math.max(...localDataAdd.filter(f => f.Tipo === TipoGuardado['2']).map(o => o.TripId));
                // cogemos el ultimo id para manejar uno local de todos los que son adicionados, puede tomar de otro array
                let idlocal = Number.isInteger(ultId) ? ultId : 0;
                preDataEnviar = { ...rowSel, TripId: (1 + idlocal) } // con el fin de luego poder
            }
            // seteamos los datos 

            GestionViajes(clienteids, Tipo, JSON.stringify(TipoGuardado['3'] === Tipo ? Id : preDataEnviar), model.Nombres).then((response) => {
                setLoading(true);
                if (response.status == 200) {
                    toaster.push(MensajeSuccess(titulo, "Guardado exitosamente"), {
                        placement: 'topCenter'
                    });
                    switch (Tipo) {
                        case TipoGuardado['1']:  // modificamos el array
                            localDataAdd = localDataAdd.filter(f => f.TripId !== preDataEnviar.TripId);
                            localDataAdd.push(preDataEnviar);
                            break;
                        case TipoGuardado['2']:  //adicionamos
                            const Result = response.data[0].TripId
                            localDataAdd.push({ ...preDataEnviar, TripId: Result });
                            break;
                        case TipoGuardado['3']: // eliminamos                            
                            localDataAdd = localDataAdd.filter(f => f.TripId !== Id.TripId);
                            break;
                    }
                    setLocalData(localDataAdd);
                    clearData(false, "");
                    RecargarInfo();// actualizamos el background para actulizar la información recién modificada

                } else {
                    toaster.push(MensajeError(titulo, "Error al guardar información, favor comuníquese con su Administrador"), {
                        placement: 'topCenter'
                    });
                }

            }).catch((e) => {
                toaster.push(MensajeError(titulo, "Error en la peticion"), {
                    placement: 'topCenter'
                });
            }).finally(() => {
                setLoading(false);
            })



        }

    }

    return (
        <>
            <Modal size="lg"
                show={show}
                onHide={() => {
                    handleClose(false);
                    setRowSel(DefaultRowViaje);
                    setEditVisible(false);
                }}
            >
                <Modal.Header closeButton >
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BlockUi blocking={isLoading} tag={"div"} message={`procesando ${titulo}`}>
                        <div >
                            <Tooltip arrow placement="left" title="Editar">
                                <Button className="bg-primary " hidden={(titulo === titulos.nuevo)}
                                    onClick={() => {
                                        clearData(true,titulos.nuevo);
                                    }}
                                >
                                    <span className="text-white fw-bold mx-2">Nuevo</span> <Add color="primary" />
                                </Button>
                            </Tooltip>

                        </div>
                        <div hidden={!editVisible} className="card card-rounded shadow mt-2" style={{ width: '100%' }}  >

                            <div className=" mb-2 mt-1 d-flex justify-content-center">
                                <div className="ms-3 text-center">
                                    <h5 className="mb-0">{titulo}</h5>
                                    <span className="text-red fs-5"> {errorData}</span>
                                </div>
                            </div>
                            <div className=" mb-2 mt-1 d-flex justify-content-between">

                                <div className="mt-1">
                                    <div>
                                        <label className="mx-4 fs-6 fw-bolder">Rango Fechas: </label>
                                    </div>
                                    {(combine && allowedMaxDays && allowedRange) && (
                                        <DateRangePicker ranges={[]} className="mx-4 fs-6 text-muted DateRangePicker" format="dd/MM/yyyy HH:mm:ss"
                                            disabledDate={combine(allowedMaxDays(2), allowedRange(
                                                moment().add(-200, 'days').startOf('day').toDate(), moment().startOf('day').toDate()
                                            ))}
                                            value={[moment(rowSel.tripStart).toDate(), moment(rowSel.tripEnd).toDate()]}
                                            onChange={(value: DateRange | null) => {
                                                if (value != null) {
                                                    let fecha = moment(value[0]);
                                                    let fechafin = moment(value[1]);
                                                    var ms = Math.abs(fechafin.diff(fecha));     // calculamos la diferencia     
                                                    let mensaje: string[] = [];
                                                    if (ms <= 0) {                                                        
                                                        mensaje.push("La duración del viaje debe ser mayor a 0!.")
                                        
                                                    }
                                                    if (ms / 3600000> 18) {
                                                        mensaje.push("La duración del viaje NO debe ser mayor a 18 Horas!.")                                        
                                                    }
                                                    if (mensaje.length > 0) {
                                                        toaster.push(MensajeError(titulo, mensaje.join(' ')), {
                                                            placement: 'topCenter'
                                                        });
                                                        return false;
                                                    }else {
                                                        setRowSel({
                                                            ...rowSel, tripStart: fecha.format(formatableJson_HHMMss),
                                                            tripEnd: fechafin.format(formatableJson_HHMMss)
                                                            , Duracion: ms / 1000
                                                        });
                                                    }
                                                   
                                                }
                                            }}
                                        />)}
                                </div>


                                <div className="mt-1 mx-4 ">
                                    <div>
                                        <label className="mx-4 fs-6 fw-bolder">Duración: </label>
                                    </div>
                                    <span className="mx-4 fs-5 text-muted d-flex justify-content-center" > {
                                        msToTimeSeconds(rowSel.Duracion)} </span>
                                </div>
                            </div>
                            <div className=" mb-2 mt-1 d-flex justify-content-between">
                                <div className="mt-1">
                                    <div>
                                        <label className="mx-4 fs-6 fw-bolder">Placa: </label>
                                    </div>
                                    <div className="mx-4 fs-6 fw-bolder">
                                        {(rowSel.TripId != -1 &&  rowSel?.Placa != "" && !isNew) ? (
                                            <span className="mx-4 fs-5 text-muted"> {rowSel?.Placa}</span>)
                                            : (
                                                CHL_SelectVehiculo(seleccionado.lstAssets, rowSel, setRowSel)
                                            )}
                                    </div>

                                </div>
                                <div className="mt-1">
                                    <div>
                                        <label className="mx-4 fs-6 fw-bolder">Conductor: </label>
                                    </div>
                                    {(isNew) ? CHL_SelectDriver(seleccionado.lstConductores, rowSel, setRowSel) :
                                        (<span className="mx-4 fs-5 text-muted"> {seleccionado?.Operador}</span>)}
                                </div>
                            </div>

                            <div className=" mb-2 mt-1 d-flex justify-content-end">
                                <Tooltip arrow placement="left" title="Guardar Datos">
                                    <Button className="bg-primary "
                                        onClick={() => {
                                            DataToTable(TipoGuardado[(titulo === titulos.nuevo) ? '2' : '1']);
                                        }}
                                    >
                                        <Save style={{ color: "white" }} />
                                    </Button>
                                </Tooltip>
                                <Tooltip className="mx-4" arrow placement="left" title="Limpiar Registros">
                                    <Button className="bg-secondary "
                                        onClick={() => {
                                            clearData(false, "");
                                        }}
                                    >
                                        <Cancel className="" color="error" />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>


                        <MaterialReactTable
                            enableEditing
                            renderRowActions={({ row, table }) => (

                                <>
                                    <Box sx={{ display: 'flex', gap: '1rem' }}>

                                        <Tooltip arrow placement="left" title="Editar">
                                            <IconButton
                                                onClick={() => {
                                                    // seteamos el registro y hacemos visible los datos
                                                    setRowSel({ ...row.original, Operador: seleccionado?.Operador });
                                                    setEditVisible(true);
                                                    setTitulo(titulos.modificar);
                                                }}
                                            >
                                                <Edit color="success" />
                                            </IconButton>
                                        </Tooltip>


                                        <Tooltip arrow placement="left" title="Eliminar">
                                            <IconButton
                                                onClick={() => {
                                                    confirmarDialogConCancelAccion
                                                        (() => {

                                                            DataToTable(TipoGuardado['3'], row.original);

                                                        }, "Está seguro que desea eliminar este registro?", "Si", "No", null)
                                                }}
                                            >
                                                <Delete color="error" />
                                            </IconButton>
                                        </Tooltip>

                                    </Box>
                                </>
                            )
                            }
                            localization={MRT_Localization_ES}
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 120,
                                },
                            }}
                            columns={CHL_DetalleViajesCampos}
                            data={localData}
                            enableTopToolbar={false}
                            enableColumnOrdering
                            rowCount={rowCount}
                            state={{
                                showProgressBars: isRefetching
                            }}

                        />
                    </BlockUi>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="secondary" onClick={() => { handleClose(false) }}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}
