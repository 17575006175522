import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";

import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap-v5";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Message, Map, List } from "@mui/icons-material";
import { ColumnFiltersState } from "@tanstack/react-table";
import moment from "moment";
import { FormatoColombiaDDMMYYYHHmm, FormatoColombiaDDMMYYYHHmmss } from "../../../../../_start/helpers/Constants";
import { DescargarExceFatiga } from "../../../../../_start/helpers/components/DescargarExcel";
import BlockUi from "@availity/block-ui";
import { MapTab2 } from "./Map";
import { useDataFatigueHistorical } from "../core/providerHistorical";
import { columnsDetalleEventos, columnsPrincipal } from "../data/dashBoardData";

type Props = {
  className: string;
  innerPadding?: string;
};

export const AlertasHistorical: React.FC<Props> = ({ className, innerPadding = "" }) => {

  const { alertasHistorical, loader2, setDataDetalladoFiltrado2, setFiltrado2, setloader2 } = useDataFatigueHistorical();

  const [dataAlertas, setDataAlertas] = useState([]);

  //table state
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  // modals state
  const [observaciones, setobservaciones] = useState("");
  const [detalleEventos, setdetalleEventos] = useState("");
  const [contactos, setcontactos] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [rowCount2, setRowCount2] = useState(0);
  const [rowCount3, setRowCount3] = useState(0);

  const [Placa, setPlaca] = useState("");
  const [conductor, setconductor] = useState("");
  const [Alerta, setAlerta] = useState("");
  const [fechaEvento, setfechaEvento] = useState("");
  const [totalEventos, settotalEventos] = useState("");
  const [title, settitle] = useState("");

  //data modals
  const [Data, setData] = useState<any[]>([]);
  const [DataDetalleEventos, setDataDetalleEventos] = useState<any[]>([]);
  const [dataContacto, setdataContacto] = useState([]);

  //data excel
  const [dataAlertasObservaciones, setdataAlertasObservaciones] = useState([]);
  const [dataAlertasEventos, setdataAlertasEventos] = useState([]);

  //show modals
  const [show, setShow] = useState(false);
  const [show4, setShow4] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const showModal = () => {
    setShow(true);
  }

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {
    setShow2(false);
  };
  const showModal2 = () => {
    setShow2(true);
  }

  //data principal
  useEffect(() => {


    //Alertas principal
    setDataAlertas(alertasHistorical);
    setRowCount(alertasHistorical.length);

    //DataExcel
    let arrayObservaciones = new Array();
    let arrayEventos = new Array();

    if (alertasHistorical.length != 0) {


      alertasHistorical.forEach((element: any) => {

        let objprincipal = {
          Criticidad: element["Criticidad"],
          Cliente: element["Cliente"],
          AlertaId: element["AlertaId"],
          TipoAlerta: element["TipoAlerta"],
          vehiculo: element["vehiculo"],
          conductor: element["conductor"],
          EventDateTime: element["EventDateTime"],
          DetalladoEventos: element["DetalladoEventos"],
          EstadoGestion: element["EstadoGestion"],
          gestor: element["gestor"]
        }

        const detalle = JSON.parse(element["Observaciones"]);
        const EnvioReporte = element["Cabecera"] != null ? JSON.parse(element["Cabecera"]) : null;

        if (detalle == null && EnvioReporte == null) {
          arrayObservaciones.push(objprincipal);
        } else {
          if (EnvioReporte != null)
            objprincipal = { ...objprincipal, ...EnvioReporte };
          if (detalle != null)
            detalle.forEach((element2: any) => {

              arrayObservaciones.push({ ...objprincipal, ...element2 });
            });
        }

      })

      setdataAlertasObservaciones(arrayObservaciones as []);

      alertasHistorical.forEach((element: any) => {

        let objprincipal = { AlertaId: element["AlertaId"] }

        const detalle = JSON.parse(element["DetalladoEventos"]);

        if (detalle == null) {
          arrayEventos.push(objprincipal);
        } else {
          detalle.forEach((element2: any) => {

            arrayEventos.push({ ...objprincipal, ...element2 });
          });
        }

      })

      setdataAlertasEventos(arrayEventos as []);

    }


  }, [alertasHistorical])

  //listado campos tablas

  //Alertas
  const columnasTabla: MRT_ColumnDef<any>[]
    = [
      {
        accessorKey: 'Cliente',
        header: 'Cliente',
        size: 100
      },
      {
        accessorKey: 'TipoAlerta',
        header: 'Alarma',
        size: 100
      },


      {
        accessorKey: 'vehiculo',
        header: 'Vehículo',

        size: 100
      },
      {
        accessorKey: 'conductor',
        header: 'Conductor',
        size: 100
      }, {
        accessorKey: 'EventDateTime',
        header: 'Fecha evento',
        Cell({ cell, column, row, table, }) {

          return (
            <>
              {
                moment(row.original.EventDateTime).format('DD/MM/YYYY HH:mm:ss')
              }
            </>

          )
        },
        size: 80
      }, {
        accessorFn: (row) => parseInt(JSON.parse(row.DetalladoEventos).length),
        id: 'DetalladoEventos',
        header: 'Eventos',
        size: 80
      }, {
        accessorKey: 'EstadoGestion',
        header: 'Estado',
        size: 100,
        Cell({ cell, column, row, table, }) {
          return (<span className={row.original.badge}>{cell.getValue()}</span>)

        }
      }, {
        accessorKey: 'Criticidad',
        header: 'Categoría',
        size: 100
      },
      {
        accessorKey: 'gestor',
        header: 'Analista',
        size: 150,
        Cell({ cell, column, row, table, }) {
          return (cell.getValue() == null) ? <span>Sin Analista</span> : <span>{row.original.gestor}</span>
        }
      }

    ];

  //Observaciones
  let listadoCampos: MRT_ColumnDef<any>[] =

    [
      {
        accessorKey: 'fechagestion',
        header: 'Fecha',
        size: 100,
        Cell({ cell, column, row, table, }) {
          return (moment(cell.getValue() as Date).format(FormatoColombiaDDMMYYYHHmmss))
        }
      },
      {
        accessorKey: 'value',
        header: 'Observaciones',
        size: 100
      },
      {
        accessorKey: 'recursos',
        header: 'Recursos',
        size: 100
      }

    ];

  //Eventos  
  let listadoEventos: MRT_ColumnDef<any>[] =

    [
      {
        accessorKey: 'evento',
        header: 'Evento',
        size: 100
      },
      {
        accessorKey: 'EventDateTime',
        header: 'Fecha',
        size: 100,
        Cell({ cell, column, row, table, }) {
          return (moment(cell.getValue() as Date).format(FormatoColombiaDDMMYYYHHmmss))
        }
      },
      {
        accessorKey: 'Latitud',
        header: 'Latitud',
        size: 100
      },
      {
        accessorKey: 'Longitud',
        header: 'Longitud',
        size: 100
      },
      {
        accessorKey: 'velocidad',
        header: 'Velocidad',
        size: 100
      }

    ];

  //Contactos
  const columnasContacto: MRT_ColumnDef<any>[]
    = [
      {
        accessorKey: 'tipo',
        header: 'Tipo'
      },
      {
        accessorKey: 'nombre',
        header: 'Nombre'
      },
      {
        accessorKey: 'numero',
        header: 'Número'
      },
      {
        accessorKey: 'correo',
        header: 'Email'
      }

    ];



  const fncReporteAlarma = [

    {
      name: "gestor",
      getData: (data: any) => {

        return (data == null) ? 'Sin Analista' : data
      }
    }, {
      name: 'DetalladoEventos',

      getData: (data: any) => {
        return JSON.parse(data).length
      }
    }
  ];

  //modals
  const modalObervaciones = (row: any) => {
    setobservaciones(row.Observaciones);
    setPlaca(row.vehiculo);
    setconductor(row.conductor);
    setAlerta(row.TipoAlerta);
    setdetalleEventos(row.DetalladoEventos);
    setcontactos(row.contactos);
    setfechaEvento(moment(JSON.parse(row.DetalladoEventos).at(-1).EventDateTime as Date).format(FormatoColombiaDDMMYYYHHmm));
    settotalEventos(JSON.parse(row.DetalladoEventos).length)

    showModal();
  }

  const modalDetalleEventos = (row: any) => {
    setPlaca(row.vehiculo);
    setconductor(row.conductor);
    setAlerta(row.TipoAlerta);
    setdetalleEventos(row.DetalladoEventos);
    setfechaEvento(moment(JSON.parse(row.DetalladoEventos).at(-1).EventDateTime as Date).format(FormatoColombiaDDMMYYYHHmm));
    settotalEventos(JSON.parse(row.DetalladoEventos).length)

    showModal2();
  }

  //Función para ir al mapa de marcial
  const IrToMap = (row: any) => {
    setPlaca(row.original.vehiculo);
    setconductor(row.original.conductor);
    setAlerta(row.original.TipoAlerta);
    setfechaEvento(moment(JSON.parse(row.original.DetalladoEventos).at(-1).EventDateTime as Date).format(FormatoColombiaDDMMYYYHHmm));
    settotalEventos(JSON.parse(row.original.DetalladoEventos).length)
    let Data = new Array()
    Data = [...Data, ...JSON.parse(row.original.DetalladoEventos)]
    setDataDetalladoFiltrado2(Data);
    setFiltrado2(true);
    showModals();
  };

  //Funciones para mapa tab
  const showModals = () => {
    settitle('Mapa')
    setShow4(true);
  }

  const handleCloseModals = () => {
    settitle('')
    setShow4(false);
  };

  //pintar modals

  //Obervaciones
  useEffect(() => {


    if (observaciones != "" && observaciones != null) {
      let json = JSON.parse(observaciones);
      setData(json);
      setRowCount(json.length);
      settitle('Observaciones');
    }
    else {
      setData([]);
      setRowCount(0);
    }
  }, [observaciones])

  //Eventos    
  useEffect(() => {

    if (detalleEventos != "" && detalleEventos != null) {
      let json = JSON.parse(detalleEventos);
      setDataDetalleEventos(json);
      setRowCount2(json.length);
    }
    else {
      setDataDetalleEventos([]);
      setRowCount2(0);
    }
  }, [detalleEventos])

  //Contactos
  useEffect(() => {

    if (contactos != "" && contactos != null) {
      let json = JSON.parse(contactos);
      setdataContacto(json);
      setRowCount3(json.length);
    }
    else {
      setdataContacto([]);
      setRowCount3(0);
    }
  }, [contactos])

  return (
    <>
      <BlockUi tag="div" keepInView blocking={loader2 ?? false}  >

        <div className="card-header align-items-center border-0">
          <div className="card-title flex-column">
            <span className="fw-bolder text-dark fs-3 ms-4 me-20">Alertas Históricas</span>
            <button className="m-2 ms-20 btn btn-sm btn-primary" type="button" onClick={() => { DescargarExceFatiga(dataAlertasObservaciones, dataAlertasEventos, columnsPrincipal, columnsDetalleEventos, `Alertas`, fncReporteAlarma) }}>
              <i className="bi-file-earmark-excel"></i> Descargar Alertas
            </button>
          </div>
        </div>

        <MaterialReactTable
        enableColumnResizing
          localization={MRT_Localization_ES}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              size: 140,
              muiTableHeadCellProps: {
                align: 'center'
              }
            },
          }}
          columns={columnasTabla}
          data={dataAlertas}
          muiTableBodyRowProps={({ row }) => ({

            sx: {

              backgroundColor:
                row.original.Criticidad === 'Riesgo alto' ? 'rgba(248, 215, 218, 1)' :
                  row.original.Criticidad === 'Riesgo moderado' ? 'rgba(255, 243, 205, 1)' :
                    row.original.Criticidad === 'Riesgo bajo' ? 'rgba(212, 237, 218, 1)' :
                      'rgba(255, 243, 205, 1)'
            }
          })}
          enableTopToolbar
          enableColumnOrdering
          enableEditing
          enableFilters
          enablePagination={false}
          enableColumnFilters={false}
          enableStickyHeader
          enableStickyFooter
          enableDensityToggle={false}
          enableRowVirtualization
          muiTableContainerProps={{
            sx: { maxHeight: '400px' }, //give the table a max height

          }}
          muiToolbarAlertBannerProps={
            isError
              ? {
                color: 'error',
                children: 'Error al cargar información',
              }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          renderRowActions={({ row, table }) => (

            <Box sx={{ display: 'flex', gap: '1rem' }}>

              {(row.original.EstadoGestion != null || row.original.EstadoGestion == true) ?
                <Tooltip arrow placement="left" title="Detalle Gestión">
                  <IconButton onClick={() => {
                    modalObervaciones(row.original);
                  }}>
                    <Message />
                  </IconButton>
                </Tooltip>
                :
                <></>}
              <Tooltip arrow placement="left" title="Detalle Eventos Alertas">
                <IconButton onClick={() => {
                  modalDetalleEventos(row.original);
                }}>
                  <List />
                </IconButton>
              </Tooltip>
              {/* //Para el mapa  Marcial */}
              <Tooltip arrow placement="top" title="Ver en el mapa">
                <IconButton onClick={(e: any) => {
                  IrToMap(row);
                }} >
                  <Map />
                </IconButton>
              </Tooltip>
            </Box>
          )
          }

          enableExpandAll={false}
          initialState={{ density: 'compact' }}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching
          }}
        />
      </BlockUi>


      {/* modal Onservaciones */}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
              <label className="mx-2 fs-6 fw-bolder">Alerta: </label> <span className="mx-1 fs-5 text-muted">{`${Alerta}`}</span>
            </div>
            <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
              <label className="mx-2 fs-6 fw-bolder">Fecha Ultimo Evento: </label> <span className="mx-2 fs-5 text-muted">{`${fechaEvento}`} </span>
            </div>
            <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
              <label className="mx-2 fs-6 fw-bolder">Cantidad Eventos: </label> <span className="mx-2 fs-5 text-muted">{`${totalEventos}`} </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
              <label className="mx-2 fs-6 fw-bolder">Placa: </label> <span className="mx-2 fs-5 text-muted">{`${Placa}`}</span>
            </div>
            <div className="col-sm-8 col-xl-8 col-md-8 col-lg-8">
              <label className="mx-2 fs-6 fw-bolder">Conductor: </label> <span className="mx-2 fs-5 text-muted">{`${conductor}`}</span>
            </div>
          </div>
        </Modal.Body>
        <Tabs
          defaultActiveKey="Observaciones"
          className="mb-3"
     
        >
          <Tab eventKey="Observaciones" title={`Observaciones`} >
            <Modal.Body>
              <div>
                <MaterialReactTable
                  localization={MRT_Localization_ES}
                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      muiTableHeadCellProps: {
                        align: 'center',
                      },
                      size: 120,
                    },
                  }}
                  columns={listadoCampos}
                  data={Data}
                  // editingMode="modal" //default         
                  enableTopToolbar={false}
                  enableColumnOrdering
                  // enableEditing
                  /* onEditingRowSave={handleSaveRowEdits}
                      onEditingRowCancel={handleCancelRowEdits}*/
                  muiToolbarAlertBannerProps={
                    isError
                      ? {
                        color: 'error',
                        children: 'Error al cargar información',
                      }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setGlobalFilter}
                  rowCount={rowCount}

                  state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching
                  }}
                />
              </div>
            </Modal.Body>
          </Tab>
          <Tab eventKey="evetos" title={`Eventos`}>
            <Modal.Body>
              <MaterialReactTable
                localization={MRT_Localization_ES}
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    muiTableHeadCellProps: {
                      align: 'center'
                    }
                  },
                }}
                columns={listadoEventos}
                data={DataDetalleEventos}
                enableTopToolbar
                enableColumnOrdering
                enableFilters
                enablePagination={false}
                enableColumnFilters={false}
                muiToolbarAlertBannerProps={
                  isError
                    ? {
                      color: 'error',
                      children: 'Error al cargar información',
                    }
                    : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                rowCount={rowCount2}
                initialState={{ density: 'compact' }}
                state={{
                  columnFilters,
                  globalFilter,
                  isLoading,
                  showAlertBanner: isError,
                  showProgressBars: isRefetching
                }}
              />
            </Modal.Body>
          </Tab>
          <Tab eventKey="Contacto" title={`Contactos`}>
            <Modal.Body>
              <MaterialReactTable
                localization={MRT_Localization_ES}
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    muiTableHeadCellProps: {
                      align: 'center'
                    }
                  },
                }}
                columns={columnasContacto}
                data={dataContacto}
                enableTopToolbar
                enableColumnOrdering
                enableFilters
                enablePagination={false}
                enableColumnFilters={false}
                muiToolbarAlertBannerProps={
                  isError
                    ? {
                      color: 'error',
                      children: 'Error al cargar información',
                    }
                    : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                rowCount={rowCount3}
                initialState={{ density: 'compact' }}
                state={{
                  columnFilters,
                  globalFilter,
                  isLoading,
                  showAlertBanner: isError,
                  showProgressBars: isRefetching
                }}
              />
            </Modal.Body>
          </Tab>
        </Tabs>

        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Eventos */}
      <Modal
        show={show2}
        onHide={handleClose2}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Detallado Eventos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
              <label className="mx-2 fs-6 fw-bolder">Alerta: </label> <span className="mx-1 fs-5 text-muted">{`${Alerta}`}</span>
            </div>
            <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
              <label className="mx-2 fs-6 fw-bolder">Fecha Ultimo Evento: </label> <span className="mx-2 fs-5 text-muted">{`${fechaEvento}`} </span>
            </div>
            <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
              <label className="mx-2 fs-6 fw-bolder">Cantidad Eventos: </label> <span className="mx-2 fs-5 text-muted">{`${totalEventos}`} </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
              <label className="mx-2 fs-6 fw-bolder">Placa: </label> <span className="mx-2 fs-5 text-muted">{`${Placa}`}</span>
            </div>
            <div className="col-sm-8 col-xl-8 col-md-8 col-lg-8">
              <label className="mx-2 fs-6 fw-bolder">Conductor: </label> <span className="mx-2 fs-5 text-muted">{`${conductor}`}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Body>
          <div>
            <MaterialReactTable
              localization={MRT_Localization_ES}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 120,
                },
              }}
              columns={listadoEventos}
              data={DataDetalleEventos}

              // editingMode="modal" //default         
              enableTopToolbar={false}
              enableColumnOrdering
              // enableEditing
              /* onEditingRowSave={handleSaveRowEdits}
                  onEditingRowCancel={handleCancelRowEdits}*/
              muiToolbarAlertBannerProps={
                isError
                  ? {
                    color: 'error',
                    children: 'Error al cargar información',
                  }
                  : undefined
              }
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              rowCount={rowCount2}

              state={{
                columnFilters,
                globalFilter,
                isLoading,
                showAlertBanner: isError,
                showProgressBars: isRefetching
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={handleClose2}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Mapa */}
      <Modal
        show={show4}
        onHide={handleCloseModals}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
              <label className="mx-2 fs-6 fw-bolder">Alerta: </label> <span className="mx-1 fs-5 text-muted">{`${Alerta}`}</span>
            </div>
            <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
              <label className="mx-2 fs-6 fw-bolder">Fecha Ultimo Evento: </label> <span className="mx-2 fs-5 text-muted">{`${fechaEvento}`} </span>
            </div>
            <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
              <label className="mx-2 fs-6 fw-bolder">Cantidad Eventos: </label> <span className="mx-2 fs-5 text-muted">{`${totalEventos}`} </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
              <label className="mx-2 fs-6 fw-bolder">Placa: </label> <span className="mx-2 fs-5 text-muted">{`${Placa}`}</span>
            </div>
            <div className="col-sm-8 col-xl-8 col-md-8 col-lg-8">
              <label className="mx-2 fs-6 fw-bolder">Conductor: </label> <span className="mx-2 fs-5 text-muted">{`${conductor}`}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Body>
          {show4 && (<MapTab2 />)}
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={handleCloseModals}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal >


    </>
  )
}