import React, { useEffect, useState } from "react";

import { useDataPreoperacional } from "../../core/provider";
import {  Preoperacional } from "../../models/dataModels";

import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from 'material-react-table/locales/es';

import type {
    ColumnFiltersState,
    PaginationState,
    SortingState,
} from '@tanstack/react-table';
import { Box, IconButton, Tooltip } from "@mui/material";
import { FormatListBulleted, Message, VerifiedUser } from "@mui/icons-material";

import { ModalObservaciones } from "./Observaciones";
import { getEncabezados, setGestor } from "../../data/dataPreoperacional";
import { FechaServidor, formatableJson_HHMMss, formatSimpleJson } from "../../../../../../_start/helpers/Helper";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../_start/helpers/components/ConfirmDialog";

import BlockUi from "@availity/block-ui";
import { listadoCampos } from "../../data/tablaPreoperacional";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";
import { UserModelSyscaf } from "../../../../auth/models/UserModel";
import { ExportarExcel } from "./ExportarExcel";
import { TablaRespuestas } from "./TablaRespuestas";


type Props = {
    clienteid: string
    fecha: any;
    filtro: string
};

export const TablaProperacional: React.FC<Props> = ({ clienteid, fecha, filtro }) => {
    const fechaInicial = moment(fecha).format(formatSimpleJson);
    const fechaFinal = moment(fecha).add(1, 'days').format(formatSimpleJson);
    const { Encabezados, UserId, setEncabezados, Visible } = useDataPreoperacional();
    const [lstVehiculosConPreoperacional, setlstVehiculosConPreoperacional] = useState<Preoperacional[]>([]);
    const [registroSeleccionado, setRegistroSeleccionado] = useState<any>();

    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const isAuthorized = useSelector<RootState>(
        ({ auth }) => auth.user
    );


    const model = (isAuthorized as UserModelSyscaf);
    const confPreoperacionales = (JSON.parse(model.preoperacional) as any[]).filter(f => f.ClienteId === clienteid);
    const TipoCliente: string = confPreoperacionales[0].Tipo;
    const Clienteids : string =confPreoperacionales[0].ClienteIds;
   
    const handleClose = () => {
        setShow(false);
    };
    const showModal = () => {
        setShow(true);
    }

    const handleClose2 = () => {
        setShow2(false);
    };
    const showModa2 = () => {
        setShow2(true);
    }



    useEffect(() => {
        if (filtro === "2") {
            setlstVehiculosConPreoperacional(Encabezados as Preoperacional[]);
            setRowCount((Encabezados as Preoperacional[]).length);
        }
        else if (filtro === "0") {
            let encabezados = (Encabezados as Preoperacional[]).filter(est => est.Estado.toString() === filtro);
            setlstVehiculosConPreoperacional(encabezados as Preoperacional[]);
            setRowCount(encabezados.length);
        }
        else {
            let encabezados = (Encabezados as Preoperacional[]).filter(est => est.Estado.toString() === filtro);
            setlstVehiculosConPreoperacional(encabezados as Preoperacional[]);
            setRowCount(encabezados.length);
        }


    }, [Encabezados, filtro])

    const modalrespuetas = (row: any) => {
        setRegistroSeleccionado(row);
        showModal()
    }

    const modalObervaciones = (row: any) => {
        setRegistroSeleccionado(row);
        showModa2()
    }

    const setGestorPreoperacional = (EncabezadoId: number, isHistorical : any) => {

        let GestorObervaciones: any = {};
        GestorObervaciones = {
            fecha: moment(FechaServidor()).format(formatableJson_HHMMss),
            value: "Gestor Asignado",
            notificar: "false",
            EsCerrado: null
        };


        confirmarDialog(() => {

            setGestor(UserId as string, '[' + JSON.stringify(GestorObervaciones) + ']',
             false, EncabezadoId, Clienteids, isHistorical).then(() => {
                getEncabezados(clienteid, fechaInicial, fechaFinal, null).then(
                    (response) => {
                        const lstPreoperacional = response.data as Preoperacional[];
                        setEncabezados(lstPreoperacional);                        // disparamos el modal enseguida
                        successDialog("Operación Éxitosa.", "");
                        modalObervaciones(lstPreoperacional.find((f => f.EncabezadoId === EncabezadoId)));
                    });

            }).catch(() => {
                errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
            });
        }, `¿Desea usted gestionar este preoperacional?`, "Sí");
    }

    if ((Encabezados as Preoperacional[]).length > 0) {
        return (
            <BlockUi tag="span" className="bg-primary" keepInView blocking={(Visible === undefined ? true : Visible)}>
                <>
                    <ExportarExcel NombreArchivo="ReporteVehiculosPreoperacional" tipoDescarga={0}
                     lstPreoperacional={lstVehiculosConPreoperacional} FechaInicial={fecha} 
                     FechaFinal={fecha} ClienteId={clienteid} />

                     
                    <div className="mt-5">
                        <MaterialReactTable enableColumnResizing
                            localization={MRT_Localization_ES}
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 120,
                                },
                            }}
                            columns={listadoCampos(TipoCliente)}
                            data={lstVehiculosConPreoperacional}
                          
                            enableTopToolbar={false}
                            enableColumnOrdering
                            enableEditing
                            
                            onColumnFiltersChange={setColumnFilters}
                            onGlobalFilterChange={setGlobalFilter}
                            onPaginationChange={setPagination}
                            onSortingChange={setSorting}
                            rowCount={rowCount}
                            enablePagination={false}
                            enableStickyHeader
                            enableDensityToggle={false}
                            enableRowVirtualization
                            muiTableContainerProps={{
                                sx: { maxHeight: '600px' }, //give the table a max height
                            }}
                            state={{
                                columnFilters,
                                globalFilter,
                               
                                pagination,
                                sorting,
                            }}
                            renderRowActions={({ row, table }) => (

                                <>
                                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                                        {(row.original?.EsGestionado != null) ?
                                            <Tooltip arrow placement="left" title="Obervaciones">
                                                <IconButton
                                                    onClick={() => {

                                                        modalObervaciones(row.original);
                                                    }}
                                                >
                                                    <Message />
                                                </IconButton>
                                            </Tooltip> :
                                            <></>

                                        }



                                        <Tooltip arrow placement="left" title="Respuestas">
                                            <IconButton
                                                onClick={() => {
                                                    modalrespuetas(row.original);
                                                }}
                                            >
                                                <FormatListBulleted />
                                            </IconButton>
                                        </Tooltip>
                                        {(row.original.Estado === 1 && row.original?.EsGestionado == null) ?

                                            <Tooltip arrow placement="left" title="Gestionar">
                                                <IconButton
                                                    onClick={() => {
                                                        setGestorPreoperacional(row.original.EncabezadoId, row.original.isHistorical);
                                                    }}
                                                >
                                                    <VerifiedUser />
                                                </IconButton>
                                            </Tooltip>
                                            : <></>
                                        }
                                    </Box>
                                </>
                            )
                            }
                        />
                    </div>
                    {(show) && (<TablaRespuestas show={show} handleClose={handleClose} title={"Respuestas"} row={registroSeleccionado} />)}
                    {(show2) && (<ModalObservaciones show={show2} handleClose={handleClose2} title={"Observaciones"} row={registroSeleccionado}
                        clienteid={clienteid} fecha={fecha} />)}

                </>
            </BlockUi>


        )
    }
    return (
        <>
            <br />
            <div>
                <h6 style={{ fontWeight: 'bold', textAlign: 'center' }}>No hay vehículos con preoperacional</h6>
            </div>

        </>

    )

}