import { Button, FormLabel, Modal } from "react-bootstrap-v5";
import { EncabezadoHtml } from "../Encabezado";
import { ConsecutivoVisual, EliminarArchivoServicio, GetCabeceraOP, GetDatosRequerimientos, GetObservaciones, GetObservacionesCambioEstado, GuardarArchivoValidacion,  SetRequerimiento } from "../../../../data/Requerimientos"
import { ConsultasIniciales, useDataRequerimientos } from "../../../../core/RequerimientosCore";
import { useState } from "react";
import { DeserializarJSon, formatableJson_HHMMss, formatSimpleJson } from "../../../../../../../../_start/helpers/Helper";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../setup";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../../_start/helpers/components/ConfirmDialog";
import { AxiosError, AxiosResponse } from "axios";
import { toaster } from "rsuite"
import { UserModelSyscaf } from "../../../../../../auth/models/UserModel";
import { MensajeError } from "../../../../../../../../_start/helpers/components/Toaster";
type PropsModaladjunto = {
    showedit: any,
    setshowedit: any
}

export const Adjunto: React.FC<PropsModaladjunto> = ({
    showedit,
    setshowedit
}) => {
    const { UserActual, lstEstadosReq, ReqSeleccionado,
        setloader, setlstRequerimientos, lstTecnicos } = useDataRequerimientos();
    const [EstadoRequerimientosSeleccionado, setEstadoRequerimientosSeleccionado] = useState<any>({ label: "Seleccione", valor: "0" });
    const [FechaOp, SetFechaOp] = useState<Date>();
    const [TecnicoAsignado, setTecnicoAsignado] = useState<any>();
    const [Ruta, setRuta] = useState<string>("");
    const [Archivo, setArchivo] = useState<File | null>(null);

    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;

    const Guardar = () => {
        const Cabecera = DeserializarJSon(ReqSeleccionado.original.Cabecera);
        // setCabecera(_Cabecera);
        let _obs = DeserializarJSon(ReqSeleccionado.original.Observaciones);

        const EstadoActual = DeserializarJSon(ReqSeleccionado.original.Estado);
        let _Cabecera = { ...Cabecera }
        // obtenemos la estructura a guardar de los requerimientos

      
         GetDatosRequerimientos(ReqSeleccionado.original.Id, _Cabecera, _obs, EstadoRequerimientosSeleccionado);
        confirmarDialog(() => {
            setloader(true)
            GuardarArchivoValidacion((Archivo != null ? Archivo.name : ""), "postventa", `$${ReqSeleccionado.original.Consecutivo}`, (Archivo != null ? Archivo : {})).then(
                (response: AxiosResponse<any>) => {
                    if (response.data == "Ok") {
                        errorDialog("Tipo de archivo invalido o ya se encuentra", "");
                    } else {
                        GetObservaciones(_obs, response.data, EstadoActual, vUser, false, true);
                        let Campos = GetDatosRequerimientos(ReqSeleccionado.original.Id, Cabecera, _obs, EstadoActual);
                        SetRequerimiento(Campos).then((response: AxiosResponse<any>) => {
                            if (response.statusText == "OK") {
                                successDialog("Archivo subido exitosamente", "");
                                ConsultasIniciales(setloader, vUser, setlstRequerimientos, toaster); //ACTUALIZAR DE FORMA LOCAL Y NO VOLVER A LLAMAR
                                setloader(false);
                            }
                        }).catch((error: AxiosError) => {
                            toaster.push(MensajeError('Guardar Requerimiento', error.message), { placement: 'topCenter' })
                        }).finally(() => { setshowedit(false); });
                        setRuta(String(response.data));
                    }
                }
            ).catch((error: AxiosError<any>) => {
                errorDialog("Tipo de archivo invalido", "");
            })
        }, `Esta seguro que desea guardar el archivo`, 'Guardar');
    }
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != null) {
            console.log(e.target.files[0]);
            setArchivo(e.target.files[0]);
            
        }
    };
    
    const EliminarArchivo = (Archivo:any) =>{
        
        confirmarDialog(() => {
            
            EliminarArchivoServicio(Archivo.name,"postventa",`$${ReqSeleccionado.original.Consecutivo}`).then((response:AxiosResponse<any>) =>{
                errorDialog(response.data, "");
                setArchivo(null);
            }).catch((error:AxiosError<any>) =>{
                successDialog(error.message.toString(), "");
            });
        }, `Esta seguro que desea eliminar el archivo`, 'Eliminar');
        

    }
    return (<>
        <Modal show={showedit} onHide={setshowedit} size={"lg"}>
            <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                <Modal.Title>{`Edición del requerimiento ${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="row">
                    <div className="col-12">
                        <EncabezadoHtml ReqSeleccionado={ReqSeleccionado} ></EncabezadoHtml>
                    </div>

                    <div className="col-8" >
                        <div className="form-control-sm">
                            <FormLabel className="fw-bold fs-4 mt-2"> Archivo adjunto:</FormLabel>
                            <input className="form-control" onChange={handleFileChange} type="file" id="formFile" />
                        </div>
                    </div>
                    <div className="col-4"  >
                        <div className="form-control-sm" hidden={Ruta === ""}>
                            <button onClick={(e:any) =>{
                                EliminarArchivo(Archivo);
                            }} className="btn btn-sm btn-danger mt-12"> <i className="bi-trash"></i></button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btn-sm" variant="primary" onClick={() => {
                    Guardar(); // guardamos la informacion del requerimiento
                }}

                    disabled={(EstadoRequerimientosSeleccionado.valor == '40' && (FechaOp == undefined || TecnicoAsignado == undefined))}
                >
                    Guardar
                </Button>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => setshowedit(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}