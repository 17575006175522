import React, { useEffect, useState } from "react";

import { Button, Form, Modal } from "react-bootstrap-v5"
import confirmarDialog, { successDialog, errorDialog } from "../../../../../../_start/helpers/components/ConfirmDialog";
import { useDataCorreosTx } from "../../core/provider";
import { insertListasCorreosTx, updateListasCorreosTx } from "../../data/dataCorreosTx";
import { Clientes, ListaNotifacion } from "../../models/dataModels";
type Props = {
    show: boolean;
    handleClose: () => void;
    title?: string;
};

export const UpdateListaCorreos: React.FC<Props> = ({ show, handleClose, title }) => {

    const { ListaNotifacionId, ClienteIdS, Clientes, ListaNotifacion, setClienteIdS, setListaNotifacion, setListaNotifacionId } = useDataCorreosTx();


    const [showInactivar, setshowInactivar] = useState(true);
    const [NombreLista, setNombreLista] = useState("");
    const ClienteSeleccionado = (Clientes as Clientes[]).filter(f => f.clienteIdS == ClienteIdS)[0];
    const ListaSeleccionada = (ListaNotifacion as ListaNotifacion[]).filter(nom => nom.ListaClienteNotifacionId == ListaNotifacionId)[0];
    useEffect(() => {

        ListaNotifacionId > 0 ?
            setNombreLista(ListaSeleccionada.NombreLista)
            : setNombreLista('');
        // segun el titulo seteamos lo que se muestra         
        setshowInactivar((title == "Crear Lista"));


    }, [])



    const updateCorreo = (tipoModificacion: number) => {
        confirmarDialog(() => {
            if (title == "Crear Lista") {
                if (ClienteSeleccionado.clienteIdS != 0) {
                    insertListasCorreosTx(ClienteSeleccionado.clienteIdS, NombreLista).then((response) => {
                        successDialog("Operación Éxitosa", "");
                        setListaNotifacion([...ListaNotifacion, response.data[0]]);
                        setClienteIdS(ClienteSeleccionado);
                        handleClose();
                    }).catch((error) => {
                        errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
                    });

                } else
                    errorDialog("<i>Debe selecionar un cliente<i/>", "");
            }
            else {
                const EsActivo = ListaSeleccionada?.EsActivo ? 0 : 1; // mandamos el opuesto al estado actual para que se active e inactive
                updateListasCorreosTx(ListaNotifacionId, NombreLista, EsActivo).then((response) => {

                    if (response.status == 200) {
                        successDialog("Operación Éxitosa", "");
                        (ListaNotifacion as ListaNotifacion[]).map(function (dato) {
                            if (dato.ListaClienteNotifacionId == ListaNotifacionId)
                                dato.EsActivo = !ListaSeleccionada?.EsActivo;
                            return dato;
                        });
                        handleClose();
                    }

                }).catch((error) => {
                    errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
                });
            }

        }, title == "Crear Lista" ? `Esta seguro que desea agregar la lista` : `Esta seguro que modificar la lista`
            , "Guardar");

    };

    const getNombreLista = (e: any) => {
        setNombreLista(e.target.value);
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6" >
                            <label className="control-label label label-sm  m-3" htmlFor="correo" style={{ fontWeight: 'bold' }}>Cliente:</label>
                            <span className="form-control control-label label label-sm  mb-3 border-0 fs-2" >{ClienteSeleccionado?.clienteNombre}</span>
                        </div>
                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                            <label className="control-label label label-sm  m-3" htmlFor="correo" style={{ fontWeight: 'bold' }}>Nombre Lista:</label>
                            <input className="form-control  input input-sm mb-3" placeholder="Ingrese Nombre Lista" type="email" onChange={getNombreLista} value={NombreLista} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary" onClick={() => {
                        updateCorreo(0);
                    }}>
                        Guardar
                    </Button>
                    <Button type="button" variant="danger" hidden={showInactivar} onClick={() => {
                        updateCorreo(1);
                    }}>
                        {ListaSeleccionada?.EsActivo ? 'Inactivar' : 'Activar'}
                    </Button>
                    <Button type="button" variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}