

import IndiceUsuarios from "../../modules/auth/components/ListadoUsuarios";
import Registration from "../../modules/Seguridad/Administracion/components/Usuarios/Registration";
//INDICADORES ECOSISTEMA SYSCAF
import ECO_IngresoUsuarios from "../../modules/Seguridad/Ecosistema/components/usuarios";
import ECO_PeticionesServicios from "../../modules/Seguridad/Ecosistema/components/Peticiones";
import ECO_Seguridad from "../../modules/Seguridad/Modulos/Seguridad";
 


export let ListaAuth : any [] = [];
// APP AUTH
ListaAuth.push( { Component: IndiceUsuarios, url : "/admin/usuarios/listado" })
ListaAuth.push( { Component: Registration, url : "/auth/registration" })

ListaAuth.push( { Component: ECO_IngresoUsuarios, url : "/admin/ingresosusuarios" })
ListaAuth.push( { Component: ECO_IngresoUsuarios, url : "/goi/ingresosusuarios" })

ListaAuth.push( { Component: ECO_PeticionesServicios, url : "/admin/dashboard" })
ListaAuth.push( { Component: ECO_Seguridad, url : "/admin/seguridad/opciones" })