import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap-v5"
import * as XLSX from 'xlsx';
import {  InsertarRequerimientosMasivos, ValidarPlacasRequerimiento } from "../../../data/PostVentaData";
import { AxiosError, AxiosResponse } from "axios";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import confirmarDialog from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { RetornarRequerimientosMasivos } from "../../../data/dashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../setup";
import { UserModelSyscaf } from "../../../../../auth/models/UserModel";
import { DescargarExcel } from "../../../../../../../_start/helpers/components/DescargarExcel";
import { GetPlantilaRequerimientos } from "../../../../../../../_start/helpers/Axios/DWHService";

type PropsRequerimientosMasivos = {
    showMasivos:any,
    setshowMasivos:any
    setloader:any
}
export const ModalRequerimientosMasivos: React.FC<PropsRequerimientosMasivos> = ({showMasivos,setshowMasivos, setloader}) =>{
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;
    
    const [FileName, setFileName] = useState<any>("Ningún archivo seleccionado");
    const [Informe, setInforme] = useState<any>({});
    const [Validado, setValidado] = useState<boolean>(false);
    const [Data, setData] = useState<any>([]);
     const readExcel = (file:any) => {
        const promise = new Promise((resolve, reject) => {
          const fileReader = new FileReader();

          fileReader.readAsBinaryString(file[0]);
    
          fileReader.onload = (e) => {
            if(e.target != null){
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "binary" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                setInforme(data);
            }
            // console.log(this.state);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    
        promise.then((d) => {
          // console.log(d)
        });
      };
    

const Validar = () =>{
    ValidarPlacasRequerimiento(JSON.stringify(Informe)).then((response:AxiosResponse<any>) =>{
        console.log(response.data);
        setData(response.data);
    }).catch((error:AxiosError<any>) =>{
        console.log(error);
    });
}

const UploadRequerimientos = () =>{
    let Requerimientos = RetornarRequerimientosMasivos(Data,vUser);
    
    confirmarDialog(() => {
        setloader(true);
        InsertarRequerimientosMasivos(JSON.stringify(Requerimientos)).then((response:AxiosResponse<any>) =>{
            DescargarExcel(response.data,[
                {
                    accessorKey: 'Consecutivo',
                    header: 'Consecutivo',
                },
                {
                    accessorKey: 'Administrador',
                    header: 'Administrador',
                },
                {
                    accessorKey: 'Estado',
                    header: 'Estado',
                },
                {
                    accessorKey: 'Vehiculo',
                    header: 'Vehiculo',
                },
                {
                    accessorKey: 'Cliente',
                    header: 'Cliente',
                },
                {
                    accessorKey: 'Categoria',
                    header: 'Categoria',
                }
            ],"Reporte de requimientos creados")
            setloader(false);
            setshowMasivos(false);
        }).catch((error:AxiosError<any>) =>{
            console.log(error);
            setloader(false);
        })
    }, `¿Esta completamente seguro de procesar todos los registros seleccionados?`, 'Si');
}
const Eliminar = (row:any) =>{
    confirmarDialog(() => {
        let a = [...Data];
        a = a.map((e:any) =>{
           if(e.AssetId != row.original.AssetId){
               return e;
           }
       }).filter(a => a);
       setData(a);
    }, `¿Esta completamente seguro que  eliminar el registro?`, 'Eliminar');
}

let ColumnasValidar: MRT_ColumnDef<any>[] =
[
    {
        accessorKey: 'assetsDescription',
        header: 'Opciones',
        Cell({ cell, column, row, table, }) {
            return (
                <div className="text-center">
                    <span className={ `text-center ${(row.original.PlacaRegistrada == false || row.original.RequerimientoAbierto == true ? "visible" :"invisible")}` }>
                        <a
                            onClick={() => Eliminar(row)}
                            className="btn btn-danger btn-sm fw-bolder"
                            title={`Quitar el registro ${row.original.registrationNumber}`}
                        >
                            <i className="bi-clipboard-check"></i>
                        </a>
                    </span>
                </div>)
                
        },
    },
    {
        accessorKey: 'clientenNombre',
        header: 'Cliente',
        size: 90,
    },
    {
        accessorKey: 'registrationNumber',
        header: 'Placa',
    },
    {
        accessorKey: 'MensajeValidacion',
        header: 'Validación',
    },
    {
        accessorKey: 'estadoSyscaf',
        header: 'Estado',
        Cell({ cell, column, row, table, }) {
            return <span>{(row.original.estadoSyscaf == null ? "-":row.original.estadoSyscaf)}</span>
        }
    },
    {
        accessorKey: 'ActivoFacturable',
        header: 'Facturable',
        size: 90
    },
    {
        accessorKey: 'Observaciones',
        header: 'Observacion',
    }
];

const DescargarPlantillaMasiva = () =>{
    GetPlantilaRequerimientos("Plantilla").then((response:AxiosResponse<any>) =>{
        if(response.status == 200) {
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            // var sampleArr = base64ToArrayBuffer(response?.data);
            const url = window.URL.createObjectURL(new Blob([response?.data], { type: "application/excel" }));
            a.href = url;
            a.download = `Plantilla.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
           console.log(response.data);
        }
    }).catch((error:AxiosError<any>) =>{
        console.log(error);
    });
};

useEffect(() =>{
    let Datos =   Data.filter((val:any) =>{
        if(val.PlacaRegistrada == false || val.RequerimientoAbierto == true){
            return val;
        }
    });
    if(Datos.length >0)
        setValidado(false);
    else
        setValidado(true);
    
},[Data])
    return (<>
        <Modal show={showMasivos} onHide={setshowMasivos} size={"xl"} >
            <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                <Modal.Title>{`Carga masiva de requerimientos `}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                        </div>
                        <div className="col-4">
                        </div>
                        <div className="col-4">
                            <a className="mt-5"  onClick={DescargarPlantillaMasiva} title="Descargue su plantilla">Plantilla</a>
                        </div>
                        <div className="col-8">
                            <input accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="input-field input input-sm form-control" type="file"                                 onChange={(files: any) => {
                                files.target.files[0] && setFileName(files.target.files[0].name);
                                    if (files.target.files) {
                                        readExcel(files.target.files);
                                    }
                                }}
                            />
                        </div>
                        <div className="col-2">
                            <button className={`btn btn-sm btn-danger float-end ${(Informe.length > 0  ? "visible" :"invisible")}`} onClick={Validar}><i className="bi bi-wrench"></i>Validar</button>
                        </div>
                        <div className="col-2">
                            <button className= {`btn btn-sm btn-success float-start ${ (Validado == true ? "visible" :"invisible" )}`} onClick={UploadRequerimientos}><i className="bi bi-upload"></i>Procesar</button>
                        </div>
                        <div className="col-12">
                        {(Data.length != 0) && (<MaterialReactTable
                                localization={MRT_Localization_ES}
                                muiTableHeadCellProps={{
                                    sx: () => ({
                                        fontSize: 14,
                                        fontStyle: 'bold',
                                        color: 'rgb(27, 66, 94)'
                                    }),
                                }}
                                columns={ColumnasValidar}
                                data={Data}
                                enableColumnResizing
                                enableDensityToggle={false}
                                enablePagination={false}
                                enableRowVirtualization
                                enableGrouping
                                enableStickyHeader
                                enableStickyFooter
                                initialState={{
                                    density: 'compact',
                                    columnVisibility: { order: false }
                                }}
                                // muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                                muiTableContainerProps={{
                                    sx: { maxHeight: 400 }
                                }}
                                muiTableBodyRowProps={({ row }) => ({
                                    sx: {
                                      backgroundColor:
                                      row.original.PlacaRegistrada == false || row.original.RequerimientoAbierto == true ? 'rgba(248, 215, 218, 1)' : 'rgba(224, 224, 224, 1)'
                                    }
                                  })}
                            />)}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => setshowMasivos(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}