import moment from "moment"
import { EstadosGOI, FormatoColombiaDDMMYYY, FormatoColombiaDDMMYYYHHmm, Perfiles } from "../../../../../_start/helpers/Constants"
import confirmarDialog, { errorDialog } from "../../../../../_start/helpers/components/ConfirmDialog"
import { Post_GetConsultasDinamicasUserCore, Post_getDynamicProcedureDWH } from "../../../../../_start/helpers/Axios/DWHService"
import { ColoruCategoria, DiasCambios, FechaCambio, FiltroDashBoardData } from "./PostVentaData"
// import { Usuarios } from "../mockData/indicadores"
import { Post_getConsultasDinamicasConAuthCore } from "../../../../../_start/helpers/Axios/CoreService"
import axios, { AxiosError, AxiosResponse } from "axios"
import { MRT_ColumnDef } from "material-react-table";
import { DeserializarJSon, GetFechaServidor, formatViewHoraMinuto, formatableJson_HHMMss, locateFormatPercentNDijitos } from "../../../../../_start/helpers/Helper"
import { Box, IconButton, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material"
import { useState } from "react"
import { ARCHIVO_DeleteFileBase64, ARCHIVO_DownloadFileBase64, NEP_InsertaArchivo, NEP_UploadFile } from "../../../../../apiurlstore"


export let PerfilUsuario: any = {
    isSuperAdmin: false,
    isAdmin: false,
    isAgent: false,
    isGestor: false,
    type: ""
}

/*============================================================================================================================================================================== */
/** ESpacio para los tipos de estados a usar por el momento usare estos porque fueron los que se habian definido si en un posterior evento se dinamiza cambiar por estos.        */
/*============================================================================================================================================================================== */
export const PerfilSuperAdmin: string = Perfiles.SuperAdmin;
export const PerfilAdminFlota: string = Perfiles.AdminFlota;
export const PerfilEmpleado: string = Perfiles.Empleado;
export const EstadoReabierto = EstadosGOI.EstadoReabierto;
export const EstadoSoporteSinAsignar = EstadosGOI.EstadoSoporteSinAsignar;
export const EstadoSoporteAsignado = EstadosGOI.EstadoSoporteAsignado;
export const EstadoSoporteenprogreso = EstadosGOI.EstadoSoporteenprogreso;
export const EstadoStAsignado = EstadosGOI.EstadoStAsignado;
export const EstadoResuelto = EstadosGOI.EstadoResuelto;
export const EstadoSoporteValidacion = EstadosGOI.EstadoSoporteValidacion;
export const EstadoStSinAsignar = EstadosGOI.EstadoStSinAsignar;
export const EstadoStOrdenServicio = EstadosGOI.EstadoStOrdenServicio;
export const TextoNotificacion: string = "Hola {UsuarioDestino}, Estás siendo notificado porque el administrador {Admin} te ha asignado el requerimiento {Consecutivo}. Por favor, revisa información. Saludos cordiales.";
export const TextoNotificacionAmin: string = "Hola {Admin}, Estás siendo notificado porque el agente {UsuarioDestino} ha dado por resuelto el requerimiento {Consecutivo}. Por favor, revisa información. Saludos cordiales.";
export const NotificarCorreo: string = "1";
export const NotificarPortal: string = "1";

export const LstValidarAbrirReq: any[] = [EstadoSoporteAsignado, EstadoSoporteenprogreso, EstadoResuelto, EstadoSoporteValidacion];
export const IndicadoresAdmin: any[] = [

    {
        "Estado": "Abiertos", "Descripcion": "Total de Requerimientos Abiertos", total: function (data: any[]) {
            return FiltroData.getTotalPorEstadoNegacion(data, ["closed", "noopen"], true)
        }, "Valor": 0
    },
    {
        "Estado": "En Soporte", "Descripcion": "Total de Requerimientos en Soporte", total: function (data: any[]) {
            return FiltroData.getTotalPorTipo(data, "Soporte")
        }, "Valor": 0
    },
    {
        "Estado": "En Servicio T", "Descripcion": "Total de Requerimientos en servicio tecnico", total: function (data: any[]) {
            return FiltroData.getTotalPorTipo(data, "ST")
        }, "Valor": 0
    },
    {
        "Estado": "En Ingeniería", "Descripcion": "Total de Requerimientos en Ingenieria", total: function (data: any[]) {
            return FiltroData.getTotalPorTipo(data, "Ing")
        }, "Valor": 0
    },
    {
        "Estado": "Total Cerrados", "Descripcion": "Total de requerimientos resueltos", total: function (data: any[]) {
            return FiltroData.getTotalPorEstado(data, ["closed"], false)
        }, "Valor": 0
    },
    {
        "Estado": "Tasa de resolución", "Descripcion": "Tasa de resolución", total: function (data: any[]) {
            const totalCreados = FiltroData.getTodosCreados(data, false);
            const totalCerrado = FiltroData.getTotalPorEstado(data, ["closed"], false);
            return (totalCreados == 0) ? "0%" : locateFormatPercentNDijitos(totalCerrado * 1.0 / totalCreados * 1.0, 2)
        }, "Valor": 0
    }
]
export const IndicadoresGenerales = [
    {
        "Estado": "Total Abiertos", "Descripcion": "Total de Requerimientos ", total: function (data: any[]) {
            return FiltroData.getTotalPorEstadoNegacion(data, ["closed"], false);
        }
    },
    {
        "Estado": "Sin Asignar", "Descripcion": "Total de Requerimientos Abiertos", total: function (data: any[]) {
            return FiltroData.getTotalPorEstado(data, ["noopen"], false)
        }
    },
    {
        "Estado": "Asignados", "Descripcion": "Total de Requerimientos en Soporte", total: function (data: any[]) {
            return FiltroData.getTotalPorEstadoNegacion(data, ["closed", "noopen"], true)
        }
    },
    {
        "Estado": "Cerrados", "Descripcion": "Total de Requerimientos en servicio tecnico", total: function (data: any[]) {
            let Cerrados = (data[0].TotalCerrados == undefined ? 0:data[0].TotalCerrados);
            let CerradosConsulta = FiltroData.getTotalPorEstado(data, ["closed"], false);

            return ( Cerrados != 0 ? Cerrados : CerradosConsulta);
        }
    },
    {
        "Estado": "Tasa de resolución", "Descripcion": "Tasa de resolución de los requerimientos", total: function (data: any[]) {
        
            const totalCreados =  data[0].TotalCreados != undefined || data[0].TotalCreados  ? data[0].TotalCreados : (FiltroData.getTodosCreados(data, false) );
            const totalCerrado = (FiltroData.getTotalPorEstado(data, ["closed"], false) != 0 ? FiltroData.getTotalPorEstado(data, ["closed"], false):data[0].TotalCerrados);
            return (totalCreados == 0) ? "0%" : locateFormatPercentNDijitos(totalCerrado * 1.0 / totalCreados * 1.0, 2)
        }
    },
]

export const IndicadoresGeneralesInforme = [
    {
        "Estado": "Total", "Descripcion": "Total de Requerimientos ", total: function (data: any[]) {
            return FiltroData.getTotalInforme(data);
        }
    },
    {
        "Estado": "Sin Asignar", "Descripcion": "Total de Requerimientos Abiertos", total: function (data: any[]) {
            return FiltroData.getTotalPorEstado(data, ["noopen"], false)
        }
    },
    {
        "Estado": "Asignados", "Descripcion": "Total de Requerimientos en Soporte", total: function (data: any[]) {
            return FiltroData.getTotalPorEstadoNegacion(data, ["closed", "noopen"], true)
        }
    },
    {
        "Estado": "Cerrados", "Descripcion": "Total de Requerimientos en servicio tecnico", total: function (data: any[]) {
            return FiltroData.getTotalPorEstado(data, ["closed"], false)
        }
    },
    {
        "Estado": "Tasa de resolución", "Descripcion": "Tasa de resolución de los requerimientos", total: function (data: any[]) {
            const totalCreados = FiltroData.getTodosCreados(data, false);
            const totalCerrado = FiltroData.getTotalPorEstado(data, ["closed"], false);
            return (totalCreados == 0) ? "0%" : locateFormatPercentNDijitos(totalCerrado * 1.0 / totalCreados * 1.0, 2)
        }
    }
]
const tabReq1 = {
    icon: '', titulo: "Todos", subtitulo: "", total: function (data: any[]) {
        return FiltroData.getTotalPorEstadoNegacion(data, ["closed"], false)
    }, notificado: true
}
const tabReq2 = {
    icon: '', titulo: "Asignados", subtitulo: "", total: function (data: any[]) {
        return FiltroData.getTotalPorEstadoNegacion(data, ["closed", "noopen"], true)
    }
}
//Soporte
const tabReq3 = {
    icon: '', titulo: "No Asignados", subtitulo: "", total: function (data: any[]) {
        return FiltroData.getTotalPorEstado(data, ["noopen"], false)
    }, notificado: true
}
// const tabReq3 = {
//     icon: '', titulo: "Cerradas", subtitulo: "", total: function (data: any[]) {
//         return FiltroData.getTotalPorEstado(data, ["closed"], false)
//     }, notificado: true
// }
const tabReq4 = { icon: 'Equalizer', titulo: "Rojo", subtitulo: "", color:'danger' }
const tabReq5 = { icon: 'Equalizer', titulo: "Amarillo", subtitulo: "", color:'warning' }
const tabReq6 = { icon: 'Equalizer', titulo: "Verde", subtitulo: "", color:'success' }

export const listTabsRequerimientos: any[] = [tabReq1, tabReq2, tabReq3, tabReq4, tabReq5,tabReq6 ]
export const totalesTabs = (data: any[], TotaleGeneral: any) => {
    let nuevo = { ...TotaleGeneral }
    listTabsRequerimientos.filter((f: any) => f.notificado).forEach((m: any) => {
        nuevo[m.titulo] = m.total(data)
    })
    return nuevo;
}

export const fncReporte = [
    {
        name: "Fecha",
        getData: (data: any) => {
            return (moment(data).format(FormatoColombiaDDMMYYY))
        }
    }
]

//=====================================================================================================================================
//Guarda los requerimientos
export function SetRequerimiento(Datos: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["Cabecera"] = Datos.Cabecera;
    params["Observaciones"] = Datos.Observaciones;
    params["Estado"] = Datos.Estado;
    params["Id"] = String(Datos.Id);
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "ModificarRequerimiento", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}

//Elimina los requerimientos
export function DeleteRequerimiento(Datos: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["Observaciones"] = Datos.Observaciones;
    params["Estado"] = Datos.Estado;
    params["Id"] = String(Datos.Id);
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "EliminarRequerimiento", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}

//Trae los requerimientos
export function GetRequerimientos(FechaInicial: any, FechaFinal: any, Perfil: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["FechaInicial"] = FechaInicial;
    params["FechaFinal"] = FechaFinal;
    params["PerfilId"] = String(Perfil);
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetRequerimientosInterfaz", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
export function GetRequerimientosDashboard() {
    var params: { [id: string]: string | null | undefined; } = {};

    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetRequerimientosDashboard", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}


//Notifica
export function SetNotificaciones(Datos: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["UsuarioId"] = Datos.UsuarioId;
    params["RequerimientoId"] = String(Datos.RequerimientoId);
    params["Descripcion"] = Datos.Descripcion;
    params["NotificarCorreo"] = Datos.NotificarCorreo;
    params["NotificarPortal"] = Datos.NotificarPortal;
    return Post_getConsultasDinamicasConAuthCore({
        NombreConsulta: "SetNotificacionesRequerimiento", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
//Encabezado
export function GetEncabezado(AssetId: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["AssetId"] = AssetId;
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetEncabezado", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
export function GetEncabezadoFallas(FechaInicial: any, FechaSinTx: any, ClienteIds: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["FechaInicial"] = FechaInicial;
    params["FechaFinal"] = FechaSinTx;
    params["ClienteIds"] = String(ClienteIds);
    return Post_getDynamicProcedureDWH({
        NombreConsulta: "GetFallaEncabezado", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
//Se guarda el diagnostico
export function SetDiagnostico(Datos: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["Diagnostico"] = Datos.Diagnostico;
    params["Observaciones"] = Datos.Observaciones;
    params["Estado"] = Datos.Estado;
    params["Id"] = String(Datos.Id);
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "setDiagnostico", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}

//Se Consultan las configuraciones
export function GetConfiguracion(Sigla: any | null) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["Sigla"] = Sigla;
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetConfiguracion", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
//Trae los requerimientos para el informe
export function GetRequerimientosInforme(FechaInicial: any, FechaFinal: any, PerfilId:any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["FechaInicial"] = FechaInicial;
    params["FechaFinal"] = FechaFinal;
  //  params["PerfilId"] = String(PerfilId);
    
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "GetRequerimientosInforme", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}
//======================================================================================================================================
export const FiltroData = {
    getTotalPorTipo: (data: any[], tipo: string) => {
        return data.filter(f => tipo == DeserializarJSon(f.Estado).tipo).length;
    },

    getTotalPorEstadoNegacion: (data: any[], Estado: any, agente: boolean) => {
        return data.filter(f => !Estado.includes(DeserializarJSon(f.Estado).tipoestado)
            && (!agente || DeserializarJSon(f.Cabecera).agente !== "")
        ).length;
    },
    getTotalPorEstadoNegacionAdminCliente: (data: any[], Estado: any, agente: boolean, Admin:any|null, Cliente:any|null) => {
        return data.filter(f => !Estado.includes(DeserializarJSon(f.Estado).tipoestado)
            &&  (!agente || DeserializarJSon(f.Cabecera).agente !== "") 
            &&  (!Admin  || DeserializarJSon(f.Cabecera).administrador === Admin) 
            &&  (!Cliente || DeserializarJSon(f.Cabecera).clienteid === Cliente )
        ).length;
    },
    
    getTotalPorTipoAdminCliente: (data: any[], tipo: string, Admin:any|null, Cliente:any|null) => {
        return data.filter(f => tipo == DeserializarJSon(f.Estado).tipo
            &&  (!Admin  || DeserializarJSon(f.Cabecera).administrador === Admin) 
            &&  (!Cliente || DeserializarJSon(f.Cabecera).clienteid === Cliente )
        ).length;
    },


    getTotalPorEstado: (data: any[], Estado: any, agente: boolean) => {
        return data.filter(f => Estado.includes(DeserializarJSon(f.Estado).tipoestado)

        ).length;
    },
    getTodos: (data: any[]) => {
        return data.filter(f => !["closed"].includes(DeserializarJSon(f.Estado).tipoestado)

        );
    },
    
    getRequerimientosPorColor: (data: any[], EstadosRequerimientos : any[], Categoria : number) => {
        return data.filter(f => ColoruCategoria(f,false,EstadosRequerimientos) === Categoria );
    },
    // Indicadores asignados
    getAsignados: (data: any[]) => {
        return data.filter(f => !["closed", "noopen"].includes(DeserializarJSon(f.Estado).tipoestado)
            && DeserializarJSon(f.Cabecera).agente !== ""
        );
    },
    getNoAsignados: (data: any[]) => {
        return data.filter(f => ["noopen"].includes(DeserializarJSon(f.Estado).tipoestado)
        );
    },
    //indicadores de cerrados
    getCerrados: (data: any[]) => {
        return data.filter(f => ["closed"].includes(DeserializarJSon(f.Estado).tipoestado)
        );
    },
    //Indicador de abiertos
    getAbiertos: (data: any[], Estado: any) => {
        return data.filter(f => [...Estado?.split(",")].includes(((FiltroDashBoardData.EsJson(f.Estado) == true ? JSON.parse(f.Estado).label : f.Estado))));
    },
    //Indicador de soporte
    getSoporte: (data: any[], Estado: any) => {
        return data.filter(f => [...Estado?.split(",")].includes(((FiltroDashBoardData.EsJson(f.Estado) == true ? JSON.parse(f.Estado).label : f.Estado))));
    },
    //Indicador total informe
    getTotalInforme: (data: any[]) => {
        return data.filter(f => DeserializarJSon(f.Estado).tipoestado).length;
    },
    //Es el reporte total
    getReporte: function (data: any[]) {
        return (data).reduce((p, c) => {
            const Cabecera = JSON.parse(c.Cabecera);
            let name = Cabecera.registrationNumber;
            p[name] = p[name] ?? [];
            p[name].push(c);
            return p;
        }, {});
    },
    //para sacar los clientes
    getClientes: (data: any[]) => {
        let Cabeceras = data.map((e: any) => JSON.parse(e.Cabecera));
        return Cabeceras.reduce((p, c) => {
            let name = c.nombrecliente;
            p[name] = p[name] ?? [];
            p[name].push(c.clienteid);
            return p;
        }, {});
    },
    //para sacar los agentes
    getAgentes: (data: any[]) => {
        let Cabeceras = data.map((e: any) => JSON.parse(e.Cabecera));
        return Cabeceras.reduce((p, c) => {
            let name = (c.agente ? c.agente : null);
            p[name] = p[name] ?? [];
            p[name].push(c.UsuarioId);
            return p;
        }, {});
    },
    //Para todos
    getTodosCreados: (data: any[], agente: boolean) => {
        return data.filter(f => (!agente || DeserializarJSon(f.Cabecera).agente !== "")
        ).length;
    },
    //para sacarlos estados
    getEstados: (data: any[]) => {
        return data.map(f => f.Estado).filter(UnicoArrayValores);
    },
    //Para filtrar por agentes
    getFiltrobyAgente: (data: any[], UsuarioId: any) => {
        //Taraigo los datos de las cabeceras y las convierto a array para poder buscarlas posteriormente.
        let Cabeceras = data.map((e: any) => JSON.parse(e.Cabecera));
        //FIltro las cabeceras por usuariops
        let CabeceraFound = Cabeceras.filter((e: any) => e.UsuarioId == UsuarioId);
        //Declaro un array para meter los datos y porteriormente retornarlos
        let l: any = [];
        //Recorro las cabeceras encoentraas. 
        CabeceraFound.reduce((a, b) => {
            //Filtro los registros que coincidan con el filtro aplicado, como tengo los datos para filtrar es en las cabeceras
            //de deserializo y escojo el primer registro y serializo convierto a string para compar si equivalen al mismo
            //Si lo es retorno el registro completo
            let o = data.filter((val: any) => {
                let cabecera = JSON.parse(val.Cabecera);
                if (String(JSON.stringify(cabecera[0])) == String(JSON.stringify(b[0]))) {
                    return val;
                }
            })
            //Agrego el primer registro encontrado
            l.push(o[0])
        }, {})
        //retorno el array con los elementos a mostrar
        return l;
    },
     //Para filtrar por agentes
     getFiltrobyAgenteNombre: (data: any[], UsuarioId: any) => {
        //Taraigo los datos de las cabeceras y las convierto a array para poder buscarlas posteriormente.
        let Cabeceras = data.map((e: any) => JSON.parse(e.Cabecera));
        //FIltro las cabeceras por usuariops
        let CabeceraFound = Cabeceras.filter((e: any) => e.administrador == UsuarioId);
        //Declaro un array para meter los datos y porteriormente retornarlos
        let l: any = [];
        //Recorro las cabeceras encoentraas. 
        CabeceraFound.reduce((a, b) => {
            //Filtro los registros que coincidan con el filtro aplicado, como tengo los datos para filtrar es en las cabeceras
            //de deserializo y escojo el primer registro y serializo convierto a string para compar si equivalen al mismo
            //Si lo es retorno el registro completo
            let o = data.filter((val: any) => {
                let cabecera = JSON.parse(val.Cabecera);
                if (String(JSON.stringify(cabecera)) == String(JSON.stringify(b))) {
                    return val;
                }
            })
            //Agrego el primer registro encontrado
            l.push(o[0])
        }, {})
        //retorno el array con los elementos a mostrar
        return l;
    },   
    getFiltrobyCliente: (data: any[], Clienteid: any) => {
        //Taraigo los datos de las cabeceras y las convierto a array para poder buscarlas posteriormente.
        let Cabeceras = data.map((e: any) => JSON.parse(e.Cabecera));
        //FIltro las cabeceras por usuariops
        let CabeceraFound = Cabeceras.filter((e: any) => e[0].clienteid == Clienteid);
        //Declaro un array para meter los datos y porteriormente retornarlos
        let l: any = [];
        //Recorro las cabeceras encoentraas. 
        CabeceraFound.reduce((a, b) => {
            //Filtro los registros que coincidan con el filtro aplicado, como tengo los datos para filtrar es en las cabeceras
            //de deserializo y escojo el primer registro y serializo convierto a string para compar si equivalen al mismo
            //Si lo es retorno el registro completo
            let o = data.filter((val: any) => {
                let cabecera = JSON.parse(val.Cabecera);
                if (String(JSON.stringify(cabecera[0])) == String(JSON.stringify(b[0]))) {
                    return val;
                }
            })
            //Agrego el primer registro encontrado
            l.push(o[0])
        }, {})
        //retorno el array con los elementos a mostrar
        return l;
    },
    getFiltrobyClienteNombres: (data: any[], Clienteid: any) => {
        //Taraigo los datos de las cabeceras y las convierto a array para poder buscarlas posteriormente.
        let Cabeceras = data.map((e: any) => JSON.parse(e.Cabecera));
        //FIltro las cabeceras por usuariops
        let CabeceraFound = (Clienteid == "Todas las bases" ? Cabeceras :  Cabeceras.filter((e: any) => e.nombrecliente == Clienteid)) ;
        //Declaro un array para meter los datos y porteriormente retornarlos
        let l: any = [];
        //Recorro las cabeceras encoentraas. 
        CabeceraFound.reduce((a, b) => {
            //Filtro los registros que coincidan con el filtro aplicado, como tengo los datos para filtrar es en las cabeceras
            //de deserializo y escojo el primer registro y serializo convierto a string para compar si equivalen al mismo
            //Si lo es retorno el registro completo
            let o = data.filter((val: any) => {
                let cabecera = JSON.parse(val.Cabecera);
                if (String(JSON.stringify(cabecera)) == String(JSON.stringify(b))) {
                    return val;
                }
            })
            //Agrego el primer registro encontrado
            l.push(o[0])
        }, {})
        //retorno el array con los elementos a mostrar
        return l;
    },
    getAsignadosTipo: (data: any[], Estado: any, Tipo: any) => {
        return data.filter(f => [...Estado?.split(",")].includes((f.Estado)) && f.Tipo == Tipo);
    },
    getAbiertosTipo: (data: any[], Estado: any, Tipo: any) => {
        return data.filter(f => ![...Estado?.split(",")].includes((f.Estado)) && f.Tipo == Tipo);
    },
    //indicadores de cerrados
    getCerradosTipo: (data: any[], Estado: any, Tipo: any) => {
        return data.filter(f => [...Estado?.split(",")].includes((f.Estado)) && f.Tipo == Tipo);
    },
    //Indicador de soporte
    getSoporteTipo: (data: any[], Estado: any, Tipo: any) => {
        let datos = data.filter((f: any) => {
            [...Estado.split(",")].includes(
                (FiltroDashBoardData.EsJson(f.Estado) == true ? JSON.parse(f.Estado).valor : f.Estado)
            )
        }
        );
        return datos
    },
    //Indicador de todos Dahsboard
    getAbiertosDashboard: (data: any[], Estados: any) => {
        let a = Estados.split(",");
        let datos = data.filter((f: any) => {
            if (!a.includes((FiltroDashBoardData.EsJson(f.Estado) == true ? JSON.parse(f.Estado).valor : f.Estado)))
                return f;
        }
        );
        return datos
    },
    //Indicador de soporte
    getSoporteDashboard: (data: any[], Estados: any) => {
        let a = Estados.split(",");
        let datos = data.filter((f: any) => {
            if (a.includes((FiltroDashBoardData.EsJson(f.Estado) == true ? JSON.parse(f.Estado).valor : f.Estado)))
                return f;
        }
        );
        return datos
    },
    //Indicador de st
    getSTDashboard: (data: any[], Estados: any) => {
        let a = Estados.split(",");
        let datos = data.filter((f: any) => {
            if (a.includes((FiltroDashBoardData.EsJson(f.Estado) == true ? JSON.parse(f.Estado).valor : f.Estado)))
                return f;
        }
        );
        return datos
    },
    getIsActivoMod: (row: any, estado: any) => {
        let Estado = (FiltroDashBoardData.EsJson(row.original.Estado) ? JSON.parse(row.original.Estado) : row.original.Estado);
        if ((Estado.label == undefined ? Estado : Estado.label) != estado)
            return false;
        else
            return true;
    },
    getvalidar: (Observacion: any) => {
        if (Observacion == null || Observacion == undefined) {
            errorDialog("Debe ingresar una observación", "");
            return false;
        }

        return true;
    },
    getIsUsuarioSoporte: (Usuario: any, Usuarios: any) => {
        let Existe = Usuarios.filter((f: any) => f.UserId == Usuario);
        return (Existe.length != 0 ? true : false);
    },
    getFiltroGestor: (data: any[], Usuario: any, Usuarios: any, St: any, Estados: any) => {
        if (Usuario == "")
            return;
        let ValoresSt = Estados.filter((e: any) => { return (e.tipo == "st") }).map((f: any) => f.valor);
        let ValoresS = Estados.filter((e: any) => { return (e.tipo == "soporte") }).map((f: any) => f.valor);
        let Valores: any = (St ? ValoresSt : ValoresS);

        let UsuarioFound = Usuarios.filter((f: any) => f.UserId == Usuario);
        if (UsuarioFound.length != 0) {
            if (UsuarioFound[0].EsGestor == true) {
                return data
            }
            else
                return data.filter((val: any) => {
                    let Estado = (FiltroDashBoardData.EsJson(val.Estado) == true ? JSON.parse(val.Estado) : val.Estado);
                    if (Valores.includes(Estado.valor) || val.UsuarioId == Usuario) {
                        return val;
                    }
                })
        }
    },
    getEstadosJson: (Estado: any) => {
        let estado = (FiltroDashBoardData.EsJson(Estado) == true ? JSON.parse(Estado) : Estado);
        return (estado.label == undefined ? estado : estado.label);
    },
    getEsCompletado: (data: any[]) => {
        return data.filter((val: any) => {
            if (val.esobligatorio == "si" && val.Respuesta == false)
                return val;
            else if (val.observaciones == "si-obligatorio" && val.RespuestaObservacion == "" || val.RespuestaObservacion == null || val.RespuestaObservacion == undefined)
                return val;
        })
    },
    getComprobarEstado: (data: any) => {
        data.map((val: any) => {
            if (val.Respuesta == true) {
                let compare = (val.compareRed == "si" ? true : false);
                if (val.Estado == compare)
                    return true;
                else
                    return false
            }
            return true;
        });
    },
    getConfiguracionSigla: (data: any[], Sigla: any) => {
        let datos = data.map((f: any) => {
            if (f.Sigla == Sigla)
                return JSON.parse(f.Configuracion);
        }).filter((e) => e)
        return datos;
    },
    //NOTIFICAR
    Notificar: (Data: any) => {
        SetNotificaciones(Data).then((response: AxiosResponse<any>) => {
            console.log("Ha sido notificado.");
        }).catch(({ error }) => {
            console.log("Error: ", error)
        });
    },
    getEsAsignable: (data: any, Estado: any) => {
        let estado = (FiltroDashBoardData.EsJson(data.Estado) == true ? JSON.parse(data.Estado) : data.Estado);
        if ((estado.label == undefined ? estado : estado.label) == Estado)
            return true;
        else
            return false;
    },
    getEsSoporte: (row: any, Estado: any, consulta: any) => {
        let _estado = Estado.filter((f: any) => {
            return (f.valor == JSON.parse(row.original.Estado).valor && f.tipo == consulta)
        })
        if (_estado.length != 0) {
            return true;
        }
        else {
            return false;
        }
    }
};
// cuando se usa un filtro permite traer el unico valor de todas los valores del array
function UnicoArrayValores(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
}

//FUNCIONES PARA LOS REQUERIMIENTOS
export const RequerimientoFunciones = {
    // Indicadores asignados
    SetAsignarRequerimiento: (data: any) => {
        confirmarDialog(() => {

        }, `¿Esta seguro que desea asignar el registro?`, 'Aceptar');
    },
}



export function EsCerrado(Estados: any, estado: any) {
    let isSet;
    let data = Estados.filter((val: any) => {
        return (val.valor == estado.valor);
    })[0].IsCerrado;
    isSet = (data === "true" ? true : false)
    return isSet;
}


export const StringNotificacion = "Hola {UsuarioDestino}, Estás siendo notificado porque el administrador {Admin} te ha asignado el requerimiento {Consecutivo}. Por favor, revisa información. Saludos cordiales.";
export const StringNtificacionAdmin = "Hola {Admin}, Estás siendo notificado porque el agente {UsuarioDestino} ha dado por resuelto el requerimiento {Consecutivo}. Por favor, revisa información. Saludos cordiales.";
export const CabeceraInicialTextos = {
    administrador: "",
    UsuarioId: "",
    assetid: "",
    clienteid: "",
    registrationNumber: "",
    nombrecliente: "",
    agente: "",
    Fallas: "",
    DiasSinTx: ""
}


//Para el reporte
export const CamposReporte: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'registrationNumber',
            header: 'Vehiculo',
        },
        {
            accessorKey: 'Cantidad',
            header: 'Cantidad',
        },
    ];

export const SetearIndicadores = (Abiertos: any, Soporte: any, St: any, Resolucion: any, _Resueltos: any, Ing: any) => {
    return [
        { "Estado": "Abiertos", "Descripcion": "Total de Requerimientos Abiertos", "Visible": "true", "Valor": Abiertos.length },
        { "Estado": "En Soporte", "Descripcion": "Total de Requerimientos en Soporte", "Visible": (Soporte.length > 0 ? "true" : "false"), "Valor": Soporte.length },
        { "Estado": "En Servicio T", "Descripcion": "Total de Requerimientos en Servicio Tecnico", "Visible": (St.length > 0 ? "true" : "false"), "Valor": St.length },
        { "Estado": "En Ingenieria", "Descripcion": "Total de Requerimientos en Ingenieria", "Visible": (Ing.length > 0 ? "true" : "false"), "Valor": Ing.length },
        { "Estado": "Tasa de resolución", "Descripcion": "Tasa de resolución de los requerimientos de los últimos 7 días", "Visible": "true", "Valor": locateFormatPercentNDijitos(Resolucion, 2) },
        { "Estado": "Total resueltos", "Descripcion": "Total de requerimientos resueltos en los últimos 7 días.", "Visible": "true", "Valor": _Resueltos.length }
    ]
}
export const ExtraerAgentes = (Data: any[]) => {
    let Guardado: any[] = [];
    Object.entries(FiltroData.getAgentes(Data)).map((elem: any) => {
        if (elem[0] != null || elem[0] != "null" || elem[0] != undefined || elem[0] != "undefined") {
            Guardado.push({ "Agente": elem[0], "UsuarioId": elem[1][0] })
        }
    });
    Guardado = Guardado.filter((val: any) => {
        return (val.UsuarioId != null);
    })
    return Guardado;
}
export const ExtraerClientes = (Data: any[]) => {
    let Guardado: any[] = [];
    Object.entries(FiltroData.getClientes(Data)).map((elem: any) => {
        Guardado.push({ "Cliente": elem[0], "ClienteId": elem[1][0] })
    });
    return Guardado;
}
export const ExtraerEstados = (Data: any[]) => {
    let Guardado: any[] = [];
    Object.entries(FiltroData.getEstados(Data)).map((elem: any) => {
        let estado = FiltroData.getEstadosJson(elem[1]);
        Guardado.push({ "Estado": estado })
    });
    return Guardado;
}

export const ExtraerReportes = (Data: any[]) => {
    let Guardado: any[] = [];
    Object.entries(Data).map((elem: any) => {
        Guardado.push({ "registrationNumber": elem[0], "Cantidad": elem[1].length })
    });
    return Guardado;
}
//EXTRAE LA INFORMACION DE USUARIO ACTUAL DEL LISTADO
// PARA SABER QUE PERTENECE
export const ExtraerInformacionData = (Data: any[], compare: any) => {

    return Data.filter(f => f.UserId == compare);
}


export const SinUsuario = { Nombres: "Seleccione", UserId: "0" };


export const ObjetoEjecucionOrden = {
    Encabezado: {},
    Admin: {},
    Titulo: "",
    DatosBasicos: {},
}

export const RequerimientoEquipoDesmontado = (Data: any) => {
    var params: { [id: string]: string | null | undefined; } = {};
    params["Cabecera"] = Data.Cabecera;
    params["Tipo"] = Data.Tipo;
    params["Observaciones"] = Data.Observaciones;
    params["Estado"] = Data.Estado;
    params["PadreId"] = Data.PadreId
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "CrearNuevoSubRequerimiento", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}


export const SetOrdenServicio = (Data: any) => {
    var params: { [id: string]: string | null | undefined; } = {};
    params["OrdenServicio"] = Data.OrdenServicio;
    params["Observaciones"] = Data.Observaciones;
    params["Estado"] = String(Data.Estado);
    params["Id"] = String(Data.Id);
    return Post_GetConsultasDinamicasUserCore({
        NombreConsulta: "setOrdenServicio", Clase: "GOIQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}

export const ConsecutivoVisual = (data: any) => {
    return `${String(data).substring(0, 6)}-${String(data).substring(6, String(data).length)}`
}

export const fncInforme = [
      {
        name: "Categoria",
        getData: (data: any) => {
            return <span className={`badge bg-info`}>{data}</span>
          }
      },
     
]

//ESPACIO PARA LOS ENCABEZADOS DE LAS TABLAS
export const Campos: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'Consecutivo',
            header: 'Nro Requerimiento',
            filterVariant: 'text', // default
            Cell({ row, }) {
                //Lo divido en 2 para tener mejor claridad

                return (ConsecutivoVisual(row.original.Consecutivo));
            }
        },
        {
            accessorKey: 'Categoria',
            header: 'Categoria',
            filterVariant: 'text', // default
            Cell({ row, }) {
                let Cabecera = JSON.parse(row.original.Cabecera);
                row.original.Categoria = Cabecera.Categoria;
                return <span className={`badge bg-info`}>{Cabecera.Categoria}</span>

            }
        },
        {
            accessorKey: 'nombrecliente',
            header: 'Cliente',
            filterVariant: 'text', // default
            Cell({ row, }) {
                let Cabecera = JSON.parse(row.original.Cabecera);
                row.original.nombrecliente = Cabecera.nombrecliente;
               
                return (Cabecera.nombrecliente == undefined ? "" : Cabecera.nombrecliente);
            },
        },
        {
            accessorKey: 'registrationNumber',
            header: 'Vehiculo',
            filterVariant: 'text', // default
            Cell({ row, }) {
                
                let Cabecera = JSON.parse(row.original.Cabecera);
                row.original.registrationNumber = Cabecera.registrationNumber;
                return (Cabecera.registrationNumber == undefined ? "" : Cabecera.registrationNumber);
            }
        },
        {
            accessorKey: 'Zona',
            header: 'Área',
            filterVariant: 'text', // default
            enableHiding:true,
            Cell({ cell, column, row, table, }) {
                let estado = JSON.parse(row.original.Estado);
                return <span className={`badge bg-${estado.color}`}>{estado.tipo}</span>
            },
            filterFn: 'customFilterFn',
        },
        {
            accessorKey: 'Estado',
            header: 'Estado',
            filterVariant: 'text', // default
            Cell({ row, }) {
                let estado = JSON.parse(row.original.Estado);
                return <span className={`badge bg-${estado.color}`}>{estado.label}</span>
            }
        },
        {
            accessorKey: 'Administrador',
            header: 'Admin',
            filterVariant: 'text', // default
            Cell({ row, }) {
                let Cabecera = JSON.parse(row.original.Cabecera);
                row.original.Administrador = Cabecera.administrador;
                return (Cabecera.administrador == undefined ? "" : <span className="fw-bolder text-primary">{Cabecera.administrador}</span>);
            },
        },
        {
            accessorKey: 'agente',
            header: 'Agente',
            filterFn: 'customFilterFn',
            Cell({ row, }) {
                let Cabecera = JSON.parse(row.original.Cabecera);
                row.original.agente = Cabecera.agente;
                return <span className="fw-bolder text-primary">{Cabecera.agente}</span>;
            },
        },
        {
            accessorKey: 'Fecha',
            filterFn: 'customFilterFn',
            header: 'Fecha creacion',
            Cell({ row, }) {
                return moment(row.original.FechaCreacion).format("DD/MM/YYYY HH:MM");
            }
        },
        
        {
            accessorKey: 'Porc',
            header: 'Porc',
            enableHiding:true,
            sortDescFirst: true,
        },

        {
            accessorKey: 'FechaCambio',
            header: 'F. Cambio estado',
            Cell({ row, }) {
                let Fecha = FechaCambio(row.original)
                return (Fecha != "" ? moment(Fecha).format("DD/MM/YYYY HH:MM"):"-");
            },
            enableHiding:true,
            filterFn: 'customFilterFn',
            sortDescFirst: false
        },
        {
            accessorKey: 'DiasCambios',
            header: 'Dias',
            filterFn: 'customFilterFn',
            Cell({ row, }) {
                return DiasCambios(row.original);
            },
            enableHiding:true 
        }
       
    ];
// trae el objeto observaciones segun informacion enviada
export const GetObservacionesCambioEstado = (arrayObservaciones: any[], EstadoRequerimientosSeleccionado: any, vUser: any, Esdlp: boolean) => {
    arrayObservaciones.push({
        fecha: GetFechaServidor().format(formatableJson_HHMMss),
        observacion: `Se cambia el estado a ${EstadoRequerimientosSeleccionado.label}`,
        usuario: vUser.Nombres,
        estado: `${EstadoRequerimientosSeleccionado.tipo} - ${EstadoRequerimientosSeleccionado.label}`,
        Esdlp: Esdlp
    })

}
// trae el objeto observaciones segun informacion enviada
export const GetObservacionesEstadoObservaciones = (arrayObservaciones: any[], observaciones: string,
    EstadoRequerimientosSeleccionado: any, vUser: any, Esdlp: boolean) => {
    arrayObservaciones.push({
        fecha: GetFechaServidor().format(formatableJson_HHMMss),
        observacion: observaciones,
        usuario: vUser.Nombres,
        estado: `${EstadoRequerimientosSeleccionado.tipo} - ${EstadoRequerimientosSeleccionado.label}`,
        Esdlp: Esdlp
    })

}
export const GetCabeceraOP = (ObjetoCabecera: any, FechaOP:string, Tecnico : any) =>{
    ObjetoCabecera["FechaProgramacionOP"] = String(FechaOP);
    ObjetoCabecera["Tecnico"] = JSON.stringify(Tecnico);
 }
export const GetObservaciones = (arrayObservaciones: any[], observacion: string, EstadoRequerimientosSeleccionado: any, vUser: any, Esdlp: boolean, EsImagen:boolean|null|undefined) => {
    arrayObservaciones.push({
        fecha: GetFechaServidor().format(formatableJson_HHMMss),
        observacion: observacion,
        usuario: vUser.Nombres,
        estado: `${EstadoRequerimientosSeleccionado.tipo} - ${EstadoRequerimientosSeleccionado.label}`,
        Esdlp: Esdlp,
        EsImagen:(EsImagen == undefined || EsImagen == null ? false:EsImagen)
    })

}


export const ActualizaDatosRequerimientos = (DatosTabla: any[], Requerimiento: any, ReqActualizado: any) => {
    return DatosTabla.map((val: any) => {
        if (val.Id == Requerimiento.original.Id) {
            val.Cabecera = ReqActualizado['Cabecera'];
            val.Estado = ReqActualizado['Estado'];
            val.Observaciones = ReqActualizado['Observaciones'];
        }
        return val;
    });
}


export const NotificarRequerimiento = (UsuarioId: any, Id: string, descripcion: string) => {

    let dataNotificacion : any = {};
    dataNotificacion['UsuarioId'] = UsuarioId;
    dataNotificacion['RequerimientoId'] = Id;
    dataNotificacion['Descripcion'] = descripcion;
    dataNotificacion['NotificarCorreo'] = NotificarCorreo;
    dataNotificacion['NotificarPortal'] = NotificarPortal;
    FiltroData.Notificar(dataNotificacion)
}

export const GetDatosRequerimientos = (Id: string, cabecera: any, _obs: any[], EstadoReq: any) => {

    let Campos: any  = {};
    Campos["Cabecera"] = JSON.stringify(cabecera);
    Campos["Observaciones"] = JSON.stringify(_obs);
    Campos["Estado"] = JSON.stringify({
        tipo: EstadoReq.tipo, label: EstadoReq.label, valor: EstadoReq.valor,
        tipoestado: EstadoReq.tipoestado, color: EstadoReq.color
    });
    Campos["Id"] = Id;

    return Campos;
}

export async function DescargarFileBase64Postventa(nombrearchivo: string) {
    return axios({
      method: 'get',
      url: ARCHIVO_DownloadFileBase64,
      params: { nombrearchivo, container: 'postventa' }
  
    })
  }

 export const GuardarArchivoValidacion = (nombre:any,contenedor:any,src:any, archivo:any ) => {
    const formData = new FormData();
    formData.append("archivo", archivo);
    formData.append("src", src);
    formData.append('nombre', ``);
    formData.append('contenedor', contenedor);
    
  return axios({
        method: 'post',
        url: NEP_UploadFile,
        data: formData ,
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {"datos":formData  }
    });
};

export const EliminarArchivoServicio = (nombre:any,contenedor:any,src:any) =>{
    const formData = new FormData();
    formData.append("src", src);
    formData.append('nombre', nombre);
    formData.append('contenedor', contenedor);
  return axios({
        method: 'post',
        url: ARCHIVO_DeleteFileBase64,
        data: formData ,
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {"datos":formData  }
    });
};


const DescargarImagen = (Nombre:any) =>{
    DescargarFileBase64Postventa(Nombre).then((response:AxiosResponse<any>) =>{
        descargarImagen(response.data, Nombre)
    }).catch((error:AxiosError<any>)=>{
        console.log(error);
    });
}

const descargarImagen = (base64:any, nombreArchivo:string) => {
    const enlace = document.createElement('a');
    enlace.href = `data:application/pdf;base64,${base64}`; //base64;
    enlace.download = nombreArchivo;

    // Simular un clic en el enlace
    document.body.appendChild(enlace);
    enlace.click();
    document.body.removeChild(enlace);
    // Crear un enlace
}


export const getTablaDetalleRequerimiento = (datos: any, UserActual: any) => {

   // const[dataDetalles, setDataDetalles] = useState<any[]>(datos);
    return (
        <>
            {/* begin::Table */}
            <div className="table-responsive border rounded" style={{
                maxHeight: "300px",
                overflowY: "auto"
            }}>
                <table className="m-2 table table-borderless align-middle ">
                    <thead style={{
                        position: "sticky",
                        top: "0"
                    }}>
                        <tr className="m-2 bg-white">
                            <th className="mx-2 p-0 w-100px"> <span className="text-syscaf-azul fw-bolder text-hover-primary fs-5">
                                Fecha
                            </span></th>
                            <th className="p-0 min-w-150px"> <span className="text-syscaf-azul fw-bolder text-hover-primary fs-5">
                                Observaciones
                            </span></th>
                            <th className="p-0 min-w-120px"> <span className="text-syscaf-azul fw-bolder text-hover-primary fs-5">
                                Estado
                            </span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {datos.sort((a: any, b: any) => moment(b.fecha).toDate().getTime() - moment(a.fecha).toDate().getTime()).map((data: any, index: any) => {
                            const preEstado: string = data.estado?.split('-')[0]?.trim();
                          
                             
                            return (<tr key={`key${data.fecha}_${index}`} >

                                <td>{moment(data.fecha).format(FormatoColombiaDDMMYYYHHmm)}</td>
                                <td className="px-0">
                                    <span className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                        {data.usuario} dice:
                                    </span>
                                    <span className="text-muted fw-bold d-block mt-1">
                                        {(data.EsImagen != true  ? data.observacion: <a className="pe-auto" onClick={(e:any)=>{
                                           let nombre = (data.observacion != "Ok" ? data.observacion.split("/"):data.observacion);
                                           
                                           const lastElement = nombre[nombre.length - 1];
                                           const el = nombre[nombre.length - 2].replace("%24", "$");
                                        
                                           DescargarImagen(`${el}/${lastElement}` )
                                        }}>
                                            {
                                                data.observacion.split("/")[data.observacion.split("/").length - 1]
                                            }
                                        </a>)} 
                                    </span>
                                </td>
                                <td className="">
                                    <span className="text-gray-800 fw-bolder d-block fs-6">
                                        {data.estado}
                                    </span>

                                </td>

                            </tr>)
                        })}


                    </tbody>
                </table>
            </div>
            {/* end::Table */}
        </>
    )
}


//Espacios pAra las Columnas
//PARA LOS DETALLES
export const Detalles: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'fecha',
            header: 'Fecha',
            Cell({ row, }) {
                return moment(row.original.fecha).format(FormatoColombiaDDMMYYYHHmm);
            }
        },
        {
            accessorKey: 'observacion',
            header: 'Observación',
            Cell({ row, }) {
                return (<><a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                    Brad Simmons
                </a>
                    <span className="text-muted fw-bold d-block mt-1">
                        {row.original.observacion}
                    </span></>)
            }


        },
        {
            accessorKey: 'usuario',
            header: 'Usuario',
        },

        {
            accessorKey: 'estado',
            header: 'Estado',
            Cell({ cell, column, row, table, }) {
                return FiltroData.getEstadosJson((row.original.estado != undefined ? row.original.estado : ""));
            },
        }
    ];
    export const EstadosActivosRequerimientos = (Data: any[]) => {
        return Data.reduce((p:any, c:any) =>{
            let name = JSON.parse(c.Estado).tipo;
            p[name] = p[name] ?? [];
            p[name].push(c);
            return p;
        },[])
    }

  