import { Button, FormLabel } from "react-bootstrap-v5";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../../_start/helpers/components/HtlmTooltip";
import { getPlantillas, setPlantillas, TiposPlantillas, Tooltips } from "../../../data/dataConfiguracion";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useEffect, useState } from "react";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { Autocomplete, Box, createFilterOptions, IconButton, TextField, Tooltip } from "@mui/material";
import { Check } from "react-feather";
import { Edit, FormatListNumbered } from "@mui/icons-material";
import { AxiosError, AxiosResponse } from "axios";
import { useToaster } from "rsuite";
import { MensajeError } from "../../../../../../../_start/helpers/components/Toaster";
import { useDataConfiguracion } from "../../../core/ConfiguracionCore";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { CargaListadoClientes } from "./ComponenteCliente";
import { preop_columnasplantillas } from "../../../data/dataConfiguracionPlantilla";

export const Plantillas: React.FC = () => {
    const { datosPreoperacional, setclienteSeleccionado, clienteSeleccionado, showObject, setshowObject, model,
        setPlantillaSel, lstPlantillas
    } = useDataConfiguracion();
    const toaster = useToaster();
    const [data, setData] = useState<any[]>([]);
    const [showNuevo, setShowNuevo] = useState<boolean>(false);
    const [Inactivo, setInactivo] = useState<boolean>(false);
    const pl = {
        ClienteId: clienteSeleccionado?.ClienteId ,
        Nombre: "",
        Observaciones: "",
        UsuarioId: model.Id,
        EsActivo: "true",
        Clave: "1",
        PlantillaId : "0",
        TipoPlantilla : null

    };
    const [TipoPlantilla, setTipoPlantilla] = useState<any[]>([])
    const [Plantilla, setPlantilla] = useState<any>(pl)
    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    useEffect(() => {
        setData(lstPlantillas)
        setTipoPlantilla(TiposPlantillas(lstPlantillas))
    }, [lstPlantillas])

    const EditarPlantilla = (row: any) => {

        row.original.Clave = "2";
        setPlantilla(row.original);
    };

    const InactivarPlantilla = (row: any) => {
        row.original.Clave = "3";
        row.original.EsActivo = (row.original.EsActivo == true ? false : true);
        confirmarDialog(() => {
            setPlantillas(row.original).then((response: AxiosResponse<any>) => {
                successDialog(response.data[0].Mensaje, "");
                setData(response.data);
            }).catch((error: AxiosError<any>) => {
                errorDialog("Ha sucedido un error al guardar la plantilla", "");
            });
        }, `Esta seguro que desea cambiar el estado a la plantilla`, 'Guardar');
    }
    const clearForm = () => {

    }

    const Nuevo = () => {
        setPlantilla(pl);
        setInactivo(false);
        setShowNuevo(true)
    }

    const Guardar = () => {
        confirmarDialog(() => {
            Plantilla.UsuarioId = model.Id;
          
            setPlantillas(Plantilla).then((response: AxiosResponse<any>) => {
                successDialog(response.data[0].Mensaje, "");
                setData(response.data);
            }).catch((error: AxiosError<any>) => {
                errorDialog("Ha sucedido un error al guardar la plantilla", "");
            });
        }, `Esta seguro que desea guardar la plantilla`, 'Guardar');
    }
    // permite adicionar al listado cuando no exista el parametro
    const filter = createFilterOptions<string>();
    return (<>

        <div className="form-horizontal  mx-auto">
            <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Cerrar"].title, Tooltips["Cerrar"].content)} >
                <Button type="button" className="bt btn-sm" variant="secondary" onClick={() => {
                    setshowObject({ ...showObject, plantillas: false, panelCentral: true });
                }}>
                    Cerrar
                </Button>
            </HtmlTooltipStyled>
            <div className="ms-3 text-center">
                <h4 className="mb-0">Configuración de Plantillas Clientes Preoperacional</h4>
                <span className="text-muted m-3">Gestión de plantillas y preguntas para el preoperacional movil</span>

            </div>
        </div>
        <hr className="m-2" />
        <div className="d-flex justify-content-start gap-4" style={{ width: '50%' }}>
            <FormLabel className="fs-4 mx-4 mt-2">Cliente:</FormLabel>
            {CargaListadoClientes(datosPreoperacional, setclienteSeleccionado, clienteSeleccionado)}

        </div>
        <hr className="m-2" />

        <div className="card shadow-sm p-2">
            <div className={`d-flex justify-content-end ${(showNuevo) && 'd-none'}`}>
                <Button size="sm" onClick={() => Nuevo()}>Nueva Plantilla</Button>
            </div>
            <div className="container primary" hidden={!showNuevo}>
                <div className="row">
                    <div className="col-6">
                        <label className="control-label label label-sm fw-bolder">Nombre</label>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1"><i className="bi-activity"></i></span>
                            <input className="form-control input input-sm" type="text" placeholder="Nombre de plantilla" value={Plantilla.Nombre} onChange={(e: any) => {
                                let p = { ...Plantilla };
                                p.Nombre = e.currentTarget.value;
                                setPlantilla(p);
                            }} />
                        </div>
                    </div>
                    <div className="col-6">
                        <label className="control-label label label-sm fw-bolder" htmlFor="check_EsActivo" >Estado</label>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1"><i className="bi-check"></i></span>
                            <input checked={Plantilla.EsActivo} disabled={Inactivo} id="check_EsActivo" type="checkbox" onChange={(e: any) => {
                                let p = { ...Plantilla };
                                p.EsActivo = e.currentTarget.checked;
                                setPlantilla(p);
                            }} />
                        </div>
                    </div>
                    <div className="col-6 mt-5">
                        <label className="control-label label label-sm fw-bolder">Observación</label>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1"><i className="bi-chat-right-text"></i></span>
                            <textarea className="form-control input input-sm" style={{ height: '73px' }} rows={2} cols={1} placeholder="observación de plantilla" value={Plantilla.Observaciones} onChange={(e: any) => {
                                let p = { ...Plantilla };
                                p.Observaciones = e.currentTarget.value;
                                setPlantilla(p);
                            }} />
                        </div>
                    </div>

                    <div className="col-6 mt-5">
                        <label className="control-label label label-sm fw-bolder" >Tipo plantilla</label>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1"><i className="bi-check"></i></span>
                            <Autocomplete className="form-control input input-sm" value={TipoPlantilla.filter(f => f == Plantilla.TipoPlantilla)} onChange={(event, newValue) => {

                                if (newValue != null && newValue != "") {
                                    const isExist = TipoPlantilla.filter(f => f == newValue);
                                    if (isExist.length == 0) {

                                        let t = [...TipoPlantilla];
                                        t.push(newValue)
                                        setTipoPlantilla(t);

                                        let p = { ...Plantilla };
                                        p.TipoPlantilla = newValue;
                                        setPlantilla(p);
                                    }
                                    else {
                                        toaster.push(MensajeError('Adicionar tipo de plantilla', "El tipo ya se encuentra asignado"), { placement: 'topCenter' })
                                        clearForm();
                                    }

                                }

                            }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options as string[], params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push(inputValue);
                                    }

                                    return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys

                                options={TipoPlantilla?.map((element: any) => element)}
                                getOptionLabel={(option: any) => {
                                    // // Value selected with enter, right from the input
                                    // if (typeof option === 'string') {
                                    //   return option;
                                    // }
                                    // Add "xxx" option created dynamically
                                    if (option) {
                                        return option;
                                    }
                                    // Regular option
                                    return option;
                                }}
                                renderOption={(props, option) => <li {...props}>{option}</li>}

                                freeSolo
                                renderInput={(params) => (
                                    <TextField {...params} label={(Plantilla.TipoPlantilla == null || Plantilla.TipoPlantilla == undefined ? "Escoja el nuevo tipo" : Plantilla.TipoPlantilla)} />
                                )}

                            />
                        </div>

                    </div>

                </div>

            </div>
            <div className={`d-flex justify-content-center gap-4 mt-2 ${(!showNuevo) && 'd-none'}`}  >
                <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Guardar"].title, Tooltips["Guardar"].content)} >
                    <Button type="button" className="btn btn-sm float-end" variant="primary" onClick={Guardar}>
                        Guardar
                    </Button>
                </HtmlTooltipStyled>

                <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Nuevo"].title, Tooltips["Nuevo"].content)} >
                    <Button type="button" className="btn btn-sm float-end" variant="secondary" onClick={Nuevo}>
                        Cerrar
                    </Button>
                </HtmlTooltipStyled>

            </div>
        </div>
        <div className="row">
            <div className="mt-5">
                {(data.length > 0 ? <MaterialReactTable
                    enableColumnResizing
                    localization={MRT_Localization_ES}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={preop_columnasplantillas}
                    data={data}
                    enableTopToolbar={false}
                    enableColumnOrdering
                    enableEditing
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    enablePagination={false}
                    enableStickyHeader
                    enableDensityToggle={false}
                    enableRowVirtualization
                    muiTableContainerProps={{
                        sx: { maxHeight: '600px' }, //give the table a max height
                    }}
                    state={{
                        columnFilters,
                        globalFilter,

                        pagination,
                        sorting,
                    }}
                    renderRowActions={({ row, table }) => (
                        <>
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <Tooltip arrow placement="left" title="Editar">
                                    <IconButton
                                        onClick={() => {
                                            EditarPlantilla(row);
                                            setInactivo(true);
                                        }}
                                    >
                                        <Edit className="text-success" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip arrow placement="left" title="Configurar Preguntas">
                                    <IconButton
                                        onClick={() => {
                                            setshowObject({ ...showObject, plantillas: false, preguntas: true })
                                            setPlantillaSel(row.original);
                                        }}
                                    >
                                        <FormatListNumbered className="text-primary" />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip arrow placement="left" title="Inactivar Plantilla">
                                    <IconButton
                                        onClick={() => {
                                            InactivarPlantilla(row);
                                        }}
                                    >
                                        <Check className="text-danger" />
                                    </IconButton>
                                </Tooltip>

                            </Box>
                        </>
                    )
                    }
                /> : <div className="d-flex justify-content-center" role="status">
                    <div className="spinner-border">
                        <span className="visually-hidden fs-3 fw-bold">Cargando plantillas ...</span>
                    </div>
                </div>)}

            </div>
        </div>



    </>)
}