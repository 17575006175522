import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useState } from "react";
import { Card, Form, Tab } from "react-bootstrap-v5";
import { CamposEventos } from "../../../data/Configuracion";
import confirmarDialog, { errorDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";

import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { DeserializarJSon } from "../../../../../../../_start/helpers/Helper";
type Props = {
    row: any;
    eventos: any[];
    objetoGuardar: any;
    setObjetoGuardar: any;
};

export const TabEventos: React.FC<Props> = ({ row, eventos, objetoGuardar, setObjetoGuardar }) => {

    const [ValorSelectEvent, setValorSelectEvent] = useState<any>({});
    const [DataEventos, setDataEventos] = useState<any[]>(objetoGuardar.condicion == '' ? [] : DeserializarJSon(objetoGuardar.condicion) );
    const [isError, setIsError] = useState(false);
    const [rowCount3, setRowCount3] = useState(0);


    /* table state*/
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    // control de listado de eventos
    function CargaListadoEventos() {
        return (
            <Form.Select className=" mb-3 " style={{ width: 300 }} onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let evento = eventos.filter((value, index) => {
                    return value.EventId === e.currentTarget.value//Number.parseInt(e.currentTarget.value)
                })
                let eventoSeleccionado = (evento.length != 0 ? evento[0] : {})
                setValorSelectEvent(eventoSeleccionado);
                // seteventoSeleccionado(eventoSeleccionado)
            }} aria-label="Default select example" value={ValorSelectEvent.EventId}>
                <option>Seleccione</option>
                {
                    eventos?.map((element, i) => {
                        // let flag = (element.EventId === eventoSeleccionado?.EventId)
                        return (<option key={element.EventId} value={element.EventId}>{element.Descripcion}</option>)
                    })
                }
            </Form.Select>
        );
    }
    // agrega a la pesta;a de eventos los que se van a utilizar 
    const AgregarEventos = (Evento: any) => {

        let Event = [...DataEventos];
        // let EventNombres = [...EventosActivosNombres];
        let esta = DataEventos.findIndex((e: any) => e.EventId == ValorSelectEvent.EventId);
        // let nesta = EventosActivosNombres.includes(NombreSelectEvent);
        if (esta != -1)
            errorDialog("El evento ya se encuentra agregado.", "");
        else {
            Event.push(ValorSelectEvent);
            setDataEventos(Event);
            setObjetoGuardar({...objetoGuardar,condicion : JSON.stringify(Event) }) // persistimos loss datos
        }
    };


    const EliminarEvento = (row: any) => {
        let EventosData = [...DataEventos];
        let index = EventosData.findIndex((e: any) => e.EventId === row.original.EventId);
        confirmarDialog(() => {
            if (index != -1) {
                EventosData.splice(index, 1);
                setDataEventos(EventosData);
                setObjetoGuardar({...objetoGuardar,condicion : JSON.stringify(EventosData) }) // persistimos loss datos
            }
        }, `Esta seguro que desea quitar el evento ${row.original.Descripcion} de la lista`, 'Eliminar');
    }

    return         <Card className="border border-gray-300 mt-2 shadow-sm">
            <span className="card-title fw-bolder rounded   bg-secondary fs-5 m-1 text-center border-bottom">Configuración Eventos</span>

            <div className="row m-5 mt-1">
                <div className="d-flex justify-content-start">
                    <label className="control-label label-sm fw-bolder mx-2 mt-4" htmlFor="evento">Evento:</label>
                    <div className="input-group " style={{ width: 400 }}>
                        <span className="input-group-text mb-3 "><i className="fas fa-book"></i></span>
                        <CargaListadoEventos />
                        {/* Aqui es el boton de guardar eventos */}
                        <button className="btn btn-sm btn-success mb-3 " type="button" title="Agregar mas eventos" name="eventos"
                            value={1} onClick={(e: any) => { AgregarEventos(e); }}> <i className="fas fa-caret-right"></i></button>
                    </div>
                </div>
                <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12">
                    {
                        (DataEventos.length > 0) && (
                            <MaterialReactTable
                                localization={MRT_Localization_ES}
                                displayColumnDefOptions={{
                                    'mrt-row-actions': {
                                        muiTableHeadCellProps: {
                                            align: 'center'
                                        }
                                    },
                                }}
                                columns={CamposEventos}
                                data={DataEventos}
                                muiTableBodyCellProps={({ row, cell }) => ({

                                    sx: {

                                        backgroundColor:

                                            cell.column.id == 'Min Rojo' ? 'rgba(248, 215, 218, 1)' :
                                                cell.column.id == 'Max Verde' ? 'rgba(212, 237, 218, 1)' :
                                                    'rgba(255, 243, 205, 1)'
                                    }

                                })}
                                enableTopToolbar
                                enableColumnOrdering
                                enableFilters
                                enablePagination={false}
                                enableColumnFilters={false}
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                            color: 'error',
                                            children: 'Error al cargar información',
                                        }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onGlobalFilterChange={setGlobalFilter}
                                onPaginationChange={setPagination}
                                onSortingChange={setSorting}
                                enableEditing
                                rowCount={DataEventos.length}
                                initialState={{ density: 'compact' }}
                                renderRowActions={({ row, table }) => (
                                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                                        <Tooltip title="Quitar evento" >
                                            <IconButton color="error" onClick={() => EliminarEvento(row)}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                                state={{
                                    columnFilters,
                                    globalFilter,
                                    isLoading,
                                    pagination,
                                    showAlertBanner: isError,
                                    showProgressBars: isRefetching,
                                    sorting,
                                }}
                            />
                        )
                    }
                </div>
            </div>
        </Card>

}