
import {  Tab, Tabs } from "react-bootstrap-v5";
import { NuevoUsuario } from "../../../data/usuariosdata";
import { useDataUsuarios } from "../../../core/usuariosProvider";
import { TabSeguridad } from "./TabSeguridad";
import { Eco_TabModuloyRoles } from "./TabModulos";
import { ECO_TabClaims } from "./TabClaims";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../setup";
import { UserModelSyscaf } from "../../../../../auth/models/UserModel";
import { PerfilSuperAdmin } from "../../../../../SYSCAF/PostVenta/data/Requerimientos";
import { TabHistorialUsuario } from "./TabHistorialUsuario";
import { IconButton, Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

export const NuevoRegistro: React.FC<{ show: boolean, setshow: any }> = ({ show, setshow }) => {
    const { usuarioSel, setUsuarioSel } = useDataUsuarios();
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;
    return (
        <div className="container mt-2 mb-2 bg-white">
            <div className="m-2 d-flex justify-content-start gap-3">
                <Tooltip title={'Regresar a Listado'}>
                    <IconButton onClick={() => {
                        setUsuarioSel(NuevoUsuario);
                        setshow(false);
                    }}  ><ArrowBack /></IconButton>
                </Tooltip>

                <span className="text-muted mt-2">Usuario:</span>
                <span className="text-muted mt-2">{usuarioSel?.Nombres} {usuarioSel?.Apellidos}</span>

            </div>

            <Tabs className="rounded" defaultActiveKey={'seguridad'} >
                <Tab className="border rounded" eventKey='seguridad' title='Información Básica'>
                    <TabSeguridad show={show} setshow={setshow} />
                </Tab>


                {(usuarioSel.Id !== undefined) && (<Tab eventKey='modulos' title='Asignar Módulos-Roles'>
                    <Eco_TabModuloyRoles /></Tab>
                )}

                {(usuarioSel.Id !== undefined) && (<Tab eventKey='historial' title='Historial Accesos'>
                    <TabHistorialUsuario />
                </Tab>)}
                {(usuarioSel.Id !== undefined && vUser.perfil === PerfilSuperAdmin) && (<Tab eventKey='claims' title='Claims'>
                    <ECO_TabClaims />
                </Tab>)}



            </Tabs>


        </div>

    )

}