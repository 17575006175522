import { MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import { Post_GetConsultasDinamicasDWH } from "../../../../../_start/helpers/Axios/DWHService";
import { FormatoColombiaDDMMYYYHHmmss } from "../../../../../_start/helpers/Constants";
import { formatFechasView, locateFormatPercentNDijitos } from "../../../../../_start/helpers/Helper";


export function GetDetallePeticiones(mes: number, anio: number) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Mes'] = `${mes}`;
    params['anio'] = `${anio}`;

    return Post_GetConsultasDinamicasDWH({
        NombreConsulta: "GetDetallePeticiones", Clase: "PORTALQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};


export const ECO_camposDetallado: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'NombreNormalizado',
            header: 'Cliente'
        },
        {
            accessorKey: 'Method',
            header: "Consulta"
        }
        , {
            accessorKey: 'Response',
            header: 'Response'
        }, {
            accessorKey: 'FechaSistema',
            header: 'Fecha',
            Cell({ cell, column, row, table, }) {
                return moment(row.original.FechaSistema).format(FormatoColombiaDDMMYYYHHmmss);
            }
        }, {
            accessorKey: 'Servidor',
            header: "Servidor",
            Cell({ cell, column, row, table, }) {
                return row.original.Servidor === 1 ? 'ZA' : 'US';
            }
        }, {
            accessorKey: 'UserId',
            header: "UserId"
        }
    ];
;


export const ECO_ContadorPeticiones: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'NombreNormalizado',
            header: 'Cliente'
        },
        {
            accessorKey: 'Method',
            header: "Consulta"
        }
        , {
            accessorKey: 'Total',
            header: 'Total'
        }, {
            accessorKey: 'Positivas',
            header: 'Positivas'
        }, {
            accessorKey: 'Negativas',
            header: "Negativas"
        }, {
            header: "Efectividad",
            Cell({ cell, column, row, table, }) {
                return locateFormatPercentNDijitos(row.original.Positivas / row.original.Total, 2 )
            }
        }
    ];
;

