import { useEffect, useState } from "react";
import { Button, Modal, Tab, Table, Tabs } from "react-bootstrap-v5";
import confirmarDialog from "../../../../../../_start/helpers/components/ConfirmDialog";
import { GetEventosPorCliente, GetPreferenciasDescargar, initNuevoEvento, ModalEditar_EventosModulo } from "../../data/Clientes";
import { Close, Delete, Edit } from "@mui/icons-material";
import { DeserializarJSon, EsJson } from "../../../../../../_start/helpers/Helper";
import { Autocomplete, Box, IconButton, TextField, Tooltip } from "@mui/material";
import { CargandoSyscaf } from "../../../../../../_start/helpers/components/Cargando";
import { useToaster } from "rsuite";
import Swal from "sweetalert2";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MensajeError, MensajeSuccess } from "../../../../../../_start/helpers/components/Toaster";
import { FiltroClientesModulos, SetPreferenciasDescargarPorCliente, SetPreferenciasDescargarPorClienteCORE } from "../../data/ConsultasClientes";
import { AxiosError, AxiosResponse } from "axios";

type props = { showModal: boolean, setShowModal: any, ClienteSeleccionado: any, plantillaModulos: any }

export const ModalEditarCliente: React.FC<props> = ({ showModal, setShowModal, ClienteSeleccionado, plantillaModulos }) => {
    const toaster = useToaster();

    // filtramos la plantillas por las que tendr'ia acceso cada cliente  y mezclamos la info para armar la tbla
    const plantillaFiltradas = plantillaModulos?.filter((f: any) => f.clienteIds === null || f.clienteIds == ClienteSeleccionado.clienteIdS);


    // actualizamos los parameros sistemas
    const [datosModificar, setDatosModificados] = useState<any>();
    const [configurar, setConfigurar] = useState<boolean>(false);
    const [datosConfModulo, setdatosConfModulo] = useState<any>({});
    const [datosEventos, setDatosEventos] = useState<any[]>([]);
    const [datosEventosSeleccionados, setDatosEventosSeleccionados] = useState<any[]>([]);
    const [cargandoEventos, setCargandoEventos] = useState<boolean>(false);

    // mezclamos la información con la que se encuentra almacenada en el registro

    useEffect(() => {
        // usamos la misma informacion actualizada con la de los campos
        // para facilitar la transisci'on de los datos
        const paramsSistemas: any = {
            ...DeserializarJSon(ClienteSeleccionado.ParamsSistema),
            Trips: ClienteSeleccionado.Trips ? 1 : 0,
            GeneraIMG: ClienteSeleccionado.GeneraIMG ? 1 : 0,
            Metrics: ClienteSeleccionado.Metrics ? 1 : 0,
            Event: ClienteSeleccionado.Event ? 1 : 0,
            Position: ClienteSeleccionado.Position ? 1 : 0,
            ActiveEvent: ClienteSeleccionado.ActiveEvent ? 1 : 0,
            notificacion: ClienteSeleccionado.notificacion ? 1 : 0

        }
        // deserializamos los modulos del cliente
        const modulos: any[] = ClienteSeleccionado.Modulos != null && EsJson(ClienteSeleccionado.Modulos) ? [...DeserializarJSon(ClienteSeleccionado.Modulos)] : [];
        setDatosModificados({ ...ClienteSeleccionado, paramsSistemas, ModuloActuales: modulos });

    }, [])

    const ConfiguarModulo = (config: any) => {

        let modulos: string | null = null;
        let datosCliente: any = { ...datosModificar };
        let paramsSistemas: any = { ...datosModificar.paramsSistemas };

        if (ClienteSeleccionado.Modulos === null)
            modulos = (config.modulos);
        else

            if (EsJson(config.modulos)) {
                let modulosParam = DeserializarJSon(config.modulos); // deserializamos los modulos actuales
                let moduloss = datosCliente.ModuloActuales.
                    filter((f: any) => modulosParam.filter((ff: any) => ff.modulo.trim() == f.modulo.trim()).length > 0);
                // verificamos que exista la propiedad necesaria para el modulo, si no se coloca el por defecto
                // dependera de la logica si se habilita o no el guardado de la tabla en una tabla especifica
                modulos = JSON.stringify(moduloss.length == 0 ? modulosParam : moduloss);
                if (moduloss.length == 0) // si no existen en los modulos actuales del cliente deben adicionarse a los modulos actuales
                    Array.apply(datosCliente.ModuloActuales, modulosParam ?? [])
            }
            else{
                modulos = JSON.stringify(datosCliente.ModuloActuales);
            }

        // se deberan en los modulos del cliente adicionar estos componentes


        // revisamos si tiene parametros especiale el modulos
        const nombreConfiguracion = `${config.configField}_conf`; // nomnbre de la propiedad que lleva si tiene algina confiuración especial
        paramsSistemas[nombreConfiguracion] = paramsSistemas[nombreConfiguracion] ?? DeserializarJSon(config.paramsSistemas)[nombreConfiguracion];

        setDatosModificados({
            ...datosCliente,
            paramsSistemas,
            modulos: EsJson(modulos) ? DeserializarJSon(modulos) : [], nombreConfiguracion
        })

        let datosPre = { ...ClienteSeleccionado.Modulos, ...config };
        setdatosConfModulo(datosPre);
        setConfigurar(true);

        // traemos los eventos del cliente seleccionado
        GetEventos(ClienteSeleccionado, config.tipoPreferenciaId);
        // traemos la información de la configuración avanzada si lo requiriese



    }
    const GuardarCamposCliente = () => {
        confirmarDialog(() => {

            const clienteGuardar = {
                Eventos: datosEventosSeleccionados,
                Modulos: FiltroClientesModulos.getModulosActualizados(datosModificar.ModuloActuales, datosModificar.modulos),
                paramsSistemas: { ...datosModificar.paramsSistemas }

            }
            SetPreferenciasDescargarPorCliente(ClienteSeleccionado.ClienteId, JSON.stringify(clienteGuardar))
                .then((response: AxiosResponse) => {
                    if(response.status ===200){
                        toaster.push(MensajeSuccess("Configurando modulo", response.statusText
                    ), { placement: 'topCenter' });
                    SetPreferenciasDescargarPorClienteCORE(ClienteSeleccionado.ClienteId, JSON.stringify(clienteGuardar));
                    }
                    

                }).catch((error: AxiosError) => {
                    toaster.push(MensajeError("Configurando modulo", error.message
                    ), { placement: 'topCenter' });
                })
            // // validamos los datos 
            console.log(clienteGuardar)
        }, `¿Esta seguro que desea guardar los cambios?`, 'Guardar');
    }

    const GetEventosSeleccionados = (eventos: any[], ClienteId: string, tipoPreferenciaId: number) => {
        setCargandoEventos(true)
        GetPreferenciasDescargar(String(ClienteId), String(tipoPreferenciaId)).then((response) => {
            if (response.status == 200) {
                // buscamos el  nombre del eventos de los eventos ya disponibles para el usuario
                // esto con el fin de no relantelizar la consulta de las escogidas
                // hay que tener en cuenta que el campo clienteids comparte con varios clientes es decir, un evento aplciar
                // para varios pues son eventos gen'ericos
                let datosRecibidos = response.data.map((m: any) => {
                    return { ...m, descriptionEvent: eventos.find((f => f.eventTypeId == m.EventTypeId))?.descriptionEvent }
                });
                setDatosEventosSeleccionados(datosRecibidos)
            }

            else {
                toaster.push(MensajeError("Configurando modulo", "Error a cargar eventos seleccionados"), { placement: 'topCenter' });
                setConfigurar(false);
            }
            setCargandoEventos(false)
        }).catch(() => setCargandoEventos(false));
    }
    const GetEventos = (ClienteId: any, tipoPreferenciaId: number) => {

        if (datosEventos.length == 0) {
            setCargandoEventos(true)
            GetEventosPorCliente(ClienteId.ClienteId).then((response) => {
                if (response.status == 200) {
                    setDatosEventos(response.data)
                    GetEventosSeleccionados(response.data, ClienteId.clienteIdS, tipoPreferenciaId);
                }
                else {
                    toaster.push(MensajeError("Configurando modulo", "Error a cargar eventos."), { placement: 'topCenter' });
                    setConfigurar(false);
                }
                setCargandoEventos(false)
            }).catch((error) => setCargandoEventos(false));

        }
        if (datosEventos.length > 0) {
            GetEventosSeleccionados(datosEventos, ClienteId.clienteIdS, tipoPreferenciaId);
        }

    }



    return <Modal show={showModal} onHide={setShowModal} size="lg">
        <Modal.Header closeButton>
            <div className="fw-bold fs-1 text-primary text-center" style={{ width: '100%' }}>
                
                <span className="text-syscaf-amarillo">Configuración Cliente : {ClienteSeleccionado.clienteNombre}</span>
                 </div>
        </Modal.Header>
        <Modal.Body>

            <div className="d-flex justify-content-center">

                <Table responsive striped bordered hover style={{ display: configurar ? "none" : "block" }}>
                    <thead>
                        <tr>
                            <th >Módulo</th>
                            <th>Activo</th>
                            <th>Inactivo</th>
                            <th>Configurar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Envío Correo Tx</td>
                            <td><input
                                type="checkbox"
                                checked={datosModificar?.notificacion}
                                onChange={(e) => {
                                    let paramsSistemas = datosModificar.paramsSistemas;
                                    paramsSistemas.notificacion = e.target.checked ? 1 : 0;
                                    setDatosModificados({ ...datosModificar, paramsSistemas, notificacion: e.target.checked })
                                }
                                }
                            /></td>
                            <td></td>
                            <td>
                            </td>
                        </tr>
                        {(datosModificar) && plantillaFiltradas.map((m: any) => (
                            <tr key={m.nombre}>
                                <td>{m.nombre}</td>
                                <td><input
                                    type="checkbox"
                                    checked={datosModificar.paramsSistemas[m.configField] == true}
                                    onChange={(e) => {

                                        const setCheck = () => {
                                            let paramsSistemas = datosModificar.paramsSistemas;
                                            paramsSistemas[m.configField] = e.target.checked ? 1 : 0;

                                            //    si es chequeado debemos internamente seleccionar lo que necesita para trabajar
                                            // de una configuramos los datos basicos de cada  cliente
                                            if (e.target.checked) {
                                                if (m.mix.length > 0) {
                                                    m.mix.forEach((fe: string) => {
                                                        datosModificar[fe] = true;
                                                        paramsSistemas[fe] = 1;

                                                    });
                                                }
                                            }
                                            setDatosModificados({ ...datosModificar, paramsSistemas: { ...paramsSistemas } });
                                        }
                                        // if (datosModificar.paramsSistemas[m.configField])
                                        //   confirmarDialog(setCheck, 'Al desactivar el módulo, se eliminarrá toda la configuración existente, está seguro que desea eliminarlo?','Si');
                                        // else
                                        setCheck();
                                        ConfiguarModulo(m)


                                    }}
                                /></td>
                                <td></td>
                                <td>
                                    {(datosModificar.paramsSistemas[m.configField] == 1) && (
                                        <IconButton onClick={() => {
                                            ConfiguarModulo(m)
                                        }}>
                                            <Edit className="text-danger" />
                                        </IconButton>)
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div className="d-flex justify-content-between">

                <span className="fw-bold fs-2" style={{ display: !configurar ? "none" : "block" }}>
                Configuración Módulo <span className="text-syscaf-amarillo text-uppercase">{datosConfModulo.nombre} </span> </span>

                <IconButton className="m-0" style={{ display: !configurar ? "none" : "block" }} onClick={() => {
                    setConfigurar(false);
                }}>
                    <Close className="text-danger" />
                </IconButton>

            </div>

            {(configurar) && (
                <Tabs
                    defaultActiveKey="eventos"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="eventos" title="Eventos">
                        <div className="d-flex align-items-start flex-column">
                            <span className="text-muted fs-6" > *Permite configurar los eventos necesarios para el funcionamiento del modulo, los eventos activos deben ser marcados para ser bien procesados</span>
                            <div className="card round shadow d-flex flex-row">
                                <label className="m-2 fs-5"> Eventos: </label>
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) => option.Id === value.Id}
                                    style={{ width: 300 }}
                                    className="mx-2 ms-2"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={datosEventos.map((m: any) => { return { Id: m.eventTypeId, label: m.descriptionEvent } })}
                                    renderInput={(params) => <TextField {...params} />
                                    }
                                    onChange={async (event, newValue: any) => {

                                        if (newValue != null && newValue != undefined && Object.keys(newValue).length > 0) {
                                            let isExist = datosEventosSeleccionados.filter(
                                                (f: any) => f.EventTypeId == newValue.Id
                                            );
                                            if (isExist.length == 0) {
                                                {
                                                    const { value: accept } = await Swal.fire({
                                                        title: "Datos Adicionales Evento",
                                                        input: "checkbox",
                                                        inputValue: 0,
                                                        inputPlaceholder: "Es un evento activo",
                                                        confirmButtonText: ` Continuar&nbsp;<i class="fa fa-arrow-right"></i>`
                                                    });

                                                    datosEventosSeleccionados.push({
                                                        ...initNuevoEvento, TipoPreferenciaId: datosConfModulo.tipoPreferenciaId,
                                                        EventTypeId: newValue.Id,
                                                        descriptionEvent: newValue.label, isActive: accept,
                                                        ClientesId: String(ClienteSeleccionado.clienteIdS)
                                                    });

                                                    setDatosEventosSeleccionados([...datosEventosSeleccionados])

                                                }

                                            } else
                                                toaster.push(MensajeError("Adicionar Evento", "El evento ya se encuentra en la lista"), { placement: 'topCenter' });

                                        }



                                    }}
                                />
                            </div>

                        </div>
                        <div className="card round mt-3 overflow-auto">
                            <MaterialReactTable
                                // tableInstanceRef={ColumnasTablas['movil']}
                                localization={MRT_Localization_ES}
                                displayColumnDefOptions={{
                                    'mrt-row-actions': {
                                        muiTableHeadCellProps: {
                                            align: 'center',
                                        },
                                    },
                                }}
                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        fontSize: 14,
                                        fontStyle: 'bold',
                                        color: 'rgb(27, 66, 94)'
                                    }),
                                }}
                                columns={ModalEditar_EventosModulo(datosEventosSeleccionados, setDatosEventosSeleccionados)}
                                data={datosEventosSeleccionados}
                                enableEditing
                                editingMode="modal" //default         
                                enableColumnOrdering

                                enableStickyHeader
                                enableDensityToggle={false}
                                enableRowVirtualization
                                enablePagination={false}

                                muiTableContainerProps={{
                                    sx: { maxHeight: '400px' }, //give the table a max height
                                }}
                                renderRowActions={({ row, table }) => (
                                    <Box sx={{ display: 'block', gap: '1rem', marginLeft: 'auto', marginRight: 'auto', alignItems: 'left', alignContent: 'left', textAlign: 'left' }}>
                                        <Tooltip arrow placement="top" title="Vehiculos del cliente">
                                            <IconButton style={{ display: !configurar ? "none" : "block" }} onClick={() => {
                                                const NoEliminados = datosEventosSeleccionados.filter((f: any) => f.EventTypeId != row.original.EventTypeId);
                                                setDatosEventosSeleccionados(NoEliminados);
                                            }}>
                                                <Delete className="text-danger" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                                enableExpandAll={false}
                            />

                        </div>
                    </Tab>
                    <Tab eventKey="configuracionTablaEventos" title="Configuración Tablas Eventos">
                        <div className="d-flex align-items-start flex-column">
                            <span className="text-muted fs-6" > *Permite configurar hacia que tablan van los eventos cuando se almacenan para un modulo</span>
                            <div className="card round shadow d-flex flex-row"></div>
                            <textarea rows={25} cols={100} defaultValue={
                                JSON.stringify(datosModificar.modulos, undefined, 4)
                            } onChange={(e) => {
                                if (EsJson(e.target.value))
                                    setDatosModificados({ ...datosModificar, modulos: DeserializarJSon(e.target.value) })


                            }}></textarea>
                        </div>
                    </Tab>
                    <Tab eventKey="configuracion" title="Configuración Avanzada">
                        <div className="d-flex align-items-start flex-column">
                            <span className="text-muted fs-6" > *Permite configurar aquellas propiedades especiales de cada cliente en formato json</span>
                            <div className="card round shadow d-flex flex-row"></div>
                            <textarea rows={25} cols={100} defaultValue={
                                JSON.stringify(datosModificar.paramsSistemas[datosModificar.nombreConfiguracion], undefined, 4)}
                                onChange={(e) => {

                                    if (EsJson(e.target.value)) {
                                        let paramsSistemas = datosModificar.paramsSistemas;
                                        paramsSistemas[datosModificar.nombreConfiguracion] = DeserializarJSon(e.target.value);
                                        console.log(paramsSistemas)
                                        setDatosModificados({ ...datosModificar, paramsSistemas })

                                    }
                                }} ></textarea>
                        </div>
                    </Tab>

                </Tabs>
            )}

            {(cargandoEventos) && (CargandoSyscaf("Cargando Eventos", ""))}
        </Modal.Body>
        <Modal.Footer>
            <Button type="button" variant="primary" onClick={() => {
                GuardarCamposCliente();
            }}>
                Guardar
            </Button>
            <Button type="button" variant="secondary" onClick={() => { setShowModal(false); }}>
                Cerrar
            </Button>
        </Modal.Footer>
    </Modal>
}