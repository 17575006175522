import { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../setup";
import { UserModelSyscaf } from "../../../auth/models/UserModel";


export interface ConfiguracionContextModel {
    Clientes: any,
    setClientes: (Clientes: any[]) => void;
    clienteSeleccionado: any,
    setclienteSeleccionado: (clienteSeleccionado: any) => void,
    loader: boolean;
    setloader: (loader: boolean) => void,
    Usuarios: any[],
    setUsuarios: (Usuarios: any[]) => void,
    datosPreoperacional: any[],
    setdatosPreoperacional: (clienteSeleccionado: any[]) => void,
    model: any,
    lstPlantillas: any[]
    setlstPlantillas: (lstPlantillas: any[]) => void,
    lstUsuarios: any[], setlstUsuarios: (Usuarios: any[]) => void,
    showObject: any
    setshowObject: (object: any) => void,
    plantillaSel: any
    setPlantillaSel: (object: any) => void,
}
const ServcContext = createContext<ConfiguracionContextModel>({
    loader: true,
    Clientes: [],
    setClientes: (Clientes: any[]) => { },
    clienteSeleccionado: {},
    setclienteSeleccionado: (clienteSeleccionado: any) => { },
    setloader: (loader: boolean) => { },
    Usuarios: [],
    setUsuarios: (Usuarios: any[]) => { },
    datosPreoperacional: [],
    setdatosPreoperacional: (clienteSeleccionado: any[]) => { },
    model: {},
    lstPlantillas: [],
    setlstPlantillas: (lstPlantillas: any[]) => { },
    lstUsuarios: [],
    setlstUsuarios: (Usuarios: any[]) => { },
    showObject: {},
    setshowObject: (showObject: any) => { },
    plantillaSel: {},
    setPlantillaSel: (showObject: any) => { }
})
export const ConfiguracionProvider: React.FC = ({ children }) => {

    // Traemos información del usuario Logueado
    const isAuthorized = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const model = (isAuthorized as UserModelSyscaf);
    const [Clientes, setClientes] = useState<any[]>([]);
    const [clienteSeleccionado, setclienteSeleccionado] = useState<any>();
    const [Usuarios, setUsuarios] = useState<any[]>([]);
    const [loader, setloader] = useState<boolean>(true);
    const [lstPlantillas, setlstPlantillas] = useState<any[]>([]);
    const [datosPreoperacional, setdatosPreoperacional] = useState<any[]>([]);
    const [showObject, setshowObject] = useState<any>({ panelCentral: true, configuracion: false, 
        usuarios: false, plantillas: false , preguntas:false,  assets : false, listas: false});
    const [lstUsuarios, setlstUsuarios] = useState<any[]>([]);
    const [plantillaSel, setPlantillaSel] = useState<any>({});
    const value: ConfiguracionContextModel = {
        Clientes,
        setClientes,
        clienteSeleccionado,
        setclienteSeleccionado,
        loader,
        setloader,
        setUsuarios,
        Usuarios,
        datosPreoperacional,
        model,
        lstPlantillas,
        setlstPlantillas, lstUsuarios, setlstUsuarios, setdatosPreoperacional, showObject, setshowObject,
        plantillaSel, setPlantillaSel
    }

    return (<ServcContext.Provider value={value}> {children} </ServcContext.Provider>);
};
export function useDataConfiguracion() {
    return useContext(ServcContext);
}

