import axios from "axios";
import { GetConfiguracionAlerta, Post_ExecProcedureByTipoConsulta, Post_getConsultasDinamicasConAuthCore, SetConfiguracionAlerta } from "../../../../../_start/helpers/Axios/CoreService";
import { Post_GetConsultasDinamicasDWH } from "../../../../../_start/helpers/Axios/DWHService";
import { DWH_getconsultadinamicasprocedure } from "../../../../../apiurlstore";
import { MRT_ColumnDef } from "material-react-table";
import { errorDialog } from "../../../../../_start/helpers/components/ConfirmDialog";
import moment from "moment";
import { FormatoColombiaDDMMYYYHHmmss } from "../../../../../_start/helpers/Constants";
export function getConfiguraciones(data: any) {
    var params: { [id: string]: string | null; } = {};
    params['nombre'] = data.nombre;
    params['clienteId'] = data.clienteId;
    params['esActivo'] = data.esActivo;
    return GetConfiguracionAlerta(params);
}
export function setConfiguraciones(data: any) {
    var params: { [id: string]: string | null; } = {};
    params['clave'] = data.clave;
    params['nombre'] = data.nombre;
    params['tiempo'] = data.tiempo;
    params['condicion'] = data.condicion;
    params['columna'] = data.columna;
    params['clienteId'] = data.clienteId;
    params['esActivo'] = data.esActivo;
    params['configuracionAlertaId'] = data.configuracionAlertaId;
    params['Detalles'] = data.Detalles;
    params['maxAmber'] = data.maxAmber;
    params['minAmber'] = data.minAmber;
    return SetConfiguracionAlerta(params);
}



export function GetEventos(Clienteid: string) {
    var params: { [id: string]: string | null | undefined; } = {};
    params['Clienteid'] = Clienteid;
    return Post_GetConsultasDinamicasDWH({
        NombreConsulta: "ObtenerEventosClienteFatiga", Clase: "FATGQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
};

export function GetEventosColumnas(Eventos: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["ListadoEventos"] = Eventos;
    return Post_GetConsultasDinamicasDWH({
        NombreConsulta: "GetEventosColumnas", Clase: "FATGQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}

export const FiltrosData = {
    getEventos: (data: any[], Cliente: any) => {
        return data.filter((D) => {
            if (D.clienteId == Cliente)
                return D;
        })
    },
}


export function setContactosAlertas(Id: string,ClienteId :string, Configuraciones: string, tipo: string) {
    var params: { [id: string]: string | null; } = {};
    params["id"] = Id.toString();
    params["ClienteId"] = ClienteId.toString();
    params["data"] = Configuraciones;
    params["tipo"] = tipo.toString();
    return axios({
        method: 'post',
        url: DWH_getconsultadinamicasprocedure,
        data: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json' },
        params: { Clase: "FATGQueryHelper", NombreConsulta: "setContactos" }
    });
}

export function setValoresAmber(Id: any, minAmber: any, maxAmber: any) {
    var params: { [id: string]: string | null | undefined; } = {};
    params["Id"] = Id.toString();
    params["minAmber"] = minAmber.toString();
    params["maxAmber"] = maxAmber.toString();
    return Post_GetConsultasDinamicasDWH({
        NombreConsulta: "setValoresAmber", Clase: "FATGQueryHelper",
        Pagina: null,
        RecordsPorPagina: null
    }, params)
}

//FILTROS
export const FiltrosParametros = {
   getEventos : (data:any, evento:any) =>{
    return data.map((f:any) =>{
        if(evento.includes(f.EventId)){
            return {"EventId":f.EventId,"Descripcion":f.Descripcion};
        }
    }).filter((e:any) =>e);
   }
}
//CONSTANTES PARA LAS TABLAS
export const CamposEventos: MRT_ColumnDef<any>[] =
[
    {
        accessorKey: 'Descripcion',
        header: 'Evento'
    },
];

// columnas para el dashboard de las alertas
export const CardContainercolumnas: MRT_ColumnDef<any>[]
= [
  {
    accessorKey: 'TipoAlerta',
    header: 'Alerta'},
  {
    accessorKey: 'vehiculo',
    header: 'Vehículo',
  },
  {
    accessorKey: 'conductor',
    header: 'Conductor',
  }, {
    accessorKey: 'EventDateTime',
    header: 'Fecha evento',
    Cell({ cell, column, row, table, }) {

      return (
        <>
          {
            moment(row.original.EventDateTime).format(FormatoColombiaDDMMYYYHHmmss)
          }
        </>

      )
    }
  }, {
    accessorFn: (row) => parseInt(JSON.parse(row.DetalladoEventos).length),
    id: 'DetalladoEventos',
    header: 'N. Eventos'
  }, {
    accessorKey: 'EstadoGestion',
    header: 'Estado',
    Cell({ cell, column, row, table, }) {
      return (cell.getValue() == null && row.original.EsGestionable) ? <span className="badge bg-danger">No Gestionado</span>
        : (cell.getValue() == 1 && row.original.EsGestionable) ? <span className="badge bg-success">Gestionado</span>
          : (cell.getValue() == 0 && row.original.EsGestionable) ? <span className="badge bg-primary">En Gestion</span>
            : (cell.getValue() == 2 && row.original.EsGestionable) ? <span className="badge bg-primary">Pendiente</span>
              : (!row.original.EsGestionable ? <span className="badge bg-info">No Gestionable</span> : <span>{row.original.EstadoGestion}</span>)
    }
  },
  {
    accessorKey: 'gestor',
    header: 'Analista',
    Cell({ cell, column, row, table, }) {
      return (cell.getValue() == null) ? <span>Sin Analista</span> : <span>{row.original.gestor}</span>
    }
  }

];


export const Campos: MRT_ColumnDef<any>[] =
[
    
    {
        accessorKey: 'esActivo',
        header: 'Estado',
        Cell({ cell, column, row, table, }) {
            return <span className={`badge bg-${row.original.esActivo ? "primary": "danger"}`}>{`${row.original.esActivo ? "Activo": "Inactivo"}`}</span>
        },
    },
    {
        accessorKey: 'nombre',
        header: 'Nombre'
    },
    {
        accessorKey: 'clienteNombre',
        header: 'Cliente'

    },
    {
        accessorKey: 'tiempo',
        header: 'Tiempo'
    },
    {

        header: 'Max Verde',
        Cell({ cell, column, row, table, }) {
            return (parseInt(row.original.minAmber) - 1)
        },
    },
    {
        accessorKey: 'minAmber',
        header: 'Min Amber',
    },
    {
        accessorKey: 'maxAmber',
        header: 'Max Amber'
    },
    {

        header: 'Min Rojo',
        Cell({ cell, column, row, table, }) {
            return (parseInt(row.original.maxAmber) + 1)
        },
    }
];

export const columnasContacto: MRT_ColumnDef<any>[]
= [
    {
        accessorKey: 'tipo',
        header: 'Tipo'
    },
    {
        accessorKey: 'nombre',
        header: 'Nombre'
    },
    {
        accessorKey: 'numero',
        header: 'Número'
    },
    {
        accessorKey: 'correo',
        header: 'Email'
    }

];
//VALIDA SI ES UN JSON O NO.
export const EsJson = (str:any) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
//CONST FUNCION VALIDAR CAMPOS
export const Validar = (NombeCondicion:any, EventosActivos:any) => {
   
    if (NombeCondicion == null || NombeCondicion == undefined || NombeCondicion == "") {
        errorDialog("Error el nombre es requerido", "");
        return false
    }
    if (EventosActivos == null || EventosActivos == undefined || EventosActivos.length == 0) {
        errorDialog("Error debe seleccionar por lo menos un evento", "");
        return false
    }
   
    return true;
}

export const CategoriasRiesgos = [{
    Nombre:"Critico", Valor:"Critico",
  
},{
    Nombre:"Mediano", Valor:"Mediano"
},{
    Nombre:"Bajo", Valor:"Bajo"
}]
export const ValidarTiempo = (Eventos:any, evento:any) =>
{
    if(Eventos.length > 1 && (evento == "" || evento == null || evento == undefined || evento=="0") ){
        errorDialog("Error no puede dejar el tiempo en cero si hay mas de un evento.", "");
        return false
    }
    return true;
}
//Para validar las condiciones
export const ValidarGuardar = (Eventos:any, Tiempo:any, Categoria:any) =>{
    if(Eventos.length == 1 && (Tiempo == "0" || Tiempo == "" || Tiempo == "" || Tiempo == undefined) && Categoria == "Todas"){
        errorDialog("Lo sentimos debe escoger una categoria a gestionar.", "");
        return false
    }
    return true;
}

//-------------------------------------- By Jan Michael ---------------------------------------------------------

export  function setConfiguracion(Sigla: string, Configuraciones: string , Movimientos: string, tipo: string ){
    var params: { [id: string]: string | null; } = {};
    params["sigla"] = Sigla;
    params["data"] = Configuraciones;
    params["movimientos"] = Movimientos;
    params["tipo"] = tipo;
    
    // hacemos la consulta 
    return  Post_ExecProcedureByTipoConsulta({    
      Clase : "FATGQueryHelper",  
      NombreConsulta: "setConfiguracionFatiga", 
      Pagina :null, 
      RecordsPorPagina :null}, 
      params);
};

export  function getConfiguracion(Sigla: string ){
  var params: { [id: string]: string | null; } = {};
  params["sigla"] = Sigla;

  // hacemos la consulta 
  return  Post_getConsultasDinamicasConAuthCore({    
    Clase : "FATGQueryHelper",  
    NombreConsulta: "getConfiguracionFatigaTorre", 
    Pagina :null, 
    RecordsPorPagina :null}, 
    params);
};


export  function getUsuarios(clienteId: string ){
  var params: { [id: string]: string | null; } = {};
  params["clienteId"] = clienteId;

  // hacemos la consulta 
  return  Post_getConsultasDinamicasConAuthCore({    
    Clase : "PortalQueryHelper",  
    NombreConsulta: "getUserByClientId", 
    Pagina :null, 
    RecordsPorPagina :null}, 
    params);
};

export function getClientes(clienteId: string ){
    var params: { [id: string]: string | null; } = {};
    params["clienteId"] = clienteId;
  
    // hacemos la consulta 
    return  Post_getConsultasDinamicasConAuthCore({    
      Clase : "FATGQueryHelper",  
      NombreConsulta: "getClientesPortal", 
      Pagina :null, 
      RecordsPorPagina :null}, 
      params);
  };

// tabla de la pestania de nueva alerta - Clasificación
  export const CamposColores: MRT_ColumnDef<any>[] =
  [
      {

          header: 'Max Verde',
          Cell({ cell, column, row, table, }) {
              return (parseInt(row.original.minAmber) - 1)
          },
      },
      {
          accessorKey: 'minAmber',
          header: 'Min Amber'
      },
      {
          accessorKey: 'maxAmber',
          header: 'Max Amber'
      },
      {

          header: 'Min Rojo',
          Cell({ cell, column, row, table, }) {
              return (parseInt(row.original.maxAmber) + 1)
          },
      }
  ];
