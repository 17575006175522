import { Help } from "@mui/icons-material";
import { IconButton, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import React from "react";

export const HtmlTooltipStyled = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export const HtmlContentTitle = (title: string, content: string) => {
    return (
        <React.Fragment>
            <Typography className="fs-4 text-center border-1 fw-bolder" color="inherit">{title}</Typography>
            <p className="text-muted fs-6" >{content}</p>
        </React.Fragment>
    )

}
// icono de ayuda en forma de interrogaci'on
export const HtmlTooltipHelp :React.FC<{title: string, content: string}>  = ({title, content}) => {
    return <HtmlTooltipStyled arrow
        title={HtmlContentTitle(title, content)
        } >
        <IconButton size="small"><Help /></IconButton>
    </HtmlTooltipStyled>

}
