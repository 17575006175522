import { AxiosError, AxiosResponse } from "axios"
import MaterialReactTable from "material-react-table"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap-v5"
import { GetSites, SetParametroSistema } from "../data/Clientes"
import { useToaster, Notification } from "rsuite";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Trash } from "react-feather"
import { Delete } from "@mui/icons-material"

type prop = {
    Data: any
}

export const ModalSystem: React.FC<prop> = ({ Data }) => {
    const [Datos, setDatos] = useState<any>(Data)
    const [Sitios, setSitios] = useState<any[]>([])
    const [Estados, setEstados] = useState<any>({"nuevo":true, "antiguo":false})
    let sitiodefault =  {
        "siteid": "",
        "sitename": ""
    } 
    const [Sitio, setSitio] = useState<any>(sitiodefault);
    const [sitioNuevo, setSitioNuevo] = useState<any>({siteid:"",sitename:"" });
    let Columnas = [
        {
            accessorKey: 'sitename',
            header: 'Sitio'
        }]
    const toaster = useToaster();
    const [DatosTabla, setDatosTabla]  = useState<any[]>([]);

    //USEEFFECTS
    useEffect(() => {
        setDatos(Data);
        ConsultarSitios(Data.ClienteId);
        setSitio(sitiodefault);
    }, [Data]);
    useEffect(() =>{
        setDatosTabla(Datos.DetallesDatos.fatiga_conf.Sites);
    },[Datos])
    //EVENTOS
    const changeSite = (e: any) => {
        let d = { ...Datos }
        d.DetallesDatos.fatiga_conf.IsSite = e.target.checked;
        setDatos(d);
    }
    //FUNCIONES
    const ConsultarSitios = (ClienteId: any) => {
        GetSites(ClienteId).then((response: AxiosResponse<any>) => {
            setSitios(response.data);
        }).catch((error: AxiosError<any>) => {
            setSitios([]);
        })
    }
   
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }

    const SelectSite = () => {
        return (
            <Form.Select className="mb-2 ms-2 mt-2" style={{ width: 300 }} onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let _sitio = Sitios.filter((value, index) => {
                    return value.siteid === Number.parseInt(e.currentTarget.value)

                })
                setSitio(_sitio[0]);
            }} aria-label="Default select example" value={(Sitio.siteid == null ? "":Sitio.siteid) }>
                <option value={""}>Seleccione</option>
                {
                    Sitios.map((element, i) => {
                        return (<option key={element.siteid} value={element.siteid}>
                            {element.sitename}</option>)
                    })
                }
            </Form.Select>
        );
    }
    const Encabezado = () =>{
        let state = (
            Data.Data.estado === "Sin Respuesta del Cliente" ?
                <span className='badge bg-warning'>{Data.Data.estado} </span> :
                Data.Data.estado === "En Mantenimiento" ?
                    <span className='badge bg-info'>{Data.Data.estado}</span> :
                    Data.Data.estado === "Detenido" ?
                        <span className='badge bg-danger'>{Data.Data.estado}</span> :
                        <span className='badge bg-success'>{Data.Data.estado}</span>
        )
        return <>
                <div className="border mb-2 mt-1 rounded shadow-sm d-flex justify-content-between">
                    <div className="mt-1">
                        <div>
                            <label className="mx-4 fs-6 fw-bolder">Cliente: </label>
                        </div>
                        <span className="mx-4 fs-5 text-muted">{Data.Data.clienteNombre}</span>
                    </div>
                    <div className="mt-1">
                        <div>
                            <label className="mx-4 fs-6 fw-bolder">Estado: </label>
                        </div>
                        <span className="mx-4 fs-6 text-muted">{state}</span>
                    </div>
                    <div className="mt-1">
                        <div>
                            <label className="mx-4 fs-6 fw-bolder">Administrador: </label>
                        </div>
                        <span className="mx-4 fs-5 text-muted">{Data.Data.usuarioNombre}</span>
                    </div>
                </div>
        </>
    }
    const Agregarsitios = () =>{
        let buscar = (Datos.DetallesDatos.fatiga_conf.IsSite && Estados.nuevo ? sitioNuevo.siteid: Sitio.siteid)
            let index =  Datos.DetallesDatos.fatiga_conf.Sites.filter((element:any) => {
                    return element.siteid == buscar
                });
            if(index.length > 0){
                toaster.push(message('error', "Requerimiento", "El sitio ya se encuentra registrado"), {
                    placement: 'topEnd'
                  });
            }else{
                let dt = {...Datos};
                dt.DetallesDatos.fatiga_conf.Sites.push(
                    (Datos.DetallesDatos.fatiga_conf.IsSite && Estados.nuevo ? 
                        {"sitename":sitioNuevo.sitename, "siteid":sitioNuevo.siteid} :
                        {"sitename":Sitio.sitename, "siteid":Sitio.siteid})
                    );

                setDatos(dt)
                setDatosTabla(dt);
                LimpiarFormulario();
            }
    }
    const Guardar = () =>{
        SetParametroSistema(Datos).then((response:AxiosResponse<any>) =>{
            toaster.push(message('success', "Mensaje", response.data[0].Mensaje), {
                placement: 'topCenter'
              });
              let d = { ...Datos }
              d.setShow(false)
              d.show = false
              setDatos(d);
        }).catch((error:AxiosError<any>) =>{
            toaster.push(message('error', "Requerimiento", "Ocurrio un error"), {
                placement: 'topEnd'
              });
        });
    }
    const Eliminar = (row:any) =>{
        console.log(row);
        let dt = {...Datos};
            dt.DetallesDatos.fatiga_conf.Sites = dt.DetallesDatos.fatiga_conf.Sites.filter((val:any) =>{
                return val.siteid != row.original.siteid;
            })
            setDatos(dt)
    }
    const LimpiarFormulario = () =>{
        let s = {...sitioNuevo};
        s.siteid = "";
        s.sitename = "";
        setSitioNuevo(s);
    }
   
    //RETURNS
    return (
        <>
            <Modal show={Datos.show} onHide={Datos.setShow} size={"lg"}>
                <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                    <Modal.Title>{Datos.Title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Encabezado></Encabezado>
                            </div>
                            <div className="col-4 mt-10">
                                    <div>
                                        <label className="label control-label label-sm fw-bolder" htmlFor="IsSite">Por sitio</label>
                                    </div>
                                    <input type="checkbox" id="IsSite" value={Datos.DetallesDatos.fatiga_conf.IsSite} checked={Datos.DetallesDatos.fatiga_conf.IsSite} onChange={changeSite} className="text-danger"></input>
                            </div>
                            <div className={`col-4 mt-10 ${(Datos.DetallesDatos.fatiga_conf.IsSite && !Estados.antiguo ? "visible" : "invisible")}`}>
                                    <div>
                                        <label className="label control-label label-sm fw-bolder" htmlFor="Nuevo">Nuevo</label>
                                    </div>
                                    <input type="checkbox" id="Nuevo" value={(Estados.nuevo ? "Si": "No")} checked={Estados.nuevo} onChange={(e) =>{
                                        let es = {...Estados};
                                        es.nuevo = e.target.checked;
                                        setEstados(es);
                                    }} className="text-danger"></input>
                            </div>
                            <div className={`col-4 mt-10 ${(Datos.DetallesDatos.fatiga_conf.IsSite &&  !Estados.nuevo? "visible" : "invisible")}`}>
                                    <div>
                                        <label className="label control-label label-sm fw-bolder" htmlFor="Antiguo">Antiguo</label>
                                    </div>
                                    <input type="checkbox" id="Antiguo" value={(Estados.antiguo ? "Si":"No")} checked={Estados.antiguo} onChange={(f) =>{
                                        let es = {...Estados};
                                        es.antiguo = f.target.checked;
                                        setEstados(es);
                                    }} className="text-danger"></input>
                            </div>
                            <div className={`d-flex justify-content-between border  rounded shadow-sm pb-5 ${(Datos.DetallesDatos.fatiga_conf.IsSite ? "visible" : "invisible")}`}>
                                <div className={`${(Datos.DetallesDatos.fatiga_conf.IsSite ? "visible" : "invisible")}`}>
                                        <label className="label control-label label-sm fw-bolder">Sitio</label>
                                        <div className={`mt-2  ${(Datos.DetallesDatos.fatiga_conf.IsSite && Estados.antiguo? "visible" : "invisible")}`} >
                                            <span className="fw-bolder mt-4 mx-2">Antiguo:</span>
                                            <SelectSite></SelectSite>
                                        </div>
                                        <div className={`mt-2  ${(Datos.DetallesDatos.fatiga_conf.IsSite && Estados.nuevo? "visible" : "invisible")} border`}>
                                            <div className="mt-2 mx-2">
                                                <label className="label label-sm control-label fw-bolder">Nuevo</label>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                    <span className="fw-bolder mt-4 mx-2">Nombre:</span>
                                                    <input className="form-control input input-sm" value={sitioNuevo.sitename} onChange={(r) => {
                                                        let s = {...sitioNuevo};
                                                        s.sitename = r.target.value;
                                                        setSitioNuevo(s);
                                                    }}/>
                                                    <span className="fw-bolder mt-4 mx-2">Id:</span>
                                                    <input className="form-control input input-sm" value={sitioNuevo.siteid}
                                                    onChange={(r) => {
                                                        let s = {...sitioNuevo};
                                                        s.siteid = r.target.value;
                                                        setSitioNuevo(s);
                                                    }}/>
                                            </div>
                                        </div>
                                </div>
                                <div className={`mt-10 ${(Datos.DetallesDatos.fatiga_conf.IsSite ? "visible" : "invisible")}`}>
                                    <button onClick={Agregarsitios} className="btn btn-sm btn-success mt-10">Agregar</button>
                                </div>
                            </div>

                            <div className={`col-12 mt-5 ${(Datos.DetallesDatos.fatiga_conf.IsSite ? "visible" : "invisible")}`}>
                                {(DatosTabla.length != 0) && (Datos.show)&& (
                                    <MaterialReactTable
                                        localization={MRT_Localization_ES}
                                        displayColumnDefOptions={{
                                            'mrt-row-actions': {
                                                muiTableHeadCellProps: {
                                                    align: 'center',
                                                },
                                                size: 180,
                                            },
                                        }}
                                        muiTableHeadCellProps={{
                                            sx: (theme) => ({
                                                fontSize: 14,
                                                fontStyle: 'bold',
                                                color: 'rgb(27, 66, 94)'
                                            }),
                                        }}
                                        defaultColumn={{
                                            minSize: 100, //allow columns to get smaller than default
                                            maxSize: 500, //allow columns to get larger than default
                                            size: 100, //make columns wider by default
                                        }}
                                        columns={Columnas}
                                        data={DatosTabla}
                                        enableEditing
                                        editingMode="modal" 
                                        initialState={{ density: 'compact' }}
                                        enableColumnOrdering
                                        enableColumnResizing
                                        enableColumnDragging={false}
                                        enablePagination={false}
                                        enableStickyHeader
                                        enableStickyFooter
                                        enableDensityToggle={false}
                                        enableRowVirtualization
                                        // enableRowNumbers
                                        enableTableFooter
                                        muiTableContainerProps={{ sx: { maxHeight: '300px' } }}
                                        renderRowActions={({ row, table }) => (
                                            <Box sx={{ display: 'block', gap: '1rem', marginLeft: 'auto', marginRight: 'auto' }}>
                                                <Tooltip arrow placement="top" title="Descargar detallado del cliente">
                                                    <IconButton onClick={() => {
                                                         Eliminar(row);
                                                        }
                                                    }>
                                                        <Delete className="text-danger" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button type="button" className="btn btn-sm" variant="primary" onClick={() => {
                       Guardar();
                    }}>
                        Guardar
                    </Button>
                    <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => {
                        let d = { ...Datos }
                        d.setShow(false)
                        d.show = false
                        setDatos(d);
                    }}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
