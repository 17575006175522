import FileSaver from "file-saver";
import XLSX from 'sheetjs-style';
import React, { useState } from "react";
import { Respuestas } from "../../models/dataModels";
import { Button, Form } from "react-bootstrap-v5";
import { GetReportePorTipo } from "../../data/dataPreoperacional";
import { AxiosResponse } from "axios";
import BlockUi from "@availity/block-ui";
import { ExportarExcel } from "./ExportarExcel";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import { FormatoColombiaDD_MM_YYY } from "../../../../../../_start/helpers/Constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";
import { UserModelSyscaf } from "../../../../auth/models/UserModel";
type Props = {
    ClienteId: string
}

export const ExportarReportes: React.FC<Props> = ({ ClienteId }) => {

    //Data desde el provider

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;carset=UTF-8';
    const isAuthorized = useSelector<RootState>(
        ({ auth }) => auth.user
    );


    const model = (isAuthorized as UserModelSyscaf);
    const TipoCliente: string = (JSON.parse(model.preoperacional) as any[]).filter(f => f.ClienteId === ClienteId)[0].Tipo;

    const { allowedMaxDays, allowedRange, combine } = DateRangePicker;
    if (allowedMaxDays)
        allowedMaxDays(31);

    if (allowedRange)
        allowedRange(moment().add(-12, "months").toDate(), moment().toDate());
    let listadoObjeto: any = [];
    let datosTemporales : any = {}
    //Variables de seteo
    const [FechaInicial, setFechaInicial] = useState<any>();
    const [FechaFinal, setFechaFinal] = useState<any>();
    const [TipoReporte, setTipoReporte] = useState(0);
    const [Visible, setVisible] = useState<boolean>(false);

    //Funciones de filtro - seteo
    function FechaInicialControl() {
        return (
            <>
                {(combine && allowedMaxDays && allowedRange) && (
                    <DateRangePicker className="mt-1 form-control border-0" format="dd/MM/yyyy" value={[FechaInicial, FechaFinal]}
                        disabledDate={combine(allowedMaxDays(31), allowedRange(
                            moment().add(-6, 'months').startOf('day').toDate(), moment().startOf('day').toDate()
                        ))}
                        onChange={(value, e) => {
                            if (value !== null) {
                                setFechaInicial(value[0])
                                setFechaFinal(value[1]);
                            }
                        }}

                    />
                )}
            </>
        )
    }



    function ReporteControl() {
        return (
            <Form.Select className="mt-4" name="TipoReporte" value={TipoReporte} onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                setTipoReporte(parseInt(e.currentTarget.value));
            }}>
                <option value={0}>Seleccione un reporte</option>
                <option value={1}>Histórico Preoperacionales</option>
                {(TipoCliente !== "SINTEL") && (
                    <>       <option value={2}>Consolidado por Mes</option>
                        <option value={3}>Consolidado por Conductor</option>
                        <option value={4}>Consolidado por Vehículo</option></>
                )}

            </Form.Select>

        )
    }

    // Funcion principal para exportar excel
    const exportarExcel = async () => {


        setVisible(true);
        GetReportePorTipo(ClienteId, FechaInicial, moment(FechaFinal).add(1,'day'), TipoReporte).then((respuesta: AxiosResponse<Respuestas[]>) => {

            crearExcel(respuesta.data);

        })

        //Funciones de consulta de datos


        const crearExcel = (respuestas: any[]) => {
            // Validamos tipo de reporte por filtro 
            let reporte: string = "";
            let tipo: number = TipoReporte;
            let Fi = moment(FechaInicial).format(FormatoColombiaDD_MM_YYY);
            let Ff = moment(FechaFinal).format(FormatoColombiaDD_MM_YYY);
            switch (tipo) {
                case 2:
                    listadoObjeto = [];
                    let KPI = ["# VIAJES", "# PREOPERACIONALES REALIZADOS", "# PREOPERACIONALES NO REALIZADOS"];
                    //iteramos la cantidad de registros a tener en el repote
                    (KPI).forEach((item, idx) => {
                        datosTemporales = {};

                        datosTemporales['KPI'] = item;

                        respuestas.forEach((r, idxR) => {
                            let porc: any = ((r.totalPreop / r.totalViajes) * 100).toFixed(2);
                            // iteramos el resultado por mes, el nombre del mes es el encabezado del reporte
                            datosTemporales[`${r.mesName}`] = (idx === 0) ? r.totalViajes : (idx === 1) ? r.totalPreop : r.totalViajes - r.totalPreop;
                            datosTemporales[`% Cumplimiento`] = (idx === 0) ? "100%" : (idx === 1) ? `${porc}%` : `${(100 - porc).toFixed(2)}%`;
                        })

                        listadoObjeto.push(datosTemporales);
                    })

                    reporte = `KPI Preoperacionales`;
                    break;

                case 4:
                    listadoObjeto = [];

                    respuestas.forEach((r) => {
                        datosTemporales = {};
                        // iteramos el resultado por mes, el nombre del mes es el encabezado del reporte
                        datosTemporales["Vehículo"] = r.vehiculo;
                        datosTemporales["Días de conducción"] = r.totalViajes;
                        datosTemporales["Preoperacionales realizados"] = r.totalPreop;
                        datosTemporales["% Cumplimiento"] = ` ${((r.totalPreop / r.totalViajes) * 100).toFixed(2)}%`;

                        listadoObjeto.push(datosTemporales);
                    })
                    reporte = `KPI Vehículo`;
                    break;

                case 3:
                    listadoObjeto = [];

                    respuestas.forEach((r) => {
                        datosTemporales = {};
                        // iteramos el resultado por mes, el nombre del mes es el encabezado del reporte
                        datosTemporales["CONDUCTOR"] = r.conductor;
                        datosTemporales["Días de conducción"] = r.totalViajes;
                        datosTemporales["Preoperacionales realizados"] = r.totalPreop;
                        datosTemporales["% Cumplimiento"] = ` ${((r.totalPreop / r.totalViajes) * 100).toFixed(2)}%`;

                        listadoObjeto.push(datosTemporales);
                    })
                    reporte = `KPI Conductor`;
                    break;
            }

            const ws = XLSX.utils.json_to_sheet(listadoObjeto.sort());
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver(data, `${reporte} ${Fi} al ${Ff}`);
            setVisible(false);

        }


    }

    //Retornamos los controles de filtro
    return (
        <BlockUi tag="span" className="bg-primary" keepInView blocking={(Visible === undefined ? true : Visible)}>
            <div className="row" style={{ padding: '20px' }}>
                <div className="col-sm-4 col-md-4 col-xs-4">
                    <label className="control-label label label-sm mx-2" style={{ fontWeight: 'bold' }}>Tipo Reporte:</label>
                    <ReporteControl />
                </div>
                <div className="col-sm-4 col-md-4 col-xs-4">
                    <label className="control-label label label-sm mx-4" style={{ fontWeight: 'bold' }}>Rango Fechas:</label>
                    <FechaInicialControl />
                </div>

                <div className="col-sm-4 col-md-4 col-xs-4">
                    <label className="control-label label label-sm"></label>
                    <div className=" ">
                        {TipoReporte === 1 ? (<ExportarExcel

                            NombreArchivo={"Histórico Preoperacionales"} tipoDescarga={0} FechaInicial={FechaInicial} FechaFinal={FechaFinal} ClienteId={ClienteId} lstPreoperacional={[]} />
                        ) : (

                            <div className="justify-content-end" style={{ textAlign: 'right' }}>
                                <Button type="button" variant="primary" onClick={exportarExcel}>
                                    Descargar
                                </Button>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </BlockUi>
    )
} 