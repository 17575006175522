import { useState } from "react";
import { Button, Col, Container, FormLabel, Modal, Row } from "react-bootstrap-v5";
import { EncabezadoHtml } from "../Encabezado";
import { useToaster } from "rsuite";
import { MensajeError, MensajeSuccess } from "../../../../../../../../_start/helpers/components/Toaster";
import { DeserializarJSon, formatableJson_HHMMss } from "../../../../../../../../_start/helpers/Helper";
import { ConsultasIniciales, useDataRequerimientos } from "../../../../core/RequerimientosCore";
import { ConsecutivoVisual, GetCabeceraOP, GetDatosRequerimientos, GetObservaciones, GetObservacionesCambioEstado, GetObservacionesEstadoObservaciones, NotificarRequerimiento, RequerimientoEquipoDesmontado, SetRequerimiento, StringNotificacion } from "../../../../data/Requerimientos";
import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../setup";
import { UserModelSyscaf } from "../../../../../../auth/models/UserModel";
import moment from "moment";
import confirmarDialog, { successDialog } from "../../../../../../../../_start/helpers/components/ConfirmDialog";
import { Checkbox } from "@mui/material";

type PropsModalEjecucionOrden = {
    Show: any,
    setShowModalOrden: any

}

export const ModalEjecucionOrden: React.FC<PropsModalEjecucionOrden> = ({ Show, setShowModalOrden }) => {
    const [infoEjecucion, setInfoEjecucion] = useState<any>({ Garantia: true });
    const { UserActual, ReqSeleccionado, lstEstadosReq, setloader, setlstRequerimientos, lstEstadosEquipoDesmontado } = useDataRequerimientos();
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;

    const toaster = useToaster();
    const Guardar = (infoDatos?: any) => {
        const EstadoActual = DeserializarJSon(ReqSeleccionado.original.Estado);
        let Cabecera = DeserializarJSon(ReqSeleccionado.original.Cabecera);
        let RequerimientoSeleccionadoLocal = (infoDatos?.DesmontadoDesinstalacion ?
            lstEstadosReq.filter((e: any) => e.valor == "46")[0] : 
            lstEstadosReq.filter((e: any) => e.valor == "9")[0]);
        let UsuarioId = ReqSeleccionado.original.UserId;
        // setCabecera(_Cabecera);
        let _obs = DeserializarJSon(ReqSeleccionado.original.Observaciones);
        if (RequerimientoSeleccionadoLocal.tipo !== EstadoActual.tipo) {
            // si se encuentra entre el listados de agentes, automaticamente se le asigna
            // de lo contrario null
            const agenteAnterior = Cabecera.LstAgentes.find((f: any) => f.tipo === RequerimientoSeleccionadoLocal.tipo);
            Cabecera = {
                ...Cabecera, agente: agenteAnterior?.Nombres,
                UsuarioId: agenteAnterior?.UserId
            }
        }
        // obsercacion por el cambio de estado
        GetObservacionesCambioEstado(_obs, RequerimientoSeleccionadoLocal, vUser, false);
        let _Cabecera = { ...Cabecera, InfoOS: infoDatos }
        let Campos = GetDatosRequerimientos(ReqSeleccionado.original.Id, _Cabecera, _obs, RequerimientoSeleccionadoLocal);

        confirmarDialog(() => {
            setloader(true)
            SetRequerimiento(Campos).then((response: AxiosResponse<any>) => {
                if (response.status == 200) {
                    successDialog(response.data[0][""], '');
                    ConsultasIniciales(setloader, vUser, setlstRequerimientos, toaster); //ACTUALIZAR DE FORMA LOCAL Y NO VOLVER A LLAMAR

                    if (UserActual.EsGestor && RequerimientoSeleccionadoLocal.tipoestado == "open") {
                        NotificarRequerimiento(UsuarioId, ReqSeleccionado.original.Id, StringNotificacion.replace("{UsuarioDestino}",
                            `${UserActual.Nombres}`).
                            replace("{Admin}", `${Cabecera.administrador}`).replace("{Consecutivo}", `${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`))
                    }
                    setloader(false);
                    setShowModalOrden(false)

                }
            }).catch(({ error }) => {
                setloader(false);
                setShowModalOrden(false);
            });
        }, `¿Esta seguro que desea agregar información al registro?`, 'Guardar');
    }

    const GuardarEjecucionOS = (infoDatos: any) => {
        // validamos que existan las observaciones a trabajar
        if (infoDatos.Observaciones == "") {
            toaster.push(MensajeError("Requerimiento", "Debe ingresar una observación"), {
                placement: 'topCenter'
            });
            return false;
        } else {
            // guardamos el requerimiento actual para que realice su cambio de estado
            Guardar(infoDatos);
            if (infoDatos.Desmontado) {
                // inicia el proceso en caso dado el equipo sea desmontado
                let Cabecera = DeserializarJSon(ReqSeleccionado.original.Cabecera);
                Cabecera = { ...Cabecera, InfoOS: infoDatos }; // asignamos los nuevos campoos para almacenarlos
                // en el actual y en el subrequerimiento
                let Estado = lstEstadosReq.filter((f: any) => f.valor == "27")
                // si el equipo es desmontado debemos verificar que el estado exista

                if (Estado.length == 0) {
                    toaster.push(MensajeError("Requerimiento", "Estado para subrequerimiento no configurado"), {
                        placement: 'topCenter'
                    });
                    return false;
                }

                if (Estado[0].tipoestado === "noopen") {
                    Cabecera = { ...Cabecera, agente: null, UsuarioId: null }
                }
                let _obs: any[] = [];
                GetObservacionesEstadoObservaciones(_obs,
                    `Creación SubRequeriento Equipo Desmontado Ref Requerimiento ${ConsecutivoVisual(Cabecera.Consecutivo)}`,
                    Estado[0], vUser, false);
                let Data: any = GetDatosRequerimientos(ReqSeleccionado.original.Id, Cabecera, _obs, Estado[0]);
                Data.PadreId = String(ReqSeleccionado.original.Id);
                Data.Tipo = "Soporte";

                RequerimientoEquipoDesmontado(Data).then((response: AxiosResponse<any>) => {
                    toaster.push(MensajeSuccess("Ejecución OS", "Creacion Requerimiento OS Equipo Desmontado"), {
                        placement: 'topCenter'
                    });
                    NotificarRequerimiento(vUser.Id, ReqSeleccionado.original.Id, "Se ha creado un subrequerimeinto por equipo desmontado")
                }).catch(({ error }) => {
                    toaster.push(MensajeError("Ejecución OS", "Error al crear Sub requerimiento, favor verifique"), {
                        placement: 'topCenter'
                    });
                });
            }
        }
    }

    return (
        <Modal show={Show} onHide={setShowModalOrden} size={"lg"}>
            <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                <Modal.Title>{`Ejecución Os Requerimiento ${ConsecutivoVisual(ReqSeleccionado.original.Consecutivo)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="container">
                    <EncabezadoHtml ReqSeleccionado={ReqSeleccionado}></EncabezadoHtml>

                    <div className="card  border overflow-auto" style={{ maxHeight: 300 }}>

                        <Container>
                            <Row className="mt-3">
                                <Col>
                                    <FormLabel>Ejecutado por Garantía:</FormLabel>
                                </Col>
                                <Col xs={1} md={1} lg={1}>

                                    <Checkbox color="primary"
                                        title="Orden de servicio por garantia"
                                        checked={infoEjecucion.Garantia}
                                        value={infoEjecucion.Garantia}
                                        onChange={(e: any) => { setInfoEjecucion({ ...infoEjecucion, Garantia: e.target.checked }) }}
                                    />

                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                    <div hidden={infoEjecucion.Garantia} >
                                        <FormLabel>Motivo No Garantía:</FormLabel>
                                        <textarea
                                            rows={2}
                                            style={{ borderColor: '#eb3626', width: "100%" }}
                                            title="El datos el equipo es desmontado"
                                            placeholder="Detalle el motivo de la no garantía"
                                            onChange={(e: any) => { setInfoEjecucion({ ...infoEjecucion, Motivo: e.currentTarget.value }) }}
                                        />

                                    </div>

                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col>
                                    <FormLabel>Desmontado por desinstalación:</FormLabel>
                                </Col>
                                <Col xs={1} md={1} lg={1}>
                                    <Checkbox color="primary"
                                        title="Desmontado desinstalación"
                                        checked={infoEjecucion.DesmontadoDesinstalacion}
                                        value={infoEjecucion.DesmontadoDesinstalacion}
                                        onChange={(e: any) => {
                                            setInfoEjecucion({ ...infoEjecucion, DesmontadoDesinstalacion: e.target.checked })
                                        }}
                                    />

                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                    <div hidden={!infoEjecucion.DesmontadoDesinstalacion} >
                                        <FormLabel>Datos desinstalación:</FormLabel>
                                        <textarea
                                            rows={2}
                                            style={{ borderColor: '#eb3626', width: "100%" }}

                                            title="Los datos del motivo de la desinstalación"
                                            placeholder="Detalle del motivo de la desinstalación"
                                            onChange={(e: any) => { setInfoEjecucion({ ...infoEjecucion, MotivoDesinstalacion: e.currentTarget.value }) }}
                                        />
                                    </div>

                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col>
                                    <FormLabel>Desmontado con Equipo Backup:</FormLabel>
                                </Col>
                                <Col xs={1} md={1} lg={1}>
                                    <Checkbox color="primary"
                                        title="Desmontado desinstalación"
                                        checked={infoEjecucion.Desmontado}
                                        value={infoEjecucion.Desmontado}
                                        onChange={(e: any) => {
                                            setInfoEjecucion({ ...infoEjecucion, Desmontado: e.target.checked })
                                        }}
                                    />

                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                    <div hidden={!infoEjecucion.Desmontado} >
                                        <FormLabel>Datos Equipos:</FormLabel>
                                        <textarea
                                            rows={2}
                                            style={{ borderColor: '#eb3626', width: "100%" }}
                                            id="datosEquipo"
                                            title="El datos el equipo es desmontado"
                                            placeholder="Relacione la mayor cantidad de datos del equipo para su manejo."
                                            onChange={(e: any) => { setInfoEjecucion({ ...infoEjecucion, DatosEquipo: e.currentTarget.value }) }}
                                        />
                                    </div>

                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col>
                                    <FormLabel>Reinstalado con Equipo Original:</FormLabel>
                                </Col>
                                <Col xs={1} md={1} lg={1}>
                                    <Checkbox color="primary"
                                        title="Reinstalado con Equipo Original"
                                        checked={infoEjecucion.ReinstaladoOriginal}
                                        value={infoEjecucion.ReinstaladoOriginal}
                                        onChange={(e: any) => {
                                            setInfoEjecucion({ ...infoEjecucion, ReinstaladoOriginal: e.target.checked })
                                        }}
                                    />

                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                    <div hidden={!infoEjecucion.ReinstaladoOriginal} >
                                        <FormLabel>Datos Equipos:</FormLabel>
                                        <textarea
                                            rows={2}
                                            style={{ borderColor: '#eb3626', width: "100%" }}
                                            id="datosEquipo"
                                            title="El datos el equipo es desmontado"
                                            placeholder="Relacione la mayor cantidad de datos del equipo para su manejo."
                                            onChange={(e: any) => { setInfoEjecucion({ ...infoEjecucion, DatosReinstalacion: e.currentTarget.value }) }}
                                        />
                                    </div>

                                </Col>
                            </Row>
                            <Row className="bg-light-danger">
                                <Col xs={5} md={5} lg={5}>
                                    <FormLabel>Ejecutado Parcial?:</FormLabel>
                                </Col>
                                <Col xs={7} md={7} lg={7}>
                                    <Checkbox color="error"
                                        checked={infoEjecucion.EsParcial}
                                        value={infoEjecucion.EsParcial}
                                        onChange={(e: any) => { setInfoEjecucion({ ...infoEjecucion, EsParcial: e.target.checked }) }}

                                    />


                                </Col>
                            </Row>
                            {(infoEjecucion.EsParcial && lstEstadosEquipoDesmontado) && (
                                lstEstadosEquipoDesmontado.map((elem: any, index: any) => {
                                    return (
                                        <Row key={index + 1} className="bg-light-danger" >
                                            <Col>
                                                <FormLabel>{elem.label}:</FormLabel>
                                            </Col>
                                            <Col xs={1} md={1} lg={1}>
                                                <Checkbox color="error"
                                                    checked={infoEjecucion[elem.campo]?.valor ?? false}
                                                    title="Falta de scripts"
                                                    value={infoEjecucion[elem.campo]?.valor ?? false}
                                                    onChange={(e: any) => {
                                                        let datosEje = { ...infoEjecucion };
                                                        datosEje[elem.campo] = { item: elem.label, valor: e.target.checked };
                                                        setInfoEjecucion(datosEje);
                                                    }}

                                                />
                                            </Col>
                                            <Col xs={6} md={6} lg={6}>
                                                <div hidden={!infoEjecucion[elem.campo]?.valor} >
                                                    <FormLabel>Relacione:</FormLabel>
                                                    <textarea
                                                        rows={2}
                                                        style={{ borderColor: '#eb3626', width: "100%" }}
                                                        value={infoEjecucion[elem.campo]?.valor ? infoEjecucion[elem.campo]?.detalle : ""}
                                                        title="Información de Referencia"
                                                        onChange={(e: any) => {
                                                            let datosEje = { ...infoEjecucion };
                                                            let objetoSelec = { tipo: elem.label, detalle: e.currentTarget.value };
                                                            datosEje[elem.campo] = datosEje[elem.campo] ? { ...datosEje[elem.campo], detalle: e.currentTarget.value } :
                                                                objetoSelec;
                                                            setInfoEjecucion(datosEje)
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                                })

                            )}


                        </Container>

                    </div>
                    <div className="card  border mt-2">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <FormLabel>Observaciones:</FormLabel>
                                    <textarea
                                        id="Observaciones"
                                        rows={3}
                                        placeholder="Escribir las observaciones del trabajo por garantia"
                                        className="form-control"
                                        value={infoEjecucion.Observaciones}
                                        onChange={(e: any) => { setInfoEjecucion({ ...infoEjecucion, Observaciones: e.currentTarget.value }) }}
                                    > </textarea>
                                </Col>

                            </Row>
                        </Container>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className={`btn btn-sm`} variant="primary" onClick={() => {
                    GuardarEjecucionOS(infoEjecucion)

                }}>
                    Finalizar Ejecución
                </Button>
                <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => setShowModalOrden(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>

    )

}


