import {  Post_getConsultasDinamicasConAuthCore } from "../../../../../_start/helpers/Axios/CoreService";

export  function setConfiguracion(Sigla: string, Configuraciones: string , Movimientos: string, tipo: string ){
    var params: { [id: string]: string | null; } = {};
    params["sigla"] = Sigla;
    params["data"] = Configuraciones;
    params["movimientos"] = Movimientos;
    params["tipo"] = tipo;
    
    // hacemos la consulta 
    return  Post_getConsultasDinamicasConAuthCore({    
      Clase : "GOIQueryHelper",  
      NombreConsulta: "setConfiguracion", 
      Pagina :null, 
      RecordsPorPagina :null}, 
      params);
};

export  function getConfiguracion(Sigla: string ){
  var params: { [id: string]: string | null; } = {};
  params["sigla"] = Sigla;

  // hacemos la consulta 
  return  Post_getConsultasDinamicasConAuthCore({    
    Clase : "GOIQueryHelper",  
    NombreConsulta: "getConfiguracion", 
    Pagina :null, 
    RecordsPorPagina :null}, 
    params);
};


export  function getUsuarios(clienteId: string ){
  var params: { [id: string]: string | null; } = {};
  params["clienteId"] = clienteId;

  // hacemos la consulta 
  return  Post_getConsultasDinamicasConAuthCore({    
    Clase : "PortalQueryHelper",  
    NombreConsulta: "getUserByClientId", 
    Pagina :null, 
    RecordsPorPagina :null}, 
    params);
};

export const Tooltips : any ={
  "Ayudageneral" : { title: "Ayuda", content : "Asegúrese de seleccionar el boton guardar para grabar los cambios." }
, "Cerrar" : {title: "Cerrar", content : "Cancela todas las acciones y cierra el modal."}
, "Guardar" : {title: "Guardar", content : "Graba todo el contenido en el servidor, para posteriormente consultarlo."}
, "Eliminar": {title: "Eliminar", content : "Permite eliminar un estado definitivamente, esta acción no se puede deshacer."}
, "Modificar": {title: "Modificar", content : "Permite editar o modificar un registro previamente guardado."}
, "Nuevo": {title: "Nuevo", content : "Abre una ventana para agregar un nuevo estado."}
, "Flujo" : {title: "Flujo", content : "Se usa solo para agregar un nuevo flujo."}
}

export  const categoriasEstados = (Data:any) =>{
    return Object.keys(Data.reduce((p: any, c: any) => {
      if(c.tipoestado != undefined){
        let name = c.tipoestado;
        p[name] = p[name] ?? [];
          p[name].push(c);
      }
      return p;
  }, {}));
}