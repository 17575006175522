import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap-v5"
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { Delete, Update } from "@mui/icons-material";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ColumnFiltersState, SortingState, PaginationState } from "@tanstack/react-table";
import { getConfiguracion, setConfiguracion } from "../../../data/parametrizacionData";
import { FechaServidor } from "../../../../../../../_start/helpers/Helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../setup";
import { UserModelSyscaf } from "../../../../../auth/models/UserModel";



type Props = {
    show: boolean;
    handleClose: () => void;
    title?: string;
};

export const UpdateDLP: React.FC<Props> = ({ show, handleClose, title }) => {

    const isAuthorized = useSelector<RootState>(
        ({ auth }) => auth.user
    );

    const model = (isAuthorized as UserModelSyscaf);

    const [objetoDlp, upateObjetoDlp] = useState<any>({
    });



    const [labelsinEditar, setlabelsinEditar] = useState("");


    const [rowCount, setRowCount] = useState(0);

    const [tituloModalDLP, settituloModalDLP] = useState('');
    const [Data, setData] = useState<any[]>([]);

    const [showModal, setshowModal] = useState(false);

    const handleClose2 = () => {
        settituloModalDLP('');
        upateObjetoDlp({})
        setshowModal(false);
    };


    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);

    let listadoCampos: MRT_ColumnDef<any>[] =

        [
            {
                accessorKey: 'order',
                header: 'Orden',
                size: 100
            },
            {
                accessorKey: 'categoria',
                header: 'Caterogia',
                size: 100
            },
            {
                accessorKey: 'label',
                header: 'Label',
                size: 100
            },
            {
                accessorKey: 'tipo',
                header: 'Tipo',
                size: 100
            },
            {
                accessorKey: 'observaciones',
                header: 'Observaciones',
                size: 100
            },
            {
                accessorKey: 'valores',
                header: 'Valores',
                size: 100
            },
            {
                accessorKey: 'esobligatorio',
                header: 'esobligatorio',
                size: 50,
                Cell({ cell, column, row, table, }) {
                    return (cell.getValue() == true) ? <span >Verdadero</span>
                        : <span>Falso</span>
                },
            },

        ];

    const showModals = () => {
        settituloModalDLP('Editar Listado DLP')
        setshowModal(true);
    }

    useEffect(() => {

        getConfiguracion('DLPST').then((response) => {

            JSON.parse(response.data[0].Configuracion) ? setData(JSON.parse(response.data[0].Configuracion) as any[])
                : setData([]);

        });

    }, [])

    function SelectCategoria() {
        return (
            <Form.Select className=" mb-3 " name="tipo" value={objetoDlp.categoria} onChange={(e) => {

                upateObjetoDlp({ ...objetoDlp, categoria: e.currentTarget.value as any })

            }}>
                <option value={0}>Selecione Categoria</option>
                <option value={'transmision'}>Transmisión</option>
                <option value={'señales'}>Señales</option>
                <option value={'demanda'}>Por Demanda</option>


            </Form.Select>
        );
    }

    function SelectTipo() {
        return (
            <Form.Select className=" mb-3 " name="tipo" value={objetoDlp.tipo} onChange={(e) => {
                upateObjetoDlp({ ...objetoDlp, tipo: e.currentTarget.value as any })
            }}>
                <option value={0}>Selecione tipo</option>
                <option value={'Check Box'}>Check Box</option>
                <option value={'Lista'}>Lista</option>
                <option value={'Text Box'}>Text Box</option>

            </Form.Select>
        );
    }

    const modalSetDLP = (row: any) => {

        setlabelsinEditar(row.label);
        upateObjetoDlp(row);
        showModals();
    }


    const setDLP = (tipoModificacion: any, labelEditar?: any) => {


        let movimientos: any = {};
        let mensaje: any = "";
        let tipoMovimiento: any = "";

        tipoModificacion = (objetoDlp.order == undefined) ? "1" : "2";
        if (tipoModificacion == "1") {
            mensaje = "Se agrega nueva configuración";
            tipoMovimiento = "Creación";
        }
        else if (tipoModificacion == "2") {
            mensaje = "Se edita configuración";
            tipoMovimiento = "Edición";
        }
        else {
            mensaje = "Se elimina configuración";
            tipoMovimiento = "Eliminacion";
        }


        setlabelsinEditar(objetoDlp.label);

        movimientos = {
            fecha: FechaServidor(),
            usuario: model.Nombres,
            tipo: tipoMovimiento,
            mensaje
        };

        confirmarDialog(() => {
            if (tipoModificacion == "1") {
                setConfiguracion('DLPST', '[' + JSON.stringify(objetoDlp) + ']', '[' + JSON.stringify(movimientos) + ']', tipoModificacion).then((response) => {
                    successDialog("Operación Éxitosa", "");
                    setData([...Data, JSON.parse(JSON.stringify(objetoDlp))] as any[]);
                    upateObjetoDlp({});
                }).catch((error) => {
                    errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
                });
            }
            else if (tipoModificacion == "2" || tipoModificacion == "3") {

                let conf = Data.filter(lis => lis.label != (tipoModificacion == "2" ? labelsinEditar : labelEditar));

                if (tipoModificacion == "2")
                    conf.push(objetoDlp);



                setConfiguracion('DLPST', JSON.stringify(conf), '[' + JSON.stringify(movimientos) + ']', tipoModificacion).then((response) => {
                    successDialog("Operación Éxitosa", "");
                    setData(JSON.parse(JSON.stringify(conf)) as any[]);
                    upateObjetoDlp({});
                    handleClose2();
                }).catch((error) => {
                    errorDialog("<i>Error comuniquese con el adminisrador<i/>", "");
                });
            }

        }, tipoModificacion == "1" ? `Esta seguro que desea agregar la configuracion` : tipoModificacion == "2" ? `Esta seguro de modificar la configurción`
            : `Esta seguro de eliminar la configurción`
            , "Guardar");

    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                fullscreen={true}
                >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack className="w-100">
                        <div className="d-flex justify-content-end">
                            <Button type="button" variant="primary" onClick={() => {
                                showModals()
                            }}>
                                Adicionar
                            </Button>
                        </div>
                        <div className="overflow-auto"
                        >
                            <MaterialReactTable 
                                localization={MRT_Localization_ES}
                                displayColumnDefOptions={{
                                    'mrt-row-actions': {
                                        muiTableHeadCellProps: {
                                            align: 'center',
                                        },
                                        size: 120,
                                    },
                                }}
                                columns={listadoCampos}
                                data={Data}
                                // editingMode="modal" //default         
                                enableTopToolbar={false}
                                enableColumnOrdering
                                enableEditing
                                // enableEditing
                                /* onEditingRowSave={handleSaveRowEdits}
                                    onEditingRowCancel={handleCancelRowEdits}*/
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                            color: 'error',
                                            children: 'Error al cargar información',
                                        }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onGlobalFilterChange={setGlobalFilter}
                                onPaginationChange={setPagination}
                                onSortingChange={setSorting}
                                rowCount={Data.length}

                                state={{
                                    columnFilters,
                                    globalFilter,
                                    isLoading,
                                    pagination,
                                    showAlertBanner: isError,
                                    showProgressBars: isRefetching,
                                    sorting,
                                }}

                                renderRowActions={({ row, table }) => (
                                    <>
                                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                                            <Tooltip arrow placement="left" title="modificar">
                                                <IconButton
                                                    onClick={() => {
                                                        modalSetDLP(row.original);
                                                    }}
                                                >
                                                    <Update />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip arrow placement="left" title="eliminar">
                                                <IconButton
                                                    onClick={() => {
                                                        setDLP("3", row.original.label);
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </>
                                )
                                }
                            />
                        </div>

                    </Stack>



                </Modal.Body>

            </Modal>

            <Modal
                show={showModal}
                onHide={handleClose2}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{tituloModalDLP}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                    </div>
                    <div className="row">
                        <div className="col-sm-2 col-xl-2 col-md-2 col-lg-2">
                            <label className="control-label label label-sm  m-3" htmlFor="dlp" style={{ fontWeight: 'bold' }}>Orden:</label>
                            <input className="form-control  input input-sm mb-3" type="number" value={objetoDlp.order} onChange={(e) => {
                                upateObjetoDlp({ ...objetoDlp, order: e.target.value, id: e.target.value })
                            }} />
                        </div>

                        <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                            <label className="control-label label label-sm  m-3" htmlFor="dlp" style={{ fontWeight: 'bold' }}>Categoria:</label>
                            <SelectCategoria />
                        </div>
                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                            <label className="control-label label label-sm  m-3" htmlFor="dlp" style={{ fontWeight: 'bold' }}>Tipo:</label>
                            <SelectTipo />
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                            <label className="control-label label label-sm  m-3" htmlFor="dlp" style={{ fontWeight: 'bold' }}>Label:</label>
                            <textarea rows={5} className="form-control  input input-sm mb-3" placeholder="Ingrese Label" value={objetoDlp.label}
                                onChange={(e) => { upateObjetoDlp({ ...objetoDlp, label: e.target.value }) }} />
                        </div>
                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                            <label className="control-label label label-sm  m-3" htmlFor="dlp" style={{ fontWeight: 'bold' }}>Valores:</label>
                            <input className="form-control  input input-sm mb-3" placeholder="Ingrese Valores" type="text" value={objetoDlp.valores} onChange={(e) => { upateObjetoDlp({ ...objetoDlp, valores: e.target.value }) }} />
                            <label className="control-label label label-sm  m-3" htmlFor="dlp" style={{ fontWeight: 'bold' }}>CompareRed:</label>
                            <input className="form-control  input input-sm mb-3" placeholder="Ingrese Valores" type="text" value={objetoDlp.CompareRed} onChange={(e) => { upateObjetoDlp({ ...objetoDlp, CompareRed: e.target.value }) }} />

                        </div>


                    </div>
                    <div className="row">

                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                            <label className="control-label label label-sm  m-3" htmlFor="dlp" style={{ fontWeight: 'bold' }}>Observaciones Positivas:</label>
                            <textarea rows={2} className="form-control  input input-sm mb-3" placeholder="Ingrese Observaciones" value={objetoDlp.observaciones} onChange={(e) => { upateObjetoDlp({ ...objetoDlp, observaciones: e.target.value }) }} />
                        </div>
                        <div className="col-sm-6 col-xl-6 col-md-6 col-lg-6">
                            <label className="control-label label label-sm  m-3" htmlFor="dlp" style={{ fontWeight: 'bold' }}>Observaciones Negativas:</label>
                            <textarea rows={2} className="form-control  input input-sm mb-3" placeholder="Ingrese Observaciones" value={objetoDlp.observacionesNegativas} onChange={(e) => { upateObjetoDlp({ ...objetoDlp, observacionesNegativas: e.target.value }) }} />
                        </div>

                    </div>

                    <label className="m-2" htmlFor="dlp" style={{ fontWeight: 'bold' }}>Es obligatorio:</label>

                    <input className=" m-3"
                        type="checkbox"
                        checked={objetoDlp.esobligatorio}
                        onChange={(e) =>
                            upateObjetoDlp({ ...objetoDlp, esobligatorio: e.target.checked })

                        }
                    />


                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary" onClick={() => {
                        setDLP("2", null);
                    }}>
                        Guardar
                    </Button>
                    <Button type="button" variant="secondary" onClick={handleClose2}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}
