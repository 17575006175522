import Chart from "react-apexcharts";
import { getCSSVariableValue } from "../../../../../../../_start/assets/ts/_utils";
import { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";

type Props = {
    className?: string;
    innerPadding?: string;
    tipoData?: number;
    nameChart: string;
    titulo: string;
    Data: any[],
    Tipo: any,
    setTitleArea: any,
    setTitleEstado: any
}
const IndicadorArea: React.FC<Props> = ({ className, nameChart, titulo, Data, Tipo, setTitleArea, setTitleEstado }) => {
    const [opciones, setopciones] = useState<any>();

    const colorsuccess = getCSSVariableValue("--bs-success");
    const colorwarning = getCSSVariableValue("--bs-warning");
    const colordanger = getCSSVariableValue("--bs-danger");
    useEffect(() => {
        let AgrupadoDato = Data.filter((val: any) => {
            if (JSON.parse(val.Estado).tipo == Tipo)
                return val;
        })
            .reduce((p: any, c: any) => {

                let name = JSON.parse(c.Estado).tipoestado;
                p[name] = p[name] ?? [];
                p[name].push(c);
                return p;
            }, {});
        let criticidad: any = {};
        if (Object.keys(AgrupadoDato).length > 0) {
            Object.keys(AgrupadoDato).map((item: any, index: any) => {
                if (item == "noopen")
                    criticidad["Abiertos"] = AgrupadoDato[item].length
                else if (item == "closed")
                    criticidad["Cerrados"] = AgrupadoDato[item].length;
                else
                    criticidad["En Progreso"] = (criticidad["En Progreso"] == undefined ? 0 : criticidad["En Progreso"]) + AgrupadoDato[item].length;
            })
        };
        let labelsArray = Object.keys(criticidad);
        let series: any[] = Object.values(criticidad);
   
        const opciones: ApexOptions = {
            series: series,
            colors: [function (value: any) {
                return ((value.w.config.labels[value.seriesIndex] == "En Progreso" ? colorwarning : (value.w.config.labels[value.seriesIndex] == "Cerrados" ? colorsuccess : colordanger)))
            }],
            chart: {
                id: `dona_indicadorarea_${nameChart}`,
                fontFamily: 'Montserrat',
                type: 'donut',
                dropShadow: {
                    enabled: true,
                    color: '#111',
                    top: -1,
                    left: 3,
                    blur: 3,
                    opacity: 0.2
                },
                events: {
                    click: (event: any, chartContext: any, config: any) => {
                        if (event.target.attributes.j != undefined) {
                            let Estado = config.config.labels[event.target.attributes.j.value];
                            setTitleArea(chartContext.opts.title.text);
                            setTitleEstado(Estado);
                        }
                    }
                },
            },
            stroke: {
                width: 0,
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            value: {
                                show: true,
                                fontSize: '21px',
                                offsetY: -7,
                            },
                            total: {
                                showAlways: true,
                                show: true,
                                fontSize: '18px',
                                fontWeight: 'bold',
                                color: '#45C0BE',
                            }
                        }
                    }
                }
            },
            labels: labelsArray,
            dataLabels: {
                enabled: true,
                formatter: function (val: number) {
                    return val.toFixed(1) + "%"
                },
            },
            legend: {
                show: true,
                position: 'bottom',
                formatter: function (seriesName: any, opts: any) {
                    return [seriesName, "(", opts.w.globals.series[opts.seriesIndex], ")"].join(' ')
                }
            },

            title: {
                text: titulo,
                align: 'center',
                style: {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#45C0BE'
                }
            }

        };
        setopciones(opciones);

        return()=>{
            ApexCharts.exec(`dona_indicadorarea_${nameChart}`,'destroy')
        }
    }, [])
    return ( 
        <div id={`POST_div_indicadorArea_${nameChart}`} key={`POSTVENTA_div_indicadorArea_${nameChart}`} className={`card ${className}`}>
            {(opciones != undefined) && (
                <Chart options={opciones} series={opciones.series}
                    type="donut" height={300} />
            )}
        </div>
    )
}
export { IndicadorArea }