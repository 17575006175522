import { Clear, AdminPanelSettings, AccountBox, Edit, Check, Person, PersonOff } from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { HtmlTooltipStyled, HtmlContentTitle } from "../../../../../../../_start/helpers/components/HtlmTooltip";
import { DividirNombre, EstateChange } from "../../../../../auth/data/datPerfil";
import { useDataUsuarios } from "../../../core/usuariosProvider";
import { ComponenteClientes, Usuarios_Tooltips, columnasTabla } from "../../../data/usuariosdata";
import { Box, IconButton } from "@mui/material";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";

export const ECO_ListadoUsuarios: React.FC<{ setshow: any }> = ({ setshow }) => {

    const { lstUsuarios, loader, setloader, setlstUsuarios, setUsuarioSel, lstClientes } = useDataUsuarios();

    const [clienteSel, setClienteSel] = useState<string>('0');
    const [lstUsuarioFiltrada, setLstUsuarioFiltrada] = useState<any[]>(lstUsuarios);
    const setCliente = (e: any) => {
        setClienteSel(e.currentTarget.value);
    }

    useEffect(() => {
        if (clienteSel === '0')
            setLstUsuarioFiltrada(lstUsuarios);
        else
            setLstUsuarioFiltrada(lstUsuarios.filter((f) => f.ClienteId === clienteSel));

    }, [lstUsuarios, clienteSel])

    //Al darle click al boton editar.
    const Editar = (row: any) => {

        let adicionales = (row.DetallesAdicionales != null ? JSON.parse(row.DetallesAdicionales) : {
            "Tipo": (row.ClienteId == "-1" ? "Usuario SYSCAF" : ""),
            "esDummy": "false"
        })
        setUsuarioSel({
            "Nombres": DividirNombre(row.Nombres)[0],
            "Apellidos": DividirNombre(row.Nombres)[1],
            "Cargo": adicionales.Tipo,
            "PerfilId": row.PerfilId,
            "ClienteId": row.ClienteId,
            "Correo": row.Email,
            "esGenerico": row.esGenerico,
            "UserName": row.UserName,
            "PhoneNumber": row.PhoneNumber,
            "esDummy": adicionales.esDummy,
            "Id": row.Id,
            "Estado": row.Estado,
            "Perfil": row.Perfil,
            "ClienteNombre": row.ClienteNombre,
            "isNew": false
        });
        setshow(true);
    }

    const CambioEstado = (row: any) => {
        confirmarDialog(() => {
            setloader(true);
            EstateChange(JSON.stringify(row.Id)).then((response: AxiosResponse<any>) => {
                if (response.status == 200) {
                    successDialog(`Operación Éxitosa`, "");
                    let _data = [...lstUsuarios];
                    _data.map((val: any) => {
                        if (val.Id == row.Id) {
                            val.Estado = (row.Estado == "Activo" ? "Inactivo" : "Activo");
                            val.LockoutEnabled = (row.LockoutEnabled ? false : true);
                        }
                    });
                    setlstUsuarios(_data);
                } else
                    errorDialog("Error", "Error al llamar la peticion");

            }).catch((error: AxiosError<any>) => {
                errorDialog("Error", error.message)

            }).finally(() => {
                setloader(false);
            });
        }, `Esta seguro que desea guardar el usuario`, 'Guardar');
    }
    return (<>{(lstUsuarios.length > 0) && (<>
        <div className="d-flex justify-content-start" >
            <label className="fs-3 m-4" style={{width:'150px'}}>       Filtro Cliente: </label>
            <div style={{width:'300px'}}>
            <ComponenteClientes ClienteId={clienteSel} Clientes={lstClientes} callBack={setCliente} />
            </div>
        </div>
        <MaterialReactTable
            localization={MRT_Localization_ES}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    size: 120
                },
            }}

            muiTableHeadCellProps={{
                sx: () => ({
                    fontSize: 14,
                    fontStyle: 'bold',
                    color: 'rgb(27, 66, 94)'
                }),
            }}
            columns={columnasTabla}
            data={lstUsuarioFiltrada}
            enableColumnOrdering
            enableStickyHeader
            enableDensityToggle={false}
            enableSorting={true}
            enablePagination={false}
            enableRowVirtualization
            muiTableContainerProps={{
                sx: { maxHeight: '400px' }, //give the table a max height
            }}
            enableEditing={true}
            editingMode="modal"
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    {
                        <HtmlTooltipStyled placement={'top'} title={HtmlContentTitle(Usuarios_Tooltips["Editar"].title, Usuarios_Tooltips["Editar"].content)} >
                            <IconButton onClick={() => {
                                Editar(row.original);
                            }}>
                                <Edit className="text-success"></Edit>
                            </IconButton>
                        </HtmlTooltipStyled>
                    }

                    <HtmlTooltipStyled placement={'top'} title={HtmlContentTitle(Usuarios_Tooltips["Inactivar"].title, Usuarios_Tooltips["Inactivar"].content)} >
                        <IconButton onClick={() => {
                            CambioEstado(row.original)
                        }}>
                            {(row.original.LockoutEnabled ? <PersonOff className="text-danger"></PersonOff> : <Person className="text-success"></Person>)}

                        </IconButton>
                    </HtmlTooltipStyled>

                </Box>
            )
            }
            state={{
                isLoading: loader

            }}
        /></>)}</>)

}