import BlockUi from "@availity/block-ui";
import { PageTitle } from "../../../../../_start/layout/core";
import { useEffect, useState } from "react";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, Download, Edit, FireTruckTwoTone, LocationCity, Person, SupportAgent, SystemUpdate } from "@mui/icons-material";
import { Campos, CamposRender, ColumnasExportarExcel, DatosDefaultSystem, GetAdministradores, GetAssets, GetClientesAdministradores, GetConfiguracionAssets, GetConfiguraciones, GetDrivers, GetInformacionModulos, GetSites, GetTiposAsset, SetParametroAsset, SetParametroDriver, SetParametroSite, TiposSeleccionados, UserStateStatus, updateAssets } from "../data/Clientes";
import { AxiosError, AxiosResponse } from "axios";
import { Button, Modal } from "react-bootstrap-v5";
import { DeserializarJSon, EsJson } from "../../../../../_start/helpers/Helper";
import { DescargarExcel } from "../../../../../_start/helpers/components/DescargarExcel";
import { EsPermitido, Operaciones, PermisosOpcion } from "../../../../../_start/helpers/Axios/CoreService";
import { ModalSystem } from "./ModalSystem";
import { ModalEditarCliente } from "./ModalesCliente/ModalEditarCliente";
import { useToaster, Notification } from "rsuite";
import { ModalAsset } from "./ModalesCliente/ModalAsset";
import { ModalDriver } from "./ModalesCliente/ModalDriver";
import { ModalSites } from "./ModalesCliente/ModalSites";
import { RootState } from "../../../../../setup";
import { useSelector } from "react-redux";
import { UserModelSyscaf } from "../../../auth/models/UserModel";
import confirmarDialog from "../../../../../_start/helpers/components/ConfirmDialog";
import { GetAdminis } from "../../../SYSCAF/PostVenta/data/PostVentaData";
export default function Clientes() {
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;
    const toaster = useToaster();
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }
    const permisosopcion = PermisosOpcion("Clientes");
    const operacionesPermisos = Operaciones;
    const ClienteTelematico = "SINTEL";
    const ClienteMix = "MIX";
    const [loader, setloader] = useState<boolean>(false);
    const [ClienteGeneral, setClienteGeneral] = useState<any>({});
    const [plantillaModulos, setplantillaModulos] = useState<any>({});
    const [DatosClientes, setDatosClientes] = useState<any[]>([]);
    const [DatosSites, setDatosSites] = useState<any[]>([]);
    const [DatosDriver, setDatosDrivers] = useState<any[]>([]);
    const [DatosAssets, setDatosAssets] = useState<any[]>([]);
    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [isError, setIsError] = useState(false);

    const [Decomisionado, setDecomisionado] = useState<string>("");
    const [Activo, setActivo] = useState<string>("");
    const [Driver, setDriver] = useState<string>("");
    const [Sitios, setSitios] = useState<string>("");
    const [showModalAsset, setShowModalAsset] = useState<boolean>(false);
    
    const [showModalDriver, setShowModalDriver] = useState<boolean>(false);
    const [showModalSites, setshowModalSites] = useState<boolean>(false);
    
    const [clienteSeleccionado, setclienteSeleccionado] = useState<any>({});
    /* Fin cluentes */
    const [Consultas, setConsultas] = useState<any[]>([
        { Cliente: "", Data: [], TipoCliente: "", ClienteNombre:"", Sitios:[] },
        { Cliente: "", Data: [],  TipoCliente: "", ClienteNombre:"", Sitios:[] },
        { Cliente: "", Data: [],  TipoCliente: "", ClienteNombre:"", Sitios:[] },
        { Cliente: "", Data: [],  TipoCliente: "", ClienteNombre:"", Sitios:[] }
    ])
    const [DataModal, setDataModal] = useState<any>({}
    )
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalactivos, setShowModalactivos] = useState<boolean>(false);
    const [showModaldriver, setShowModaldriver] = useState<boolean>(false);
    const [showModalSitios, setShowModalSitios] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
   
    const [CamposDriver, setCamposDriver] = useState<any>({
        DriverId: "",
        fmDriverId:"",
        employeeNumber:"",
        Name:"",
        SiteId:"",
        ClienteId:"",
        EsActivo:"",
        Clave:"1"
    });
    const [CamposSite, setCamposSite] = useState<any>({
        SiteId:"",
        SiteName:"",
        ClienteId:"",
        EsActivo:"",
        Clave:"1"
    });

    const [Configuracion, setConfiguracion] = useState<any>(
        {
            Estados : [],
            Tipos:[],
            Drivers:[],
            Paquetes:[],
            Activos:[],
            Vertical:[],
            OBC:["Si","No"],
            MIX:["Si","No"],
            DriverSeleccionado:{},
            EstadoSeleccionado:{},
            TipoSeleccionado:{}
           
        }
    );

    useEffect(() => {
        Consultar();
        return () => { }
    }, [])

    useEffect(()=> {
        if(ClienteGeneral.ClienteId){
            GetSites(ClienteGeneral.ClienteId).then((response: AxiosResponse<any>) => {
                let Consult = [...Consultas]
                Consult[1].Sitios = response.data;
                Consult[2].Sitios = response.data;
                Consult[3].Sitios = response.data;
                setConsultas(Consult);
            }).catch(() => {
                console.log("error");
                setloader(false);
            });
        }

        return () => { }
    },[ClienteGeneral])

    const Consultar = () => {
        setloader(true);
        setIsLoading(true);
        setIsRefetching(true)
        let c = {...Configuracion};
        GetConfiguraciones(null).then((response: AxiosResponse<any>) => {
            let Status =  UserStateStatus(response.data, "UserState");
            let Veticales = UserStateStatus(response.data, "Vertical");
            let Paquetes = UserStateStatus(response.data, "Paquete comercial");
            let Activos = UserStateStatus(response.data, "Activo facturables");
            c.Estados = (Status.length> 0 ?  (EsJson(Status[0]["Configuracion"])  ? DeserializarJSon(Status[0]["Configuracion"]).UserState:[]) : []) ;
            c.Vertical =(Veticales.length >0 ?  (EsJson(Veticales[0]["Configuracion"]) ? DeserializarJSon(Veticales[0]["Configuracion"]).Vertical:[] ) : []);
            c.Paquetes = (Paquetes.length > 0 ? (EsJson(Paquetes[0]["Configuracion"]) ? DeserializarJSon(Paquetes[0]["Configuracion"])["Paquete comercial"]:[] ):[]) ;
            c.Activos = (Activos.length > 0 ? (EsJson(Activos[0]["Configuracion"]) ? DeserializarJSon(Activos[0]["Configuracion"])["Activo facturables"]:[] ): []); 
        }).catch(() => {
            console.log("error");
        });
        GetTiposAsset(null).then((response: AxiosResponse<any>) => {
            c.Tipos = response.data;
          
        }).catch(() => {
            console.log("error");
        });
        //Consulta el listado de admins por el cliente seleccionado.
        GetAdminis().then((response: AxiosResponse<any>) => {
            c.Administradores=response.data.map((e: any) => { return { "Nombre": e.nombres, "UsuarioId": e.id } }).sort();
        }).catch((error: AxiosError<any>) => {
            console.log(error);
        });
      
        GetClientesAdministradores(null).then((response: AxiosResponse<any>) => {
            setDatosClientes(response.data);
            setloader(false);
            setIsLoading(false);
            setIsRefetching(false)
            setRowCount(response.data.length);
            GetInformacionModulos().then((response) => {
                if (response.status == 200)
                    setplantillaModulos(response.data)            
            });
            setConfiguracion(c)
        }).catch((error:any) => {
            console.log(error);
            setIsError(true);
            setloader(false);
            setIsLoading(false);
            setIsRefetching(false)
        }).finally(() => {
            setloader(false);
            setConfiguracion(c)
        });

       
        
    }

    const consultarAdmins = (row: any) => {
        
    }
    const consultarVehiculos = (row: any) => {
        if (row.original.ClienteId !== Consultas[1].Cliente) {
            setloader(true);
            GetAssets(row.original.ClienteId).then((response: AxiosResponse<any>) => {
                let Decomisionados = response.data.filter((item: any) => {
                    return item.UserState === 'Decommissioned'
                });
                setDecomisionado(Decomisionados.length);
                let Activos = response.data.filter((item: any) => {
                    return item.UserState !== 'Decommissioned'
                });
                setActivo(Activos.length)
                setDatosAssets(response.data);
                let Consult = [...Consultas]
                Consult[1].Cliente = row.original.ClienteId;
                Consult[1].Data = response.data;
                Consult[1].TipoCliente = row.original.TipoCliente;
                Consult[1].ClienteNombre = row.original.clienteNombre;
                setClienteGeneral({"ClienteId": row.original.ClienteId, "Nombre":row.original.clienteNombre});
                setConsultas(Consult);
                setloader(false);
                setShowModalactivos(true)
            }).catch((Error:AxiosError<any>) => {
                console.log("error ", Error);
                setloader(false);
            });
           
        }
        else {
            setDatosAssets(Consultas[1].Data);
            setShowModalactivos(true)
        }
    }
    const consultarDrivers = (row: any) => {
        if (row.original.ClienteId !== Consultas[2].Cliente) {
            setloader(true);
            GetDrivers(row.original.ClienteId).then((response: AxiosResponse<any>) => {
                setDatosDrivers(response.data);
                let Consult = [...Consultas]
                Consult[2].Cliente = row;
                Consult[2].Data = response.data;
                Consult[2].TipoCliente = row.original.TipoCliente;
                Consult[2].ClienteNombre = row.original.clienteNombre;
                setClienteGeneral({"ClienteId": row.original.ClienteId, "Nombre":row.original.clienteNombre});
                setConsultas(Consult);
                setloader(false);
                setShowModaldriver(true);
                setDriver(response.data.length);
            }).catch(() => {
                console.log("error");
                setloader(false);
            });
        } else {
            setShowModaldriver(true);
            setDatosDrivers(Consultas[2].Data);
        }
    }
    const consultarSitios = (row: any, recargar:boolean) => {
        if (row.original.ClienteId !== Consultas[3].Cliente || recargar ) {
            setloader(true);
            GetSites(row.original.ClienteId).then((response: AxiosResponse<any>) => {
                let Consult = [...Consultas]
                Consult[3].Cliente = row.original.ClienteId;
                Consult[3].Data = response.data;
                Consult[3].TipoCliente = row.original.TipoCliente;
                Consult[3].ClienteNombre = row.original.clienteNombre;
                setDatosSites(response.data)
                setClienteGeneral({});  setClienteGeneral({"ClienteId": row.original.ClienteId, "Nombre":row.original.clienteNombre});  setClienteGeneral({"ClienteId": row.original.ClienteId, "Nombre":row.original.clienteNombre});                setConsultas(Consult);
                setloader(false);
                setShowModalSitios(true);
                setSitios(response.data.length);
            }).catch(() => {
                console.log("error");
                setloader(false);
            });
        } else{
            setShowModalSitios(true);
            setDatosSites(Consultas[3].Data);
        }
            

    }

    const GetReporteConfiguracionAssets = (row: any) => {
        setloader(true);
        GetConfiguracionAssets(row).then((response: AxiosResponse<any>) => {
            DescargarExcel(response.data, ColumnasExportarExcel, "ReporteConfiguration");
            setloader(false);
        }).catch(() => {
            setloader(false);
            console.log("Error");
        })
    }

    const EditarCampos = (row: any) => {
        setclienteSeleccionado(row)
        setShowModal(true)
    }
    const EditarDriver = (row:any) =>{
        let drv = {...CamposDriver};
        drv.SiteId = String(row.SiteId);
        drv.Name = row.name;
        drv.ClienteId = String(row.ClienteId);
        drv.EsActivo = String(row.EsActivo);
        drv.DriverId = String(row.DriverId);
        drv.fmDriverId = String(row.fmDriverId);
        drv.employeeNumber = String(row.employeeNumber);
        drv.Clave = "2";
        setCamposDriver(drv);
        setShowModalDriver(true);
    }
    const CrearDrvier = () =>{
        let drv = {...CamposDriver};
        drv.SiteId = "";
        drv.Name = "";
        drv.ClienteId = Consultas[2].Cliente.original.ClienteId;
        drv.EsActivo = "true";
        drv.DriverId = "";
        drv.fmDriverId = "";
        drv.Clave = "1";
        drv.employeeNumber = "";
        setCamposDriver(drv);
        setShowModalDriver(true);
    }
    const CrearSite = () =>{
        let st = {...CamposSite};
        st.SiteId = "";
        st.SiteName = "";
        st.ClienteId = "";
        st.EsActivo = "true";
        st.Clave = "1";
        setCamposSite(st);
        setshowModalSites(true);
    }
    const EditarSite = (row:any) =>{
        let st = {...CamposSite};
        st.SiteId =String( row.siteid);
        st.SiteName = row.sitename;
        st.ClienteId = String(row.clienteid);
        st.EsActivo = String(row.EsActivo) ;
        st.Clave = "2";
        setCamposSite(st);
        setshowModalSites(true);
    }
    const DetallesSystem = (row: any) => {
        let Datos = {
            show,
            setShow,
            Title: `Configuraciones del sistema para el cliente ${row.original.clienteNombre}`,
            DetallesDatos: (EsJson(row.original.ParamsSistema) ? DeserializarJSon(row.original.ParamsSistema) :
                DatosDefaultSystem),
            columnas: {},
            ClienteId: row.original.ClienteId,
            Data: row.original,
        }
        Datos.show = true;
        Datos.setShow(true);
        setDataModal(Datos);
    }

    const EditarAsset  = (row:any) =>{
        let txtAdicional = {
            'Mix Vision':"",
            'OBC Syscaf':"",
            'Activo facturable':"",
            'Vertical':"",
            'Paquete comercial':"",
            'Administrador de flota':""
        };

        let adicionales = (row.AdditionalDetails != null ? JSON.parse(row.AdditionalDetails):txtAdicional);
        let conf = {...Configuracion}
            conf.TipoSeleccionado = TiposSeleccionados(conf.Tipos,row.AssetTypeId);
            conf.AssetId = row.AssetId;
            conf.Clave = "2";
            conf.Description = row.Description;
            conf.RegistrationNumber= row.RegistrationNumber;
            conf.SiteId=row.SiteId;
            conf.ClienteId=Consultas[1].Cliente;
            conf.CreateBy = row.CreatedBy;
            conf.UserState = row.UserState;
            conf.EstadoSeleccionado = row.UserState;
            conf.isVisible = (row.AdditionalDetails != null ? true:false);
            conf.AdditionalDetails = adicionales;
            conf.AdditionalDetails.MIXSeleccionado = adicionales['Mix Vision'];
            conf.AdditionalDetails.OBCSeleccionado = adicionales['OBC Syscaf'];
            conf.AdditionalDetails.ActivoSeleccionado = adicionales['Activo facturable'];
            conf.AdditionalDetails.PaqueteSeleccionado = adicionales['Paquete comercial'];
            conf.AdditionalDetails.VerticalSeleccionado = adicionales['Vertical'];
            conf.AdditionalDetails.AdminSeleccionado = (adicionales['Administrador de flota'] == undefined ? "": adicionales['Administrador de flota'].split(" - ")[0]) ;
        setConfiguracion(conf);
        setShowModalAsset(true)
    }

    const NuevoAsset = () =>{
        let conf = {...Configuracion};
        conf.isVisible = false;
        conf.TipoSeleccionado= "";
        conf.AssetId="";
        conf.Clave="1";
        conf.Description="";
        conf.RegistrationNumber="";
        conf.ClienteId = Consultas[1].Cliente;
        conf.CreateBy=  vUser.Nombres;
        conf.EstadoSeleccionado = "null";
        conf.SiteId= "";
        conf.UserState="";
        conf.AdditionalDetails = {
                ActivoSeleccionado: "null",
                VerticalSeleccionado: "null",
                PaqueteSeleccionado:"null",
                TipoSeleccionado:0,
                MIXSeleccionado:"null",
                OBCSeleccionado:"null",
                AdminSeleccionado:""
            }
        setConfiguracion(conf);
        setShowModalAsset(true);
    };
    //Modificar
    const EliminarSitio = (row:any) =>{
        let Campos = {
            SiteId : String(row.siteid),
            SiteName: String(row.sitename),
            ClienteId : String(row.clienteid),
            EsActivo:String((row.EsActivo == true ? false:true)),
            Clave:"3"
        }
        let Consult = [...Consultas]
        let Dato = {
            original:{
                ClienteId: Consult[3].Cliente,
                TipoCliente :  Consult[3].TipoCliente
            }
        }
        confirmarDialog(() => {
            setloader(true);
            SetParametroSite(Campos).then((response: AxiosResponse<any>) => {
                toaster.push(message((response.data[0].Id == "-1" ? 'error':'success') , "Sitios", response.data[0].Mensaje), {
                    placement: 'topCenter'
                });
                consultarSitios(Dato, true);
            }).catch(() => {
                setloader(false);
                console.log("Error");
            })
        }, `¿Esta seguro que desea  eliminar el sitio?`, 'Guardar');
    }
    return (
        <>
            <PageTitle>Clientes</PageTitle>
            <BlockUi tag="div" keepInView blocking={loader ?? false}  >
                {(DatosClientes.length !== 0) && (<MaterialReactTable
                    // tableInstanceRef={ColumnasTablas['movil']}
                    localization={MRT_Localization_ES}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 280,
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: (theme) => ({
                            fontSize: 14,
                            fontStyle: 'bold',
                            color: 'rgb(27, 66, 94)'
                        }),
                    }}
                    columns={Campos}
                    data={DatosClientes}
                    enableEditing
                    editingMode="modal" //default         
                    enableColumnOrdering
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                color: 'error',
                                children: 'Error al cargar información',
                            }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    enableStickyHeader
                    enableDensityToggle={false}
                    enableRowVirtualization
                    enablePagination={false}
                    defaultColumn={{
                        minSize: 150, //allow columns to get smaller than default
                        maxSize: 500, //allow columns to get larger than default
                        size: 500, //make columns wider by default
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: '600px' }, //give the table a max height
                    }}
                    initialState={{ density: 'compact' }}
                    renderRowActions={({ row, table }) => (
                      
                        <Box sx={{ display: 'block', gap: '1rem', marginLeft: 'auto', marginRight: 'auto', alignItems:'left', alignContent:'left', textAlign:'left' }}>
                              <Tooltip arrow placement="top" title="Vehiculos del cliente">
                                <IconButton onClick={() => {
                                    // PanelConsultas(row.original.ClienteId)
                                    consultarVehiculos(row);
                                }}>
                                    <FireTruckTwoTone className="text-black" />
                                </IconButton>
                            </Tooltip>
                           
                             <Tooltip arrow placement="top" title="Conductores del cliente">
                                <IconButton onClick={() => {
                                    // PanelConsultas(row.original.ClienteId)
                                    consultarDrivers(row);
                                }}>
                                    <Person className="text-info" />
                                </IconButton>
                            </Tooltip>
                             <Tooltip arrow placement="top" title="Sitios del cliente">
                                <IconButton onClick={() => {
                                    // PanelConsultas(row.original.ClienteId)
                                    consultarSitios(row, false);
                                }}>
                                    <LocationCity className="text-info" />
                                </IconButton>
                            </Tooltip>
                   
                            <Tooltip arrow placement="top" title="Gestionar campos de cliente">
                                <IconButton onClick={() => {
                                    EditarCampos(row.original)
                                }}>
                                    <Edit className="text-danger" />
                                </IconButton>
                            </Tooltip>
                            {(row.original.TipoCliente == ClienteMix || row.original.TipoCliente == "0" || row.original.TipoCliente == null ) && (
                                 <Tooltip arrow placement="top" title="Descargar detallado del cliente">
                                 <IconButton onClick={() => { GetReporteConfiguracionAssets(row.original.ClienteId) }
                                 }>
                                     <Download className="text-warning" />
                                 </IconButton>
                             </Tooltip>
                            )}

                            {(EsPermitido(permisosopcion, operacionesPermisos.AsigSitio)) && (
                            <Tooltip arrow placement="top" title="Configurar datos del sistema">
                                <IconButton onClick={() => {
                                    DetallesSystem(row);
                                }
                                }>
                                    <SystemUpdate className="text-success" />
                                </IconButton>
                            </Tooltip>
                         )}

                        </Box>
                    )}
                    muiExpandButtonProps={({ row }) => ({
                        onClick: () => {
                            //al expandir consulta el admin antes no
                            consultarAdmins(row.original.ClienteId);
                        }
                    })
                    }
                    enableExpandAll={false}
                    state={{
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                />)}
            </BlockUi>
        {(showModal) && (<ModalEditarCliente showModal={showModal} setShowModal={setShowModal}
            ClienteSeleccionado={clienteSeleccionado} plantillaModulos={plantillaModulos}></ModalEditarCliente>)}

            <Modal show={showModalactivos} onHide={setShowModalactivos} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title> {"Vehiculos del cliente"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="d-flex w-100" >
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="d-flex align-items-center justify-content-center mb-2" data-placement="top" title="Activos">
                                                <a href="#" className="btn bg-transparent border border-primary text-primary rounded-pill border-2 btn-icon mr-3">
                                                    <i className="bi-truck-front"></i>
                                                </a>
                                                <div>
                                                    <div className="fw-bolder text-primary">Activos</div>
                                                    <span className="text-muted fw-bolder text-primary" data-placement="top" title="Cantidad de vehiculos activos">{Activo}</span>&nbsp;&nbsp;<span></span>
                                                </div>
                                            </div>
                                            <div className="w-75 mx-auto mb-3" id="new-visitors"></div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="d-flex align-items-center justify-content-center mb-2" data-placement="top" title="Decomisionados">
                                                <a href="#" className="btn bg-transparent border border-danger text-danger rounded-pill border-2 btn-icon mr-3">
                                                    <i className="bi-truck-front"></i>
                                                </a>
                                                <div>
                                                    <div className="fw-bolder text-danger">Decomisionados</div>
                                                    <span className="text-muted fw-bolder text-danger" data-placement="top" title="Cantidad de vehiculos decomisionados">{Decomisionado}</span>&nbsp;&nbsp;<span></span>
                                                </div>
                                            </div>
                                            <div className="w-75 mx-auto mb-3" id="new-visitors"></div>
                                        </div>
                                        {(Consultas[1].TipoCliente == ClienteTelematico) && (<div className="col-sm-4">
                                            <div className="d-flex align-items-center justify-content-center mb-2" data-placement="top" title="Agregar">
                                                    <button className="btn  btn-sm btn-primary" onClick={NuevoAsset}> <i className="bi-patch-plus-fill"></i> Nuevo</button>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <MaterialReactTable
                                    localization={MRT_Localization_ES}
                                    columns={CamposRender.Asset}
                                    data={DatosAssets}
                                    initialState={{ density: 'compact' }}
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enableRowVirtualization
                                    enablePagination={false}
                                    enableEditing
                                    editingMode="modal" //default  
                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            muiTableHeadCellProps: {
                                                align: 'center',
                                            },
                                            size: 100,
                                            maxSize:500
                                        },
                                    }}
                                    renderRowActions={({ row, table }) => (
                                        <Box sx={{ display: 'block', gap: '1rem', marginLeft: 'auto', marginRight: 'auto' }}>
                                        {(Consultas[1].TipoCliente == ClienteTelematico) && (
                                        <Tooltip arrow placement="top" title="Editar asset">
                                          <IconButton onClick={() => {
                                            EditarAsset(row.original);
                                           
                                          }}>
                                              <Edit className="text-danger" />
                                          </IconButton>
                                      </Tooltip>
                                       )} 
                                  </Box>
                                    )}
                                    renderDetailPanel={({ row }) => (
                                        <Box
                                            sx={{
                                                display: 'grid',
                                                margin: 'auto',
                                                gridTemplateColumns: '1fr 1fr',
                                                width: '100%',
                                            }}
                                        >
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-6" >
                                                        <Typography><b>unitIMEI</b>: {row.original.UnitIMEI == "" ? "-" : row.original.UnitIMEI}</Typography>
                                                        <Typography><b>unitSCID</b>: {row.original.UnitSCID == "" ? "-" : row.original.UnitSCID}</Typography>
                                                    </div>
                                                    <div className="col-sm-6" >
                                                        <Typography><b>ingresoSalida</b>: {row.original.ingresoSalida == "" ? "-" : row.original.ingresoSalida}</Typography>
                                                        <Typography><b>esManual</b>: {(row.original.EsManual == null ? "-" : row.original.EsManual)}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </Box>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary" onClick={() => { setShowModalactivos(false); }}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModaldriver} onHide={setShowModaldriver} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title> {"Conductores del cliente"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12">
                            <div className="d-flex w-100" >
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-4">
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="d-flex align-items-center justify-content-center mb-2" data-placement="top" title="Conductores">
                                                <a href="#" className="btn bg-transparent border border-info text-primary rounded-pill border-2 btn-icon mr-3">
                                                    <i className="bi-person"></i>
                                                </a>
                                                <div>
                                                    <div className="fw-bolder text-info">Conductores</div>
                                                    <span className="text-muted fw-bolder" data-placement="top" title="Cantidad de conductores del cliente">{Driver}</span>&nbsp;&nbsp;<span></span>
                                                </div>
                                            </div>
                                            <div className="w-75 mx-auto mb-3" id="new-visitors"></div>
                                        </div>
                                        {(Consultas[2].TipoCliente == ClienteTelematico) && (<div className="col-sm-4">
                                            <div className="d-flex align-items-center justify-content-center mb-2" data-placement="top" title="Agregar">
                                                    <button className="btn  btn-sm btn-primary" onClick={CrearDrvier}>  <i className="bi-patch-plus-fill"></i> Nuevo</button>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <MaterialReactTable
                                    localization={MRT_Localization_ES}
                                    columns={CamposRender.Driver}
                                    data={DatosDriver}
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enableRowVirtualization
                                    enablePagination={false}
                                    enableEditing
                                    editingMode="modal" //default 
                                    initialState={{ density: 'compact' }}
                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            muiTableHeadCellProps: {
                                                align: 'center',
                                            },
                                            size: 5,
                                            maxSize:25
                                        },
                                    }}
                                    renderRowActions={({ row, table }) => (
                                        <Box sx={{ display: 'block', gap: '1rem', marginLeft: 'auto', marginRight: 'auto' }}>
                                        {(Consultas[2].TipoCliente == ClienteTelematico) && (
                                        <Tooltip arrow placement="top" title="Editar Conductor">
                                          <IconButton onClick={() => {
                                               EditarDriver(row.original);
                                          }}>
                                              <Edit className="text-danger" />
                                          </IconButton>
                                      </Tooltip>
                                       )} 
                                  </Box>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary" onClick={() => { setShowModaldriver(false); }}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            {(DataModal.show) && (<ModalSystem Data={DataModal}></ModalSystem>)}
            {
                (showModalSitios)  &&(
                    <Modal show={showModalSitios} onHide={setShowModalSitios} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title> {"Sitios del cliente"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12 col-xl-12 col-md-12 col-lg-12">
                            <div className="d-flex w-100" >
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-4">
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="d-flex align-items-center justify-content-center mb-2" data-placement="top" title="Conductores">
                                                <a href="#" className="btn bg-transparent border border-info text-primary rounded-pill border-2 btn-icon mr-3">
                                                    <i className="bi-person"></i>
                                                </a>
                                                <div>
                                                    <div className="fw-bolder text-info">Sitios</div>
                                                    <span className="text-muted fw-bolder" data-placement="top" title="Cantidad de conductores del cliente">{Sitios}</span>&nbsp;&nbsp;<span></span>
                                                </div>
                                            </div>
                                            <div className="w-75 mx-auto mb-3" id="new-visitors"></div>
                                        </div>
                                        {(Consultas[3].TipoCliente == ClienteTelematico) && (<div className="col-sm-4">
                                            <div className="d-flex align-items-center justify-content-center mb-2" data-placement="top" title="Agregar">
                                                    <button className="btn  btn-sm btn-primary" onClick={CrearSite}> <i className="bi-patch-plus-fill"></i> Nuevo</button>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <MaterialReactTable
                                    localization={MRT_Localization_ES}
                                    columns={CamposRender.Sites}
                                    data={DatosSites}
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enableRowVirtualization
                                    enablePagination={false}
                                    initialState={{ density: 'compact' }}
                                    enableEditing
                                    editingMode="modal"//default 
                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            muiTableHeadCellProps: {
                                                align: 'center',
                                            },
                                            size: 50,
                                            maxSize:50
                                        },
                                    }}
                                    renderRowActions={({ row, table }) => (
                                        <Box sx={{ display: 'block', gap: '1rem', marginLeft: 'auto', marginRight: 'auto' }}>
                                        {(Consultas[3].TipoCliente == ClienteTelematico) && (
                                        <Tooltip arrow placement="top" title="Editar sitio">
                                          <IconButton onClick={() => {
                                           EditarSite(row.original);
                                          }}>
                                              <Edit className="text-warning" />
                                          </IconButton>
                                      </Tooltip>
                                      
                                       )} 
                                       {(Consultas[3].TipoCliente == ClienteTelematico) && (
                                            <Tooltip arrow placement="top" title="Eliminar sitio">
                                            <IconButton onClick={() => {
                                                EliminarSitio(row.original);
                                            }}>
                                                <Delete className="text-danger" />
                                            </IconButton>
                                        </Tooltip>
                                       )}
                                  </Box>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary" onClick={() => { setShowModalSitios(false); }}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
                )
            }
            {(showModalAsset) && (<ModalAsset  setDatosAssets={setDatosAssets}  showModal={showModalAsset} setShowModal={setShowModalAsset} Cliente={Consultas[1].ClienteNombre} ClienteSeleccionado={Consultas[1].Cliente} ConfiguracionGeneral={Configuracion} setloader={setloader} Consultas={Consultas} setConsultas={setConsultas} TipoCliente={Consultas[1].TipoCliente} Sitios={Consultas[1].Sitios}></ModalAsset>)}
            {(showModalDriver) && (<ModalDriver showModal={showModalDriver} setShowModal={setShowModalDriver} Cliente={Consultas[2].Cliente.original} ClienteSeleccionado={Consultas[2].Cliente} setloader={setloader} setCamposDriver={setCamposDriver} CamposDriver={CamposDriver} Consultas={Consultas} setConsultas={setConsultas} TipoCliente={Consultas[2].TipoCliente} Sitios={Consultas[1].Sitios}/>)}
            {(showModalSites) && (<ModalSites setDatosSites={setDatosSites} showModal={showModalSites} setShowModal={setshowModalSites} ClienteSeleccionado={Consultas[3].Cliente} setloader={setloader} setCamposSite={setCamposSite} CamposSite={CamposSite} Consultas={Consultas} setConsultas={setConsultas} TipoCliente={Consultas[3].TipoCliente} Cliente={ClienteGeneral} />)}
            
        </>
    )
}

