import { useEffect, useState } from "react"
import { PageTitle } from "../../../../_start/layout/core"
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table"
import { MRT_Localization_ES } from "material-react-table/locales/es"
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Update } from "@mui/icons-material";
import BlockUi from "@availity/block-ui";
import { AxiosError, AxiosResponse } from "axios";
import { DeleteEstados, EstadosTpos, GetEstados, GetEstadosCliente, SetEstados, SetEstadosClienteEspecificos, Tooltips } from "./data/Estados";
import { ModalEstado } from "./components/Estados/ModalEstados";
import confirmarDialog, { errorDialog, successDialog } from "../../../../_start/helpers/components/ConfirmDialog";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../_start/helpers/components/HtlmTooltip";
import { ObtenerListadoCLientes } from "./data/Reporte";
import { Form } from "react-bootstrap-v5";

export default function Estados() {
    const isAuthorized = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const Cliente = {
        clienteIdS:"0",
        ClienteId:0,
        clienteNombre:"Todos"
    }
    const Config = '[{ "MinAmber":"0","MaxAmber":"0", "Orden":"0", "Semaforizado":"true" ,"PermitePosponer":"true"}]';

    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [loader, setloader] = useState<boolean>(false);
    const TipoIds = "3";
    const [show, setshow] = useState<boolean>(false);
    const [Clientes, setClientes] = useState<any[]>([]);
    const [ClienteSeleccionado, setClienteSeleccionado] = useState<any>(Cliente);
    const [Data, setData] = useState<any[]>([]);
    const [Copy, setCopy] = useState<any[]>([]);
    const [Elemento, setElemento] = useState<number>(1);
    const [ConfiguracionEstado, setConfiguracionEstado] = useState<any[]>([]);
    let a:any =1; 
    // let DataOriginal:any[];
    const [Estado, setEstado] = useState<any>({
        "Clave": "1",
        "Estado": "",
        "TipoIdS": "3",
        "Icono": "",
        "ModalTitulo": "Nuevo estado",
        "EstadoIdS": null,
        "Configuracion": JSON.parse(Config)
    })
    useEffect(() => {
        CargaClientes();
        return () => { }
    }, [])
    useEffect(() => {
        if(ClienteSeleccionado.clienteIdS == "0")
            ConsultarEstados();
        return () => { }
    }, [ClienteSeleccionado])

    useEffect(() =>{
        let el = Elemento;
        el = el+1;
        setElemento(el);
    },[Data, Copy])

    let listadoCampos: MRT_ColumnDef<any>[] =

        [
            {
                accessorKey: 'estado',
                header: 'Nombre',
                size: 100
            },
            {
                accessorKey: 'Icono',
                header: 'Icono',
                size: 100,
                Cell({ cell, column, row, table, }) {
                    return <span className=""><i className={`${row.original.Icono}`}></i></span>
                },
            },
            {
                accessorKey: 'Orden',
                header: 'Orden',
                size: 100,
                Cell({ cell, column, row, table, }) {
                    let i = (row.original.Configuracion == null ? "-" : parseInt(JSON.parse(row.original.Configuracion)[0].Orden))
                    return <span className=""><i className={`${(i == "0" ? "bg-success" : "")}`}></i>{i}</span>
                },
            },
            {

                header: 'Max Verde',
                size: 100,
                Cell({ cell, column, row, table, }) {
                    let i = (row.original.Configuracion == null || row.original.Configuracion == "" ? "-" : parseInt((JSON.parse(row.original.Configuracion)[0].MinAmber =="" ? "0":JSON.parse(row.original.Configuracion)[0].MinAmber)) - 1)
                    return (i)
                },
            },
            {
                accessorKey: 'MinAmber',
                header: 'Min Ambar',
                size: 100,
                Cell({ cell, column, row, table, }) {
                    let i = (row.original.Configuracion == null ? "-"  : ( JSON.parse(row.original.Configuracion)[0].MinAmber == "" ? " - " : JSON.parse(row.original.Configuracion)[0].MinAmber ) );
                    return (i);
                },
            },
            {
                accessorKey: 'MaxAmber',
                header: 'Max Ambar',
                size: 100,
                Cell({ cell, column, row, table, }) {
                    let i = (row.original.Configuracion == null ? "-"  : (JSON.parse(row.original.Configuracion)[0].MaxAmber == "" ? "-":JSON.parse(row.original.Configuracion)[0].MaxAmber));
                    return (i);
                },
            },
            {

                header: 'Min Rojo',
                size: 100,
                Cell({ cell, column, row, table, }) {
                    let i = (row.original.Configuracion == null ? "-"  : parseInt((JSON.parse(row.original.Configuracion)[0].MaxAmber =="" ? "0":JSON.parse(row.original.Configuracion)[0].MaxAmber)) + 1)
                    return (i)
                },
            }
        ];

    
    const Guardar = (Estado: any) => {
        confirmarDialog(() => {
            setloader(true);
            SetEstados(Estado).then((response: AxiosResponse<any>) => {
                setData(response.data);
                successDialog("Operación Éxitosa.", "");
                setloader(false);
                setshow(false);
            }).catch((error: AxiosError<any>) => {
                console.log(error);
                setloader(false);
            });
        }, `Esta seguro que desea guardar el estado`, 'Guardar');
    }

    const Eliminar = (row: any) => {
        let Datos: any = {};
        Datos.EstadoIdS = row.original.estadoIdS
        Datos.TipoIdS = row.original.tipoIdS;
        confirmarDialog(() => {
            setloader(true);
            DeleteEstados(Datos).then((response: AxiosResponse<any>) => {
                setData(response.data);
                successDialog("Operación Éxitosa.", "");
                setloader(false);
                setshow(false);
            }).catch((error: AxiosError<any>) => {
                console.log(error);
                setloader(false);
            });
        }, `Esta seguro que desea borrar el estado, esta accion no podra revertirse`, 'Borrar');


    }


    const EditarEstado = (row: any) => {
        setEstado({
            "Clave": "2",
            "Estado": row.original.estado,
            "TipoIdS": row.original.tipoIdS,
            "Icono": row.original.Icono,
            "ModalTitulo": `Edicion del estado ${row.original.estado}`,
            "EstadoIdS": row.original.estadoIdS,
            "Configuracion": JSON.parse((row.original.Configuracion != undefined ? row.original.Configuracion : Config))
        })
        setshow(true);
    }

    

    const CargaClientes = () => {
        setloader(true);
        ObtenerListadoCLientes().then((response:AxiosResponse<any>) => {
            setClientes(response.data);
            setloader(false);
        }
        ).catch((error) => {
            errorDialog("Consultar clientes", "Error al consultar los clientes, no se puede desplegar informacion");
            setloader(false);
        });
    }

    
    const ConsultarEstados = () => {
        setloader(true);
        GetEstados(TipoIds).then((response: AxiosResponse<any>) => {
            setData(response.data);
            setloader(false);
        }).catch((error: AxiosError<any>) => {
            console.log(error);
            setloader(false);
        })
    }
 
    const consultarEstadoCliente = (ClienteId:any) =>{
        if(ClienteId != 0){
            let al =  Data.filter((val:any) =>{
                val.Configuracion = JSON.stringify([
                  {
                    "MinAmber":"0",
                    "MaxAmber":"0",
                    "Orden":"0",
                    "Semaforizado":false,
                    "PermitePosponer":false,
                    "CantidadMax":"0",
                    "Cantidad":"0",
                    "Veces":"0"
                  }
                ]);
              return val;
        })
        setloader(true);
            GetEstadosCliente(ClienteId).then((response:AxiosResponse<any>) =>{
                setData((response.data[0].ConfiguracionEstado  != null ? JSON.parse(response.data[0].ConfiguracionEstado): al));
                setloader(false);
            }).catch((error:AxiosError<any>) =>{
                console.log("Error al consultar estados");
                setloader(false);
            });
        }
        else{
            setData(Copy)
        }
    };

    const ClienteComponentes = () =>{
        return(
                <Form.Select defaultValue={ClienteSeleccionado.ClienteId}  className=" h-40px w-300px " onChange={(e) => {
                    let cli = Clientes?.filter((val:any) =>{
                            return val.ClienteId == e.currentTarget.value;
                    });
                    setClienteSeleccionado((cli.length>0 ? cli[0]: Cliente));
                    consultarEstadoCliente((cli.length>0 ? cli[0].ClienteId: Cliente.ClienteId));
                }} aria-label="Default select example">
                    <option value={0}>Todos</option>
                    {           
                        Clientes?.map((element:any,i:any) => {
                            return (<option key={element.ClienteId}  value={(element.ClienteId != null ? element.ClienteId:0)}>{element.clienteNombre}</option>)
                        })
                    }
                </Form.Select>    
                )
        }


        const EdicionEstadosClienteEspecifico = (Estado: any) =>{
            let r =  [...Data];
            let s:any[] = r.map((val:any) =>{
                if(String(val.estadoIdS) == String(Estado.EstadoIdS) ){
                    val.Configuracion = Estado.Configuracion;
                    val.estado=  Estado.Estado;
                    val.Icono = Estado.Icono;
                }
                console.log(val);
                console.log(Estado);
                return {
                    "estado": val.estado,
                    "tipoIdS": val.tipoIdS,
                    "Icono": val.Icono,
                    "estadoIdS": val.estadoIdS,
                    "Configuracion":val. Configuracion
                };
            });
            
            if(Estado.EstadoIdS == null || Estado.EstadoIdS == "null" || Estado.EstadoIdS == undefined || Estado.EstadoIdS == "")
                s.push({
                    "estado": Estado.Estado,
                    "tipoIdS": Estado.TipoIdS,
                    "Icono": Estado.Icono,
                    "estadoIdS": (Estado.EstadoIdS == null || Estado.EstadoIdS == "null" ? "-1":Estado.EstadoIdS),
                    "Configuracion":Estado.Configuracion
                });
            setConfiguracionEstado(s);  
            confirmarDialog(() => {
                setloader(true);
                SetEstadosClienteEspecificos(JSON.stringify(s),ClienteSeleccionado.ClienteId).then((response:AxiosResponse) =>{
                    setData(JSON.parse(response.data[0].ConfiguracionEstado));
                    successDialog("Operación Éxitosa.", "");
                    setloader(false);
                    setshow(false);
                }).catch((error:AxiosError) =>{
                    console.log("Error");
                    setloader(false);
                });
            }, `¿Esta seguro que desea guardar el estado personalizado para el cliente ${ClienteSeleccionado.clienteNombre}?`, 'Guardar');

            
        }
        const elimina = (array:any, Estado:any) => {
            console.log(Estado);
            return array.filter((e:any) => e.estado !== Estado.Estado);
          }

        const EliminarEspecifico = (row: any) => {
            let Ressultado = elimina(Data, { Estado: row.original.estado})
            confirmarDialog(() => {
                setloader(true);
                SetEstadosClienteEspecificos(JSON.stringify(Ressultado),ClienteSeleccionado.ClienteId).then((response:AxiosResponse) =>{
                    setData(JSON.parse(response.data[0].ConfiguracionEstado));
                    successDialog("Operación Éxitosa.", "");
                    setloader(false);
                    setshow(false);
                }).catch((error:AxiosError) =>{
                    console.log("Error");
                    setloader(false);
                });
            }, `¿Esta seguro que desea borrar el estado personalizado para el cliente ${ClienteSeleccionado.clienteNombre}?`, 'Guardar');
        }
    
    return (
        <>
            <PageTitle>Configuración de estados para transmisión</PageTitle>
            <BlockUi tag="div" keepInView blocking={loader ?? false}  >
                < div >
                    {/* Este espacio es la cabecera de la pagina */}
                    <div className="card">
                        <div className="d-flex justify-content-between mb-2">
                            <div className="mx-auto">
                                <div className="ms-3 text-center">
                                    <h3 className="mb-0"> Listado Estados tx </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  w-100">
                        <div className="col-6">
                            <label className="control-label label label-sm fw-bolder">Cliente</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><i className="bi-file-earmark-person"></i></span>
                                <ClienteComponentes/>
                            </div>
                        </div>
                        <div className="col-6">
                            <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Nuevo"].title, Tooltips["Nuevo"].content)} >
                                <button className="float-end btn btn-sm btn-primary" onClick={(e: any) => {
                                    setEstado({
                                        "Clave": "1",
                                        "Estado": "",
                                        "TipoIdS": "3",
                                        "Icono": "",
                                        "ModalTitulo": "Nuevo estado",
                                        "EstadoIdS": null,
                                        "Configuracion": JSON.parse(Config)
                                    });
                                    setshow(true);
                                }}><i className="bi-plus"></i>Nuevo</button>
                            </HtmlTooltipStyled>

                        </div>
                    </div>
                    {/* Fin del encabezado */}
                    <div className="mt-5">
                        {(Data.length >0) && (Elemento) && (<MaterialReactTable
                            localization={MRT_Localization_ES}
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 90,
                                },
                            }}
                            columns={listadoCampos}
                            data={Data}
                            // editingMode="modal" //default         
                            enableTopToolbar={false}
                            enableColumnOrdering
                            enableEditing
                            enablePagination={true}
                            // enableEditing
                            /* onEditingRowSave={handleSaveRowEdits}
                                onEditingRowCancel={handleCancelRowEdits}*/
                            muiToolbarAlertBannerProps={
                                isError
                                    ? {
                                        color: 'error',
                                        children: 'Error al cargar información',
                                    }
                                    : undefined
                            }
                            onColumnFiltersChange={setColumnFilters}
                            onGlobalFilterChange={setGlobalFilter}
                            onPaginationChange={setPagination}
                            onSortingChange={setSorting}
                            rowCount={rowCount}
                            initialState={{ density: 'compact' }}
                            state={{
                                columnFilters,
                                globalFilter,
                                isLoading,
                                pagination,
                                showAlertBanner: isError,
                                showProgressBars: isRefetching,
                                sorting,
                            }}
                            muiTableBodyCellProps={({ row, cell }) => ({
                                sx: {
                                    backgroundColor:


                                        cell.column.id == 'Min Rojo' && (JSON.parse(cell.row.original.Configuracion) != null && JSON.parse(cell.row.original.Configuracion)[0].Semaforizado) ? 'rgba(248, 215, 218, 1)' :
                                            cell.column.id == 'Max Verde' && (JSON.parse(cell.row.original.Configuracion) != null && JSON.parse(cell.row.original.Configuracion)[0].Semaforizado) ? 'rgba(212, 237, 218, 1)' :
                                                (cell.column.id == 'MinAmber' && (JSON.parse(cell.row.original.Configuracion) != null && JSON.parse(cell.row.original.Configuracion)[0].Semaforizado) || cell.column.id == 'MaxAmber' && (JSON.parse(cell.row.original.Configuracion) != null && JSON.parse(cell.row.original.Configuracion)[0].Semaforizado))
                                                    ? 'rgba(255, 243, 205, 1)' : ''
                                }

                            })}
                            renderRowActions={({ row, table }) => (
                                <>
                                    <Box sx={{ display: 'flex', gap: '1rem' }}>

                                        <HtmlTooltipStyled placement="left" arrow title={HtmlContentTitle(Tooltips["Modificar"].title, Tooltips["Modificar"].content)} >
                                            <IconButton onClick={() => { EditarEstado(row) }}>
                                                <Edit className="text-success" />
                                            </IconButton>
                                        </HtmlTooltipStyled>
                                        <HtmlTooltipStyled placement="right" arrow title={HtmlContentTitle(Tooltips["Eliminar"].title, Tooltips["Eliminar"].content)} >
                                            <IconButton onClick={() => { 
                                                (ClienteSeleccionado.clienteIdS != "0" ? EliminarEspecifico(row) : Eliminar(row))}}>
                                                <Delete className="text-danger" />
                                            </IconButton>
                                        </HtmlTooltipStyled>
                                    </Box>
                                </>
                            )
                            }
                        />)} 
                    </div>
                </div>
            </BlockUi>
            {(show) && (<ModalEstado Guardar={(ClienteSeleccionado.clienteIdS != "0" ? EdicionEstadosClienteEspecifico : Guardar)} show={show} setshow={setshow} Data={Estado} setData={setEstado} loader={loader} setloader={setloader} DatosCompletos={Data} />)}
        </>
    )
}