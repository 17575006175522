
import { useDataUsuarios } from "../../../core/usuariosProvider";
import { NuevoUsuario, Usuarios_Tooltips, ValidarUsuario } from "../../../data/usuariosdata";
import { useEffect, useState } from "react";
import { ECO_Usuario_InformacionBasica } from "./informacionBasica";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../../_start/helpers/components/HtlmTooltip";
import { Button } from "react-bootstrap-v5";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../../_start/helpers/components/ConfirmDialog";
import { PostUsuario } from "../../../../../auth/data/datPerfil";
import { AxiosError, AxiosResponse } from "axios";
import { ECO_Usuario_InformacionSeguridad } from "./InformacionSeguridad";
export const TabSeguridad: React.FC<{ show: boolean, setshow: any }> = ({ show, setshow }) => {
    const { lstUsuarios, setloader, setlstUsuarios, usuarioSel, setUsuarioSel } = useDataUsuarios();
    const [Usuario, setUsuario] = useState<any>(NuevoUsuario);
    useEffect(() => {
        setUsuario(usuarioSel);
    }, [usuarioSel]);

    const Guardar = () => {
        if (ValidarUsuario(Usuario)) {
            confirmarDialog(() => {
                setloader(true);
                let DatosUsuarios = Usuario;
                DatosUsuarios.DetallesAdicionales = JSON.stringify({
                    "Tipo": (DatosUsuarios.Cargo == null ? "Usuario SYSCAF" : DatosUsuarios.Cargo)
                })
                DatosUsuarios.UserName = DatosUsuarios.Correo.toLowerCase();
                PostUsuario(DatosUsuarios, DatosUsuarios.isNew).then((response: AxiosResponse<any>) => {
                    successDialog(`Operación Éxitosa`, "");
                    if (response.status == 200) {
                        if(response.data?.exitoso) {

                        
                        let _data = [...lstUsuarios];
                        const usuario = {...usuarioSel,  ...DatosUsuarios, Id: response.data?.data?.id, Nombres: `${DatosUsuarios.Nombres} ${DatosUsuarios.Apellidos}`
                        , Email: DatosUsuarios.Correo };
                        if (DatosUsuarios.isNew)
                            _data.push(usuario);
                        else
                            _data = _data.map((val: any) => {
                                if (val.Id == DatosUsuarios.Id) {
                                    val.Nombres = `${DatosUsuarios.Nombres} ${DatosUsuarios.Apellidos}`;
                                    val.Email = DatosUsuarios.Correo;
                                    val.Perfil = DatosUsuarios.Perfil;
                                    val.Estado = DatosUsuarios.Estado;
                                    val.ClienteNombre = DatosUsuarios.ClienteNombre;
                                    val.PhoneNumber = DatosUsuarios.PhoneNumber;
                                    val.ClienteId = DatosUsuarios.ClienteId;
                                }
                                return val;
                            });
                        setlstUsuarios(_data);
                        setUsuarioSel(usuario);
                    }else{
                        errorDialog("Error al guardar usuario",response.data?.mensaje);
                    }
                    }
                }).catch((error: AxiosError<any>) => {
                    errorDialog("Error al guardar usuario", error.message);
                    
                }).finally(() => { setloader(false); });
            }, `Esta seguro que desea guardar el usuario`, 'Guardar');
        }
    }


    return (<>
        <ECO_Usuario_InformacionBasica />
        <ECO_Usuario_InformacionSeguridad />
        <div className="d-flex justify-content-center">
            <HtmlTooltipStyled placement={'top'} title={HtmlContentTitle(Usuarios_Tooltips["Guardar"].title, Usuarios_Tooltips["Guardar"].content)} >
                <Button type="button" className="btn btn-sm m-2" variant="primary" onClick={() => {
                    Guardar();
                }}>
                    Guardar
                </Button>
            </HtmlTooltipStyled>
            <HtmlTooltipStyled placement={'top'} title={HtmlContentTitle(Usuarios_Tooltips["Cerrar"].title, Usuarios_Tooltips["Cerrar"].content)} >
                <Button type="button" className="btn btn-sm m-2" variant="secondary" onClick={() => {
                    setshow(false);
                    setUsuarioSel(NuevoUsuario);
                }}>
                    Cerrar
                </Button>
            </HtmlTooltipStyled>
        </div>
    </>)
}