
import { FilroPeticiones } from "../../../data/consultasGenerales";
import { useDataServicios } from "../../../core/serviciosProvider";
import { useEffect, useState } from "react";
import { DonaUsers } from "../../Historial/Indicadores/Dona";
export const ECO_IndicadorPeticiones: React.FC = () => {
    const { lstContadorPeticiones } = useDataServicios();
    const [filtroGeneral, setFiltroGeneral] = useState<any>([]);

    useEffect(()=>{
        const lstfiltro: any = lstContadorPeticiones.filter(f => f.orden == 1);
        setFiltroGeneral(lstfiltro);

        return()=>{
            setFiltroGeneral([])  
        }
    }, [lstContadorPeticiones])
    
    return (
        <div className={`card card-stretch mb-5 mb-xxl-8`}>
            {/* <!--begin::Header--> */}
            <div className={` border-1 pt-5 `}>
                <div className="mx-auto">
                    <div className="ms-3 text-center">
                        <h5 className="mb-0">Módulos</h5>
                        <span className="text-muted m-0 fs-5">Indicadores Peticiones.</span>
                    </div>
                </div>

                <div id="indicador_chart_peticiones" className={`d-flex justify-content-center m-2  flex-wrap border rounded `}>
                    {(filtroGeneral.length > 0) && (
                        <DonaUsers series={FilroPeticiones.getDataDonaGeneral(filtroGeneral[0])}
                            labels={['Negativas', 'Positivas']} nameChart={`ECO_peticiones_indicadores`}
                            titulo="Peticiones Mix Integrated Mes" showLegend={true} showTotal={true} />
                    )}
                </div>
            </div>
        </div>
    )
}




