import { useState } from "react";
import { Card } from "react-bootstrap-v5";
import { FormControl, FormControlLabel, Radio, RadioGroup, Checkbox } from "@mui/material";
type Props = {
    Configuracion: any;
    setConfiguracion: any;
};

export const TabGeneral: React.FC<Props> = ({ Configuracion, setConfiguracion }) => {

    const Riesgos = ["Riesgo alto", "Riesgo moderado", "Riesgo bajo"] // lisatdo de riesgos a trabajar
    const [Categoria, setCategoria] = useState<string | null>("null");

    // validamos si hay configuracion de sonido
    Configuracion.Detalles.Sonido = Configuracion.Detalles.Sonido ?? [...Configuracion.Detalles.Gestionar];

    // para cualquier propiedad de detalle se valida si el objeto se encuentra 
    // chechado o no
    const getChecked = (campo: string, target: string) => {
        return Configuracion.Detalles[campo]?.indexOf(target) != -1
    }
    const getCheckedTipoAlerta = (target: string) => {
        return Configuracion.Detalles.TipoAlerta == target
    }

    const getDisabled = (target: string) => {
        return parseInt(Configuracion.tiempo) > 0 || Configuracion.Detalles.TipoAlerta == target
    }

    //setea la configuración de gestion
    // modifica la de sonido
    const setGestionAlerta = (evento: any) => {
        let D = { ...Configuracion };
        let i = D.Detalles.Gestionar.indexOf(evento.target.value);
        if (i != -1) {

            D.Detalles.Gestionar.splice(i, 1);
            const s = D.Detalles.Sonido.indexOf(evento.target.value);
            if (s != -1)
                D.Detalles.Sonido.splice(s, 1);
        }
        else {
            D.Detalles.Gestionar.push(evento.target.value);
            D.Detalles.Sonido.push(evento.target.value);
        }

        setConfiguracion(D);
    }
    // setea la configuración de sonido
    const setSonidoAlerta = (evento: any) => {
        let D = { ...Configuracion };
        let i = D.Detalles.Sonido.indexOf(evento.target.value);
        if (i != -1)
            D.Detalles.Sonido.splice(i, 1); // si existe elimina 
        else
            D.Detalles.Sonido.push(evento.target.value); // de lo contrario adiciona al array
        setConfiguracion(D);
    }

    // contro radio button mediante el cuál se puede escoger que tipo de alerta
    // ésta condición solo aparece si el tiempo de evaluación es Cero

    const ControlTipoAlerta = () => {
        return (<FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                value={Categoria}
                name="row-radio-buttons-group"
                onChange={(e: any) => {
                    e.preventDefault();
                    let D = { ...Configuracion };
                    D.Detalles.TipoAlerta = e.target.value;
                    D.Detalles.Gestionar = [e.target.value];
                    D.Detalles.Sonido = [e.target.value];
                    setConfiguracion(D);
                }}
            >
                {ControRbRiesgo(0, "Crítico", "error", "danger")}
                {ControRbRiesgo(1, "Mediano", "warning", null)}
                {ControRbRiesgo(2, "Bajo", "success", null)}
            </RadioGroup>
        </FormControl>)
    }

    // control Radio button por categoría de riesgo 
    const ControRbRiesgo = (categoria: number, label: string, color: any, labelColor: string | null) => {
        return <FormControlLabel color="red"
            value={Riesgos[categoria]}
            control={<Radio checked={getCheckedTipoAlerta(Riesgos[categoria])}
                color={color}
                id={`Riesgo${label}`} />}
            label={<><label className={`text-${labelColor ?? color}`}
                style={{ fontWeight: 'bold' }}
                htmlFor={`Riesgo${label}`}>{label}</label></>}
        />
    }


    // control de chechks de gestionar alertas

    const ControlGestionarAlerta = (categoria: number, label: string, color: any, labelColor: string | null) => {
        return <>
            <Checkbox
                onChange={(evento: any) => {
                    evento.preventDefault();
                    setGestionAlerta(evento);
                }}
                checked={getChecked("Gestionar", Riesgos[categoria])}
                disabled={!getDisabled(Riesgos[categoria])}
                value={Riesgos[categoria]}
                id={`Gestionar${label}`}
                color={color} />
            <label style={{ fontWeight: 'bold', color: (labelColor ?? color) }} htmlFor={`Gestionar${label}`}>{label}</label>
        </>
    }


    const ControlSonido = (categoria: number, label: string, color: any, labelColor: string | null) => {
        return <>
            <Checkbox
                onChange={(evento: any) => {
                    setSonidoAlerta(evento)
                }}
                checked={getChecked("Sonido", Riesgos[categoria])}
                disabled={!getChecked("Gestionar", Riesgos[categoria])}
                value={Riesgos[categoria]}
                id={`Sonar${label}`}
                color={color} />
            <label style={{ fontWeight: 'bold', color: (labelColor ?? color) }} htmlFor={`Sonar${label}`}>{label}</label>
        </>
    }

    return (<><Card className="border border-gray-300 mt-0 pt-0">
        <span className="card-title fw-bolder rounded  bg-secondary   m-1 text-center fs-5 border-bottom">Configuración General</span>

        <div className="row m-5 mt-2">
            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                <label className="control-label label-sm font-weight-bold" htmlFor="Nombre" style={{ fontWeight: 'bold' }}>Nombre alerta</label>
                <div className="input-group mb-3">
                    <span className="input-group-text"><i className="fas fa-pen"></i></span>
                    <input name="Nombre" placeholder="Nombre alerta" className="form-control input-sm" value={Configuracion.nombre} onChange={(e: any) => {
                        e.preventDefault();
                        let D = { ...Configuracion };
                        D.nombre = e.target.value;
                        setConfiguracion(D);
                    }} />
                </div>
            </div>
            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                <label className="control-label label-sm font-weight-bold" htmlFor="Tiempo" style={{ fontWeight: 'bold' }}>Tiempo evaluación (Min)</label>
                <div className="input-group mb-3" >
                    <span className="input-group-text"><i className="fas fa-clock"></i></span>
                    <input type="number" name="Tiempo" placeholder="Tiempo evaluación" className="form-control input-sm" value={Configuracion.tiempo} onChange={(e: any) => {
                        e.preventDefault();
                        let D = { ...Configuracion };
                        const tiempo = e.target.value;
                        D.tiempo = tiempo;
                        D.Detalles.TipoAlerta = (parseInt(tiempo) == 0) ? null : D.Detalles.TipoAlerta;
                        D.Detalles.Gestionar = (parseInt(tiempo) == 0) ? [] : [...Riesgos];
                        D.Detalles.Sonido = (parseInt(tiempo) == 0) ? [] : [...Riesgos];

                        setConfiguracion(D);

                    }} />
                </div>
            </div>
            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                <label className="control-label label-sm font-weight-bold" htmlFor="Nombre" style={{ fontWeight: 'bold' }}>Tiempo cierre (Min)</label>
                <div className="input-group mb-3">
                    <span className="input-group-text"><i className="fas fa-pen"></i></span>
                    <input name="tiempocierre" type="number" placeholder="Tiempo cierre en minutos" className="form-control input-sm" value={Configuracion.Detalles.TiempoCierre} onChange={(e: any) => {
                        e.preventDefault();
                        let D = { ...Configuracion };
                        D.Detalles.TiempoCierre = e.target.value;
                        setConfiguracion(D);
                    }} />
                </div>
            </div>
            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4"
                style={{ display: (parseInt(Configuracion.tiempo) > 0 ? 'none' : 'inline') }}>
                <label className="control-label label-sm font-weight-bold" htmlFor="Nombre" style={{ fontWeight: 'bold' }}> Tipo alerta</label>
                <div>
                    {ControlTipoAlerta()}
                </div>
            </div>
            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4"
                style={{
                    display: (parseInt(Configuracion.tiempo) > 0 ?
                        'block' : (Configuracion.Detalles.TipoAlerta != null) ? "block" : "none"
                    )
                }}
            >
                <label className="control-label label-sm font-weight-bold" htmlFor="Nombre"
                    style={{
                        fontWeight: 'bold'
                    }}>Gestionar</label>
                <div>
                    {ControlGestionarAlerta(0, "Crítico", "error", "red")}
                    {ControlGestionarAlerta(1, "Mediano", "warning", "orange")}
                    {ControlGestionarAlerta(2, "Bajo", "success", "green")}

                </div>
            </div>
            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4" style={{ display: (Configuracion.Detalles["Gestionar"].length == 0 ? "block" : "none") }}>

                <label className="control-label label-sm font-weight-bold" htmlFor="dashboard" style={{ fontWeight: 'bold' }}>Mostrar en DashBoard</label>
                <div>
                    <Checkbox onChange={(e: any) => {
                        let D = { ...Configuracion };
                        D.Detalles.VerDashboard = e.target.checked;
                        setConfiguracion(D);
                    }} checked={Configuracion.Detalles.VerDashboard} id="dashboard" color="primary" />
                </div>

            </div>
            <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4" style={{ display: (Configuracion.Detalles["Gestionar"].length > 0 ? "block" : "none") }}>

                <label className="control-label label-sm font-weight-bold" htmlFor="dashboard" style={{ fontWeight: 'bold' }}>Sonido alerta</label>
                <div>
                    {ControlSonido(0, "Crítico", "error", "red")}
                    {ControlSonido(1, "Mediano", "warning", "orange")}
                    {ControlSonido(2, "Bajo", "success", "green")}

                </div>

            </div>
        </div>
    </Card></>)

}