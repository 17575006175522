import { MRT_ColumnDef } from "material-react-table";
import { Preoperacional, Respuestas } from "../models/dataModels";
import { DeserializarJSon } from "../../../../../_start/helpers/Helper";

export const PREOP_Tooltips : any ={
    "00" : { title: "¿Esta seguro que desea agregar el comentario?", boton : "Guardar" }
, "10" : {title: "¿Esta seguro que terminar la gestión sin Aprobarla?", boton : "Terminar"}
, "11" : {title: "¿Esta seguro que terminar la gestión y Aprobarla?", boton : "Aprobar"}
}

export const listadoCampos = (TipoCliente : string) =>{

 const columnas : MRT_ColumnDef<Preoperacional>[] =

    [{
        accessorKey: 'Usuario',
        header: 'Usuario',
        size: 100
    },
    {
        accessorKey: (TipoCliente === "SINTEL") ? 'Tipo' : 'Conductor',
        header: (TipoCliente === "SINTEL") ? 'Cargo' : 'Conductor', 
        size: 100
    },
    {
        accessorKey: 'FechaHoraString',
        header: 'Fecha',
        size: 100
    },
    {
        accessorKey: 'Description',
        header: 'Activo',
        size: 80
    },
    {
        accessorKey: 'Estado',
        header: 'Estado',
        size: 80,
        Cell({ cell, column, row, table, }) {
            return (cell.getValue() == true) ? <span className="badge bg-danger">No Aprobado</span> : <span className="badge bg-primary">Aprobado</span>
        }
    },
    {
        accessorKey: 'EsGestionado',
        header: 'Estado Gestion',
        size: 80,
        Cell({ cell, column, row, table, }) {
            return (cell.getValue() == true) ? <span className="badge bg-primary">Gestionado</span> :
                row.original.Estado == 1 && (cell.getValue() == null) ? <span className="badge bg-danger">No Gestionado</span> :
                    row.original.Estado == 1 && (cell.getValue() == false) ? <span className="badge bg-warning">En Gestion</span> :
                        ""
        }
    },
    {
        accessorKey: 'GetorNombre',
        header: 'Gestor',
        size: 100
    }

    ];
 return columnas;
}

export const GetColorRow = (Respuesta: string, CompareRed: string) => {
    const flag: boolean = (Respuesta === CompareRed); // si es positivo quiere decir que la pregunta es  // está mala y
    // y debe ser catalogada como roja
    // si no tieme comp
    return flag ? 'rgba(248, 215, 218, 1)' : (
        (Respuesta === 'si' ||
            Respuesta === 'bueno') ? 'rgba(212, 237, 218, 1)' :
            Respuesta === 'malo'
                || Respuesta === 'no' ? 'rgba(255, 243, 205, 1)' : ''
    )
}

export const PREOP_listadoRespuesta: MRT_ColumnDef<Respuestas>[] =
[
    {
        accessorKey: 'Secuencia',
        header: '#',
        size: 50
    },
    {
        accessorKey: 'Agrupador',
        header: 'Categoría',
        size: 100
    },

    {
        accessorKey: 'Pregunta',
        header: 'Pregunta',
        size: 100
    },
    {
        accessorKey: 'Respuesta',
        header: 'Respuesta',
        size: 80
    },
    {
        accessorKey: 'Adjunto',
        header: 'Adjuntos',
        size: 80,
        Cell({ cell, column, row, table, }) {
            const adjunto = row.original.Adjunto;
            if (adjunto !== undefined) {
                const listad: string[] = DeserializarJSon(adjunto);
                return <>

                    {
                        listad.map((value, index) =>
                            <> <a className="mx-2" key={index} href={value}>{`Foto${index + 1}`}</a> <br /></>
                        )
                    }

                </>

            }

        },
    }
];