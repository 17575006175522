
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useToaster } from "rsuite";
import { MensajeError } from "../../../../../_start/helpers/components/Toaster";
import { ExtraerInformacionData, FiltroData, GetConfiguracion, GetRequerimientos, PerfilAdminFlota, PerfilEmpleado, PerfilSuperAdmin } from "../data/Requerimientos";
import { GetFechaServidor } from "../../../../../_start/helpers/Helper";
import { AxiosError, AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../setup";
import { UserModelSyscaf } from "../../../auth/models/UserModel";
import { GetUser } from "../../../auth/data/datPerfil";
import { GetDetalleListaBySigla } from "../../../Seguridad/Modulos/data/seguridaddata";


export interface RequerimientosContextModel {
    loader: boolean;
    lstRequerimientos: any[];
    lstConfiguracion: any[];
    lstUsuarios: any[];
    lstEstadosReq: any[];
    lstEstadosEquipoDesmontado: any[];
    lstDLP: any[];
    setloader: (loader: boolean) => void;
    setlstRequerimientos: (listado: any[]) => void;
    setlstConfiguracion: (listado: any[]) => void;
    setlstUsuarios: (listado: any[]) => void;
    setEstadosReq: (listado: any[]) => void;
    setEstadosEquipoDesmontado: (listado: any[]) => void;
    setDLP: (listado: any[]) => void;
    perfil: any, setPerfil: (listado: any) => void;
    UserActual: any, setUserActual: (listado: any) => void;
    ReqSeleccionado: any, setReqSeleccionado: (listado: any) => void;
    ListadoDLPRespuesta: any[];
    setListadoDLPRespuesta :(listado: any[]) => void;
    lstTecnicos: any[];
    setlstTecnicos :(listado: any[]) => void;
}

const ServcContext = createContext<RequerimientosContextModel>({
    setlstRequerimientos: (listado: any[]) => { },
    setlstConfiguracion: (listado: any[]) => { },
    setloader: (lloader: boolean) => { },
    loader: true,
    lstRequerimientos: [],
    lstConfiguracion: [],
    lstUsuarios: [],
    setlstUsuarios: (listado: any[]) => { },
    lstEstadosReq: [],
    lstEstadosEquipoDesmontado: [],
    lstDLP: [],
    setEstadosReq: (listado: any[]) => { },
    setEstadosEquipoDesmontado: (listado: any[]) => { },
    setDLP: (listado: any[]) => { },
    perfil: {}, setPerfil: (listado: any) => { },
    UserActual: {},
    setUserActual: (listado: any) => { },
    ReqSeleccionado: undefined,
    setReqSeleccionado: (listado: any) => { },
    ListadoDLPRespuesta: [],
    setListadoDLPRespuesta: (listado: any[]) => { },
    lstTecnicos: [],
    setlstTecnicos: (listado: any[]) => { },
});

export const RequerimientosProvider: React.FC = ({ children }) => {

    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;

    // VARIABLES DE USUARIO
    let PerfilUsuario: any = {};
    PerfilUsuario.isSuperAdmin = (PerfilSuperAdmin == vUser.perfil);
    PerfilUsuario.isAdmin = (PerfilAdminFlota == vUser.perfil);
    PerfilUsuario.isAgent = (PerfilEmpleado == vUser.perfil);

    const [perfil, setPerfil] = useState<any>(PerfilUsuario);
    const [UserActual, setUserActual] = useState<any>([]);

    
    const [lstRequerimientos, setlstRequerimientos] = useState<any[]>([]);
    const [lstConfiguracion, setlstConfiguracion] = useState<any[]>([]);
    const [lstUsuarios, setlstUsuarios] = useState<any[]>([]);
    const [loader, setloader] = useState<boolean>(true);
    const [lstEstadosReq, setEstadosReq] = useState<any[]>([]);
    const [lstEstadosEquipoDesmontado, setEstadosEquipoDesmontado] = useState<any[]>([]);
    const [lstDLP, setDLP] = useState<any[]>([]);
    const [ReqSeleccionado, setReqSeleccionado] = useState<any>();
    const [ListadoDLPRespuesta, setListadoDLPRespuesta] = useState<any[]>([]);
    const [lstTecnicos, setlstTecnicos] = useState<any[]>([]);
    const value: RequerimientosContextModel = {
        lstConfiguracion,
        setlstConfiguracion,
        lstRequerimientos,
        setlstRequerimientos,
        setloader, loader,
        lstUsuarios,
        setlstUsuarios,
        lstEstadosReq,
        lstEstadosEquipoDesmontado,
        lstDLP,
        setEstadosReq,
        setEstadosEquipoDesmontado,
        setDLP,
        perfil, setPerfil, UserActual, setUserActual,
        ReqSeleccionado,
        setReqSeleccionado,
        ListadoDLPRespuesta,
        setListadoDLPRespuesta,
        lstTecnicos,
        setlstTecnicos
    };
    return (<ServcContext.Provider value={value}> {children} </ServcContext.Provider>);
};

//Hace toda la magia de ir al servidor, traerse los datos y setearlos
export const GetDataRequerimientos: React.FC = ({ children }) => {
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;

    const toaster = useToaster();
    const { perfil, setlstConfiguracion, setlstRequerimientos, setloader, setlstUsuarios,
        setEstadosReq, setEstadosEquipoDesmontado, setDLP,
        setPerfil, setUserActual, setlstTecnicos
    } = useDataRequerimientos();

    //Se consultan las Configuraciones
    const getConfiguracion = (sigla: any | null) => {
        GetConfiguracion(sigla).then((response: AxiosResponse<any>) => {
            if (response.data.length != 0) {
                //Usuarios de Soporte
                let Users = FiltroData.getConfiguracionSigla(response.data, "COUSS");
                setlstUsuarios((Users.length != 0 ? Users[0] : []));
                //Estados generales
                let States = FiltroData.getConfiguracionSigla(response.data, "OERS");
                setEstadosReq(States[0]);
                //Preguntas DLP
                let DLP = FiltroData.getConfiguracionSigla(response.data, "DLPST");
                setDLP(DLP[0]);

                // Estados para subrequerimientos
                let dataConfig = FiltroData.getConfiguracionSigla(response.data, "EOSP");
                let datExec = dataConfig[0];
                setEstadosEquipoDesmontado(datExec);
                // VALIDAMOS SI ES UN AGENTE Y A QUE TIPO PERTENECE
                if (Users.length > 0) {
                    let usuario = ExtraerInformacionData(Users[0], vUser.Id);

                    if (usuario.length > 0) {
                        let Perfil = { ...perfil };
                        Perfil.tipo = usuario[0].tipo; // se asigna el tipo  Soporte / ST / ING
                        Perfil.EsGestor = usuario[0].EsGestor; // Determina si es Gestor
                        setPerfil(Perfil);

                        setUserActual(usuario[0]);

                    }
                }
            }
        }).catch((error: AxiosError) => {
            toaster.push(MensajeError('Traer Configuracion', error.message), { placement: 'topCenter' })
        })
    }

    const  GetUsuariosPerfilTecnico = ()=>{
        GetDetalleListaBySigla('SYS-STPRO').then(
           (response : AxiosResponse)=>{
               if(response.status === 200)
               {
   
                   GetUser(null,`${response.data[0].DetalleListaId}`).then(
                       (response) =>{
                        setlstTecnicos(response.data);
                       }
                   ).catch((error: AxiosError) => {
                    toaster.push(MensajeError('Traer Tecnicos Orden programada', error.message), { placement: 'topCenter' })
                })
               }
           }
        ).catch(()=>{ return [];});
       
   }

    const interval = useRef<any>();
    useEffect(() => {
        //Para obtner todas las configuraciones le paso la sigla en null, podria
        //filtrarse por sigla
        getConfiguracion(null);
        if (interval.current != 0)
            clearInterval(interval.current);
        let tiempo = 60000 * 10; // cada 1 min
        ConsultasIniciales(setloader, vUser, setlstRequerimientos, toaster);
        interval.current = setInterval(() => {
            ConsultasIniciales(setloader, vUser, setlstRequerimientos, toaster);
        }, tiempo);
        GetUsuariosPerfilTecnico();
        return () => {
            setlstConfiguracion([]);
            setlstRequerimientos([]);
            setlstUsuarios([]);
            setEstadosReq([]);
            setEstadosEquipoDesmontado([]);
            setDLP([]);
            // limpiamos todas las variables a ser detruidas
        };
    }, [children]);
    return <></>;
};

/** */
export function useDataRequerimientos() {
    return useContext(ServcContext);
}

//CONSULTA VEHICULOS OPERANDO
//ESPACIO PARA LAS CONSULTAS INICIALES
export const ConsultasIniciales = (setloader: any, vUser: any, setlstRequerimientos: any, toaster: any) => {
    setloader(true);

    GetRequerimientos(GetFechaServidor(), GetFechaServidor(), vUser.perfil).then(
        (response: AxiosResponse<any>
        ) => {
            setlstRequerimientos(response.data);


        }).catch((error: AxiosError<any>) => {
            toaster.push(MensajeError('Traer Requerimientos', error.message), { placement: 'topCenter' })
        }).finally(() => { setloader(false); });



};

