import { useEffect, useState } from "react"
import BlockUi from "@availity/block-ui";
import { DateRangePicker, useToaster } from "rsuite";
import { Help, PunchClock } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import moment from "moment";
import { PageTitle } from "../../../../../_start/layout/core";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../_start/helpers/components/HtlmTooltip";
import { ECO_Tooltips, GetClientesAdministradores, GetListadoUsuario, HistorialUsuarios } from "../data/datausuarios";
import { MensajeError } from "../../../../../_start/helpers/components/Toaster";
import { formatSimpleJson } from "../../../../../_start/helpers/Helper";
import { Perfiles, ranges } from "../../../../../_start/helpers/Constants";
import { Form, Tab, Tabs } from "react-bootstrap-v5";
import { TabHistorial } from "./Historial/usuarioTabHistorial";
import { TabUsuarios } from "./Historial/usuarioTabUsuarios";
import { TabIndicadores } from "./Historial/usuarioTabIndicadores";
import { TablaEntradaVsModulos } from "./Historial/Indicadores/IndicadoresPorModulos";
import { FiltroAdmin } from "../data/consultasGenerales";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../setup";
import { UserModelSyscaf } from "../../../auth/models/UserModel";
import { AxiosResponse } from "axios";
export default function ECO_IngresoUsuarios() {
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;
    const { allowedMaxDays, allowedRange, combine } = DateRangePicker;
    const toaster = useToaster();

    const [loader, setLoader] = useState(false);
    const [RangoFechas, setJornadaRangoFechas] = useState<any>({
        Inicial: moment().add(-1, 'month').toDate(),
        Final: moment().toDate()
    });

    const [data, setData] = useState<any[]>([]);
    const [dataUsuarios, setDataUsuarios] = useState<any[]>([]);
    const [dataFiltrada, setDataFiltrada] = useState<any[]>([]);
    const [dataUsuariosFiltrada, setDataUsuariosFiltrada] = useState<any[]>([]);
    const [dataAdmins, setDataAdmins] = useState<any[]>([]);
    const [adminSel, setAdminSel] = useState<any>('-1');
    // traemos la informacion de los parametros 
    useEffect(() => {
        ConsultarUsuarios();
        if (vUser.perfil === Perfiles.SuperAdmin)
            ConsultaAdministradores();

        return () => {
            setData([]);
            setDataUsuarios([]);
            setDataAdmins([]);
            setDataFiltrada([]);
            setDataUsuariosFiltrada([]);
        }
    }, []);
    useEffect(() => {
        Consultar();
    }, [RangoFechas]);
    // cuando hay cambio de administradores debe filtrar la info
    useEffect(() => {

        const clientes: string[] = dataAdmins.find(f => f.userid === adminSel)?.clientes;
        setDataFiltrada(adminSel === '-1' ? data : FiltroAdmin.historialFiltradosPorAdmin(data, clientes))
        setDataUsuariosFiltrada(adminSel === '-1' ? dataUsuarios : FiltroAdmin.usuariosFiltradosPorAdmin(dataUsuarios, clientes))

    }, [adminSel]);


    const ConsultaAdministradores = () => {
        setLoader(true)
        GetClientesAdministradores().then((res: AxiosResponse) => {
            if (res.status === 200) {
                // agrupamos la información por administrador 
                setDataAdmins(FiltroAdmin.getConsolidadoPorAdmin(res.data));
                setAdminSel('-1');
            }
            else {
                toaster.push(MensajeError(ECO_Tooltips['ErrorConsultar'].title, ECO_Tooltips['ErrorConsultar'].content), {
                    placement: 'topCenter'
                });
            }

        }).catch((e) => {
            toaster.push(MensajeError(ECO_Tooltips['ErrorConsultar'].title, ECO_Tooltips['ErrorConsultar'].content), {
                placement: 'topCenter'
            });
        }).finally(() => { setLoader(false); });
    }


    const Consultar = () => {
        setLoader(true)
        HistorialUsuarios(
            moment(RangoFechas.Inicial).format(formatSimpleJson),
            moment(RangoFechas.Final).format(formatSimpleJson)).then((res: AxiosResponse) => {
                if (res.status === 200) {
                    setData(res.data)
                    setDataFiltrada(res.data)
                }
                else {
                    toaster.push(MensajeError(ECO_Tooltips['ErrorConsultar'].title, ECO_Tooltips['ErrorConsultar'].content), {
                        placement: 'topCenter'
                    });
                }

            }).catch((e) => {
                toaster.push(MensajeError(ECO_Tooltips['ErrorConsultar'].title, ECO_Tooltips['ErrorConsultar'].content), {
                    placement: 'topCenter'
                });
            }).finally(() => { setLoader(false); });
    }

    const ConsultarUsuarios = () => {
        setLoader(true)
        GetListadoUsuario().then((res: AxiosResponse) => {
            if (res.status === 200) {

                setDataUsuarios(res.data)
                setDataUsuariosFiltrada(res.data)
            }
            else {
                toaster.push(MensajeError(ECO_Tooltips['ErrorConsultar'].title, ECO_Tooltips['ErrorConsultar'].content), {
                    placement: 'topCenter'
                });
            }

        }).catch((e) => {
            toaster.push(MensajeError(ECO_Tooltips['ErrorConsultar'].title, ECO_Tooltips['ErrorConsultar'].content), {
                placement: 'topCenter'
            });
        }).finally(() => { setLoader(false); });
    }
    const ComponenteClientes = () => {
        return (
            <Form.Select className=" h-40px w-300px " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                setAdminSel(e.currentTarget.value);
            }} value={adminSel} aria-label="Default select example">
                <option value={'-1'}>Todas las bases</option>
                {
                    dataAdmins.map((element: any, i: any) => {
                        return (<option key={element.userid} value={element.userid}>{element.admin}</option>)
                    })
                }
            </Form.Select>
        )
    }

    return (<>
        <PageTitle>Historial de Usuarios Activos </PageTitle>
        <BlockUi tag="div" keepInView blocking={loader ?? false} message="Cargando datos, favor espere..." >

            <div className="card card-rounded shadow mt-2" style={{ width: '100%' }}  >
                <Stack className="border shadow-sm mt-10">
                    <div className="mx-auto">
                        <div className="ms-3 text-center">
                            <h5 className="mb-0">Historial de Usuarios<HtmlTooltipStyled arrow
                                title={HtmlContentTitle(ECO_Tooltips["Historial"].title, ECO_Tooltips["Historial"].content)
                                } >
                                <IconButton size="small"><Help /></IconButton>
                            </HtmlTooltipStyled></h5>

                        </div>
                    </div>
                    <div className="d-flex justify-content-start m-5">
                        <Stack >

                            <h4 className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Filtros: </h4>
                            <div className="d-flex justify-content-start"  >
                                <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Rango Fechas: </label>
                                {(combine && allowedMaxDays && allowedRange) && (
                                    <DateRangePicker
                                        disabledDate={combine(allowedMaxDays(31), allowedRange(
                                            moment().add(-12, 'month').startOf('day').toDate(), moment().startOf('day').toDate()
                                        ))} ranges={ranges} format="dd/MM/yyyy" caretAs={PunchClock}
                                        value={[RangoFechas.Inicial, RangoFechas.Final]}
                                        onChange={(value, e) => {
                                            if (value !== null) {
                                                const inicial: any = value[0];
                                                const final: any = value[1];

                                                setJornadaRangoFechas({
                                                    Inicial: inicial,
                                                    Final: final
                                                })
                                            }
                                        }}
                                    />)}
                                {(vUser.perfil === Perfiles.SuperAdmin) && (
                                    <>   <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Administrador: </label>

                                        <ComponenteClientes /></>
                                )}


                            </div>

                        </Stack>

                    </div>
                </Stack>

            </div>
            <div className="d-flex flex-column mt-5">
                <Tabs defaultActiveKey="usuariosActivos" className="mb-3  rounded " >
                    <Tab eventKey="usuariosActivos" title={'Ingresos Usuarios'} className="mx-1">
                        <TabIndicadores data={dataFiltrada} dataUsuarios={dataUsuariosFiltrada} />
                    </Tab>
                    <Tab eventKey="TablaEntradaVsModulos" title={'Ingresos Módulos'} className="mx-1">
                        <TablaEntradaVsModulos data={dataFiltrada} dataUsuarios={dataUsuariosFiltrada} />
                    </Tab>
                    <Tab eventKey="usuarios" title={'Usuarios Sistema'} className="mx-1">
                        <TabUsuarios data={dataUsuariosFiltrada} ConsultarUsuarios={ConsultarUsuarios} />
                    </Tab>
                    <Tab eventKey="gestion" title={'Historial Usuarios'} className=" mx-1">
                        <TabHistorial data={dataFiltrada}/>
                    </Tab>


                </Tabs>
            </div>
        </BlockUi>
    </>
    )
}