import { Stack } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { DateRangePicker, useToaster } from "rsuite";
import { ranges } from "../../../../../../../_start/helpers/Constants";
import { PunchClock } from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { columnasHistorialUsuario } from "../../../data/usuariosdata";
import { GetLogUsuarioPorUsuario } from "../../../data/usuariosRolesdata";
import { useDataUsuarios } from "../../../core/usuariosProvider";
import { AxiosError } from "axios";
import { MensajeError } from "../../../../../../../_start/helpers/components/Toaster";

export const TabHistorialUsuario: React.FC = () => {
    const toaster = useToaster();

    const { usuarioSel } = useDataUsuarios();
    const { allowedMaxDays, allowedRange, combine } = DateRangePicker;
    const [loader, setLoader] = useState(false);
    const [RangoFechas, setJornadaRangoFechas] = useState<any>({
        Inicial: moment().add(-1, 'month').toDate(),
        Final: moment().toDate()
    });
    const [dataHistorica, setdataHistorica] = useState<any[]>([]);

    useEffect(() => {
        Consultar();
    }, [RangoFechas])

    const Consultar = () => {
        setLoader(true);
        GetLogUsuarioPorUsuario(usuarioSel.Id, RangoFechas.Inicial, RangoFechas.Final).
            then((r) => {

                setdataHistorica(r.data);
            }).catch((error: AxiosError) => {
                toaster.push(MensajeError('Error al cargar Datos', 'Favor actualice la página para recargar la información'), {
                    placement: 'topCenter'
                })
            }).finally(() => { setLoader(false) });

    }

    return (<div className="d-flex flex-column justify-content-start m-5 gap-3">
   

            <div className="d-flex justify-content-start"  >
                <label className="control-label label  label-sm mx-5 m-2 mt-2 fs-3">Rango Fechas: </label>
                {(combine && allowedMaxDays && allowedRange) && (
                    <DateRangePicker
                        disabledDate={combine(allowedMaxDays(31), allowedRange(
                            moment().add(-12, 'month').startOf('day').toDate(), moment().startOf('day').toDate()
                        ))} ranges={ranges} format="dd/MM/yyyy" caretAs={PunchClock}
                        value={[RangoFechas.Inicial, RangoFechas.Final]}
                        onChange={(value, e) => {
                            if (value !== null) {
                                const inicial: any = value[0];
                                const final: any = value[1];

                                setJornadaRangoFechas({
                                    Inicial: inicial,
                                    Final: final
                                })
                            }
                        }}
                    />)}
            </div>
            <div>
                <MaterialReactTable
                    localization={MRT_Localization_ES}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120
                        },
                    }}

                    muiTableHeadCellProps={{
                        sx: () => ({
                            fontSize: 14,
                            fontStyle: 'bold',
                            color: 'rgb(27, 66, 94)'
                        }),
                    }}
                    columns={columnasHistorialUsuario}
                    data={dataHistorica}
                    enableColumnOrdering
                    enableStickyHeader
                    enableDensityToggle={false}
                    enableSorting={true}
                    enablePagination={false}
                    enableRowVirtualization
                    muiTableContainerProps={{
                        sx: { maxHeight: '500px' }, //give the table a max height
                    }}
                    enableEditing={true}
                    editingMode="modal"
                    state={{
                        isLoading: loader

                    }}
                />
            </div>

    
    </div>)

}