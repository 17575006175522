import { Modal } from "react-bootstrap-v5";
import { useDataDashBoard } from "../../../core/DashboardCore";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import {  CamposEspeciales, Encabezado } from "../../../data/dashboard";
import { GetVehiculoEspecial } from "../../../data/PostVentaData";
import { useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { useToaster } from "rsuite";
import { MensajeError } from "../../../../../../../_start/helpers/components/Toaster";

export const ModalEquiposEspeciales: React.FC<{ CrearRequerimiento: any, show: boolean, setShow: any }> = ({ CrearRequerimiento, show, setShow }) => {

    const {  } = useDataDashBoard();
    const [loader, setLoader] = useState(false);
    const [PlacaEspecial, setPlacaEspecial] = useState<string>("");
    const toaster = useToaster();
    
    const [DatoVehiculoEspecial, setDatoVehiculoEspecial] = useState<any[]>([]);
    const getVehiculoEspecial = (Placa: any) => {
        if (Placa != "") {
            setLoader(true)
            GetVehiculoEspecial(Placa).then((response: AxiosResponse<any>) => {
                setDatoVehiculoEspecial(response.data);
                setLoader(false)
            }).catch((error: AxiosError<any>) => {
                toaster.push(MensajeError( "Error al extraer datos", error.message), {
                    placement: 'topCenter'
                });
                setLoader(false)
            });
        }
        else {
            toaster.push(MensajeError( "Datos vacios", "Por favor ingrese una placa"), {
                placement: 'topCenter'
            });
        }
    };


    return(
        <Modal show={show} onHide={setShow} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Busqueda de equipos especiales</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <label className="control-label label label-sm fw-bolder">Placa</label>
                            <div className="mb-3">
                                <input className="input form-control input-sm" type="text" onChange={(e: any) => {
                                    setPlacaEspecial(e.target.value);
                                }} placeholder="Placa del vehiculo especial a buscar"></input>
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3 mt-7">
                                <button className="btn btn-sm btn-warning" onClick={() => {
                                    getVehiculoEspecial(PlacaEspecial);
                                }}><i className="bi bi-search"></i> Consultar</button>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <MaterialReactTable
                            localization={MRT_Localization_ES}
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 100,
                                },
                            }}
                            muiTableHeadCellProps={{
                                sx: () => ({
                                    fontSize: 14,
                                    fontStyle: 'bold',
                                    color: 'rgb(27, 66, 94)'
                                }),
                            }}
                            columns={CamposEspeciales(CrearRequerimiento, 4)}
                            data={DatoVehiculoEspecial}
                            enableColumnOrdering
                            enableStickyHeader
                            enableDensityToggle={false}
                            enablePagination={false}
                            enableRowVirtualization
                            muiTableContainerProps={{
                                sx: { maxHeight: '400px' }, //give the table a max height
                            }}
                            initialState={{ density: 'compact' }}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-secundary" onClick={() => { setShow(false); }}>Cancelar</button>
            </Modal.Footer>
        </Modal>)
};