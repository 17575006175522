import { Button } from "@mui/material";
import { useDataConfiguracion } from "../../../core/ConfiguracionCore";

export const PREOP_PanelGeneral: React.FC = () => {
    const { showObject, setshowObject, Clientes } = useDataConfiguracion();


    return (
        <div className="col-lg-12">
            <div className="row g-5">
                <div className="col-sm-6">
                    <h4 className="fw-bolder mb-4 mt-4">Configuración</h4>
                </div>

            </div>

            {/* begin::Row */}
            <div className="row g-5 mt-5">

                <div className="col-sm-4" >
                    <Button variant="text"
                        onClick={() => {
                            setshowObject({ ...showObject, configuracion: true, panelCentral: false })
                        }}
                        style={{ width: '100%' }}
                        className=" rounded bg-light-success hoverable shadow-none min-h-150px "
                    >
                        <div className="card-body d-flex flex-column flex-center text-center">
                            <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                Eventos
                            </h3>
                            <span className="mb-0 text-gray-600">
                                Parametrización general eventos y frecuencias
                            </span>
                        </div>
                    </Button>

                </div>
                <div className="col-sm-4" >
                    <Button variant="text"
                        onClick={() => {
                            setshowObject({ ...showObject, usuarios: true, panelCentral: false })
                        }}
                        style={{ width: '100%' }}
                        className=" rounded bg-light-primary hoverable shadow-none min-h-150px ">

                        <div className="card-body d-flex flex-column flex-center text-center">
                            <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                Usuarios
                            </h3>
                            <span className="mb-0 text-gray-600">
                                Activación , asociación y gestión.
                            </span>
                        </div>
                    </Button>

                </div>
                <div className="col-sm-4" >
                    <Button variant="text"
                        onClick={() => {
                            setshowObject({ ...showObject, plantillas: true, panelCentral: false })
                        }}
                        style={{ width: '100%' }}
                        className=" rounded bg-light-danger hoverable shadow-none min-h-150px "
                    >
                        <div className="card-body d-flex flex-column flex-center text-center">
                            <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                Plantillas
                            </h3>
                            <p className="mb-0 text-gray-600">
                                Gestión Plantillas y Preguntas.
                            </p>
                        </div>
                    </Button>
                </div>


                <div className="row g-5 mt-5">
                    <div className="col-sm-4" >
                        <Button variant="text"
                            onClick={() => {
                                setshowObject({ ...showObject, assets: true, panelCentral: false })
                            }}
                            style={{ width: '100%' }}
                            className=" rounded bg-light-info hoverable shadow-none min-h-150px "
                        >
                            <div className="card-body d-flex flex-column flex-center text-center">
                                <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                    Assest
                                </h3>
                                <p className="mb-0 text-gray-600">
                                    Permite escoger los assets a trabajar
                                </p>
                            </div>
                        </Button>
                    </div>
                    <div className="col-sm-4" >
                        <Button variant="text"
                            onClick={() => {
                                setshowObject({ ...showObject, listas: true, panelCentral: false })
                            }}
                            style={{ width: '100%' }}
                            className="card rounded bg-light-success hoverable shadow-none min-h-150px "
                        >
                            <div className="card-body d-flex flex-column flex-center text-center">
                                <h3 className="fs-3 mb-2 text-dark fw-bolder">
                                    Listas Distribución
                                </h3>
                                <p className="mb-0 text-gray-600">
                                    Configura los correos a donde llegarán las notificaciones
                                </p>
                            </div>
                        </Button>
                    </div>

                </div>

            </div>
        </div>
    )
}